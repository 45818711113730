import PropTypes from "prop-types"
import React from "react"
import { useParams } from "react-router-dom"
import classNames from "classnames"
import ScoreWidget from "components/widgets/ScorePage/widgets/ScoreWidget"

const NoDashboardDataOrganism = ({ maturity, description }) => {
  const { pillarId } = useParams()

  return (
    <div className="Verdikt-Score">
      <div className={classNames(pillarId, "page-frame")}>
        <div className="page">
          <div className="left">
            <div className="row">
              {maturity !== null && <ScoreWidget maturity={maturity} />}
              <div className="widget-no-data">{description}</div>
            </div>
            <div className="row"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

NoDashboardDataOrganism.propTypes = {
  maturity: PropTypes.number,
  description: PropTypes.string.isRequired,
}

export default NoDashboardDataOrganism
