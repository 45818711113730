import { useEffect } from "react"
import { useAppContext } from "components/AppContext"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { DEFAULT_URI } from "../../hooks/useObjectiveNavigation"

const ObjectiveNavigationTemplate = ({ children, changeToDefault }) => {
  const { objectiveId } = useParams()
  const { updateObjectiveNavigation } = useAppContext()

  useEffect(() => {
    if (objectiveId) {
      updateObjectiveNavigation(objectiveId)
    } else if (changeToDefault) {
      updateObjectiveNavigation(DEFAULT_URI)
    }
  }, [objectiveId, changeToDefault])

  return children
}

ObjectiveNavigationTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  changeToDefault: PropTypes.bool,
}

ObjectiveNavigationTemplate.defaultPropTypes = {
  children: null,
  changeToDefault: true,
}

export default ObjectiveNavigationTemplate
