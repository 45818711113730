import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { VOLUME_UNIT } from "utils/constants/numbers"
import { round } from "services/units/round"
import { convertValuesToReadable } from "services/units/valueConversion"
import { ML_UNIT, VOLUME_UNITS_KEYS } from "services/units/units"

const ChartSDG13WaterConsumptionMolecule = ({
  mixedChart = [],
  defaultUnit,
}) => {
  const { t } = useTranslation()
  const labels = mixedChart.map((websiteData) => websiteData.label)
  const bar = mixedChart.map((websiteData) => websiteData.bar)

  const lineValues = mixedChart.map((websiteData) => websiteData.line * 10)
  const { values: line, unit: lineUnitKey } = convertValuesToReadable(
    lineValues,
    ML_UNIT,
    VOLUME_UNITS_KEYS,
    round
  )

  const barData = {
    label: t("score:score.6.chart6.legend.bar"),
    data: bar,
  }

  const lineData = {
    label: t("score:score.6.chart6.legend.line"),
    data: line,
  }

  const data = { bar_data: barData, line_data: lineData }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`score:score.6.chart6.help`)}>
      <MixedChart
        title={t("score:score.6.chart6.title")}
        data_inputs={data}
        labels={labels}
        unit_left_axis={t(`units:units.volume.${lineUnitKey}`)}
        unit_right_axis={NumberFormatterApp.getI18nUnit(
          VOLUME_UNIT,
          mixedChart[0]?.bar_unit || defaultUnit
        )}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSDG13WaterConsumptionMolecule.propTypes = {
  mixedChart: PropTypes.array,
  defaultUnit: PropTypes.string,
}

ChartSDG13WaterConsumptionMolecule.defaultProps = {
  defaultUnit: "l_unit",
}

export default ChartSDG13WaterConsumptionMolecule
