import PropTypes from "prop-types"
import React from "react"
import TextChartODD13EmissionsEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsEmployeeMolecule"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import ChartODD13EquipmentsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentsGHGEmissionsMolecule"
import ChartODD13GHGEmissionsDistributionConsolidateMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionConsolidateMolecule"
import TextChartODD13HighestITDomainEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13HighestITDomainEmissionsMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartODD13GHGEmissionsInternalExternalMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsInternalExternalMolecule"
import ChartODD13ConsolidateChart3 from "views/molecules/canvases/charts/ChartODD13ConsolidateChart3"

const RegionScoreLayoutOrganism = ({ score }) => {
  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={
        <TextChartODD13EmissionsEmployeeMolecule
          score={score}
          parent={parent}
        />
      }
      chart3={<ChartODD13EquipmentsGHGEmissionsMolecule score={score} />}
      chart4={
        <ChartODD13GHGEmissionsDistributionConsolidateMolecule score={score} />
      }
      chart5={<TextChartODD13HighestITDomainEmissionsMolecule score={score} />}
      chart6={
        score?.odd13_ghg_internal_external_spit ? (
          <ChartODD13GHGEmissionsInternalExternalMolecule
            ghgSplit={score?.odd13_ghg_internal_external_spit}
          />
        ) : (
          <ChartODD13ConsolidateChart3 score={score} />
        )
      }
    />
  )
}

RegionScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default RegionScoreLayoutOrganism
