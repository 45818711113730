import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartBadge from "views/atoms/badges/ChartBadge"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { CO2_EMISSION_UNITS, KG_CO2E } from "services/units/units"
import { convertValueToReadable } from "services/units/valueConversion"
import { round } from "services/units/round"

const TextChartODD13VolumeGHGTelcoMolecule = ({
  telcoAveragePerFte,
  volumeAveragePerFte,
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const badge = (
    <ChartBadge
      value={+telcoAveragePerFte}
      lowerLimit={500}
      upperLimit={800}
      inverse
    />
  )
  const perFte = (baseUnit) => (
    <>
      {baseUnit} {t(`analyze:it_domain.consolidated.saas.per_line`)}
    </>
  )

  const volumeAveragePerFteFormatted = NumberFormatterApp.getNumberFormatter(
    volumeAveragePerFte,
    "storage",
    "gb_unit"
  )

  const {
    value: telcoAveragePerFteConverted,
    unit: telcoAveragePerFteUnit,
  } = convertValueToReadable(
    +telcoAveragePerFte,
    KG_CO2E,
    CO2_EMISSION_UNITS,
    (value) => round(value, 0)
  )

  const content = {
    kpi: telcoAveragePerFteConverted,
    kpi_unit: perFte(
      <Unit unit={t(`units:units.emission.${telcoAveragePerFteUnit}`)} />
    ),
    kpi_text: t(`${translationPrefix}chartVolumeGHGTelco.text_telco`),
    sub_kpi: parseInt(volumeAveragePerFteFormatted[0]),
    sub_kpi_unit: perFte(
      <Unit
        unit={t(`units:units.storage.${volumeAveragePerFteFormatted[1]}`)}
      />
    ),
    reference: badge,
  }

  const content_for_display = <TextChartMinor content={content} />

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`analyze:it_domain.per_node.chartVolumeGHGTelco.help`)}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13VolumeGHGTelcoMolecule.propTypes = {
  telcoAveragePerFte: PropTypes.number.isRequired,
  volumeAveragePerFte: PropTypes.number.isRequired,
}

export default TextChartODD13VolumeGHGTelcoMolecule
