import { Banner, Layout } from "@shopify/polaris"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useAppContext } from "../../AppContext"
import { PILLAR_ENVIRONMENTAL, PILLAR_SOCIAL } from "utils/constants/pillars"
import { NODE_TYPE_COUNTRY } from "utils/constants/organization/nodeTypes"
import { useCollectContext } from "./CollectContextProvider"

const PillarsBanners = () => {
  const { t } = useTranslation()
  const tPrefix = "collect:questions.common.banner"

  const { pillarId } = useParams()
  const { collect } = useCollectContext()
  const { currentNode } = useAppContext()

  const generateMessage = useCallback(() => {
    if (!currentNode) {
      return "current_scope"
    }
    if (!pillarId || pillarId === PILLAR_ENVIRONMENTAL) {
      return null
    }
    if (currentNode.type !== NODE_TYPE_COUNTRY && collect === null) {
      return "unavailable_node_type"
    }
    if (pillarId === PILLAR_SOCIAL && collect === null) {
      return "unavailable_country"
    }
    return null
  }, [pillarId, collect, currentNode])

  const message = generateMessage()

  if (!message) {
    return null
  }

  return (
    <Layout.Section>
      <Banner
        status="info"
        title={t(`${tPrefix}.${message}.title`)}
        action={{
          content: t(`${tPrefix}.${message}.content`),
          url: "/dashboard",
        }}
      >
        {t(`${tPrefix}.${message}.text`)}
      </Banner>
    </Layout.Section>
  )
}

PillarsBanners.propTypes = {}

export default PillarsBanners
