import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const Objective10Score = ({ score, collect }) => {
  const { t } = useTranslation()

  const socialReference = score.social_references
  const socialPeopleInventoryScore = score.social_people_inventory_score
  const websiteScores = score.website_scores

  const { objectiveId } = useParams()
  const translationPrefix = `score:score.${objectiveId}.`

  const chart1 = useCallback(() => {
    const inclusion_KPI = score["odd10_inclusion_KPI"]

    const content = {
      kpi: inclusion_KPI,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const recruitment_dynamic_KPI = score["odd10_recruitment_dynamic_KPI"]

    const content = {
      kpi: recruitment_dynamic_KPI,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
      translationPrefix_chartX: translationPrefix + "chart2.",
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    const indefinite_term_input =
      collect["recruitment_indefinite_term_contract_rate"] ??
      socialPeopleInventoryScore[
        "odd10_recruitment_indefinite_term_contract_rate"
      ]
    const fixed_term_input =
      collect["recruitment_fixed_term_contract_rate"] ??
      socialPeopleInventoryScore["odd10_recruitment_fixed_term_contract_rate"]
    const work_study_rate_input =
      collect["recruitment_work_study_rate"] ??
      socialPeopleInventoryScore["odd10_recruitment_work_study_rate"]
    const trainee_rate_input =
      collect["recruitment_trainee_rate"] ??
      socialPeopleInventoryScore["odd10_recruitment_trainee_rate"]

    const indefinite_term_reference =
      socialReference["odd10_recruitment_indefinite_term_contract_rate_IT"]
    const fixed_term_reference =
      socialReference["odd10_recruitment_fixed_term_contract_rate_IT"]
    const work_study_rate_reference =
      socialReference["odd10_recruitment_work_study_rate_IT"]
    const trainee_rate_reference =
      socialReference["odd10_recruitment_trainee_rate_IT"]

    let data = [
      {
        label: t(`score:score.10.chart3.indefinite`),
        data: indefinite_term_input,
        reference: indefinite_term_reference,
      },
      {
        label: t(`score:score.10.chart3.fixed_term`),
        data: fixed_term_input,
        reference: fixed_term_reference,
      },
      {
        label: t(`score:score.10.chart3.apprenticeship`),
        data: work_study_rate_input,
        reference: work_study_rate_reference,
      },
      {
        label: t(`score:score.10.chart3.internship`),
        data: trainee_rate_input,
        reference: trainee_rate_reference,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart3.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart3.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.market_data")}
          axesLabel={t(translationPrefix + "chart3.y_label")}
          displayYAxe={true}
          displayLegend={true}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart4 = useCallback(() => {
    const labels = [
      t(`score:score.10.chart4.axes.seniority`),
      t(`score:score.10.chart4.axes.handicap`),
      t(`score:score.10.chart4.axes.number_of_nationalities`),
      t(`score:score.10.chart4.axes.non_local_employee_rate`),
    ]

    const senior_rate_input =
      collect["inclusion_IT_senior_rate"] ??
      socialPeopleInventoryScore["odd10_inclusion_IT_senior_rate"]
    const disabled_employee_rate_input =
      collect["inclusion_IT_disabled_employee_rate"] ??
      socialPeopleInventoryScore["odd10_inclusion_IT_disabled_employee_rate"]
    const number_nationalities_input =
      collect["diversity_IT_number_nationalities"] ??
      socialPeopleInventoryScore["odd10_diversity_IT_number_nationalities"]
    const non_local_employee_rate_input =
      collect["diversity_IT_non_local_employee_rate"] ??
      socialPeopleInventoryScore["odd10_diversity_IT_non_local_employee_rate"]

    const senior_rate_reference =
      socialReference["odd10_inclusion_IT_senior_rate_IT"]
    const disabled_employee_rate_reference =
      socialReference["odd10_inclusion_IT_disabled_employee_rate_IT"]
    const number_nationalities_reference =
      socialReference["odd10_diversity_IT_number_nationalities_IT"]
    const non_local_employee_rate_reference =
      socialReference["odd10_diversity_IT_non_local_employee_rate_IT"]

    let dataset = []

    let datas = [
      senior_rate_input,
      disabled_employee_rate_input,
      number_nationalities_input,
      non_local_employee_rate_input,
    ]
    let object = {
      label: t(`score:score.common.datasets.client_data`),
      data: { datas },
    }
    dataset.push(object)

    datas = [
      senior_rate_reference,
      disabled_employee_rate_reference,
      number_nationalities_reference,
      non_local_employee_rate_reference,
    ]
    object = {
      label: t(`score:score.common.datasets.market_data`),
      data: { datas },
    }
    dataset.push(object)

    const data = [
      {
        labels: labels,
        datasets: dataset,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <RadarChart title={t(translationPrefix + "chart4.title")} data={data} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart5 = useCallback(() => {
    const inclusion_handicap_KPI = score["odd10_inclusion_handicap_KPI"]

    const content = {
      kpi: inclusion_handicap_KPI,
      kpi_unit: t(translationPrefix + "chart5.unit"),
      kpi_text: t(translationPrefix + "chart5.text"),
      translationPrefix_chartX: translationPrefix + "chart5.",
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    let data = []
    const accessibility_reference =
      socialReference["odd10_inclusion_IT_website_accessibility_maturity_rate"]

    websiteScores.forEach((websiteScore) => {
      let accessibility_maturity =
        websiteScore.accessibility_maturity == null
          ? 0
          : websiteScore.accessibility_maturity

      data.push({
        label: websiteScore.url.split("/")[2],
        data: accessibility_maturity,
        reference: accessibility_reference,
      })
    })

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart6.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart6.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.market_data")}
          displayYAxe={true}
          displayLegend={true}
          axesLabel=""
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference, socialPeopleInventoryScore, websiteScores])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd10_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective10Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective10Score
