import React from "react"
import { Banner } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const ImproveWebsiteErrorBoundary = () => {
  const { t } = useTranslation()

  const { title, description } = t("error_boundary:improve_website", {
    returnObjects: true,
  })

  return (
    <Banner title={title} status="warning">
      {description}
    </Banner>
  )
}

export default ImproveWebsiteErrorBoundary
