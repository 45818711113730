import { Banner, Page } from "@shopify/polaris"
import { useHistory, useParams } from "react-router-dom"
import React, { createContext, useContext, useEffect, useState } from "react"
import Loading from "components/widgets/Loading"
import ObjectivesFrame, {
  CHANGE_TO_PILLAR,
} from "components/widgets/CollectPage/ObjectivesFrame"
import ThemesFrame from "components/widgets/CollectPage/ThemesFrame"
import RecommendationsLayout from "components/widgets/ImprovePage/RecommendationsLayout"
import { useTranslation } from "react-i18next"
import Message from "components/widgets/Dashboard/Message"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import { useAppContext } from "components/AppContext"
import RecommendationEntity from "services/entities/RecommendationEntity"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../auth/withAuthRequired"
import { useEntity } from "../../hooks/useAxios"
import {
  IMPROVE_MATURITY_PAGE,
  IMPROVE_WEBSITES_PAGE,
} from "utils/constants/improve/pages"
import usePillars from "views/organisms/pillars/usePillars"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import RedirectIfDisabled from "views/molecules/RedirectIfDisabled"
import {
  PROFIT_RECOMMENDATION_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"

export const ImproveContext = createContext()

export function useImproveContext() {
  return useContext(ImproveContext)
}

const ImproveMaturityPage = () => {
  const [recommendations, setRecommendations] = useState(null)
  const [score, setScore] = useState(null)
  const { pillarId, objectiveId, themeId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()

  const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(true)
  const [isScoreLoading, setIsScoreLoading] = useState(true)

  const { isFeatureActive } = useFeatureFlag()

  const profitRecommendationFeatureActive = isFeatureActive(
    PROFIT_RECOMMENDATION_FEATURE
  )

  const {
    currentPeriod,
    envScore,
    socialScore,
    isCurrentNodeLoading,
    currentRootNode,
    isCurrentRootNodeLoading,
    parentOrganization,
    profitScore,
  } = useAppContext()
  const recommendationEntity = useEntity(RecommendationEntity)

  const getRecommendations = async (pillar, objective = null, theme = null) => {
    try {
      const recommendationsResponse = await recommendationEntity.index(
        pillar,
        objective,
        theme
      )

      setRecommendations(recommendationsResponse)
    } catch (err) {
      console.error(err)
      setRecommendations([])
    } finally {
      setIsRecommendationsLoading(false)
    }
  }

  const getScore = (pillar) => {
    setIsScoreLoading(true)
    const pillarToScore = {
      [PILLAR_ENVIRONMENTAL]: envScore,
      [PILLAR_SOCIAL]: socialScore,
      [PILLAR_PROFIT]: profitScore,
    }
    setScore(pillarToScore[pillar] || null)
    setIsScoreLoading(false)
  }

  const pillars = usePillars({
    pageName: `/${IMPROVE_MATURITY_PAGE}`,
    [PILLAR_ENVIRONMENTAL]: { disabled: !envScore },
    [PILLAR_SOCIAL]: { disabled: !socialScore },
    [PILLAR_PROFIT]: {
      disabled: !profitScore || !profitRecommendationFeatureActive,
    },
  })

  useEffect(() => {
    if (!pillarId) {
      history.push(PILLAR_ENVIRONMENTAL)
      return
    }

    if (pillarId === PILLAR_PROFIT && !profitRecommendationFeatureActive) {
      history.push(`/improve/maturity/${PILLAR_ENVIRONMENTAL}`)
    }

    getRecommendations(pillarId, objectiveId, themeId)
    getScore(pillarId)
  }, [pillarId, objectiveId, themeId, envScore, socialScore])

  useEffect(() => {
    /** Reset reco loading state everytime we change objective or theme to display the loader again
     * The RecommendationsLayout component will then load and handle further api calls to recommendations api
     */
    setIsRecommendationsLoading(true)
  }, [pillarId, objectiveId, themeId])

  if (currentRootNode && !currentRootNode.has_children) {
    return <Message messageId="no_node_children" show />
  }

  const content = () => {
    if (
      isCurrentNodeLoading ||
      isCurrentRootNodeLoading ||
      isRecommendationsLoading ||
      isScoreLoading
    )
      return <Loading />

    if (!score)
      return (
        <Banner
          title={t(`improve:messages.empty.title`)}
          action={{
            content: t(`improve:messages.empty.cta`),
            url: "/collect",
          }}
        >
          {t(`improve:messages.empty.message`)}
        </Banner>
      )

    return (
      <RecommendationsLayout
        pillar={pillarId}
        objective={objectiveId}
        theme={themeId}
      />
    )
  }

  return (
    <ImproveContext.Provider
      value={{
        recommendations,
        score,
        getScore,
      }}
    >
      <ObjectiveNavigationTemplate changeToDefault={false}>
        <RedirectIfDisabled pageName={`/${IMPROVE_WEBSITES_PAGE}`}>
          <Page fullWidth>
            {parentOrganization && (
              <BreadcrumbsOrganism
                parentOrganization={parentOrganization}
                currentPeriodName={currentPeriod?.name}
              />
            )}
            <br />
            <div className="pageLayout">
              <PillarOrganism pillars={pillars} />
              <ObjectivesFrame
                pageName={IMPROVE_MATURITY_PAGE}
                changeToDefault={CHANGE_TO_PILLAR}
              >
                <ThemesFrame pageName={IMPROVE_MATURITY_PAGE}>
                  {content()}
                </ThemesFrame>
              </ObjectivesFrame>
            </div>
          </Page>
        </RedirectIfDisabled>
      </ObjectiveNavigationTemplate>
    </ImproveContext.Provider>
  )
}

export default withAuthRequired(ImproveMaturityPage)
