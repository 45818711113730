import React from "react"
import ProgressBar from "components/widgets/ProgressBar"
import PropTypes from "prop-types"
import classNames from "classnames"

const ChartElementBodyAtom = ({
  actionComponent,
  headerComponent,
  progress,
  isPartialPartner,
}) => (
  <div className="oc-box">
    <div
      className={classNames("oc-side", {
        "oc-side--partial-partner": isPartialPartner,
      })}
    >
      {actionComponent}
    </div>
    <div className="oc-container">
      {headerComponent}
      <div className="oc-content">
        <ProgressBar chart progress={progress} />
      </div>
    </div>
  </div>
)

ChartElementBodyAtom.propTypes = {
  actionComponent: PropTypes.node.isRequired,
  headerComponent: PropTypes.node.isRequired,
  progress: PropTypes.number.isRequired,
  isPartialPartner: PropTypes.bool,
}

ChartElementBodyAtom.defaultProps = {
  isPartialPartner: false,
}

export default ChartElementBodyAtom
