export const BOTTOM_LEGEND = {
  position: "bottom",
}

export const RIGHT_LEGEND = {
  position: "right",
}

export const HIDDEN_LEGEND = {
  display: false,
}
