import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Label } from "@shopify/polaris"
import GlossaryHover from "components/widgets/CollectPage/GlossaryHover"

/**
 * @description  This function checks if the label needs a Trans component
 * (in order to have a hoverable glossary word)
 * or just a regular t() function
 * @param {string} translationPrefix
 * @param {boolean} isExternalLabel
 * @param {string} id
 * @returns Either a Label containing Trans component, a Label containing string, a Trans, or a string
 */
export function checkHover(
  translationPrefix,
  isExternalLabel = false,
  id = ""
) {
  const { t } = useTranslation()
  let components = []

  let { label, hover, precision } = t(translationPrefix.slice(0, -1), {
    returnObjects: true,
  })

  label = label ?? ""
  precision = !precision ? "" : precision + " "

  // we check if the question in the collect JSON has a 'hover' entry containing an object, which means that the question contains hoverable glossary words
  if (hover) {
    //if so, we iterate through the 'hover' entries in order to pass them as props to new GlossaryHover components. We push these to the 'components' array
    Object.entries(hover).forEach(([key, value]) => {
      components.push(
        <GlossaryHover replace={value.replace} entry={value.glossary_entry} />
      )
    })
    //we can now pass the 'components' array as a prop to a Trans component
    //we also check if the component that needs a hover is an ExternalLabel, because for this component, Trans has to be a children of Label
    if (isExternalLabel)
      return [
        <Label key={id} id={id}>
          {
            (precision,
            (
              <Trans
                i18nKey={translationPrefix + "label"}
                components={components}
              />
            ))
          }
        </Label>,
      ]
    else
      return [
        precision,
        <Trans
          key={1}
          i18nKey={translationPrefix + "label"}
          components={components}
        />,
      ]
  }
  //else, we just return the t function (as a children of a Label component if it is for an ExternalLabel)
  const precisionAndLabel = precision + label

  return isExternalLabel ? (
    <Label id={id}>{precisionAndLabel}</Label>
  ) : (
    precisionAndLabel
  )
}

/**
 * @description This function displays hoverable glossary words inside the Score page charts
 * @param {Object} content
 * @param {string} textOrSubtext
 * @returns Trans component or string
 */
export function checkHoverScore(
  content,
  textOrSubtext = "text",
  noSubtextHover = false
) {
  const { t } = useTranslation()
  let components = []
  const hasHover =
    typeof t(content.translationPrefix_chartX + "hover", {
      returnObjects: true,
    }) === "object"
  if (hasHover) {
    const hoverObject = t(content.translationPrefix_chartX + "hover", {
      returnObjects: true,
    })
    Object.entries(hoverObject).forEach(([key, value]) => {
      components.push(
        <GlossaryHover replace={value.replace} entry={value.glossary_entry} />
      )
    })

    /* Avoid returning a trans component if there is a subtext that doesn't need hover but is in a chart with hoverable components */
    if (textOrSubtext === "subtext" && noSubtextHover) {
      return content.reference_text
    }
    return (
      <Trans
        i18nKey={content.translationPrefix_chartX + textOrSubtext}
        components={components}
      />
    )
  } else if (textOrSubtext === "subtext") return content.reference_text
  return content.kpi_text
}
