import { Layout } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"

import "stylesheets/CollectPage.scss"
import "stylesheets/ObjectiveTabs.scss"
import ThemeLayoutOrganism from "views/organisms/themes/ThemeLayoutOrganism"
import SDGsObjectiveOrganism from "views/organisms/objectives/SDGsObjectiveOrganism"
import TabsObjectiveMolecule from "views/organisms/objectives/TabsObjectiveOrganism"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import { PILLARS } from "utils/constants/pillars"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import WaitTagSeleniumAtom from "views/atoms/selenium/WaitTagSeleniumAtom"

const AnalyzeTemplate = ({
  parentOrganization,
  themeType,
  tabs,
  objectives,
  children,
  pillars,
  periodName,
}) => {
  return (
    <Layout>
      <WaitTagSeleniumAtom />

      <Layout.Section>
        <BreadcrumbsOrganism
          parentOrganization={parentOrganization}
          currentPeriodName={periodName}
        />
      </Layout.Section>

      <Layout.Section>
        <PillarOrganism pillars={pillars} />

        <ThemeLayoutOrganism
          tabs={<TabsObjectiveMolecule tabs={tabs} themeType={themeType} />}
          objectives={
            <SDGsObjectiveOrganism
              objectives={objectives}
              themeType={themeType}
            />
          }
          themeType={themeType}
        >
          {children}
        </ThemeLayoutOrganism>
      </Layout.Section>
    </Layout>
  )
}

AnalyzeTemplate.propTypes = {
  parentOrganization: PropTypes.array,
  periodName: PropTypes.string,

  tabs: PropTypes.array,
  objectives: PropTypes.array,
  pillars: PropTypes.array,

  children: PropTypes.element,

  themeType: PropTypes.oneOf(PILLARS).isRequired,

  pillarsFrameHidden: PropTypes.bool,
}

AnalyzeTemplate.defaultProps = {
  parentOrganization: [],
  tabs: [],
  objectives: [],
  pillars: [],
}

export default AnalyzeTemplate
