import React from "react"
import "stylesheets/blocs/atoms/forms/collect/title.scss"
import PropTypes from "prop-types"

const TitleFormCollect = ({ text }) => {
  return <h1 className="collect__form__title">{text}</h1>
}

export default TitleFormCollect

TitleFormCollect.propTypes = {
  text: PropTypes.string.isRequired,
}
