import React from "react"
import { useTranslation } from "react-i18next"
import DoughnutChartAtom from "views/atoms/charts/doughnuts/DoughnutChartAtom"
import PropTypes from "prop-types"

const EquipmentTypeDoughnutChartAtom = ({
  colorsByLabel,
  equipmentTypes,
  unitType,
  title,
}) => {
  const { t } = useTranslation()

  const data = {
    doughnut_charts_data_total: equipmentTypes.doughnut_charts_data_total,
    doughnut_charts: equipmentTypes.doughnut_charts.map(({ data, label }) => ({
      data,
      label: t(`common:equipment_types.${label}`),
      color: colorsByLabel[label],
    })),
  }

  return (
    <DoughnutChartAtom title={title} data={data} unitType={unitType} large />
  )
}

EquipmentTypeDoughnutChartAtom.propTypes = {
  colorsByLabel: PropTypes.object,
  equipmentTypes: PropTypes.object,
  unitType: PropTypes.string,
  title: PropTypes.string,
}

EquipmentTypeDoughnutChartAtom.defaultProps = {
  unitType: "count",
  title: "",
  equipmentTypes: {
    doughnut_charts: [],
    doughnut_charts_data_total: { number: 0, unit: "" },
  },
  colorsByLabel: {},
}

export default EquipmentTypeDoughnutChartAtom
