import NumberFormatterApp from "services/apps/numberFormatterApp"
import { useTranslation } from "react-i18next"

const useConversion = ({ baseUnit, baseValue, unitType }) => {
  const { t } = useTranslation()

  const [convertedValue, convertedUnit] = NumberFormatterApp.getNumberFormatter(
    baseValue,
    unitType,
    baseUnit
  )

  const convert = (value) =>
    Math.round(
      NumberFormatterApp.getNumberByUnitFormatter(
        value,
        unitType,
        baseUnit,
        convertedUnit
      )[0]
    )

  return {
    convertedValue,
    convertedUnit,
    unitLabel: t(`units:units.${unitType}.${convertedUnit}`),
    convert,
  }
}

export default useConversion
