import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import UsersEntity from "services/entities/UsersEntity"
import TableMemberListOrganism from "views/organisms/organization/tables/TableMemberListOrganism"
import { Modal } from "@shopify/polaris"
import OrganizationMembersFormMolecule from "views/molecules/forms/organizations/OrganizationMembersFormMolecule"
import Loading from "components/widgets/Loading"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"

const MemberListModalOrganism = ({ nodeId, isExternal, disabled }) => {
  const [members, setMembers] = useState([])
  const [membersToAdd, setMembersToAdd] = useState([])
  const [isAddingMember, setIsAddingMember] = useState(false)
  const [error, setError] = useState(null)
  const [isMembersFetching, setIsMemberFetching] = useState(false)
  const [isAvailableMemberFetching, setIsAvailableMemberFetching] = useState(
    false
  )
  const userEntity = useEntity(UsersEntity)

  const { t } = useTranslation()

  const getMembers = async () => {
    setIsMemberFetching(true)
    const response = await userEntity.getNodeUsers(nodeId)
    setMembers(response.data)
    setIsMemberFetching(false)
  }

  const getAvailableMembers = async () => {
    setIsAvailableMemberFetching(true)
    const response = await userEntity.getNodeAvailableUsers(nodeId)
    setMembersToAdd(response.data)
    setIsAvailableMemberFetching(false)
  }

  useEffect(getMembers, [])

  useEffect(getAvailableMembers, [])

  const handleSubmit = async (userId, role) => {
    try {
      if (userId && role) {
        setIsAddingMember(true)
        await userEntity.addUserToNode(nodeId, userId, role)

        await getMembers()
        await getAvailableMembers()
        setError(null)
      }
    } catch (err) {
      if (err.isAxiosError) {
        switch (err.response.status) {
          case 404:
            setError(t("activation:common.members.errors.not_found"))
            break
          default:
            setError(t("activation:common.members.errors.default"))
            break
        }
      } else {
        setError(t("activation:common.members.errors.default"))
      }
    } finally {
      setIsAddingMember(false)
    }
  }

  const handleRemoveMember = async (userId) => {
    await userEntity.removeUserFromNode(nodeId, userId)

    await getMembers()
    await getAvailableMembers()
  }

  return (
    <>
      <Modal.Section>
        {!disabled && (
          <OrganizationMembersFormMolecule
            isLoading={isAddingMember || isAvailableMemberFetching}
            onSubmit={handleSubmit}
            members={membersToAdd}
            error={error}
          />
        )}
      </Modal.Section>
      <Modal.Section>
        {isMembersFetching ? (
          <Loading />
        ) : (
          <TableMemberListOrganism
            members={members}
            onRemove={handleRemoveMember}
            isExternal={isExternal}
            disabled={disabled}
          />
        )}
      </Modal.Section>
    </>
  )
}

MemberListModalOrganism.propTypes = {
  isExternal: PropTypes.bool,
  nodeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

MemberListModalOrganism.defaultProps = {
  disabled: false,
  isExternal: false,
}

export default MemberListModalOrganism
