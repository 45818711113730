import React from "react"
import PropTypes from "prop-types"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import ChartSDG13AccessibilityErrorsMolecule from "views/molecules/canvases/charts/websites/ChartSDG13AccessibilityErrorsMolecule"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import { convertValueToReadable } from "services/units/valueConversion"
import { BYTE_UNITS, KB_UNIT } from "services/units/units"
import { round } from "services/units/round"
import ChartAnalyzeWebsiteBpCompliance from "views/molecules/canvases/charts/websites/ChartAnalyzeWebsiteBpCompliance"
import ChartSDG13WebsitesGhgMolecule from "views/molecules/canvases/charts/websites/ChartSDG13WebsitesGhgMolecule"
import ChartAnalyzeWebsiteAccessibilityCompliance from "views/molecules/canvases/charts/websites/ChartAnalyzeWebsiteAccessibilityCompliance"
import ChartAnalyzeWebsiteSplitPerItDomain from "views/molecules/canvases/charts/websites/ChartAnalyzeWebsiteSplitPerItDomain"
import ChartBadge from "views/atoms/badges/ChartBadge"

const WebsiteAnalyzeOrganism = ({ envScore, websiteId }) => {
  const { t } = useTranslation()
  const chart = envScore?.websites_charts?.charts[websiteId]

  if (!chart) {
    return null
  }

  const kpi3 = () => {
    const convertedByteValue = convertValueToReadable(
      chart?.ghg_website_kB_size,
      KB_UNIT,
      BYTE_UNITS,
      (v) => round(v, 2)
    )

    const badge = (
      <ChartBadge
        value={chart?.ghg_website_kB_size}
        lowerLimit={300}
        upperLimit={3000}
        inverse
      />
    )

    const content = {
      kpi: convertedByteValue.value,
      kpi_unit: t(`units:units.storage.${convertedByteValue.unit}`),
      kpi_text: t("analyze:websites.page_size.title"),
      sub_kpi: chart?.bp_http_request_count,
      sub_kpi_unit: t("analyze:websites.requests.unit"),
      reference: badge,
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`analyze:websites.page_size.help`)}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  return (
    <ITDomainAnalysisLayoutOrganism
      forceMaturity
      maturity={chart?.maturity ?? 0}
      chart1={
        <ChartSDG13WebsitesGhgMolecule
          key={websiteId}
          websiteEmissions={chart?.ghg_period_CO2 / 1000}
          ghgSingleVisitorEmisison={chart?.ghg_average_visit_CO2}
          ghgSingPageEmission={
            chart?.ghg_single_visitor_CO2 === chart?.ghg_average_visit_CO2
              ? null
              : chart?.ghg_single_visitor_CO2
          }
        />
      }
      chart2={
        <ChartSDG13AccessibilityErrorsMolecule
          accessibilityErrors={chart?.accessibility_errors}
        />
      }
      chart3={<ChartAnalyzeWebsiteSplitPerItDomain websiteScore={chart} />}
      chart4={<ChartAnalyzeWebsiteBpCompliance websiteScore={chart} />}
      chart5={kpi3()}
      chart6={
        <ChartAnalyzeWebsiteAccessibilityCompliance websiteScore={chart} />
      }
    />
  )
}

WebsiteAnalyzeOrganism.propTypes = {
  envScore: PropTypes.object.isRequired,
  websiteId: PropTypes.number.isRequired,
}

export default WebsiteAnalyzeOrganism
