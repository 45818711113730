import React, { useCallback, useState } from "react"
import { Icon, Modal, TextContainer } from "@shopify/polaris"
import { SearchMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const RecommendationsModalMolecule = ({ co2eSavings, costSavings }) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  const handleChange = useCallback(() => setActive(!active), [active])

  const activator = (
    <div onClick={handleChange}>
      <Icon source={SearchMajor} color="base" />
    </div>
  )

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={t("improve:recommendations_table.modal.co2e_vs_cost.title")}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              {t("improve:recommendations_table.modal.co2e_vs_cost.co2e.label")}
              :
              <b>
                {` ${co2eSavings} ${t(
                  "improve:recommendations_table.modal.co2e_vs_cost.co2e.unit"
                )}`}
              </b>
              {` ${t(
                "improve:recommendations_table.modal.co2e_vs_cost.co2e.frequency"
              )} `}
            </p>
            <p>
              {t("improve:recommendations_table.modal.co2e_vs_cost.cost.label")}
              :
              <b>
                {` ${costSavings} ${t(
                  "improve:recommendations_table.modal.co2e_vs_cost.cost.unit"
                )}`}
              </b>
              {` ${t(
                "improve:recommendations_table.modal.co2e_vs_cost.cost.frequency"
              )} `}
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}

RecommendationsModalMolecule.propTypes = {
  co2eSavings: PropTypes.number.isRequired,
  costSavings: PropTypes.number.isRequired,
}

export default RecommendationsModalMolecule
