import { Checkbox } from "@shopify/polaris"
import { ExternalLabel } from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import { Module } from "components/widgets/CollectPage/forms/Module"

const CheckboxTableField = ({ id, headerIds, rows, disabler, modules }) => {
  const { t } = useTranslation()
  const { formData, setFormData, translationPath } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const translationPrefix = `collect:questions.${translationPath}.${id}.`

  const titleMarkup = useCallback(() => {
    const title = t(translationPrefix + "label", "")
    return title == "" ? null : (
      <ExternalLabel
        translationPathName={id}
        translationPath={translationPath}
      />
    )
  }, [])

  const generateHeaderMarkup = useCallback(() => {
    let items = [<th key="header-base"></th>]
    headerIds.forEach((headerId, index) => {
      items.push(
        <th key={`header-${index}`}>
          {t(`${translationPrefix}headers.${headerId}`)}
        </th>
      )
    })
    return (
      <thead>
        <tr>{items}</tr>
      </thead>
    )
  }, [headerIds])

  const generateRowsMarkup = useCallback(() => {
    const items = rows.map((row, index) => {
      const key = `row-${index}`
      const title = t(`${translationPrefix}rows.${row.title}`)
      const checkboxes = row.fields.map((field, checkBoxIndex) => {
        const key = `field-${checkBoxIndex}`
        if (field === null) {
          return <td key={checkBoxIndex}></td>
        }

        const disabled = isLocked || (disabler && disabler(field))

        return (
          <td key={key} className="centered">
            <Module module={modules[checkBoxIndex]}>
              <Checkbox
                checked={formData[field]}
                onChange={(value) =>
                  setFormData({ ...formData, [field]: value })
                }
                disabled={disabled}
                label={""}
              />
            </Module>
          </td>
        )
      })

      return (
        <tr key={key}>
          <td>{title}</td>
          {checkboxes}
        </tr>
      )
    })
    return <tbody>{items}</tbody>
  }, [rows, formData])

  return (
    <div>
      {titleMarkup()}
      <table>
        {generateHeaderMarkup()}
        {generateRowsMarkup()}
      </table>
    </div>
  )
}

CheckboxTableField.propTypes = {
  id: PropTypes.string.isRequired,
  headerIds: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.object),
  disabler: PropTypes.func,
  modules: PropTypes.arrayOf(PropTypes.string),
}

CheckboxTableField.defaultProps = {
  headerIds: [],
  rows: [],
  disabler: null,
  modules: [],
}

export default CheckboxTableField
