import ScoreIcon from "components/widgets/ScoreIcon"
import ScoreValue from "components/widgets/ScoreValue"
import PropTypes from "prop-types"
import React from "react"
import "stylesheets/ScorePage/ScoreWidget.scss"
import classNames from "classnames"
import { PILLARS } from "utils/constants/pillars"

const ScoreWidget = ({
  maturity,
  hasIcon = true,
  isCentered = true,
  pillarId,
}) => {
  return (
    <div
      id="score"
      className={classNames(pillarId, `Verdikt-ScoreWidget`, {
        centered: isCentered,
      })}
    >
      <ScoreValue score={maturity} pillarId={pillarId} />
      {!hasIcon ? null : <ScoreIcon score={maturity} pillarId={pillarId} />}
    </div>
  )
}

ScoreWidget.propTypes = {
  hasIcon: PropTypes.bool,
  isCentered: PropTypes.bool,
  maturity: PropTypes.number.isRequired,
  pillarId: PropTypes.oneOf(PILLARS),
}

export default ScoreWidget
