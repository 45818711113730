import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Form, FormLayout, Select } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import ConfirmationModal from "components/widgets/ActivationPage/ConfirmationModal"
import ParentSelectionField from "components/widgets/Dashboard/OrganizationChart/ParentSelectionField"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import { useAppContext } from "components/AppContext"
import { useNodeContext } from "views/organisms/interactive/NodeContext"

const ParentSelectionForm = ({ onClick, nodeTypes, isRoot }) => {
  const { t } = useTranslation()

  const generateItem = (type) => ({
    label: t(`dashboard:chart.types.${type.type}`),
    value: type.type,
    disabled: type.disabled,
  })

  const { setParent } = useNodeContext()

  const [options, setOptions] = useState(nodeTypes?.map(generateItem) || [])

  const [formData, setFormData] = useState({})

  const { currentRootNode } = useAppContext()

  const submit = useCallback(() => {
    setParent(formData.parent, formData.type, isRoot)
    onClick()
  }, [formData])

  useEffect(() => {
    setOptions(nodeTypes?.map(generateItem) || [])

    if (nodeTypes[currentRootNode?.type]?.length === 1) {
      setFormData({ type: nodeTypes[currentRootNode?.type][0] })
    }
  }, [nodeTypes])

  return (
    <Form onSubmit={submit}>
      <FormLayout>
        <Select
          label={t("dashboard:chart.selection_form.type")}
          options={options}
          placeholder={t("dashboard:chart.selection_form.type_placeholder")}
          value={String((formData && formData["type"]) ?? "")}
          onChange={(value) =>
            setFormData({
              ...formData,
              type: value,
              parent: undefined,
            })
          }
          disabled={options.length === 1}
        />

        {!isRoot && (
          <ParentSelectionField
            type={formData.type}
            property="parent"
            value={formData?.parent}
            onChange={(value) => {
              setFormData({ ...formData, parent: value })
            }}
          />
        )}

        {
          // If the parent is a country and is not meant to have sites, open a confirmation modal to inform the user
          // we check siteAssessment === false and not !siteAssessment because we want it to be a boolean, not just falsey ('undefined')
          formData?.parent?.site_assessment === false &&
          formData.type === NODE_TYPE_SITE ? (
            <ConfirmationModal
              actionFunction={submit}
              actionLabel={"confirm"}
            />
          ) : (
            <Button
              submit
              primary={formData.parent}
              disabled={isRoot ? !formData.type : !formData.parent}
            >
              {t("common:confirm")}
            </Button>
          )
        }
      </FormLayout>
    </Form>
  )
}

ParentSelectionForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  isRoot: PropTypes.bool,
}

ParentSelectionForm.defaultProps = {
  isRoot: false,
}

export default ParentSelectionForm
