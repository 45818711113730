import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { Modal, Button, Icon } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { CirclePlusMajor, EditMajor } from "@shopify/polaris-icons"

/**
 * @description This Modal is triggered by a click on the 'Delete' | 'Confirm' button and acts as a confirmation dialog.
 * @param {function} actionFunction Function from parent triggered by the modal action
 * @param {string} actionLabel "delete" | "confirm": For translations and buttons appearance
 * @returns {JSX.Element} A Polaris Modal
 */
const ConfirmationModal = ({ actionFunction, actionLabel }) => {
  const [active, setActive] = useState(false)
  const { t } = useTranslation()

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const executeAndClose = () => {
    actionFunction()
    toggleActive()
  }

  const activator = (
    <Button
      destructive={actionLabel === "delete"}
      primary={actionLabel === "confirm"}
      onClick={toggleActive}
    >
      {t(`common:${actionLabel}`)}
    </Button>
  )

  const secondaryActionsArray = [
    {
      content: t(`common:cancel`),
      onAction: toggleActive,
    },
    {
      content: t(`common:${actionLabel}`),
      onAction: executeAndClose,
      destructive: actionLabel === "delete",
      primary: actionLabel === "confirm",
    },
  ]

  return (
    <Modal
      small
      activator={activator}
      open={active}
      onClose={toggleActive}
      title={t(`common:${actionLabel}`)}
      secondaryActions={secondaryActionsArray}
    >
      <Modal.Section>
        {t(`dashboard:chart.${actionLabel}_button.text`)}
      </Modal.Section>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  actionFunction: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
}

export default ConfirmationModal
