import PropTypes from "prop-types"
import React from "react"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import { STATUS_TO_COLORS } from "utils/constants/analyze/kpi/chartStatus"
import { EQUIPMENT_TYPE_VOLUME_DEFAULT_LEGEND_MODIFIERS } from "./equipmentTypeVolumeDefaults"

/**
 * @param score Api::charts::MixedChartSerializer
 * @param type String - used to display proper chart title
 * @returns {JSX.Element}
 * @constructor
 */

const ChartODD13EquipmentTypeVolumeAverageGHGMolecule = ({
  mixedChart = [],
  type = null,
  afterBody,
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const labels = mixedChart.map((node) => {
    switch (node?.type) {
      case "EquipmentScore": {
        return t(`common:equipment_types.${node.label}`)
      }

      default:
        NodeOrganization.getName(node?.label, node?.type, {
          countryShortName: true,
        })
    }
  })
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const bar_data = {
    label: t(
      translationPrefix + "chartEquipmentTypeVolumeAverageGHG.legend.bar"
    ),
    data: bar,
  }

  const line_data = {
    label: t(
      translationPrefix + "chartEquipmentTypeVolumeAverageGHG.legend.line"
    ),
    data: line,
  }

  const data = { bar_data, line_data }

  const title = type
    ? t(
        translationPrefix +
          "chartEquipmentTypeVolumeAverageGHG.specific_domain_title." +
          type
      )
    : t(translationPrefix + "chartEquipmentTypeVolumeAverageGHG.title")

  const barBackgroundColors = mixedChart.map(
    ({ status }) => STATUS_TO_COLORS[status]
  )

  return (
    <MixedChart
      title={title}
      data_inputs={data}
      labels={labels}
      unit_left_axis={t(
        translationPrefix + "chartEquipmentTypeVolumeAverageGHG.unit_left_axis"
      )}
      unit_right_axis={t(
        translationPrefix + "chartEquipmentTypeVolumeAverageGHG.unit_right_axis"
      )}
      barBackgroundColors={barBackgroundColors}
      legendModifiers={EQUIPMENT_TYPE_VOLUME_DEFAULT_LEGEND_MODIFIERS}
      afterBody={afterBody}
    />
  )
}

ChartODD13EquipmentTypeVolumeAverageGHGMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
  type: PropTypes.string,
  afterBody: PropTypes.func,
}

ChartODD13EquipmentTypeVolumeAverageGHGMolecule.defaultProps = {
  type: null,
  afterBody: () => {},
}

export default ChartODD13EquipmentTypeVolumeAverageGHGMolecule
