import { useTranslation } from "react-i18next"
import CountryApp from "services/apps/countryApp"
import { useAppContext } from "../components/AppContext"

// If update - Make sure to align with REUSABLE_PERSONAL_WORKPLACE_EQUIPMENTS constant in 'domain_generator.rb'
export const reusablePersonalWorkplaceEquipmentTypes = [
  "desktop",
  "laptop",
  "tablet",
  "smartphone",
  "monitor",
  "thin_client",
  "fixed_phone",
]

export const generateCollectedEquipmentName = (equipmentType) => {
  const { t } = useTranslation()
  return t(`common:equipment_types.${equipmentType}`, "Unknown")
}

const DEFAULT_OPTIONS = {
  sorted: true,
  additionalKeys: [],
}

/**
 * Returns a list of all translated countries (default in English)
 * @param options {{sorted: boolean}} Sort countries alphabetically
 * @returns {{label: string, value: *}[]}
 */
export const locations = (options = {}) => {
  const { sorted, additionalKeys } = { ...DEFAULT_OPTIONS, ...options }
  const { countryOptions } = useAppContext()

  let countriesAlpha2Structure = countryOptions.map((alpha2) => ({
    label: CountryApp.getNameI18n(alpha2),
    value: alpha2,
  }))

  if (sorted) {
    countriesAlpha2Structure = countriesAlpha2Structure.sort(
      (countryA, countryB) => countryA.label.localeCompare(countryB.label)
    )
  }

  return additionalKeys.concat(countriesAlpha2Structure)
}
