import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import CalculatorApp from "services/apps/calculatorApp"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const TRANSLATION_PREFIX = "analyze:it_domain.cloud.kpis.emission"

const TextAnalysisCloudGHGEmission = ({ score, hidePercentageOfEmissions }) => {
  const { t } = useTranslation()

  const { unit, value: kpi } = convertValueToReadable(
    score?.cloud_score?.co2e,
    T_CO2E,
    CO2_EMISSION_UNITS,
    round
  )

  const kpi_unit = t(`units:units.emission.${unit}`)

  let content = {
    kpi,
    kpi_unit,
    kpi_text: t(`${TRANSLATION_PREFIX}.title`),
  }

  if (!hidePercentageOfEmissions) {
    content = {
      ...content,
      reference: CalculatorApp.getPercent(
        score?.cloud_score?.co2e,
        score.odd13_CO2,
        {
          round: 0,
        }
      ),
      reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
      reference_unit: t(`score:score.13.chartGHGEmissions.unit`),
    }
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${TRANSLATION_PREFIX}.help`)}>
      <TextChart
        content={<TextChartMajor content={content} noSubtextHover={true} />}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextAnalysisCloudGHGEmission.propTypes = {
  score: PropTypes.object.isRequired,
  hidePercentageOfEmissions: PropTypes.bool,
}

TextAnalysisCloudGHGEmission.defaultProps = {
  hidePercentageOfEmissions: false,
}

export default TextAnalysisCloudGHGEmission
