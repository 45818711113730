import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COMPANY,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_PHASE,
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_REGION,
  NODE_TYPE_SERVICE,
  NODE_TYPE_SERVICE_COMPONENT,
  NODE_TYPE_SITE,
} from "utils/constants/organization/nodeTypes"

export const TYPE_TO_PRIORITY = {
  [NODE_TYPE_COMPANY]: 0,
  [NODE_TYPE_PROGRAM]: 0,
  [NODE_TYPE_PROJECT]: 0,
  [NODE_TYPE_SERVICE]: 0,
  [NODE_TYPE_BRAND]: 1,
  [NODE_TYPE_PHASE]: 1,
  [NODE_TYPE_SERVICE_COMPONENT]: 1,
  [NODE_TYPE_REGION]: 2,
  [NODE_TYPE_COUNTRY]: 3,
  [NODE_TYPE_SITE]: 4,
}

export const getNodeTypePriority = (type) => {
  const priority = TYPE_TO_PRIORITY[type]
  return priority ?? 10
}
