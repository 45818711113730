import { Redirect, useParams } from "react-router-dom"
import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Button, Icon, Modal, TextContainer } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { InfoMinor } from "@shopify/polaris-icons"
import className from "classnames"
import ThemeTabs from "components/widgets/ThemeTabs"
import { OBJECTIVES_THEMES } from "utils/constants/pillars"
import { useCollectContext } from "./CollectContextProvider"
import {
  IMPROVE_MATURITY_PAGE,
  IMPROVE_WEBSITES_PAGE,
} from "utils/constants/improve/pages"

const ThemesFrame = ({ pageName, children }) => {
  const { t } = useTranslation()

  const { progress, isCollectLocked } =
    pageName === "collect" ? useCollectContext() : {}

  const { pillarId, objectiveId, themeId } = useParams()
  const themes = OBJECTIVES_THEMES[objectiveId]
  const [isModalOpen, setModalOpen] = useState(false)

  const TextToFormat = (text) =>
    text.split("\n").map((value, index) => {
      return (
        <span key={index}>
          {value}
          <br />
        </span>
      )
    })

  const toggleModalOpen = useCallback(() => {
    setModalOpen(!isModalOpen)
  }, [isModalOpen])

  const modalMarkup = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={toggleModalOpen}
        title={t(
          `collect:pillars.${pillarId}.objectives.${objectiveId}.themes.${themeId}.title`
        )}
      >
        <Modal.Section>
          <div>
            <TextContainer>
              <p>
                {TextToFormat(
                  t(
                    `collect:pillars.${pillarId}.objectives.${objectiveId}.themes.${themeId}.description`
                  )
                )}
              </p>
            </TextContainer>
          </div>
        </Modal.Section>
      </Modal>
    )
  }

  const renderModalButton = (pageName) => {
    if (
      pageName === "analyze" ||
      pageName === IMPROVE_MATURITY_PAGE ||
      pageName === IMPROVE_WEBSITES_PAGE
    ) {
      return null
    }
    return (
      <div align="right" className={className(pillarId)}>
        <Button
          plain
          onClick={() => {
            toggleModalOpen()
          }}
        >
          <Icon source={InfoMinor} />
        </Button>
      </div>
    )
  }

  /**
   * @description Loads the theme tabs
   * When we click on an objective tab and the component loads for the first time,
   * themeId will be 'undefined' because we haven't clicked on a theme tab yet,
   * so we automatically redirect to the first theme tab.
   * Except for the improve page, because we want to be able to see a summary of recommendations per objective,
   * before clicking on a theme tab to see the detailed recommendations per theme.
   * @return a div containing the Theme tabs
   */
  const themeMarkup = () => {
    if (
      pageName !== "analyze" &&
      pageName !== IMPROVE_MATURITY_PAGE &&
      pageName !== IMPROVE_WEBSITES_PAGE &&
      !themes.includes(themeId)
    ) {
      // TODO: Dynamically load default theme
      return (
        <Redirect to={`/${pageName}/${pillarId}/${objectiveId}/${themes[0]}`} />
      )
    }
    return (
      <>
        <ThemeTabs
          pageName={pageName}
          pillar={pillarId}
          objective={objectiveId}
          theme={themeId}
          progress={isCollectLocked ? null : progress}
        />
        {renderModalButton(pageName)}
        <div className="themesContent">{children}</div>
      </>
    )
  }

  return (
    <>
      {modalMarkup()}
      {themeMarkup()}
    </>
  )
}

ThemesFrame.propTypes = {
  children: PropTypes.any.isRequired,
  pageName: PropTypes.string.isRequired,
}

export default ThemesFrame
