export const EMISSION_UNIT = "emission"
export const ENERGY_UNIT = "energy"
export const STORAGE_UNIT = "storage"
export const COUNT_UNIT = "count"
export const VOLUME_UNIT = "volume"

export const G_UNIT = "g_unit"
export const KG_UNIT = "kg_unit"
export const T_UNIT = "t_unit"

export const MWH_UNIT = "mwh_unit"
export const KWH_UNIT = "kwh_unit"
