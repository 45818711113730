import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import ChartODD13RegionWorkplaceBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionWorkplaceBreakdownMolecule"
import ChartODD13RegionNetworkBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionNetworkBreakdownMolecule"
import ChartODD13RegionHostingBreakdownMolecule from "views/molecules/canvases/charts/ChartODD13RegionHostingBreakdownMolecule"
import TextChartODD13VolumeGHGTelcoMolecule from "views/molecules/canvases/charts/TextChartODD13VolumeGHGTelcoMolecule"
import TextChartODD13AverageEquipmentsPerFteMolecule from "views/molecules/canvases/charts/TextChartODD13AverageEquipmentsPerFteMolecule"
import ChartODD13EquipmentTypeVolumeAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeAverageGHGMolecule"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const TransversalITDomainAnalysisOrganism = ({ score }) => {
  const { t } = useTranslation()

  const chart3Dispatcher = () => {
    if (score.odd13_global_node_children_defined) {
      return (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.transversal.chartRegionHostingBreakdown.help`
          )}
        >
          <ChartODD13RegionHostingBreakdownMolecule score={score} />
        </HelpWidgetChartInteractiveMolecule>
      )
    } else {
      return score?.odd13_business_equipment_type_average ? (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.per_node.workplace.chartRegionWorkplaceBreakdown.help`
          )}
        >
          <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
            mixedChart={score?.odd13_business_equipment_type_average}
            type="business"
          />
        </HelpWidgetChartInteractiveMolecule>
      ) : (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.per_node.hosting.chartRegionHostingBreakdown.help`
          )}
        >
          <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
            mixedChart={
              score?.odd13_internal_hosting_volume_emission_equipments
            }
            type="hosting"
          />
        </HelpWidgetChartInteractiveMolecule>
      )
    }
  }

  const chart4Dispatcher = () => {
    if (score.odd13_global_node_children_defined) {
      return (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.transversal.chartRegionWorkplaceBreakdown.help`
          )}
        >
          <ChartODD13RegionWorkplaceBreakdownMolecule score={score} />
        </HelpWidgetChartInteractiveMolecule>
      )
    } else {
      return (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.per_node.workplace.chartRegionWorkplaceBreakdown.help`
          )}
        >
          <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
            mixedChart={score?.odd13_workplace_equipment_type_average}
            type="workplace"
          />
        </HelpWidgetChartInteractiveMolecule>
      )
    }
  }

  const chart6Dispatcher = () => {
    if (score.odd13_global_node_children_defined) {
      return (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.transversal.chartRegionNetworkBreakdown.help`
          )}
        >
          <ChartODD13RegionNetworkBreakdownMolecule score={score} />
        </HelpWidgetChartInteractiveMolecule>
      )
    } else {
      return (
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.per_node.chartRegionNetworkBreakdown.help`
          )}
        >
          <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
            mixedChart={score?.odd13_network_volume_emission_equipment}
            type="network"
          />
        </HelpWidgetChartInteractiveMolecule>
      )
    }
  }

  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_it_domain_co2}
        />
      }
      chart2={
        <TextChartODD13VolumeGHGTelcoMolecule
          telcoAveragePerFte={+score?.odd13_telco_co2_per_fte}
          volumeAveragePerFte={+score?.odd13_volume_of_data_per_fte}
        />
      }
      chart3={chart3Dispatcher()}
      chart4={chart4Dispatcher()}
      chart5={<TextChartODD13AverageEquipmentsPerFteMolecule score={score} />}
      chart6={chart6Dispatcher()}
    />
  )
}

TransversalITDomainAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default TransversalITDomainAnalysisOrganism
