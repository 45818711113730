import React from "react"
import PropTypes from "prop-types"
import { ActionList, Popover, TopBar } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const CompareSearch = ({ search }) => {
  const { t } = useTranslation()

  const {
    nodes,
    isError,
    onFocus,
    searchValue,
    searchListId,
    isSearchActive,
    handleSearchChange,
    handleSearchResultsDismiss,
  } = search

  const activator = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder={t("common:topbar.search")}
      showFocusBorder={false}
      onFocus={onFocus}
      focused={isSearchActive}
    />
  )

  const noResultsAction = [
    {
      content: t("common:topbar.noResults"),
      disabled: true,
    },
  ]

  const errorAction = [
    {
      content: t("common:topbar.searchError"),
      disabled: true,
    },
  ]

  const displayedNodes = nodes.length ? nodes : noResultsAction

  return (
    <div className="compareSearchOrganism__search">
      <Popover
        fluidContent
        active={isSearchActive}
        preventAutofocus
        activator={activator}
        onClose={handleSearchResultsDismiss}
      >
        <ActionList
          actionRole={searchListId}
          items={isError ? errorAction : displayedNodes}
        />
      </Popover>
    </div>
  )
}

CompareSearch.propTypes = {
  search: PropTypes.object.isRequired,
}

export default CompareSearch
