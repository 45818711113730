import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import { FormLayout } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"
import { fieldIsFalsyOrNonPositive } from "components/widgets/CollectPage/useDisablingFields"

export const teamConfigGenerator = (prefix) => ({
  fields: [
    `${prefix}_team_count`,
    `${prefix}_female_percentage`,
    `${prefix}_internal_percentage`,
    `${prefix}_field_percentage`,
  ],
  disablingConfig: {
    disablingField: `${prefix}_team_count`,
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      `${prefix}_female_percentage`,
      `${prefix}_internal_percentage`,
      `${prefix}_field_percentage`,
    ],
  },
})

const TeamCompositionFields = ({ prefix, withoutField }) => (
  <FormLayout>
    <TextField id={`${prefix}_team_count`} type="number" />
    <FormLayout.Group>
      <TextField id={`${prefix}_female_percentage`} type="number" />
      <TextField id={`${prefix}_internal_percentage`} type="number" />
    </FormLayout.Group>
    {!withoutField && (
      <TextField id={`${prefix}_field_percentage`} type="number" />
    )}
    <hr />
  </FormLayout>
)

TeamCompositionFields.propTypes = {
  prefix: PropTypes.string.isRequired,
  withoutField: PropTypes.string,
}

TeamCompositionFields.defaultProps = {
  withoutField: false,
}

export default TeamCompositionFields
