import { useCallback } from "react"

const DEV_HOSTS = ["localhost", "verdikt-web-dev.herokuapp.com"]
const TEST_HOSTS = ["verdikt-web-test.herokuapp.com"]
const DEMO_HOSTS = ["verdikt-web-demo.herokuapp.com"]
const PREPROD_PROD_HOSTS = [
  "verdikt-web-preprod.herokuapp.com",
  "verdikt-web-production.herokuapp.com",
  "app.verdikt.io",
]
const PROD_HOSTS = [
  "verdikt-web-test.herokuapp.com",
  "verdikt-web-preprod.herokuapp.com",
  "verdikt-web-production.herokuapp.com",
  "app.verdikt.io",
]
const ALL_HOSTS = DEV_HOSTS + TEST_HOSTS + DEMO_HOSTS + PROD_HOSTS

export const PROFIT_PILLAR_FEATURE = "profit"
export const SIMULATE_FEATURE = "simulation"
export const GHG_RECOMMENDATION_FEATURE = "ghgRecommendation"
export const OFFICE_365_FEATURE = "office365"
export const REPLAY_ALWAYS_VISIBLE = "replayAlwaysVisible"
export const FLEX_INVENTORY_FEATURE = "flexInventory"
export const HIDE_PROVIDER_MATURITY_FEATURE = "hideProviderMaturity"
export const PROFIT_RECOMMENDATION_FEATURE = "profitRecommendation"
export const OVERVIEW_FEATURE = "overview"

const FEATURES_TO_HOSTS = {
  [SIMULATE_FEATURE]: DEV_HOSTS,
  [GHG_RECOMMENDATION_FEATURE]: DEV_HOSTS,
  [OFFICE_365_FEATURE]: ALL_HOSTS,
  [REPLAY_ALWAYS_VISIBLE]: DEV_HOSTS + DEMO_HOSTS,
  [FLEX_INVENTORY_FEATURE]: ALL_HOSTS,
  [HIDE_PROVIDER_MATURITY_FEATURE]: PREPROD_PROD_HOSTS + DEMO_HOSTS,
  [PROFIT_RECOMMENDATION_FEATURE]: [...DEV_HOSTS, ...TEST_HOSTS, ...DEMO_HOSTS],
  [OVERVIEW_FEATURE]: DEV_HOSTS + TEST_HOSTS,
}

export const useFeatureFlag = () => {
  const hostname = window.location.hostname

  const isFeatureActive = useCallback(
    (feature) =>
      FEATURES_TO_HOSTS[feature] &&
      FEATURES_TO_HOSTS[feature].includes(hostname),
    []
  )

  const ifFeatureActive = (feature, component, fallback = null) =>
    isFeatureActive(feature) ? component : fallback

  return { isFeatureActive, ifFeatureActive }
}
