import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { SentryRoute } from "services/apps/sentry"

const ProtectedRoute = ({ disabled, render, ...props }) => (
  <SentryRoute
    {...props}
    render={() => (disabled ? <Redirect to="/organize" /> : render())}
  />
)

ProtectedRoute.propTypes = {
  disabled: PropTypes.bool,
  render: PropTypes.func.isRequired,
}

ProtectedRoute.defaultProps = {
  disabled: false,
}

export default ProtectedRoute
