import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"

const getLabel = (label) => {
  if (label.version_name) {
    return label.version_name
  }

  return label.name
}

const ChartODD13EmissionByMonth = ({ score }) => {
  const { t } = useTranslation()

  const generateMonthLabel = ({ label, year }) => {
    if (label) {
      return (
        t(`score:score.13.chartEmissionByMonth.months.${label}`) + " " + year
      )
    }

    return year
  }

  const { datasets, periods, unit_key } =
    score?.odd13_emissions_by_month_consolidated || {}

  const unit = t(`units:units.emission.${unit_key}`)

  const generateData = () => {
    const newDatasets = datasets.map((dataset, index) => {
      return {
        label: getLabel(dataset?.label_key),
        data: dataset?.data,
        backgroundColor:
          Config.common.colors[index % Config.common.colors.length],
        unit,
        stack: 0,
      }
    })

    return {
      labels: periods.map((period) => generateMonthLabel(period)),
      datasets: newDatasets,
    }
  }

  const title = t(`score:score.13.chartEmissionsByMonth.title`)

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartEmissionsByMonth.help`)}
    >
      <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
        <h1>{title}</h1>
        <div className="content">
          <Bar
            data={generateData()}
            options={{
              ...DEFAULT_OPTIONS,
              ...yAxes(unit),
            }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13EmissionByMonth.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13EmissionByMonth
