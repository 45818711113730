import React, { useCallback } from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import PropTypes from "prop-types"
import ChartRadarMolecule from "views/molecules/canvases/charts/common/ChartRadarMolecule"
import { useTranslation } from "react-i18next"
import { chart4Options } from "views/organisms/canvases/charts/analyzes/historization/HistorizationAnalyzeChartsOrganism"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import _ from "lodash"
import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import Config from "assets/configs/score"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import { round } from "services/units/round"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import i18n from "i18next"
import { COLORS } from "utils/constants/colors"
import {
  KPI_STATUS_DISABLED,
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"
import { Bar } from "react-chartjs-2"
import classNames from "classnames"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"

const prefixTranslation = "analyze:compare."

const colors = {
  lowest: {
    backgroundColor: "rgba(100, 164, 108, 0.5)",
    borderColor: Config.common.colorsEvaluation.green,
    pointBackgroundColor: Config.common.colorsEvaluation.green,
  },
  medium: {
    backgroundColor: "rgba(255, 218, 32, 0.5)",
    borderColor: Config.common.colorsEvaluation.yellow,
    pointBackgroundColor: Config.common.colorsEvaluation.yellow,
  },
  highest: {
    backgroundColor: "rgba(199, 0, 57, 0.5)",
    borderColor: Config.common.colorsEvaluation.red,
    pointBackgroundColor: Config.common.colorsEvaluation.red,
  },
}

const formatVariationValue = (value) =>
  value > 0 ? `+${round(value)}` : round(value)

const colorByPosition = (hasFirstPosition, hasLastPosition, hasOneNode) => {
  if (hasFirstPosition) return colors.lowest
  if (hasLastPosition && hasOneNode) return colors.medium
  if (hasLastPosition) return colors.highest
  return colors.medium
}

const WebsitesCompareOrganism = ({ comparedScores, comparedNodes }) => {
  const { t } = useTranslation()

  const hasOneNode = comparedNodes.length === 1

  const chart1 = useCallback(() => {
    const { datasets, unit_key } = comparedScores.chart1
    const unit = t(`units:units.emission.${unit_key}`)

    const data = datasets.map(({ name, value }, index) => {
      const hasFirstPosition = index === 0
      const hasLastPosition = index === datasets.length - 1
      return {
        label: compareNodeLabels(name),
        data: value,
        unit,
        color: colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode)
          .borderColor,
      }
    })

    if (!datasets.length) return {}

    return {
      data,
      axesLabel: unit,
    }
  }, [comparedScores])

  const chart2 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart2

    const maturities = datasets
      .map(({ maturity }) => +maturity)
      .sort()
      .reverse()

    const cleaned_maturities = _.uniq(maturities)

    const formattedDatasets = datasets.map(({ label, data, maturity }) => {
      const position = cleaned_maturities.findIndex((m) => m === +maturity)
      const hasFirstPosition = position === 0
      const hasLastPosition = position === cleaned_maturities.length - 1

      return {
        label: compareNodeLabels(label),
        data,
        ...colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode),
      }
    })

    return {
      labels,
      datasets: formattedDatasets,
    }
  }, [comparedScores])

  const kp1 = (
    <TextChart
      content={
        <TextChartMajor
          content={{
            kpi: compareNodeLabels(comparedScores.kpi1.name),
            kpi_text: t(prefixTranslation + "charts.kpi1.title"),
          }}
        />
      }
    />
  )

  const majorGhgEmissionVariationKpi = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: formatVariationValue(comparedScores.kpi2.percentage),
            kpi_unit: "%",
            kpi_text: t(
              "analyze:compare.websites.major_ghg_emission_variation.title"
            ),
            sub_kpi: t(
              `analyze:websites.split_per_it_domain.${comparedScores.kpi2.field}`
            ),
            reference: formatVariationValue(comparedScores.kpi2.value.value),
            reference_unit: (
              <>
                &nbsp;
                <Unit
                  unit={NumberFormatterApp.getI18nUnit(
                    EMISSION_UNIT,
                    comparedScores.kpi2.value.unit
                  )}
                />
              </>
            ),
          }}
        />
      }
    />
  )

  const highestMaturityVariationKpi = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: formatVariationValue(comparedScores.kpi3.percentage),
            kpi_unit: "%",
            kpi_text: t(
              "analyze:compare.websites.highest_maturity_variation.title"
            ),
            sub_kpi: t(
              `analyze:compare.websites.highest_maturity_variation.labels.${comparedScores.kpi3.field}`
            ),
            reference: `${formatVariationValue(
              comparedScores.kpi3.value
            )} / 100`,
          }}
        />
      }
    />
  )

  const chart3Labels = () => [
    {
      text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.lowest"),
      fillStyle: Config.common.colorsEvaluation.green,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
    {
      text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.flat"),
      fillStyle: Config.common.colorsEvaluation.yellow2,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
    {
      text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.highest"),
      fillStyle: Config.common.colorsEvaluation.red,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
  ]
  const chart3statusColors = {
    [KPI_STATUS_UP]: Config.common.colorsEvaluation.green,
    [KPI_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow2,
    [KPI_STATUS_DOWN]: Config.common.colorsEvaluation.red,
    [KPI_STATUS_DISABLED]: Config.common.colorsEvaluation.green,
  }
  const chart3statusColor = (status) => chart3statusColors[status]

  const chart3 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart3
    const unit = t(`units:units.emission.${comparedScores.chart3.unit_key}`)

    const data = datasets.map(({ label_key, data, statuses, percentages }) => {
      return {
        label: t(`analyze:websites.split_per_it_domain.${label_key}`),
        data: percentages,
        tooltipValues: data,
        backgroundColor: statuses.map(chart3statusColor),
        stack: true,
        unit,
        showLabelInData: true,
      }
    })

    const { title, help } = t(
      `analyze:compare.websites.evolution_of_ghg_emissions_distribution`,
      {
        returnObjects: true,
      }
    )

    return (
      <HelpWidgetChartInteractiveMolecule text={help}>
        <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={{ datasets: data, labels: labels.map(compareNodeLabels) }}
              options={{
                ...DEFAULT_OPTIONS,
                ...yAxes("%"),
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    generateLabels: chart3Labels,
                  },
                },
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [comparedScores])

  return (
    <ITDomainAnalysisLayoutOrganism
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={`${prefixTranslation}charts.`}
      chart1={kp1}
      chart2={majorGhgEmissionVariationKpi}
      chart3={
        <BarChart
          title={t(prefixTranslation + "charts.chart1.label")}
          displayYAxe={true}
          dataTitle=""
          {...chart1()}
        />
      }
      chart4={
        <ChartRadarMolecule
          title={t(prefixTranslation + "charts.chart2.label")}
          config={chart2()}
          options={chart4Options}
          pillarId={PILLAR_ENVIRONMENTAL}
        />
      }
      chart5={highestMaturityVariationKpi}
      chart6={chart3()}
    />
  )
}

WebsitesCompareOrganism.propTypes = {
  comparedScores: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array,
}

WebsitesCompareOrganism.defaultProps = {
  disabled: false,
  comparedNodes: [],
}

export default WebsitesCompareOrganism
