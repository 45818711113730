import { CO2_EMISSION_UNITS } from "services/units/units"

const UNIT_CONVERTER_VALUE = 1000
const UPPER_STEP = 1000
const LOWER_STEP = 1

const isUpperUnit = (unit, units) => unit === units[units.length - 1]
const isLowerUnit = (unit, units) => unit === units[0]

const canConvertValuesToUpperUnit = (values, upperStep) =>
  values.every((value) => value >= upperStep)
const canConvertValuesToLowerUnit = (values, lowerStep) =>
  values.some((value) => value < lowerStep)

export const convertValueToReadable = (
  value,
  unit,
  units,
  roundCallback = noRoundCallback
) => {
  if (!units) return { value, unit }

  while (value >= 1000 && !isUpperUnit(unit, units)) {
    const index = units.indexOf(unit)
    unit = units[index + 1]
    value = value / UNIT_CONVERTER_VALUE
  }

  while (value < 1 && !isLowerUnit(unit, units)) {
    const index = units.indexOf(unit)
    unit = units[index - 1]
    value = value * UNIT_CONVERTER_VALUE
  }

  value = roundCallback(value)

  return { value, unit }
}

export const convertValuesToReadable = (
  values,
  unit,
  units,
  roundCallback = noRoundCallback,
  lowerStep = LOWER_STEP,
  upperStep = UPPER_STEP
) => {
  while (
    canConvertValuesToUpperUnit(values, upperStep) &&
    !isUpperUnit(unit, units)
  ) {
    const index = units.indexOf(unit)
    unit = units[index + 1]
    values = values.map((value) => value / UNIT_CONVERTER_VALUE)
  }

  while (
    canConvertValuesToLowerUnit(values, lowerStep) &&
    !isLowerUnit(unit, units)
  ) {
    const index = units.indexOf(unit)
    unit = units[index - 1]
    values = values.map((value) => value * UNIT_CONVERTER_VALUE)
  }

  return {
    values: values.map((value) => roundCallback(value)),
    unit,
  }
}

export const convertEmissionValue = (
  value,
  unit,
  roundCallback = noRoundCallback
) => {
  return convertValueToReadable(value, unit, CO2_EMISSION_UNITS, roundCallback)
}

export const convertValuesToUnit = (
  values,
  unit,
  targetUnit,
  units,
  roundCallback = noRoundCallback
) => {
  if (unit === targetUnit) {
    values = values.map((value) => roundCallback(value))

    return { values, unit }
  }

  while (!isUpperUnit(unit, units) && unit !== targetUnit) {
    values = values.map((value) => value / UNIT_CONVERTER_VALUE)
    unit = units[units.indexOf(unit) + 1]
  }

  while (!isLowerUnit(unit, units) && unit !== targetUnit) {
    values = values.map((value) => value * UNIT_CONVERTER_VALUE)
    unit = units[units.indexOf(unit) - 1]
  }

  values = values.map((value) => roundCallback(value))

  return { values, unit }
}

export const determineUnit = (
  values,
  unit,
  units,
  lowerStep = LOWER_STEP,
  upperStep = UPPER_STEP
) => {
  const { unit: newUnit } = convertValuesToReadable(
    values,
    unit,
    units,
    noRoundCallback,
    lowerStep,
    upperStep
  )

  return newUnit
}

export const noRoundCallback = (value) => value
