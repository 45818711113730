import React, { useEffect, useState } from "react"
import frTranslations from "@shopify/polaris/locales/fr.json"
import enTranslations from "@shopify/polaris/locales/en.json"
import LinkConfig from "configs/LinkConfig"
import CookieBanner from "components/widgets/CookieBanner"
import AppLayoutChild from "components/AppLayoutChild"
import { AppProvider } from "@shopify/polaris"
import LogoLight from "assets/images/logo-light.svg"
import CorporateIT from "assets/images/logo-corporate.png"
import ITprovider from "assets/images/logo-provider.png"
import Logo from "assets/images/logo.svg"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import { COLORS } from "utils/constants/colors"
import { useLocation } from "react-router-dom"
import isCurrentRootPartner from "utils/isCurrentRootPartner"
import "stylesheets/overwrite-polaris.scss"

const theme = {
  colors: {
    topBar: {
      background: COLORS.corporate.primary,
    },
  },
  logo: {
    width: 124,
    topBarSource: LogoLight,
    contextualSaveBarSource: Logo,
    url: "/",
    accessibilityLabel: "Verdikt",
  },
}

const AppConfigProvider = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const [themeStructure, setThemeStructure] = useState(theme)

  const { availableProducts, isLoggedIn, currentRootNode } = useAppContext()
  const isPartner = isCurrentRootPartner(currentRootNode)

  const menuColor = () => {
    if (isPartner) {
      return COLORS.payingPartner.primary
    } else {
      return COLORS.corporate.primary
    }
  }

  useEffect(() => {
    theme.logo.url = "/dashboard"

    setThemeStructure({ ...theme })
  }, [availableProducts, isLoggedIn])

  useEffect(() => {
    if (currentRootNode) {
      theme.logo.width = 264
      currentRootNode.is_provider
        ? (theme.logo.topBarSource = ITprovider)
        : (theme.logo.topBarSource = CorporateIT)
    } else {
      theme.logo.width = 124
    }
    setThemeStructure({ ...theme })
  }, [currentRootNode])

  useEffect(() => {
    theme.colors.topBar.background = menuColor()

    setThemeStructure({
      ...theme,
    })
  }, [currentRootNode, location])

  return (
    <AppProvider
      i18n={{
        frTranslations,
        enTranslations,
        Polaris: {
          ContextualSaveBar: {
            save: t("common:save"),
          },
        },
      }}
      theme={themeStructure}
      linkComponent={LinkConfig}
    >
      <CookieBanner />
      <AppLayoutChild />
    </AppProvider>
  )
}

AppConfigProvider.propTypes = {}

export default AppConfigProvider
