import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Config from "assets/configs/score.js"
import {
  CHART_STATUS_NEUTRAL,
  STATUS_TO_COLORS,
} from "utils/constants/analyze/kpi/chartStatus"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { LIFETIME_LEVER } from "utils/constants/recommendations/propositions"
import { ArrowDownMinor } from "@shopify/polaris-icons"
import { Icon } from "@shopify/polaris"
import { COLORS } from "utils/constants/colors"

const CHART_OPTIONS = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      color: COLORS.white,
      formatter: (value, context) => {
        return context.dataset.label
      },
    },
  },
  scales: {
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "kt.CO2",
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const RecommendationDetailsDashboard = ({ proposition }) => {
  const { t } = useTranslation()

  const helperGetter = (type) => {
    let key = ""
    switch (proposition.lever) {
      case LIFETIME_LEVER:
        key = `${proposition.lever}_${proposition.simulation_detail.equipment_type}`
        break
      default:
        key = proposition.lever
    }

    return t(`recommendation:propositions.graph.helper.${type}.${key}`)
  }

  const datasets = proposition.chart.datasets.map((dataset, index) => {
    const color =
      dataset.status === CHART_STATUS_NEUTRAL
        ? Config.common.disabledColor
        : Config.common.colors[index]

    const afterColor =
      dataset.status === CHART_STATUS_NEUTRAL
        ? Config.common.disabledColor
        : STATUS_TO_COLORS[dataset.status]
    return {
      data: dataset.data,
      backgroundColor: [color, afterColor],
      borderColor: COLORS.white,
      color: [COLORS.white],
      borderWidth: 1,
      fill: false,
      label: t(`common:equipment_types.${dataset.label_key}`),
      stack: 1,
    }
  })

  return (
    <div className="recommendationsLayout__details__dashboard">
      <div
        className={classNames(
          "Verdikt-Score-DoughnutChart",
          proposition.pillar
        )}
      >
        <div className="content">
          <HelpWidgetChartInteractiveMolecule text={helperGetter("chart")}>
            <Bar
              data={{
                labels: proposition.chart.labels.map((label) =>
                  t(`recommendation:propositions.graph.labels.${label}`)
                ),
                datasets,
              }}
              options={CHART_OPTIONS}
            />
          </HelpWidgetChartInteractiveMolecule>
        </div>
      </div>

      <div className={classNames(proposition.pillar)}>
        <HelpWidgetChartInteractiveMolecule text={helperGetter("kpi")}>
          <div className="recommendationsLayout__details__dashboard__kpi">
            <div>{t("recommendation:propositions.graph.helper.kpi.title")}</div>
            <div>
              {`${proposition.kpis.primary.value} ${proposition.kpis.primary.unit}`}
            </div>

            <div>
              <Icon source={ArrowDownMinor} />
              <b>{`${proposition.kpis.secondary.value}${proposition.kpis.secondary.unit} `}</b>
              {`${t(
                "recommendation:propositions.graph.kpi.sub_kpi_text_prefix"
              )} `}
              <b>
                {t("recommendation:propositions.graph.kpi.domain.workplace")}
              </b>
              {` ${t(
                "recommendation:propositions.graph.kpi.sub_kpi_text_suffix"
              )}`}
            </div>
            <div>
              <Icon source={ArrowDownMinor} />
              <b>{`${proposition.kpis.tertiary.value}${proposition.kpis.tertiary.unit}`}</b>
              {` ${t("recommendation:propositions.graph.kpi.reference_text")}`}
            </div>
          </div>
        </HelpWidgetChartInteractiveMolecule>
      </div>
    </div>
  )
}

RecommendationDetailsDashboard.propTypes = {
  proposition: PropTypes.shape({
    pillar: PropTypes.string.isRequired,
    chart: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.number).isRequired,
          label_key: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
        })
      ).isRequired,
      simulation_detail: PropTypes.object.isRequired,
    }).isRequired,
    kpis: PropTypes.shape({
      primary: PropTypes.shape({
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }).isRequired,
      secondary: PropTypes.shape({
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }).isRequired,
      tertiary: PropTypes.shape({
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default RecommendationDetailsDashboard
