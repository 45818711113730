import { useTranslation } from "react-i18next"
import React from "react"
import Form from "../../Form"
import { FormLayout } from "@shopify/polaris"
import { Select, TextField, YesNoRadio } from "../../fields/CollectFields"
import { Module } from "../../Module"
import { PRINTING_MODULE } from "utils/constants/collects/modules"
import { useFormConfig } from "../../FormContext"

const fields = [
  "printing_period_printing_volume",
  "printing_period_cartridge_volume",
  "printing_cartridge_eol",
  "printing_paper_recycle_volume",
  "printing_cartridge_toner_ratio",
  "printing_recto_verso_policy",
  "printing_user_id_policy",
]
const translationPath = "15.printing"

const PrintingForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <Module module={PRINTING_MODULE}>
        <div className="columns">
          <div className="column column">
            <FormLayout>
              <TextField id="printing_period_printing_volume" type="number" />
              <TextField id="printing_period_cartridge_volume" type="number" />
              <TextField id="printing_cartridge_toner_ratio" type="number" />
              <TextField id="printing_paper_recycle_volume" type="number" />
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <YesNoRadio id="printing_user_id_policy" />
              <YesNoRadio id="printing_recto_verso_policy" />
              <Select
                id="printing_cartridge_eol"
                options={[
                  {
                    label: t(
                      "collect:questions.15.printing.printing_cartridge_eol.options.none"
                    ),
                    value: "0",
                  },
                  {
                    label: t(
                      "collect:questions.15.printing.printing_cartridge_eol.options.recycling"
                    ),
                    value: "1",
                  },
                  {
                    label: t(
                      "collect:questions.15.printing.printing_cartridge_eol.options.reconditioning"
                    ),
                    value: "2",
                  },
                  {
                    label: t("collect:questions.common.do_not_know"),
                    value: "3",
                  },
                ]}
              />
            </FormLayout>
          </div>
        </div>
      </Module>
    </Form>
  )
}

export default PrintingForm
