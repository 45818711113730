import React from "react"
import LevelEmblem from "components/widgets/ImprovePage/LevelEmblem"
import { Badge, Icon } from "@shopify/polaris"
import RecommendationsModalMolecule from "views/recommendations/RecommendationsModalMolecule"
import { ArrowDownMinor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { sdg_theme_1_2_11 } from "utils/constants/pillars"

const themesByCdg = {
  "1_2_11": { themes: sdg_theme_1_2_11 },
  1: { themes: sdg_theme_1_2_11 },
  2: { themes: sdg_theme_1_2_11 },
  3: {
    themes: [
      "well_being",
      "positive_impact",
      "attrition",
      "remote_work",
      "alert_system",
    ],
  },
  4: { themes: ["training"] },
  5: { themes: ["parity"] },
  6: { themes: ["water_consumption"] },
  7: { themes: ["energy_consumption", "energy_green"] },
  8: {
    themes: [
      "project_portfolio",
      "projects_process",
      "eco_design",
      "investments",
      "project_governance",
    ],
  },
  9: {
    themes: [
      "innovation_portfolio",
      "structure",
      "innovation_governance",
      "practices",
      "innovation_practices",
    ],
  },
  10: { themes: ["staff", "recruitment", "inclusion", "diversity"] },
  11: { themes: sdg_theme_1_2_11 },
  12: { themes: ["sustainable_consumption", "sustainable_production"] },
  13: {
    themes: [
      "workplace",
      "datacenter_internal",
      "datacenter_external",
      "internal_hosting",
      "external_hosting",
      "network",
      "software",
      "it_team",
      "eco_conception",
      "accessibility",
    ],
  },
  14: { themes: [] },
  15: { themes: ["printing", "electronic_waste", "scarce_resources"] },
  16: {
    themes: ["csr", "cdr", "ethics", "cyber_security", "ai_and_personal_data"],
  },
  17: { themes: ["partnerships_sourcing", "partnerships"] },
}

const Recommendation = ({
  pillar,
  impact,
  complexity,
  recoKey,
  domain,
  data,
  onSelectRecommendation,
  theme,
  objective,
  description,
  sdg,
  additionalBadges,
  resources,
}) => {
  const { t } = useTranslation()

  const themeType = (recommendationKey, themes) => {
    for (const theme of themes) {
      if (recommendationKey?.includes(theme)) {
        return theme
      }
    }
  }

  const getThemeOrSdg = (recommendationKey) => {
    const selectedTheme = themeType(recommendationKey, themesByCdg[sdg].themes)

    if (objective) {
      return t(
        `collect:pillars.${pillar}.objectives.${sdg}.themes.${selectedTheme}.title`
      )
    }
    return `${t("improve:recommendations_table.sdg")} ${sdg}`
  }

  return (
    <tr>
      <td className="recommendations__table__tdSeverityGraph">
        <LevelEmblem pillar={pillar} type="impact" severity={impact} />
      </td>
      <td className="recommendations__table__tdSeverityGraph">
        <LevelEmblem pillar={pillar} type="complexity" severity={complexity} />
      </td>
      <td className="recommendations__table__tdText">
        <section className="recommendations__table__badgeSection">
          <div>
            {/* display a badge with the ODD if we are in pillar scope or with theme if ODD scope */}
            {theme ? null : <Badge>{getThemeOrSdg(recoKey)}</Badge>}
            <Badge>{t(`improve:recommendations_table.domain.${domain}`)}</Badge>
            {additionalBadges}
          </div>
          {!!data && (
            <RecommendationsModalMolecule
              co2eSavings={data.co2eSavings}
              costSavings={data.costSavings}
            />
          )}
          {onSelectRecommendation && (
            <button onClick={onSelectRecommendation}>
              <Icon source={ArrowDownMinor} />
            </button>
          )}
        </section>
        {description}
      </td>
    </tr>
  )
}

Recommendation.propTypes = {
  pillar: PropTypes.string.isRequired,
  impact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  complexity: PropTypes.string.isRequired,
  recoKey: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSelectRecommendation: PropTypes.func,
  theme: PropTypes.string,
  objective: PropTypes.string,
  description: PropTypes.string.isRequired,
  sdg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  resources: PropTypes.array,
}

Recommendation.defaultProps = {
  data: null,
  theme: null,
  onSelectRecommendation: null,
  objective: null,
  resources: [],
}

export default Recommendation
