import React from "react"
import { CheckboxField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import { FormLayout } from "@shopify/polaris"
import PropTypes from "prop-types"

const FIELDS = [
  "cloud_inventory_aws",
  "cloud_inventory_gcp",
  "cloud_inventory_azure",
]

const InventoryChoiceConfig = ({ disabled }) => {
  const { t } = useTranslation()
  return (
    <>
      <FormLayout.Group>
        {t(
          "collect:questions.13.datacenter_external.cloud.provider_choices.label"
        )}
      </FormLayout.Group>
      {FIELDS.map((field) => (
        <FormLayout.Group key={field}>
          <CheckboxField id={field} disabled={disabled} />
        </FormLayout.Group>
      ))}
    </>
  )
}

InventoryChoiceConfig.propTypes = {
  disabled: PropTypes.bool,
}

InventoryChoiceConfig.defaultProps = {
  disabled: false,
}

export default InventoryChoiceConfig
