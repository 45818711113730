import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import Unit from "views/atoms/reports/Unit"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const TextChartODD13GHGEmissionsNetworkEquipmentMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const {
    value,
    unit,
  } = convertValueToReadable(
    score?.odd13_network_emission_equipment_average,
    T_CO2E,
    CO2_EMISSION_UNITS,
    (value) => round(value, 2)
  )

  const content = {
    kpi: value,
    kpi_unit: <Unit unit={NumberFormatterApp.getI18nUnit("emission", unit)} />,
    kpi_text: t(`${translationPrefix}chartGHGEmissionsNetworkEquipment.text`),
  }

  const content_for_display = <TextChartMinor content={content} />
  return <TextChart content={content_for_display} />
}

TextChartODD13GHGEmissionsNetworkEquipmentMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default TextChartODD13GHGEmissionsNetworkEquipmentMolecule
