import React from "react"
import { FormLayout } from "@shopify/polaris"
import { YesNoRadio } from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import TextField from "components/widgets/CollectPage/forms/fields/TextField"
import Select from "components/widgets/CollectPage/forms/fields/Select"
import { POLICY_ETHICS } from "utils/constants/centralization/policyItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const fields = [
  "governance_ethics_budget_share",
  "governance_ethics_dedicated_function",
  "governance_ethics_warning_system",
  "governance_ethics_trainings",
  "governance_ethics_trainings_test",
  "governance_ethics_committee",
  "governance_ethics_representative_in_it",
  "governance_ethics_policy_separation_of_duties",
  "governance_ethics_policy_delegation_of_authority",
]
const translationPath = "16.ethics"

const Objective16EthicsForm = () => {
  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <FormLayout>
        <Module module={POLICY_ETHICS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <TextField id="governance_ethics_budget_share" type="number" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="governance_ethics_dedicated_function" />
                <YesNoRadio id="governance_ethics_committee" />
                <YesNoRadio id="governance_ethics_representative_in_it" />
                <Select
                  id="governance_ethics_trainings"
                  options={["no_one", "excom", "managers", "everyone"]}
                />
                <YesNoRadio id="governance_ethics_trainings_test" />
                <YesNoRadio id="governance_ethics_warning_system" />
                <YesNoRadio id="governance_ethics_policy_separation_of_duties" />
                <YesNoRadio id="governance_ethics_policy_delegation_of_authority" />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default Objective16EthicsForm
