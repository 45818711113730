export const CORPORATE = "corporate"
export const PARTIAL_PARTNER = "partial_partner"
export const PROVIDER = "provider"

export const getProductName = (organization, currentRoot) => {
  if (organization?.is_partial_partner) return PARTIAL_PARTNER
  switch (true) {
    case currentRoot?.is_corporate:
      return CORPORATE
    case currentRoot?.is_provider:
      return PROVIDER
    default:
      return CORPORATE
  }
}
