import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsEmployeeMolecule"
import TextChartODD13MobileDataConsumptionEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13MobileDataConsumptionEmployeeMolecule"
import ChartODD13EquipmentTypeVolumeAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeAverageGHGMolecule"
import ChartODD13EquipmentVolumeEmissionsTopLocMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentVolumeEmissionsTopLocMolecule"
import ChartODD13EquipmentFteTopEmissionsLocMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentFteTopEmissionsLocMolecule"
import TextChartOdd13GhgEmissionTab from "views/molecules/canvases/charts/TextChartODD13GHGEmissionTab"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const WorkplaceAnalysisOrganism = ({ score, isOnGlobalDashboard }) => {
  const { t } = useTranslation()

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_workplace_maturity ?? 0}
      chart1={
        <TextChartOdd13GhgEmissionTab
          ghgEmissions={score?.odd13_workplace_CO2}
          ghgEmissionsCollect={score?.odd13_CO2}
          node={score?.node}
          hidePercentageOfEmissions={isOnGlobalDashboard}
        />
      }
      chart2={<TextChartODD13GHGEmissionsEmployeeMolecule score={score} />}
      chart3={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.workplace.chartVolumeEmissionsTopLoc.help`
          )}
        >
          <ChartODD13EquipmentVolumeEmissionsTopLocMolecule
            mixedChart={score?.odd13_co2_workplace_equipment_volume_ghg_top}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart4={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.workplace.chartEquipmentTypeVolumeAverageGHG.help`
          )}
        >
          <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
            mixedChart={score?.odd13_workplace_equipment_type_average}
            afterBody={function (tooltipItem, data) {
              if (
                tooltipItem[0].xLabel === t("common:equipment_types.accessory")
              ) {
                return score?.accessories
                  .map((accessory) => t(`common:equipment_types.${accessory}`))
                  .join(", ")
              }
            }}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart5={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.workplace.chartMobileDataConsumptionEmployee.help`
          )}
        >
          <TextChartODD13MobileDataConsumptionEmployeeMolecule score={score} />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart6={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:it_domain.consolidated.workplace.chartEquipmentFteTopEmissionsLoc.help`
          )}
        >
          <ChartODD13EquipmentFteTopEmissionsLocMolecule
            mixedChart={score?.odd13_co2_equipment_emissions_top}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
    />
  )
}

WorkplaceAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  isOnGlobalDashboard: PropTypes.bool,
}

WorkplaceAnalysisOrganism.defaultProps = {
  isOnGlobalDashboard: false,
}

export default WorkplaceAnalysisOrganism
