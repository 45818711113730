import React from "react"
import { useAppContext } from "components/AppContext"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import _ from "lodash"
import { SUBSCRIPTION_TYPE_CORPORATE } from "utils/constants/subscriptions"
import {
  NODE_CATEGORY_CORPORATE,
  NODE_CATEGORY_PARTNER,
} from "utils/constants/organization/nodeTypes"
import {
  BANNER_INFO_STATUS,
  BANNER_SUCCESS_STATUS,
} from "utils/constants/alert_banners/alertBannersConstant"
import { useTranslation } from "react-i18next"
import TopBannerMolecule from "views/molecules/banners/TopBannerMolecule"
import NodeOrganization from "services/organizations/NodeOrganization"

const MESSAGE_ID_CURRENT = "current"
const MESSAGE_ID_NO_ACTIVE_SUBSCRIPTION = "no_active_subscription"
const MESSAGE_ID_HAS_NOT_SOMETHING_TO_DO = "has_not_something_to_do"
const MESSAGE_ID_CURRENT_CORPORATE = `no_leaf_node.${NODE_CATEGORY_CORPORATE}`
const MESSAGE_ID_CURRENT_PARTNER = `no_leaf_node.${NODE_CATEGORY_PARTNER}`
const MESSAGE_ID_PARTIAL_PARTNER_CALCULATED_SCORE = `partial_partner_calculated_score`

const MESSAGE_ID_TO_STATUS = {
  [MESSAGE_ID_CURRENT]: BANNER_INFO_STATUS,
  [MESSAGE_ID_NO_ACTIVE_SUBSCRIPTION]: BANNER_INFO_STATUS,
  [MESSAGE_ID_HAS_NOT_SOMETHING_TO_DO]: BANNER_INFO_STATUS,
  [MESSAGE_ID_CURRENT_CORPORATE]: BANNER_INFO_STATUS,
  [MESSAGE_ID_CURRENT_PARTNER]: BANNER_INFO_STATUS,
  [MESSAGE_ID_PARTIAL_PARTNER_CALCULATED_SCORE]: BANNER_SUCCESS_STATUS,
}

const OrganizationPageBanner = () => {
  const { t } = useTranslation()
  const {
    currentRootNode,
    currentNode,
    subscription,
    currentOrganization,
  } = useAppContext()
  const { organization } = useChart()

  const isPartnerNode = (node) =>
    currentRootNode?.organization_id !== node?.organization_id

  const hasALeafWithinCurrentOrg = (branch) =>
    (!!branch.has_collect &&
      branch.organization_id === currentOrganization?.id) ||
    _.some(branch.children, hasALeafWithinCurrentOrg)

  const hasPartnerRootScored = () => {
    const rootPartners = NodeOrganization.findNodesInTree(
      isPartnerNode,
      organization
    )

    return rootPartners && !!rootPartners.every((root) => !!root.score)
  }

  const getBannerMessageId = () => {
    if (!subscription) return MESSAGE_ID_NO_ACTIVE_SUBSCRIPTION
    if (!organization || !hasALeafWithinCurrentOrg(organization))
      return subscription.subscription_type === SUBSCRIPTION_TYPE_CORPORATE
        ? MESSAGE_ID_CURRENT_CORPORATE
        : MESSAGE_ID_CURRENT_PARTNER
    if (!currentNode) return MESSAGE_ID_CURRENT
    if (!currentRootNode.has_something_to_do) return null // formerly MESSAGE_ID_HAS_NOT_SOMETHING_TO_DO
    if (currentOrganization?.is_partial_partner && hasPartnerRootScored())
      return MESSAGE_ID_PARTIAL_PARTNER_CALCULATED_SCORE
  }

  const messageId = getBannerMessageId()

  if (!messageId) return null

  const { title, message } = t(`dashboard:messages.${messageId}`, {
    returnObjects: true,
  })

  return (
    <TopBannerMolecule
      title={title}
      status={MESSAGE_ID_TO_STATUS[messageId]}
      message={message}
    />
  )
}

export default OrganizationPageBanner
