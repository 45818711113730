import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import NodeOrganization from "services/organizations/NodeOrganization"
import PropTypes from "prop-types"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import { ADMIN_ROLE, VIEWER_ROLE } from "utils/constants/userRoles"
import EditNodeOrganism from "views/organisms/interactive/EditNodeOrganism"
import { useAppContext } from "components/AppContext"
import { NodeContextProvider } from "../../organisms/interactive/NodeContext"
import ButtonIconMolecule from "../interactive/ButtonIconMolecule"
import ChartHeaderAtom from "views/molecules/organization/ChartHeaderAtom"
import {
  NODE_TYPE_TO_DEFAULT_DATASET,
  PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET,
} from "utils/constants/forms/nodeFormDataset"
import {
  NODE_TYPE_TO_STANDARD_FORM_FIELDS,
  PROVIDER_NODE_TYPE_TO_FORM_FIELDS,
} from "utils/constants/forms/nodeForms"
import { getTypeForNodeTree } from "services/tree/nodeType"
import DiscardedNodeButtonOrganism from "views/organisms/interactive/DiscardedNodeButtonOrganism"
import { PROVIDER } from "services/apps/product"

const ChartNodeHeaderMolecule = ({ node, onDiscardedToggle, isDiscarded }) => {
  const { organize, reload } = useChart()
  const { subscription, currentRootNode, setToastStatus } = useAppContext()
  const { t } = useTranslation()

  const showDetailModal = () =>
    organize && node?.can_read && [ADMIN_ROLE, VIEWER_ROLE].includes(node.role)

  const getActivator = useCallback(() => {
    return node.can_read
      ? {
          Component: ButtonIconMolecule,
          props: {
            iconName: node.can_edit ? "EditMajor" : "ViewMajor",
          },
          text: null,
        }
      : null
  }, [node])

  const type = getTypeForNodeTree(node.type, currentRootNode)

  const shouldShowDiscardedButton =
    node.organization_category === PROVIDER &&
    !!node.score &&
    !!node.version_name

  const isDisabledDiscardedNode = node.is_scored_parent

  return (
    <ChartHeaderAtom
      title={NodeOrganization.getNodeName(node)}
      versionName={node.version_name}
      subtitle={t(`dashboard:chart.types.${type}`)}
      node={node}
      editComponent={
        showDetailModal() && (
          <NodeContextProvider
            nodeId={node.id}
            defaultDatasets={
              subscription?.is_provider
                ? PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET
                : NODE_TYPE_TO_DEFAULT_DATASET
            }
          >
            <EditNodeOrganism
              organizationNode={node}
              reload={reload}
              activator={getActivator()}
              nodeTypeToFormFields={
                subscription?.is_provider
                  ? PROVIDER_NODE_TYPE_TO_FORM_FIELDS
                  : NODE_TYPE_TO_STANDARD_FORM_FIELDS
              }
            />
            {shouldShowDiscardedButton && (
              <DiscardedNodeButtonOrganism
                isDiscarded={isDiscarded}
                onToastStatus={setToastStatus}
                onClick={onDiscardedToggle}
                disabled={isDisabledDiscardedNode}
              />
            )}
          </NodeContextProvider>
        )
      }
    />
  )
}

ChartNodeHeaderMolecule.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    role: PropTypes.string,
    version_name: PropTypes.string,
    is_scored_parent: PropTypes.bool,
    can_edit: PropTypes.bool.isRequired,
    can_read: PropTypes.bool.isRequired,
    discarded: PropTypes.bool.isRequired,
    organization_category: PropTypes.string.isRequired,
    score: PropTypes.number,
  }).isRequired,
  onDiscardedToggle: PropTypes.func.isRequired,
  isDiscarded: PropTypes.bool.isRequired,
}

export default ChartNodeHeaderMolecule
