import React from "react"
import { useEntity } from "hooks/useAxios"
import ScoreEntity from "services/entities/ScoreEntity"
import ComparePageContentTemplate from "views/templates/ComparePageContentTemplate"
import NodeAnalysesOrganism from "views/organisms/canvases/charts/compare/NodeAnalysesOrganism"

const ComparePage = () => {
  const scoreEntity = useEntity(ScoreEntity)

  return (
    <ComparePageContentTemplate
      compareRequester={scoreEntity.compare}
      CompareScoreComponent={NodeAnalysesOrganism}
    />
  )
}

export default ComparePage
