export const IMPORT_STATUS_COMPLETED = "status_completed"
export const IMPORT_STATUS_ERROR = "status_error"
export const IMPORT_STATUS_UPLOADED = "status_uploaded"
export const IMPORT_STATUS_WORK_IN_PROGRESS = "status_work_in_progress"
export const IMPORT_STATUS_WAITING_FOR_FILE = "waiting_for_file"

export const IMPORT_PROCESSING_STATUSES = [
  IMPORT_STATUS_UPLOADED,
  IMPORT_STATUS_WORK_IN_PROGRESS,
]
