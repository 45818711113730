import React, { useEffect, useState } from "react"
import { Page } from "@shopify/polaris"
import Objective13ITDomainAnalysisOrganism from "views/organisms/canvases/charts/Objective13ITDomainAnalysisOrganism"
import Loading from "components/widgets/Loading"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import AnalyzeTemplate from "views/templates/AnalyzeTemplate"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useHistory, useParams } from "react-router-dom"
import { isNodeLeaf } from "utils/constants/organization/nodeTypes"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import { OFFICE_365_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import usePillars from "views/organisms/pillars/usePillars"
import ScoreLoaderTemplate from "views/templates/ScoreLoaderTemplate"

const AnalyzeITDomainPage = () => {
  const { themeId } = useParams()

  const history = useHistory()
  const { t } = useTranslation()

  const {
    currentPeriod,
    currentNode,
    parentOrganization,
    envScore,
    isEnvScoreLoading,
    isCurrentNodeLoading,
  } = useAppContext()
  const { isFeatureActive } = useFeatureFlag()

  const pillars = usePillars({
    pageName: "/analyze-ghg-scopes",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: "/analyze/it-domain/environmental/13/equipments",
    },
  ]

  const [hideTabsByNodeType, setHideTabsByNodeType] = useState(true)

  useEffect(() => setHideTabsByNodeType(isNodeLeaf(currentNode)), [currentNode])

  const tabs = [
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.equipments.title`
      ),
      hidden: +envScore?.odd13_it_domain_co2 === 0,

      selected: themeId === "equipments",
      url: "/analyze/it-domain/environmental/13/equipments",
    },
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.workplace.title`
      ),
      selected: themeId === "workplace",
      hidden:
        hideTabsByNodeType ||
        !envScore?.odd13_workplace_CO2 ||
        +envScore?.odd13_workplace_CO2 === 0,
      url: "/analyze/it-domain/environmental/13/workplace",
    },
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.datacenter_internal.title`
      ),
      selected: themeId === "datacenter_internal",
      url: "/analyze/it-domain/environmental/13/datacenter_internal",
      hidden:
        hideTabsByNodeType ||
        !envScore?.odd13_datacenter_internal_CO2 ||
        +envScore?.odd13_datacenter_internal_CO2 === 0,
    },
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.network.title`
      ),
      selected: themeId === "network",
      hidden:
        hideTabsByNodeType ||
        !envScore?.odd13_network_CO2 ||
        +envScore?.odd13_network_CO2 === 0,
      url: "/analyze/it-domain/environmental/13/network",
    },
    {
      name: t("analyze:it_domain.consolidated.apps.title"),
      selected: themeId === "apps",
      hidden: !envScore?.apps,
      url: "/analyze/it-domain/environmental/13/apps",
    },
    {
      name: t(`analyze:tabs.cloud`),
      selected: themeId === "cloud",
      hidden: !envScore?.cloud_score,
      url: "/analyze/it-domain/environmental/13/cloud",
    },
    {
      name: t("analyze:it_domain.consolidated.saas.title"),
      selected: themeId === "saas",
      hidden:
        !envScore ||
        !envScore.saas ||
        envScore.odd13_software_CO2_external_apps < 1,
      url: "/analyze/it-domain/environmental/13/saas",
    },
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.office365.title`
      ),
      selected: themeId === "office365",
      hidden: !(
        isFeatureActive(OFFICE_365_FEATURE) && envScore?.o365_emissions
      ),
      url: "/analyze/it-domain/environmental/13/office365",
    },
    {
      name: t(
        `collect:pillars.environmental.objectives.13.themes.websites.title`
      ),
      selected: themeId === "websites",
      hidden:
        !envScore?.website_analyse?.number_of_websites ||
        envScore?.website_analyse?.number_of_websites === 0,
      url: "/analyze/it-domain/environmental/13/websites",
    },
  ]

  useEffect(() => {
    const currentTabAvailable = !!tabs.find(
      (tab) => tab.selected && !tab.hidden
    )

    if (
      !currentTabAvailable &&
      !(isEnvScoreLoading || isCurrentNodeLoading || !envScore)
    ) {
      const availableTab = tabs.find((tab) => !tab.hidden)
      if (availableTab) {
        history.push(availableTab.url)
      }
    }
  }, [themeId, isEnvScoreLoading, isCurrentNodeLoading, envScore])

  if (isEnvScoreLoading || isCurrentNodeLoading) {
    return <Loading />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!envScore}>
        <ScoreLoaderTemplate pillarScore={envScore}>
          <AnalyzeTemplate
            parentOrganization={parentOrganization}
            themeType={PILLAR_ENVIRONMENTAL}
            objectives={objectives}
            pillars={pillars}
            tabs={tabs}
            periodName={currentPeriod?.name}
          >
            <Objective13ITDomainAnalysisOrganism envScore={envScore} />
          </AnalyzeTemplate>
        </ScoreLoaderTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(AnalyzeITDomainPage)
