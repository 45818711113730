import React from "react"
import { FormLayout, Link } from "@shopify/polaris"
import PropTypes from "prop-types"

const ForgottenPasswordLinkMolecule = ({ title, onResetPasswordLeave }) => {
  return (
    <FormLayout.Group>
      <div style={{ textAlign: "right" }}>
        <Link
          onClick={onResetPasswordLeave}
          style={{
            display: "block",
            width: "100%",
            textAlign: "right",
          }}
        >
          {title}
        </Link>
      </div>
    </FormLayout.Group>
  )
}

ForgottenPasswordLinkMolecule.propTypes = {
  title: PropTypes.string.isRequired,
  onResetPasswordLeave: PropTypes.func.isRequired,
}

export default ForgottenPasswordLinkMolecule
