import { COLORS } from "utils/constants/colors"

const SCORE_CONFIG = {
  common: {
    colors: [
      COLORS.corporate.primary,
      COLORS.planet.secondary,
      COLORS.planet.tertiary,
      COLORS.planet.c60,
      COLORS.profit.c40,
      "#4C8981",
      COLORS.planet.c50,
      COLORS.corporate.c30,
      COLORS.payingPartner.primary,
      "#64a40c",
    ],
    colors_infra: ["#526058", "#1F2421", "#333C37"],
    colors_rgba: [
      "rgba(32, 104, 105, 0.2)",
      "rgba(73, 160, 120, 0.2)",
      "rgba(100, 164, 108, 0.2)",
      "rgba(156, 197, 161, 0.2)",
      "rgba(55, 120, 90, 0.2)",
    ],
    colorsEvaluation: {
      green: COLORS.planet.c70,
      green2: COLORS.corporate.c50,
      red: COLORS.danger.secondary,
      red2: COLORS.danger.dark,
      yellow: "#e2964c",
      yellow2: "#ffda20",
    },
    disabledColor: "#ababab",
  },
  13: {
    chartGHGEmissionsDistribution: {
      colors: {
        equipment: COLORS.planet.primary,
        equipments: COLORS.profit.primary,
        cloud: COLORS.profit.c30,
        network: COLORS.profit.c30,
        printing: COLORS.planet.c60,
        collab: COLORS.profit.c60,
        energy: COLORS.planet.tertiary,
        deee: COLORS.planet.c50,
        website: COLORS.planet.c70,
        external_app: COLORS.planet.c60,
      },
    },
    chartEnergyMix: {
      colors: {
        green: COLORS.planet.c70,
        red: COLORS.danger.secondary,
        yellow: "#ffda20",
      },
    },
  },
  6: {
    chart6: {
      website_water_thresold: 3,
    },
  },
  7: {
    chart3: {
      data_order: ["GCP", "Azure", "AWS", "OVH", "IBM", "Alibaba", "Oracle"],
      data_match: {
        AWS: 60,
        Azure: 80,
        GCP: 100,
        OVH: 60,
        Alibaba: 40,
        Oracle: 40,
        IBM: 60,
      },
      colors_cloud: [COLORS.neutral.c10, COLORS.neutral.c20],
      data_label: {
        AWS: "C",
        Azure: "B",
        GCP: "A",
        OVH: "C",
        Alibaba: "D",
        Oracle: "D",
        IBM: "C",
      },
    },
    chart6: {
      colors: {
        orange: COLORS.warning.lighter,
      },
      data_order: ["best", "middle_plus", "middle_less", "worse"],
      data_match: {
        best: 95,
        middle_plus: 75,
        middle_less: 50,
        worse: 25,
      },
      detailed_list: {
        A: "Enercoop, Planete oui, Urban Solar Energy, Ilek, Plum",
        B: "Mint Energie, ekWateur, GEG, Alterna, Selia",
        C:
          "Energem, GreenYellow, Lucia, E.Leclerc, OHM, Butagaz, Proxelia, Energies du Santerre",
        D: "Iberdrola, Vattenfall, Happ-E Engie, Total, EDF, ENI",
      },
    },
  },
  12: {
    chart6: {
      eol_recycle_ratio: {
        borderColor: COLORS.danger.secondary,
        backgroundColor: "rgba(199, 0, 57, 0.5)",
      },
      eol_internal_reconditioning_ratio: {
        borderColor: COLORS.planet.c70,
        backgroundColor: "rgba(100, 164, 108, 0.5)",
      },
      eol_external_reconditioning_ratio: {
        borderColor: "rgba(255, 218, 32, 1)",
        backgroundColor: "rgba(255, 218, 32, 0.5)",
      },
    },
  },
  15: {
    chart3: {
      paper_weight: 0.005,
      thresold: {
        paper: 50,
        deee: 21,
      },
    },
    chart4: {
      scarce_resources: {
        copper: COLORS.profit.primary,
        cobalt: COLORS.planet.primary,
        tantalum: COLORS.planet.secondary,
        indium: COLORS.planet.c60,
        gallium: COLORS.payingPartner.primary,
        palladium: "#4C8981",
      },
    },
    chart6: {
      soil_pollution: ["plastic", "oil", "metal"],
      colors: {
        plastic: COLORS.planet.secondary,
        oil: COLORS.planet.c80,
        metal: COLORS.planet.primary,
      },
    },
  },
}

export default SCORE_CONFIG
