import Loading from "components/widgets/Loading"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import CountryOrSiteScoreLayoutOrganism from "views/organisms/canvases/charts/ScorePerLevel/CountryOrSiteScoreLayoutOrganism"
import BrandScoreLayoutOrganism from "views/organisms/canvases/charts/ScorePerLevel/BrandScoreLayoutOrganism"
import RegionScoreLayoutOrganism from "views/organisms/canvases/charts/ScorePerLevel/RegionScoreLayoutOrganism"
import CompanyScoreLayoutOrganism from "views/organisms/canvases/charts/ScorePerLevel/CompanyScoreLayoutOrganism"
import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COMPANY,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
  NODE_TYPE_PARTNER,
  NODE_TYPE_PHASE,
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_REGION,
  NODE_TYPE_SERVICE,
  NODE_TYPE_SERVICE_COMPONENT,
  NODE_TYPE_SITE,
} from "utils/constants/organization/nodeTypes"
import { useAppContext } from "components/AppContext"

const Objective13ScoreOrganism = ({ score }) => {
  const firstLoad = useRef(true)

  const {
    currentNode,
    isCurrentNodeLoading,
    isScoreLoading,
    isEnvScoreLoading,
    isEnvCollectLoading,
  } = useAppContext()

  useEffect(() => {
    firstLoad.current = false
  })

  const displayChartsByNodeType = () => {
    switch (currentNode?.type) {
      case NODE_TYPE_COMPANY:
      case NODE_TYPE_PARTNER:
      case NODE_TYPE_PROGRAM:
      case NODE_TYPE_SERVICE:
      case NODE_TYPE_PROJECT:
        return <CompanyScoreLayoutOrganism score={score} />

      case NODE_TYPE_BRAND:
        return <BrandScoreLayoutOrganism score={score} />

      case NODE_TYPE_REGION:
      case NODE_TYPE_SERVICE_COMPONENT:
      case NODE_TYPE_PHASE:
        return <RegionScoreLayoutOrganism score={score} />

      case NODE_TYPE_COUNTRY:
      case NODE_TYPE_COUNTRY_TEAM:
      case NODE_TYPE_SITE:
        return (
          <CountryOrSiteScoreLayoutOrganism
            score={score}
            isConsolidated={currentNode?.has_children}
          />
        )
      default:
        return null
    }
  }

  if (
    isCurrentNodeLoading ||
    isScoreLoading ||
    isEnvScoreLoading ||
    isEnvCollectLoading
  )
    return <Loading />
  else return displayChartsByNodeType()
}

Objective13ScoreOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Objective13ScoreOrganism
