import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import classNames from "classnames"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import NodeOrganization from "services/organizations/NodeOrganization"

const ChartODD13GHGEmissionsDistributionConsolidateMolecule = ({ score }) => {
  const { t } = useTranslation()

  const { datasets, label_keys } = score.odd13_emissions_breakdown_consolidated

  const unit =
    datasets[0].unit ||
    t(`score:score.13.chartGHGEmissionsDistribution.axes_label`)

  const labels = label_keys.map((label_key) =>
    t(`score:score.13.chartGHGEmissionsDistribution.${label_key}`)
  )

  const data = datasets.map((dataset, index) => ({
    label: NodeOrganization.getNodeNameWithVersion(dataset.label_key),
    data: dataset.data,
    backgroundColor: Config.common.colors[index],
    unit,
    stack: 1,
  }))

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissionsDistribution.help`)}
    >
      <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
        <h1>{t("score:score.13.chartGHGEmissionsDistribution.title")}</h1>
        <div className="content">
          <Bar
            data={{ datasets: data, labels }}
            options={{
              ...DEFAULT_OPTIONS,
              ...yAxes(unit),
            }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13GHGEmissionsDistributionConsolidateMolecule.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13GHGEmissionsDistributionConsolidateMolecule
