import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PartnershipsForm from "./objective17/Objective17PartnershipsForm"
import PositiveImpactForm from "../PositiveImpactForm"
import Objective17SourcingForm from "components/widgets/CollectPage/forms/pillars/financial/objective17/Objective17SourcingForm"

const Objective17Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "partnerships_sourcing":
        return <Objective17SourcingForm />
      case "partnerships":
        return <PartnershipsForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective17Form
