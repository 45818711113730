import { Layout } from "@shopify/polaris"
import React from "react"
import TemplateBannerReportAtom from "views/atoms/reports/banners/TemplateBannerReportAtom"
import CardSectionDispatcherReportOrganism from "views/organisms/reports/CardSectionDispatcherReportOrganism.jsx"

const DefaultReportTemplate = () => {
  return (
    <>
      <Layout.Section>
        <TemplateBannerReportAtom />
      </Layout.Section>
      <Layout.Section>
        <CardSectionDispatcherReportOrganism />
      </Layout.Section>
    </>
  )
}

export default DefaultReportTemplate
