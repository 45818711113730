import { TopBar } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const TopBarUnauthenticatedUserMenu = () => {
  const { t } = useTranslation()
  const goToLogin = () => history.push("/")

  return (
    <TopBar.UserMenu name={t("common:topbar.login")} onToggle={goToLogin} />
  )
}

export default TopBarUnauthenticatedUserMenu
