import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  generateCollectedEquipmentName,
  reusablePersonalWorkplaceEquipmentTypes,
} from "helpers/CollectHelper"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import { Icon, Tooltip } from "@shopify/polaris"
import { InfoMinor } from "@shopify/polaris-icons"
import { WORKPLACE } from "utils/constants/equipmentDomains"
import { useFormContext } from "components/widgets/CollectPage/forms/FormContext"

const LIFETIME_FIELD = "lifetime"
const EOL_INTERNAL = "eol_internal_reconditioning_ratio"
const EOL_EXTERNAL = "eol_external_reconditioning_ratio"
const EOL_RECYCLE = "eol_recycle_ratio"

const ResponsibleProductionTableBodyLines = ({ equipmentTypes, domain }) => {
  const { t } = useTranslation()
  const { errors, formData, setFormData } = useFormContext()

  const isReusableWorkplaceEquipment = (equipmentType) =>
    domain === WORKPLACE &&
    reusablePersonalWorkplaceEquipmentTypes.some(
      (equipment) => equipment === equipmentType
    )

  const dataGetter = (object, equipmentType) =>
    object?.collected_equipments?.[domain]?.[equipmentType]

  const handleChange = (equipmentType, value, property) => {
    const equipment = dataGetter(formData, equipmentType) || {}

    if (property === LIFETIME_FIELD) {
      equipment[property] = parseFloat(value)
    } else {
      equipment[property] = parseInt(value)
    }

    if (property !== LIFETIME_FIELD) {
      const otherProperty =
        property === EOL_INTERNAL ? EOL_EXTERNAL : EOL_INTERNAL

      equipment[EOL_RECYCLE] =
        100 - (parseInt(equipment[otherProperty]) || 0) - parseInt(value)
    }

    setFormData({
      ...formData,
      collected_equipments: {
        ...formData.collected_equipments,
        [domain]: {
          ...formData.collected_equipments[domain],
          [equipmentType]: equipment,
        },
      },
    })
  }

  return equipmentTypes.map((equipmentType, index) => (
    <tr key={index}>
      <td>{generateCollectedEquipmentName(equipmentType)}</td>
      <td className="top">
        <TextField
          id={`collected_equipments.${domain}.${equipmentType}.${LIFETIME_FIELD}`}
          type="number"
          onChange={(value) =>
            handleChange(equipmentType, value, LIFETIME_FIELD)
          }
          step={0.1}
        />
      </td>
      <td className="top">
        {isReusableWorkplaceEquipment(equipmentType) && (
          <TextField
            id={`collected_equipments.${domain}.${equipmentType}.${EOL_INTERNAL}`}
            type="number"
            onChange={(value) =>
              handleChange(equipmentType, value, EOL_INTERNAL)
            }
          />
        )}
      </td>
      <td className="top">
        <TextField
          id={`collected_equipments.${domain}.${equipmentType}.${EOL_EXTERNAL}`}
          type="number"
          onChange={(value) => handleChange(equipmentType, value, EOL_EXTERNAL)}
        />
      </td>
      <td className="top">
        <TextField
          id={`collected_equipments.${domain}.${equipmentType}.${EOL_RECYCLE}`}
          type="number"
          disabled
        />
      </td>
      <td>
        {dataGetter(errors, equipmentType) && (
          <Tooltip
            content={t(
              `collect:questions.12.sustainable_production.errors.${errors?.collected_equipments?.[equipmentType]}`
            )}
            dismissOnMouseOut
          >
            <Icon source={InfoMinor} color="red" />
          </Tooltip>
        )}
      </td>
    </tr>
  ))
}

export default ResponsibleProductionTableBodyLines

ResponsibleProductionTableBodyLines.propTypes = {
  equipmentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  domain: PropTypes.string.isRequired,
}
