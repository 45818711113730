import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const TemplateBannerReportAtom = () => {
  const { t } = useTranslation()

  return (
    <Banner
      title={t("share:content.template.banner.title")}
      action={{
        content: t("share:content.template.banner.cta"),
        url: "/collect",
      }}
    >
      {t("share:content.template.banner.message")}
    </Banner>
  )
}

export default TemplateBannerReportAtom
