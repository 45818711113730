import React from "react"
import {
  generateCollectedEquipmentName,
  reusablePersonalWorkplaceEquipmentTypes,
} from "helpers/CollectHelper"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import { WORKPLACE } from "utils/constants/equipmentDomains"

const ResponsibleConsumptionTableBodyLines = ({ equipmentTypes, domain }) => {
  const isReusableWorkplaceEquipment = (equipmentType) =>
    domain === WORKPLACE &&
    reusablePersonalWorkplaceEquipmentTypes.some(
      (equipment) => equipment === equipmentType
    )

  return equipmentTypes.map((equipmentType, index) => (
    <tr key={index}>
      <td>{generateCollectedEquipmentName(equipmentType)}</td>
      <td className="top">
        <TextField
          id={`collected_equipments.[${domain}].[${equipmentType}].upstream_lifetime`}
          type="number"
        />
      </td>
      <td className="top">
        <TextField
          id={`collected_equipments.[${domain}].[${equipmentType}].second_hand_ratio`}
          type="number"
        />
      </td>
      <td className="top">
        {isReusableWorkplaceEquipment(equipmentType) && (
          <TextField
            id={`collected_equipments.[${domain}].[${equipmentType}].internal_reuse_ratio`}
            type="number"
          />
        )}
      </td>
    </tr>
  ))
}

export default ResponsibleConsumptionTableBodyLines

ResponsibleConsumptionTableBodyLines.propTypes = {
  equipmentTypes: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
}
