import React, { useRef } from "react"
import PropTypes from "prop-types"
import ChartNodeMolecule from "views/molecules/organization/ChartNodeMolecule"
import classNames from "classnames"

const ChartNodesChildrenMolecule = ({
  node,
  isParentColumn,
  hidden,
  position,
}) => {
  const refs = useRef([])

  if (!node?.children || node.children.length === 0) return null

  return (
    <ul
      className={classNames(position, {
        hidden,
        column: isParentColumn,
      })}
    >
      {node.children.map((child, index) => (
        <ChartNodeMolecule
          node={child}
          key={index}
          ref={(element) => (refs.current[index] = element)}
          onCloseSiblings={() => refs.current.map((ref) => ref.closeChildren())}
        />
      ))}
    </ul>
  )
}

ChartNodesChildrenMolecule.propTypes = {
  node: PropTypes.object.isRequired,
  isParentColumn: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  position: PropTypes.string,
}

export default ChartNodesChildrenMolecule

ChartNodesChildrenMolecule.defaultProps = {
  position: "bottom",
}
