import React from "react"
import { Card, List } from "@shopify/polaris"
import PropTypes from "prop-types"
import ReassessmentItem from "views/atoms/organization/subscription/reassessment/ReassessmentItemAtom"
import { useTranslation } from "react-i18next"
import { PROVIDER } from "services/apps/product"

const CardSubscriptionMolecule = ({ subscription, title, badge }) => {
  const { t } = useTranslation()

  const ReassessmentItems = () =>
    subscription.reassessment_list.map((reassessment, idx) => (
      <ReassessmentItem reassessment={reassessment} key={idx} />
    ))

  const partnerSubscriptionDetail = () => (
    <Card.Section subdued title={t("subscription:providers.title")}>
      <List>
        <List.Item>
          {t("subscription:providers.max_trees")}: {subscription.max_trees}
        </List.Item>
        <List.Item>
          {t("subscription:providers.tree_count")}: {subscription.trees}
        </List.Item>
      </List>
    </Card.Section>
  )
  const corpoSubscriptionDetail = () => (
    <Card.Section subdued title={t("subscription:reassessment.title")}>
      <List>
        <List.Item>
          {t(
            "subscription:current_subscription.labels.reassessment_number_done"
          )}
          : {subscription?.reassessment_done}
        </List.Item>
      </List>
    </Card.Section>
  )

  return (
    <Card>
      <Card.Header title={title}>{badge}</Card.Header>

      <Card.Section subdued title={t("subscription:perimeter.title")}>
        <List>
          <List.Item>
            Type: {t(`subscription:perimeter.${subscription.perimeter}.title`)}
          </List.Item>
        </List>
        <p>
          {t(`subscription:perimeter.${subscription.perimeter}.description`)}
        </p>
      </Card.Section>

      <Card.Section subdued title={t("subscription:duration.title")}>
        <List>
          <List.Item>
            {`${t("subscription:current_subscription.labels.start_date")}: ${
              subscription?.date_start
            }`}
          </List.Item>
          <List.Item>
            {`${t("subscription:current_subscription.labels.end_date")}: ${
              subscription?.date_end
            }`}
          </List.Item>
        </List>
      </Card.Section>

      {subscription.subscription_type === PROVIDER
        ? partnerSubscriptionDetail()
        : corpoSubscriptionDetail()}

      {!!subscription?.reassessment_list?.length && (
        <Card.Section subdued title="Reassessment List">
          <List>
            <ReassessmentItems />
          </List>
        </Card.Section>
      )}
    </Card>
  )
}

CardSubscriptionMolecule.propTypes = {
  subscription: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    date_start: PropTypes.string.isRequired,
    date_end: PropTypes.string.isRequired,
    subscription_type: PropTypes.string.isRequired,
    perimeter: PropTypes.string.isRequired,
    reassessment_number: PropTypes.number,
    reassessment_done: PropTypes.number,
    reassessment_list: PropTypes.array,
    max_trees: PropTypes.number,
    trees: PropTypes.number,
  }),
  title: PropTypes.string,
  badge: PropTypes.element,
}

CardSubscriptionMolecule.defaultProps = {
  subscription: {
    created_at: undefined,
    date_start: undefined,
    date_end: undefined,
    subscription_type: " ",
    reassessment_number: 0,
    reassessment_done: 0,
    reassessment_list: [],
    trees: 0,
  },
}

export default CardSubscriptionMolecule
