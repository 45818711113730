import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"

const ChartODD13GHGEmissionsInternalExternalMolecule = ({
  ghgSplit = {
    doughnut_charts: [],
    doughnut_charts_data_total: { number: 0, unit: "" },
  },
}) => {
  const { t } = useTranslation()

  const ghgSplitData = useCallback(
    () =>
      ghgSplit.doughnut_charts.map((ghg) => ({
        data: ghg.data,
        label: t(
          `score:score.13.chartGHGEmissionsInternalExternal.${ghg?.label}`
        ),
        unit: t(`units:units.emission.${ghg?.unit}`),
      })),
    [ghgSplit]
  )

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissionsInternalExternal.help`)}
    >
      <DoughnutChart
        title={t(`score:score.13.chartGHGEmissionsInternalExternal.title`)}
        data={ghgSplitData()}
        dataTitle=""
        centerText={null}
        centerTextUnit={t("units:units.emission.t_unit")}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13GHGEmissionsInternalExternalMolecule.propTypes = {
  ghgSplit: PropTypes.object,
}

export default ChartODD13GHGEmissionsInternalExternalMolecule
