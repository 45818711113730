import { Button, List } from "@shopify/polaris"
import React, { useState } from "react"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { useHistory } from "react-router-dom"

const ReassessmentItem = ({
  reassessment = { id: undefined, period_name: undefined },
}) => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const updateHistorySelected = async () => {
    try {
      setLoading(true)
      await OrganizationEntity.putHistory(reassessment.id)
      history.push("/dashboard")
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <List.Item>
      <Button primary onClick={updateHistorySelected} loading={loading}>
        {reassessment.period_name}
      </Button>
    </List.Item>
  )
}

export default ReassessmentItem
