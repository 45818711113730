import { Card, Stack, ButtonGroup, Button } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const CardErrorAtom = () => {
  const { t } = useTranslation()

  return (
    <Card title={t(`common:errors.unknown_error.title`)}>
      <Card.Section>
        <Stack spacing="loose" vertical>
          <p>{t(`common:errors.unknown_error.subtitle`)}</p>
          <Stack distribution="trailing">
            <ButtonGroup>
              <Link to={`/contact`}>
                <Button>{t(`common:errors.contact`)}</Button>
              </Link>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default CardErrorAtom
