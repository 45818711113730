import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import DcExternalInventory from "components/widgets/CollectPage/forms/inventories/environment/DcExternalInventory"
import React from "react"
import {
  CLOUD_COLLECT_MODULE,
  DATACENTER_EXTERNAL_COLLECT_MODULE,
} from "utils/constants/collects/modules"
import { Module } from "../../Module"
import FormContextProvider, { useFormConfig } from "../../FormContext"
import CloudUsageInventory from "components/widgets/CollectPage/forms/inventories/environment/CloudUsageInventory"
import ModalCloudInventories, {
  BOTH_CHOICE,
  INVENTORY_CHOICE,
  MANUAL_CHOICE,
} from "views/molecules/collects/cloud_inventories/ModalCloudInventories"
import CloudProviderDetailedInventories from "views/molecules/collects/cloud_inventories/CloudProviderDetailedInventories"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"

const fields = [
  "data_external_data_volume",
  "data_cloud_csr_criteria",
  "data_cloud_quota_policy",
  "data_cloud_archiving_policy",
  "data_cloud_data_duration_policy",
]
const translationPath = "13.datacenter_external"

const Objective13DataExternalForm = () => {
  const { collect } = useCollectContext()
  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column column-23">
            <FormLayout>
              <Module module={DATACENTER_EXTERNAL_COLLECT_MODULE}>
                <FormContextProvider>
                  <DcExternalInventory />
                </FormContextProvider>
                <br />
                <TextField id="data_external_data_volume" type="number" />
              </Module>
              <Module module={CLOUD_COLLECT_MODULE}>
                <FormContextProvider>
                  <div className="icon-text-lined">
                    <ExternalLabel
                      pathOverride={"13.datacenter_external"}
                      translationPathName="cloud_table"
                    />
                    <ModalCloudInventories />
                  </div>

                  {[MANUAL_CHOICE, BOTH_CHOICE].includes(
                    collect.cloud_inventory_choice
                  ) && <CloudUsageInventory />}

                  {collect.cloud_inventory_choice === BOTH_CHOICE && <br />}

                  {[INVENTORY_CHOICE, BOTH_CHOICE].includes(
                    collect.cloud_inventory_choice
                  ) && <CloudProviderDetailedInventories />}
                </FormContextProvider>
              </Module>
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <Module module={CLOUD_COLLECT_MODULE}>
                <YesNoRadio id="data_cloud_csr_criteria" />
                <br />
                <MultipleChoiceField
                  id="policies"
                  choices={[
                    "data_cloud_quota_policy",
                    "data_cloud_archiving_policy",
                    "data_cloud_data_duration_policy",
                  ]}
                />
              </Module>
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13DataExternalForm
