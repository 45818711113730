import React from "react"
import PropTypes from "prop-types"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import { useAppContext } from "../../../../components/AppContext"
import { NODE_TYPE_COUNTRY_TEAM } from "utils/constants/organization/nodeTypes"

const ExternalUploadMolecule = ({
  translationPathName,
  title,
  precision,
  inventoryType,
  displayDownloadIcon,
  displayQualityIcon,
  fileIdExternal,
  fileId,
  templateSelectionComponent,
  disabled,
}) => {
  const { currentNode } = useAppContext()

  const id =
    currentNode?.type === NODE_TYPE_COUNTRY_TEAM ? fileIdExternal : fileId

  return (
    <FileField
      translationPathName={translationPathName}
      id={id}
      inventoryType={inventoryType}
      title={title}
      precision={precision}
      displayDownloadIcon={displayDownloadIcon}
      displayQualityIcon={displayQualityIcon}
      templateSelectionComponent={templateSelectionComponent}
      disabled={disabled}
    />
  )
}

ExternalUploadMolecule.propTypes = {
  translationPathName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  precision: PropTypes.string.isRequired,
  displayDownloadIcon: PropTypes.bool,
  displayQualityIcon: PropTypes.bool,
  fileIdExternal: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  inventoryType: PropTypes.string.isRequired,
  templateSelectionComponent: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
}

ExternalUploadMolecule.defaultProps = {
  disabled: false,
}

export default ExternalUploadMolecule
