import React from "react"
import { Button } from "@shopify/polaris"
import NoCentralizationMessage from "views/molecules/centralization/NoCentralizationMessage"
import PropTypes from "prop-types"

const PillarCentralizationButton = ({
  title,
  onClick,
  hasCentralizationRules,
}) => {
  return (
    <>
      <Button plain onClick={onClick} disabled={!hasCentralizationRules}>
        {title}
      </Button>

      {!hasCentralizationRules && <NoCentralizationMessage />}
    </>
  )
}

PillarCentralizationButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hasCentralizationRules: PropTypes.bool,
}

PillarCentralizationButton.defaultProps = {
  hasCentralizationRules: false,
}

export default PillarCentralizationButton
