import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import EnvCollectEntity from "services/entities/EnvCollectEntity"
import Form from "components/widgets/CollectPage/forms/Form"
import { Banner, FormLayout, TextStyle } from "@shopify/polaris"
import {
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import ResponsibleConsumptionTableMolecule from "views/molecules/collects/objectives/12/responsibleConsumption/ResponsibleConsumptionTableMolecule"
import Loading from "components/widgets/Loading"
import {
  EQUIPMENT_WORKPLACE_COLLECT_MODULE,
  EXTERNAL_APPS_COLLECT_MODULE,
  PRINTING_MODULE,
} from "utils/constants/collects/modules"
import { Module } from "components/widgets/CollectPage/forms/Module"
import { useFormContext } from "components/widgets/CollectPage/forms/FormContext"
import { useCollectContext } from "../../../../../../components/widgets/CollectPage/CollectContextProvider"
import { useEntity } from "../../../../../../hooks/useAxios"

const translationPath = "12.sustainable_consumption"

const ResponsibleConsumptionOrganism = () => {
  const { t } = useTranslation()

  const { collect } = useCollectContext()
  const [equipmentTypesAvailable, setEquipmentTypesAvailable] = useState({})
  const [
    isFetchingEquipmentsTypesAvailable,
    setIsFetchingEquipmentsTypesAvailable,
  ] = useState(true)
  const { setFormData, setTranslationPath } = useFormContext()
  const envCollectEntity = useEntity(EnvCollectEntity)

  // Get available equipment types for the current env collects
  useEffect(async () => {
    try {
      const { data } = await envCollectEntity.getEquipmentsTypesAvailable()
      setEquipmentTypesAvailable(data)
    } catch (e) {
      console.error(e)
    } finally {
      setIsFetchingEquipmentsTypesAvailable(false)
    }
  }, [])

  useEffect(() => {
    if (collect == null) return

    setTranslationPath(translationPath)
    const data = {
      loaded_data: true,
      label_equipment: collect?.label_equipment,
      label_cartridge: collect?.label_cartridge,
      label_paper: collect?.label_paper,
      second_hand_cartridge_ratio: collect?.second_hand_cartridge_ratio,
      second_hand_paper_ratio: collect?.second_hand_paper_ratio,
      second_hand_licences_ratio: collect?.second_hand_licences_ratio,
      collected_equipments: {
        workplace: {},
        infrastructure: {},
        network_security: {},
        business: {},
      },
    }

    collect?.collected_equipments.forEach((collectedEquipment) => {
      data.collected_equipments[collectedEquipment.domain][
        collectedEquipment.equipment_type
      ] = collectedEquipment
    })

    setFormData(data)
  }, [])

  return (
    <Form>
      <div className="columns">
        <div className="column column-23">
          <FormLayout>
            <div>
              <ExternalLabel translationPathName="table_1" />
              {isFetchingEquipmentsTypesAvailable ? (
                <Loading />
              ) : equipmentTypesAvailable.length > 0 ? (
                <ResponsibleConsumptionTableMolecule
                  equipmentTypesAvailable={equipmentTypesAvailable}
                />
              ) : (
                <Banner
                  title={t(
                    "collect:questions.12.sustainable_consumption.banners.no_equipment_types.title"
                  )}
                  status="warning"
                >
                  {t(
                    "collect:questions.12.sustainable_consumption.banners.no_equipment_types.description"
                  )}
                </Banner>
              )}
            </div>
            <TextStyle variation="subdued">
              {t(
                "collect:questions.12.sustainable_consumption.second_hand_help.label"
              )}
              <br />
              {t(
                "collect:questions.12.sustainable_consumption.internal_reuse_help.label"
              )}
            </TextStyle>
          </FormLayout>
        </div>
        <div className="column">
          <FormLayout>
            <Module module={EXTERNAL_APPS_COLLECT_MODULE}>
              <TextField id="second_hand_licences_ratio" type="number" />
            </Module>
            <Module module={EQUIPMENT_WORKPLACE_COLLECT_MODULE}>
              <Select
                id="label_equipment"
                options={[
                  { label: t("collect:questions.common.none"), value: "0" },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_equipment.options.epeat"
                    ),
                    value: "2",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_equipment.options.gold"
                    ),
                    value: "3",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_equipment.options.energystar"
                    ),
                    value: "4",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_equipment.options.blue_angel"
                    ),
                    value: "5",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_equipment.options.european_ecolabel"
                    ),
                    value: "6",
                  },
                  { label: t("collect:questions.common.other"), value: "1" },
                ]}
              />
            </Module>
            <Module module={PRINTING_MODULE}>
              <Select
                id="label_cartridge"
                options={[
                  { label: t("collect:questions.common.none"), value: "0" },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_cartridge.options.nf"
                    ),
                    value: "2",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_cartridge.options.stmc"
                    ),
                    value: "3",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_cartridge.options.rit"
                    ),
                    value: "4",
                  },
                  { label: t("collect:questions.common.other"), value: "1" },
                ]}
              />
              <YesNoRadio id="second_hand_cartridge_ratio" />
              <Select
                id="label_paper"
                options={[
                  { label: t("collect:questions.common.none"), value: "0" },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_paper.options.fsc"
                    ),
                    value: "2",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_paper.options.blue_angel"
                    ),
                    value: "3",
                  },
                  {
                    label: t(
                      "collect:questions.12.sustainable_consumption.label_paper.options.pefc"
                    ),
                    value: "4",
                  },
                  { label: t("collect:questions.common.other"), value: "1" },
                ]}
              />
              <YesNoRadio id="second_hand_paper_ratio" />
            </Module>
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default ResponsibleConsumptionOrganism
