import PropTypes from "prop-types"
import { DropZone } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const FileUploadAtom = ({ loadedItems }) => {
  const { t } = useTranslation()

  if (!loadedItems) {
    return (
      <DropZone.FileUpload
        actionTitle={t("collect:inventory.file_upload_area.create.title")}
        actionHint={t("collect:inventory.file_upload_area.create.hint")}
      />
    )
  }
  return (
    <DropZone.FileUpload
      actionTitle={t("collect:inventory.file_upload_area.update.title")}
      actionHint={t("collect:inventory.file_upload_area.update.hint")}
    />
  )
}

FileUploadAtom.propTypes = {
  loadedItems: PropTypes.number,
}

export default FileUploadAtom
