import React from "react"
import { Banner, Layout } from "@shopify/polaris"
import { PropTypes } from "prop-types"

const AlertBanner = ({ title, action, content }) => {
  return (
    <Layout.Section>
      <Banner title={title} action={action}>
        {content}
      </Banner>
    </Layout.Section>
  )
}

export default AlertBanner

AlertBanner.propTypes = {
  action: PropTypes.object,
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
}

AlertBanner.defaultProps = {
  action: null,
}
