import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import AutocompleteFieldMolecule from "views/molecules/interactive/AutocompleteFieldMolecule"
import { useTranslation } from "react-i18next"
import { Banner } from "@shopify/polaris"
import { BANNER_INFO_STATUS } from "../../../utils/constants/alert_banners/alertBannersConstant"

const AutocompleteFieldOrganizationShareMolecule = ({
  organizations,
  disabled,
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [showAlertBanner, setShowAlertBanner] = useState(false)

  useEffect(() => {
    if (value === "") {
      setShowAlertBanner(false)
      return
    }

    const val = value.toLowerCase()

    const matchOrganization = organizations.some((option) => {
      const label = option.label.toLowerCase()
      return label === val
    })

    setShowAlertBanner(!matchOrganization)
  }, [value])

  return (
    <>
      <AutocompleteFieldMolecule
        label={t("dashboard:chart.share_form.organization")}
        options={organizations}
        disabled={disabled}
        placeholder={t("dashboard:chart.share_form.organization_placeholder")}
        value={value}
        onChange={onChange}
      />

      {showAlertBanner && (
        <Banner
          title={t(
            "dashboard:chart.share_form.banner.organization_not_found.title"
          )}
          status={BANNER_INFO_STATUS}
        >
          {t(
            "dashboard:chart.share_form.banner.organization_not_found.description"
          )}
        </Banner>
      )}
    </>
  )
}

AutocompleteFieldOrganizationShareMolecule.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

AutocompleteFieldOrganizationShareMolecule.defaultProps = {
  disabled: false,
  value: "",
}

export default AutocompleteFieldOrganizationShareMolecule
