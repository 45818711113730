import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { COLORS } from "utils/constants/colors"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { HIDDEN_LEGEND } from "services/charts/legend"
import { yAxes } from "services/charts/yAxes"

const TRANSLATION_PREFIX = "analyze:it_domain.cloud.charts.zone_emissions"
const TITLE_T_PATH = `${TRANSLATION_PREFIX}.title`

const ChartAnalysisCloudByZone = ({ datasets, labels, unitKey }) => {
  const { t } = useTranslation()

  const title = t(TITLE_T_PATH)
  const unit = t(`units:units.emission.${unitKey}`)

  const data = {
    labels,
    datasets: datasets.map((dataset, index) => {
      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor:
          Config.common.colors[index % Config.common.colors.length],
        borderColor: COLORS.white,
        borderWidth: 1,
        stack: "default",
        unit,
        showLabelInData: true,
      }
    }),
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: HIDDEN_LEGEND,
    ...yAxes(unit),
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", PILLAR_ENVIRONMENTAL)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}

ChartAnalysisCloudByZone.propTypes = {
  datasets: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  unitKey: PropTypes.string.isRequired,
}

export default ChartAnalysisCloudByZone
