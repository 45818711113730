import React from "react"
import ChartBadge from "views/atoms/badges/ChartBadge"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const translationPrefix = "score:score.7.chart5"

const TextToFormat = (text) =>
  text.split("\n").map((value, index) => {
    return (
      <span key={index}>
        {value}
        <br />
      </span>
    )
  })

const TextChartOdd7Pue = ({ globalPueScore }) => {
  const { t } = useTranslation()

  const energyConsumptionPue = globalPueScore?.average
    ? parseFloat(globalPueScore?.average)
    : null

  const badge = energyConsumptionPue ? (
    <ChartBadge
      value={energyConsumptionPue}
      lowerLimit={1.4}
      upperLimit={1.7}
      inverse
    />
  ) : null

  const notAvailable = t(`${translationPrefix}.not_available`)

  const content = {
    reference: badge,
    kpi: globalPueScore.average ?? notAvailable,
    kpi_unit: t(translationPrefix + ".unit"),
    kpi_text: TextToFormat(t(translationPrefix + ".text")),
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(translationPrefix + ".help")}>
      <TextChart content={<TextChartMinor content={content} />} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartOdd7Pue.propTypes = {
  globalPueScore: PropTypes.shape({
    average: PropTypes.string,
    coverage: PropTypes.number.isRequired,
  }),
}

export default TextChartOdd7Pue
