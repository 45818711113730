import React from "react"
import KpiMolecule from "views/molecules/canvases/charts/common/KpiMolecule"
import KpiTitleAtom from "views/atoms/canvases/charts/KpiTitleAtom"
import PropTypes from "prop-types"
import {
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"

const CompareKpiMolecule = ({
  title,
  difference,
  current,
  status,
  currentUnit,
  differenceUnit,
  subtitle,
  freeText,
  currentUnitToLeft,
}) => {
  return (
    <KpiMolecule subtitle={subtitle}>
      <KpiTitleAtom
        name={title}
        delta={difference}
        value={current}
        valueStatus={status}
        valueUnit={currentUnit}
        deltaUnit={differenceUnit}
        freeText={freeText}
        currentUnitToLeft={currentUnitToLeft}
      />
    </KpiMolecule>
  )
}

export default CompareKpiMolecule

CompareKpiMolecule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  current: PropTypes.number,
  difference: PropTypes.number,
  currentUnit: PropTypes.string,
  differenceUnit: PropTypes.string,
  status: PropTypes.oneOf([KPI_STATUS_UP, KPI_STATUS_NEUTRAL, KPI_STATUS_DOWN]),
  freeText: PropTypes.string,
  currentUnitToLeft: PropTypes.bool,
}

CompareKpiMolecule.defaultProps = {
  subtitle: "",
  difference: null,
  unit: "",
  status: null,
  freeText: null,
  current: null,
  currentUnitToLeft: false,
}
