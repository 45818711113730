import React from "react"
import { Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import AnalyzeHistorizationAlertBanners from "views/molecules/analyze/alert-banner/AnalyzeHistorizationAlertBanners"
import { useAppContext } from "components/AppContext"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { ALERT_BANNER_NO_HISTORIZATION } from "utils/constants/alert_banners/alertBannersConstant"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"
import { useParams } from "react-router-dom"
import EnvHistoryAnalyzeOrganism from "views/organisms/canvases/charts/analyzes/historization/EnvHistoryAnalyzeOrganism"
import ProfitHistoryAnalyzeOrganism from "views/organisms/canvases/charts/analyzes/historization/ProfitHistoryAnalyzeOrganism"
import useAvailablePillar from "hooks/useAvailablePillar"

const AnalyzeHistorizationPage = () => {
  const { parentOrganization, historizationRequest } = useAppContext()
  const { pillarId } = useParams()

  const { data } = historizationRequest

  const availablePillarLoaded = useAvailablePillar({
    pathPrefix: "/analyze/history",
  })

  const hasProfitDisabled =
    (data && !data.profit) || data?.profit?.history_count === 1

  const pillars = usePillars({
    pageName: "/analyze/history",
    [PILLAR_ENVIRONMENTAL]: { disabled: data && !data.env },
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: hasProfitDisabled },
  })

  if (
    historizationRequest.isLoading ||
    !parentOrganization ||
    !availablePillarLoaded
  ) {
    return <Loading />
  }

  const displayPillar = () => {
    if (pillarId === PILLAR_ENVIRONMENTAL && !!data.env) {
      return <EnvHistoryAnalyzeOrganism data={data.env} pillars={pillars} />
    }

    if (pillarId === PILLAR_PROFIT && !!data.profit) {
      return (
        <ProfitHistoryAnalyzeOrganism data={data.profit} pillars={pillars} />
      )
    }

    return "not found"
  }

  return (
    <Page fullWidth={historizationRequest.isSuccess}>
      {historizationRequest.isError && (
        <>
          {historizationRequest.errorCode === 404 && (
            <AnalyzeHistorizationAlertBanners
              type={ALERT_BANNER_NO_HISTORIZATION}
            />
          )}
        </>
      )}

      {historizationRequest.isSuccess && data && displayPillar()}
    </Page>
  )
}

export default withAuthRequired(AnalyzeHistorizationPage)
