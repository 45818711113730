import React from "react"
import PropTypes from "prop-types"

const WebsiteRecommendationDescription = ({ title, description }) => {
  return (
    <>
      <h3 style={{ fontSize: 18, marginBottom: 10 }}>{title}</h3>
      {description}
    </>
  )
}

WebsiteRecommendationDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default WebsiteRecommendationDescription
