import { hostname } from "components/configs/Common"

const HOSTNAME_TO_CONFIG = {
  localhost: "local",
  "verdikt-web-dev.herokuapp.com": "verdikt-web-dev",
  "verdikt-web-test.herokuapp.com": "verdikt-web-test",
  "verdikt-web-demo.herokuapp.com": "verdikt-web-demo",
  "verdikt-web-preprod.herokuapp.com": "verdikt-web-preprod",
  "verdikt-web-production.herokuapp.com": "production",
  "app.verdikt.io": "production",
}

export const sentryEnvironment = HOSTNAME_TO_CONFIG[hostname]
export const isLocalEnvironment = sentryEnvironment === "local"
