import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"
import useConversion from "hooks/useConversion"

export const EMPTY_LINE_LEGEND = [
  (legendItem) => (legendItem.fillStyle = COLORS.transparent),
]

const ChartOdd13ExternalAppEmissions = ({ score }) => {
  const { t } = useTranslation()
  const tPrefix = "analyze:it_domain.consolidated.saas.applications"

  const { bar, line, bar_unit, line_unit } = score?.applications

  const units = {
    t: t(`units:units.emission.t_unit`),
    gPerGb: `${t(`units:units.emission.g_unit`)} ${t(
      `analyze:it_domain.consolidated.saas.per`
    )} ${t(`units:units.storage.gb_unit`)}`,
  }

  const { unitLabel, convert } = useConversion({
    baseValue: score?.applications?.bar[0] * 1000,
    baseUnit: "kg_unit",
    unitType: "emission",
  })

  const data_inputs = {
    bar_data: {
      label: t(`${tPrefix}.emissions`),
      data: bar,
    },
    line_data: {
      label: t(`${tPrefix}.emission_factor`),
      data: line,
    },
  }

  const addUnitToDataLabel = {
    plugins: {
      datalabels: {
        display: true,
        color: COLORS.white,
        formatter: (value) =>
          `${value}\n${t(`units:units.emission.${bar_unit}`)}`,
        textAlign: "center",
      },
    },
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${tPrefix}.help`)}>
      <MixedChart
        title={t(`${tPrefix}.title`)}
        labels={score?.applications?.label}
        data_inputs={data_inputs}
        unit_right_axis={t(`units:units.emission.${bar_unit}`)}
        unit_left_axis={units.gPerGb}
        legendModifiers={EMPTY_LINE_LEGEND}
        config={addUnitToDataLabel}
        barBackgroundColors={COLORS.planet.primary}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartOdd13ExternalAppEmissions.propTypes = {
  score: PropTypes.shape({
    applications: PropTypes.object.isRequired,
  }).isRequired,
}

export default ChartOdd13ExternalAppEmissions
