import React from "react"
import { TextField } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const DateFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <TextField
      label={t(`activation:${type}.questions.${property}.label`)}
      id={property}
      name={property}
      value={value}
      error={error}
      onChange={onChange}
      type="date"
      disabled={disabled}
    />
  )
}

DateFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

DateFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default DateFieldAtom
