import React from "react"
import PropTypes from "prop-types"
import { Heading } from "@shopify/polaris"
import Recommendations from "components/widgets/ImprovePage/Recommendations"
import { LIFETIME_LEVER } from "utils/constants/recommendations/propositions"
import { useTranslation } from "react-i18next"

const KEY_TO_COMPONENT_KEY = {
  impact: "impact",
  complexity: "complexity",
  domain: "lever",
  key: "theme",
}

const RecommendationsGhgLayout = ({
  pillar,
  kpiComponent,
  headingText,
  recommendations,
  theme,
  objective,
}) => {
  const { t } = useTranslation()

  const recommendationDescriptionGetter = (recommendation) => {
    let key = ""
    switch (recommendation.lever) {
      case LIFETIME_LEVER:
        key = `${recommendation.lever}_${recommendation.simulation_detail.equipment_type}`
        break
      default:
        key = recommendation.lever
    }

    return t(`recommendation:propositions.descriptions.${key}`)
  }

  return (
    <div
      className={`recommendationsLayout recommendationsLayout--${pillar} recommendationsLayout-ghg`}
    >
      <div className="recommendationsLayout__score">{kpiComponent}</div>
      <div className="recommendationsLayout__recommendations">
        <Heading element="h1">{headingText}</Heading>

        <Recommendations
          pillar={pillar}
          objective={objective}
          theme={theme}
          recommendations={recommendations}
          keysToComponentKeys={KEY_TO_COMPONENT_KEY}
          canSelectRecommendationDetails
          recommendationDescriptionGetter={recommendationDescriptionGetter}
          sdgGetter={(recommendation) => recommendation.sdg}
        />
      </div>
    </div>
  )
}

RecommendationsGhgLayout.propTypes = {
  pillar: PropTypes.string,
  kpiComponent: PropTypes.element,
  headingText: PropTypes.string,
  recommendations: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
  objective: PropTypes.string,
}

RecommendationsGhgLayout.defaultProps = {
  pillar: "environmental",
  kpiComponent: null,
  headingText: null,
  recommendations: [],
  theme: null,
  objective: null,
}

export default RecommendationsGhgLayout
