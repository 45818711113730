import PropTypes from "prop-types"
import React from "react"
import { Tooltip } from "@shopify/polaris"
import NodeIconAtom from "components/widgets/Dashboard/OrganizationChart/NodeIconAtom"

const NodeActionMolecule = ({ icon, onAction, title }) => (
  <Tooltip light content={title}>
    <NodeIconAtom handleChange={onAction}>{icon}</NodeIconAtom>
  </Tooltip>
)

NodeActionMolecule.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onAction: PropTypes.func,
}

export default NodeActionMolecule
