import Config from "assets/configs/score.js"

export const CHART_STATUS_GOOD = "status_good"
export const CHART_STATUS_NEUTRAL = "status_neutral"
export const CHART_STATUS_BAD = "status_bad"

export const STATUS_TO_COLORS = {
  [CHART_STATUS_GOOD]: Config.common.colorsEvaluation.green,
  [CHART_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow,
  [CHART_STATUS_BAD]: Config.common.colorsEvaluation.red,
}
