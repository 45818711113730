import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import { DEFAULT_OPTIONS } from "services/charts/default"

const MatchingBreakdownBarStackedChartAtom = ({
  chartData = { labels: [], datasets: [] },
}) => {
  const { t } = useTranslation()

  const generateData = useCallback(() => {
    const translatedEquipmentTypes = chartData.labels.map(
      (labelEquipmentType) => t(`common:equipment_types.${labelEquipmentType}`)
    )

    const datasets = chartData.datasets.map((dataset, index) => ({
      label: t(`collect:qualities.analytics.${dataset.label}`),
      data: dataset.data,
      count: dataset.count,
      backgroundColor: [
        Config.common.colorsEvaluation.green,
        Config.common.colors[0],
        Config.common.colorsEvaluation.yellow,
      ][index % 3],
      stack: 1,
    }))

    let resultats = []

    for (let i = 0; i < datasets[0].data.length; i++) {
      let somme = _.sumBy(datasets, (dataset) => dataset.data[i])
      resultats.push(somme)
    }

    const valueMax = Math.max(...resultats)

    datasets.Max = valueMax

    return {
      labels: translatedEquipmentTypes,
      datasets: datasets,
    }
  }, [chartData])
  return <Bar options={DEFAULT_OPTIONS} data={generateData()} />
}

MatchingBreakdownBarStackedChartAtom.propTypes = {
  chartData: PropTypes.object,
}

export default MatchingBreakdownBarStackedChartAtom
