import SdgIcon from "assets/images/sdg"
import classNames from "classnames/bind"
import ProgressBar from "components/widgets/ProgressBar"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Link } from "react-router-dom"
import "stylesheets/ObjectiveTabs.scss"
import { PILLARS_OBJECTIVES } from "utils/constants/pillars"

const ObjectiveTabs = ({ pageName, pillar, objective, progress }) => {
  const objectives = PILLARS_OBJECTIVES[pillar]

  const isObjectiveIn1211 = useCallback(
    () => ["1", "2", "11"].includes(objective),
    [objective]
  )

  const objectiveListMarkup = useCallback(() => {
    return objectives.map((objectiveId, i) => {
      if (pageName === "analyze" && objectiveId !== 13) {
        return
      }
      return (
        <li key={i}>
          <Link to={`/${pageName}/${pillar}/${objectiveId}`}>
            <button
              className={classNames(pillar, {
                selected: objective == objectiveId,
                selected_three:
                  [1, 2, 11].includes(objectiveId) && isObjectiveIn1211(),
              })}
            >
              <SdgIcon id={objectiveId} />
              {!progress ? null : (
                <ProgressBar
                  progress={progress}
                  pillarId={pillar}
                  objectiveId={`${objectiveId}`}
                />
              )}
            </button>
          </Link>
        </li>
      )
    })
  }, [pillar, objective, progress])

  return objectives ? (
    <ul className="objectivesMenu">{objectiveListMarkup()}</ul>
  ) : null
}

ObjectiveTabs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pillar: PropTypes.string.isRequired,
  objective: PropTypes.string,
  progress: PropTypes.object,
}

export default ObjectiveTabs
