import PropTypes from "prop-types"
import React from "react"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"

const UploadOrganism = ({ centralisedComponent, decentralisedComponent }) => {
  const { collect } = useCollectContext()
  const { module } = useModuleContext()

  if (!module)
    console.error(`No module defined for one of the ${UploadOrganism}`)

  if (!centralisedComponent && !decentralisedComponent) {
    return <></>
  }

  const isCentralized =
    collect?.centralization &&
    Object.keys(collect.centralization).some(
      (key) => key === module && collect.centralization[key].centralized
    )

  return isCentralized ? centralisedComponent : decentralisedComponent
}

UploadOrganism.propTypes = {
  centralisedComponent: PropTypes.element.isRequired,
  decentralisedComponent: PropTypes.element.isRequired,
}

export default UploadOrganism
