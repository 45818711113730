import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import "stylesheets/ScorePage/BarChart.scss"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const Objective1_2_11Score = ({ score, collect }) => {
  const { t } = useTranslation()

  const socialReference = score.social_references
  const goodProjects = score.good_projects
  const localCommunityInitiatives = score.local_community_initiatives

  const translationPrefix = `score:score.1.`

  const chart1 = useCallback(() => {
    const total_initiatives_number = score["odd1_2_11_total_initiatives_number"]

    const content = {
      kpi: total_initiatives_number,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
    }

    const content_for_display = <TextChartMajor content={content} />

    return <TextChart content={content_for_display} />
  }, [score])

  const chart2 = useCallback(() => {
    const total_initiative_budget = score["odd1_2_11_total_initiative_budget"]

    const content = {
      kpi: total_initiative_budget,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return <TextChart content={content_for_display} />
  }, [score])

  const chart3 = useCallback(() => {
    let data = [
      { label: t(translationPrefix + "chart3.poverty"), data: 0 },
      { label: t(translationPrefix + "chart3.hunger"), data: 0 },
      { label: t(translationPrefix + "chart3.education"), data: 0 },
      { label: t(translationPrefix + "chart3.other"), data: 0 },
    ]

    let totalNumberInitiative = 0

    if (localCommunityInitiatives.length > 0) {
      localCommunityInitiatives.forEach((localCommunityInitiative) => {
        if (localCommunityInitiative.initiative_type === "poverty") {
          data[0].data += 1
        } else if (localCommunityInitiative.initiative_type === "hunger") {
          data[1].data += 1
        } else if (localCommunityInitiative.initiative_type === "education") {
          data[2].data += 1
        } else if (localCommunityInitiative.initiative_type === "other") {
          data[3].data += 1
        }
        totalNumberInitiative += 1
      })
    }

    if (goodProjects.length > 0) {
      goodProjects.forEach((goodProject) => {
        if (goodProject.initiative_type === "poverty") {
          data[0].data += 1
        } else if (goodProject.initiative_type === "hunger") {
          data[1].data += 1
        } else if (goodProject.initiative_type === "education") {
          data[2].data += 1
        } else if (goodProject.initiative_type === "other") {
          data[3].data += 1
        }
        totalNumberInitiative += 1
      })
    }

    if (totalNumberInitiative === 0)
      return (
        <TextChart
          content={
            <TextChartMinor
              content={{
                kpi_text: t(translationPrefix + "chart3.noData"),
              }}
            />
          }
        />
      )

    return (
      <DoughnutChart
        title={t(translationPrefix + "chart3.title")}
        centerText={totalNumberInitiative}
        centerTextUnit={t(translationPrefix + "chart3.unit")}
        data={data}
        large={false}
      />
    )
  }, [localCommunityInitiatives, goodProjects])

  const chart4 = useCallback(() => {
    let labels = [
      t(translationPrefix + "chart4.patronage"),
      t(translationPrefix + "chart4.foundation"),
      t(translationPrefix + "chart4.social_intrapreneuriat"),
      t(translationPrefix + "chart4.other"),
      t(translationPrefix + "chart4.good_projects"),
    ]

    let local_community_initiatives_number = 0
    let local_community_initiatives_total_budget = 0
    let good_projects_number = 0
    let good_projects_total_budget = 0

    localCommunityInitiatives.forEach((e) => {
      local_community_initiatives_number++
      local_community_initiatives_total_budget += +e.budget
    })

    goodProjects.forEach((e) => {
      good_projects_number++
      good_projects_total_budget += +e.budget
    })

    const bar_data = {
      label: t(translationPrefix + "chart4.budget"),
      data: [
        collect.patronage_budget,
        collect.foundation_budget,
        collect.social_intrapreneuriat_budget,
        local_community_initiatives_total_budget,
        good_projects_total_budget,
      ],
      suffix: t(translationPrefix + "chart4.unit_right_axis"),
    }

    const line_data = {
      label: t(translationPrefix + "chart4.project_number"),
      data: [
        collect.patronage_number_of_intiatives,
        collect.foundation_number_of_intiatives,
        collect.social_intrapreneuriat_number_of_intiatives,
        local_community_initiatives_number,
        good_projects_number,
      ],
      suffix: "",
    }

    const data = { bar_data, line_data }

    return (
      <MixedChart
        title={t(translationPrefix + "chart4.title")}
        data_inputs={data}
        labels={labels}
        unit_right_axis={t(translationPrefix + "chart4.unit_right_axis")}
        unit_left_axis=""
      />
    )
  }, [collect, localCommunityInitiatives, goodProjects])

  const chart5 = useCallback(() => {
    const mission_driven_companies = Math.round(
      socialReference["odd1_2_11_mission_driven_enterprise_CAC_40"]
    )
    const mission_driven_companies_ref_date =
      socialReference["odd1_2_11_mission_driven_enterprise_CAC_40_ref_date"]

    const content = {
      kpi: mission_driven_companies,
      kpi_unit: t(translationPrefix + "chart5.unit"),
      kpi_text: t(translationPrefix + "chart5.text"),
      reference: mission_driven_companies_ref_date,
      translationPrefix_chartX: translationPrefix + "chart5.",
    }

    const content_for_display = <TextChartMinor content={content} />

    return <TextChart content={content_for_display} />
  }, [socialReference])

  const chart6 = useCallback(() => {
    const patronage_budget_rate =
      socialReference["odd1_2_11_patronage_budget_CAC_40"]
    const patronage_budget_increase_rate =
      socialReference["odd1_2_11_patronage_budget_increase_CAC_40"]
    const patronage_budget_increase_CAC_40_ref_date =
      socialReference["odd1_2_11_patronage_budget_increase_CAC_40_ref_data"]

    const content = (
      <div>
        <p className="em">
          {patronage_budget_rate} {t(translationPrefix + "chart6.unit")}
        </p>
        <p>{t(translationPrefix + "chart6.average_turnover")}</p>
        <br />
        <p>
          {t(translationPrefix + "chart6.increase_part1")}
          {patronage_budget_increase_rate}
          {t(translationPrefix + "chart6.increase_part2")}
          {patronage_budget_increase_CAC_40_ref_date}
          {t(translationPrefix + "chart6.increase_part3")}
        </p>
      </div>
    )

    return <TextChart content={content} />
  }, [socialReference])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd1_2_11_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective1_2_11Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective1_2_11Score
