import React from "react"
import "stylesheets/blocs/organisms/tables/ReportsList.scss"
import { useReportPageContext } from "views/contexts/ReportPageContext"
import AddButtonEmptyTableReportMolecule from "views/molecules/reports/table/AddButtonEmptyTableReportMolecule"
import RowTableReportMolecule from "views/molecules/reports/table/RowTableReportMolecule"
import LoadingSpinnerButtonReportMolecule from "views/molecules/reports/table/LoadingSpinnerButtonReportMolecule"

const TableContentReportMolecule = () => {
  const { reports, reportsLoading, reportsReloading } = useReportPageContext()

  const getTableElements = () => {
    if (reports.length === 0) {
      return <AddButtonEmptyTableReportMolecule />
    }

    return reports.map((report, index) => (
      <RowTableReportMolecule report={report} key={index} />
    ))
  }

  if (reportsLoading || reportsReloading) {
    return <LoadingSpinnerButtonReportMolecule />
  } else {
    return getTableElements()
  }
}

export default TableContentReportMolecule
