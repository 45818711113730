import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import "stylesheets/widgets/ScoreValue.scss"

const ScoreValue = ({ score, light, pillarId, chart, category }) => {
  return (
    <div
      className={classNames(pillarId, category, {
        "Verdikt-ScoreValue": true,
        "Verdikt-ScoreValue--disabled": !score,
        light: light,
        chart: chart,
      })}
    >
      <div>{score}</div>
      <div></div>
      <div>100</div>
    </div>
  )
}

ScoreValue.propTypes = {
  score: PropTypes.number,
  light: PropTypes.bool,
  chart: PropTypes.bool,
  pillarId: PropTypes.string,
  category: PropTypes.string,
}

ScoreValue.defaultProps = {
  score: null,
  category: null,
}

export default ScoreValue
