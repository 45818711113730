import React, { useEffect, useState } from "react"
import { Banner, Button, ExceptionList, Select } from "@shopify/polaris"
import { ADMIN_ROLE, VIEWER_ROLE } from "utils/constants/userRoles"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { RiskMinor } from "@shopify/polaris-icons"

const OrganizationMembersFormMolecule = ({
  onSubmit,
  members,
  isLoading,
  error,
}) => {
  const [memberId, setMemberId] = useState("")
  const [role, setRole] = useState("")
  const [showWarning, setShowWarning] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const member = members.find(({ id }) => id === memberId)

    if (member && member.assigned_on_children) {
      setShowWarning(true)
    } else {
      setShowWarning(false)
    }
  }, [memberId])

  return (
    <>
      <form className="node-modal__form-add-members">
        {error && <Banner status="danger">{error}</Banner>}
        <Select
          disabled={isLoading || members.length < 1}
          labelHidden
          placeholder={t("activation:common.members.form.user.placeholder")}
          options={members.map((member) => ({
            value: `${member.id}`,
            label: member.email,
          }))}
          onChange={setMemberId}
          value={memberId}
        />
        <Select
          disabled={isLoading || members.length < 1}
          labelHidden
          placeholder={t("activation:common.members.form.role.placeholder")}
          options={[
            {
              label: t("common:user_form.roles.viewer"),
              value: VIEWER_ROLE,
            },
            {
              label: t("common:user_form.roles.admin"),
              value: ADMIN_ROLE,
            },
          ]}
          onChange={setRole}
          value={role}
        />
        <Button
          loading={isLoading}
          disabled={
            members.length < 1 || memberId.length < 1 || role.length < 1
          }
          onClick={() => {
            onSubmit(memberId, role)
            setMemberId("")
          }}
        >
          {t("activation:common.members.form.submit")}
        </Button>
      </form>
      {showWarning && (
        <ExceptionList
          items={[
            {
              icon: RiskMinor,
              description: t(
                "activation:common.members.warnings.on_child_node"
              ),
            },
          ]}
        />
      )}
    </>
  )
}

export default OrganizationMembersFormMolecule

OrganizationMembersFormMolecule.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  members: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
