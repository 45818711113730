import React from "react"
import LifetimeHypothesesMolecule from "views/molecules/recommendations/hypotheses/LifetimeHypothesesMolecule"
import PropTypes from "prop-types"
import LessPaperHypothesesMolecule from "views/molecules/recommendations/hypotheses/LessPaperHypothesesMolecule"
import RelocateHypothesesMolecule from "views/molecules/recommendations/hypotheses/RelocateHypothesesMolecule"
import {
  LESS_PAPER_LEVER,
  LIFETIME_LEVER,
  RELOCATE_LEVER,
} from "utils/constants/recommendations/propositions"

const HypothesesMolecule = ({ proposition, onChange }) => {
  switch (proposition.lever) {
    case LIFETIME_LEVER:
      return (
        <LifetimeHypothesesMolecule
          onChange={onChange}
          value={proposition.simulation_detail.new_lifetime_in_year}
          equipmentType={proposition.simulation_detail.equipment_type}
        />
      )
    case LESS_PAPER_LEVER:
      return (
        <LessPaperHypothesesMolecule
          value={proposition.simulation_detail.paper_saving_in_percent}
          onChange={onChange}
        />
      )
    case RELOCATE_LEVER:
      return (
        <RelocateHypothesesMolecule
          onChange={onChange}
          value={proposition.simulation_detail.new_country}
        />
      )
    default:
      return null
  }
}

HypothesesMolecule.propTypes = {
  proposition: PropTypes.shape({
    pillar: PropTypes.string.isRequired,
    sdg: PropTypes.number.isRequired,
    topic: PropTypes.string.isRequired,
    kpi: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    lever: PropTypes.string.isRequired,
    impact: PropTypes.string.isRequired,
    complexity: PropTypes.string.isRequired,
    simulation_detail: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default HypothesesMolecule
