import PropTypes from "prop-types"
import React, { useCallback } from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Config from "assets/configs/score.js"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import ChartBadge from "views/atoms/badges/ChartBadge"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { COLORS } from "utils/constants/colors"
import _ from "lodash"
import { tooltipsLabelWithUnitValue } from "services/charts/tooltips"
import classNames from "classnames"
import { PILLAR_PROFIT } from "utils/constants/pillars"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import NoChartDataOrganism from "views/molecules/canvases/charts/profit/NoChartDataOrganism"
import NoDashboardDataOrganism from "views/molecules/canvases/charts/profit/NoDashboardDataOrganism"

const budgetColor = (sustainableLabel) =>
  sustainableLabel === "sustainable"
    ? Config.common.colors[1]
    : sustainableLabel === "not_sustainable"
    ? COLORS.danger.secondary
    : COLORS.neutral.c10

const chart4Config = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const plainValue =
          data.datasets[tooltipItem.datasetIndex].plain_data[tooltipItem.index]

        const unit = data.datasets[tooltipItem.datasetIndex].unit

        return `${data.labels[tooltipItem.index]}: ${plainValue} ${unit}`
      },
    },
  },
}

const SUSTAINABLE_INDEX = 0
const NOT_SUSTAINABLE_INDEX = 1
const CLIENT_INDEX = 0
const INTERNAL_INDEX = 1

const changeColorForHighestValue = (
  dataIndex,
  highestColor,
  values,
  colors
) => {
  const client = values[CLIENT_INDEX][dataIndex]
  const internal = values[INTERNAL_INDEX][dataIndex]
  if (client > internal) colors[CLIENT_INDEX][dataIndex] = highestColor
  if (internal > client) colors[INTERNAL_INDEX][dataIndex] = highestColor
}

const COLORS_CHART_3_6 = [COLORS.corporate.c30, COLORS.warning.light]

const chart4FallbackConfig = {
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const plainValue =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

        const unit = data.datasets[tooltipItem.datasetIndex].unit

        return `${data.labels[tooltipItem.index]}: ${plainValue} ${unit}`
      },
    },
  },
}

const NoProjects = () => {
  const { t } = useTranslation()

  return <NoChartDataOrganism description={t("score:score.8.no_projects")} />
}
const NoBudgets = () => {
  const { t } = useTranslation()

  return <NoChartDataOrganism description={t("score:score.8.no_budgets")} />
}

const Objective8Score = ({ score }) => {
  const { t } = useTranslation()

  const chart1 = useCallback(() => {
    if (score.inventories.projects === 0) return <NoProjects />

    const content = {
      kpi: score.odd8_projects_sustainable_percentage || 0,
      kpi_unit: "%",
      kpi_text: t("score:score.8.chart1.kpi_text"),
      reference_unit: " %",
      reference_text: t("score:score.8.chart1.reference_text"),
      reference: score.odd8_projects_sustainable_budget_percentage,
    }

    const contentForDisplay = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart1.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    if (score.inventories.budgets === 0) return <NoBudgets />

    const badge = (
      <ChartBadge
        value={score.odd8_budgets_sustainable_percentage}
        lowerLimit={40}
        upperLimit={70}
      />
    )

    const { value, unit_key } = score.odd8_budgets_sustainable_sum_converted

    const content = {
      reference: badge,
      kpi: score.odd8_budgets_sustainable_percentage || 0,
      kpi_unit: t("score:score.8.chart2.kpi_unit"),
      kpi_text: t("score:score.8.chart2.kpi_text"),
      sub_kpi: value,
      sub_kpi_unit: `${t(`units:units.money.${unit_key}`)} ${t(
        "score:score.8.chart2.sub_kpi_text"
      )}`,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart2.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    if (score.inventories.projects === 0) return <NoProjects />

    const {
      values,
      unit_key,
    } = score.odd8_sustainable_projects_budget_breakdown_chart

    const unit = t(`units:units.money.${unit_key}`)

    const title = t(`score:score.8.chart3.budget.labels.title`)
    const labels = [
      t(`score:score.8.chart3.budget.labels.sustainable`),
      t(`score:score.8.chart3.budget.labels.not_sustainable`),
    ]

    const lineLabels = [
      {
        label: t(`score:score.8.chart3.budget.labels.business`),
        shortLabel: t(`score:score.8.chart3.budget.shortLabels.business`),
      },
      {
        label: t(`score:score.8.chart3.budget.labels.it`),
        shortLabel: t(`score:score.8.chart3.budget.shortLabels.it`),
      },
    ]

    const lineColors3 = [[...COLORS_CHART_3_6], [...COLORS_CHART_3_6]]

    changeColorForHighestValue(
      SUSTAINABLE_INDEX,
      COLORS.profit.primary,
      values,
      lineColors3
    )
    changeColorForHighestValue(
      NOT_SUSTAINABLE_INDEX,
      COLORS.danger.primary,
      values,
      lineColors3
    )

    const data = values.map((value, index) => ({
      ...lineLabels[index],
      data: value,
      stack: 0,
      backgroundColor: lineColors3[index],
      unit,
    }))

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart3.help")}>
        <div
          className={classNames(
            "Verdikt-Score-HorizontalBarChart",
            PILLAR_PROFIT
          )}
        >
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={{ labels: labels, datasets: data }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    color: COLORS.white,
                    font: {
                      weight: "bold",
                    },
                    textAlign: "center",
                    formatter: (value, context) => {
                      if (+value <= 0) return ""

                      const { shortLabel, unit } = context.dataset
                      return `${shortLabel}: ${value} ${unit}`
                    },
                  },
                },
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      const { value, datasetIndex } = tooltipItem
                      const { label, unit } = data.datasets[datasetIndex]

                      return `${label}: ${value} ${unit}`
                    },
                  },
                },
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: unit,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart4 = useCallback(() => {
    if (score.inventories.budgets === 0) return <NoBudgets />

    const {
      values,
      unit_key,
    } = score.odd8_sustainable_budget_per_category_chart

    const unit = t(`units:units.money.${unit_key}`)

    if (values.length < 2) return null

    const data = values.map((value, index) => ({
      label: t(`score:score.8.chart4.labels.${value.label_key}`),
      data: value.data,
      plain_data: value.plain_data,
      unit: unit,
      backgroundColor: Config.common.colors[index],
    }))

    const total = Math.round(_.sumBy(values, (value) => value.plain_data))
    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart4.help")}>
        <DoughnutChart
          title={t("score:score.8.chart4.title")}
          data={data}
          config={chart4Config}
          centerText={total}
          centerTextUnit={unit}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart4Fallback = useCallback(() => {
    const { values, unit_key } = score.odd8_biggest_budgets

    const unit = t(`units:units.money.${unit_key}`)

    const data = values.map(({ label, data, sustainable }) => ({
      data,
      label:
        label === "others"
          ? t(`score:score.8.chart4Fallback.labels.others`)
          : label,
      color: budgetColor(sustainable),
      unit: unit,
    }))

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.8.chart4Fallback.help")}
      >
        <DoughnutChart
          title={t("score:score.8.chart4Fallback.title")}
          data={data}
          config={chart4FallbackConfig}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart5 = useCallback(() => {
    if (score.inventories.projects === 0) return <NoProjects />

    const value = score.odd8_projects_eco_designed_percentage || 0
    const badge = <ChartBadge value={value} lowerLimit={10} upperLimit={40} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.8.chart5.kpi_unit"),
      kpi_text: t("score:score.8.chart5.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart5.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    if (score.inventories.budgets === 0) return <NoBudgets />

    const {
      values,
      unit_key,
    } = score.odd8_sustainable_budget_split_by_type_chart
    const title = t("score:score.8.chart6.labels.title")
    const unit = t(`units:units.money.${unit_key}`)

    let labels = [
      t("score:score.8.chart6.labels.sustainable"),
      t("score:score.8.chart6.labels.not_sustainable"),
    ]

    const lineLabels = [
      {
        label: t(`score:score.8.chart6.labels.opex`),
        shortLabel: t(`score:score.8.chart3.budget.shortLabels.business`),
      },
      {
        label: t(`score:score.8.chart6.labels.capex`),
        shortLabel: t(`score:score.8.chart3.budget.shortLabels.it`),
      },
    ]

    const lineColors6 = [[...COLORS_CHART_3_6], [...COLORS_CHART_3_6]]

    changeColorForHighestValue(
      SUSTAINABLE_INDEX,
      COLORS.profit.primary,
      values,
      lineColors6
    )
    changeColorForHighestValue(
      NOT_SUSTAINABLE_INDEX,
      COLORS.danger.primary,
      values,
      lineColors6
    )

    const data = values.map((value, index) => ({
      ...lineLabels[index],
      data: value,
      stack: 0,
      backgroundColor: lineColors6[index],
      unit,
    }))

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart6.help")}>
        <div
          className={classNames(
            "Verdikt-Score-HorizontalBarChart",
            PILLAR_PROFIT
          )}
        >
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={{ labels: labels, datasets: data }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    color: COLORS.white,
                    font: {
                      weight: "bold",
                    },
                    formatter: (value, context) => {
                      if (+value <= 0) return ""

                      const { label, unit } = context.dataset
                      return `${label}: ${value} ${unit}`
                    },
                  },
                },
                legend: {
                  display: false,
                },
                tooltips: {
                  callbacks: {
                    label: tooltipsLabelWithUnitValue,
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: unit,
                      },
                    },
                  ],
                },
              }}
              plugins={[ChartDataLabels]} // include the datalabels plugin
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  if (score.inventories.budgets === 0 && score.inventories.projects === 0)
    return (
      <NoDashboardDataOrganism
        maturity={score.odd9_maturity}
        description={t("score:score.8.no_budgets_nor_projects")}
      />
    )

  return (
    <ScoreLayoutOrganism
      maturity={score.odd8_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4() ? chart4() : chart4Fallback()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective8Score.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Objective8Score
