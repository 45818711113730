import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Config from "assets/configs/score"
import { RETURN_OBJECTS } from "services/translations/config"

const ChartSdg13WorkplaceEquipmentPerAllocation = ({ score }) => {
  const { t } = useTranslation()

  const { title, help } = t(
    "score:score.13.chartSplitPerAllocation",
    RETURN_OBJECTS
  )

  const generateData = () => {
    const {
      datasets,
      unit_key,
    } = score.odd13_workplace_emission_split_per_allocation
    return datasets.map((dataset, index) => ({
      label: dataset.label === "other" ? t("common:other") : dataset.label,
      data: dataset.data,
      color: Config.common.colors[index % Config.common.colors.length],
      unit: t(`units:units.emission.${unit_key}`),
    }))
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <DoughnutChart title={title} data={generateData()} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSdg13WorkplaceEquipmentPerAllocation.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartSdg13WorkplaceEquipmentPerAllocation
