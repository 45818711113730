import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import "stylesheets/widgets/Dashboard/ChartNode.scss"
import DottedButton from "components/widgets/Dashboard/OrganizationChart/DottedButton"
import NodeOrganization from "services/organizations/NodeOrganization"
import ChartNodeChildrenMolecule from "views/molecules/organization/ChartNodesChildrenMolecule"
import { COLORS } from "utils/constants/colors"
import NodeAction from "components/widgets/Dashboard/OrganizationChart/NodeActions"
import ChartNodeHeaderMolecule from "views/molecules/organization/ChartNodeHeaderMolecule"
import ChartElementBodyAtom from "views/molecules/organization/ChartElementBodyAtom"
import { useAppContext } from "components/AppContext"
import ChartNodeOrganizationsMolecule from "views/molecules/organization/ChartOrganizationsMolecule"
import { CONTEXT_TYPE_CORPORATE } from "utils/constants/organization/contextTypes"
import { ORGANIZATION_CATEGORY_TO_COLOR } from "utils/constants/organization/nodeTypes"
import NodeEntity from "services/entities/NodeEntity"
import { useEntity } from "hooks/useAxios"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"

const NUMBER_OF_LEAVES_BEFORE_COLUMN = 3

const ChartNodeMolecule = forwardRef(
  ({ node, onCloseSiblings, isParentColumn }, ref) => {
    const nodeRef = useRef()
    const { currentNode, currentOrganization } = useAppContext()
    const { reload } = useChart()
    const nodeEntity = useEntity(NodeEntity)

    const [isChildrenBottomCollapsed, setIsChildrenBottomCollapsed] = useState(
      !node.isCurrentNodeBelow ?? false
    )
    const [isChildrenRightCollapsed, setIsChildrenRightCollapsed] = useState(
      true
    )
    const [childrenPosition, setChildrenPosition] = useState("bottom")

    useEffect(() => {
      if (node.isCurrentNodeBelow) {
        const isScopeIdOnBottom = node.children.find(
          (child) =>
            child.isCurrentNodeBelow ||
            NodeOrganization.isNodeCurrentScope(child, currentNode)
        )

        if (isScopeIdOnBottom) {
          onCloseSiblings()
          setIsChildrenBottomCollapsed(false)
          setChildrenPosition("bottom")
        } else if (node.organizations.length > 0) {
          onCloseSiblings()
          setIsChildrenRightCollapsed(false)
          setChildrenPosition("right")
        }
      }
    }, [node])

    const nodeClass = useCallback(
      () =>
        NodeOrganization.getNodeClass(
          node,
          !isChildrenBottomCollapsed,
          NodeOrganization.isNodeCurrentScope(node, currentNode),
          !isChildrenRightCollapsed,
          node.discarded || node.parent_discarded
        ),
      [isChildrenBottomCollapsed, isChildrenRightCollapsed, node]
    )

    /**
     * @description Add a blue border to the node if it corresponds to the current scope
     * @event If the current scope is undefined, display the N-1 level of children.
     */
    useEffect(() => {
      if (!currentNode) {
        setIsChildrenBottomCollapsed(node.type !== "company")
        return
      }

      setIsChildrenBottomCollapsed(!node.isCurrentNodeBelow)
    }, [currentNode])

    const toggleChildrenViewRight = () => {
      if (isChildrenRightCollapsed) {
        onCloseSiblings()
        setChildrenPosition("right")
      }

      setIsChildrenRightCollapsed(!isChildrenRightCollapsed)
      setIsChildrenBottomCollapsed(true)
    }

    const toggleChildrenBottomView = () => {
      if (isChildrenBottomCollapsed) {
        onCloseSiblings()
        setChildrenPosition("bottom")
      }

      setIsChildrenBottomCollapsed(!isChildrenBottomCollapsed)
      setIsChildrenRightCollapsed(true)
    }

    useEffect(
      () =>
        node.context_type === CONTEXT_TYPE_CORPORATE &&
        NodeOrganization.isNodeCurrentScope(node, currentNode) &&
        !hasChildren &&
        hasOrganizations &&
        toggleChildrenViewRight(),
      []
    )

    useImperativeHandle(ref, () => ({
      closeChildren() {
        setIsChildrenRightCollapsed(true)
        setIsChildrenBottomCollapsed(true)
      },
    }))

    const hasOrganizations = node.organizations.length >= 1

    const hasChildren = node.children.length >= 1

    const childrenColor =
      ORGANIZATION_CATEGORY_TO_COLOR[node.organization_category]

    const classNames = [
      "oc-hierarchy",
      childrenPosition,
      node.organizations.length > 0 ? "children-right" : "",
      node.children.length > 0 ? "children-bottom" : "",
      node.organization_category,
      !isChildrenRightCollapsed ? "children-right-expanded" : "",
    ].join(" ")

    const manyChildrenOnlyLeaves = useCallback(
      () =>
        node.children.length > NUMBER_OF_LEAVES_BEFORE_COLUMN &&
        node.children.every((child) => child.children.length === 0),
      [node]
    )

    const discardedToggle = async () => {
      await nodeEntity.update(node.id, { discarded: !node.discarded })
      reload()
    }

    return (
      <li className={classNames}>
        <div ref={nodeRef} className={nodeClass()}>
          <ChartElementBodyAtom
            progress={node.progress}
            actionComponent={<NodeAction node={node} />}
            headerComponent={
              <ChartNodeHeaderMolecule
                node={node}
                onDiscardedToggle={discardedToggle}
                isDiscarded={node.discarded}
              />
            }
            isPartialPartner={currentOrganization?.is_partial_partner}
          />

          {hasChildren && (
            <DottedButton
              onClick={toggleChildrenBottomView}
              position="bottom"
              color={childrenColor}
            />
          )}

          {hasOrganizations && (
            <DottedButton
              onClick={toggleChildrenViewRight}
              position="right"
              color={COLORS.danger.dark}
            />
          )}
        </div>

        <ChartNodeChildrenMolecule
          node={node}
          position="bottom"
          isParentColumn={manyChildrenOnlyLeaves()}
          hidden={isChildrenBottomCollapsed}
        />

        <ChartNodeOrganizationsMolecule
          node={node}
          position="right"
          hidden={isChildrenRightCollapsed}
        />
      </li>
    )
  }
)

ChartNodeMolecule.displayName = "ChartNodeMolecule"

ChartNodeMolecule.propTypes = {
  node: PropTypes.object.isRequired,
  onCloseSiblings: PropTypes.func,
  isParentColumn: PropTypes.bool,
}

ChartNodeMolecule.defaultProps = {
  onCloseSiblings: () => {},
  isParentColumn: false,
}

export default ChartNodeMolecule
