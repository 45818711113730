import React, { useEffect } from "react"
import ScoreWidget from "components/widgets/ScorePage/widgets/ScoreWidget.jsx"
import Recommendations from "components/widgets/ImprovePage/Recommendations"
import { Heading } from "@shopify/polaris"
import "stylesheets/ImprovePage/RecommendationsLayout.scss"
import { useTranslation } from "react-i18next"
import { useImproveContext } from "components/pages/ImproveMaturityPage"
import PropTypes from "prop-types"

const aliasNameForTheme = {
  social: {
    1: {
      societal_commitments: "odd1_2_11_maturity",
    },
    2: {
      societal_commitments: "odd1_2_11_maturity",
    },
    11: {
      societal_commitments: "odd1_2_11_maturity",
    },
    3: {
      well_being: "odd3_maturity",
    },
    4: {
      education: "odd4_maturity",
    },
    5: {
      parity: "odd5_maturity",
    },
  },
}

const RecommendationsLayout = ({ pillar, objective, theme }) => {
  const { t } = useTranslation()
  const { recommendations, score, getScore } = useImproveContext()

  useEffect(() => {
    getScore(pillar)
  }, [pillar])

  const recommendationDescriptionGetter = (recommendation) => {
    return t(`recommendation:${recommendation.recommendationKey}`)
  }

  const sdgGetter = (recommendation) => {
    const regex = /^odd([0-9]+_[0-9]+_[0-9]+|[0-9]+)/
    return recommendation.recommendationKey.match(regex)[1]
  }

  /**
   * @description This function iterates though the objectivesThemes object and returns Recommendations for every theme in every objective
   * @returns a Recommendations component, which is a <table> element containing all recommendations per theme, or a <p> element
   */
  const displayRecommendations = () => {
    if (recommendations && recommendations.length > 0)
      return (
        <Recommendations
          pillar={pillar}
          objective={objective}
          theme={theme}
          recommendations={recommendations}
          recommendationDescriptionGetter={recommendationDescriptionGetter}
          sdgGetter={sdgGetter}
        />
      )
    else return <p>{t(`improve:recommendations_table.empty`)}</p>
  }

  /**
   * @description Displays a Heading if we are in summary view.
   * @returns a div containing the Header component
   */
  const displayHeading = () => {
    let scope
    if (theme) scope = "theme"
    else if (objective) scope = "objective"
    else scope = "pillar"
    return (
      <div className="recommendations__headingContainer">
        <Heading element="h1">
          {t(`improve:heading.synthesis.${scope}`)}
        </Heading>
      </div>
    )
  }

  const maturityThemeName = () => {
    const aliasName = aliasNameForTheme?.[pillar]?.[objective]?.[theme]

    return aliasName || `odd${objective}_${theme}_maturity`
  }

  /**
   * @description Displays a ScoreWidget with different style and data depending on whether it is the sdg score or the theme score
   * @returns a ScoreWidget with different props depending on the case
   */
  const displayScore = () => {
    if (theme) {
      const maturityThemeScore = score[maturityThemeName()]

      if (maturityThemeScore != null) {
        return (
          /* score for theme */
          <ScoreWidget
            maturity={maturityThemeScore}
            hasIcon={false}
            isCentered={false}
            pillarId={pillar}
          />
        )
      } else return null
    } else if (score[`odd${objective}_maturity`] != null)
      return (
        /* score for sdg */
        <ScoreWidget
          maturity={score[`odd${objective}_maturity`]}
          isCentered={false}
          pillarId={pillar}
        />
      )
    else if (score[`pillar_maturity`] != null)
      /* score for pillar */
      return (
        <ScoreWidget
          maturity={score[`pillar_maturity`]}
          isCentered={false}
          pillarId={pillar}
        />
      )
    else return null
  }

  return (
    <div className={`recommendationsLayout recommendationsLayout--${pillar}`}>
      <div className="recommendationsLayout__score">
        {score ? displayScore() : null}
      </div>
      <div className="recommendationsLayout__recommendations">
        {displayHeading()}
        {displayRecommendations()}
      </div>
    </div>
  )
}

RecommendationsLayout.propTypes = {
  objective: PropTypes.string,
  pillar: PropTypes.string,
  theme: PropTypes.string,
}

export default RecommendationsLayout
