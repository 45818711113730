import React from "react"
import { Collapsible } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const cropText = (text, length) => {
  if (text.length <= length) return text
  return `${text.substring(0, length)}...`
}

const WebsiteRecommendationTable = ({ open, header, rows }) => {
  const { t } = useTranslation()

  return (
    <tr>
      <td colSpan={3}>
        <Collapsible open={open} transition id="recommendation">
          <table className="Verdikt-DynamicTable">
            <thead>
              <tr className="leftAligned">
                {header.map((field, index) => (
                  <th key={index}>
                    {t(`recommendation:websites.table.header.${field}`)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={i}>
                  {row.map((cell, j) => (
                    <td key={j}>{cropText(`${cell || ""}`, 200)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Collapsible>
      </td>
    </tr>
  )
}

WebsiteRecommendationTable.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.array,
  rows: PropTypes.array,
}

WebsiteRecommendationTable.defaultProps = {
  open: false,
  header: [],
  rows: [],
}

export default WebsiteRecommendationTable
