import React from "react"
import { Checkbox } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const CheckboxFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <Checkbox
      label={t(`activation:${type}.questions.${property}.label`)}
      checked={value}
      error={error}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

CheckboxFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.bool,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CheckboxFieldAtom.defaultProps = {
  value: false,
  node: null,
  error: null,
  disabled: false,
}

export default CheckboxFieldAtom
