import React from "react"
import PropTypes from "prop-types"
import { Select } from "@shopify/polaris"
import { checkHover } from "components/widgets/CollectPage/forms/fields/Functions"
import { useTranslation } from "react-i18next"

const SelectReportAtom = ({ id, value, onChange, options, errors }) => {
  const { t } = useTranslation()

  return (
    <Select
      label={checkHover(`share:content.reports_modal.` + id + `.`)}
      placeholder={t("share:content.reports_modal.default_select_placeholder")}
      value={value}
      options={options}
      onChange={onChange}
      error={errors ? errors[id] : null}
    />
  )
}

SelectReportAtom.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errors: PropTypes.object,
}

export default SelectReportAtom
