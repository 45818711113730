import React from "react"
import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import MultipleChoiceField from "../../../fields/MultipleChoiceField"
import Select from "components/widgets/CollectPage/forms/fields/Select"
import { useTranslation } from "react-i18next"
import { POLICY_AI_PERSONAL_DATA } from "utils/constants/centralization/policyItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const fields = [
  "governance_ia_explainability_policy",
  "governance_ia_risks_of_bias_identification",
  "governance_ia_data_transparency",
  "eco_design_in_ia",
  "governance_ia_monitoring_new_regulations",
  "governance_ia_evaluation_none",
  "governance_ia_evaluation_internal",
  "governance_ia_evaluation_free_tools",
  "governance_ia_evaluation_market_tools",
  "governance_ia_evaluation_consultants",
  "governance_ia_data_quality_gender",
  "governance_ia_data_quality_age",
  "governance_ia_data_quality_ethnicity",
  "governance_ia_data_quality_handicap",
  "governance_ia_public_data_quality_policy",
  "governance_ia_quality_procedure",
  "governance_ia_product_quality_verified",
  "governance_ia_product_quality_reviewed",
  "governance_personal_data_gdpr_compliant",
  "governance_personal_data_close_storage",
  "governance_personal_data_collected_data",
  "governance_personal_data_user_approval",
  "governance_personal_data_data_processing_info",
  "governance_personal_data_expiration_periods",
  "governance_personal_data_expired_data_process",
]

const translationPath = "16.artificial_intelligence"
const tPrefix = `collect:questions.${translationPath}`

const ArtificialIntelligence = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <FormLayout>
        <Module module={POLICY_AI_PERSONAL_DATA}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <ExternalLabel translationPathName="bias" />
                <table>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.bias.identification`)}</td>
                      <td>
                        <Select
                          id="governance_ia_risks_of_bias_identification"
                          options={[
                            "defined",
                            "implemented",
                            "systematic",
                            "none",
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.bias.transparency`)}</td>
                      <td>
                        <YesNoRadio id="governance_ia_data_transparency" />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.bias.data_quality`)}</td>
                      <td>
                        <MultipleChoiceField
                          id="data_quality"
                          choices={[
                            "governance_ia_data_quality_gender",
                            "governance_ia_data_quality_age",
                            "governance_ia_data_quality_ethnicity",
                            "governance_ia_data_quality_handicap",
                          ]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <YesNoRadio id="governance_ia_explainability_policy" />
                <MultipleChoiceField
                  id="evaluation_tools"
                  choices={[
                    "governance_ia_evaluation_none",
                    "governance_ia_evaluation_internal",
                    "governance_ia_evaluation_free_tools",
                    "governance_ia_evaluation_market_tools",
                    "governance_ia_evaluation_consultants",
                  ]}
                />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <MultipleChoiceField
                  id="european_statistics_code_practice"
                  choices={[
                    "governance_ia_public_data_quality_policy",
                    "governance_ia_quality_procedure",
                    "governance_ia_product_quality_verified",
                    "governance_ia_product_quality_reviewed",
                  ]}
                />

                <YesNoRadio id="governance_ia_monitoring_new_regulations" />
                <hr />

                <YesNoRadio id="governance_personal_data_gdpr_compliant" />

                <ExternalLabel translationPathName="data_collection" />
                <table>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.data_collection.collected_data`)}</td>
                      <td>
                        <Select
                          id="governance_personal_data_collected_data"
                          options={["maximum", "user_choice", "essential_only"]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.data_collection.user_approval`)}</td>
                      <td>
                        <YesNoRadio id="governance_personal_data_user_approval" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(`${tPrefix}.data_collection.data_processing_info`)}
                      </td>
                      <td>
                        <YesNoRadio id="governance_personal_data_data_processing_info" />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.data_collection.close_storage`)}</td>
                      <td>
                        <YesNoRadio id="governance_personal_data_close_storage" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.governance_personal_data_expiration_periods.text`
                        )}
                      </td>
                      <td>
                        <YesNoRadio id="governance_personal_data_expiration_periods" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.governance_personal_data_expired_data_process.text`
                        )}
                      </td>
                      <td>
                        <Select
                          id="governance_personal_data_expired_data_process"
                          options={["no_action", "archiving", "deletion"]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default ArtificialIntelligence
