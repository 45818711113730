import PropTypes from "prop-types"
import React from "react"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Unit from "views/atoms/reports/Unit"

const TextChartODD13HighestITDomainEmissionsMolecule = ({ score }) => {
  const { t } = useTranslation()

  const odd13_co2_domain_polluting_most = score?.odd13_co2_domain_polluting_most
  const content = {
    kpi: odd13_co2_domain_polluting_most?.percent,
    kpi_unit: (
      <Unit unit={t(`score:score.13.chartHighestITDomainEmissions.unit`)} />
    ),
    kpi_text: t(
      `score:score.13.chartHighestITDomainEmissions.text.${odd13_co2_domain_polluting_most?.domain}`
    ),
    reference: "",
    reference_text: t(`score:score.13.chartHighestITDomainEmissions.subtext`),
  }
  const content_for_display = <TextChartMajor content={content} />

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartHighestITDomainEmissions.help`)}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13HighestITDomainEmissionsMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13HighestITDomainEmissionsMolecule
