import { useTranslation } from "react-i18next"
import React from "react"
import { FormLayout } from "@shopify/polaris"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import { fieldIsFalsyOrNonPositive } from "components/widgets/CollectPage/useDisablingFields"
import {
  ExternalLabel,
  MultipleChoiceField,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { INVENTORY_INNOVATIONS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const fields = [
  "internal_innov_lean_startup",
  "internal_innov_design_thinking",
  "internal_innov_agile_methodology",
  "internal_innov_agile_at_scale",
  "internal_innov_other",
  "internal_innov_events_projects_proposed",
  "internal_innov_events_csr_criteria",
  "internal_innov_events_csr_share",
  "internal_innov_events_dedicated_time",
  "internal_innov_events_projects_financed",
  "internal_innov_intrapreneur_presence",
  "internal_innov_intrapreneur_projects_proposed",
  "internal_innov_intrapreneur_csr_criteria",
  "internal_innov_intrapreneur_csr_share",
  "internal_innov_intrapreneur_dedicated_time",
  "internal_innov_intrapreneur_projects_financed",
  "internal_innov_new_ideas_platform",
  "internal_innov_new_ideas_platform_internal",
  "internal_innov_new_ideas_platform_external",
]
const translationPath = "9.internal_innovation"
const disableConfig = [
  {
    disablingField: "internal_innov_events_csr_criteria",
    fieldsToDisable: ["internal_innov_events_csr_share"],
  },
  {
    disablingField: "internal_innov_intrapreneur_csr_criteria",
    fieldsToDisable: ["internal_innov_intrapreneur_csr_share"],
  },
  {
    disablingField: "internal_innov_new_ideas_platform",
    fieldsToDisable: [
      "internal_innov_new_ideas_scope",
      "internal_innov_new_ideas_platform_internal",
      "internal_innov_new_ideas_platform_external",
    ],
  },
  {
    disablingField: "internal_innov_events_projects_proposed",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "internal_innov_events_csr_criteria",
      "internal_innov_events_dedicated_time",
      "internal_innov_events_projects_financed",
    ],
  },
  {
    disablingField: "internal_innov_intrapreneur_projects_proposed",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "internal_innov_intrapreneur_csr_criteria",
      "internal_innov_intrapreneur_dedicated_time",
      "internal_innov_intrapreneur_projects_financed",
    ],
  },
]

const InternalInnovationForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath, disableConfig })

  const tPrefix = "collect:questions.9.internal_innovation"

  const generateFieldRow = (field, suffix = "") => (
    <tr>
      <td>{t(`${tPrefix}.${field}`)}</td>
      <td>
        <TextField
          id={`internal_innov_intrapreneur_${field}`}
          type="number"
          suffix={suffix}
        />
      </td>
      <td>
        <TextField
          id={`internal_innov_events_${field}`}
          type="number"
          suffix={suffix}
        />
      </td>
    </tr>
  )

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_INNOVATIONS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <ExternalLabel translationPathName="numbers" />
                <table>
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.numbers.intrapreneur`)}</td>
                      <td>{t(`${tPrefix}.numbers.events`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {generateFieldRow("projects_proposed")}
                    <tr>
                      <td>{t(`${tPrefix}.csr_criteria`)}</td>
                      <td>
                        <YesNoRadio id="internal_innov_intrapreneur_csr_criteria" />
                      </td>
                      <td>
                        <YesNoRadio id="internal_innov_events_csr_criteria" />
                      </td>
                    </tr>
                    {generateFieldRow("csr_share", "%")}
                    {generateFieldRow("dedicated_time", "%")}
                    {generateFieldRow("projects_financed", "%")}
                  </tbody>
                </table>
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <MultipleChoiceField
                  id="process"
                  choices={[
                    "internal_innov_design_thinking",
                    "internal_innov_lean_startup",
                    "internal_innov_agile_methodology",
                    "internal_innov_agile_at_scale",
                    "internal_innov_other",
                  ]}
                />

                <hr />

                <YesNoRadio id="internal_innov_new_ideas_platform" />
                <MultipleChoiceField
                  id="internal_innov_new_ideas_scope"
                  choices={[
                    "internal_innov_new_ideas_platform_internal",
                    "internal_innov_new_ideas_platform_external",
                  ]}
                />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default InternalInnovationForm
