import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import StackedBarChartAtom from "views/atoms/forms/GHGProtocol/StackedBarChartAtom"
import References from "utils/functions/references"

const ChartODD13ScopesStackPerGroupBreakdownMolecule = ({
  score,
  protocol,
  group,
  stack,
}) => {
  const { t } = useTranslation()

  const getData = () => {
    switch (group) {
      case "scopes":
        if (protocol === "ghg_protocol") {
          return score?.emissions_scopes_breakdown_per_ghg_sub_scope
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_scopes_breakdown_per_bc_sub_scope
        }

        break
      case "sub_scopes":
        if (protocol === "ghg_protocol") {
          return score?.emissions_ghg_sub_scopes_breakdown_per_kpi
        } else if (protocol === "bilan_carbone") {
          return score?.emissions_bc_sub_scopes_breakdown_per_kpi
        }

        break
    }
  }

  const generateData = useCallback(() => {
    const dataBeforeTranslations = References.cloneDeep(getData())

    if (!dataBeforeTranslations) {
      return {}
    }

    for (const dataset of dataBeforeTranslations.datasets) {
      dataset.label = t(`analyze:ghg_scopes.${stack}.${dataset.label}`)
    }

    dataBeforeTranslations.labels = dataBeforeTranslations.labels.map(
      (element) => {
        return t(`analyze:ghg_scopes.${group}.${element}`)
      }
    )

    return dataBeforeTranslations
  }, [score])

  return (
    <StackedBarChartAtom
      title={t(
        `analyze:ghg_scopes.chartODD13ScopesStackPerGroupBreakdownMolecule.title.${group}_and_${stack}`
      )}
      data={generateData()}
      axesLabel={t(`units:units.charts.emission.t_unit`)}
      displayYAxe
      displayLegend={false}
    />
  )
}

ChartODD13ScopesStackPerGroupBreakdownMolecule.propTypes = {
  score: PropTypes.object,
  protocol: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  stack: PropTypes.string.isRequired,
}

export default ChartODD13ScopesStackPerGroupBreakdownMolecule
