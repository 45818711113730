import React from "react"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const GOOD_STATUS = "success"
const BAD_STATUS = "critical"
const NEUTRAL_STATUS = "info"

const getBadgeStatus = (value, lowerLimit, upperLimit, inverse) => {
  if (value <= lowerLimit) {
    return inverse ? GOOD_STATUS : BAD_STATUS
  } else if (value >= upperLimit) {
    return inverse ? BAD_STATUS : GOOD_STATUS
  } else {
    return NEUTRAL_STATUS
  }
}

/**
 *
 * @param {Number} value
 * @param {Number} lowerLimit
 * @param {Number} upperLimit
 * @param {Boolean} inverse if inverse is true, the badge value is "success" if the value is inferior de lowerLimit, the badge value is "critical"
 * @returns {JSX.Element}
 */
const ChartBadge = ({ value, lowerLimit, upperLimit, inverse = false }) => {
  const { t } = useTranslation()

  const badgeStatus = getBadgeStatus(value, lowerLimit, upperLimit, inverse)

  return (
    <Badge size="medium" status={badgeStatus}>
      {t(`score:badge.status.${badgeStatus}`)}
    </Badge>
  )
}

ChartBadge.propTypes = {
  value: PropTypes.number.isRequired,
  lowerLimit: PropTypes.number.isRequired,
  upperLimit: PropTypes.number.isRequired,
  inverse: PropTypes.bool,
}

export default ChartBadge
