import React from "react"
import { Select } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const LifetimeHypothesesMolecule = ({ onChange, value, equipmentType }) => {
  const { t } = useTranslation()

  const unit = t("recommendation:propositions.hypotheses.less_paper.unit")

  return (
    <div className="recommendationsLayout__details__config__hypotheses">
      <Select
        label={null}
        options={[
          { label: `Desktop`, value: "desktop" },
          { label: `Laptop`, value: "laptop" },
          { label: "Monitor", value: "monitor" },
          { label: "Server", value: "server" },
          { label: "Firewall", value: "firewall" },
        ]}
        onChange={onChange}
        value={equipmentType}
        disabled
      />
      <Select
        label={null}
        options={[
          { label: `1 ${unit}`, value: "1" },
          { label: `2 ${unit}`, value: "2" },
          { label: `3 ${unit}`, value: "3" },
          { label: `4 ${unit}`, value: "4" },
          { label: `5 ${unit}`, value: "5" },
          { label: `6 ${unit}`, value: "6" },
          { label: `7 ${unit}`, value: "7" },
        ]}
        onChange={onChange}
        value={`${value}`}
        disabled
      />
    </div>
  )
}

LifetimeHypothesesMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  equipmentType: PropTypes.string.isRequired,
}

export default LifetimeHypothesesMolecule
