import React, { useCallback, useState } from "react"
import {
  Banner,
  Button,
  Checkbox,
  Form,
  FormLayout,
  TextField,
} from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { Authentication } from "services/login/Authentication"
import PropTypes from "prop-types"
import ForgottenPasswordLinkMolecule from "views/molecules/login/forgotten-password/ForgottenPasswordLinkMolecule"
import useAuth0 from "../../../components/auth/useAuth0"
import { ApiError } from "../../../helpers/errors/ApiError"
import { useHistory } from "react-router-dom"
import { useFeatureFlag } from "../../../hooks/useFeatureFlag"

const LoginFormOrganism = ({
  defaultEmail,
  onResetPassword,
  onEmailChange,
}) => {
  const { t } = useTranslation()
  const { setIsAuthenticated } = useAuth0()
  const [rememberMe, setRememberMe] = useState(false)
  const [email, setEmail] = useState(defaultEmail)
  const [password, setPassword] = useState("")
  const history = useHistory()
  const { isFeatureActive } = useFeatureFlag()

  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [globalError, setGlobalError] = useState("")

  const updateEmail = useCallback((email) => {
    setEmailError(null)
    setGlobalError("")
    setEmail(email)
    onEmailChange(email)
  }, [])

  const updatePassword = useCallback(
    (password) => {
      setPasswordError(null)
      setGlobalError("")
      setPassword(password)
    },
    [passwordError, globalError]
  )

  const isFieldsValid = () => {
    const emailErrorMessage = Authentication.validateEmail(email)
    const passwordErrorMessage = Authentication.validatePassword(password)

    setEmailError(emailErrorMessage)
    setPasswordError(passwordErrorMessage)

    return !emailErrorMessage && !passwordErrorMessage
  }

  const login = useCallback(async () => {
    if (isFieldsValid()) {
      try {
        await Authentication.login(email, password, rememberMe)
        setIsAuthenticated(true)
        history.push("/dashboard")
      } catch (err) {
        if (err instanceof ApiError && err.code === 401) {
          setGlobalError(t("common:login.errors.global_error"))
        } else {
          setGlobalError(t("common:login.errors.other"))
        }
      }
    }
  }, [email, password, rememberMe])

  return (
    <Form onSubmit={login}>
      <FormLayout>
        {globalError && <Banner status="warning">{globalError}</Banner>}
        <TextField
          label={t("common:login.home.email")}
          type="email"
          value={email}
          onChange={updateEmail}
          error={emailError}
        />
        <TextField
          label={t("common:login.home.password")}
          type="password"
          value={password}
          onChange={updatePassword}
          error={passwordError}
        />
        <FormLayout.Group>
          <Checkbox
            label={t("common:login.home.remember")}
            checked={rememberMe}
            onChange={setRememberMe}
          />
          <div style={{ textAlign: "right" }}>
            <ForgottenPasswordLinkMolecule
              title={t("common:login.home.forgot_password")}
              onResetPasswordLeave={onResetPassword}
            />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <Button fullWidth primary submit>
            {t("common:login.home.login")}
          </Button>
        </FormLayout.Group>
      </FormLayout>
    </Form>
  )
}

LoginFormOrganism.propTypes = {
  defaultEmail: PropTypes.string.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
}

export default LoginFormOrganism
