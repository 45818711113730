import React from "react"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import "stylesheets/blocs/atoms/ExternalBadgeAtom.scss"

const ExternalUserBadgeAtom = () => {
  const { t } = useTranslation()

  return (
    <div className="external-badge">
      <Badge progress="incomplete" status="warning">
        {t("common:user_list.external")}
      </Badge>
    </div>
  )
}

export default ExternalUserBadgeAtom
