import classNames from "classnames/bind"
import { Link } from "react-router-dom"
import React from "react"

import "stylesheets/blocs/molecules/objectives/TabObjectiveMolecule.scss"
import PropTypes from "prop-types"
import { PILLARS } from "utils/constants/pillars"

const TabObjectiveMolecule = ({
  title,
  selected,
  firstItem,
  url,
  themeType,
}) => {
  return (
    <div className="TabObjectiveMolecule">
      <Link to={url}>
        <button
          className={classNames(
            "TabObjectiveMolecule__button",
            {
              "TabObjectiveMolecule__button--selected": selected,
              "TabObjectiveMolecule__button--firstItem-border": firstItem,
            },
            themeType
          )}
        >
          {title}
        </button>
      </Link>
    </div>
  )
}

TabObjectiveMolecule.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,

  selected: PropTypes.bool.isRequired,
  firstItem: PropTypes.bool.isRequired,

  themeType: PropTypes.oneOf(PILLARS).isRequired,
}

export default TabObjectiveMolecule
