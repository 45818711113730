import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { COLORS } from "utils/constants/colors"

const TRANSLATION_PREFIX = "analyze:it_domain.cloud.charts.co2_vs_cost"
const LINE_VALUE_SUFFIX = "€"

const ChartAnalysisCloudCo2VSCost = ({ datasets, labels, co2_unit }) => {
  const { t } = useTranslation()

  const title = t(`${TRANSLATION_PREFIX}.title`)

  const data = {
    labels: labels,
    datasets: datasets.map((dataset, index) => {
      const isCost = dataset.label_key === "cost"
      return {
        type: isCost ? "line" : "bar",
        label: t(`${TRANSLATION_PREFIX}.datasets.labels.${dataset.label_key}`),
        data: dataset.data,
        backgroundColor: Config.common.colors[index],
        borderColor: isCost ? Config.common.colors[index] : COLORS.white,
        borderWidth: isCost ? 2 : 0,
        stack: "default",
        order: isCost ? 1 : 2,
        datalabels: { display: !isCost },
        fill: !isCost,
        yAxisID: isCost ? "y-axis-2" : "y-axis-1",
      }
    }),
  }

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: co2_unit,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: LINE_VALUE_SUFFIX,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const type = data.datasets[tooltipItem.datasetIndex].type
          const prefix = type === "bar" ? co2_unit : LINE_VALUE_SUFFIX
          return `${data.datasets[tooltipItem.datasetIndex].label} : ${
            tooltipItem.yLabel
          } ${prefix}`
        },
      },
    },
    plugins: {
      datalabels: {
        color: COLORS.white,
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          const prefix =
            context.dataset.type === "bar" ? co2_unit : LINE_VALUE_SUFFIX
          return `${context.dataset.data[context.dataIndex]} ${prefix}`
        },
      },
    },
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", PILLAR_ENVIRONMENTAL)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}

ChartAnalysisCloudCo2VSCost.propTypes = {
  datasets: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  co2_unit: PropTypes.string.isRequired,
}

export default ChartAnalysisCloudCo2VSCost
