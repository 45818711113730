import PropTypes from "prop-types"
import { Caption, Stack, Thumbnail } from "@shopify/polaris"
import { NoteMajor } from "@shopify/polaris-icons"
import React from "react"

const DropZoneFileNameMolecule = ({ file }) => {
  return (
    <Stack distribution="center">
      <Thumbnail size="small" alt={file.name} source={NoteMajor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  )
}

DropZoneFileNameMolecule.propTypes = {
  file: PropTypes.object.isRequired,
}

export default DropZoneFileNameMolecule
