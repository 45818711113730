import React from "react"
import PropTypes from "prop-types"
import { Checkbox } from "@shopify/polaris"
import useCentralizationForm from "hooks/useCentralizationForm"

const PillarCentralizationLine = ({
  centralizationKey,
  name,
  value,
  disabled,
  providerName,
  onCheckboxChange,
}) => {
  const { getGovernanceText, showCheckbox, isChecked } = useCentralizationForm(
    disabled
  )

  return (
    <tr>
      <td>{name}</td>
      <td>
        {showCheckbox(value) && (
          <Checkbox
            id={centralizationKey}
            checked={isChecked(value)}
            onChange={onCheckboxChange}
            label={null}
          />
        )}

        {getGovernanceText(value, providerName)}
      </td>
    </tr>
  )
}

PillarCentralizationLine.propTypes = {
  centralizationKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf(["centralized", "inherited", "not_centralized"])
    .isRequired,
  disabled: PropTypes.bool.isRequired,
  providerName: PropTypes.string,
  onCheckboxChange: PropTypes.func.isRequired,
}

PillarCentralizationLine.defaultProps = {
  providerName: null,
}

export default PillarCentralizationLine
