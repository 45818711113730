import React, { useState } from "react"
import { Banner, Card, Layout, Page } from "@shopify/polaris"
import LoginLogoMolecules from "views/molecules/login/LoginLogoMolecules"
import LoginFormOrganism from "views/organisms/login/LoginFormOrganism"
import ForgottenPasswordFormOrganism from "views/organisms/login/forgotten-password/ForgottenPasswordFormOrganism"
import { useTranslation } from "react-i18next"
import OktaLoginFormOrganism from "../organisms/login/OktaLoginFormOrganism"
import useAuth0 from "../../components/auth/useAuth0"
import { Redirect } from "react-router-dom"
import { disabledAuth } from "../../components/auth/AuthConfig"

const LoginPage = () => {
  const [wantResetPassword, setWantResetPassword] = useState(false)
  const [email, setEmail] = useState("")

  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  const queryParams = new URLSearchParams(window.location.search)

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          {queryParams.get("resetPasswordSuccess") && (
            <Banner status="success">
              {t("common:login.password_reset.success")}
            </Banner>
          )}
        </Layout.Section>
        <Layout.Section>
          <Card title={<LoginLogoMolecules />}>
            <Card.Section>
              {disabledAuth ? (
                !wantResetPassword ? (
                  <LoginFormOrganism
                    defaultEmail={email}
                    onResetPassword={() => setWantResetPassword(true)}
                    onEmailChange={(value) => setEmail(value)}
                  />
                ) : (
                  <ForgottenPasswordFormOrganism
                    email={email}
                    onResetPasswordLeave={() => setWantResetPassword(false)}
                    onEmailChange={(email) => setEmail(email)}
                  />
                )
              ) : (
                <OktaLoginFormOrganism />
              )}
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default LoginPage
