import React from "react"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import {
  ExternalLabel,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { FormLayout } from "@shopify/polaris"
import { INVENTORY_SOCIAL_PEOPLE_INVENTORIES } from "utils/constants/centralization/inventoryItems"
import { useFormContext } from "../../FormContext"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"

const SocialPeopleInventory = () => {
  const { formData } = useFormContext()

  return (
    <FormLayout>
      <ExternalLabel
        pathOverride="common"
        translationPathName="social_people_inventory"
      />
      <div style={{ textAlign: "center" }}>
        <YesNoRadio id="upload_detailed_inventory" />
      </div>
      <FileField
        id="decentralized_inventory_social"
        translationPathName="inventory_social_people"
        inventoryType={INVENTORY_SOCIAL_PEOPLE_INVENTORIES}
        disabled={formData?.upload_detailed_inventory === false}
        displayDownloadIcon
        templateSelectionComponent={
          <TemplateFileLinkMolecule id="decentralized_inventory_social" />
        }
      />
    </FormLayout>
  )
}

SocialPeopleInventory.propTypes = {}

export default SocialPeopleInventory
