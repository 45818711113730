import React, { useEffect, useState } from "react"
import { useAppContext } from "components/AppContext"
import TopBarCustom from "components/widgets/TopBarCustom"
import { useLocation } from "react-router-dom"
import { Frame } from "@shopify/polaris"
import classNames from "classnames"
import Routes from "routes/Routes"
import { getProductName } from "services/apps/product"
import useToggle from "hooks/useToggle"
import NavigationBar from "components/NavigationBar"
import ToastAtom from "views/atoms/forms/ToastAtom"

const AppLayoutChild = () => {
  const {
    currentOrganization,
    toastStatus,
    setToastStatus,
    currentRootNode,
  } = useAppContext()
  const location = useLocation()

  const [hiddenNavigation, toggleHiddenNavigation] = useState(true)
  const [collapsedNavigation, toggleCollapsedNavigation] = useToggle(true)

  const HIDDEN_NAVIGATION_MARKUP = ["/"]
  useEffect(() => {
    toggleHiddenNavigation(HIDDEN_NAVIGATION_MARKUP.includes(location.pathname))
  }, [location.pathname])

  return (
    <div
      id="verdikt-app"
      className={classNames({
        "navigation-minimized": collapsedNavigation,
        "navigation-hidden": hiddenNavigation,
        [`product-${getProductName(
          currentOrganization,
          currentRootNode
        )}`]: true,
      })}
    >
      <Frame
        showMobileNavigation
        navigation={
          <NavigationBar
            collapsedNavigation={collapsedNavigation}
            toggleCollapsedNavigation={toggleCollapsedNavigation}
          />
        }
        topBar={<TopBarCustom />}
      >
        <ToastAtom toastStatus={toastStatus} setToastStatus={setToastStatus} />
        <Routes />
      </Frame>
    </div>
  )
}

AppLayoutChild.propTypes = {}

export default AppLayoutChild
