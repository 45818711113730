import PropTypes from "prop-types"
import React from "react"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"

const ChartODD13VolumeEmissionsTopLocMolecule = ({ mixedChart = [] }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const labels = mixedChart.map((node) => compareNodeLabels(node.label, true, true))
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const bar_data = {
    label: t(translationPrefix + "chartVolumeEmissionsTopLoc.legend.bar"),
    data: bar
  }

  const line_data = {
    label: t(translationPrefix + "chartVolumeEmissionsTopLoc.legend.line"),
    data: line
  }

  const data = { bar_data, line_data }

  return (
    <MixedChart
      title={t(translationPrefix + "chartVolumeEmissionsTopLoc.title")}
      data_inputs={data}
      labels={labels}
      unit_left_axis={NumberFormatterApp.getI18nUnit("emission", "kg_unit")}
      unit_right_axis={t(
        translationPrefix + "chartVolumeEmissionsTopLoc.unit_right_axis"
      )}
    />
  )
}

ChartODD13VolumeEmissionsTopLocMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired
}

export default ChartODD13VolumeEmissionsTopLocMolecule
