import { Icon } from "@shopify/polaris"
import { StarFilledMinor, StarOutlineMinor } from "@shopify/polaris-icons"
import React from "react"
import PropTypes from "prop-types"
import {
  TOAST_STATUS_PREFERRED_SELECTED_VERSION,
  TOAST_STATUS_PREFERRED_UNSELECTED_VERSION,
} from "utils/constants/toastStatuses"

const DiscardedNodeButtonOrganism = ({
  isDiscarded,
  onClick,
  onToastStatus,
  disabled,
}) => {
  const icon = isDiscarded ? StarOutlineMinor : StarFilledMinor

  const onChange = () => {
    const toast = isDiscarded
      ? TOAST_STATUS_PREFERRED_SELECTED_VERSION
      : TOAST_STATUS_PREFERRED_UNSELECTED_VERSION

    onToastStatus(toast)
    onClick()
  }

  return (
    <button
      className="buttonIcon buttonIcon__icon-container buttonIcon__icon-container--discarded-star"
      onClick={onChange}
      disabled={disabled}
    >
      <Icon source={icon} color="base" />
    </button>
  )
}

DiscardedNodeButtonOrganism.propTypes = {
  isDiscarded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onToastStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

DiscardedNodeButtonOrganism.defaultProps = {
  disabled: false,
}

export default DiscardedNodeButtonOrganism
