import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import CentralizedFileTemplateDownloadAtom from "views/atoms/buttons/CentralizedFileTemplateDownloadAtom"
import { getCentralizedFileId } from "utils/functions/centralization/centralizationFunctions"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import StatusUploadOrganism from "views/organisms/upload/StatusUploadOrganism"
import CentralizationInventoryEntity from "services/entities/CentralizationInventoryEntity"
import { FILE_TYPE_XLSX } from "utils/constants/uploads/fileTypes"
import { INVENTORY_TYPES } from "utils/constants/centralization/type"
import {
  IMPORT_STATUS_UPLOADED,
  IMPORT_STATUS_WORK_IN_PROGRESS,
} from "utils/constants/imports/importStatus"
import { useAppContext } from "components/AppContext"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import { upload } from "utils/functions/upload/upload"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import { useEntity } from "hooks/useAxios"

const UploadCentralizedOrganism = ({
  inventoryType,
  displayQualityIcon,
  displayDownloadIcon,
  disabled,
}) => {
  const { collect, setCollect, setToastStatus } = useCollectContext()
  const { currentNode } = useAppContext()

  const { isLocked } = useFieldIsLocked()
  const centralizationInventoryEntity = useEntity(CentralizationInventoryEntity)

  const [inventoryStatus, setInventoryStatus] = useState(
    collect?.inventories?.[inventoryType]
  )

  useEffect(() => setInventoryStatus(collect?.inventories?.[inventoryType]), [
    collect,
    inventoryType,
  ])

  const handleFileUpload = useCallback(async (_dropFiles, acceptedFiles) => {
    try {
      if (acceptedFiles.length < 1) {
        setToastStatus(6)
        return
      }

      const collectUpdated = await upload(
        acceptedFiles[0],
        currentNode,
        collect,
        getCentralizedFileId(currentNode?.type, inventoryType),
        centralizationInventoryEntity.centralizedFileUpload
      )

      setCollect(collectUpdated)
      setToastStatus(5)
    } catch (err) {
      console.error(err)
      setToastStatus(6)
    }
  }, [])

  const isDropZoneDisabled = () => {
    const centralizationType = collect?.centralization?.[inventoryType]

    if (!centralizationType?.centralized) {
      return true
    }

    switch (inventoryStatus?.processing_status) {
      case IMPORT_STATUS_UPLOADED:
      case IMPORT_STATUS_WORK_IN_PROGRESS:
        return true

      default:
        return isLocked
    }
  }

  if (currentNode?.type === NODE_TYPE_SITE) {
    return null
  }
  return (
    <article>
      <CentralizedFileTemplateDownloadAtom
        focusedNodeType={currentNode?.type}
        fileId={getCentralizedFileId(currentNode?.type, inventoryType)}
      />
      <StatusUploadOrganism
        accept={FILE_TYPE_XLSX}
        allowMultiple={false}
        onDrop={handleFileUpload}
        disabled={isDropZoneDisabled() || disabled}
        inventoryStatus={inventoryStatus}
        setInventoryStatus={setInventoryStatus}
        displayQualityIcon={displayQualityIcon}
        displayDownloadIcon={displayDownloadIcon}
      />
    </article>
  )
}

UploadCentralizedOrganism.propTypes = {
  inventoryType: PropTypes.oneOf(INVENTORY_TYPES).isRequired,
  displayQualityIcon: PropTypes.bool,
  displayDownloadIcon: PropTypes.bool,
  disabled: PropTypes.bool,
}

UploadCentralizedOrganism.defaultProps = {
  displayQualityIcon: false,
  displayDownloadIcon: true,
  disabled: false,
}

export default UploadCentralizedOrganism
