import { COLORS } from "utils/constants/colors"

export const NODE_TYPE_COMPANY = "company"
export const NODE_TYPE_CLIENT = "client"
export const NODE_TYPE_PARTNER = "partner"
export const NODE_TYPE_BRAND = "brand"
export const NODE_TYPE_REGION = "region"
export const NODE_TYPE_COUNTRY = "country"
export const NODE_TYPE_SITE = "site"
export const NODE_TYPE_PROGRAM = "program"
export const NODE_TYPE_PROJECT = "project"
export const NODE_TYPE_PHASE = "phase"
export const NODE_TYPE_COUNTRY_TEAM = "country_team"
export const NODE_TYPE_SERVICE = "service"
export const NODE_TYPE_SERVICE_COMPONENT = "service_component"

export const NODE_CATEGORY_PARTNER = "partner"
export const NODE_CATEGORY_CORPORATE = "corporate"

export const NODE_COUNTRY_LIKE_TYPES = [
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
]

export const PARTNER_NODE_TYPES = [
  NODE_TYPE_PROGRAM,
  NODE_TYPE_SERVICE,
  NODE_TYPE_PARTNER,
  NODE_TYPE_PROJECT,
]

export const NODE_AVAILABLE_STATUS = "available"
export const NODE_SCORED_STATUS = "already_scored"

export const CORPORATE_ROOT_NODE_TYPES = [NODE_TYPE_COMPANY]

export const ORGANIZATION_CATEGORY_CORPORATE = "corporate"
export const ORGANIZATION_CATEGORY_PROVIDER = "provider"
export const ORGANIZATION_CATEGORY_PARTIAL_PARTNER = "partial_partner"

export const ORGANIZATION_CATEGORY_TO_COLOR = {
  [ORGANIZATION_CATEGORY_CORPORATE]: COLORS.corporate.c40,
  [ORGANIZATION_CATEGORY_PROVIDER]: COLORS.payingPartner.primary,
  [ORGANIZATION_CATEGORY_PARTIAL_PARTNER]: COLORS.danger.dark,
}

export const isNodeLeaf = (node) => {
  switch (node?.type) {
    case NODE_TYPE_COUNTRY:
      return !node.characteristic?.site_assessment
    case NODE_TYPE_SITE:
    case NODE_TYPE_COUNTRY_TEAM:
      return true
    default:
      return false
  }
}
