import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ConfirmationCardAtom from "./ConfirmationCardAtom"

const DeleteConfirmationFormMolecule = ({ onActionCancel, onActionDelete }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationCardAtom
      cancel={() => onActionCancel(false)}
      validate={onActionDelete}
      actionName="delete"
    >
      {t(`dashboard:chart.buttons.delete_button.text`)}
    </ConfirmationCardAtom>
  )
}

DeleteConfirmationFormMolecule.propTypes = {
  onActionCancel: PropTypes.func,
  onActionDelete: PropTypes.func,
}

export default DeleteConfirmationFormMolecule
