const baseUrl = "/api/env_collect"

const EnvCollectEntity = (backClient) => ({
  show: async () => await backClient.get(baseUrl),
  put: async (data) => await backClient.put(baseUrl, { env_collect: data }),
  getEquipmentsTypesAvailable: async () =>
    await backClient.get(`${baseUrl}/equipments/types`),
  getModules: async () => await backClient.get(`${baseUrl}/modules`),
  progress: async () => await backClient.get(`${baseUrl}/progress`),
})

export default EnvCollectEntity
