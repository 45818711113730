import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { Bar } from "react-chartjs-2"
import classNames from "classnames"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import { HIDDEN_LEGEND } from "services/charts/legend"
import Config from "assets/configs/score.js"
import { WHITE_BORDER_DATASET } from "services/charts/datasets"

export const findColorByValue = (value, total) => {
  const ratio = value / total
  if (ratio < 0.1) return Config.common.colorsEvaluation.green
  if (ratio < 0.2) return Config.common.colorsEvaluation.yellow

  return Config.common.colorsEvaluation.red
}

const datasetKeyProvider = () => {
  return btoa(Math.random()).substring(0, 12)
}

const ChartODD13GHGEmissionsDistributionMolecule = ({ score }) => {
  const { t } = useTranslation()

  const { datasets, label_keys, unit } = score.odd13_emissions_breakdown

  const translatedUnit = t(`units:units.emission.${unit}`)

  const labels = label_keys.map((label_key) =>
    t(`score:score.13.chartGHGEmissionsDistribution.${label_key}`)
  )

  const data = datasets.map((dataset) => ({
    dataLabels: dataset.data_label_keys.map((data_label_key) =>
      t(`score:score.13.chartGHGEmissionsDistribution.${data_label_key}`)
    ),
    data: dataset.data,
    backgroundColor: dataset.data_before_conversion.map((value) =>
      findColorByValue(value, score.odd13_CO2)
    ),
    unit: translatedUnit,
    stack: 1,
    showLabelInData: true,
    ...WHITE_BORDER_DATASET,
  }))

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissionsDistribution.help`)}
    >
      <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
        <h1>{t("score:score.13.chartGHGEmissionsDistribution.title")}</h1>
        <div className="content">
          <Bar
            datasetKeyProvider={datasetKeyProvider}
            data={{ datasets: data, labels }}
            options={{
              ...DEFAULT_OPTIONS,
              ...yAxes(translatedUnit),
              legend: HIDDEN_LEGEND,
            }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13GHGEmissionsDistributionMolecule.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13GHGEmissionsDistributionMolecule
