import React from "react"
import { FormLayout } from "@shopify/polaris"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import {
  ExternalLabel,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { useTranslation } from "react-i18next"
import { INVENTORY_INNOVATIONS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import UploadOrganism from "views/organisms/upload/UploadOrganism"

const fields = [
  "structure_digital_innov_percentage",
  "structure_rnd_revenue_ratio",
  "structure_innovation_roi",
  "structure_patents_count",
  "structure_canceled_projects",
  "structure_on_time_delivery_percentage",
  "structure_sustainability_project_priority",
]
const translationPath = "9.innovation_portfolio"

const InnovationPortfolioForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath })

  const tPrefix = `collect:questions.${translationPath}`

  return (
    <Form>
      <Module module={INVENTORY_INNOVATIONS}>
        <div className="columns">
          <div className="column">
            <FormLayout>
              <TextField
                id="structure_digital_innov_percentage"
                type="number"
              />

              <UploadOrganism
                centralisedComponent={
                  <CentralizedUploadMolecule
                    translationPathName="innovation_inventories"
                    inventoryType={INVENTORY_INNOVATIONS}
                    displayDownloadIcon
                  />
                }
                decentralisedComponent={
                  <FileField
                    id="decentralized_inventory_innovations"
                    title="innovation_inventories"
                    inventoryType={INVENTORY_INNOVATIONS}
                    translationPathName="innovation_inventories"
                    templateSelectionComponent={
                      <TemplateFileLinkMolecule id="decentralized_inventory_innovations" />
                    }
                  />
                }
              />
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <YesNoRadio id="structure_sustainability_project_priority" />

              <ExternalLabel translationPathName="indicators" />
              <table>
                <tbody>
                  {[
                    { field: "structure_on_time_delivery_percentage" },
                    { field: "structure_rnd_revenue_ratio" },
                    { field: "structure_innovation_roi" },
                    { field: "structure_patents_count", suffix: "" },
                    { field: "structure_canceled_projects" },
                  ].map(({ field, suffix }) => (
                    <tr key={field}>
                      <td>{t(`${tPrefix}.indicators.${field}`)}</td>
                      <td>
                        <TextField
                          type="number"
                          id={field}
                          suffix={suffix ?? "%"}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </FormLayout>
          </div>
        </div>
      </Module>
    </Form>
  )
}

export default InnovationPortfolioForm
