import React from "react"
import PropTypes from "prop-types"
import RecommendationDetailsActions from "views/molecules/recommendations/RecommendationDetailsActions"
import RecommendationDetailsConfig from "views/molecules/recommendations/RecommendationDetailsConfig"
import RecommendationDetailsDashboard from "views/molecules/recommendations/RecommendationDetailsDashboard"

const RecommendationDetails = ({ onClose, proposition }) => {
  return (
    <>
      <tr>
        <RecommendationDetailsConfig proposition={proposition} />
        <RecommendationDetailsActions onClose={onClose} />
      </tr>
      <tr className="recommendationsLayout__details">
        <td colSpan={3}>
          <RecommendationDetailsDashboard proposition={proposition} />
        </td>
      </tr>
    </>
  )
}

RecommendationDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  proposition: PropTypes.shape({
    pillar: PropTypes.string.isRequired,
    kpi: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    simulation_proposition_graphs: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    chart: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    kpis: PropTypes.shape({
      primary: PropTypes.shape({
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
      secondary: PropTypes.shape({
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}

export default RecommendationDetails
