import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  Select,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Form from "components/widgets/CollectPage/forms/Form"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ExternalUploadMolecule from "views/molecules/upload/worplace/ExternalUploadMolecule"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import { INVENTORY_TECH_TEAM_INVENTORIES } from "utils/constants/centralization/inventoryItems"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import { Module } from "../../Module"
import { useFormConfig, useFormContext } from "../../FormContext"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { NODE_TYPE_COUNTRY_TEAM } from "utils/constants/organization/nodeTypes"
import {
  DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE,
  DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE,
} from "utils/constants/collects/centralizationFilesId"
import { useAppContext } from "components/AppContext"

const YearlyTransportModes = [
  "plane",
  "train",
  "company_car",
  "rental_car",
  "taxi",
]

const fields = [
  "travel_airplane_duration_policy",
  "travel_mobility_subscription_policy",
  "travel_carpool_policy",
  "travel_coworking_policy",
  "travel_other_policy",
  "travel_company_car",
  "travel_company_car_unit",
  "travel_plane",
  "travel_plane_unit",
  "travel_rental_car",
  "travel_rental_car_unit",
  "travel_taxi",
  "travel_taxi_unit",
  "travel_train",
  "travel_train_unit",
  "odd13_it_team_commute_co2",
  "odd13_it_team_remote_average",
]
const translationPath = "13.it_team"

const Objective13ITTeamForm = () => {
  const { t } = useTranslation()
  const { formData, setFormData } = useFormContext()
  const { currentNode } = useAppContext()

  useFormConfig({ fields, translationPath })

  useEffect(() => {
    if (!formData?.travel_plane_unit) return

    setFormData({
      ...formData,
      travel_train_unit: formData?.travel_plane_unit,
      travel_company_car_unit: formData?.travel_plane_unit,
      travel_rental_car_unit: formData?.travel_plane_unit,
      travel_taxi_unit: formData?.travel_plane_unit,
    })
  }, [formData?.travel_plane_unit])

  const generateTableMarkup = useCallback(() => {
    const items = YearlyTransportModes.map((mode, index) => {
      const id = `travel_${mode}`
      const id_unit = `travel_${mode}_unit`

      return (
        <tr key={`mode-${index}`}>
          <td>{t(`collect:questions.13.it_team.table.${id}.title`)}</td>
          <td>
            <TextField id={id} type="number" />
          </td>
          <td>
            <Select
              id={id_unit}
              value={String((formData && formData.id_unit) ?? "distance")}
              options={[
                {
                  label: t(
                    "collect:questions.13.it_team.table.input_unit.distance"
                  ),
                  value: "distance",
                },
                {
                  label: t(
                    "collect:questions.13.it_team.table.input_unit.CO2_emissions"
                  ),
                  value: "co2_emissions",
                },
              ]}
            />
          </td>
        </tr>
      )
    })
    return (
      <div>
        <ExternalLabel translationPathName={"table"} />
        <table>
          <tbody>{items}</tbody>
        </table>
      </div>
    )
  }, [formData])

  const fileId =
    currentNode?.type === NODE_TYPE_COUNTRY_TEAM
      ? DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE
      : DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_TECH_TEAM_INVENTORIES}>
                <TextField id="odd13_it_team_commute_co2" type="number" />
                <br />
                <TextField id="odd13_it_team_remote_average" type="number" />
                <br />

                <UploadOrganism
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="tech_team_inventory_file"
                      inventoryType={INVENTORY_TECH_TEAM_INVENTORIES}
                      displayDownloadIcon
                    />
                  }
                  decentralisedComponent={
                    <ExternalUploadMolecule
                      translationPathName="tech_team_inventory_file"
                      fileIdExternal="decentralized_inventory_external_digital_team_commute"
                      fileId="decentralized_inventory_digital_team_commute"
                      inventoryType={INVENTORY_TECH_TEAM_INVENTORIES}
                      title={t(
                        "collect:questions.13.it_team.tech_team_inventory_file.label"
                      )}
                      precision={t(
                        "collect:questions.13.it_team.tech_team_inventory_file.precision"
                      )}
                      displayDownloadIcon
                      templateSelectionComponent={
                        <TemplateFileLinkMolecule id={fileId} />
                      }
                    />
                  }
                />
              </Module>
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_TECH_TEAM_INVENTORIES}>
                {generateTableMarkup()}
                <br />
                <TextField id="travel_airplane_duration_policy" type="number" />
                <MultipleChoiceField
                  id="policies"
                  choices={[
                    "travel_mobility_subscription_policy",
                    "travel_carpool_policy",
                    "travel_coworking_policy",
                  ]}
                  otherField="travel_other_policy"
                />
              </Module>
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13ITTeamForm
