import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

export const STATUS_IMPROVING = "success"
export const STATUS_WORSEN = "critical"
export const STATUS_NEUTRAL = "info"
export const STATUS_NEW = "new"

const BadgeStatusChartAtom = ({ status }) => {
  const { t } = useTranslation()

  const getText = useCallback(() => {
    switch (status) {
      case STATUS_IMPROVING:
        return t("analyze:historization.node.kpi.badges.improving")
      case STATUS_WORSEN:
        return t("analyze:historization.node.kpi.badges.worsen")
      case STATUS_NEUTRAL:
        return t("analyze:historization.node.kpi.badges.neutral")
      case STATUS_NEW:
        return t("analyze:historization.node.kpi.badges.new")
    }
  }, [status])

  return (
    <Badge status={status === STATUS_NEW ? "info" : status}>{getText()}</Badge>
  )
}

BadgeStatusChartAtom.propTypes = {
  status: PropTypes.oneOf([STATUS_IMPROVING, STATUS_NEUTRAL, STATUS_WORSEN]),
}

export default BadgeStatusChartAtom
