import React from "react"
import PropTypes from "prop-types"
import SnowRunModalOrganism from "views/organisms/forms/modal/SnowRunModalOrganism"
import { INVENTORY_TYPES } from "utils/constants/centralization/type"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import { useCollectContext } from "../../../components/widgets/CollectPage/CollectContextProvider"

const SnowUploadOrganism = ({
  translationPath,
  translationPathName,
  inventoryType,
  children,
}) => {
  const { collect, setCollect } = useCollectContext()

  if (collect?.inventories?.[inventoryType] || !collect?.available_snow)
    return children

  return (
    <>
      {collect?.inventories?.[inventoryType] ? (
        <CentralizedUploadMolecule
          translationPath={translationPath}
          translationPathName={translationPathName}
          inventoryType={inventoryType}
          displayQualityIcon
          disabled
        />
      ) : (
        <SnowRunModalOrganism
          collect={collect}
          setCollect={setCollect}
          snowRun={collect?.snow_run}
        />
      )}
    </>
  )
}

SnowUploadOrganism.propTypes = {
  translationPathName: PropTypes.string.isRequired,
  translationPath: PropTypes.string,
  children: PropTypes.node,
  inventoryType: PropTypes.oneOf(INVENTORY_TYPES).isRequired,
}

export default SnowUploadOrganism
