import React, { useCallback } from "react"
import classNames from "classnames"
import { Line } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import { PropTypes } from "prop-types"
import CountryApp from "services/apps/countryApp"
import { useTranslation } from "react-i18next"
import { PILLAR_ENVIRONMENTAL, PILLARS } from "utils/constants/pillars"

const defaultOptions = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const ChartMultipleLinesMolecule = ({
  title,
  pillarId,
  config,
  chartType,
  options,
  forceNoCountry,
}) => {
  const { t } = useTranslation()

  const getLabel = useCallback(
    (label) => {
      switch (chartType) {
        case "ghg_breakdown_evolution":
          return t(
            `analyze:historization.node.charts.ghg_breakdown_evolution.labels.${label}`
          )
        default:
          return forceNoCountry ? label : CountryApp.getNameI18n(label) || label
      }
    },
    [chartType]
  )

  const generateData = useCallback(() => {
    const datasets = config.datasets

    const data = {
      labels: config.labels,
      datasets: [],
    }

    data.datasets = datasets.map((dataset, index) => {
      const values = data.labels.map(
        (label) => dataset.data.find((details) => details.key === label)?.value
      )

      return {
        label: getLabel(dataset.data[0]?.label),
        data: values,
        tension: 0.01,
        borderColor: Config.common.colors[index],
        fill: false,
      }
    })

    return data
  }, [config])

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Line
          options={{ ...defaultOptions, ...options }}
          data={generateData()}
        />
      </div>
    </div>
  )
}

export default ChartMultipleLinesMolecule

ChartMultipleLinesMolecule.propTypes = {
  chartType: PropTypes.object,
  config: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  title: PropTypes.string,
  pillarId: PropTypes.oneOf(PILLARS),
  forceNoCountry: PropTypes.bool,
}

ChartMultipleLinesMolecule.defaultProps = {
  chartType: null,
  title: "",
  pillarId: PILLAR_ENVIRONMENTAL,
  forceNoCountry: false,
}
