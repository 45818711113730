import React from "react"
import ChartODD13GHGEmissionsInternalExternalMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsInternalExternalMolecule"
import ChartOdd13ExternalAppEmissions from "views/molecules/canvases/charts/ChartOdd13ExternalAppEmissions"
import PropTypes from "prop-types"
import ChartSdg13WorkplaceEquipmentPerAllocation from "views/molecules/canvases/charts/ChartSDG13WorkplaceEquipmentPerAllocation"

const Chart3Sdg13Country = ({ score }) => {
  if (score?.odd13_ghg_internal_external_spit)
    return (
      <ChartODD13GHGEmissionsInternalExternalMolecule
        ghgSplit={score?.odd13_ghg_internal_external_spit}
      />
    )

  if (score.odd13_workplace_emission_split_per_allocation) {
    return <ChartSdg13WorkplaceEquipmentPerAllocation score={score} />
  }

  return <ChartOdd13ExternalAppEmissions score={score.saas} />
}

Chart3Sdg13Country.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Chart3Sdg13Country
