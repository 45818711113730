const baseUrl = "/api/consolidation"

const ConsolidationEntity = (backClient) => ({
  isAvailable: async (id) => {
    const { data } = await backClient.get(`${baseUrl}/available/${id}`)
    return data
  },
  putConsolidation: async (id) =>
    await backClient.put(`${baseUrl}/score`, { id }),
})

export default ConsolidationEntity
