import PropTypes from "prop-types"
import { Icon } from "@shopify/polaris"
import { CancelSmallMinor, MobileAcceptMajor } from "@shopify/polaris-icons"
import React from "react"

const TranslatePillarReportAtom = ({ pillar }) => {
  switch (pillar) {
    case true:
      return (
        <div className="yes-icon">
          <Icon source={MobileAcceptMajor} />
        </div>
      )
    case false:
      return (
        <div className="no-icon">
          <Icon source={CancelSmallMinor} />
        </div>
      )
  }
}

TranslatePillarReportAtom.propTypes = {
  pillar: PropTypes.bool.isRequired,
}

export default TranslatePillarReportAtom
