import { Layout } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"

import "stylesheets/CollectPage.scss"
import "stylesheets/ObjectiveTabs.scss"
import ThemeLayoutOrganism from "views/organisms/themes/ThemeLayoutOrganism"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import WaitTagSeleniumAtom from "views/atoms/selenium/WaitTagSeleniumAtom"

const AnalyzeHistoryTemplate = ({
  parentOrganization,
  children,
  pillars,
  periodName,
}) => {
  return (
    <Layout>
      <WaitTagSeleniumAtom />
      <Layout.Section>
        <BreadcrumbsOrganism
          parentOrganization={parentOrganization}
          currentPeriodName={periodName}
        />
      </Layout.Section>

      <Layout.Section>
        <PillarOrganism pillars={pillars} />

        <ThemeLayoutOrganism tabs={[]} objectives={[]}>
          {children}
        </ThemeLayoutOrganism>
      </Layout.Section>
    </Layout>
  )
}

AnalyzeHistoryTemplate.propTypes = {
  parentOrganization: PropTypes.array.isRequired,
  periodName: PropTypes.string.isRequired,
  pillars: PropTypes.array,
  children: PropTypes.element,
}

export default AnalyzeHistoryTemplate
