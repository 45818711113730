import React from "react";
import {
  Card,
  Layout,
  Link,
  Page,
} from "@shopify/polaris";

const Privacy = () => {

  return (
    <Page fullWidth title="Politique de confidentialité">
      <Layout>
        <Layout.Section>
          <Card title="Données collectées">
            <Card.Section title="Données recueillies vous concernant">
            <p>Lorsque vous créez un compte sur l’application Verdikt, nous conservons votre adresse e-mail, ainsi que toutes les données que vous pourrez saisir dans votre profil utilisateur.</p>
            </Card.Section>
            <Card.Section title="Données recueillies concernant votre entreprise">
            <p>Les informations recueillies dans les questionnaires sont enregistrées dans un fichier informatisé par Verdikt. La base légale du traitement est le consentement.</p>
            <p>Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. </p>
            <p>Consultez le site <Link url="https://cnil.fr" external="true">cnil.fr</Link> pour plus d’informations sur vos droits.</p>
            </Card.Section>
          </Card>
          <Card title="Vos droits">
            <Card.Section>
            <p>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données sur notre <Link url="/contact">page de contact</Link>.</p>
            <p>Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</p>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );

};

export default Privacy;
