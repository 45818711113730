const NodeEntity = (client) => ({
  show: async (id) => await client.get(`/api/nodes/${id}`),
  index: async ({ type }) => {
    const params = type ? `?type=${type}` : ""
    return await client.get(`/api/nodes${params}`)
  },
  breadcrumbIndex: async (ids) =>
    await client.get(`/api/node/compare/by_ids`, { params: { ids } }),
  create: async (data) => {
    await client.post(`/api/nodes`, data)
  },
  update: async (id, data) => await client.put(`/api/nodes/${id}`, data),
  destroy: async (id) => await client.delete(`/api/nodes/${id}`),
  archive: async (id) => await client.put(`/api/nodes/${id}/archive`),
  search: async (params) => await client.get(`/api/nodes/search`, { params }),
  searchNodes: async (params) =>
    await client.get(`/api/nodes/scored_search`, { params }),
  availableParent: async (type, nodeId) =>
    await client.post("/api/tree/available_parents", {
      type: type,
      node_id: nodeId,
    }),
  share: async (data) =>
    await client.post(`/api/node_organization_links`, data),
  availableNodeShares: async () =>
    await client.get(`/api/tree/available_shares`),
  availableOrganizationShares: async (data) =>
    await client.post(`/api/tree/available_shares`, data),
  availableChildTypes: async (types) =>
    await client.get(`/api/tree/available_child_types`, { params: { types } }),
})

export default NodeEntity
