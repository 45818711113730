import React from "react"
import PropTypes from "prop-types"
import CollectStartedIconsMolecule from "views/molecules/CollectStartedIconsMolecule"

const ChartHeaderAtom = ({
  title,
  subtitle,
  versionName,
  editComponent,
  node,
  hideCollectIcons
}) => {
  return (
    <div className="oc-heading">
      <div className="oc-text">
        <div className="oc-text oc-text__name">
          {title} {versionName && `(${versionName})`}
        </div>

        <div className="oc-text oc-text__type">{subtitle}</div>

        {!hideCollectIcons && (
          <CollectStartedIconsMolecule
            nodeId={node?.id}
            hasEnvCollect={!!node?.has_env_collect}
            hasEnvScore={!!node?.has_env_score}
            hasSocialCollect={!!node?.has_social_collect}
            hasSocialScore={!!node?.has_social_score}
            hasProfitCollect={!!node?.has_profit_collect}
            hasProfitScore={!!node?.has_profit_score}
          />
        )}
      </div>

      {editComponent}
    </div>
  )
}

ChartHeaderAtom.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  versionName: PropTypes.string,
  editComponent: PropTypes.node,
  node: PropTypes.object.isRequired,
  hideCollectIcons: PropTypes.bool,
}

ChartHeaderAtom.defaultProps = {
  editComponent: null,
  hideCollectIcons: false,
}

export default ChartHeaderAtom
