import { FormLayout } from "@shopify/polaris"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Form from "components/widgets/CollectPage/forms/Form"
import DcInternalInventory from "components/widgets/CollectPage/forms/inventories/environment/DcInternalInventory"
import React from "react"
import { DATACENTER_INTERNAL_COLLECT_MODULE } from "utils/constants/collects/modules"
import { Module } from "../../Module"
import FormContextProvider, { useFormConfig } from "../../FormContext"

const fields = [
  "data_internal_data_volume",
  "data_internal_sizing_policy",
  "data_internal_decom_policy",
  "data_internal_virtualization_ratio",
]
const translationPath = "13.datacenter_internal"

const Objective13DataInternalForm = () => {
  useFormConfig({ fields, translationPath })

  return (
    <Module module={DATACENTER_INTERNAL_COLLECT_MODULE}>
      <Form>
        <FormLayout>
          <div className="columns">
            <div className="column column-23">
              <FormLayout>
                <FormContextProvider>
                  <DcInternalInventory />
                </FormContextProvider>
                <TextField id="data_internal_data_volume" type="number" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <MultipleChoiceField
                  id="policies"
                  choices={[
                    "data_internal_sizing_policy",
                    "data_internal_decom_policy",
                  ]}
                />
                <TextField
                  id="data_internal_virtualization_ratio"
                  type="number"
                />
              </FormLayout>
            </div>
          </div>
        </FormLayout>
      </Form>
    </Module>
  )
}

export default Objective13DataInternalForm
