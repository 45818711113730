import React, { useCallback, useEffect, useState } from "react"
import { Banner, Button, Form, FormLayout, TextField } from "@shopify/polaris"
import { Authentication } from "services/login/Authentication"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ForgottenPasswordMessageMolecule from "views/molecules/login/forgotten-password/ForgottenPasswordMessageMolecule"
import { ApiError } from "helpers/errors/ApiError"
import ResetPassword from "services/login/ResetPassword"
import ForgottenPasswordLinkMolecule from "views/molecules/login/forgotten-password/ForgottenPasswordLinkMolecule"

const ForgottenPasswordFormOrganism = ({
  email,
  onResetPasswordLeave,
  onEmailChange,
}) => {
  const { t } = useTranslation()
  const [resetEmail, setResetEmail] = useState(email)
  const [emailError, setEmailError] = useState(null)
  const [globalError, setGlobalError] = useState("")
  const [
    isResetPasswordBannerDisplayed,
    shouldDisplayResetPasswordBanner,
  ] = useState(false)

  useEffect(() => {
    onEmailChange(resetEmail)
    setEmailError(null)
    setGlobalError("")
  }, [resetEmail])

  const resetPassword = useCallback(async () => {
    const message = Authentication.validateEmail(resetEmail)
    setEmailError(message)
    if (message === null) {
      try {
        await ResetPassword.sendInstructions(resetEmail)
        shouldDisplayResetPasswordBanner(true)
      } catch (err) {
        if (err instanceof ApiError) {
          setGlobalError(err.message)
        } else {
          setGlobalError(t("common:login.errors.other"))
        }
      }
    }
  }, [resetEmail])

  return (
    <>
      <ForgottenPasswordMessageMolecule
        email={email}
        isResetPasswordBannerDisplayed={isResetPasswordBannerDisplayed}
      />
      {globalError && <Banner status="warning">{globalError}</Banner>}
      <Form onSubmit={resetPassword}>
        <FormLayout>
          <TextField
            label={t("common:login.home.email")}
            type="email"
            value={resetEmail}
            onChange={setResetEmail}
            error={emailError}
          />
          <ForgottenPasswordLinkMolecule
            title={t("common:login.password_reset.return_login")}
            onResetPasswordLeave={onResetPasswordLeave}
          />
          <FormLayout.Group>
            <Button fullWidth primary submit>
              {t("common:login.password_reset.action")}
            </Button>
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </>
  )
}

ForgottenPasswordFormOrganism.propTypes = {
  email: PropTypes.string.isRequired,
  onResetPasswordLeave: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
}

export default ForgottenPasswordFormOrganism
