import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"

const ChartSDG13BpPointsMolecule = ({ bpPoints }) => {
  const { t } = useTranslation()

  const bpTypes = ["bp_good", "bp_medium", "bp_bad"]

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`analyze:websites.bp_points.help`)}
    >
      <TextChart
        content={bpTypes.map((bpType, idx) => (
          <TextChartMinor
            content={{
              kpi: `${bpPoints[bpType].count} (${bpPoints[bpType].percentage}%)`,
              kpi_text: t(`analyze:websites.bp_points.${bpType}`),
            }}
            key={idx}
          />
        ))}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSDG13BpPointsMolecule.propTypes = {
  bpPoints: PropTypes.object.isRequired,
}

export default ChartSDG13BpPointsMolecule
