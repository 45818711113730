export const DECENTRALIZED_FOLDERS = {
  en: "/templates/collects/decentralized/en",
  fr: "/templates/collects/decentralized/fr",
}

export const DECENTRALIZED_WORKPLACE_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_equipment_workplace.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_equipment_workplace.fr.xlsx`,
}

export const DECENTRALIZED_EXTERNAL_WORKPLACE_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_external_equipment_workplace.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_external_equipment_workplace.fr.xlsx`,
}

export const DECENTRALIZED_WORKPLACE_FLEX_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_equipment_workplace_description.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_equipment_workplace_description.fr.xlsx`,
}

export const DECENTRALIZED_EXTERNAL_WORKPLACE_FLEX_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_external_equipment_workplace_description.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_external_equipment_workplace_description.fr.xlsx`,
}

export const DECENTRALIZED_NETWORK_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_equipment_network.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_equipment_network.fr.xlsx`,
}

export const DECENTRALIZED_NETWORK_FLEX_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_equipment_network_description.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_equipment_network_description.fr.xlsx`,
}

export const DECENTRALIZED_DATA_CENTER_FULL_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_datacenter_full.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_datacenter_full.fr.xlsx`,
}

export const DECENTRALIZED_DATA_CENTER_FULL_FLEX_FILE_PATHS = {
  en: `${DECENTRALIZED_FOLDERS.en}/decentralized_inventory_datacenter_full_description.en.xlsx`,
  fr: `${DECENTRALIZED_FOLDERS.fr}/decentralized_inventory_datacenter_full_description.fr.xlsx`,
}
