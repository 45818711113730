import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"

const TextChartODD13GHGEmissionsLocalDataRoomMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const data = score?.odd13_internal_hosting_ghg_emissions_average || {}

  const getUnit = (value, maximum = 500) => {
    if (value > maximum) {
      return `${translationPrefix}kt_unit`
    }
    return `${translationPrefix}t_unit`
  }

  const getValue = (value, maximum = 500, fixed = 0) => {
    if (value > maximum) {
      return (value / 1000).toFixed(fixed)
    }
    return Math.round(value)
  }

  const content = {
    kpi: getValue(data?.median_small?.total),
    kpi_unit: t(getUnit(data?.median_small?.total)),
    kpi_text: t(`${translationPrefix}chartGHGEmissionsLocalDataRoom.text`),
    reference: "*" + data?.median_small?.average,
    reference_unit: (
      <Unit
        unit={t(
          `${translationPrefix}chartGHGEmissionsLocalDataRoom.reference_unit`
        )}
      />
    ),
    reference_text: t(
      `${translationPrefix}chartGHGEmissionsLocalDataRoom.subtext`
    ),
  }

  const content_for_display = <TextChartMinor content={content} />

  return <TextChart content={content_for_display} />
}

TextChartODD13GHGEmissionsLocalDataRoomMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13GHGEmissionsLocalDataRoomMolecule
