import { BOTTOM_LEGEND } from "services/charts/legend"
import { DEFAULT_DATALABELS } from "services/charts/datalabels"
import { tooltipsLabelWithUnitValue } from "services/charts/tooltips"

export const DEFAULT_OPTIONS = {
  maintainAspectRatio: false,
  legend: BOTTOM_LEGEND,
  tooltips: {
    callbacks: {
      label: tooltipsLabelWithUnitValue,
    },
  },
  plugins: {
    ...DEFAULT_DATALABELS,
  },
}
