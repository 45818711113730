import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"

const ChartSdg13MaturityScoresMolecule = ({ maturityScore }) => {
  const { t } = useTranslation()

  const generateData = useCallback(() => {
    const labels = maturityScore.labels.map((label) =>
      t(
        `score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.legend.${label}`
      )
    )

    return [
      {
        labels,
        datasets: maturityScore.datasets,
      },
    ]
  }, [maturityScore])

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(
        `score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.help`
      )}
    >
      <RadarChart
        title={t(
          "score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.title"
        )}
        data={generateData()}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSdg13MaturityScoresMolecule.propTypes = {
  maturityScore: PropTypes.object.isRequired,
}

export default ChartSdg13MaturityScoresMolecule
