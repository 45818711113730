import React from "react"
import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
} from "@shopify/polaris"

const ThreeSectionsSkeletonPage = () => {
  return (
    <Page>
      <Layout>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="medium" />}
          description={<SkeletonBodyText lines={2} />}
        >
          <Card>
            <Card.Section title={<SkeletonDisplayText size="small" />}>
              <SkeletonBodyText lines={10} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="medium" />}
          description={<SkeletonBodyText lines={2} />}
        >
          <Card>
            <Card.Section title={<SkeletonDisplayText size="small" />}>
              <SkeletonBodyText lines={6} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="medium" />}
          description={<SkeletonBodyText lines={2} />}
        >
          <Card>
            <Card.Section title={<SkeletonDisplayText size="small" />}>
              <SkeletonBodyText lines={3} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  )
}

export default ThreeSectionsSkeletonPage
