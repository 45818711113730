import PropTypes from "prop-types"
import React from "react"
import TextAnalysisCloudGHGEmission from "views/molecules/canvases/charts/cloud/TextAnalysisCloudGHGEmission"
import TextAnalysisCloudService from "views/molecules/canvases/charts/cloud/TextAnalysisCloudService"
import TextAnalysisCloudServiceInCarbonCountry from "views/molecules/canvases/charts/cloud/TextAnalysisCloudServiceInCarbonCountry"
import ChartAnalysisCloudByRegion from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudByRegion"
import ChartAnalysisCloudCo2VSCost from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudCo2VSCost"
import ChartAnalysisCloudMainProvider from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudMainProvider"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartAnalysisCloudByZone from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudByZone"
import ChartAnalysisCloudServicesAndCosts from "views/molecules/canvases/charts/cloud/ChartAnalysisCloudServicesAndCosts"
import { useTranslation } from "react-i18next"

const CloudAnalysisOrganism = ({ score, isOnGlobalDashboard }) => {
  const { t } = useTranslation()
  const { cloud_score } = score

  const zone_emissions = cloud_score?.zone_emissions
  const service_biggest_emission = cloud_score?.service_biggest_emission
  const services_and_costs = cloud_score?.services_and_costs
  const biggest_emissions_of_low_service_use =
    cloud_score?.biggest_emissions_of_low_service_use

  const clouder_emission_by_region =
    score?.cloud_score?.clouder_emission_by_region

  const cost_and_co2_by_provider = score?.cloud_score?.cost_and_co2_by_provider

  const top_clouder_per_region = score?.cloud_score?.top_clouder_per_region

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_datacenter_external_cloud_maturity}
      chart1={
        <TextAnalysisCloudGHGEmission
          score={score}
          hidePercentageOfEmissions={isOnGlobalDashboard}
        />
      }
      chart2={
        <TextAnalysisCloudService
          serviceName={`${service_biggest_emission.provider} ${service_biggest_emission.service_name}`}
          value={service_biggest_emission?.co2e}
          percentage={service_biggest_emission?.percentage}
        />
      }
      chart3={
        zone_emissions ? (
          <ChartAnalysisCloudByZone
            datasets={cloud_score.zone_emissions?.datasets || []}
            labels={cloud_score.zone_emissions?.label_keys || []}
            unitKey={cloud_score.zone_emissions?.unit_key || ""}
          />
        ) : (
          <ChartAnalysisCloudByRegion
            datasets={clouder_emission_by_region?.datasets || []}
            labels={clouder_emission_by_region?.label_keys || []}
            unitKey={clouder_emission_by_region?.unit_key || ""}
          />
        )
      }
      chart4={
        services_and_costs ? (
          <ChartAnalysisCloudServicesAndCosts
            datasets={services_and_costs?.datasets || []}
            labels={services_and_costs?.label_keys || []}
            unitKey={services_and_costs?.unit_key || ""}
            lineUnitKey={services_and_costs?.line_unit_key || ""}
            title={t("analyze:it_domain.cloud.charts.services_and_costs.title")}
          />
        ) : (
          <ChartAnalysisCloudCo2VSCost
            co2_unit={t(
              `units:units.emission.${cost_and_co2_by_provider.co2_unit_key}`
            )}
            datasets={cost_and_co2_by_provider?.datasets || []}
            labels={cost_and_co2_by_provider?.label_keys || []}
          />
        )
      }
      chart5={
        <TextAnalysisCloudServiceInCarbonCountry
          value={score?.cloud_score?.high_ghg_intensity_percentage}
        />
      }
      chart6={
        biggest_emissions_of_low_service_use ? (
          <ChartAnalysisCloudServicesAndCosts
            datasets={biggest_emissions_of_low_service_use?.datasets || []}
            labels={biggest_emissions_of_low_service_use?.label_keys || []}
            unitKey={biggest_emissions_of_low_service_use?.unit_key || ""}
            lineUnitKey={
              biggest_emissions_of_low_service_use?.line_unit_key || ""
            }
            title={t(
              "analyze:it_domain.cloud.charts.biggest_emissions_of_low_service_use.title"
            )}
          />
        ) : (
          <ChartAnalysisCloudMainProvider
            datasets={top_clouder_per_region?.datasets || []}
            labels={top_clouder_per_region?.label_keys || []}
            unitKey={top_clouder_per_region?.unit_key || ""}
          />
        )
      }
    />
  )
}

CloudAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  isOnGlobalDashboard: PropTypes.bool,
}

CloudAnalysisOrganism.defaultProps = {
  isOnGlobalDashboard: false,
}

export default CloudAnalysisOrganism
