import { backClient } from "helpers/AxiosHelper"
import { ApiError } from "helpers/errors/ApiError"
import i18n from "i18next"

export default class ResetPassword {
  static async sendInstructions(email) {
    const data = { email: email }

    try {
      await backClient.post("/api/send_reset_password_instructions.json", data)
    } catch (err) {
      if (err.isAxiosError) {
        switch (err.response.status) {
          case 404:
            throw new ApiError(404, i18n.t("common:login.errors.unknown_email"))
          default:
            throw new ApiError(
              err.response.status,
              i18n.t("common:login.errors.global_error")
            )
        }
      } else {
        throw new Error(err)
      }
    }
  }

  static async reset(newPassword, token) {
    const data = {
      new_password: newPassword,
      token: token,
    }

    try {
      await backClient.post("/api/reset_password.json", data)
    } catch (err) {
      let code = err.response.status
      let message = ""
      const apiErrorCode = err.response.data.code

      switch (code) {
        case 400:
          switch (apiErrorCode) {
            case 3010:
              message = "common:login.errors.3010"
              break
            case 3011:
              message = "common:login.errors.3011"
              break
            default:
              message = "common:login.errors.3012"
              break
          }
          break
        case 401:
          switch (apiErrorCode) {
            case 3000:
              message = "common:login.password_reset.error"
              break
            case 3001:
              message = "common:login.password_reset.timeout"
              break
            default:
              message = "common:login.errors.other"
              break
          }
          break
        default:
          message = "common:login.errors.other"
      }

      throw new ApiError(code, message)
    }
  }
}
