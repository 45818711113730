import React from "react"
import { Select } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { locations } from "helpers/CollectHelper"
import { useAppContext } from "../../../../components/AppContext"

const CountryNameFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const { countryOptions } = useAppContext()

  return (
    <Select
      label={t(`activation:${type}.questions.${property}.label`)}
      placeholder={t("activation:common.default_select_country_placeholder")}
      id={property}
      name={property}
      options={locations()}
      value={value}
      error={error}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

CountryNameFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CountryNameFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default CountryNameFieldAtom
