import React from "react"
import { useTranslation } from "react-i18next"

const ScoreChartErrorBoundary = () => {
  const { t } = useTranslation()

  const { description } = t("error_boundary:chart", {
    returnObjects: true,
  })

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        fontSize: "1.5rem",
        textAlign: "center",
      }}
    >
      {description}
    </div>
  )
}

export default ScoreChartErrorBoundary
