import React from "react"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import BarChartMoleculeDataQuality from "views/molecules/charts/BarChartMoleculeDataQuality"
import { displayUrl } from "utils/UrlReformater"
import Loading from "components/widgets/Loading"
import NodeOrganization from "services/organizations/NodeOrganization"
import { round } from "services/units/round"

const DataQualitySwitch = () => {
  const { envCollect, isEnvCollectLoading } = useAppContext()
  const { t } = useTranslation()

  if (isEnvCollectLoading) {
    return <Loading />
  }
  const chart1 = () => {
    const content = {
      kpi: envCollect?.total_amount_of_uploaded_object,
      kpi_unit: "",
      kpi_text: t(`collect:data_quality.kpi1.title`),
      reference: envCollect?.data_quality_reference,
      reference_text: "The reference data quality is 100%",
      reference_unit: "%",
    }

    return <TextChart content={<TextChartMajor content={content} />} />
  }

  const chart2 = () => {
    const content = {
      kpi: envCollect?.percentage_of_valid_data,
      kpi_unit: "%",
      kpi_text: t(`collect:data_quality.kpi2.title`),
      reference: envCollect?.data_quality_reference,
      reference_text: "The reference data quality is 100%",
      reference_unit: "%",
    }

    return <TextChart content={<TextChartMajor content={content} />} />
  }

  const chart3 = () => {
    const data = {
      labels: envCollect?.matching_assets.labels.map((label) =>
        t(`common:equipment_types.${label}`)
      ),
      datasets: envCollect?.matching_assets.datasets.map((dataset, index) => ({
        data: dataset.data.map((value) => (value === 0 ? null : value)),
        label: t(`collect:data_quality.chart1.${dataset.label}`),
        backgroundColor: [
          Config.common.colorsEvaluation.green,
          Config.common.colors[0],
          Config.common.colorsEvaluation.yellow,
        ][index % 3],
        stack: "combined",
      })),
    }

    const plainData = envCollect?.matching_assets.datasets.map((dataset) => ({
      data: dataset.data,
      label: dataset.label,
    }))

    return (
      <BarChartMoleculeDataQuality
        title={t(`collect:data_quality.chart1.title`)}
        data={data}
        plainData={plainData}
        yLabel="#"
      />
    )
  }
  const chart4 = () => {
    const labels = envCollect?.ccf_usages_ordered_desc.labels.map(
      (item) => item
    )
    const data = {
      labels: labels,
      datasets: envCollect?.ccf_usages_ordered_desc.datasets.map(
        (item, index) => ({
          label: NodeOrganization.getNodeNameWithVersion(item.label),
          data: item.data,
          backgroundColor:
            Config.common.colors[index % Config.common.colors.length],
          stack: "combined",
        })
      ),
    }

    const plainData = envCollect?.ccf_usages_ordered_desc.datasets.map(
      (item) => ({
        label: item.label,
        data: item.data,
      })
    )

    return (
      <BarChartMoleculeDataQuality
        data={data}
        title={t(`collect:data_quality.chart2.title`)}
        yLabel={t(`collect:data_quality.chart2.yLabel`)}
        plainData={plainData}
      />
    )
  }

  const websiteChart = () => {
    const labels = envCollect?.website_ordered_desc.labels.map((item) =>
      displayUrl({ url: item })
    )
    const data = {
      labels: labels,
      datasets: envCollect?.website_ordered_desc.datasets.map(
        (item, index) => ({
          label: NodeOrganization.getNodeNameWithVersion(item.label),
          data: item.data.map((value) => (value === 0 ? null : value)),
          backgroundColor:
            Config.common.colors[index % Config.common.colors.length],
          stack: "combined",
        })
      ),
    }

    const plainData = envCollect?.website_ordered_desc.datasets.map((item) => ({
      label: item.label,
      data: item.data,
    }))

    return (
      <BarChartMoleculeDataQuality
        title={t(`collect:data_quality.chartWebsite.title`)}
        yLabel={t(`collect:data_quality.chartWebsite.yLabel`)}
        data={data}
        plainData={plainData}
      />
    )
  }
  const chart5 = () => {
    const content = {
      kpi: envCollect?.percentage_of_matched_assets.total_match,
      kpi_unit: "%",
      kpi_text: t(`collect:data_quality.kpi3.title`),
      reference: envCollect?.data_quality_reference,
      reference_text: "The reference data quality is 100%",
      reference_unit: "%",
      sub_kpi_text: t(`collect:data_quality.kpi3.subtitle`),
      sub_kpi: envCollect?.percentage_of_matched_assets.perfect_match,
      sub_kpi_unit: "%",
    }

    return <TextChart content={<TextChartMajor content={content} />} />
  }

  const chart6 = () => {
    const data = {
      labels: envCollect?.tech_team_commute.labels.map((label) =>
        t(`common:transport_mode.${label}`)
      ),
      datasets: envCollect?.tech_team_commute.datasets.map(
        (dataset, index) => ({
          data: dataset.data.map((value) =>
            +value === 0 ? null : round(+value)
          ),
          label: NodeOrganization.getNodeNameWithVersion(dataset.label),
          backgroundColor:
            Config.common.colors[index % Config.common.colors.length],
          stack: "combined",
        })
      ),
    }

    const plainData = envCollect?.tech_team_commute.datasets.map((item) => ({
      label: item.label,
      data: item.data,
    }))

    return (
      <BarChartMoleculeDataQuality
        title={t(`collect:data_quality.chart3.title`)}
        yLabel={t(`collect:data_quality.chart3.yLabel`)}
        data={data}
        plainData={plainData}
      />
    )
  }

  const chartKeyToCallback = {
    matching_assets: chart3,
    ccf_usages_ordered_desc: chart4,
    tech_team_commute: chart6,
    website_ordered_desc: websiteChart,
  }

  const charts = (() => {
    const defaultChartKeys = [
      "matching_assets",
      "ccf_usages_ordered_desc",
      "tech_team_commute",
    ]

    const substituteChartKeys = ["website_ordered_desc"]
    const useSubstituteKeys = []
    let substituteCount = 0

    const chartKeys = defaultChartKeys.map((defaultKey) => {
      if (!envCollect[defaultKey]) {
        const substituteKey = substituteChartKeys.find(
          (key) => !useSubstituteKeys[substituteCount] && !!envCollect[key]
        )

        if (substituteKey) {
          useSubstituteKeys.push(substituteKey)
        }
        return substituteKey
      }

      return defaultKey
    })
    return chartKeys.map((key) => chartKeyToCallback[key] || (() => null))
  })()

  return (
    <ScoreLayoutOrganism
      chart1={chart1()}
      chart2={chart2()}
      chart3={charts[0]()}
      chart4={charts[1]()}
      chart5={chart5()}
      chart6={charts[2]()}
    />
  )
}

DataQualitySwitch.propTypes = {}

export default DataQualitySwitch
