import PropTypes from "prop-types"
import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useAppContext } from "components/AppContext"
import { round } from "services/units/round"

const ChartODD13EmissionBreakdownPerRegionMolecule = ({
  score,
  translationScope = "",
}) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const help = currentRootNode?.is_corporate
    ? t("score:score.13.chartGHGEmissionsPerEmployee.corporate.help")
    : t("score:score.13.chartGHGEmissionsPerEmployee.provider.help")

  const getTranslationPath = () => {
    if (translationScope === "global") {
      return `score:score.13.chartEmissionBreakdownPerEntity`
    } else {
      return `score:score.13.chartEmissionBreakdownPerRegion`
    }
  }

  const emissionsBreakdown = useCallback(() => {
    const doughnutChartsData = score?.emissions_breakdown?.doughnut_charts || []
    const doughnutChartsDataTotal =
      score?.emissions_breakdown?.doughnut_charts_data_total || 0

    const [
      emissionBreakdownTotalFormatted,
      emissionBreakdownTotalFormattedUnit,
    ] = NumberFormatterApp.getNumberFormatter(
      doughnutChartsDataTotal,
      "emission",
      "t_unit"
    )

    const doughnutChartsDataFormatted = doughnutChartsData.map((node) => {
      return {
        label: NodeOrganization.getName(node.label, node.type, {
          countryShortName: true,
        }),
        data: node.data,
        unit: t(`units:units.emission.${node.unit}`),
      }
    })

    return {
      centerText: round(emissionBreakdownTotalFormatted),
      centerTextUnit: NumberFormatterApp.getI18nUnit(
        "emission",
        emissionBreakdownTotalFormattedUnit
      ),
      data: doughnutChartsDataFormatted,
    }
  }, [score])

  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <DoughnutChart
        title={t(`${getTranslationPath()}.title`)}
        centerText={emissionsBreakdown().centerText}
        centerTextUnit={t(emissionsBreakdown().centerTextUnit)}
        data={emissionsBreakdown().data}
        large={false}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13EmissionBreakdownPerRegionMolecule.propTypes = {
  score: PropTypes.object,
  translationScope: PropTypes.string.isRequired,
}

export default ChartODD13EmissionBreakdownPerRegionMolecule
