import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import MultipleChoiceField from "../../../fields/MultipleChoiceField"
import { useTranslation } from "react-i18next"
import Select from "components/widgets/CollectPage/forms/fields/Select"
import Objective16TeamComposition, {
  teamConfigGenerator,
} from "components/widgets/CollectPage/forms/pillars/financial/TeamCompositionFields"
import { Module } from "components/widgets/CollectPage/forms/Module"
import { POLICY_CSR } from "utils/constants/centralization/policyItems"

const CDP_TYPES = ["climate_change", "forests", "water"]
const CDP_FIELDS = CDP_TYPES.map((type) => `governance_csr_cdp_${type}`)
const CDP_RANKINGS = ["a", "b", "b-", "c", "c-", "d", "d-", "f"]

const TEAM = "governance_csr"
const teamConfig = teamConfigGenerator(TEAM)

const fields = [
  ...teamConfig.fields,
  "governance_csr_budget_share",
  "governance_csr_leaders_training",
  "governance_csr_leaders_percentage",
  "governance_csr_part_of_global_strategy",
  "governance_csr_cso_in_excom",
  "governance_csr_kpis_defined",
  "governance_csr_kpis_aligned_with_sdgs",
  "governance_csr_kpis_incentive_for_execs",
  "governance_csr_kpis_trickled_down",
  "governance_csr_data_disclosed_to_cdp",
  "governance_csr_label_bcorp",
  "governance_csr_label_positive_workspace",
  "governance_csr_label_lucie",
  "governance_csr_label_csr_involved",
  ...CDP_FIELDS,
  "governance_csr_strategy_sbti",
  "governance_csr_emissions_with_scope_3",
]

const translationPath = "16.csr_strategy"
const tPrefix = `collect:questions.${translationPath}`

const disableConfig = [
  {
    disablingField: "governance_csr_data_disclosed_to_cdp",
    fieldsToDisable: CDP_FIELDS,
  },
  teamConfig.disablingConfig,
  {
    disablingField: "governance_csr_kpis_defined",
    fieldsToDisable: [
      "governance_csr_kpis_aligned_with_sdgs",
      "governance_csr_kpis_incentive_for_execs",
      "governance_csr_kpis_trickled_down",
    ],
  },
]

const CsrStrategy = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath, disableConfig })

  const translatedRankings = useCallback(
    () =>
      CDP_RANKINGS.map((ranking) => ({
        label: t(`${tPrefix}.cdp.ranks.${ranking}`),
        value: ranking,
      })),
    [t]
  )

  // Hidden until centralization, cf VER-2152
  const centralizedQuestions = (
    <FormLayout>
      <MultipleChoiceField
        id="labels"
        choices={[
          "governance_csr_label_bcorp",
          "governance_csr_label_positive_workspace",
          "governance_csr_label_lucie",
          "governance_csr_label_csr_involved",
        ]}
      />

      <YesNoRadio id="governance_csr_data_disclosed_to_cdp" />
      <ExternalLabel translationPathName="cdp" />
      <table>
        <thead>
          <tr>
            <td />
            {CDP_TYPES.map((type, index) => (
              <td key={index}>{t(`${tPrefix}.cdp.${type}`)}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t(`${tPrefix}.cdp.ranking`)}</td>
            {CDP_FIELDS.map((field, index) => (
              <td key={index} className="select--small">
                <Select id={field} options={translatedRankings()} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <YesNoRadio id="governance_csr_strategy_sbti" />
    </FormLayout>
  )

  return (
    <Form>
      <FormLayout>
        <Module module={POLICY_CSR}>
          <div className="columns">
            <div className="column">
              <Objective16TeamComposition prefix={TEAM} />

              <FormLayout>
                <TextField id="governance_csr_budget_share" type="number" />

                <YesNoRadio id="governance_csr_leaders_training" />
                <TextField
                  id="governance_csr_leaders_percentage"
                  type="number"
                />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="governance_csr_part_of_global_strategy" />
                <YesNoRadio id="governance_csr_cso_in_excom" />

                <YesNoRadio id="governance_csr_kpis_defined" />
                <YesNoRadio id="governance_csr_kpis_aligned_with_sdgs" />
                <YesNoRadio id="governance_csr_kpis_incentive_for_execs" />
                <YesNoRadio id="governance_csr_kpis_trickled_down" />

                <YesNoRadio id="governance_csr_emissions_with_scope_3" />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default CsrStrategy
