import PropTypes from "prop-types"
import {
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"

export const statusPropTypes = PropTypes.oneOf([
  KPI_STATUS_UP,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_DOWN,
])

export const compareKpiProps = PropTypes.shape({
  value: PropTypes.number,
  difference: PropTypes.number,
  status: statusPropTypes.isRequired,
})
