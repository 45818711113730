import React, { useEffect, useState } from "react"
import { useFormContext } from "./forms/FormContext"

/**
 * Reset a number of fields if another is set to a specific value
 * @param {string} nullifyingField
 * @param {string[]} fieldsToNullify
 * @param {*} defaultCheck
 */
const useNullifyingField = (
  nullifyingField,
  fieldsToNullify,
  defaultCheck = true
) => {
  const [disabled, setDisabled] = useState({})
  const { formData, setFormData } = useFormContext()

  useEffect(() => {
    if (formData?.[nullifyingField] === defaultCheck) {
      const nextFormData = { ...formData }
      const nextDisabled = {}
      fieldsToNullify.forEach((field) => {
        nextFormData[field] = null
        nextDisabled[field] = true
      })
      setFormData(nextFormData)
      setDisabled(nextDisabled)
    }
  }, [formData?.[nullifyingField]])

  return {
    disabled,
  }
}

export default useNullifyingField
