import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartHeaderAtom from "views/molecules/organization/ChartHeaderAtom"

const ChartOrganizationHeaderMolecule = ({
  organization,
  hideCollectIcons,
}) => {
  const { t } = useTranslation()

  return (
    <ChartHeaderAtom
      title={organization.name}
      subtitle={t(`dashboard:chart.types.partner`)}
      hideCollectIcons={hideCollectIcons}
    />
  )
}

ChartOrganizationHeaderMolecule.propTypes = {
  organization: PropTypes.object.isRequired,
  hideCollectIcons: PropTypes.bool,
}

ChartOrganizationHeaderMolecule.defaultProps = {
  hideCollectIcons: false,
}

export default ChartOrganizationHeaderMolecule
