import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import useRequest from "hooks/useRequest"
import { useAppContext } from "components/AppContext"
import { useEntity } from "hooks/useAxios"
import OrganizationEntity from "services/entities/OrganizationEntity"

const ChartContext = React.createContext()

export const useChart = () => {
  return useContext(ChartContext)
}

const markIsCurrentNodeBelow = (tree) => {
  if (!tree) return

  const isCurrentNodeBelow =
    tree.is_current_node || tree.children.some(markIsCurrentNodeBelow)

  tree.isCurrentNodeBelow = isCurrentNodeBelow

  return isCurrentNodeBelow
}

const ChartProvider = ({ children, organize }) => {
  const [toastStatus, setToastStatus] = useState(0)

  const organizationEntity = useEntity(OrganizationEntity)
  const { currentRootNode, reloadCurrentNode, currentNode } = useAppContext()

  const {
    data: organization,
    reload: reloadOrganization,
    isLoading,
  } = useRequest(
    () => organizationEntity.getCurrentOrganization(),
    [currentRootNode, currentNode],
    {
      condition: !!currentRootNode,
      getter: ({ data }) => {
        markIsCurrentNodeBelow(data)

        return data
      },
    }
  )

  const reload = (
    { organization, currentNode } = { organization: true, currentNode: true }
  ) => {
    if (organization) {
      reloadOrganization()
    }
    if (currentNode) {
      reloadCurrentNode()
    }
  }

  return (
    <ChartContext.Provider
      value={{
        organization,
        isLoading,

        toastStatus,
        setToastStatus,

        organize,
        reload,
      }}
    >
      {children}
    </ChartContext.Provider>
  )
}

ChartProvider.propTypes = {
  currentScope: PropTypes.object,
  children: PropTypes.any,
  organize: PropTypes.bool,
}

ChartProvider.defaultProps = {
  organize: false,
}

export default ChartProvider
