export const TOAST_STATUS_INACTIVE = 0
export const TOAST_STATUS_COLLECT_UPDATED = 1
export const TOAST_STATUS_INVALID_COLLECT = 2
export const TOAST_STATUS_COLLECT_CLOSED = 3
export const TOAST_STATUS_ERROR = 4
export const TOAST_STATUS_FILE_PROCESSED = 5
export const TOAST_STATUS_FILE_REJECTED = 6
export const TOAST_STATUS_ALL_CHILDREN_NOT_KEPT = 7
export const TOAST_STATUS_VERSION_CHILDREN_KEPT = 8
export const TOAST_STATUS_PREFERRED_SELECTED_VERSION = 9
export const TOAST_STATUS_PREFERRED_UNSELECTED_VERSION = 10

export const ERROR_TOAST_STATUSES = [
  TOAST_STATUS_INVALID_COLLECT,
  TOAST_STATUS_ERROR,
  TOAST_STATUS_FILE_REJECTED,
]
