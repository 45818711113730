import React from "react"
import { useTranslation } from "react-i18next"
import { MediaCard } from "@shopify/polaris"
import PropTypes from "prop-types"

const TemplateMediaCardReportAtom = ({ filename, reportImage }) => {
  const { t } = useTranslation()

  return (
    <MediaCard
      title={t("share:content.template.title")}
      primaryAction={{
        external: true,
        download: filename,
        content: `${t("share:content.template.button")}`,
        url: `${t("share:content.template.file")}`,
      }}
      description={t("share:content.template.description")}
      size="small"
      portrait={true}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src={reportImage}
      />
    </MediaCard>
  )
}

TemplateMediaCardReportAtom.propTypes = {
  filename: PropTypes.string,
  reportImage: PropTypes.string,
}

export default TemplateMediaCardReportAtom
