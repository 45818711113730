import { useState, useEffect } from "react"

export const useDebounce = (value, milliSeconds = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), milliSeconds)

    return () => {
      clearTimeout(timer)
    }
  }, [value, milliSeconds])

  return { debouncedValue }
}
