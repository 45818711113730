import EquipmentTypeDoughnutChartAtom from "views/atoms/charts/doughnuts/EquipmentTypeDoughnutChartAtom"
import NodeDoughnutChartAtom from "views/atoms/charts/doughnuts/NodeDoughnutChartAtom"
import React from "react"
import PropTypes from "prop-types"

const EquipmentDetailOrganism = ({ fileQuality }) => {
  if (!fileQuality?.nodes)
    return (
      <EquipmentTypeDoughnutChartAtom
        equipmentTypes={fileQuality?.equipment_types}
      />
    )

  return (
    <div className="doughnut-holder">
      <div className="doughnut">
        <EquipmentTypeDoughnutChartAtom
          equipmentTypes={fileQuality?.equipment_types}
        />
      </div>
      <div className="doughnut">
        <NodeDoughnutChartAtom nodes={fileQuality?.nodes} />
      </div>
    </div>
  )
}

EquipmentDetailOrganism.propTypes = {
  fileQuality: PropTypes.shape({
    equipment_types: PropTypes.object.isRequired,
    nodes: PropTypes.object,
  }),
}

export default EquipmentDetailOrganism
