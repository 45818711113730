import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  Form as PForm,
  FormLayout,
  Icon,
  Modal,
  SkeletonBodyText,
  Stack,
} from "@shopify/polaris"
import {
  CirclePlusMajor,
  CirclePlusMinor,
  EditMinor,
} from "@shopify/polaris-icons"
import {
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import { useFormContext } from "../../FormContext"
import { useCollectContext } from "../../../CollectContextProvider"
import { GoodProjectsEntity } from "../../../../../../services/entities/StandardEntity"
import { useEntity } from "../../../../../../hooks/useAxios"

const modalTranslationPath = "1_2_11.positive_impact.good_projects.modal"
const translationPath = "collect:questions.1_2_11.positive_impact.good_projects"

const GoodProject = () => {
  const { isCollectLocked } = useCollectContext()
  const [isModalOpen, setModalOpen] = useState(false)
  const [shouldLoad, setShouldLoad] = useState(true)
  const [goodProjects, setGoodProjects] = useState(null)
  const isMounted = useRef(null)
  const [isSending, setIsSending] = useState(false)
  const { t } = useTranslation()

  const {
    formData,
    setFormData,
    setErrors,
    setTranslationPath,
  } = useFormContext()

  useEffect(() => setTranslationPath(modalTranslationPath), [])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  const goodProjectsEntity = useEntity(GoodProjectsEntity)

  useEffect(() => {
    if (!shouldLoad) return
    setShouldLoad(false)
    goodProjectsEntity.list().then((response) => {
      if (!isMounted.current) return
      setGoodProjects(response.data)
    })
  }, [shouldLoad])

  const toggleModalOpen = useCallback(() => {
    if (isModalOpen) {
      setErrors({})
      setFormData({})
      setShouldLoad(true)
    }
    setModalOpen(!isModalOpen)
  }, [isModalOpen])

  const saveProject = () => {
    const successCallback = () => {
      setIsSending(false)
      setShouldLoad(true)
      toggleModalOpen()
    }
    const errorCallback = (error) => {
      setIsSending(false)
      setErrors(error.response.data)
    }
    const data = new FormData()
    if (formData.initiative_type) {
      data.set("good_project[initiative_type]", formData.initiative_type)
    }
    if (formData.budget) {
      data.set("good_project[budget]", formData.budget)
    }
    if (formData.green_KPI_monitored != undefined) {
      // Attention: green_KPI_monitored is boolean, that's way we check using undefined
      data.set(
        "good_project[green_KPI_monitored]",
        formData.green_KPI_monitored
      )
    }
    if (formData.benefits) {
      data.set("good_project[benefits]", formData.benefits)
    }
    setErrors({})
    setIsSending(true)
    const promise = formData.id
      ? goodProjectsEntity.update(formData.id, data)
      : goodProjectsEntity.create(data)
    promise.then(successCallback).catch(errorCallback)
  }

  const deleteProject = useCallback(() => {
    if (!formData.id) return
    setIsSending(true)
    goodProjectsEntity
      .delete(formData.id)
      .then(() => {
        setIsSending(false)
        setShouldLoad(true)
        toggleModalOpen()
      })
      .catch(() => {
        setIsSending(false)
      })
  }, [formData])

  const modalMarkup = () => {
    const title = formData.id
      ? t(translationPath + ".modal.edit")
      : t(translationPath + ".modal.add")
    const secondaryAction = formData.id
      ? {
          content: t("common:delete"),
          onAction: deleteProject,
          destructive: true,
          loading: isSending,
        }
      : null
    return (
      <Modal
        open={isModalOpen}
        onClose={toggleModalOpen}
        title={title}
        primaryAction={{
          content: t("common:save"),
          onAction: saveProject,
          loading: isSending,
        }}
        secondaryActions={secondaryAction}
      >
        <div className="social">
          <Modal.Section>
            <PForm onSubmit={saveProject} implicitSubmit={false}>
              <FormLayout>
                <Select
                  id="initiative_type"
                  options={[
                    {
                      label: t(translationPath + ".type.options.poverty"),
                      value: "poverty",
                    },
                    {
                      label: t(translationPath + ".type.options.hunger"),
                      value: "hunger",
                    },
                    {
                      label: t(translationPath + ".type.options.education"),
                      value: "education",
                    },
                    {
                      label: t(translationPath + ".type.options.other"),
                      value: "other",
                    },
                  ]}
                />
                <TextField id="budget" type="number" />
                <YesNoRadio id="green_KPI_monitored" />
                <TextField id="benefits" type="string" />
              </FormLayout>
            </PForm>
          </Modal.Section>
        </div>
      </Modal>
    )
  }

  const tableMarkup = useCallback(() => {
    if (goodProjects == null) return <SkeletonBodyText />
    const items = []
    if (goodProjects.length == 0) {
      items.push(
        <tr key="0">
          <td colSpan={5}>
            <Stack
              distribution="center"
              alignment="center"
              spacing="extraTight"
            >
              <p>{t(translationPath + ".empty.label")}</p>
              {isCollectLocked ? null : (
                <Button plain onClick={toggleModalOpen}>
                  <Icon source={CirclePlusMajor} />
                </Button>
              )}
            </Stack>
          </td>
        </tr>
      )
    } else {
      goodProjects.forEach((project) => {
        items.push(
          <tr key={project.id}>
            <td>
              {" "}
              {t(translationPath + ".type.options." + project.initiative_type)}
            </td>
            <td>
              {project.budget} {t(translationPath + ".modal.budget.suffix")}
            </td>
            <td>
              {project.green_KPI_monitored
                ? t("collect:questions.common.yes")
                : t("collect:questions.common.no")}
            </td>
            {isCollectLocked ? null : (
              <td>
                <Button
                  plain
                  onClick={() => {
                    setFormData(project)
                    toggleModalOpen()
                  }}
                >
                  <Icon source={EditMinor} />
                </Button>
              </td>
            )}
          </tr>
        )
      })
    }
    return (
      <div>
        <ExternalLabel
          pathOverride="1_2_11.positive_impact"
          translationPathName="good_projects"
        />
        <Stack vertical>
          <table className="Verdikt-DynamicTable">
            <thead>
              <tr className="leftAligned">
                <th>{t(translationPath + ".type.label")}</th>
                <th>{t(translationPath + ".budget.label")}</th>
                <th>{t(translationPath + ".green_KPI_monitored.label")}</th>
                {isCollectLocked ? null : (
                  <th>
                    <Button plain onClick={toggleModalOpen}>
                      <Icon source={CirclePlusMinor} />
                    </Button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </Stack>
      </div>
    )
  }, [goodProjects])

  return (
    <div>
      {modalMarkup()}
      {tableMarkup()}
    </div>
  )
}

export default GoodProject
