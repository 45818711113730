import { useCallback, useEffect, useState } from "react"
import useSearch from "hooks/useSearch"
import { useEntity } from "hooks/useAxios"
import NodeEntity from "services/entities/NodeEntity"
import _ from "lodash"
import useRequest from "hooks/useRequest"
import { useAppContext } from "components/AppContext"

const SEARCH_LIST_ID = "compareSearchOrganism__search"

const useCompare = (storedNodes, condition, compareRequester) => {
  const { currentNode } = useAppContext()

  const [comparedNodes, setComparedNodes] = useState(storedNodes)

  const nodeEntity = useEntity(NodeEntity)

  useEffect(() => setComparedNodes(storedNodes), [storedNodes])

  const selectedNodeIds = useCallback(
    () => comparedNodes.map((node) => node.id),
    [comparedNodes]
  )

  const {
    data: comparedScores,
    isError: isComparedScoresError,
    isLoading: isComparedScoresLoading,
  } = useRequest(
    () => compareRequester(selectedNodeIds()),
    [currentNode, comparedNodes],
    {
      condition: !!currentNode && condition,
    }
  )

  const onNodeAction = (node) =>
    setComparedNodes((oldArray) => [...oldArray, node])

  const onDelete = (node) =>
    setComparedNodes(_.reject(comparedNodes, { id: node.id }))

  const search = useSearch({
    searchListId: SEARCH_LIST_ID,
    onNodeAction: onNodeAction,
    searchRequester: nodeEntity.searchNodes,
    params: {
      excluded_node_ids: selectedNodeIds(),
    },
  })

  return {
    isComparedScoresLoading,
    isComparedScoresError,
    comparedScores,
    comparedNodes,
    onDelete,
    search,
  }
}

export default useCompare
