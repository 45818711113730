import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, FormLayout, TextField } from "@shopify/polaris"

const ReassessmentPeriodFormMolecule = ({ onChange, formData, disabled }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Section
        title={t("organization:reassessment.form.titles.period.corporate")}
      >
        <FormLayout.Group>
          <TextField
            label={t(
              "organization:reassessment.form.fields.period_name.corporate"
            )}
            id="periodName"
            name="periodName"
            value={formData.periodName}
            onChange={onChange}
            type="text"
            inputMode="text"
            required
            helpText={
              <span>
                {t(
                  "organization:reassessment.form.warnings.period_name.corporate"
                )}
              </span>
            }
            disabled={disabled}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label={t("organization:reassessment.form.fields.period_starts_on")}
            id="periodStartsOn"
            name="periodStartsOn"
            value={formData.periodStartsOn}
            onChange={onChange}
            type="date"
            disabled={disabled}
          />
          <TextField
            label={t("organization:reassessment.form.fields.period_ends_on")}
            id="periodEndsOn"
            name="periodEndsOn"
            value={formData.periodEndsOn}
            onChange={onChange}
            type="date"
            disabled={disabled}
          />
        </FormLayout.Group>
      </Card.Section>
    </Card>
  )
}

ReassessmentPeriodFormMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    periodName: PropTypes.string,
    periodStartsOn: PropTypes.string,
    periodEndsOn: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
}

ReassessmentPeriodFormMolecule.defaultProps = {
  disabled: false,
  isPartner: false,
}

export default ReassessmentPeriodFormMolecule
