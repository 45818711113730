import React from "react"
import { Icon } from "@shopify/polaris"
import { EditMajor, ViewMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"

const ModalCloudInventoriesActivator = ({ onClick, disabled }) => {
  const { isLocked } = useFieldIsLocked()

  const icon = disabled || isLocked ? ViewMajor : EditMajor

  return (
    <button onClick={onClick} disabled={disabled}>
      <div className="iconCollectType">
        <Icon source={icon} />
      </div>
    </button>
  )
}

ModalCloudInventoriesActivator.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ModalCloudInventoriesActivator.defaultProps = {
  disabled: false,
}

export default ModalCloudInventoriesActivator
