import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Banner, Card, Form, Layout, Page } from "@shopify/polaris"
import ReassessmentDuplicateFormMolecule from "views/molecules/organization/reassessment/ReassessmentDuplicateFormMolecule"
import ReassessmentPeriodFormMolecule from "views/molecules/organization/reassessment/ReassessmentPeriodFormMolecule"
import ReassessmentSubmitFormMolecule from "views/molecules/organization/reassessment/ReassessmentSubmitFormMolecule"
import { useAppContext } from "../../../components/AppContext"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import ReassessmentScenarioFormMolecule from "views/molecules/organization/reassessment/ReassessmentScenarioFormMolecule"
import ViewerOnlyBanner from "views/molecules/collects/banners/ViewerOnlyBanner"

const ReplayOrganisms = ({
  isRootNodeAdmin,
  reassessmentAvailable,
  formData,
  onSubmit,
  onChange,
  isError,
  errorType,
  isLoading,
}) => {
  const { t } = useTranslation()
  const {
    currentRootNode,
    parentOrganization,
    currentPeriod,
    currentNode,
  } = useAppContext()

  const isFormDisabled =
    isLoading ||
    !reassessmentAvailable?.status ||
    (!currentRootNode?.is_provider && !isRootNodeAdmin)

  const translationContext = currentRootNode?.is_provider
    ? "provider"
    : "standard"

  const PeriodComponent = currentRootNode?.is_provider
    ? ReassessmentScenarioFormMolecule
    : ReassessmentPeriodFormMolecule
  return (
    <>
      <Page>
        <BreadcrumbsOrganism
          parentOrganization={parentOrganization}
          currentPeriodName={currentPeriod?.name}
        />
        <br />
        <Layout>
          {currentNode?.viewer_only && <ViewerOnlyBanner />}
          {!currentRootNode?.is_provider && !isRootNodeAdmin ? (
            <Layout.Section>
              <Banner
                status="warning"
                title={t("replay:warning.unauthorized.title")}
              >
                {t("replay:warning.unauthorized.description")}
              </Banner>
            </Layout.Section>
          ) : (
            reassessmentAvailable &&
            !reassessmentAvailable?.status && (
              <Layout.Section>
                <Banner
                  status="warning"
                  title={t(
                    `replay:warning.reassessment_unavailable.${translationContext}.${reassessmentAvailable?.reason}.title`
                  )}
                >
                  {t(
                    `replay:warning.reassessment_unavailable.${translationContext}.${reassessmentAvailable?.reason}.description`
                  )}
                </Banner>
              </Layout.Section>
            )
          )}
          <Form onSubmit={onSubmit}>
            <Layout.AnnotatedSection
              title={t("replay:section.duplicate.title")}
              description={t("replay:section.duplicate.description")}
            >
              <Card>
                {isError && (
                  <Banner
                    status="critical"
                    title={t("organization:reassessment.status.error.title")}
                  >
                    {errorType
                      ? t("organization:reassessment.same_version")
                      : t("organization:reassessment.status.error.content")}
                  </Banner>
                )}
                <ReassessmentDuplicateFormMolecule
                  onChange={onChange}
                  formData={formData}
                  disabled={isFormDisabled}
                  hideOrganization={currentRootNode?.is_provider}
                  hideSocial={currentRootNode?.is_provider}
                  hideProfit={currentRootNode?.is_provider}
                />
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title={
                currentRootNode?.is_provider
                  ? t("replay:section.period.partner.title")
                  : t("replay:section.period.corporate.title")
              }
              description={
                currentRootNode?.is_provider
                  ? t("replay:section.period.partner.description")
                  : t("replay:section.period.corporate.description")
              }
            >
              <PeriodComponent
                formData={formData}
                onChange={onChange}
                disabled={isFormDisabled}
              />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title={t("replay:section.submit.title")}
              description={t("replay:section.submit.description")}
            >
              <ReassessmentSubmitFormMolecule
                formData={formData}
                isLoading={isLoading}
                disabled={isFormDisabled}
              />
            </Layout.AnnotatedSection>
          </Form>
        </Layout>
      </Page>
    </>
  )
}

ReplayOrganisms.propTypes = {
  reassessmentAvailable: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    reason: PropTypes.string,
  }).isRequired,
  isRootNodeAdmin: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    organization: PropTypes.bool.isRequired,
    collectEnvironmentalPolitics: PropTypes.bool.isRequired,
    collectEnvironmentalInventory: PropTypes.bool.isRequired,
    collectSocialPolitics: PropTypes.bool.isRequired,
    collectSocialInventory: PropTypes.bool.isRequired,
    collectProfitPolitics: PropTypes.bool.isRequired,
    collectProfitInventory: PropTypes.bool.isRequired,
    periodName: PropTypes.string,
    periodStartsOn: PropTypes.string,
    periodEndsOn: PropTypes.string,
    versionName: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  errorType: PropTypes.string,
}

ReplayOrganisms.defaultProps = {
  isError: false,
  isLoading: false,
}

export default ReplayOrganisms
