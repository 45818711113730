import {
  CENTRALIZATION_FULL,
  CENTRALIZATION_INVENTORY,
  CENTRALIZATION_POLICY,
  CENTRALIZATION_TYPES,
  INVENTORY_TYPES,
  POLICY_TYPES,
} from "utils/constants/centralization/type"

class BaseCentralization {
  static hasSnowRunCentralization(centralization = []) {
    return this.hasProperty(
      "centralized_by_service_now",
      centralization,
      CENTRALIZATION_INVENTORY
    )
  }

  /**
   * @param property {string<'inherited', 'centralized'>}
   * @param centralization {array}
   * @param centralizationType {string<CENTRALIZATION_INVENTORY, CENTRALIZATION_POLICY>, null}
   * @returns {boolean}
   */
  static hasProperty(property, centralization = [], centralizationType = null) {
    if (!centralizationType) {
      return centralization.some(
        (centralizationItem) => centralizationItem[property]
      )
    }

    const centralizationTypeList = this.centralizationListByType(
      centralizationType
    )

    return centralization.some(
      (centralizationItem) =>
        centralizationItem[property] &&
        centralizationTypeList.includes(centralizationItem.centralization_type)
    )
  }

  /**
   * @param centralizationType {string<CENTRALIZATION_INVENTORY, CENTRALIZATION_POLICY>, null}
   * @returns {string[]}
   */
  static centralizationListByType(centralizationType) {
    switch (centralizationType) {
      case CENTRALIZATION_INVENTORY:
        return INVENTORY_TYPES

      case CENTRALIZATION_POLICY:
        return POLICY_TYPES

      case CENTRALIZATION_FULL:
        return CENTRALIZATION_TYPES
    }
  }

  static defineInheritanceFromParent(parentCentralization = []) {
    return parentCentralization.map((centralization_item) => ({
      centralization_type: centralization_item.centralization_type,
      inherited:
        centralization_item.centralized || centralization_item.inherited,
    }))
  }
}

export default BaseCentralization
