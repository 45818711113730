import React from "react"
import { BrowserRouter } from "react-router-dom"
import AppLayout from "components/AppLayout"
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory"
import { initializeSentry } from "services/apps/sentry"
import { isLocalEnvironment } from "components/configs/SentryConfig"

if (!isLocalEnvironment) {
  initializeSentry()
}

const App = (
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <AppLayout />
    </Auth0ProviderWithHistory>
  </BrowserRouter>
)

export default App
