import * as Sentry from "@sentry/react"
import { Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import { sentryEnvironment } from "components/configs/SentryConfig"

const history = createBrowserHistory()

const dns =
  "https://8525a534fe1544528cc3d7afdb57f585@o4505278499258368.ingest.sentry.io/4505278587797504"

const pipelineApp = /^https:\/\/pipeline-feat-(.*?)\.herokuapp\.com/
const herokuApp = /^https:\/\/verdikt-web-(.*?)\.herokuapp\.com/
const productionApp = /^https:\/\/app\.verdikt\.io/

export const initializeSentry = () => {
  Sentry.init({
    dsn: dns,
    environment: sentryEnvironment || "local",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [pipelineApp, herokuApp, productionApp],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
  })
}

export const SentryRoute = Sentry.withSentryRouting(Route)
