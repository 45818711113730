import React, { useCallback } from "react"
import PropTypes from "prop-types"
import ChartElementBodyAtom from "views/molecules/organization/ChartElementBodyAtom"
import ChartOrganizationHeaderMolecule from "views/molecules/organization/ChartOrganizationHeaderMolecule"
import OrganizationActions from "views/molecules/organization/OrganizationActions"
import classNames from "classnames"

const ChartOrganizationsMolecule = ({ node, hidden, position }) => {
  const className = useCallback(() => {
    const classNameCollapse = hidden ? "hidden" : ""
    return `${classNameCollapse} ${position}`
  }, [hidden, position])

  if (!node.organizations || node.organizations.length === 0) return null

  return (
    <ul className={className()}>
      {node.organizations.map((organization, index) => {
        const isProgressCompleted =
          organization.progress === 100 && !!organization.score
        const nodeClasses = classNames("oc-node", "oc-node--partial_partner", {
          "oc-node--collectClosed": isProgressCompleted,
        })

        return (
          <li className="oc-hierarchy organization" key={index}>
            <div className={nodeClasses}>
              <ChartElementBodyAtom
                progress={organization.progress}
                actionComponent={
                  <OrganizationActions
                    organization={organization}
                    hasAccess={node.can_read}
                  />
                }
                headerComponent={
                  <ChartOrganizationHeaderMolecule
                    organization={organization}
                    hideCollectIcons
                  />
                }
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

ChartOrganizationsMolecule.propTypes = {
  node: PropTypes.object.isRequired,
  hidden: PropTypes.bool.isRequired,
  position: PropTypes.string,
}

export default ChartOrganizationsMolecule

ChartOrganizationsMolecule.defaultProps = {
  position: "bottom",
}
