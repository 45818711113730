import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Button, Card } from "@shopify/polaris"

const ReassessmentSubmitFormMolecule = ({ formData, isLoading, disabled }) => {
  const { t } = useTranslation()

  const isSubmitDisabled =
    disabled ||
    (formData.periodName.length === 0 && formData.versionName.length === 0)

  return (
    <Card>
      <Card.Section>
        <Button submit loading={isLoading} disabled={isSubmitDisabled}>
          {t("organization:reassessment.form.fields.submit")}
        </Button>
      </Card.Section>
    </Card>
  )
}

ReassessmentSubmitFormMolecule.propTypes = {
  formData: PropTypes.shape({
    periodName: PropTypes.string.isRequired,
    versionName: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

ReassessmentSubmitFormMolecule.defaultProps = {
  disabled: false,
}

export default ReassessmentSubmitFormMolecule
