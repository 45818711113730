import React from "react"
import { HistorySelectAtom } from "views/atoms/organization/charts/buttons/HistorySelectAtom"
import { useAppContext } from "components/AppContext"
import "stylesheets/Organization/Historization/OrganizationHistorization.scss"
import PropTypes from "prop-types"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import { CONTEXT_TYPE_EXTERNAL_PARTNER } from "utils/constants/organization/contextTypes"
import useAdminRules from "hooks/useAdminRules"

const HistorizationNavigationMolecule = ({ organize }) => {
  const { currentUser } = useAppContext()
  const { organization } = useChart()
  const { isRootNodeAdmin } = useAdminRules()

  if (!currentUser) return null

  const showEditPeriod =
    organize &&
    isRootNodeAdmin &&
    organization &&
    organization.context_type !== CONTEXT_TYPE_EXTERNAL_PARTNER

  return (
    <div className="organization__historization__navigation">
      <HistorySelectAtom showEditPeriod={showEditPeriod} />
    </div>
  )
}

HistorizationNavigationMolecule.propTypes = {
  organize: PropTypes.bool,
}

HistorizationNavigationMolecule.defaultProps = {
  organize: false,
}

export default HistorizationNavigationMolecule
