import ModalCloudUsage from "views/molecules/collects/cloud_inventories/ModalCloudUsage"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"
import CloudUsageEntity from "services/entities/CloudUsageEntity"
import CloudUsageTableLine from "components/widgets/CollectPage/forms/inventories/environment/CloudUsageTableLine"
import { Stack } from "@shopify/polaris"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"

const prefixCloud = "collect:questions.common.cloud_form."

export const FIELDS = [
  "provider",
  "region",
  "service_name",
  "usage_amount",
  "usage_unit",
  "cost",
]

const CloudUsageInventory = () => {
  const cloudUsageEntity = useEntity(CloudUsageEntity)
  const { t } = useTranslation()
  const [cloudUsage, setCloudUsage] = useState([])
  const { isLocked } = useFieldIsLocked()

  const getCloudUsage = async () => {
    try {
      const response = await cloudUsageEntity.index()
      setCloudUsage(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCloudUsage()
  }, [])

  return (
    <table className="Verdikt-DynamicTable">
      <thead>
        <tr className="leftAligned">
          {FIELDS.map((field) => (
            <th key={field}>{t(prefixCloud + field)}</th>
          ))}
          <th />
          <th>
            <ModalCloudUsage postAction={getCloudUsage} />
          </th>
        </tr>
      </thead>
      <tbody>
        {cloudUsage.map((cloud) => (
          <CloudUsageTableLine
            key={cloud.id}
            cloud={cloud}
            afterUpdateAction={getCloudUsage}
          />
        ))}

        {cloudUsage.length === 0 && (
          <tr>
            <td colSpan={9}>
              <Stack
                distribution="center"
                alignment="center"
                spacing="extraTight"
              >
                <p>
                  {t(
                    "collect:questions.13.datacenter_external.cloud.no_manual_clouds"
                  )}
                </p>
                {!isLocked && <ModalCloudUsage postAction={getCloudUsage} />}
              </Stack>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default CloudUsageInventory
