import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import WaterConsumptionForm from "./Objective6WaterConsumptionForm"

const Objective6Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "water_consumption":
        return <WaterConsumptionForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective6Form
