import ScoreValue from "components/widgets/ScoreValue"
import { Icon } from "@shopify/polaris"
import React from "react"
import { BlogMajor } from "@shopify/polaris-icons"
import NodeOrganization from "services/organizations/NodeOrganization"
import { useAppContext } from "components/AppContext"
import NodeActionMolecule from "components/widgets/Dashboard/OrganizationChart/NodeActionMolecule"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { COLLECT_PAGE, DASHBOARD_PAGE } from "hooks/useRedirection"
import { round } from "services/units/round"
import {
  HIDE_PROVIDER_MATURITY_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"

const SetCurrentScopeAction = ({ node }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()
  const { isFeatureActive } = useFeatureFlag()
  const {
    updateCurrentNode,
    currentNode,
    objectiveUri,
    redirect,
    currentOrganization,
  } = useAppContext()

  let icon = node.score ? (
    <ScoreValue score={node.score} chart category={node.category} />
  ) : (
    <Icon source={BlogMajor} />
  )

  if (
    node.score &&
    (currentRootNode.is_provider || currentOrganization.is_partial_partner) &&
    isFeatureActive(HIDE_PROVIDER_MATURITY_FEATURE)
  ) {
    icon = (
      <p className="co2e">
        <p>{round(node.odd13_CO2.value)}</p>
        <p>{t(`units:units.emission.${node.odd13_CO2.unit}`)}</p>
      </p>
    )
  }

  const onAction = async () => {
    const objective = objectiveUri ? `/${objectiveUri}` : ""
    const pathname = !node.score
      ? `${COLLECT_PAGE}/${objective}`
      : DASHBOARD_PAGE

    const isCurrentScope = NodeOrganization.isNodeCurrentScope(
      node,
      currentNode
    )

    if (!isCurrentScope) await updateCurrentNode(node.id)

    redirect(pathname)
  }

  const tKey = node.score ? "view_score" : "view_collect"
  const { title, text } = t(`dashboard:chart.node_actions.${tKey}`, {
    returnObjects: true,
  })

  const props = {
    onAction,
    icon,
    title,
    text,
  }

  return <NodeActionMolecule {...props} />
}

SetCurrentScopeAction.propTypes = {
  node: PropTypes.shape({
    score: PropTypes.number,
    id: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
  }),
}

export default SetCurrentScopeAction
