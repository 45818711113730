import PropTypes from "prop-types"
import { Button, Icon, Tooltip } from "@shopify/polaris"
import { ReportsMajor } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"

const DownloadReportButtonReportAtom = ({ report }) => {
  const { t } = useTranslation()

  if (report.report_status === "done" && !!report?.pdf_url) {
    return (
      <Tooltip
        light
        dismissOnMouseOut
        content={t("share:content.reports_table.tooltips.download_report")}
      >
        <Button plain url={report.pdf_url}>
          <Icon source={ReportsMajor} />
        </Button>
      </Tooltip>
    )
  } else {
    return ""
  }
}

DownloadReportButtonReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default DownloadReportButtonReportAtom
