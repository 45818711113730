import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartBadge from "views/atoms/badges/ChartBadge"

const ChartSDG13AccessibilityErrorsMolecule = ({
  accessibilityErrors,
  lowerLimit,
  upperLimit,
  showAverage,
}) => {
  const { t } = useTranslation()

  const badge = (
    <ChartBadge
      lowerLimit={lowerLimit}
      upperLimit={upperLimit}
      inverse
      value={accessibilityErrors?.average}
    />
  )

  const content = {
    reference: badge,
    kpi: accessibilityErrors.total,
    kpi_text: t("analyze:websites.accessibility_errors.title"),
  }

  if (showAverage) {
    content.sub_kpi = accessibilityErrors.average
    content.sub_kpi_text = t("analyze:websites.accessibility_errors.average")
  }

  const content_for_display = <TextChartMinor content={content} />

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`analyze:websites.accessibility_errors.help`)}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSDG13AccessibilityErrorsMolecule.propTypes = {
  accessibilityErrors: PropTypes.object.isRequired,
  lowerLimit: PropTypes.number,
  upperLimit: PropTypes.number,
  showAverage: PropTypes.bool,
}

ChartSDG13AccessibilityErrorsMolecule.defaultProps = {
  lowerLimit: 3,
  upperLimit: 6,
  showAverage: false,
}

export default ChartSDG13AccessibilityErrorsMolecule
