import { Banner, Layout, Page } from "@shopify/polaris"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

const Message = ({ messageId, children, show }) => {
  const { t } = useTranslation()

  const bannerStatus = useCallback(() => {
    switch (messageId) {
      case "error":
        return "error"
      default:
        return "info"
    }
  }, [messageId])

  const bannerUrl = useCallback(() => {
    switch (messageId) {
      case "error":
        return "/contact"
      case "calculating":
        return "/score"
      case "initializing":
        return "/setup"
      case "no_node_children":
        return "/organize"
      default:
        return "/collect"
    }
  }, [messageId])

  const action = t(`dashboard:messages.${messageId}.cta`).includes("cta")
    ? null
    : {
        content: t(`dashboard:messages.${messageId}.cta`),
        url: bannerUrl(),
      }

  if (!show) {
    return <></>
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner
            status={bannerStatus()}
            title={t(`dashboard:messages.${messageId}.title`)}
            action={action}
          >
            {t(`dashboard:messages.${messageId}.message`)}
          </Banner>
        </Layout.Section>
        <Layout.Section>{children}</Layout.Section>
      </Layout>
    </Page>
  )
}

Message.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.any,
  show: PropTypes.bool,
}

Message.defaultProps = {
  show: true,
}

export default Message
