import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { mean } from "lodash"
import { useParams } from "react-router"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { useAppContext } from "components/AppContext"
import DoughnutChartAtom from "views/atoms/charts/doughnuts/DoughnutChartAtom"
import { round } from "services/units/round"

const Objective4Score = ({ score, collect }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()
  const { objectiveId } = useParams()
  const translationPrefix = `score:score.${objectiveId}.`

  const socialReference = score.social_references
  const company = score.root_node
  const socialCollect = collect
  const socialPeopleInventoryScore = score.social_people_inventory_score

  const chart1 = useCallback(() => {
    const employee_rate_kpi = score["odd4_education_green_employee_rate"]
    const employee_rate_reference = Math.round(
      socialReference["odd4_training_IT_CSR_employee_rate_global"]
    )

    const content = {
      kpi: employee_rate_kpi,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      reference: employee_rate_reference,
      reference_unit: t(translationPrefix + "chart1.unit"),
      reference_text: t(translationPrefix + "chart1.subtext"),
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score, socialReference])

  const chart2 = useCallback(() => {
    const training_hours_kpi = Math.round(
      mean([
        Number(collect["CSR_training_IT_average_time_per_employee"]),
        Number(collect["green_IT_training_IT_average_time_per_employee"]),
      ])
    )
    const training_hours_reference = Math.round(
      mean([
        parseFloat(
          socialReference[
            "odd4_training_IT_CSR_average_time_per_employee_global"
          ]
        ),
        parseFloat(
          socialReference[
            "odd4_training_IT_Green_IT_average_time_per_employee_IT"
          ]
        ),
      ])
    )

    const text = currentRootNode?.is_corporate
      ? t("score:score.4.chart2.corporate.text")
      : t("score:score.4.chart2.provider.text")

    const content = {
      kpi: training_hours_kpi,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: text,
      reference: training_hours_reference,
      reference_unit: t(translationPrefix + "chart2.unit"),
      reference_text: t(translationPrefix + "chart2.subtext"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score, socialReference])

  const chart3 = useCallback(() => {
    let data = [
      {
        label: t(`score:score.4.chart3.CSR`),
        data: socialCollect.CSR_training_IT_elearning_rate,
      },
      {
        label: t(`score:score.4.chart3.green_it`),
        data: socialCollect.green_IT_training_IT_elearning_rate,
      },
      {
        label: t(`score:score.4.chart3.new_tech`),
        data: socialCollect.new_tech_training_IT_elearning_rate,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart3.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart3.title")}
          data={data}
          axesLabel={t(translationPrefix + "chart3.y_label")}
          displayYAxe={true}
          doSortData={true}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference, company])

  const chart4 = useCallback(() => {
    const csr_employee_rate_reference =
      socialReference["odd4_training_IT_CSR_employee_rate_global"]
    const Green_IT_employee_rate_reference =
      socialReference["odd4_training_IT_Green_IT_employee_rate_IT"]
    const new_tech_employee_rate_reference =
      socialReference["odd4_training_IT_new_tech_employee_rate_IT"]

    const csr_employee_rate_input =
      collect["CSR_training_IT_employee_rate"] ??
      (socialPeopleInventoryScore
        ? socialPeopleInventoryScore["odd4_CSR_training_IT_employee_rate"]
        : null)
    const Green_IT_employee_rate_input =
      collect["green_IT_training_IT_employee_rate"] ??
      (socialPeopleInventoryScore
        ? socialPeopleInventoryScore["odd4_green_IT_training_IT_employee_rate"]
        : null)
    const new_tech_employee_rate_input =
      collect["new_tech_training_IT_employee_rate"] ??
      (socialPeopleInventoryScore
        ? socialPeopleInventoryScore["odd4_new_tech_training_IT_employee_rate"]
        : null)

    let data = [
      {
        label: t(`score:score.4.chart4.csr`),
        data: csr_employee_rate_input,
        reference: csr_employee_rate_reference,
      },
      {
        label: t(`score:score.4.chart4.green_it`),
        data: Green_IT_employee_rate_input,
        reference: Green_IT_employee_rate_reference,
      },
      {
        label: t(`score:score.4.chart4.new_techs`),
        data: new_tech_employee_rate_input,
        reference: new_tech_employee_rate_reference,
      },
    ]
    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart4.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.recommended")}
          axesLabel={t(translationPrefix + "chart4.y_label")}
          displayYAxe={true}
          displayLegend={true}
          doSortData={true}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference, socialPeopleInventoryScore])

  const chart5 = useCallback(() => {
    const budget_kpi = Math.round(
      Number(
        (score["odd4_education_green_budget"] /
          collect["training_IT_average_budget"]) *
          100
      )
    )

    const budget_reference = Math.round(
      Number(socialReference["odd4_training_IT_CSR_average_budget_global"]) +
        Number(socialReference["odd4_training_IT_Green_IT_average_budget_IT"])
    )

    const content = {
      kpi: budget_kpi,
      kpi_unit: t(translationPrefix + "chart5.unit"),
      kpi_text: t(translationPrefix + "chart5.text"),
      reference: budget_reference,
      reference_unit: t(translationPrefix + "chart5.unit"),
      reference_text: t(translationPrefix + "chart5.subtext"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score, socialReference])

  const chart6 = useCallback(() => {
    const budget_CSR_training_IT = {
      data: round(socialCollect["CSR_training_IT_average_budget"]),
      label: t(translationPrefix + `chart6.CSR_training_IT`),
    }
    const budget_new_tech_training_IT = {
      data: round(socialCollect["new_tech_training_IT_average_budget"]),
      label: t(translationPrefix + `chart6.new_tech_training_IT`),
    }
    const budget_green_IT_training_IT = {
      data: round(socialCollect["green_IT_training_IT_average_budget"]),
      label: t(translationPrefix + `chart6.green_IT_training_IT`),
    }
    const budget_other_training_IT = {
      data:
        round(socialCollect["training_IT_average_budget"]) -
        budget_CSR_training_IT.data -
        budget_new_tech_training_IT.data -
        budget_green_IT_training_IT.data,
      label: t(translationPrefix + `chart6.other`),
    }

    const data = [
      budget_CSR_training_IT,
      budget_new_tech_training_IT,
      budget_green_IT_training_IT,
      budget_other_training_IT,
    ]

    const dataset = data.map((item) => ({
      data: item.data,
      label: item.label,
      color: item.color,
    }))

    const doughnut_charts = dataset.map(({ data, label, color }) => ({
      data,
      label,
      color,
    }))

    const doughnut_charts_data_total = {
      number: round(socialCollect["training_IT_average_budget"]),
      unit: "k_euro_unit",
    }

    const data_doughnut = {
      doughnut_charts_data_total: doughnut_charts_data_total,
      doughnut_charts: doughnut_charts,
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart6.help")}
      >
        <DoughnutChartAtom
          title={t(translationPrefix + "chart6.title")}
          data={data_doughnut}
          unitType="money"
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [socialReference])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd4_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective4Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective4Score
