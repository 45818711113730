import { Icon, Navigation } from "@shopify/polaris"
import {
  AffiliateMajor,
  AnalyticsMajor,
  ArrowLeftMinor,
  ArrowRightMinor,
  BlogMajor,
  ColumnWithTextMajor,
  DataVisualizationMajor,
  DnsSettingsMajor,
  HintMajor,
  ReplayMinor,
  ReportsMajor,
  ShareMinor,
  ThemeEditMajor,
  TransactionMajor,
  WandMajor,
} from "@shopify/polaris-icons"
import {
  GHG_RECOMMENDATION_FEATURE,
  OVERVIEW_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import getITDomainUrl from "utils/getITDomainUrl"

const NavigationBar = ({ collapsedNavigation, toggleCollapsedNavigation }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const { ifFeatureActive } = useFeatureFlag()
  const {
    updateObjectiveNavigation,
    objectiveUri,
    disabledFeatures,
    envScore,
    currentRootNode,
    currentNode,
  } = useAppContext()

  const [currentPath, setCurrentPath] = useState("")
  useEffect(() => setCurrentPath(location.pathname), [location])

  const showWebsitesAnalysis = !!envScore?.websites_charts
  const disabledProviderImprovePage =
    currentRootNode?.is_provider && !showWebsitesAnalysis

  return (
    <Navigation location="/">
      <div>
        <button
          className="navigation-toggle"
          onClick={toggleCollapsedNavigation}
        >
          <Icon
            source={collapsedNavigation ? ArrowRightMinor : ArrowLeftMinor}
          />
        </button>
        <Navigation.Section
          items={[
            {
              label: t("common:menu.dashboard"),
              icon: DataVisualizationMajor,
              url: "/dashboard",
              selected: currentPath.startsWith("/dashboard"),
              disabled: disabledFeatures.score,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.organize"),
              icon: AffiliateMajor,
              url: "/organize",
              selected: currentPath.startsWith("/organize"),
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.collect"),
              icon: BlogMajor,
              url: `/collect/${objectiveUri}`,
              selected: currentPath.startsWith("/collect"),
              disabled: disabledFeatures.collect,
              subNavigationItems: ifFeatureActive(
                OVERVIEW_FEATURE,
                [
                  {
                    url: `/collect/overview/${objectiveUri}`,
                    selected: currentPath.startsWith("/collect/overview"),
                    label: t("common:menu.data_quality"),
                  },
                ],
                []
              ),
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.assess"),
              icon: DataVisualizationMajor,
              url: `/score/${objectiveUri}`,
              onClick: () =>
                currentPath.startsWith("/score") &&
                updateObjectiveNavigation(null),
              selected: currentPath.startsWith("/score"),
              disabled: disabledFeatures.score,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.analyze.title"),
              icon: AnalyticsMajor,
              url: getITDomainUrl(
                currentNode,
                `/analyze/it-domain/${objectiveUri}`
              ),
              selected: currentPath.startsWith("/analyze"),
              disabled:
                disabledFeatures.envScore && disabledFeatures.historization,
              subNavigationItems: [
                {
                  url: getITDomainUrl(
                    currentNode,
                    `/analyze/it-domain/${objectiveUri}`
                  ),
                  selected:
                    currentPath.startsWith("/analyze/it-domain") ||
                    currentPath.startsWith("/analyze/websites"),
                  label: t("common:menu.analyze.it_domain"),
                  disabled: disabledFeatures.envScore,
                  subNavigationItems:
                    showWebsitesAnalysis && !currentNode?.has_websites_only
                      ? [
                          {
                            label: t("common:menu.analyze.websites"),
                            url: `/analyze/websites`,
                            selected: currentPath.startsWith(
                              "/analyze/websites"
                            ),
                          },
                        ]
                      : [],
                },
                {
                  url:
                    "/analyze/ghg-scopes/environmental/13/ghg_protocol/synthesis",
                  selected: currentPath.startsWith("/analyze/ghg-scopes"),
                  label: t("common:menu.analyze.ghg"),
                  disabled: disabledFeatures.envScore,
                },
                {
                  url: `/analyze/history/${objectiveUri}`,
                  selected: currentPath.startsWith("/analyze/history"),
                  label: t("common:menu.analyze.historic"),
                  disabled: disabledFeatures.historization,
                },
              ],
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.compare"),
              icon: TransactionMajor,
              url: `/compare/environmental`,
              selected: currentPath.startsWith("/compare"),
              disabled: disabledFeatures.compare,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.improve.title"),
              icon: WandMajor,
              url: currentRootNode?.is_provider
                ? "/improve/websites"
                : `/improve/maturity/${objectiveUri}`,
              selected: currentPath.startsWith("/improve"),
              disabled: disabledFeatures.improve || disabledProviderImprovePage,
              subNavigationItems: ifFeatureActive(
                GHG_RECOMMENDATION_FEATURE,
                [
                  {
                    url: `/improve/maturity/${objectiveUri}`,
                    selected: currentPath.startsWith("/improve/maturity"),
                    disabled: disabledFeatures.improveMaturity,
                    label: t("common:menu.improve.maturity"),
                  },
                  {
                    url: `/improve/ghg/${objectiveUri}`,
                    selected: currentPath.startsWith("/improve/ghg"),
                    disabled: disabledFeatures.improveGHG,
                    label: t("common:menu.improve.ghg"),
                  },
                  showWebsitesAnalysis && {
                    url: `/improve/websites`,
                    selected: currentPath.startsWith("/improve/websites"),
                    disabled: disabledFeatures.improveWebsite,
                    label: t("common:menu.improve.website"),
                  },
                ].filter(Boolean),
                showWebsitesAnalysis
                  ? [
                      {
                        url: `/improve/maturity/${objectiveUri}`,
                        selected: currentPath.startsWith("/improve/maturity"),
                        disabled: disabledFeatures.improveMaturity,
                        label: t("common:menu.improve.maturity"),
                      },
                      showWebsitesAnalysis && {
                        url: `/improve/websites`,
                        selected: currentPath.startsWith("/improve/websites"),
                        disabled: disabledFeatures.improveWebsite,
                        label: t("common:menu.improve.website"),
                      },
                    ]
                  : []
              ),
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.share"),
              icon: ShareMinor,
              url: "/share",
              selected: currentPath.startsWith("/share"),
              disabled: disabledFeatures.envScore,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.replay"),
              icon: ReplayMinor,
              url: "/replay",
              selected: currentPath.startsWith("/replay"),
              disabled: disabledFeatures.replay,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.simulate"),
              icon: ThemeEditMajor,
              disabled: true,
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.pilot"),
              icon: ReportsMajor,
              disabled: true,
              selected: currentPath.startsWith("/pilot"),
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("common:menu.benchmark"),
              icon: ColumnWithTextMajor,
              disabled: true,
              selected: currentPath.startsWith("/benchmark"),
            },
          ]}
        />
      </div>
      <div className="Verdikt-Footer">
        <Navigation.Section
          title={t("common:menu.footer.title")}
          items={[
            {
              url: "/privacy",
              label: t("common:menu.footer.privacy"),
              icon: DnsSettingsMajor,
              selected: currentPath.startsWith("/privacy"),
            },
            {
              url: "/help",
              label: t("common:menu.footer.help"),
              icon: HintMajor,
              selected: currentPath.startsWith("/help"),
            },
          ]}
        />
      </div>
    </Navigation>
  )
}

NavigationBar.propTypes = {
  collapsedNavigation: PropTypes.bool.isRequired,
  toggleCollapsedNavigation: PropTypes.func.isRequired,
}

export default NavigationBar
