import useToggle from "hooks/useToggle"
import { useCallback, useEffect, useState } from "react"

export const STRUCTURED_CHOICE = "structured"
export const FLEX_CHOICE = "flex"

const useTemplateFileSelection = (structuredFilePath, flexFilePath) => {
  const [active, toggleActive] = useToggle(false)
  const [selectedChoice, setSelectedChoice] = useState(STRUCTURED_CHOICE)
  const [selectedFilePath, setSelectedFilePath] = useState(structuredFilePath)

  const handleChange = useCallback(toggleActive, [])

  const handleChoiceChange = useCallback(
    (value) => {
      setSelectedChoice(value)
      setSelectedFilePath(
        value === STRUCTURED_CHOICE ? structuredFilePath : flexFilePath
      )
    },
    [structuredFilePath, flexFilePath]
  )

  const handleDownload = useCallback(() => {
    handleChange()
    window.open(selectedFilePath, "_blank")
  }, [selectedFilePath])

  // use to handle the case where the user changes the language
  useEffect(() => {
    handleChoiceChange(selectedChoice)
  }, [structuredFilePath, flexFilePath])

  return {
    active,
    selectedChoice,
    handleChange,
    handleChoiceChange,
    handleDownload,
  }
}

export default useTemplateFileSelection
