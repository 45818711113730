import PropTypes from "prop-types"
import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import References from "utils/functions/references"

const ChartODD13ScopesBreakdownMolecule = ({
  emissions_breakdown_per_scope,
}) => {
  const { t } = useTranslation()

  const generateData = useCallback(() => {
    const dataBeforeTranslations = References.cloneDeep(
      emissions_breakdown_per_scope
    )

    if (!dataBeforeTranslations || !dataBeforeTranslations.length) {
      return []
    }

    for (const element of dataBeforeTranslations) {
      element.label = t(`analyze:ghg_scopes.scopes.${element.label}`)
    }

    return dataBeforeTranslations
  }, [emissions_breakdown_per_scope])

  return (
    <DoughnutChart
      title={t(`analyze:ghg_scopes.chartODD13ScopesBreakdownMolecule.title`)}
      centerText=""
      centerTextUnit={t(`units:units.charts.emission.t_unit`)}
      data={generateData()}
      large={false}
    />
  )
}

ChartODD13ScopesBreakdownMolecule.propTypes = {
  emissions_breakdown_per_scope: PropTypes.array,
}

export default ChartODD13ScopesBreakdownMolecule
