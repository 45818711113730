import React from "react"
import { Layout, Page } from "@shopify/polaris"
import PropTypes from "prop-types"

const TwoColumnsOneSectionTemplate = ({
  title,
  subtitle,
  description,
  children,
}) => {
  return (
    <Page title={title}>
      <Layout>
        <Layout.Section>
          <Layout.AnnotatedSection title={subtitle} description={description}>
            {children}
          </Layout.AnnotatedSection>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default TwoColumnsOneSectionTemplate

TwoColumnsOneSectionTemplate.propTypes = {
  children: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
