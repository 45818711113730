import StandardEntity from "./StandardEntity"

const baseUrl = "/api/websites"

const WebsitesEntity = (backClient) => {
  const standardEntity = StandardEntity(baseUrl)

  return {
    ...standardEntity(backClient),
    assess: async (data) =>
      await backClient.post("/api/env_collect/websites_computation", data),
  }
}

export default WebsitesEntity
