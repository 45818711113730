import React from "react"
import { Modal, TextContainer } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import "stylesheets/TemplateFileSelection.scss"
import classNames from "classnames"
import useTemplateFileSelection, {
  FLEX_CHOICE,
  STRUCTURED_CHOICE,
} from "hooks/upload/useTemplateFileSelection"
import i18next from "i18next"

const TemplateFileSelectionMolecule = ({
  fileId,
  structuredFilePath,
  flexFilePath,
}) => {
  const { t } = useTranslation()
  const {
    active,
    selectedChoice,
    handleChange,
    handleChoiceChange,
    handleDownload,
  } = useTemplateFileSelection(structuredFilePath, flexFilePath)

  const activator = (
    <button
      className="template-selection-button"
      onClick={handleChange}
      type="button"
    >
      {t("collect:questions.common.drop_zone.template")}
    </button>
  )

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={t("collect:questions.common.drop_zone.templates.title")}
      primaryAction={{
        content: t("collect:questions.common.drop_zone.templates.action"),
        onAction: handleDownload,
      }}
    >
      <Modal.Section>
        <TextContainer>
          <p>{t("collect:questions.common.drop_zone.templates.subtitle")}</p>

          <div className="template-file-selection__container">
            <div
              className={classNames(
                "template-file-selection__container__item",
                {
                  "template-file-selection__container__item--selected":
                    selectedChoice === STRUCTURED_CHOICE,
                }
              )}
              onClick={() => handleChoiceChange(STRUCTURED_CHOICE)}
            >
              <p>
                {t(
                  "collect:questions.common.drop_zone.templates.structured.choice"
                )}
              </p>
              <img
                src={`/images/templates/${fileId}.${i18next.language}.png`}
                alt={STRUCTURED_CHOICE}
              />
            </div>
            <div
              className={classNames(
                "template-file-selection__container__item",
                {
                  "template-file-selection__container__item--selected":
                    selectedChoice === FLEX_CHOICE,
                }
              )}
              onClick={() => handleChoiceChange(FLEX_CHOICE)}
            >
              <p>
                {t("collect:questions.common.drop_zone.templates.flex.choice")}
              </p>
              <img src="/images/templates/flex.en.png" alt={FLEX_CHOICE} />
            </div>
          </div>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

TemplateFileSelectionMolecule.propTypes = {
  fileId: PropTypes.string.isRequired,
  structuredFilePath: PropTypes.string.isRequired,
  flexFilePath: PropTypes.string.isRequired,
}

TemplateFileSelectionMolecule.defaultProps = {}

export default TemplateFileSelectionMolecule
