import Config from "assets/configs/score.js"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Bar } from "react-chartjs-2"
import "stylesheets/ScorePage/StackedChart.scss"
import { sortData } from "utils/functions/charts/ChartsFunctions"
import { useTranslation } from "react-i18next"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { PILLARS } from "utils/constants/pillars"
import classNames from "classnames"
import { COLORS } from "utils/constants/colors"
import { DEFAULT_OPTIONS } from "services/charts/default"

const StackedChart = ({
  title,
  labels,
  data,
  config,
  fab_data_to_be_displayed,
  use_data_to_be_displayed,
  fab_suffix,
  use_suffix,
  pillarId,
  colorCallback,
}) => {
  const { t } = useTranslation()

  const generateData = useCallback(() => {
    let datasets = []

    data = sortData(data)
    data.forEach((value, datasetIndex) => {
      const backgroundColors = value.data.map((data, index) =>
        colorCallback(index, value, datasetIndex)
      )
      const label_legend = [
        t("score:score.13.chartNetworkEquipementsGHGEmissions.use_label"),
        t(`score:score.13.chartNetworkEquipementsGHGEmissions.fab_label`),
      ]
      datasets.push({
        label: value.label,
        data: value.data,
        backgroundColor: backgroundColors,
        label_legend: label_legend,
      })
    })
    return {
      labels: labels,
      datasets: datasets,
    }
  }, [data])

  const generateOptions = useCallback(() => {
    let options = {
      ...DEFAULT_OPTIONS,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "%",
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            if (!value) return null
            data =
              ctx.dataset.label ===
              t(`score:score.common.lifecycle.manufacturing`)
                ? fab_data_to_be_displayed
                : use_data_to_be_displayed

            return (
              ctx.dataset.label + "\n" + data[ctx.dataIndex] + " " + fab_suffix
            )
          },
          textAlign: "center",
          display: true,
          color: COLORS.white,
          font: {
            weight: "bold",
          },
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label} : ${
              tooltipItem.yLabel
            } %`
          },
        },
      },
    }
    return _.merge(options, config)
  }, [
    data,
    config,
    fab_data_to_be_displayed,
    use_data_to_be_displayed,
    fab_suffix,
    use_suffix,
  ])

  return (
    <div className={classNames("Verdikt-Score-StackedChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar
          data={generateData()}
          plugins={[ChartDataLabels]}
          options={generateOptions()}
        />
      </div>
    </div>
  )
}

StackedChart.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  fab_data_to_be_displayed: PropTypes.arrayOf(PropTypes.string),
  use_data_to_be_displayed: PropTypes.arrayOf(PropTypes.string),
  fab_suffix: PropTypes.string,
  use_suffix: PropTypes.string,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
  colorCallback: PropTypes.func,
}

StackedChart.defaultProps = {
  config: {},
  colorCallback: (index) =>
    Config.common.colors[index % Config.common.colors.length] + "FF",
}

export default StackedChart
