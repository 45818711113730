import { ApiError } from "helpers/errors/ApiError"

const UsersEntity = (backClient) => ({
  delete: async (id) => await backClient.delete(`/api/user/${id}`),
  getCurrentRootNodeUser: async (id) =>
    await backClient.get(`/api/organization_members/${id}`),
  getCurrentRootNodeUsers: async () =>
    await backClient.get(`/api/organization_members`),
  getNodeUsers: async (nodeId) =>
    await backClient.get(`/api/node/${nodeId}/members`, {
      params: { id: nodeId },
    }),
  getNodeAvailableUsers: async (nodeId) =>
    await backClient.get(`/api/node/${nodeId}/available_members`, {
      params: { id: nodeId },
    }),
  addUserToNode: async (nodeId, userId, role) =>
    await backClient.post(`/api/node/${nodeId}/members`, {
      id: nodeId,
      user_id: userId,
      role: role,
    }),
  removeUserFromNode: async (nodeId, userId) =>
    await backClient.delete(`/api/node/${nodeId}/members/${userId}`, {
      params: {
        id: nodeId,
        user_id: userId,
      },
    }),
  availableProducts: async () =>
    await backClient.get(`/api/user/products/available`),

  createUserCompany: async (user) =>
    await backClient.post("/api/organization_members", user),

  updateUserCompany: async (user) => {
    try {
      return await backClient.put(`/api/organization_members/${user.id}`, user)
    } catch (err) {
      if (err.isAxiosError) {
        switch (err.response.data.code) {
          case 3000:
            throw new ApiError(err.response.status, "common:login.errors.3000")
          case 3002:
            throw new ApiError(err.response.status, "common:login.errors.3002")
          default:
            throw new ApiError(err.response.status, "common:login.errors.other")
        }
      }
    }
  },

  deleteUserCompany: async (user) => {
    try {
      return await backClient.delete(`/api/organization_members/${user.id}`)
    } catch (err) {
      if (err.isAxiosError) {
        switch (err.response.status) {
          case 404:
            throw new ApiError(
              404,
              "common:user_list.delete_modal.errors.not_found"
            )
          default:
            throw new ApiError(err.response.status, "common:login.errors.other")
        }
      }
    }
  },

  currentAdminUser: async () => {
    const response = await backClient.get("/api/current_admin_user")
    return response.data
  },
})

export const sortByLastNameAsc = (users) => {
  return users.sort((a, b) => {
    if (a.lastname && !b.lastname) {
      return -1
    }

    if (!a.lastname && b.lastname) {
      return 1
    }

    if (a.lastname < b.lastname) {
      return -1
    }

    if (a.lastname > b.lastname) {
      return 1
    }

    return 0
  })
}

export default UsersEntity
