import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const WorkInProgressBannerReportAtom = () => {
  const { t } = useTranslation()

  return (
    <Banner
      title={t("share:content.banners.work_in_progress.title")}
      status="success"
    >
      <p>{t("share:content.banners.work_in_progress.body")}</p>
    </Banner>
  )
}

export default WorkInProgressBannerReportAtom
