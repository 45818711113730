import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Banner, FormLayout, Modal } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import useToggle from "hooks/useToggle"
import Form from "components/widgets/CollectPage/forms/Form"
import StackedButtonMolecule from "views/molecules/activators/StackedButtonMolecule"
import useO365 from "hooks/useO365"
import TextFieldAtom from "views/atoms/organization/form/TextFieldAtom"

const CREDENTIAL_FIELDS = [
  {
    key: "tenant",
  },
  {
    key: "enrollment_id",
  },
  {
    key: "client_id",
  },
  {
    key: "client_secret",
    inputType: "password",
  },
  {
    key: "instance_id",
  },
  {
    key: "subscription_key",
    inputType: "password",
  },
]

const O365CredentialsModalOrganism = ({ collect, setCollect, locked }) => {
  const { t } = useTranslation()
  const {
    error,
    onSubmit,
    isLoading,
    credentials,
    setCredentials,
    credentialsResponse,
  } = useO365(collect?.o365_credentials)

  const [open, toggleOpen] = useToggle(false)

  const onChange = (value, key) =>
    setCredentials({ ...credentials, [key]: value })

  const activator = useCallback(
    () => (
      <StackedButtonMolecule
        toggleOpen={toggleOpen}
        locked={locked}
        edit={!!collect?.o365_credentials?.id}
        text={t(`collect:banners.office365.button`)}
      />
    ),
    [collect, locked]
  )

  useEffect(() => {
    if (credentialsResponse) {
      toggleOpen()
      setCollect({ ...collect, o365_credentials: credentialsResponse })
    }
  }, [credentialsResponse])

  return (
    <Modal
      activator={activator()}
      open={open}
      onClose={toggleOpen}
      title={t(`collect:banners.office365.form.title`)}
      primaryAction={{
        content: t("common:save"),
        onAction: onSubmit,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: toggleOpen,
        },
      ]}
    >
      <Modal.Section>
        {error && <Banner status="critical">{error.message}</Banner>}
        <Form>
          <FormLayout>
            {CREDENTIAL_FIELDS.map(({ key, inputType }, index) => (
              <TextFieldAtom
                value={credentials?.[key]}
                label={t(`collect:banners.office365.form.${key}`)}
                onChange={onChange}
                property={key}
                inputType={inputType}
                key={index}
                disabled={locked}
              />
            ))}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

O365CredentialsModalOrganism.propTypes = {
  collect: PropTypes.object.isRequired,
  setCollect: PropTypes.func.isRequired,
  locked: PropTypes.bool,
}

O365CredentialsModalOrganism.defaultProps = {
  locked: false,
}

export default O365CredentialsModalOrganism
