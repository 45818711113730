import React from "react"
import { Icon, Modal, Tooltip } from "@shopify/polaris"
import { ViewMajor } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import useToggle from "hooks/useToggle"
import { useEntity } from "hooks/useAxios"
import UserStateEntity from "services/entities/UserStateEntity"
import { useAppContext } from "components/AppContext"
import ScoreValue from "components/widgets/ScoreValue"
import { NODE_CATEGORY_PARTNER } from "utils/constants/organization/nodeTypes"
import {
  HIDE_PROVIDER_MATURITY_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"
import { round } from "services/units/round"

const OrganizationActions = ({ organization, hasAccess }) => {
  const { t } = useTranslation()

  const [active, toggleActive] = useToggle()
  const { reloadCurrentRoot } = useAppContext()
  const userStateEntity = useEntity(UserStateEntity)
  const { isFeatureActive } = useFeatureFlag()

  const changeRoot = async () => {
    await userStateEntity.setOrganization(organization.id)
    reloadCurrentRoot()
  }

  const scoreIcon = () => {
    if (isFeatureActive(HIDE_PROVIDER_MATURITY_FEATURE)) {
      return (
        <p className="co2e">
          <p>{round(organization.odd13_CO2.value)}</p>
          <p>{t(`units:units.emission.${organization.odd13_CO2.unit}`)}</p>
        </p>
      )
    }

    return (
      <ScoreValue
        score={organization.score}
        chart
        category={NODE_CATEGORY_PARTNER}
      />
    )
  }

  const activator = (
    <div className="nodeIcon" onClick={toggleActive}>
      {organization.score ? scoreIcon() : <Icon source={ViewMajor} />}
    </div>
  )

  const secondaryActions = [
    {
      content: t("common:cancel"),
      onAction: () => toggleActive(),
    },
    {
      content: t("common:confirm"),
      primary: true,
      onAction: () => changeRoot(),
    },
  ]

  return (
    <Tooltip
      light
      content={t(`dashboard:chart.organization_actions.view.title`)}
    >
      <Modal
        small
        activator={hasAccess && activator}
        open={active}
        onClose={toggleActive}
        title={t(`dashboard:chart.organization_actions.view.title`)}
        secondaryActions={secondaryActions}
      >
        <Modal.Section>
          {t(`dashboard:chart.organization_actions.view.text`)}
        </Modal.Section>
      </Modal>
    </Tooltip>
  )
}

OrganizationActions.propTypes = {
  organization: PropTypes.object.isRequired,
  hasAccess: PropTypes.bool,
}

OrganizationActions.defaultProps = {
  hasAccess: false,
}

export default OrganizationActions
