import React from "react"
import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import { useTranslation } from "react-i18next"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Select from "components/widgets/CollectPage/forms/fields/Select"
import Objective16TeamComposition, {
  teamConfigGenerator,
} from "components/widgets/CollectPage/forms/pillars/financial/TeamCompositionFields"
import { fieldIsFalsy } from "components/widgets/CollectPage/useDisablingFields"
import { POLICY_CYBER_SECURITY } from "utils/constants/centralization/policyItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const TEAM = "governance_cyber_security"
const teamConfig = teamConfigGenerator(TEAM)

const fields = [
  ...teamConfig.fields,
  "governance_cyber_security_budget",
  "governance_cyber_security_strategy_defined",
  "governance_cyber_security_maturity_frequency",
  "governance_cyber_security_maturity_means",
  "governance_cyber_security_audits_frequency",
  "governance_cyber_security_audits_means",
  "governance_cyber_security_label_cyber_vadis",
  "governance_cyber_security_label_iso_27001",
  "governance_cyber_security_label_other",
  "governance_cyber_security_cyber_score",
  "governance_cyber_security_kpi",
]

const translationPath = "16.cyber_security"
const tPrefix = `collect:questions.${translationPath}`

const FREQUENCIES = ["no", "once", "regularly"]
const MEANS = ["no", "internally", "free_tools", "market_tools", "consultants"]
const SECURITY_KPI = [
  "initial",
  "managed",
  "defined",
  "quantitatively_managed",
  "optimizing",
]

const isNullOrNo = (value) => fieldIsFalsy(value) || value === "no"
const disableConfig = [
  teamConfig.disablingConfig,
  {
    disablingField: "governance_cyber_security_maturity_frequency",
    condition: isNullOrNo,
    fieldsToDisable: ["governance_cyber_security_maturity_means"],
  },
  {
    disablingField: "governance_cyber_security_audits_frequency",
    condition: isNullOrNo,
    fieldsToDisable: ["governance_cyber_security_audits_means"],
  },
]

const CyberSecurityForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath, disableConfig })

  const frequencyOptions = FREQUENCIES.map((frequency) => ({
    value: frequency,
    label: t(`${tPrefix}.means.frequency.${frequency}`),
  }))
  const meansOptions = MEANS.map((frequency) => ({
    value: frequency,
    label: t(`${tPrefix}.means.means.${frequency}`),
  }))

  const securityKpiOptions = SECURITY_KPI.map((frequency) => ({
    value: frequency,
    label: t(`${tPrefix}.governance_cyber_security_kpi.kpi.${frequency}`),
  }))

  return (
    <Form>
      <FormLayout>
        <Module module={POLICY_CYBER_SECURITY}>
          <div className="columns">
            <div className="column">
              <Objective16TeamComposition prefix={TEAM} />
              <FormLayout>
                <TextField
                  id="governance_cyber_security_budget"
                  type="number"
                />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="governance_cyber_security_strategy_defined" />

                <ExternalLabel translationPathName="means" />
                <table className="increased-padding">
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.means.maturity`)}</td>
                      <td>{t(`${tPrefix}.means.audits`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.means.frequency.label`)}</td>
                      <td>
                        <Select
                          id="governance_cyber_security_maturity_frequency"
                          options={frequencyOptions}
                        />
                      </td>
                      <td>
                        <Select
                          id="governance_cyber_security_audits_frequency"
                          options={frequencyOptions}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.means.means.label`)}</td>
                      <td>
                        <Select
                          id="governance_cyber_security_maturity_means"
                          options={meansOptions}
                        />
                      </td>
                      <td>
                        <Select
                          id="governance_cyber_security_audits_means"
                          options={meansOptions}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Select
                  id="governance_cyber_security_kpi"
                  options={securityKpiOptions}
                />

                <MultipleChoiceField
                  id="labels"
                  choices={[
                    "governance_cyber_security_label_cyber_vadis",
                    "governance_cyber_security_label_iso_27001",
                    "governance_cyber_security_label_other",
                  ]}
                />
                <Select
                  id="governance_cyber_security_cyber_score"
                  options={[
                    "not_applicable",
                    "not_assessed",
                    "a",
                    "b",
                    "c",
                    "d",
                    "e",
                  ]}
                />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default CyberSecurityForm
