import React from "react"
import { Spinner } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const LoadingSpinnerButtonReportMolecule = () => {
  const { t } = useTranslation()

  return (
    <tr>
      <td className="spinner-container" colSpan="8">
        <Spinner
          accessibilityLabel={t("share:content.reports_table.loading_reports")}
        />
      </td>
    </tr>
  )
}

export default LoadingSpinnerButtonReportMolecule
