import React from "react"
import { Card, Checkbox, FormLayout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ReassessmentDuplicateFormMolecule = ({
  onChange,
  formData,
  disabled,
  hideOrganization,
  hideSocial,
  hideProfit,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {!hideOrganization && (
        <Card.Section title={t("organization:reassessment.form.titles.global")}>
          <FormLayout.Group>
            <Checkbox
              label={t("organization:reassessment.form.fields.organization")}
              id="organization"
              name="organization"
              checked={formData.organization}
              onChange={onChange}
              disabled={disabled}
            />
          </FormLayout.Group>
        </Card.Section>
      )}

      <Card.Section title={t("organization:reassessment.form.titles.planet")}>
        <FormLayout.Group>
          <Checkbox
            label={t(
              "organization:reassessment.form.fields.collect_environmental.politics"
            )}
            id="collectEnvironmentalPolitics"
            name="collectEnvironmentalPolitics"
            disabled={!formData.organization || disabled}
            checked={formData.collectEnvironmentalPolitics}
            onChange={onChange}
          />

          <Checkbox
            label={t(
              "organization:reassessment.form.fields.collect_environmental.inventories"
            )}
            id="collectEnvironmentalInventory"
            name="collectEnvironmentalInventory"
            disabled={!formData.organization || disabled}
            checked={formData.collectEnvironmentalInventory}
            onChange={onChange}
          />
        </FormLayout.Group>
      </Card.Section>

      {!hideSocial && (
        <Card.Section title={t("organization:reassessment.form.titles.social")}>
          <FormLayout.Group>
            <Checkbox
              label={t(
                "organization:reassessment.form.fields.collect_social.politics"
              )}
              id="collectSocialPolitics"
              name="collectSocialPolitics"
              disabled={!formData.organization || disabled}
              checked={formData.collectSocialPolitics}
              onChange={onChange}
            />

            <Checkbox
              label={t(
                "organization:reassessment.form.fields.collect_social.inventories"
              )}
              id="collectSocialInventory"
              name="collectSocialInventory"
              disabled={!formData.organization || disabled}
              checked={formData.collectSocialInventory}
              onChange={onChange}
            />
          </FormLayout.Group>
        </Card.Section>
      )}

      {!hideProfit && (
        <Card.Section title={t("organization:reassessment.form.titles.profit")}>
          <FormLayout.Group>
            <Checkbox
              label={t(
                "organization:reassessment.form.fields.collect_profit.politics"
              )}
              id="collectProfitPolitics"
              name="collectProfitPolitics"
              disabled={!formData.organization || disabled}
              checked={formData.collectProfitPolitics}
              onChange={onChange}
            />

            <Checkbox
              label={t(
                "organization:reassessment.form.fields.collect_profit.inventories"
              )}
              id="collectProfitInventory"
              name="collectProfitInventory"
              disabled={!formData.organization || disabled}
              checked={formData.collectProfitInventory}
              onChange={onChange}
            />
          </FormLayout.Group>
        </Card.Section>
      )}
    </>
  )
}

ReassessmentDuplicateFormMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    organization: PropTypes.bool.isRequired,
    collectEnvironmentalPolitics: PropTypes.bool.isRequired,
    collectEnvironmentalInventory: PropTypes.bool.isRequired,
    collectSocialPolitics: PropTypes.bool.isRequired,
    collectSocialInventory: PropTypes.bool.isRequired,
    collectProfitPolitics: PropTypes.bool.isRequired,
    collectProfitInventory: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  hideOrganization: PropTypes.bool,
  hideSocial: PropTypes.bool,
  hideProfit: PropTypes.bool,
}

ReassessmentDuplicateFormMolecule.defaultProps = {
  disabled: false,
  hideOrganization: false,
  hideSocial: false,
  hideProfit: false,
}

export default ReassessmentDuplicateFormMolecule
