import React from "react"
import { Icon } from "@shopify/polaris"
import "stylesheets/blocs/molecules/collectStartedIconMolecule/collectStartedIconMolecule.scss"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useAppContext } from "components/AppContext"
import { useHistory } from "react-router-dom"

const IconChartAtom = ({ icon, suffix, disabled, url, nodeId }) => {
  const history = useHistory()
  const {
    updateCurrentNode,
    reloadEnvCollect,
    reloadSocialCollect,
    profitCollectRequest,
  } = useAppContext()
  const handleClick = async () => {
    await updateCurrentNode(nodeId)
    await Promise.all([
      reloadEnvCollect(),
      reloadSocialCollect(),
      profitCollectRequest.reload(),
    ])

    history.push(url)
  }

  return (
    <div
      onClick={!disabled && handleClick}
      className={classNames("collect-icon--" + suffix, {
        ["collect-icon--disabled"]: disabled,
      })}
    >
      <div className="collect-icon--size">
        <Icon source={icon} />
      </div>
    </div>
  )
}
IconChartAtom.propTypes = {
  icon: PropTypes.func.isRequired,
  suffix: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  url: PropTypes.string,
  nodeId: PropTypes.number,
}

IconChartAtom.defaultProps = {
  disabled: false,
}

export default IconChartAtom
