import { useAppContext } from "components/AppContext"
import { ADMIN_ROLE, VIEWER_ROLE } from "utils/constants/userRoles"

const useAdminRules = () => {
  const { currentUser, currentRootNode, currentNode } = useAppContext()

  const isUserAdmin = !!currentUser?.has_user_management
  const isVerdiktAccount = !!currentUser?.is_verdikt_account
  const isRootNodeAdmin = currentRootNode?.role === ADMIN_ROLE
  const isCurrentNodeAdmin = currentNode?.role === ADMIN_ROLE
  const isCurrentNodeViewerOnly = currentNode?.role === VIEWER_ROLE
  const isUserOverallAdmin = currentUser?.role_override === ADMIN_ROLE

  return {
    isUserAdmin,
    isVerdiktAccount,
    isRootNodeAdmin,
    isCurrentNodeAdmin,
    isCurrentNodeViewerOnly,
    isUserOverallAdmin,
  }
}

export default useAdminRules
