import { Banner, Layout } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import "stylesheets/CollectPage.scss"
import "stylesheets/ObjectiveTabs.scss"

const ScoreLoaderTemplate = ({ pillarScore, children }) => {
  const { t } = useTranslation()

  const showScore = !!pillarScore

  return (
    <>
      {showScore ? (
        children
      ) : (
        <Layout.Section>
          <Banner
            title={t("analyze:no_score.title")}
            action={{
              content: t("analyze:no_score.cta"),
              url: "/collect",
            }}
          >
            {t(`analyze:no_score.content`)}
          </Banner>
        </Layout.Section>
      )}
    </>
  )
}

ScoreLoaderTemplate.propTypes = {
  pillarScore: PropTypes.object.isRequired,
  children: PropTypes.element,
}

ScoreLoaderTemplate.defaultProps = {}

export default ScoreLoaderTemplate
