import React from "react"
import useAuth0 from "components/auth/useAuth0"
import TopBarUnauthenticatedUserMenu from "components/widgets/TopBar/TopBarUnauthenticatedUserMenu"
import TopBarAuthenticatedUserMenu from "components/widgets/TopBar/TopBarAuthenticatedUserMenu"

const TopBarUserMenu = () => {
  const { isAuthenticated } = useAuth0()

  return !isAuthenticated ? (
    <TopBarUnauthenticatedUserMenu />
  ) : (
    <TopBarAuthenticatedUserMenu />
  )
}

export default TopBarUserMenu
