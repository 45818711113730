import React from "react"
import { MobileAcceptMajor, MobileCancelMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"

const IconTableSubscriptionAtom = ({ valid }) => {
  if (valid === null) {
    return ""
  }

  return valid ? (
    <MobileAcceptMajor width={25} className="check" />
  ) : (
    <MobileCancelMajor width={25} className="cross" />
  )
}

IconTableSubscriptionAtom.propTypes = {
  valid: PropTypes.bool.isRequired,
}

export default IconTableSubscriptionAtom
