import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import StackedChart from "components/widgets/ScorePage/widgets/StackedChart"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { EMISSION_UNIT, KG_UNIT, T_UNIT } from "utils/constants/numbers"
import useDomainBreakdownChart from "views/molecules/canvases/charts/useDomainBreakdownChart"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { CO2_EMISSION_UNITS } from "services/units/units"

const STRUCTURE = [
  {
    label: "workplace",
    fab: "odd13_workplace_CO2_fab_real",
    use: "odd13_workplace_CO2_use",
  },
  {
    label: "datacenter",
    fab: "odd13_datacenter_CO2_fab_real",
    use: "odd13_datacenter_CO2_use",
  },
  {
    label: "network",
    fab: "odd13_network_CO2_fab_real",
    use: "odd13_network_CO2_use",
  },
  {
    label: "business",
    fab: "odd13_business_CO2_fab_real",
    use: "odd13_business_CO2_use",
  },
]

const translationPrefix = "score:score.13.chartEquipementsGHGEmissions"

const ChartODD13EquipmentsGHGEmissionsMolecule = ({ score }) => {
  const { t } = useTranslation()

  const options = {
    baseUnit: T_UNIT,
    lowerUnit: KG_UNIT,
    unitType: EMISSION_UNIT,
    units: CO2_EMISSION_UNITS,
    manufacturingLabel: t(`${translationPrefix}.manufacturing`),
    useLabel: t(`${translationPrefix}.use`),
  }

  const props = useDomainBreakdownChart(STRUCTURE, score, options)

  const labels = props.labelKeys.map((labelKey) =>
    t(`${translationPrefix}.${labelKey}`)
  )

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${translationPrefix}.help`)}>
      <StackedChart
        labels={labels}
        title={t(`${translationPrefix}.title`)}
        {...props}
        pillarId={PILLAR_ENVIRONMENTAL}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13EquipmentsGHGEmissionsMolecule.propTypes = {
  score: PropTypes.shape({
    odd13_workplace_CO2_fab_real: PropTypes.string,
    odd13_workplace_CO2_use: PropTypes.string,
    odd13_datacenter_CO2_fab_real: PropTypes.string,
    odd13_datacenter_CO2_use: PropTypes.string,
    odd13_network_CO2_fab_real: PropTypes.string,
    odd13_network_CO2_use: PropTypes.string,
  }),
}

export default ChartODD13EquipmentsGHGEmissionsMolecule
