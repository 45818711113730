import HistorizationConsolidateAnalyzeChartsOrganism from "views/organisms/canvases/charts/analyzes/historization/HistorizationConsolidateAnalyzeChartsOrganism"
import HistorizationAnalyzeChartsOrganism from "views/organisms/canvases/charts/analyzes/historization/HistorizationAnalyzeChartsOrganism"
import React from "react"
import { useAppContext } from "components/AppContext"
import AnalyzeHistoryTemplate from "views/templates/AnalyzeHistoryTemplate"
import PropTypes from "prop-types"

const EnvHistoryAnalyzeOrganism = ({ data, pillars }) => {
  const { currentPeriod, parentOrganization } = useAppContext()

  return (
    <AnalyzeHistoryTemplate
      periodName={currentPeriod?.name}
      parentOrganization={parentOrganization}
      pillars={pillars}
    >
      {data.consolidate ? (
        <HistorizationConsolidateAnalyzeChartsOrganism
          score={data.score}
          maturityEvolutionChartData={data.chart_maturity_evolution}
          ghgEvolutionChartData={data.chart_ghg_evolution}
          ghgBreakdownEvolutionChartData={data.chart_ghg_breakdown_evolution}
          maturityDeltaKpi={data.kpi_maturity_delta}
          co2DeltaKpi={data.kpi_co2_delta}
          highestMaturityKpi={data.kpi_highest_maturity_delta}
        />
      ) : (
        <HistorizationAnalyzeChartsOrganism
          maturity={data.score}
          maturityIndexesesChartData={data.chart_maturity_indexes}
          ghgEvolutionChartData={data.chart_ghg_evolution}
          ghgBreakdownEvolutionChartData={data.chart_ghg_breakdown_evolution}
          kpiMaturityEvolution={data.kpi_maturity_evolution}
          kpiBiggestGhgEvolution={data.kpi_biggest_ghg_evolution}
          kpiBiggestThemeMaturityEvolution={
            data.kpi_biggest_theme_maturity_evolution
          }
        />
      )}
    </AnalyzeHistoryTemplate>
  )
}

EnvHistoryAnalyzeOrganism.propTypes = {
  data: PropTypes.object.isRequired,
  pillars: PropTypes.array.isRequired,
}

export default EnvHistoryAnalyzeOrganism
