import Config from "assets/configs/score.js"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import BarChart from "../widgets/BarChart"
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"

const CloudProviderChart = ({ clouds }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.7."

  const collectPresentDCs = new Set(clouds.map(({ provider }) => provider))

  const config = Config["7"]["chart3"]
  const data = config.data_order.map((cloudProvider, index) => ({
    label: cloudProvider,
    data: config.data_match[cloudProvider],
    color: collectPresentDCs.has(cloudProvider.toLowerCase())
      ? COLORS.planet.primary
      : config.colors_cloud[index % config.colors_cloud.length],
  }))

  const defaultColor = config.colors_cloud[0]

  const chartConfig = {
    legend: {
      display: true,
      labels: {
        generateLabels: (chart) => {
          const dataset = chart?.config?.data?.datasets?.[0]
          const hidden = !!Object.values(dataset?._meta)?.[0]?.hidden
          return [
            {
              datasetIndex: 0,
              text: t(translationPrefix + "chart3.data_title"),
              fillStyle: defaultColor,
              strokeStyle: defaultColor,
              hidden,
            },
          ]
        },
      },
    },
  }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(translationPrefix + "chart3.help")}
    >
      <BarChart
        title={t(translationPrefix + "chart3.title")}
        data={data}
        config={chartConfig}
        dataTitle={t(translationPrefix + "chart3.data_title")}
        displayYAxe
        axesLabel="%"
        displayLegend
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

CloudProviderChart.propTypes = {
  clouds: PropTypes.array.isRequired,
}

export default CloudProviderChart
