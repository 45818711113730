import React, { useEffect, useState } from "react"
import "stylesheets/ImprovePage/Recommendations.scss"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Recommendation from "components/widgets/ImprovePage/Recommendation"
import RecommendationDetails from "views/organisms/recommendations/RecommendationDetails"

const Recommendations = ({
  pillar,
  objective,
  theme,
  recommendations,
  keysToComponentKeys,
  canSelectRecommendationDetails,
  recommendationDescriptionGetter,
  sdgGetter,
}) => {
  const { t } = useTranslation()
  const [selectedRecommendation, setSelectedRecommendation] = useState(null)

  useEffect(() => setSelectedRecommendation(null), [recommendations])

  /**
   * @description This function iterates through an object containing recommendations and generates a table row per recommendation
   * @returns A table row containing a recommendation and its impact and complexity represented in graphs
   */
  const displayRecommendations = () => {
    if (selectedRecommendation) {
      return (
        <Recommendation
          impact={selectedRecommendation[keysToComponentKeys.impact]}
          complexity={selectedRecommendation[keysToComponentKeys.complexity]}
          pillar={pillar}
          domain={selectedRecommendation[keysToComponentKeys.domain]}
          recoKey={selectedRecommendation[keysToComponentKeys.key]}
          theme={theme}
          objective={objective}
          description={recommendationDescriptionGetter(selectedRecommendation)}
          sdg={sdgGetter(selectedRecommendation)}
        />
      )
    }

    if (recommendations) {
      return recommendations.map((recommendation, i) => {
        return (
          <Recommendation
            key={i}
            impact={recommendation[keysToComponentKeys.impact]}
            complexity={recommendation[keysToComponentKeys.complexity]}
            pillar={pillar}
            domain={recommendation[keysToComponentKeys.domain]}
            recoKey={recommendation[keysToComponentKeys.key]}
            theme={theme}
            onSelectRecommendation={
              canSelectRecommendationDetails
                ? () => setSelectedRecommendation(recommendation)
                : null
            }
            objective={objective}
            description={recommendationDescriptionGetter(recommendation)}
            sdg={sdgGetter(recommendation)}
          />
        )
      })
    }
  }

  return (
    <div className={`recommendations recommendations--${pillar}`}>
      <div className="recommendations__tableContainer">
        <table className="recommendations__table">
          <thead>
            <tr>
              <th className="recommendations__thText" scope="col">
                {t(`improve:recommendations_table.header.impact`)}
              </th>
              <th className="recommendations__thText" scope="col">
                {t(`improve:recommendations_table.header.complexity`)}
              </th>
              <th className="recommendations__thText" scope="col">
                {t(`improve:recommendations_table.header.recommendation`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {displayRecommendations()}
            {canSelectRecommendationDetails && selectedRecommendation && (
              <RecommendationDetails
                proposition={selectedRecommendation}
                onClose={() => setSelectedRecommendation(null)}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Recommendations.propTypes = {
  objective: PropTypes.string,
  pillar: PropTypes.string.isRequired,
  theme: PropTypes.string,
  recommendations: PropTypes.array.isRequired,
  keysToComponentKeys: PropTypes.shape({
    impact: PropTypes.string,
    complexity: PropTypes.string,
    domain: PropTypes.string,
    key: PropTypes.string,
  }),
  canSelectRecommendationDetails: PropTypes.bool,
  recommendationDescriptionGetter: PropTypes.func.isRequired,
  sdgGetter: PropTypes.func.isRequired,
}

Recommendations.defaultProps = {
  keysToComponentKeys: {
    impact: "recommendationImpact",
    complexity: "recommendationComplexity",
    domain: "recommendationDomain",
    key: "recommendationKey",
  },
  canSelectRecommendationDetails: false,
}

export default Recommendations
