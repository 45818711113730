import React from "react"
import { convertValueToReadable } from "services/units/valueConversion"
import classNames from "classnames"
import ConditionalLink from "components/widgets/ConditionalLink"
import SdgIcon from "assets/images/sdg"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const Cell = ({ pillarId, score, cell, isDisabled, quantitative }) => {
  const {
    sdg,
    maturity_field,
    quantitative_field,
    unit,
    units,
    quantitative_text,
    take_more_space,
    unitType,
  } = cell
  const { t } = useTranslation()
  const sdgScore = score ? score[maturity_field] ?? 0 : 0
  const quantitativeValue = score ? score[quantitative_field] ?? 0 : 0

  const quantitativeFieldConverted = convertValueToReadable(
    quantitativeValue,
    unit,
    units
  )

  let unitText = unit

  if (unitType) {
    unitText = t(`units:units.${unitType}.${quantitativeFieldConverted.unit}`)
  }

  return (
    <li
      key={sdg}
      className={classNames({
        colored: true,
        background: true,
        disabled: isDisabled,
      })}
    >
      <ConditionalLink to={`/score/${pillarId}/${sdg}`} condition={!isDisabled}>
        <div className="progress progress--full colored" />
        <div
          className="progress colored"
          style={{ height: `${sdgScore}%` }}
        ></div>
        <SdgIcon id={sdg} quantitative={quantitative && !isDisabled} />
        {quantitative && !isDisabled && (
          <div
            className={classNames("quantitative", {
              xl: take_more_space,
            })}
          >
            <p>
              {`${Math.round(quantitativeFieldConverted.value)}${
                unitText ? ` ${unitText}` : ""
              }`}
            </p>
            {quantitative_text && (
              <p>{t(`score:score.${sdg}.quantitative_text`)}</p>
            )}
          </div>
        )}
      </ConditionalLink>
    </li>
  )
}

Cell.propTypes = {
  pillarId: PropTypes.string.isRequired,
  score: PropTypes.object.isRequired,
  cell: PropTypes.shape({
    sdg: PropTypes.string.isRequired,
    maturity_field: PropTypes.string.isRequired,
    quantitative_field: PropTypes.string.isRequired,
    unit: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.string),
    quantitative_text: PropTypes.bool,
    take_more_space: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool,
  quantitative: PropTypes.bool,
}

Cell.defaultProps = {
  isDisabled: false,
  quantitative: false,
}

export default Cell
