import React from "react"
import PropTypes from "prop-types"
import "stylesheets/blocs/organisms/organizationCharts/BreadcrumbOrganizationChartOrganism.scss"

const BreadcrumbMolecule = ({ items, onClick }) => {
  return (
    <article
      className="organizationPath organizationPath__content"
      onClick={onClick}
    >
      <p>
        {items.map((item, index) => (
          <span key={index}>
            {!index === 0 && <span> &gt; </span>}
            {item}
          </span>
        ))}
      </p>
    </article>
  )
}

BreadcrumbMolecule.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
}

BreadcrumbMolecule.defaultProps = {
  onClick: () => {},
}

export default BreadcrumbMolecule
