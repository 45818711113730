import { Banner, Layout, Page } from "@shopify/polaris"
import PropTypes from "prop-types"
import React from "react"

const NoSubscriptionBanner = ({ title, description, status }) => {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner status={status} title={title}>
            {description}
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

NoSubscriptionBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
}

export default NoSubscriptionBanner
