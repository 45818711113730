import React from "react"
import PropTypes from "prop-types"
import UpdatedAtReportAtom from "views/atoms/reports/table/fields/UpdatedAtReportAtom"
import TranslateReportStatusReportAtom from "views/atoms/reports/table/fields/TranslateReportStatusReportAtom"
import TranslateReportTypeReportAtom from "views/atoms/reports/table/fields/TranslateReportTypeReportAtom"
import TranslateLanguageReportAtom from "views/atoms/reports/table/fields/TranslateLanguageReportAtom"
import TranslatePillarReportAtom from "views/atoms/reports/table/fields/TranslatePillarReportAtom"
import DownloadReportButtonReportAtom from "views/atoms/reports/table/buttons/DownloadReportButtonReportAtom"
import RegenerateReportButtonReportAtom from "views/atoms/reports/table/buttons/RegenerateReportButtonReportAtom"

const RowTableReportMolecule = ({ report }) => {
  return (
    <tr>
      <td>
        <UpdatedAtReportAtom report={report} />
      </td>
      <td>
        <TranslateReportStatusReportAtom report={report} />
      </td>
      <td>
        <TranslateReportTypeReportAtom report={report} />
      </td>
      <td>
        <TranslateLanguageReportAtom report={report} />
      </td>
      <td>
        <TranslatePillarReportAtom pillar={report?.environmental_pillar} />
      </td>
      <td>
        <TranslatePillarReportAtom pillar={report?.social_pillar} />
      </td>
      <td>
        <TranslatePillarReportAtom pillar={report?.profit_pillar} />
      </td>
      <td>
        <DownloadReportButtonReportAtom report={report} />
      </td>
      <td>
        <RegenerateReportButtonReportAtom report={report} />
      </td>
    </tr>
  )
}

RowTableReportMolecule.propTypes = {
  report: PropTypes.object.isRequired,
}

export default RowTableReportMolecule
