const CONFIG_PRODUCTION = "production"
const CONFIG_UAT = "uat"
const CONFIG_DEVELOPMENT = "development"

const HOSTNAME_TO_CONFIG = {
  localhost: CONFIG_DEVELOPMENT,
  "verdikt-web-subdev.herokuapp.com": CONFIG_UAT,
  "verdikt-web-dev.herokuapp.com": CONFIG_UAT,
  "verdikt-web-test.herokuapp.com": CONFIG_UAT,
  "verdikt-web-demo.herokuapp.com": CONFIG_UAT,
  "app.markup.io": CONFIG_DEVELOPMENT,
  "verdikt-web-preprod.herokuapp.com": CONFIG_PRODUCTION,
  "verdikt-web-production.herokuapp.com": CONFIG_PRODUCTION,
  "app.verdikt.io": CONFIG_PRODUCTION,
}

export const hostname = window.location?.hostname ?? CONFIG_DEVELOPMENT
export const configKey = HOSTNAME_TO_CONFIG[hostname] ?? CONFIG_PRODUCTION
