import NodeActionWithConfirmMolecule from "components/widgets/Dashboard/OrganizationChart/NodeActionWithConfirmMolecule"
import useToggle from "hooks/useToggle"
import { useEntity } from "hooks/useAxios"
import ConsolidationEntity from "services/entities/ConsolidationEntity"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import { useAppContext } from "components/AppContext"
import PropTypes from "prop-types"
import { BehaviorMajor } from "@shopify/polaris-icons"
import { Icon } from "@shopify/polaris"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DASHBOARD_PAGE } from "hooks/useRedirection"
import {
  TOAST_STATUS_ALL_CHILDREN_NOT_KEPT,
  TOAST_STATUS_VERSION_CHILDREN_KEPT,
} from "utils/constants/toastStatuses"

const ConsolidateAction = ({ node }) => {
  const { t } = useTranslation()
  const consolidationEntity = useEntity(ConsolidationEntity)
  const { setToastStatus } = useChart()
  const { updateCurrentNode, reloadScore, redirect } = useAppContext()
  const [requiresConfirm, toggleRequiresConfirm] = useToggle(false)
  const [translationKey, setTranslationKey] = useState("consolidate")
  const [
    modalConsolidationAuthorized,
    setModalConsolidationAuthorized,
  ] = useState(true)

  const checkBeforeConsolidation = async () => {
    try {
      const availability = await consolidationEntity.isAvailable(node.id)

      if (availability.mandatory_kept_children) {
        setToastStatus(TOAST_STATUS_ALL_CHILDREN_NOT_KEPT)
        return
      }

      if (availability.several_version_of_a_children_are_kept) {
        setToastStatus(TOAST_STATUS_VERSION_CHILDREN_KEPT)
        return
      }

      if (
        availability.are_employees_fewer ||
        availability.are_it_employees_fewer
      ) {
        setTranslationKey("consolidate_with_more_employee_than_parent")
        setModalConsolidationAuthorized(false)
        toggleRequiresConfirm()
        return
      }

      if (
        availability.are_employees_more ||
        availability.are_it_employees_more
      ) {
        setTranslationKey("consolidate_with_employee_difference")
        setModalConsolidationAuthorized(true)
        toggleRequiresConfirm()
        return
      }
    } catch (error) {
      setToastStatus(6)
    }

    await launchConsolidation()
  }

  const launchConsolidation = async () => {
    try {
      await consolidationEntity.putConsolidation(node.id)

      setToastStatus(3)

      redirect(DASHBOARD_PAGE)
      await reloadScore()
      await updateCurrentNode(node.id)
    } catch (error) {
      if (error.response.data.toast_status) {
        setToastStatus(error.response.data.toast_status)
        return
      }

      if (error.response.status === 403) {
        setToastStatus(4)
      } else if (error.response.status === 406) {
        setToastStatus(5)
      } else {
        setToastStatus(6)
      }
    }
  }

  const icon = <Icon source={BehaviorMajor} />

  const { title, text } = t(`dashboard:chart.node_actions.${translationKey}`, {
    returnObjects: true,
  })

  const commonProps = { title, text, icon }

  return requiresConfirm ? (
    <NodeActionWithConfirmMolecule
      onAction={modalConsolidationAuthorized ? launchConsolidation : null}
      active
      {...commonProps}
    />
  ) : (
    <NodeActionWithConfirmMolecule
      onAction={checkBeforeConsolidation}
      {...commonProps}
    />
  )
}

ConsolidateAction.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mandatory_kept_children: PropTypes.bool.isRequired,
    several_version_of_a_children_are_kept: PropTypes.bool.isRequired,
  }),
}

export default ConsolidateAction
