import React from "react"
import { FormLayout } from "@shopify/polaris"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import Form from "components/widgets/CollectPage/forms/Form"
import { MultipleChoiceField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import YesNoRadio from "components/widgets/CollectPage/forms/fields/YesNoRadio"
import { isInvertedChoice } from "components/widgets/CollectPage/useDisablingFields"
import { Module } from "components/widgets/CollectPage/forms/Module"
import { INVENTORY_PROJECTS } from "utils/constants/centralization/inventoryItems"

const fields = [
  "eco_design_practices_eco_conception",
  "eco_design_practices_sobriety",
  "eco_design_practices_inclusion",
  "eco_design_practices_functional_coverage",
  "eco_design_physical_requirements_evaluation",
  "eco_design_development_simulation",
  "eco_design_development_tool_measure",
  "eco_design_development_tool_code_analysis",
  "eco_design_development_tool_good_practice_analysis",
  "eco_design_development_code_review",
  "eco_design_decommissioning",
  "eco_design_evaluation_a_posteriori",
  "eco_design_standards_rgesn",
  "eco_design_standards_rgaa",
  "eco_design_standards_afnor_spec_2201",
  "eco_design_standards_afnor_spec_other",
  "eco_design_ia_footprint_none",
  "eco_design_ia_footprint_internal",
  "eco_design_ia_footprint_free_tools",
  "eco_design_ia_footprint_market_tools",
  "eco_design_ia_footprint_consultants",
  "eco_design_in_ia",
  "eco_design_in_data_management",
]
const translationPath = "8.eco_design"
const disableConfig = [
  {
    disablingField: "eco_design_ia_footprint_none",
    condition: isInvertedChoice,
    fieldsToDisable: [
      "eco_design_ia_footprint_internal",
      "eco_design_ia_footprint_free_tools",
      "eco_design_ia_footprint_market_tools",
      "eco_design_ia_footprint_consultants",
    ],
  },
]

const EcoDesignForm = () => {
  useFormConfig({ fields, translationPath, disableConfig })

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_PROJECTS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <MultipleChoiceField
                  id="design"
                  choices={[
                    "eco_design_practices_eco_conception",
                    "eco_design_practices_sobriety",
                    "eco_design_practices_inclusion",
                    "eco_design_practices_functional_coverage",
                  ]}
                />

                <MultipleChoiceField
                  id="projects_design_ecodesign_standards"
                  choices={[
                    "eco_design_standards_rgesn",
                    "eco_design_standards_rgaa",
                    "eco_design_standards_afnor_spec_2201",
                    "eco_design_standards_afnor_spec_other",
                  ]}
                />

                <YesNoRadio id="eco_design_in_ia" />
                <YesNoRadio id="eco_design_in_data_management" />

                <YesNoRadio id="eco_design_physical_requirements_evaluation" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="eco_design_development_simulation" />
                <MultipleChoiceField
                  id="projects_development_practices_tools"
                  choices={[
                    "eco_design_development_tool_measure",
                    "eco_design_development_tool_code_analysis",
                    "eco_design_development_tool_good_practice_analysis",
                  ]}
                />

                <MultipleChoiceField
                  id="environmental_footprint_methodology"
                  choices={[
                    "eco_design_ia_footprint_none",
                    "eco_design_ia_footprint_internal",
                    "eco_design_ia_footprint_free_tools",
                    "eco_design_ia_footprint_market_tools",
                    "eco_design_ia_footprint_consultants",
                  ]}
                />

                <YesNoRadio id="eco_design_development_code_review" />
                <YesNoRadio id="eco_design_decommissioning" />
                <YesNoRadio id="eco_design_evaluation_a_posteriori" />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default EcoDesignForm
