import { ActionList, Icon, Popover } from "@shopify/polaris"
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons"
import React from "react"
import useToggle from "hooks/useToggle"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import useRootNode from "hooks/useRootNode"
import UserStateEntity from "services/entities/UserStateEntity"
import { useEntity } from "hooks/useAxios"
import "stylesheets/navigation/ProductNavigation.scss"
import CorporateIt from "assets/images/logos/CorporateIT"
import ITProvider from "assets/images/logos/ITProvider"

const ProductNavigation = () => {
  const { t } = useTranslation()
  const userStateEntity = useEntity(UserStateEntity)
  const [active, toggle] = useToggle(false)
  const { productDetails, currentRootNode, currentUser } = useAppContext()
  const { changeRoot } = useRootNode()

  const onAction = async (id) => {
    if (id === currentRootNode?.id) return

    const rootResponse = await userStateEntity.setCurrentRoot(id)

    await changeRoot(rootResponse, "/")
  }

  const activator = (
    <button onClick={toggle}>
      <Icon source={MobileVerticalDotsMajor} tone="base" />
    </button>
  )

  if (!productDetails || !currentRootNode || !currentUser) return null

  const corporateDisabled = !(
    (currentUser.can_switch_product && productDetails.has_corporate_product) ||
    currentRootNode?.is_corporate
  )

  const providerDisabled = !(
    (currentUser.can_switch_product && productDetails.has_provider_product) ||
    currentRootNode?.is_provider
  )

  return (
    <Popover
      active={active}
      activator={activator}
      autofocusTarget="first-node"
      onClose={toggle}
    >
      <div className="product-navigation">
        <ActionList
          actionRole="menuitem"
          onActionAnyItem={toggle}
          sections={[
            {
              items: [
                {
                  content: <CorporateIt />,
                  disabled: corporateDisabled,
                  active: currentRootNode?.is_corporate,
                  onAction: () =>
                    currentRootNode?.is_provider &&
                    onAction(productDetails.target_corporate_tree),
                },
                {
                  content: <ITProvider />,
                  disabled: providerDisabled,
                  active: currentRootNode?.is_provider,
                  onAction: () =>
                    currentRootNode?.is_corporate &&
                    onAction(productDetails.target_provider_tree),
                },
              ],
            },
          ]}
        />
      </div>
    </Popover>
  )
}

export default ProductNavigation
