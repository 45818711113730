import axios from "axios"
import i18n from "i18next"

export const backClient = axios.create({
  withCredentials: true,
})

backClient.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = i18n.language
    return config
  },
  (error) => Promise.reject(error)
)
