import React from "react"
import { Icon, Tooltip } from "@shopify/polaris"
import { AlertMinor } from "@shopify/polaris-icons"
import ModalCloudUsage from "views/molecules/collects/cloud_inventories/ModalCloudUsage"
import { FIELDS } from "components/widgets/CollectPage/forms/inventories/environment/CloudUsageInventory"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const CloudUsageTableLine = ({ cloud, afterUpdateAction }) => {
  const { t } = useTranslation()

  return (
    <tr>
      {FIELDS.map((field) => (
        <td key={field}>
          {field === "cost" && cloud[field] ? `${cloud[field]}€` : cloud[field]}
        </td>
      ))}
      <td className="not-valid-icon">
        {!cloud.valid && (
          <Tooltip
            content={t(
              "collect:questions.13.datacenter_external.cloud.errors.invalid"
            )}
          >
            <Icon source={AlertMinor} tone="base" />
          </Tooltip>
        )}
      </td>
      <td>
        <ModalCloudUsage item={cloud} postAction={afterUpdateAction} />
      </td>
    </tr>
  )
}

CloudUsageTableLine.propTypes = {
  cloud: PropTypes.object.isRequired,
  afterUpdateAction: PropTypes.func.isRequired,
}

export default CloudUsageTableLine
