import PropTypes from "prop-types"
import React from "react"
import { Redirect, useParams } from "react-router-dom"
import WorkplaceAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/WorkplaceAnalysisOrganism"
import NetworkAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/NetworkAnalysisOrganism"
import DatacenterInternalAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/DatacenterInternalAnalysisOrganism"
import DatacenterExternalAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/DatacenterExternalAnalysisOrganism"
import SoftwareAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/SoftwareAnalysisOrganism"
import WebsiteAnalyseChartOrganism from "views/organisms/canvases/charts/analyzes/website/WebsiteAnalyseChartOrganism"
import CloudAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/CloudAnalysisOrganism"
import Office365AnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/Office365AnalysisOrganism"
import SaasAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/SaasAnalysisOrganism"
import VmsAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/VmsAnalysisOrganism"
import TransversalITDomainAnalysisOrganism from "views/organisms/canvases/charts/ITDomainAnalysis/TransversalITDomainAnalysisOrganism"

const Objective13ITDomainAnalysisOrganism = ({ envScore }) => {
  const { themeId } = useParams()

  const domainSwitch = (themeId) => {
    switch (themeId) {
      case "equipments":
        return <TransversalITDomainAnalysisOrganism score={envScore} />
      case "websites":
        return <WebsiteAnalyseChartOrganism score={envScore} />
      case "workplace":
        return <WorkplaceAnalysisOrganism score={envScore} />
      case "datacenter_internal":
        return <DatacenterInternalAnalysisOrganism score={envScore} />
      case "datacenter_external":
        return <DatacenterExternalAnalysisOrganism score={envScore} />
      case "network":
        return <NetworkAnalysisOrganism score={envScore} />
      case "software":
        return <SoftwareAnalysisOrganism score={envScore} />
      case "cloud":
        return <CloudAnalysisOrganism score={envScore} />
      case "office365":
        return <Office365AnalysisOrganism score={envScore} />
      case "saas":
        return <SaasAnalysisOrganism score={envScore.saas} />
      case "apps":
        return <VmsAnalysisOrganism score={envScore.apps} />

      default:
        return <Redirect to="/analyze/it-domain/environmental/13/equipments" />
    }
  }

  return domainSwitch(themeId)
}

Objective13ITDomainAnalysisOrganism.propTypes = {
  envScore: PropTypes.object.isRequired,
}

export default Objective13ITDomainAnalysisOrganism
