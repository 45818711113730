export const BILLIONS_PER_YEAR = "billions_per_year"
export const MILLIONS_PER_YEAR = "millions_per_year"
export const THOUSANDS_PER_YEAR = "thousands_per_year"

export const EXPONENT_PER_UNIT = {
  [BILLIONS_PER_YEAR]: 1_000_000,
  [MILLIONS_PER_YEAR]: 1_000,
  [THOUSANDS_PER_YEAR]: 1,
}

const PRODUCT_TO_FINANCIALS_UNIT_KEYS = {
  corporate: {
    billions: "billions_per_year",
    millions: "millions_per_year",
    thousands: "thousands_per_year",
  },
  provider: {
    billions: "billions",
    millions: "millions",
    thousands: "thousands",
  },
}

const financialsUnits = (t, product = "corporate") => {
  const units = PRODUCT_TO_FINANCIALS_UNIT_KEYS[product]

  return [
    {
      label: t(`activation:common.questions.${units.billions}`),
      value: BILLIONS_PER_YEAR,
    },
    {
      label: t(`activation:common.questions.${units.millions}`),
      value: MILLIONS_PER_YEAR,
    },
    {
      label: t(`activation:common.questions.${units.thousands}`),
      value: THOUSANDS_PER_YEAR,
    },
  ]
}

export default financialsUnits
