import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import AuthProvider from "./AuthProvider"
import { auth0Config, disabledAuth } from "./AuthConfig"

// This allows to stay logged in on reload, on browsers that don't allow third-party cookies
// https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
const LOCAL_STORAGE = "localstorage"

const Auth0ProviderWithHistory = ({ children }) => {
  const scope = "openid profile email read:current_user"
  const responseType = "token id_token"

  const history = useHistory()

  const onRedirectCallback = (appState) =>
    appState?.returnTo && history.push(appState?.returnTo)

  return disabledAuth ? (
    <AuthProvider>{children}</AuthProvider>
  ) : (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.client_id}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={LOCAL_STORAGE}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0Config.audience,
        scope,
        responseType,
      }}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node,
}

export default Auth0ProviderWithHistory
