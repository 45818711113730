import { Badge } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const TranslateReportStatusReportAtom = ({ report }) => {
  const { t } = useTranslation()

  switch (report.report_status) {
    case "to_be_launched":
      return (
        <Badge status="info">
          {t(
            "share:content.reports_modal.report_status.options.to_be_launched"
          )}
        </Badge>
      )
    case "work_in_progress":
      return (
        <Badge status="partiallyComplete">
          {t(
            "share:content.reports_modal.report_status.options.work_in_progress"
          )}
        </Badge>
      )
    case "done":
      return (
        <Badge status="success">
          {t("share:content.reports_modal.report_status.options.done")}
        </Badge>
      )
    case "failed":
      return (
        <Badge status="critical">
          {t("share:content.reports_modal.report_status.options.failed")}
        </Badge>
      )
    case "deprecated":
      return (
        <Badge status="warning">
          {t("share:content.reports_modal.report_status.options.deprecated")}
        </Badge>
      )
  }
}

TranslateReportStatusReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default TranslateReportStatusReportAtom
