const baseUrl = "/api/profit_collect"

const ProfitCollectEntity = (backClient) => ({
  show: async () => await backClient.get(baseUrl),
  progress: async () => await backClient.get(`${baseUrl}/progress`),
  put: async (data) => await backClient.put(baseUrl, { profit_collect: data }),
  close: async () => await backClient.post(`${baseUrl}/close`),
})

export default ProfitCollectEntity
