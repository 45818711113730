import React, { useCallback } from "react"
import classNames from "classnames"
import { Radar } from "react-chartjs-2"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import { PILLARS } from "utils/constants/pillars"
import { POSITION_BOTTOM } from "utils/constants/positions"

const defaultOptions = {
  maintainAspectRatio: false,
  legend: { display: true, position: POSITION_BOTTOM },
  scale: {
    angleLines: {
      display: false,
    },
    ticks: {
      suggestedMin: 0,
    },
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItem, data) {
        return data.datasets[tooltipItem[0].datasetIndex].label
      },
      label: function (tooltipItem, data) {
        return data.labels[tooltipItem.index] + ": " + tooltipItem.value
      },
    },
  },
}

const ChartRadarMolecule = ({
  title,
  pillarId,
  config,
  ticks,
  options,
  colors,
}) => {
  const { t } = useTranslation()

  const generateData = useCallback(() => {
    const labels = config.labels.map((label) =>
      t(`analyze:historization.node.odd_labels.${label}`)
    )

    const datasets = config.datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      fill: true,
      backgroundColor: dataset.backgroundColor || colors.backgroundColor(index),
      borderColor: dataset.borderColor || colors.borderColor(index),
      pointBackgroundColor:
        dataset.pointBackgroundColor || colors.pointBackgroundColor(index),
    }))

    return {
      labels: labels,
      datasets: datasets,
    }
  }, [config])

  const generateOption = (customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions }
    // override ticks option with props
    finalOptions.scale.ticks = { ...finalOptions.scale.ticks, ...ticks }

    return finalOptions
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Radar options={generateOption(options)} data={generateData()} />
      </div>
    </div>
  )
}

ChartRadarMolecule.propTypes = {
  config: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  ticks: PropTypes.object,
  title: PropTypes.string,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
  options: PropTypes.object,
  colors: PropTypes.object,
}

ChartRadarMolecule.defaultProps = {
  max: null,
  min: null,
  ticks: {},
  title: "",
  options: {},
  colors: {
    backgroundColor: (index) => Config.common.colors_rgba[index],
    borderColor: (index) => Config.common.colors[index],
    pointBackgroundColor: (index) => Config.common.colors[index],
  },
}

export default ChartRadarMolecule
