import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { round } from "services/units/round"
import { RETURN_OBJECTS } from "services/translations/config"
import ChartBadge from "views/atoms/badges/ChartBadge"

const TextChartODD13AverageEquipmentsPerFteMolecule = ({ score }) => {
  const { t } = useTranslation()

  const value = round(score?.odd13_number_of_workplace_equipment_per_employee)

  const { title, help } = t(
    "analyze:it_domain.assets.equipment_per_fte",
    RETURN_OBJECTS
  )

  const badge = (
    <ChartBadge value={value} lowerLimit={1.5} upperLimit={2.5} inverse />
  )

  const contentVolumeEquipment = {
    kpi_text: title,
    kpi: value,
    reference: badge,
  }

  const content_for_display = (
    <>
      <TextChartMinor content={contentVolumeEquipment} />
    </>
  )

  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13AverageEquipmentsPerFteMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13AverageEquipmentsPerFteMolecule
