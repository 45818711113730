import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { Link } from "@shopify/polaris"
import PropTypes from "prop-types"

function GlossaryHover({ entry, replace }) {
  const { t } = useTranslation()

  return (
    <span className="GlossaryHover">
      {replace}
      <span className="definition">
        <strong>{t(`help:glossary.content.${entry}.word`)} - </strong>
        <Trans
          i18nKey={`help:glossary.content.${entry}.definition`}
          components={[
            <Link key={1} url={t(`help:glossary.content.${entry}.link`)} />,
          ]}
        ></Trans>
      </span>
    </span>
  )
}
GlossaryHover.propTypes = {
  entry: PropTypes.string.isRequired,
  replace: PropTypes.string.isRequired,
}

export default GlossaryHover
