import React from "react"
import { Spinner } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

export const Loading = ({ loadingTextDisplayed = true }) => {
  const { t } = useTranslation()
  return (
    <div className="loading-spinner">
      <div>
        <div>
          <div className="loading-spinner__icon">
            <Spinner size="large" color="teal" />
          </div>

          {loadingTextDisplayed && (
            <div className="loading-spinner__text">{t("common:loading")}</div>
          )}
        </div>
      </div>
    </div>
  )
}

Loading.propTypes = {
  hasBackground: PropTypes.bool,
  loadingTextDisplayed: PropTypes.bool,
}

export default Loading
