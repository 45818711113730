import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, Layout, Page, Tabs } from "@shopify/polaris"
import Glossary from "components/widgets/HelpPage/Glossary"
import Faq from "components/widgets/HelpPage/Faq"
import withAuthRequired from "../auth/withAuthRequired";

function HelpPage() {
  const { t } = useTranslation()

  const [selected, setSelected] = useState(0)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  )

  const tabs = [
    {
      id: "faq",
      content: t("help:faq.title"),
      accessibilityLabel: "FAQ",
      panelID: "glossary",
    },
    {
      id: "glossary",
      content: t("help:glossary.title"),
      panelID: "glossary",
    },
  ]

  return (
    <div>
      <Page title={t("help:title")}>
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs
                tabs={tabs}
                selected={selected}
                onSelect={handleTabChange}
                fitted
              >
                <Card.Section>
                  {selected == 0 ? <Faq /> : <Glossary />}
                </Card.Section>
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  )
}

export default withAuthRequired(HelpPage)
