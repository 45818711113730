import React, { useState } from "react"
import HeaderTemplate from "views/templates/shared/HeaderTemplate"
import BreadcrumbOrganizationChartOrganism from "views/organisms/organizationCharts/BreadcrumbOrganizationChartOrganism"
import BreadcrumbMolecule from "views/molecules/common/breadcrumb/BreadcrumbMolecule"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"

const BreadcrumbsOrganism = ({
  parentOrganization,
  currentPeriodName,
  redirectDisabled,
  onDelete,
  node,
  italic,
}) => {
  const [redirect, setRedirect] = useState(false)

  if (!parentOrganization) return null

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/organize",
        }}
      />
    )
  }

  return (
    <HeaderTemplate>
      <BreadcrumbOrganizationChartOrganism
        node={node}
        onDelete={onDelete}
        pathArray={parentOrganization}
        redirectDisabled={redirectDisabled}
        italic={italic}
      />
      {!!currentPeriodName && (
        <BreadcrumbMolecule
          items={[currentPeriodName]}
          onClick={() => setRedirect(true)}
        />
      )}
    </HeaderTemplate>
  )
}

BreadcrumbsOrganism.propTypes = {
  parentOrganization: PropTypes.arrayOf(PropTypes.object),
  currentPeriodName: PropTypes.string,
  redirectDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  node: PropTypes.object,
  italic: PropTypes.bool,
}

BreadcrumbsOrganism.defaultProps = {
  redirectDisabled: false,
  italic: false,
}

export default BreadcrumbsOrganism
