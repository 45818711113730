import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import GHGScopesOrganism from "views/organisms/canvases/charts/ghg-scopes/GHGScopesOrganism"
import GHGScopesDetailsOrganism from "views/organisms/canvases/charts/ghg-scopes/GHGScopesDetailsOrganism"

const GHGAnalyzeOrganism = ({ envScore, protocolId, synthesisId }) => {
  switch (synthesisId) {
    case "synthesis":
      return <GHGScopesOrganism score={envScore} protocol={protocolId} />
    case "detail":
      return <GHGScopesDetailsOrganism score={envScore} protocol={protocolId} />
    default:
      return protocolId === "bilan_carbone" ? (
        <Redirect to="/analyze/ghg-scopes/environmental/13/bilan_carbone/synthesis" />
      ) : (
        <Redirect to="/analyze/ghg-scopes/environmental/13/ghg_protocol/synthesis" />
      )
  }
}

GHGAnalyzeOrganism.propTypes = {
  envScore: PropTypes.object.isRequired,

  protocolId: PropTypes.string.isRequired,
  synthesisId: PropTypes.string.isRequired,
}

export default GHGAnalyzeOrganism
