import activationFr from "assets/translations/activation.fr.json"
import collectFr from "assets/translations/collect.fr.json"
import commonFr from "assets/translations/common.fr.json"
import dashboardFr from "assets/translations/dashboard.fr.json"
import notificationsFr from "assets/translations/notifications.fr.json"
import scoreFr from "assets/translations/score.fr.json"
import shareEn from "assets/translations/share.en.json"
import shareFr from "assets/translations/share.fr.json"
import userProfileFr from "assets/translations/user_profile.fr.json"
import collectEn from "assets/translations/collect.en.json"
import activationEn from "assets/translations/activation.en.json"
import commonEn from "assets/translations/common.en.json"
import dashboardEn from "assets/translations/dashboard.en.json"
import notificationsEn from "assets/translations/notifications.en.json"
import scoreEn from "assets/translations/score.en.json"
import userProfileEn from "assets/translations/user_profile.en.json"
import improveFr from "assets/translations/improve.fr.json"
import improveEn from "assets/translations/improve.en.json"
import analyzeFr from "assets/translations/analyze.fr.json"
import analyzeEn from "assets/translations/analyze.en.json"
import unitsFr from "assets/translations/units.fr.json"
import unitsEn from "assets/translations/units.en.json"
import helpEn from "assets/translations/help.en.json"
import helpFr from "assets/translations/help.fr.json"
import recommendationEn from "assets/translations/recommendation.en.json"
import recommendationFr from "assets/translations/recommendation.fr.json"
import organizationFr from "assets/translations/organization.fr.json"
import organizationEn from "assets/translations/organization.en.json"
import subscriptionFr from "assets/translations/subscription.fr.json"
import subscriptionEn from "assets/translations/subscription.en.json"
import simulateFr from "assets/translations/simulate.fr.json"
import simulateEn from "assets/translations/simulate.en.json"
import replayFR from "assets/translations/replay.fr.json"
import replayEN from "assets/translations/replay.en.json"
import ErrorBoundaryFR from "assets/translations/error_boundary.fr.json"
import ErrorBoundaryEN from "assets/translations/error_boundary.en.json"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  fr: {
    activation: activationFr,
    collect: collectFr,
    common: commonFr,
    dashboard: dashboardFr,
    notifications: notificationsFr,
    score: scoreFr,
    share: shareFr,
    user_profile: userProfileFr,
    improve: improveFr,
    analyze: analyzeFr,
    units: unitsFr,
    help: helpFr,
    recommendation: recommendationFr,
    organization: organizationFr,
    subscription: subscriptionFr,
    simulate: simulateFr,
    replay: replayFR,
    error_boundary: ErrorBoundaryFR,
  },
  en: {
    activation: activationEn,
    share: shareEn,
    common: commonEn,
    notifications: notificationsEn,
    collect: collectEn,
    score: scoreEn,
    dashboard: dashboardEn,
    user_profile: userProfileEn,
    improve: improveEn,
    analyze: analyzeEn,
    units: unitsEn,
    help: helpEn,
    recommendation: recommendationEn,
    organization: organizationEn,
    subscription: subscriptionEn,
    simulate: simulateEn,
    replay: replayEN,
    error_boundary: ErrorBoundaryEN,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lng") || "fr",
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
