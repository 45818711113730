import React, { useEffect } from "react"
import { FormLayout } from "@shopify/polaris"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { fieldIsFalsyOrNonPositive } from "components/widgets/CollectPage/useDisablingFields"
import {
  useFormConfig,
  useFormContext,
} from "components/widgets/CollectPage/forms/FormContext"
import Form from "components/widgets/CollectPage/forms/Form"
import {
  ExternalLabel,
  FileField,
  FinancialField,
  MultipleChoiceField,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import { INVENTORY_BUDGETS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"

const fields = [
  "investments_sustainable_category",
  "investments_green_percentage",
  "investments_opex_percentage",
  "investments_capex_percentage",
  "investments_budget_trainings",
  "investments_budget_green_it",
  "investments_budget_it_for_green",
  "investments_budget_innovation",
  "investments_budget_green_energy",
  "investments_participation",
  "investments_cvc_funding",
  "investments_cdr_segment",
  "investments_investment_amount",
  "investments_investment_csr_amount",
  "investments_investments_percentage",
  "investments_investments_csr_percentage",
  "investments_acquisition_percentage",
  "investments_acquisition_csr_percentage",
]
const translationPath = "8.investments"

const disableConfig = [
  {
    disablingField: "investments_sustainable_category",
    fieldsToDisable: ["investments_green_percentage"],
  },
  {
    disablingField: "investments_participation",
    fieldsToDisable: [
      "investments_cvc_funding",
      "investments_cdr_segment",
      "investments_investment_amount",
    ],
  },
  {
    disablingField: "investments_investment_amount",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "investments_investment_csr_amount",
      "investments_investments_percentage",
      "investments_acquisition_percentage",
    ],
  },
  {
    disablingField: "investments_investments_percentage",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "investments_investments_csr_percentage",
      "investments_acquisition_csr_percentage",
    ],
  },
]

const tPrefix = `collect:questions.${translationPath}`

const InvestmentsForm = () => {
  const { t } = useTranslation()
  const { collect } = useCollectContext()
  const { formData, setFormData } = useFormContext()

  useFormConfig({ fields, translationPath, disableConfig })

  useEffect(() => {
    if (!formData?.investments_opex_percentage) return

    const opex = formData?.investments_opex_percentage
    const capex = opex === null || opex === "" ? null : 100 - opex

    setFormData({
      ...formData,
      investments_capex_percentage: capex,
    })
  }, [formData?.investments_opex_percentage])

  useEffect(() => {
    if (
      !formData?.investments_investments_percentage ||
      // This condition is to avoid a double useEffect (one here and one in disablingFields)
      (+formData?.investments_investments_percentage === 0 &&
        !!formData?.investments_investments_csr_percentage)
    )
      return

    setFormData({
      ...formData,
      investments_acquisition_percentage:
        100 - formData?.investments_investments_percentage,
    })
  }, [
    formData?.investments_investments_percentage,
    formData?.investments_investments_csr_percentage,
  ])

  useEffect(() => {
    if (!formData?.investments_investments_csr_percentage) return

    setFormData({
      ...formData,
      investments_acquisition_csr_percentage:
        100 - formData?.investments_investments_csr_percentage,
    })
  }, [formData?.investments_investments_csr_percentage])

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_BUDGETS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <UploadOrganism
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="investments"
                      inventoryType={INVENTORY_BUDGETS}
                      displayDownloadIcon
                    />
                  }
                  decentralisedComponent={
                    <FileField
                      id="decentralized_inventory_budgets"
                      title="investments"
                      inventoryType={INVENTORY_BUDGETS}
                      translationPathName="investments"
                      templateSelectionComponent={
                        <TemplateFileLinkMolecule id="decentralized_inventory_budgets" />
                      }
                    />
                  }
                />

                <ExternalLabel translationPathName="investment_pex_distribution" />
                <FormLayout.Group>
                  <TextField id="investments_opex_percentage" type="number" />
                  <TextField
                    id="investments_capex_percentage"
                    type="number"
                    disabled
                  />
                </FormLayout.Group>

                <YesNoRadio id="investments_sustainable_category" />
                <TextField id="investments_green_percentage" type="number" />
                <MultipleChoiceField
                  id="budget_lines"
                  choices={[
                    "investments_budget_trainings",
                    "investments_budget_green_it",
                    "investments_budget_innovation",
                  ]}
                />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="investments_participation" />
                <table>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.participation.cvc_funding`)}</td>
                      <td>
                        <YesNoRadio id="investments_cvc_funding" />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.participation.cdr_segment`)}</td>
                      <td>
                        <YesNoRadio id="investments_cdr_segment" />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ExternalLabel translationPathName="transactions" />
                <table>
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.transactions.all`)}</td>
                      <td>{t(`${tPrefix}.transactions.csr`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.transactions.investment_amount`)}</td>
                      <td>
                        <FinancialField id="investments_investment_amount" />
                      </td>
                      <td>
                        <FinancialField id="investments_investment_csr_amount" />
                      </td>
                    </tr>
                    {["investments", "acquisition"].map((field, index) => (
                      <tr key={index}>
                        <td>
                          {t(`${tPrefix}.transactions.${field}_percentage`)}
                        </td>
                        <td>
                          <TextField
                            id={`investments_${field}_percentage`}
                            type="number"
                            suffix="%"
                            disabled={field === "acquisition"}
                          />
                        </td>
                        <td>
                          <TextField
                            id={`investments_${field}_csr_percentage`}
                            type="number"
                            suffix="%"
                            disabled={field === "acquisition"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default InvestmentsForm
