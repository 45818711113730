// If you update these colors, also update the vars.scss file
import colors from "stylesheets/vars.scss"

export const COLORS = {
  white: colors.white,
  black: colors.black,
  transparent: colors.transparent,

  planet: {
    primary: colors.planetPrimary,
    secondary: colors.planetSecondary,
    tertiary: colors.planetTertiary,
    light: colors.planetLight,

    c10: colors.planetc10,
    c20: colors.planetc20,
    c30: colors.planetc30,
    c40: colors.planetc40,
    c50: colors.planetc50,
    c60: colors.planetc60,
    c70: colors.planetc70,
  },

  social: {
    primary: colors.socialPrimary,
    secondary: colors.socialSecondary,
    tertiary: colors.socialTertiary,
    light: colors.socialLight,

    c30: colors.socialc30,
    c40: colors.socialc40,
  },

  profit: {
    primary: colors.profitPrimary,
    secondary: colors.profitSecondary,
    tertiary: colors.profitTertiary,
    light: colors.profitLight,

    c10: colors.profitc10,
    c30: colors.profitc30,
    c40: colors.profitc40,
    c50: colors.profitc50,
  },

  corporate: {
    primary: colors.corporatePrimary,
    secondary: colors.corporateSecondary,
    light: colors.corporateLight,
    c10: colors.corporatec10,
    c20: colors.corporatec20,
    c30: colors.corporatec30,
    c40: colors.corporatec40,
    c50: colors.corporatec50,
    c60: colors.corporatec60,
    c70: colors.corporatec70,
    c80: colors.corporatec80,
    c90: colors.corporatec90,
  },

  partialPartner: {
    primary: colors.partialPartnerPrimary,
    secondary: colors.partialPartnerSecondary,
    light: colors.partialPartnerLight,
  },

  payingPartner: {
    primary: colors.payingPartnerPrimary,
    secondary: colors.payingPartnerSecondary,
    lighter: colors.payingPartnerLighter,
  },

  neutral: {
    c10: colors.neutralc10,
    c20: colors.neutralc20,
    c30: colors.neutralc30,
    c40: colors.neutralc40,
    c50: colors.neutralc50,
    c60: colors.neutralc60,
  },

  warning: {
    primary: colors.warningPrimary,
    lighter: colors.warningLighter,
    light: colors.warningLight,
    darker: colors.warningDarker,
  },

  danger: {
    primary: colors.dangerPrimary,
    secondary: colors.dangerSecondary,
    lighter: colors.dangerLighter,
    light: colors.dangerLight,
    dark: colors.dangerDark,
    darker: colors.dangerDarker,
  },
}
