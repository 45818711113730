import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  Banner,
  Button,
  Form,
  FormLayout,
  Icon,
  Modal,
  Stack,
  TextField,
} from "@shopify/polaris"
import { SettingsMajor } from "@shopify/polaris-icons"
import { HistoryContext } from "views/contexts/HistoryContext"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import PeriodEntity from "services/entities/PeriodEntity"
import { useEntity } from "hooks/useAxios"

const PeriodEditModal = () => {
  const [locked, setLocked] = useState(false)
  const [name, setName] = useState("")
  const [startsOn, setStartsOn] = useState(null)
  const [endsOn, setEndsOn] = useState(null)
  const [error, setError] = useState(null)
  const [active, setActive] = useState(false)
  const periodEntity = useEntity(PeriodEntity)

  const { currentRootNode, currentPeriod, subscription } = useAppContext()

  const { t } = useTranslation()

  useEffect(() => {
    setName(currentPeriod?.name)
    setStartsOn(currentPeriod?.starts_on)
    setEndsOn(currentPeriod?.ends_on)
  }, [currentPeriod, currentRootNode])

  useEffect(() => setLocked(currentPeriod?.locked), [currentPeriod])

  const toggleModal = useCallback(() => setActive((active) => !active), [])

  const historyContext = useContext(HistoryContext)
  const { reloadCurrentPeriod } = useAppContext()

  const activator = (
    <Button onClick={toggleModal}>
      <Icon source={SettingsMajor} color="base" />
    </Button>
  )

  const updatePeriod = useCallback(async () => {
    try {
      await periodEntity.update(currentPeriod.id, {
        name,
        starts_on: startsOn,
        ends_on: endsOn,
      })

      historyContext.reload()
      reloadCurrentPeriod()

      toggleModal()
    } catch (err) {
      setError(err.response.data)
    }
  }, [name, startsOn, endsOn])

  const title = subscription.is_provider
    ? t("organization:period_form.title.partner")
    : t("organization:period_form.title.corporate")

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={toggleModal}
      title={title}
      primaryAction={
        !locked && {
          content: t("organization:period_form.fields.submit"),
          onAction: updatePeriod,
          disabled: locked,
        }
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            {!!error && (
              <Banner title="Impossible de modifier" status="critical" />
            )}

            <Form onSubmit={updatePeriod}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    label={t("organization:period_form.fields.name")}
                    value={name}
                    onChange={setName}
                    disabled={locked}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <TextField
                    label={t("organization:period_form.fields.date_start")}
                    type="date"
                    value={startsOn}
                    onChange={setStartsOn}
                    disabled={locked}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <TextField
                    label={t("organization:period_form.fields.date_end")}
                    type="date"
                    value={endsOn}
                    onChange={setEndsOn}
                    disabled={locked}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  )
}

export default PeriodEditModal
