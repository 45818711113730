import Config from "assets/configs/score.js"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Bar } from "react-chartjs-2"
import "stylesheets/ScorePage/BarChart.scss"
import classNames from "classnames"
import { chartTooltipsLabelGenerator } from "components/widgets/ScorePage/widgets/chartTooltipsLabelGenerator"
import { COLORS } from "utils/constants/colors"

const StackedBarChartAtom = ({
  title,
  data,
  axesLabel,
  displayYAxe = true,
  displayLegend = true,
}) => {
  const generateData = useCallback(() => {
    data.datasets.forEach((dataset, index) => {
      let color = Config.common.colors[index % Config.common.colors.length]
      dataset.color = color
      dataset.order = 10
      dataset.borderSkipped = true
      dataset.backgroundColor = color
      dataset.hoverBackgroundColor = color
      if (dataset.border) {
        dataset.borderColor = "#E44E58"
        dataset.borderWidth = 2.5
      } else {
        dataset.borderColor = "#3772FF"
        dataset.borderWidth = 0
      }
    })

    return {
      labels: data.labels,
      datasets: data.datasets,
    }
  }, [data])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: displayLegend,
      position: "bottom",
      labels: {
        fontStyle: "italic",
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          display: displayYAxe,
          scaleLabel: {
            display: displayYAxe,
            labelString: axesLabel,
          },
        },
      ],
    },
    tooltips: {
      callbacks: { label: chartTooltipsLabelGenerator(axesLabel) },
    },
    plugins: {
      datalabels: {
        display: true,
        color: COLORS.white,
        font: {
          weight: "bold",
        },
      },
    },
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart")}>
      <h1>{title}</h1>
      <div className="content">
        <Bar data={generateData()} options={options} />
      </div>
    </div>
  )
}

StackedBarChartAtom.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  axesLabel: PropTypes.string,
  displayYAxe: PropTypes.bool,
  displayLegend: PropTypes.bool,
  doSortData: PropTypes.bool,
}

export default StackedBarChartAtom
