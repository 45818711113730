import React from "react"
import { PropTypes } from "prop-types"

const KpiMolecule = ({ children, subtitle }) => {
  return (
    <div className="Verdikt-Score-TextChart kpi-compare">
      <div className="Verdikt-Score-TextChart-Content">
        <div>
          <p>{subtitle}</p>
          {children}
        </div>
      </div>
    </div>
  )
}

KpiMolecule.propTypes = {
  children: PropTypes.element,
  subtitle: PropTypes.string,
}

KpiMolecule.defaultProps = {
  children: null,
  subtitle: "",
}

export default KpiMolecule
