// @todo refactor component and remove forceEnabled -> hotfix
import { useTranslation } from "react-i18next"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import {
  FormLayout,
  Select as PSelect,
  TextField as PTextField,
} from "@shopify/polaris"
import PropTypes from "prop-types"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import financialsUnits, {
  BILLIONS_PER_YEAR,
  EXPONENT_PER_UNIT,
  MILLIONS_PER_YEAR,
  THOUSANDS_PER_YEAR,
} from "utils/functions/financialsUnits"
import { checkHover } from "./Functions"

const findUnit = (value) => {
  if (!value) {
    return THOUSANDS_PER_YEAR
  } else if (isExactly(value, BILLIONS_PER_YEAR)) {
    return BILLIONS_PER_YEAR
  } else if (isExactly(value, MILLIONS_PER_YEAR)) {
    return MILLIONS_PER_YEAR
  } else {
    return THOUSANDS_PER_YEAR
  }
}

const formatValue = (value, unit) =>
  value === null ? null : value / EXPONENT_PER_UNIT[unit]
const deFormatValue = (value, unit) =>
  value === "" ? null : value * EXPONENT_PER_UNIT[unit]
const isExactly = (value, unit) => value % EXPONENT_PER_UNIT[unit] === 0

export const FinancialField = ({ id, disabled }) => {
  const { t } = useTranslation()
  const {
    formData,
    setFormData,
    disabledFields,
    errors,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const [formattedValue, setFormattedValue] = useState(
    _.get(formData, id, null)
  )
  const [formattedUnit, setFormattedUnit] = useState(THOUSANDS_PER_YEAR)

  const [isUpdated, setIsUpdated] = useState(false)

  const translationPrefix = `collect:questions.${translationPath}.${id}.`

  useEffect(() => {
    const newValue = _.get(formData, id, null)

    const unit = findUnit(newValue)
    setFormattedUnit(unit)
    setFormattedValue(formatValue(newValue, unit))
  }, [formData, _.get(formData, id)])

  const updateForm = (fValue, fUnit) => {
    fValue !== null &&
      setFormData({
        ...formData,
        [id]: deFormatValue(fValue, fUnit),
      })
  }

  return (
    <div>
      <FormLayout>
        <FormLayout.Group
          condensed
          title={checkHover(translationPrefix)}
          className="test"
        >
          <PTextField
            style={{ marginLeft: 0, marginTop: "0.4rem" }}
            label={null}
            type="number"
            placeholder={t(translationPrefix + "placeholder", "")}
            value={String(formattedValue !== null ? formattedValue : "")}
            onChange={(val) => {
              setFormattedValue(val)
              setIsUpdated(true)
            }}
            onBlur={() =>
              isUpdated && updateForm(formattedValue, formattedUnit)
            }
            error={_.get(errors, id, null)}
            disabled={isLocked || disabled || _.get(disabledFields, id, false)}
          />
          <PSelect
            style={{ marginTop: "0.4rem" }}
            label={null}
            options={financialsUnits(t)}
            value={formattedUnit}
            onChange={(val) => {
              setFormattedUnit(val)
              updateForm(formattedValue, val)
            }}
            disabled={disabled || isLocked || _.get(disabledFields, id, false)}
          />
        </FormLayout.Group>
      </FormLayout>
    </div>
  )
}

FinancialField.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

FinancialField.defaultProps = {
  disabled: false,
}

export default FinancialField
