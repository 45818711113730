import { FormLayout } from "@shopify/polaris"
import React from "react"
import BannerDispatcherReportOrganism from "views/molecules/reports/BannerDispatcherReportMolecule"
import ModalMarkupReportOrganism from "views/organisms/reports/ModalMarkupReportOrganism"
import TableMarkupReportOrganism from "views/organisms/reports/TableMarkupReportOrganism"

const ListReportTemplate = () => {
  return (
    <FormLayout>
      <br />
      <BannerDispatcherReportOrganism />
      <ModalMarkupReportOrganism />
      <TableMarkupReportOrganism />
    </FormLayout>
  )
}

export default ListReportTemplate
