import { Banner, Layout, Page } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"

const TopBannerMolecule = ({ status, title, action, message }) => {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner status={status} title={title} action={action}>
            {message}
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

TopBannerMolecule.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.object,
}

TopBannerMolecule.defaultProps = {
  action: null,
}

export default TopBannerMolecule
