import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { useState, useEffect } from "react"
import { DescriptionList, Card, Layout, Link } from "@shopify/polaris"

function Glossary() {
  const { t } = useTranslation()
  //entries --> {['csr', {'word':'..', 'definition':'..'}], [], ... }
  const entries = Object.entries(
    t("help:glossary.content", { returnObjects: true })
  )
  let [items, setItems] = useState([])

  /**
   * @description Maps through the entries of the glossary and returns an object depending on the language selected by the user
   * @returns an object in the format { word1 : definition, word2 : definition, ... }
   */
  function reformatEntries() {
    let unsortedObject = {}
    entries.map((entry) => {
      let key = t(`help:glossary.content.${entry[0]}.word`)
      let def = (
        <Trans
          i18nKey={`help:glossary.content.${entry[0]}.definition`}
          components={[
            <Link key={1} url={t(`help:glossary.content.${entry[0]}.link`)} />,
          ]}
        />
      )
      unsortedObject[key] = def
    })
    return unsortedObject
  }

  /**
   * @description This function sorts an object alphabetically
   * @param {Object} o The object must be formatted as follows {"c":"crane","b":"boy","a":"ant"}
   * @returns a sorted object {a: "ant", b: "boy", c: "crane"}
   */
  function sortObjectByKeys(o) {
    return Object.keys(o)
      .sort()
      .reduce((r, k) => ((r[k] = o[k]), r), {})
  }

  /**
   * @description Reorganizes the glossary object in the format of the 'items' array needed by DescriptionList
   * items={[
            {
            term: '',
            description:
                '',
            },
            {
            term: '',
            description:
                '',
            ]}
   *
   * @param {Object} sortedObject 
   */

  function updateItems(sortedObject) {
    setItems([])
    for (let [key, value] of Object.entries(sortedObject)) {
      setItems((prevState) => [...prevState, { term: key, description: value }])
    }
  }

  useEffect(() => {
    //the 't' dependency triggers useEffect:
    // - a new glossary is loaded and sorted when the language setting changes
    // - the glossary object is then used to update items state
    let unsortedGlossary = reformatEntries()
    let sortedGlossary = sortObjectByKeys(unsortedGlossary)
    updateItems(sortedGlossary)
  }, [t])

  return (
    <Layout>
      <Layout.Section>
        <Card.Section>
          <DescriptionList items={items} />
        </Card.Section>
      </Layout.Section>
    </Layout>
  )
}

export default Glossary
