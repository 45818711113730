import React from "react"
import Logo from "assets/images/logo.svg"

const LoginLogoMolecules = () => (
  <div style={{ textAlign: "center" }}>
    <img src={Logo} height="50" style={{ display: "inline" }} />
  </div>
)

export default LoginLogoMolecules
