import React, { useEffect, useState } from "react"
import { Banner } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { BANNER_INFO_STATUS } from "utils/constants/alert_banners/alertBannersConstant"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import { useParams } from "react-router-dom"
import { PILLAR_TO_CENTRALIZATION_TYPES } from "utils/constants/centralization/type"

const CentralizationBannerCollect = () => {
  const { t, i18n } = useTranslation()

  const { pillarId } = useParams()
  const { collect, modules, isModuleActive } = useCollectContext()
  const [deactivatedModules, setDeactivatedModules] = useState([])

  useEffect(() => {
    const collectModules = PILLAR_TO_CENTRALIZATION_TYPES[pillarId]

    const nextDeactivedModules = collectModules
      ? collectModules
          .filter((module) => !isModuleActive(module))
          .map((module) => t(`collect:modules.${module}`))
          .sort()
      : []

    setDeactivatedModules(nextDeactivedModules)
  }, [collect, modules, i18n.language])

  if (collect?.closed_at !== null || deactivatedModules.length === 0) {
    return null
  }

  const tPrefix = "collect:banners.centralization.collect_other_levels"

  return (
    <>
      <Banner
        status={BANNER_INFO_STATUS}
        title={t(`${tPrefix}.title`)}
        action={{
          content: t(`${tPrefix}.content`),
          url: "/organize",
        }}
      >
        {t(`${tPrefix}.text`)}
        {deactivatedModules.join(", ")}
      </Banner>
      <br />
    </>
  )
}

CentralizationBannerCollect.propTypes = {}

export default CentralizationBannerCollect
