import { ADMIN_ROLE, NO_ROLE, VIEWER_ROLE } from "utils/constants/userRoles"

export const jobTitles = [
  { labelTranslationKey: "common:user_form.job_title.nothing", value: "" },
  {
    labelTranslationKey: "common:user_form.job_title.executive",
    value: "executive",
  },
  { labelTranslationKey: "common:user_form.job_title.cio", value: "cio" },
  { labelTranslationKey: "common:user_form.job_title.csr", value: "csr" },
  { labelTranslationKey: "common:user_form.job_title.cto", value: "cto" },
  { labelTranslationKey: "common:user_form.job_title.other", value: "other" },
]

export const jobScopes = [
  { labelTranslationKey: "common:user_form.job_scope.nothing", value: "" },
  { labelTranslationKey: "common:user_form.job_scope.group", value: "group" },
  {
    labelTranslationKey: "common:user_form.job_scope.business",
    value: "business",
  },
]

export const jobReachs = [
  { labelTranslationKey: "common:user_form.job_reach.nothing", value: "" },
  {
    labelTranslationKey: "common:user_form.job_reach.worldwide",
    value: "worldwide",
  },
  { labelTranslationKey: "common:user_form.job_reach.region", value: "region" },
  {
    labelTranslationKey: "common:user_form.job_reach.country",
    value: "country",
  },
  { labelTranslationKey: "common:user_form.job_reach.site", value: "site" },
]

export const ROLE_OVERRIDES = [
  {
    labelTranslationKey: "common:user_form.roles.none",
    value: NO_ROLE,
  },
  {
    labelTranslationKey: "common:user_form.roles.viewer",
    value: VIEWER_ROLE,
  },
  {
    labelTranslationKey: "common:user_form.roles.admin",
    value: ADMIN_ROLE,
  },
]
