import { Banner, Modal } from "@shopify/polaris"
import { useAppContext } from "components/AppContext"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import InventoryChoiceConfig from "views/molecules/collects/cloud_inventories/InventoryChoiceConfig"
import ModalCloudInventoriesActivator from "views/molecules/collects/cloud_inventories/ModalCloudInventoriesActivator"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"

export const MANUAL_CHOICE = "manual"
export const INVENTORY_CHOICE = "inventory"
export const BOTH_CHOICE = "both"

const ModalCloudInventories = () => {
  const { moduleIsActive } = useModuleContext()
  useFormConfig({
    fields: [
      "cloud_inventory_choice",
      "cloud_inventory_aws",
      "cloud_inventory_gcp",
      "cloud_inventory_azure",
      "cloud_inventory_aws_choice",
      "cloud_inventory_gcp_choice",
      "cloud_inventory_azure_choice",
    ],
    translationPath: "13.datacenter_external.cloud",
  })
  const { envCollect } = useAppContext()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleChange = useCallback(() => setOpen(!open), [open])
  const closeModal = useCallback(() => setOpen(false), [])

  const cloudInventoryChoice = envCollect.cloud_inventory_choice

  const text = t("collect:questions.13.datacenter_external.cloud", {
    returnObjects: true,
  })

  const activator = moduleIsActive ? (
    <ModalCloudInventoriesActivator onClick={handleChange} />
  ) : null

  return (
    <Modal
      activator={activator}
      open={open}
      onClose={closeModal}
      title={t(
        "collect:questions.13.datacenter_external.dc.modal.cloud_service_description"
      )}
      primaryAction={{
        content: text.submit,
        onAction: closeModal,
      }}
    >
      {envCollect.has_cloud_usages && (
        <Banner title={text.banners.collected_data.title} status="warning">
          {text.banners.collected_data.description}
        </Banner>
      )}

      <Modal.Section>
        <RadioButton
          id="cloud_inventory_choice"
          choices={[
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.manual"
              ),
              value: MANUAL_CHOICE,
            },
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.inventory"
              ),
              value: INVENTORY_CHOICE,
            },
            {
              label: t(
                "collect:questions.13.datacenter_external.cloud.cloud_inventory_choice.options.both"
              ),
              value: BOTH_CHOICE,
            },
          ]}
          disabled={!moduleIsActive}
        />
      </Modal.Section>

      {[INVENTORY_CHOICE, BOTH_CHOICE].includes(cloudInventoryChoice) && (
        <InventoryChoiceConfig disabled={!moduleIsActive} />
      )}
    </Modal>
  )
}

export default ModalCloudInventories
