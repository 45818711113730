export const INVENTORY_EXTERNAL_APPS = "external_apps"
export const INVENTORY_INTERNAL_APPS = "internal_apps"
export const INVENTORY_VIRTUAL_MACHINES = "virtual_machines"
export const INVENTORY_NETWORK_EQUIPMENT_INVENTORIES =
  "network_equipment_inventories"
export const INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES =
  "workplace_equipment_inventories"
export const INVENTORY_TECH_TEAM_INVENTORIES = "tech_team_inventories"
export const INVENTORY_BUSINESS_ASSETS_INVENTORIES =
  "business_equipment_inventories"
export const INVENTORY_SOCIAL_PEOPLE_INVENTORIES = "social_people_inventories"
export const INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES =
  "data_center_external_inventories"
export const INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES =
  "data_center_internal_inventories"
export const INVENTORY_WEBSITES = "websites"
export const INVENTORY_DATA_CENTER_CLOUD_INVENTORIES =
  "data_center_cloud_inventories"
export const AWS_BILLING_EXPORTS = "aws_cloud_provider_exports"
export const GCP_BILLING_EXPORTS = "gcp_cloud_provider_exports"

export const INVENTORY_BUDGETS = "budget_inventories"
export const INVENTORY_PROJECTS = "project_inventories"
export const INVENTORY_INNOVATIONS = "innovation_inventories"

export const INVENTORY_WEBSITE = "websites_inventories"
export const INVENTORY_PARTNERS = "partner_inventories"
export const INVENTORY_CLOUD = "ccf_usages"
