import { useEffect } from "react"
import * as Sentry from "@sentry/react"

const useSentry = ({
  currentUser,
  currentOrganization,
  currentRootNode,
  currentNode,
}) => {
  useEffect(() => {
    Sentry.setUser({
      id: currentUser?.id,
      email: currentUser?.email,
    })

    const sentryCurrentOrganization =
      currentOrganization &&
      `${currentOrganization?.name} (${currentOrganization?.id})`

    const sentryCurrentRoot =
      currentRootNode && `${currentRootNode?.name} (${currentRootNode?.id})`

    const sentryCurrentNode =
      currentNode && `${currentNode?.name} (${currentNode?.id})`

    Sentry.setContext("context details", {
      user: currentUser?.email,
      current_organization: sentryCurrentOrganization,
      current_root: sentryCurrentRoot,
      current_node: sentryCurrentNode,
    })
  }, [currentUser, currentOrganization, currentRootNode, currentNode])
}

export default useSentry
