import React from "react"
import { Modal } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import ParentSelectionForm from "components/widgets/Dashboard/OrganizationChart/ParentSelectionForm"
import { NodeContextProvider } from "./NodeContext"
import useToggle from "hooks/useToggle"
import AddNodeOrganism from "views/organisms/interactive/AddNodeOrganism"
import PropTypes from "prop-types"
import { useOrganizationActionsContext } from "views/molecules/organization/charts/TreeActionsAtom"
import {
  NODE_TYPE_TO_DEFAULT_DATASET,
  PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET,
} from "utils/constants/forms/nodeFormDataset"
import { useAppContext } from "components/AppContext"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import NodeEntity from "services/entities/NodeEntity"
import { useEntity } from "hooks/useAxios"
import useRequest from "hooks/useRequest"

const NodeCreationOrganism = ({
  nodeTypeToFormFields,
  formFieldsToComponents,
  nodeStructure,
}) => {
  const { t } = useTranslation()
  const { onClose } = useOrganizationActionsContext()
  const { subscription } = useAppContext()

  const [parentFormModal, toggleParentFormModal] = useToggle(true)
  const [nodeFormModal, toggleNodeFormModal] = useToggle(false)

  const { reload } = useChart()
  const nodeEntity = useEntity(NodeEntity)
  const { data: childTypes, isLoading: isChildTypesLoading } = useRequest(
    () => nodeEntity.availableChildTypes(nodeStructure?.nodeTypes),
    [nodeStructure?.nodeTypes],
    { condition: !!nodeStructure?.nodeTypes }
  )

  const parentSelectorOnSubmit = () => {
    toggleParentFormModal()
    toggleNodeFormModal()
  }

  return (
    <NodeContextProvider
      nodeTypeToFormFields={nodeTypeToFormFields}
      formFieldsToComponents={formFieldsToComponents}
      defaultDatasets={
        subscription?.is_provider
          ? PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET
          : NODE_TYPE_TO_DEFAULT_DATASET
      }
    >
      <Modal
        open={parentFormModal}
        onClose={onClose}
        title={t(`dashboard:chart.selection_form.modal_title`)}
      >
        <Modal.Section>
          {!isChildTypesLoading && (
            <ParentSelectionForm
              onClick={parentSelectorOnSubmit}
              nodeTypes={childTypes}
              isRoot={nodeStructure?.root}
            />
          )}
        </Modal.Section>
      </Modal>

      {nodeFormModal && (
        <AddNodeOrganism
          open={nodeFormModal}
          onClose={onClose}
          reload={reload}
        />
      )}
    </NodeContextProvider>
  )
}

NodeCreationOrganism.propTypes = {
  formFieldsToComponents: PropTypes.object.isRequired,
  nodeTypeToFormFields: PropTypes.object.isRequired,
  nodeStructure: PropTypes.object.isRequired,
}

export default NodeCreationOrganism
