const UserEntity = (backClient) => ({
  show: async () => await backClient.get(`/api/user`),
  availableProducts: async () =>
    await backClient.get(`/api/user/products/available`),
  update: async (data) => await backClient.put("/api/user", data),
  confirmEmail: async (data) =>
    await backClient.post("/api/confirm_email", data),
})

export default UserEntity
