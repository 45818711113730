import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import "stylesheets/widgets/ScoreValue.scss"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"
import { useTranslation } from "react-i18next"

const CO2EValue = ({ value, pillarId, category, chart, light }) => {
  const { t } = useTranslation()

  const valueConverted = convertValueToReadable(
    value,
    T_CO2E,
    CO2_EMISSION_UNITS,
    (value) => round(value, 0)
  )

  if (!value) return null

  return (
    <div
      className={classNames(pillarId, category, {
        "Verdikt-ScoreValue": true,
        "Verdikt-ScoreValue--disabled": !value,
        co2e: true,
        light: light,
        chart: chart,
      })}
    >
      <div>{valueConverted.value}</div>
      <div></div>
      <div>{t(`units:units.emission.${valueConverted.unit}`)}</div>
    </div>
  )
}

CO2EValue.propTypes = {
  value: PropTypes.number,
  pillarId: PropTypes.string,
  category: PropTypes.string,
  light: PropTypes.bool,
  chart: PropTypes.bool,
}

CO2EValue.defaultProps = {
  score: null,
  category: null,
  light: false,
  chart: false,
  pillarId: "environmental",
}

export default CO2EValue
