import PropTypes from "prop-types"
import { TextChart } from "components/widgets/ScorePage/widgets/TextCharts"
import React from "react"

const NoChartDataOrganism = ({ description }) => {
  const contentForDisplay = (
    <div>
      <p>{description}</p>
    </div>
  )

  return <TextChart content={contentForDisplay} />
}

NoChartDataOrganism.propTypes = {
  description: PropTypes.string.isRequired,
}

export default NoChartDataOrganism
