import React from "react"
import DoughnutChartAtom from "views/atoms/charts/doughnuts/DoughnutChartAtom"
import CountryApp from "services/apps/countryApp"

const NodeDoughnutChartAtom = ({
  nodes = {
    doughnut_charts: [],
    doughnut_charts_data_total: { number: 0, unit: "" },
  },
  unitType = "count",
  title = "",
}) => {
  if (nodes.doughnut_charts.length === 0) return null

  const data = {
    doughnut_charts: nodes.doughnut_charts.map(({ data, label }) => ({
      data,
      label: CountryApp.getNameI18n(label, true),
    })),
    doughnut_charts_data_total: nodes.doughnut_charts_data_total,
  }

  return (
    <DoughnutChartAtom title={title} data={data} unitType={unitType} large />
  )
}

export default NodeDoughnutChartAtom
