import React from "react"
import { Banner, Modal, TextContainer } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ModalDeleteOrganism = ({
  show,
  onClose,
  onDelete,
  errorMessage,
  email,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={show}
      onClose={onClose}
      titleHidden
      title={`${t("common:user_list.delete_modal.actions.delete")} ${email}`}
      primaryAction={{
        content: t("common:user_list.delete_modal.actions.delete"),
        onAction: onDelete,
      }}
      secondaryActions={[
        {
          content: t("common:user_list.delete_modal.actions.cancel"),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          {errorMessage && <Banner status="warning">{errorMessage}</Banner>}
          <p>{`${t("common:user_list.delete_modal.title")} "${email}"`}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

ModalDeleteOrganism.propTypes = {
  show: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

ModalDeleteOrganism.defaultProps = {
  errorMessage: null,
}

export default ModalDeleteOrganism
