import React from "react"
import PropTypes from "prop-types"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import "stylesheets/blocs/molecules/searches/CompareSearchOrganism.scss"
import CompareSearch from "views/molecules/searches/CompareSearch"
import ComparisonList from "views/molecules/searches/ComparisonList"

const CompareSearchOrganism = ({
  parentOrganization,
  onDelete,
  search,
  comparedNodes,
}) => (
  <section className="compareSearchOrganism">
    <BreadcrumbsOrganism parentOrganization={parentOrganization} />

    <CompareSearch search={search} />
    <ComparisonList nodes={comparedNodes} onDelete={onDelete} />
  </section>
)

CompareSearchOrganism.propTypes = {
  parentOrganization: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array.isRequired,
}

export default CompareSearchOrganism
