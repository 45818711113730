import _ from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Radar } from "react-chartjs-2"
import { useParams } from "react-router"
import "stylesheets/ScorePage/RadarChart.scss"
import className from "classnames"
import Config from "assets/configs/score.js"
import { COLORS } from "../../../../utils/constants/colors"

const RadarChart = ({
  title,
  data,
  config,
  titleClass,
  pointColor = (index) => Config.common.colors[index],
}) => {
  const { pillarId } = useParams()
  const generateData = useCallback(() => {
    let labels = data[0].labels
    let radarData = data[0].datasets
    let datasets = []

    radarData.forEach((dataset, index) => {
      const pointBackgroundColors = pointColor(index, dataset.data.datas)

      const data_item = {
        label: dataset.label,
        data: dataset.data.datas,
        backgroundColor: dataset.backgroundColor || `${COLORS.planet.c60}80`,
        borderColor: dataset.borderColor || Config["common"].colors[index],
        pointBackgroundColor:
          dataset.pointBackgroundColor || pointBackgroundColors,
      }
      datasets.push(data_item)
    })

    return {
      labels: labels,
      datasets: datasets,
    }
  }, [data])

  const generateOptions = useCallback(() => {
    let options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
        labels: { boxWidth: 2 },
      },
      scale: {
        ticks: {
          beginAtZero: true,
          fontSize: 10,
          fontColor: COLORS.corporate.primary,
        },
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
          label: (tooltipItem, data) => {
            const { label } = data.datasets[tooltipItem.datasetIndex]
            return `${label} : ${tooltipItem.yLabel} %`
          },
        },
      },
      plugins: {
        datalabels: { display: false },
      },
    }
    return _.merge(options, config)
  }, [config])

  return (
    <div className={className("Verdikt-Score-RadarChart", pillarId)}>
      <h1 className={titleClass}>{title}</h1>
      <div className="content">
        <Radar data={generateData()} options={generateOptions()} />
      </div>
    </div>
  )
}

RadarChart.propTypes = {
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  pointColor: PropTypes.func,
}

RadarChart.defaultProps = {
  config: {},
  titleClass: null,
}

export default RadarChart
