import { TopBar } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  BillingStatementEuroMajor,
  ChecklistAlternateMajor,
  EmailMajor,
  LogOutMinor,
  ProfileMinor,
  SettingsMajor,
} from "@shopify/polaris-icons"
import ExternalBadges from "views/molecules/badges/ExternalBadges"
import { useAppContext } from "components/AppContext"
import useToggle from "hooks/useToggle"
import useAuth0 from "components/auth/useAuth0"
import useAdminRules from "hooks/useAdminRules"

const TopBarAuthenticatedUserMenu = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const { currentUser, currentRootNode, currentOrganization } = useAppContext()
  const { isUserAdmin } = useAdminRules()

  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false)
  const [routes, setRoutes] = useState([])

  const handleSignOut = () =>
    logout({ logoutParams: { returnTo: window.location.origin } })

  useEffect(() => {
    if (currentUser) {
      let defaultRoutes = [
        {
          content: t("common:topbar.user_profile"),
          icon: ProfileMinor,
          url: "/profile",
          position: 1,
        },
        {
          content: t("common:topbar.contact"),
          icon: EmailMajor,
          url: "/contact",
          position: 4,
        },
        {
          content: t("common:topbar.logout"),
          icon: LogOutMinor,
          onAction: handleSignOut,
          position: 5,
        },
      ]
      if (isUserAdmin) {
        defaultRoutes.push(
          {
            content: t("common:topbar.user_list"),
            icon: ChecklistAlternateMajor,
            url: "/users",
            position: 2,
          },
          {
            content: t("common:topbar.subscription"),
            icon: BillingStatementEuroMajor,
            url: "/subscription",
            position: 3,
          }
        )
      }

      const routeSorted = defaultRoutes.sort((a, b) => a.position - b.position)

      setRoutes(routeSorted)
    }
  }, [currentUser, currentRootNode])

  const actions = currentUser?.show_admin_link
    ? [
        {
          items: [
            {
              content: t("common:topbar.admin"),
              icon: SettingsMajor,
              url: "/admin",
            },
          ],
        },
        {
          items: routes,
        },
      ]
    : [{ items: routes }]

  if (currentUser) {
    return (
      <>
        {currentOrganization &&
          currentOrganization.id !== currentUser.organization_id && (
            <ExternalBadges organizationName={currentOrganization.name} />
          )}

        <TopBar.UserMenu
          actions={actions}
          name={currentUser && currentUser.email}
          open={isMenuOpen}
          onToggle={toggleIsMenuOpen}
        />
      </>
    )
  } else {
    return (
      <TopBar.UserMenu
        actions={[]}
        name={""}
        open={isMenuOpen}
        onToggle={toggleIsMenuOpen}
      />
    )
  }
}

export default TopBarAuthenticatedUserMenu
