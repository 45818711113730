import { REPLAY_ALWAYS_VISIBLE, useFeatureFlag } from "hooks/useFeatureFlag"
import { useEffect, useState } from "react"

const useDisabledFeatures = ({
  isAuthenticated,
  currentNode,
  isCurrentRootNodeLoading,
  isCurrentNodeLoading,
  score,
  isScoreLoading,
  envScore,
  isEnvScoreLoading,
  historizationRequest,
  currentOrganization,
  isCurrentOrganizationLoading,
  profitScoreRequest,
  socialScore,
  isSocialScoreLoading,
  currentRootNode,
}) => {
  const [disabledFeatures, setDisabledFeatures] = useState({
    collect: false,
    score: false,
    envScore: false,
    socialScore: false,
    profitScore: false,
    improve: false,
    improveGHG: false,
    improveMaturity: false,
    improveWebsite: false,
    historization: false,
    replay: false,
    compare: false,
    redirectUrl: "/organize",
  })
  const { isFeatureActive } = useFeatureFlag()

  const updateState = () => {
    if (!isAuthenticated || isCurrentRootNodeLoading || isCurrentNodeLoading)
      return

    const nextState = {
      ...disabledFeatures,
      collect: !currentNode || !currentNode.has_collect,
    }
    if (!isScoreLoading) {
      nextState.score = !score || score.failed
      nextState.replay =
        (!score && !isFeatureActive(REPLAY_ALWAYS_VISIBLE)) ||
        currentNode?.light
    }
    if (!isEnvScoreLoading) nextState.envScore = !envScore
    if (
      !(
        isCurrentOrganizationLoading ||
        isEnvScoreLoading ||
        isCurrentOrganizationLoading
      )
    )
      nextState.compare =
        !envScore ||
        !currentRootNode.is_provider ||
        currentOrganization.is_partial_partner
    if (!isSocialScoreLoading) nextState.socialScore = !socialScore
    if (!profitScoreRequest.isLoading)
      nextState.profitScore = !profitScoreRequest.data
    if (!(isEnvScoreLoading || isCurrentRootNodeLoading)) {
      nextState.improveGHG = !envScore || currentRootNode.is_provider
      nextState.improveMaturity = !envScore || currentRootNode.is_provider
      nextState.improveWebsite = !envScore || !envScore?.website_analyse
      nextState.improve = !envScore
    }

    if (!isScoreLoading && !historizationRequest?.isLoading)
      nextState.historization = !historizationRequest?.data

    setDisabledFeatures(nextState)
  }

  useEffect(updateState, [
    currentNode,
    isScoreLoading,
    score,
    isEnvScoreLoading,
    envScore,
    isSocialScoreLoading,
    socialScore,
    profitScoreRequest?.data, // Note: the two lines cannot be merged, it
    profitScoreRequest?.isLoading, //  leads to recursive stack overflow
    historizationRequest?.data,
    historizationRequest?.isLoading,
    currentOrganization,
    isCurrentOrganizationLoading,
  ])

  return disabledFeatures
}

export default useDisabledFeatures
