import { useTranslation } from "react-i18next"
import React from "react"
import Form from "../../Form"
import { FormLayout, TextStyle } from "@shopify/polaris"
import { TextField, YesNoRadio } from "../../fields/CollectFields"
import { ELECTRONIC_WASTE_MODULE } from "utils/constants/collects/modules"
import { Module } from "../../Module"
import { useFormConfig } from "../../FormContext"

const fields = [
  "electronic_waste_recycled_volume",
  "electronic_waste_non_recycled_volume",
  "electronic_waste_registry",
  "electronic_waste_supplier_check",
  "electronic_waste_regulation_knowledge",
  "electronic_waste_document_archiving",
]
const translationPath = "15.electronic_waste"

const WasteForm = () => {
  const { t } = useTranslation()
  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <Module module={ELECTRONIC_WASTE_MODULE}>
        <div className="columns">
          <div className="column">
            <FormLayout>
              {/* <Heading>Cartouches</Heading> */}
              <TextField id="electronic_waste_recycled_volume" type="number" />
              <TextField
                id="electronic_waste_non_recycled_volume"
                type="number"
              />
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <YesNoRadio id="electronic_waste_regulation_knowledge" />
              <YesNoRadio id="electronic_waste_registry" />
              <YesNoRadio id="electronic_waste_document_archiving" />
              <YesNoRadio id="electronic_waste_supplier_check" />
              {/* <Heading>Déchets électroniques</Heading> */}
            </FormLayout>
            <TextStyle variation="subdued">
              <br />
              {t("collect:questions.15.electronic_waste.deee.label")}
            </TextStyle>
          </div>
        </div>
      </Module>
    </Form>
  )
}

export default WasteForm
