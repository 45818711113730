import { Layout, Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import Error from "components/widgets/ScorePage/Error"
import ReportEntity from "services/entities/ReportEntity"
import React, { useEffect, useState } from "react"
import ListReportTemplate from "views/templates/reports/ListReportTemplate"
import DefaultReportTemplate from "views/templates/reports/DefaultReportTemplate"
import { ReportPageContext } from "views/contexts/ReportPageContext"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import { useAppContext } from "components/AppContext"
import ViewerOnlyBanner from "views/molecules/collects/banners/ViewerOnlyBanner"
import withAuthRequired from "../../components/auth/withAuthRequired"
import { useEntity } from "../../hooks/useAxios"
import { useTranslation } from "react-i18next"

const SharePage = () => {
  const { t } = useTranslation()

  const [reports, setReports] = useState([])
  const [reportsLoading, setReportsLoading] = useState(true)

  const [reportsReloading, setReportsReloading] = useState(false)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportRegenerating, setReportRegenerating] = useState(false)

  const [workInProgress, setWorkInProgressBanner] = useState(false)

  const [
    reportRequestResponseBanner,
    setReportRequestResponseBanner,
  ] = useState({})

  const {
    currentPeriod,
    currentRootNode,
    currentNode,
    isCurrentRootNodeLoading,
    score,
    isScoreLoading,
    parentOrganization,
    isParentOrganizationLoading,
  } = useAppContext()
  const reportEntity = useEntity(ReportEntity)

  useEffect(async () => {
    try {
      const reportsRequest = await reportEntity.getReports()
      setReports(reportsRequest)
    } catch (err) {
      console.log("get reports errors", err)
    } finally {
      setReportsLoading(false)
      setReportsReloading(false)
    }
  }, [reportsReloading])

  const templateDispatcher = () => {
    if (
      isParentOrganizationLoading ||
      isCurrentRootNodeLoading ||
      isScoreLoading ||
      reportsLoading
    ) {
      return <Loading />
    } else if (!score) {
      return <DefaultReportTemplate />
    } else if (score.failed) {
      return <Error />
    } else {
      return <ListReportTemplate />
    }
  }

  return (
    <ReportPageContext.Provider
      value={{
        currentRootNode,
        score,
        reports,
        reportsLoading,
        setReportsLoading,
        reportsReloading,
        setReportsReloading,
        reportModalOpen,
        setReportModalOpen,
        reportRegenerating,
        setReportRegenerating,
        reportRequestResponseBanner,
        setReportRequestResponseBanner,
        workInProgress,
        setWorkInProgressBanner,
      }}
    >
      <Page>
        <Layout>
          {currentNode?.viewer_only && <ViewerOnlyBanner />}
          <Layout.Section>
            <BreadcrumbsOrganism
              parentOrganization={parentOrganization}
              currentPeriodName={currentPeriod?.name}
            />
            {templateDispatcher()}
          </Layout.Section>
        </Layout>
      </Page>
    </ReportPageContext.Provider>
  )
}

export default withAuthRequired(SharePage)
