import React from "react"
import ChartProvider from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import PropTypes from "prop-types"
import ChartContainerLoaderOrganism from "views/organisms/organization/ChartContainerLoaderOrganism"

const OrganizationPage = ({ organize }) => {
  return (
    <ChartProvider organize={organize}>
      <ChartContainerLoaderOrganism />
    </ChartProvider>
  )
}

OrganizationPage.propTypes = {
  organize: PropTypes.bool,
}

OrganizationPage.defaultProps = {
  organize: false,
}

export default OrganizationPage
