import PropTypes from "prop-types"
import React from "react"
import "stylesheets/widgets/Dashboard/NodeIcon.scss"

const NodeIconAtom = ({ children, handleChange }) => {
  return (
    <div className={`nodeIcon`} onClick={handleChange}>
      {children}
    </div>
  )
}

NodeIconAtom.propTypes = {
  handleChange: PropTypes.func,
  children: PropTypes.node.isRequired,
}

NodeIconAtom.defaultProps = {
  handleChange: () => null,
}

export default NodeIconAtom
