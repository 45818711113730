import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import InvestmentsForm from "./objective8/Objective8InvestmentsForm"
import PositiveImpactForm from "../PositiveImpactForm"
import EcoDesignForm from "components/widgets/CollectPage/forms/pillars/financial/objective8/Objective8EcoDesignForm"
import ProjectGovernanceForm from "components/widgets/CollectPage/forms/pillars/financial/objective8/ProjectGovernanceForm"
import ProjectPortfolioForm from "components/widgets/CollectPage/forms/pillars/financial/objective8/ProjectPortfolioForm"

const Objective8Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "project_portfolio":
        return <ProjectPortfolioForm />
      case "eco_design":
        return <EcoDesignForm />
      case "investments":
        return <InvestmentsForm />
      case "project_governance":
        return <ProjectGovernanceForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective8Form
