import PropTypes from "prop-types"
import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import React from "react"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"

const translationPrefix = "score:score.7.chart6"
const DC_CLOUD_TYPES = [
  "data_center_internal_inventories",
  "data_center_external_inventories",
  "data_center_cloud_inventories",
]
const REFERENCE_PUE = 1.58

const colorThreshold = (average, reference = REFERENCE_PUE) =>
  average > reference ? COLORS.danger.primary : null

const CONFIG = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const label = data.datasets[tooltipItem.datasetIndex].label
        return `${label} : ${tooltipItem.yLabel}`
      },
    },
  },
}

const ChartOdd7Pue = ({ pueScores }) => {
  const { t } = useTranslation()
  const data = DC_CLOUD_TYPES.map((cloudType) => {
    const average = pueScores[cloudType]?.average
    return {
      label: t(`${translationPrefix}.${cloudType}`),
      data: average,
      reference: REFERENCE_PUE,
      color: colorThreshold(+average),
    }
  })

  return (
    <HelpWidgetChartInteractiveMolecule text={t(translationPrefix + ".help")}>
      <BarChart
        title={t(translationPrefix + ".title")}
        data={data}
        config={CONFIG}
        dataTitle={t("score:score.common.datasets.client_data")}
        referenceTitle={t("score:score.common.datasets.market_data")}
        displayYAxe
        displayLegend
        axesLabel={t(translationPrefix + ".axis_title")}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartOdd7Pue.propTypes = {
  pueScores: PropTypes.objectOf(
    PropTypes.shape({
      average: PropTypes.string,
      coverage: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default ChartOdd7Pue
