import PropTypes from "prop-types"
import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Config from "assets/configs/score.js"
import { RETURN_OBJECTS } from "services/translations/config"

const ChartODD13GHGEmissionOverviewMolecule = ({ score }) => {
  const { t } = useTranslation()

  const { title, help } = t(
    `score:score.13.chartODD13GHGEmissionOverview`,
    RETURN_OBJECTS
  )

  const labels = t(
    "score:score.13.chartGHGEmissionsDistribution",
    RETURN_OBJECTS
  )

  const generateData = useCallback(() => {
    const { data, label_keys, unit } =
      score?.odd13_emissions_breakdown_consolidated_parent || {}

    return data.map((value, index) => {
      return {
        label: labels[label_keys[index]],
        data: value,
        backgroundColor: Config.common.colors[index % data.length],
        unit,
      }
    })
  }, [score])

  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <DoughnutChart title={title} data={generateData()} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13GHGEmissionOverviewMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartODD13GHGEmissionOverviewMolecule
