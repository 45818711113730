import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Select } from "@shopify/polaris"

const NumberFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()

  const getSectorOptions = () => {
    let options = []
    const sectors = t(`activation:company.questions.sector.options`, {
      returnObjects: true,
    })

    for (const sector of Object.keys(sectors)) {
      options.push({
        label: t(`activation:company.questions.sector.options.${sector}`),
        value: sector,
      })
    }

    return options
  }

  return (
    <Select
      label={t(`activation:${type}.questions.${property}.label`)}
      placeholder={t("collect:questions.common.default_select_placeholder")}
      id={property}
      name={property}
      value={value}
      error={error}
      onChange={onChange}
      options={getSectorOptions()}
      disabled={disabled}
    />
  )
}

NumberFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.number,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

NumberFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default NumberFieldAtom
