import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Form,
  FormLayout,
  Layout,
  Page,
  Select,
  TextField,
} from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  jobReachs,
  jobScopes,
  jobTitles,
  ROLE_OVERRIDES,
} from "utils/datas/usersFormDatas"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { useAppContext } from "components/AppContext"

import { useEntity } from "hooks/useAxios"
import useToggle from "hooks/useToggle"
import useAdminRules from "hooks/useAdminRules"

const UserForm = ({
  onSubmit,
  user,
  onCancel,
  errors,
  disabled,
  title,
  description,
  submitTitle,
}) => {
  const { t } = useTranslation()
  const { currentUser, productDetails, currentOrganization } = useAppContext()
  const { isUserOverallAdmin } = useAdminRules()
  const [isExternalOrganization, toggleActive] = useToggle(
    user?.organization?.is_partial_partner
  )

  const organizationEntity = useEntity(OrganizationEntity)
  const [canSwitch, setCanSwitch] = useState(user?.can_switch_product || false)

  const [email, setEmail] = useState(user?.email || "")
  const [firstname, setFirstname] = useState(user?.firstname || "")
  const [lastname, setLastname] = useState(user?.lastname || "")
  const [jobTitle, setJobTitle] = useState(
    user?.job_title || jobTitles[0].value
  )
  const [jobScope, setJobScope] = useState(
    user?.job_scope || jobScopes[0].value
  )
  const [jobReach, setJobReach] = useState(
    user?.job_reach || jobReachs[0].value
  )
  const [roleOverride, setRoleOverride] = useState(
    user?.role_override || ROLE_OVERRIDES[0].value
  )
  const [hasUserManagement, setHasUserManagement] = useState(
    user?.has_user_management ?? false
  )
  const [organizationId, setOrganizationId] = useState(
    `${currentUser.organization_id}`
  )

  const [organizationOptions, setOrganizationOptions] = useState([])

  useEffect(async () => {
    const organizations = await organizationEntity.index()
    const options = organizations.map(({ name, id }) => ({
      label: name,
      value: `${id}`,
    }))
    setOrganizationOptions(options)
    if (organizations.length) {
      setOrganizationId(`${organizations[0].id}`)
    }
  }, [])
  const cleanIfEmpty = (string) => (string === "" ? null : string)
  const showExternal = organizationOptions.length > 0
  const showSwitchProduct = !!(
    productDetails?.has_provider_product &&
    productDetails?.has_corporate_product &&
    !currentOrganization?.is_partial_partner &&
    !isExternalOrganization &&
    currentUser?.can_switch_product
  )
  const keepSelfSwitch = user?.id === currentUser.id

  const handleCheckboxChange = () => {
    if (!keepSelfSwitch) {
      setCanSwitch(!canSwitch)
    }
  }

  const handleSubmit = () => {
    const data = {
      email,
      can_switch_product: canSwitch,
      firstname: cleanIfEmpty(firstname),
      lastname: cleanIfEmpty(lastname),
      organization_id: parseInt(organizationId),
      job_title: cleanIfEmpty(jobTitle),
      job_scope: cleanIfEmpty(jobScope),
      job_reach: cleanIfEmpty(jobReach),
      role_override: cleanIfEmpty(roleOverride),
      has_user_management: hasUserManagement,
      is_external: isExternalOrganization,
    }

    if (user) {
      data.id = user.id
    }

    onSubmit(data)
  }

  const yesNoChoices = [
    { label: t("collect:questions.common.yes"), value: "true" },
    { label: t("collect:questions.common.no"), value: "false" },
  ]

  const externalGroup = showExternal ? (
    <FormLayout.Group>
      <Checkbox
        label={t("common:user_new.form.is_external")}
        checked={isExternalOrganization}
        onChange={toggleActive}
        name="is_external"
      />

      {isExternalOrganization && (
        <Select
          options={organizationOptions}
          onChange={setOrganizationId}
          value={organizationId}
          error={errors?.organization_id}
          disabled={disabled}
        />
      )}
    </FormLayout.Group>
  ) : null

  const switchGroup = showSwitchProduct ? (
    <FormLayout.Group>
      <Checkbox
        label={t("common:user_new.form.can_switch_product")}
        checked={canSwitch}
        onChange={handleCheckboxChange}
        name="can_switch_product"
        disabled={keepSelfSwitch}
      />
    </FormLayout.Group>
  ) : null

  return (
    <Page>
      <Form onSubmit={handleSubmit}>
        <Layout.AnnotatedSection title={title} description={description}>
          <Card>
            <Card.Section>
              <FormLayout.Group>
                <TextField
                  label={`${t("common:user_new.form.email")}*`}
                  type="email"
                  value={email}
                  onChange={setEmail}
                  readOnly={!!user}
                  error={errors?.email?.join(", ")}
                  disabled={disabled}
                />
              </FormLayout.Group>

              {externalGroup}

              {switchGroup}
              <FormLayout.Group>
                <ChoiceList
                  title={t("common:user_new.form.has_user_management")}
                  choices={yesNoChoices}
                  onChange={(value) =>
                    setHasUserManagement(value[0] === "true")
                  }
                  selected={[String(hasUserManagement)]}
                  error={errors?.has_user_management}
                  disabled={disabled || user?.id === currentUser.id}
                />
              </FormLayout.Group>

              {isUserOverallAdmin && (
                <FormLayout.Group>
                  <Select
                    label={t("common:user_new.form.role_override")}
                    options={ROLE_OVERRIDES.map(
                      ({ labelTranslationKey, value }) => ({
                        label: t(labelTranslationKey),
                        value,
                      })
                    )}
                    onChange={setRoleOverride}
                    value={roleOverride}
                    error={errors?.role_override}
                    disabled={disabled}
                  />
                </FormLayout.Group>
              )}
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title={t("common:user_new.personal_details.title")}
          description={t("common:user_new.personal_details.description")}
        >
          <Card>
            <Card.Section>
              <FormLayout.Group>
                <TextField
                  label={t("common:user_new.form.lastname")}
                  type="text"
                  value={lastname}
                  onChange={setLastname}
                  error={errors?.last_name}
                  disabled={disabled}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  label={t("common:user_new.form.firstname")}
                  type="text"
                  value={firstname}
                  onChange={setFirstname}
                  error={errors?.first_name}
                  disabled={disabled}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  label={t("common:user_new.form.job_title")}
                  options={jobTitles.map(({ labelTranslationKey, value }) => ({
                    label: t(labelTranslationKey),
                    value,
                  }))}
                  onChange={setJobTitle}
                  value={jobTitle}
                  error={errors?.job_title}
                  disabled={disabled}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  label={t("common:user_new.form.job_scope")}
                  options={jobScopes.map(({ labelTranslationKey, value }) => ({
                    label: t(labelTranslationKey),
                    value,
                  }))}
                  onChange={setJobScope}
                  value={jobScope}
                  error={errors?.job_scope}
                  disabled={disabled}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  label={t("common:user_new.form.job_reach")}
                  options={jobReachs.map(({ labelTranslationKey, value }) => ({
                    label: t(labelTranslationKey),
                    value,
                  }))}
                  onChange={setJobReach}
                  value={jobReach}
                  error={errors?.job_reach}
                  disabled={disabled}
                />
              </FormLayout.Group>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection>
          <Card>
            <Card.Section>
              <FormLayout.Group>
                {!disabled && (
                  <Button fullWidth primary submit>
                    {submitTitle}
                  </Button>
                )}
                {onCancel && (
                  <Button fullWidth onClick={onCancel}>
                    {t("common:cancel")}
                  </Button>
                )}
              </FormLayout.Group>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Form>
    </Page>
  )
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  user: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  submitTitle: PropTypes.string.isRequired,
}

UserForm.defaultProps = {
  user: null,
  onCancel: null,
  errors: {},
  disabled: false,
}

export default UserForm
