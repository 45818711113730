import React from "react"

import "stylesheets/blocs/templates/themes/ThemeLayoutTemplate.scss"
import PropTypes from "prop-types"
import { PILLARS } from "utils/constants/pillars"
import classNames from "classnames"

const ThemeLayoutOrganism = ({
  tabs,
  objectives,
  children,
  themeType,
  forceSize,
}) => {
  const hasTabs = tabs?.props?.tabs?.some((tab) => !tab.props?.hidden)

  return (
    <section
      className={classNames("themeLayout", themeType, {
        ["themeLayout--forceSize"]: forceSize,
      })}
    >
      <div className="themeLayout__objectives">{objectives}</div>

      <article className="themeLayout__view">
        <div className="themeLayout__tabs">{tabs}</div>

        <div
          className={classNames("themeLayout__content", {
            "themeLayout__content--border": !hasTabs,
          })}
        >
          {children}
        </div>
      </article>
    </section>
  )
}

ThemeLayoutOrganism.propTypes = {
  tabs: PropTypes.element.isRequired,
  objectives: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  themeType: PropTypes.oneOf(PILLARS).isRequired,
  forceSize: PropTypes.bool,
}

ThemeLayoutOrganism.defaultProps = {
  forceSize: false,
}

export default ThemeLayoutOrganism
