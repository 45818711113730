import React from "react"
import { Button, Form, FormLayout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import useAuth0 from "../../../components/auth/useAuth0"

const OktaLoginFormOrganism = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()

  return (
    <Form
      onSubmit={() =>
        loginWithRedirect({ appState: { returnTo: window.location.pathname } })
      }
    >
      <FormLayout>
        <Button id="okta-auth-login" fullWidth primary submit>
          {t("common:login.home.login")}
        </Button>
      </FormLayout>
    </Form>
  )
}

OktaLoginFormOrganism.propTypes = {}

export default OktaLoginFormOrganism
