import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Banner } from "@shopify/polaris"
import { TOAST_STATUS_ERROR } from "utils/constants/toastStatuses"
import { useAppContext } from "components/AppContext"
import {
  BANNER_INFO_STATUS,
  BANNER_SUCCESS_STATUS,
  BANNER_WARNING_STATUS,
} from "utils/constants/alert_banners/alertBannersConstant"
import AllocationEntity from "services/entities/AllocationEntity"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import { useEntity } from "hooks/useAxios"
import ScoreEntity from "services/entities/ScoreEntity"
import { DASHBOARD_PAGE } from "hooks/useRedirection"

const STATUS_READY = "ready"
const IGNORED_STATUSES = ["scored", "collect_not_full"]

const BANNER_STATUSES = {
  already_closed: BANNER_INFO_STATUS,
  not_a_leaf: BANNER_INFO_STATUS,
  missing_partner_nodes: BANNER_WARNING_STATUS,
  waiting_for_partner_scores: BANNER_WARNING_STATUS,
  missing_characteristics: BANNER_WARNING_STATUS,
  missing_period: BANNER_WARNING_STATUS,
  unallocated_provider: BANNER_WARNING_STATUS,
  [STATUS_READY]: BANNER_SUCCESS_STATUS,
  allocation_ready: BANNER_SUCCESS_STATUS,
  allocated: BANNER_SUCCESS_STATUS,
  not_editable_next_node: BANNER_INFO_STATUS,
}

const CollectStatusBanner = () => {
  const { t } = useTranslation()
  const {
    progress,
    collect,
    setCollect,
    setToastStatus,
    setError,
  } = useCollectContext()
  const { reloadScore, redirect } = useAppContext()
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const allocationEntity = useEntity(AllocationEntity)
  const scoreEntity = useEntity(ScoreEntity)

  useEffect(() => setStatus(collect?.status), [collect])

  const close = useCallback(async () => {
    if (status !== STATUS_READY) return

    setLoading(true)
    try {
      await scoreEntity.compute()
      redirect(DASHBOARD_PAGE)
      reloadScore()
      setLoading(false)
    } catch (error) {
      setToastStatus(TOAST_STATUS_ERROR)
      setError(error.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [progress, collect, status, redirect])

  const allocation = useCallback(async () => {
    try {
      setLoading(true)

      const { data } = await allocationEntity.allocate(collect.id, collect.type)

      setCollect(data)
    } catch (err) {
      setToastStatus(TOAST_STATUS_ERROR)
      setError(err?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const STATUS_ACTIONS = {
    [STATUS_READY]: {
      content: t("collect:questions.common.closing.ready.button"),
      loading: isLoading,
      onAction: () => close(),
    },
    allocation_ready: {
      content: t("collect:questions.common.closing.allocation_ready.button"),
      loading: isLoading,
      onAction: () => allocation(),
    },
  }

  if (!status || IGNORED_STATUSES.includes(status)) return null

  return (
    <>
      <Banner
        title={t(`collect:questions.common.closing.${status}.title`)}
        status={BANNER_STATUSES[status]}
        action={STATUS_ACTIONS[status]}
      >
        {t(`collect:questions.common.closing.${status}.message`)}
      </Banner>
      <br />
    </>
  )
}

export default CollectStatusBanner
