const CloudUsageEntity = (backClient) => {
  return {
    index: async () => await backClient.get(`/api/ccf/usages`),
    create: async (data) => await backClient.post(`/api/ccf/usages`, data),
    update: async (id, data) =>
      await backClient.put(`/api/ccf/usages/${id}`, data),
    delete: async (id) => await backClient.delete(`/api/ccf/usages/${id}`),
    data: async (provider) =>
      await backClient.get(`/api/ccf/usages/data/${provider}`),
  }
}

export default CloudUsageEntity
