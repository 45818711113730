import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import UserForm from "views/organisms/user/form/UserForm"
import { Redirect } from "react-router-dom"
import withAuthRequired from "../../components/auth/withAuthRequired"
import { useAppContext } from "../../components/AppContext"
import { useEntity } from "../../hooks/useAxios"
import UsersEntity from "../../services/entities/UsersEntity"
import ThreeSectionsSkeletonPage from "views/pages/skeletons/ThreeSectionsSkeletonPage"

const UserCreationPage = () => {
  const [redirectToUsersList, setRedirectToUsersList] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { currentUser } = useAppContext()
  const usersEntity = useEntity(UsersEntity)

  const { t } = useTranslation()

  const handleSubmit = async (user) => {
    setIsLoading(true)
    try {
      await usersEntity.createUserCompany(user)
      setRedirectToUsersList(true)
    } catch (err) {
      setErrors(err.response?.data)
    } finally {
      setIsLoading(false)
    }
  }

  if (redirectToUsersList) {
    return <Redirect to="/users" />
  }

  if (!currentUser) return <ThreeSectionsSkeletonPage />

  return (
    <UserForm
      title={t("common:user_new.title")}
      description={t("common:user_new.description")}
      submitTitle={t("common:user_new.submit")}
      onSubmit={handleSubmit}
      errors={errors}
    />
  )
}

export default withAuthRequired(UserCreationPage)
