import PropTypes from "prop-types"
import React, { useCallback } from "react"
import "stylesheets/widgets/ScoreIcon.scss"
import classNames from "classnames"
import { default as Seedling } from "assets/images/seedling.svg"
import { default as Herb } from "assets/images/herb.svg"
import { default as Tree } from "assets/images/tree.svg"

const ScoreIcon = ({ score, pillarId }) => {
  const icon = useCallback(() => {
    if (score <= 33) {
      return <img src={Seedling} alt="🌱" width="30" height="25" />
    }
    if (score <= 67) {
      return <img src={Herb} alt="🌿" width="30" height="25" />
    }
    return <img src={Tree} alt="🌳" width="30" height="25" />
  }, [score])

  return (
    <div className={classNames(pillarId, "Verdikt-ScoreIcon")}>{icon()}</div>
  )
}

ScoreIcon.propTypes = {
  score: PropTypes.number.isRequired,
  pillarId: PropTypes.string,
}

export default ScoreIcon
