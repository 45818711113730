const CloudUploadEntity = (backClient) => ({
  upload: async (fileData) => {
    const { data } = await backClient.post(
      "/api/uploads/collects/cloud/billing_export_upload",
      fileData
    )
    return data
  },
})

export default CloudUploadEntity
