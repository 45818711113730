import React from "react"
import { useTranslation } from "react-i18next"
import CreateReportButtonReportAtom from "views/atoms/reports/table/buttons/CreateReportButtonReportAtom"

import "stylesheets/blocs/organisms/tables/ReportsList.scss"

const TableHeaderReportMolecule = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <th>{t("share:content.reports_table.created_at")}</th>
        <th>{t("share:content.reports_table.report_status")}</th>
        <th>{t("share:content.reports_table.report_type")}</th>
        <th>{t("share:content.reports_table.language")}</th>
        <th>{t("share:content.reports_table.environmental_pillar")}</th>
        <th>{t("share:content.reports_table.social_pillar")}</th>
        <th>{t("share:content.reports_table.profit_pillar")}</th>
        <th>{t("share:content.reports_table.pdf_url")}</th>
        <th>
          <CreateReportButtonReportAtom />
        </th>
      </tr>
    </thead>
  )
}

export default TableHeaderReportMolecule
