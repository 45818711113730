import { FormLayout } from "@shopify/polaris"
import Form from "components/widgets/CollectPage/forms/Form"
import React from "react"
import { useTranslation } from "react-i18next"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import { INVENTORY_BUSINESS_ASSETS_INVENTORIES } from "utils/constants/centralization/inventoryItems"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import { Module } from "../../Module"
import { useFormConfig } from "../../FormContext"
import { Select } from "components/widgets/CollectPage/forms/fields/CollectFields"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"

const fields = ["odd13_business_assets_require_life_cycle_analysis"]
const translationPath = "13.business_assets"

const Objective13BusinessAssetsForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_BUSINESS_ASSETS_INVENTORIES}>
                <UploadOrganism
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="inventory_equipment_business"
                      inventoryType={INVENTORY_BUSINESS_ASSETS_INVENTORIES}
                      displayDownloadIcon
                      displayQualityIcon
                    />
                  }
                  decentralisedComponent={
                    <FileField
                      id="decentralized_inventory_equipment_business"
                      translationPathName="inventory_equipment_business"
                      inventoryType={INVENTORY_BUSINESS_ASSETS_INVENTORIES}
                      title={t(
                        "collect:questions.13.network.inventory_equipment_business.label"
                      )}
                      precision={t(
                        "collect:questions.13.network.inventory_equipment_business.precision"
                      )}
                      displayDownloadIcon
                      displayQualityIcon
                      templateSelectionComponent={
                        <TemplateFileLinkMolecule id="decentralized_inventory_equipment_business" />
                      }
                    />
                  }
                />
              </Module>
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <Module module={INVENTORY_BUSINESS_ASSETS_INVENTORIES}>
                <Select
                  id="odd13_business_assets_require_life_cycle_analysis"
                  options={[
                    {
                      label: t(
                        "collect:questions.13.business_assets.odd13_business_assets_require_life_cycle_analysis.options.all"
                      ),
                      value: "all",
                    },
                    {
                      label: t(
                        "collect:questions.13.business_assets.odd13_business_assets_require_life_cycle_analysis.options.only_top_ones"
                      ),
                      value: "only_top_ones",
                    },
                    {
                      label: t(
                        "collect:questions.13.business_assets.odd13_business_assets_require_life_cycle_analysis.options.none"
                      ),
                      value: "none",
                    },
                  ]}
                />
              </Module>
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13BusinessAssetsForm
