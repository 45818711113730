export const WEBSITE_COLLECT_MODULE = "websites"
export const DATACENTER_EXTERNAL_COLLECT_MODULE =
  "data_center_external_inventories"
export const DATACENTER_INTERNAL_COLLECT_MODULE =
  "data_center_internal_inventories"
export const EQUIPMENT_NETWORK_COLLECT_MODULE = "network_equipment_inventories"
export const EQUIPMENT_WORKPLACE_COLLECT_MODULE =
  "workplace_equipment_inventories"
export const EQUIPMENT_BUSINESS_COLLECT_MODULE =
  "business_equipment_inventories"
export const EXTERNAL_APPS_COLLECT_MODULE = "external_apps"
export const INTERNAL_APPS_COLLECT_MODULE = "internal_apps"
export const POSITIVE_IMPACT_MODULE = "positive_impact"
export const CLOUD_COLLECT_MODULE = "ccf_usages"
export const WATER_CONSUMPTION_MODULE = "water_consumption"
export const ELECTRICITY_CONSUMPTION_MODULE = "electricity_consumption"
export const TELCO_MODULE = "telco"
export const PRINTING_MODULE = "printing"
export const ELECTRONIC_WASTE_MODULE = "electronic_waste"
export const MAIL_COLLABORATION_MODULE = "mail_collaboration"
export const BYOD_MODULE = "byod"
export const SDG13_POSITIVE_IMPACT_MODULE = "odd13_positive_impact"
export const SDG12_POSITIVE_IMPACT_MODULE = "odd12_positive_impact"
export const SDG7_POSITIVE_IMPACT_MODULE = "odd7_positive_impact"
export const SDG6_POSITIVE_IMPACT_MODULE = "odd6_positive_impact"
export const SDG15_POSITIVE_IMPACT_MODULE = "odd15_positive_impact"

export const POSITIVE_IMPACT_MODULES = [
  SDG13_POSITIVE_IMPACT_MODULE,
  SDG12_POSITIVE_IMPACT_MODULE,
  SDG7_POSITIVE_IMPACT_MODULE,
  SDG6_POSITIVE_IMPACT_MODULE,
  SDG15_POSITIVE_IMPACT_MODULE,
]

export const DOMAIN_TO_MODULE = {
  workplace: EQUIPMENT_WORKPLACE_COLLECT_MODULE,
  network_security: EQUIPMENT_NETWORK_COLLECT_MODULE,
  infrastructure: DATACENTER_INTERNAL_COLLECT_MODULE,
  business: EQUIPMENT_BUSINESS_COLLECT_MODULE,
}
