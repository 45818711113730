import { Page } from "@shopify/polaris"
import React, { createContext, useContext, useEffect, useState } from "react"
import Loading from "components/widgets/Loading"
import { useTranslation } from "react-i18next"
import Message from "components/widgets/Dashboard/Message"
import { useAppContext } from "components/AppContext"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../auth/withAuthRequired"
import { useEntity } from "../../hooks/useAxios"
import { IMPROVE_WEBSITES_PAGE } from "utils/constants/improve/pages"
import usePillars from "views/organisms/pillars/usePillars"
import RedirectIfDisabled from "views/molecules/RedirectIfDisabled"
import WebsiteRecommendationEntity from "services/entities/WebsiteRecommendationEntity"
import { useHistory, useParams } from "react-router-dom"
import AnalyzeGHGTemplate from "views/templates/AnalyzeGHGTemplate"
import WebsiteRecommendation from "components/widgets/WebsiteImprovePage/WebsiteRecommendation"
import { ErrorBoundary } from "@sentry/react"
import ImproveWebsiteErrorBoundary from "components/ErrorBoundary/ImproveWebsiteErrorBoundary"

export const ImproveContext = createContext()

export function useImproveContext() {
  return useContext(ImproveContext)
}

const ImproveWebsitesPage = () => {
  const { id } = useParams()
  const [recommendations, setRecommendations] = useState(null)
  const { t } = useTranslation()
  const history = useHistory()

  const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(true)

  const {
    currentPeriod,
    isEnvScoreLoading,
    isCurrentNodeLoading,
    currentRootNode,
    isCurrentRootNodeLoading,
    parentOrganization,
  } = useAppContext()
  const recommendationEntity = useEntity(WebsiteRecommendationEntity)

  useEffect(async () => {
    const data = await recommendationEntity.index()

    setRecommendations(data)
    setIsRecommendationsLoading(false)
  }, [])

  const tabs = recommendations?.tabs?.map((tab) => {
    return {
      id: tab.id,
      name: tab.title,
      url: `/improve/websites/${tab.id}`,
      selected: tab.id === +id,
    }
  })

  const firstTabId = tabs?.[0]?.id

  const pillars = usePillars({
    pageName: `/improve/websites/${firstTabId}`,
    [PILLAR_ENVIRONMENTAL]: { disabled: false, selected: true },
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  if (currentRootNode && !currentRootNode.has_children) {
    return <Message messageId="no_node_children" show />
  }

  useEffect(() => {
    if (isRecommendationsLoading || !recommendations) return

    if (!recommendations?.tabs?.length) {
      history.push("/improve/maturity")
      return
    }

    if (!id || !recommendations.websites_audits[id]) {
      history.push(`/improve/websites/${recommendations.tabs[0].id}`)
      return
    }
  }, [recommendations, isRecommendationsLoading, id])

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: `/improve/websites/${firstTabId}`,
    },
  ]

  if (
    isRecommendationsLoading ||
    isEnvScoreLoading ||
    isCurrentNodeLoading ||
    isCurrentRootNodeLoading
  ) {
    return <Loading />
  }

  return (
    <ErrorBoundary fallback={<ImproveWebsiteErrorBoundary />}>
      <ObjectiveNavigationTemplate changeToDefault={false}>
        <RedirectIfDisabled pageName={`/${IMPROVE_WEBSITES_PAGE}`}>
          <Page fullWidth>
            <AnalyzeGHGTemplate
              needEnvScore={false}
              parentOrganization={parentOrganization}
              themeType={PILLAR_ENVIRONMENTAL}
              objectives={objectives}
              pillars={pillars}
              tabs={tabs}
              periodName={currentPeriod?.name}
              forceSize
            >
              <div
                className={`recommendationsLayout recommendationsLayout--environmental`}
              >
                <div className="recommendationsLayout__score"></div>

                <div className="recommendationsLayout__recommendations recommendationsLayout__recommendations--website">
                  <div
                    className={`recommendations recommendations--environmental`}
                  >
                    <div className="recommendations__tableContainer">
                      <table className="recommendations__table recommendations__table--website">
                        <thead>
                          <tr>
                            <th className="recommendations__thText" scope="col">
                              {t(`improve:recommendations_table.header.impact`)}
                            </th>
                            <th className="recommendations__thText" scope="col">
                              {t(
                                `improve:recommendations_table.header.complexity`
                              )}
                            </th>
                            <th className="recommendations__thText" scope="col">
                              {t(
                                `improve:recommendations_table.header.recommendation`
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recommendations?.websites_audits[
                            id
                          ]?.failed_audits?.map((recommendation, index) => (
                            <WebsiteRecommendation
                              key={index}
                              recommendation={recommendation}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </AnalyzeGHGTemplate>
          </Page>
        </RedirectIfDisabled>
      </ObjectiveNavigationTemplate>
    </ErrorBoundary>
  )
}

export default withAuthRequired(ImproveWebsitesPage)
