import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const Objective3Score = ({ score, collect }) => {
  const { t } = useTranslation()
  const firstLoad = useRef(true)
  const { objectiveId } = useParams()
  const translationPrefix = `score:score.${objectiveId}.`

  const socialReference = score.social_references

  useEffect(() => {
    firstLoad.current = false
  })

  const chart1 = useCallback(() => {
    const resignation_input = Number(collect["well_being_resignation_rate"])
    const contractual_terminaison_input = Number(
      collect["well_being_contractual_termination_rate"]
    )
    const non_economic_layoff_input = Number(
      collect["well_being_non_economic_layoff_rate"]
    )
    const attrition_kpi = Math.round(
      Number(
        resignation_input +
          contractual_terminaison_input +
          non_economic_layoff_input
      )
    )

    const attrition_reference = Math.round(
      socialReference["odd3_resignation_rate_IT"]
    )

    const content = {
      kpi: attrition_kpi,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      reference: attrition_reference,
      reference_unit: t(translationPrefix + "chart1.unit"),
      reference_text: t(translationPrefix + "chart1.subtext"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart2 = useCallback(() => {
    const engagement_kpi =
      collect["well_being_opinion_survey_declared_engagement"]
    const engagement_reference = Math.round(
      socialReference["odd3_opinion_engagement_rate_global"]
    )

    const content = {
      kpi: engagement_kpi,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
      reference: engagement_reference,
      reference_unit: t(translationPrefix + "chart2.unit"),
      reference_text: t(translationPrefix + "chart2.subtext"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart3 = useCallback(() => {
    const response_rate_reference =
      socialReference["odd3_working_condition_response_rate_global"]
    const survey_satisfaction_reference =
      socialReference["odd3_working_condition_satisfaction_rate_global"]

    const response_rate_input =
      collect["well_being_working_condition_survey_average_responses"]
    const survey_satisfaction_input =
      collect["well_being_working_condition_survey_satisfaction_rate"]

    let data = [
      {
        label: t(`score:score.3.chart3.response_rate`),
        data: response_rate_input,
        reference: response_rate_reference,
      },
      {
        label: t(`score:score.3.chart3.satisfaction_rate`),
        data: survey_satisfaction_input,
        reference: survey_satisfaction_reference,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart3.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart3.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.recommended")}
          axesLabel={t(translationPrefix + "chart3.y_label")}
          displayYAxe={true}
          displayLegend={true}
          doSortData={false}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart4 = useCallback(() => {
    const average_response_input =
      collect["well_being_opinion_survey_average_response"]
    const average_response_reference =
      socialReference["odd3_opinion_response_rate_global"]
    const declared_engagement_input =
      collect["well_being_opinion_survey_declared_engagement"]
    const declared_engagement_reference =
      socialReference["odd3_opinion_engagement_rate_global"]

    let data = [
      {
        label: t(`score:score.3.chart4.response_rate`),
        data: average_response_input,
        reference: average_response_reference,
      },
      {
        label: t(`score:score.3.chart4.engagement_rate`),
        data: declared_engagement_input,
        reference: declared_engagement_reference,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart4.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.recommended")}
          axesLabel={t(translationPrefix + "chart4.y_label")}
          displayYAxe={true}
          displayLegend={true}
          doSortData={false}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  const chart5 = useCallback(() => {
    const alertSystemExist = collect["well_being_alert_system_employee"]
    const alertSystemCases =
      collect["well_being_alert_system_employee_declaration_number"]
    let message = ""

    if (!alertSystemExist) {
      message = t(`score:score.3.chart5.no_alert_system`)
    } else {
      if (alertSystemCases == 0) {
        message = t(`score:score.3.chart5.no_declarations`)
      } else {
        message = t(`score:score.3.chart5.with_declarations`)
      }
    }

    const content = (
      <div>
        <p>{t(`score:score.3.chart5.title`)}</p>

        <p className="em">{message}</p>
      </div>
    )

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect])

  const chart6 = useCallback(() => {
    const resignation = {
      label: t(`score:score.3.chart6.resignation`),
      data: collect["well_being_resignation_rate"],
      reference: socialReference["odd3_resignation_rate_IT"],
    }
    const non_economic_layoff = {
      label: t(`score:score.3.chart6.non_economic_layoff`),
      data: collect["well_being_non_economic_layoff_rate"],
      reference: socialReference["odd3_non_economic_layoff_rate_global"],
    }
    const conventional_rupture_rate = {
      label: t(`score:score.3.chart6.contractual_termination`),
      data: collect["well_being_contractual_termination_rate"],
      reference: socialReference["odd3_contractual_termination_rate_global"],
    }

    let data = [resignation, non_economic_layoff, conventional_rupture_rate]

    // Sort in decreasing order
    data.sort(function (a, b) {
      return b.data - a.data
    })

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart6.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart6.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.recommended")}
          axesLabel={t(translationPrefix + "chart6.y_label")}
          displayYAxe={true}
          displayLegend={true}
          doSortData={false}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd3_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective3Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective3Score
