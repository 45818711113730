// @todo refactor component and remove forceEnabled -> hotfix
import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import { Select as PSelect } from "@shopify/polaris"
import { checkHover } from "./Functions"
import PropTypes from "prop-types"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import _ from "lodash"

const Select = ({ id, options, onChange, disabled }) => {
  const { t } = useTranslation()
  const {
    formData,
    setFormData,
    disabledFields,
    errors,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()
  const [optionsList, setOptionsList] = useState([])
  const idLastPart = _.last(id.split("."))
  const translationPrefix = `collect:questions.${translationPath}.${idLastPart}.`

  useEffect(() => {
    const optionsWithLabels =
      typeof options[0] === "string"
        ? options.map((value) => ({
            label: t(`${translationPrefix}options.${value}`),
            value,
          }))
        : options
    setOptionsList(optionsWithLabels)
  }, [options])

  const updateValue = (value) => {
    if (onChange) {
      return onChange(value)
    }
    const data = _.cloneDeep(formData)
    _.set(data, id, value)
    setFormData(data)
  }

  return (
    <PSelect
      label={checkHover(translationPrefix)}
      options={optionsList}
      placeholder={t(
        translationPrefix + "placeholder",
        t("collect:questions.common.default_select_placeholder")
      )}
      value={String(_.get(formData, id, "") ?? "")}
      onChange={updateValue}
      error={_.get(errors, id, null)}
      disabled={disabled || isLocked || _.get(disabledFields, id, false)}
    />
  )
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  forceEnabled: PropTypes.bool,
}

Select.defaultProps = {
  forceEnabled: false,
}

export default Select
