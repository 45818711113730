import React, { useEffect, useState } from "react"
import useReassessment from "hooks/useReassessment"
import { useEntity } from "hooks/useAxios"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { useHistory } from "react-router-dom"
import { useAppContext } from "components/AppContext"
import useAdminRules from "hooks/useAdminRules"
import ReplayOrganisms from "views/organisms/replay/ReplayOrganisms"
import ThreeSectionsSkeletonPage from "views/pages/skeletons/ThreeSectionsSkeletonPage"

const reassessmentDefaults = {
  organization: false,
  collectSocialInventory: false,
  collectEnvironmentalInventory: false,
  collectSocialPolitics: false,
  collectEnvironmentalPolitics: false,
  collectProfitPolitics: false,
  collectProfitInventory: false,
  periodName: "",
  periodStartsOn: null,
  periodEndsOn: null,
  versionName: "",
  versionDescription: "",
}

const ReplayPage = () => {
  const [reassessmentAvailable, setReassessmentAvailable] = useState(null)
  const [subscriptionAvailable, setSubscriptionAvailable] = useState(false)
  const organizationEntity = useEntity(OrganizationEntity)
  const history = useHistory()
  const { isRootNodeAdmin } = useAdminRules()
  const {
    reloadCurrentPeriod,
    reloadCurrentNode,
    currentUser,
    currentNode,
    currentRootNode,
    currentOrganization,
  } = useAppContext()

  const {
    data,
    onChange,
    reassess,
    isLoading,
    isError,
    errorType,
  } = useReassessment(reassessmentDefaults, currentRootNode)

  const handleSubmit = () => {
    reassess(() => {
      reloadCurrentPeriod()
      reloadCurrentNode()
      history.push("/organize")
    })
  }

  const reassessmentAvailableRequest = async () => {
    try {
      const { data } = await organizationEntity.getReassessmentAvailable()
      const { reassessment_available, subscription_available } = data

      setReassessmentAvailable(reassessment_available)
      setSubscriptionAvailable(subscription_available)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(reassessmentAvailableRequest, [])

  if (!currentOrganization || !currentRootNode || !currentUser) {
    return <ThreeSectionsSkeletonPage />
  }

  return (
    <ReplayOrganisms
      currentUser={currentUser}
      currentRootNode={currentRootNode}
      reassessmentAvailable={reassessmentAvailable}
      isRootNodeAdmin={isRootNodeAdmin}
      formData={data}
      onSubmit={handleSubmit}
      onChange={onChange}
      isError={isError}
      errorType={errorType}
      isLoading={isLoading || !currentRootNode}
    />
  )
}

export default ReplayPage
