import PropTypes from "prop-types"
import React from "react"
import "stylesheets/ScorePage/TextChart.scss"
import { checkHoverScore } from "components/widgets/CollectPage/forms/fields/Functions.jsx"

export const TextChart = ({ content }) => {
  return (
    <div className="Verdikt-Score-TextChart">
      <div className="Verdikt-Score-TextChart-Content">{content}</div>
    </div>
  )
}

TextChart.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export const TextChartMajor = ({ content, noSubtextHover }) => {
  return (
    <div>
      <p>
        {content.kpi_title}
        <em>
          {checkHoverScore(content)}
          <br />
          &nbsp;{content.kpi}
          &nbsp;{content.kpi_unit}
        </em>
        {content.kpi_unit_suffix && (
          <>
            <br />
            &nbsp;{content.kpi_unit_suffix}
          </>
        )}
      </p>
      <p style={{ marginTop: "10px" }} />
      {content.sub_kpi ? (
        <p>
          {content.sub_kpi}&nbsp;{content.sub_kpi_unit} {content.sub_kpi_text}
        </p>
      ) : null}
      {content.reference != null && (
        <p>
          {content.reference}&nbsp;
          {content.reference_unit}&nbsp;
          {checkHoverScore(content, "subtext", noSubtextHover)}
        </p>
      )}
      {content.sub_reference != null && (
        <p>
          {content.sub_reference}&nbsp;
          {content.sub_reference_unit}&nbsp;
          {checkHoverScore(content, "subtext", noSubtextHover)}
        </p>
      )}
    </div>
  )
}

TextChartMajor.propTypes = {
  content: PropTypes.object.isRequired,
  noSubtextHover: PropTypes.bool,
}

export const TextChartMinor = ({ content, noSubtextHover }) => {
  return (
    <div>
      <p> {checkHoverScore(content)}</p>
      <p className="em">
        {content.kpi}&nbsp;{content.kpi_unit}
      </p>
      {content.sub_kpi ? (
        <p>
          {content.sub_kpi}&nbsp;{content.sub_kpi_unit} {content.sub_kpi_text}
        </p>
      ) : null}
      {content.reference != null && (
        <p>
          <br />
          {content.reference}
          {content.reference_unit}&nbsp;
          {checkHoverScore(content, "subtext", noSubtextHover)}
        </p>
      )}
    </div>
  )
}

TextChartMinor.propTypes = {
  content: PropTypes.object.isRequired,
  noSubtextHover: PropTypes.bool,
}
