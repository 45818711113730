import { Button, Icon, Tooltip } from "@shopify/polaris"
import { CirclePlusMinor } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { useReportPageContext } from "views/contexts/ReportPageContext"

const CreateReportButtonReportAtom = () => {
  const { t } = useTranslation()

  const { setReportModalOpen } = useReportPageContext()

  const reportModalOpen = () => {
    setReportModalOpen(true)
  }

  return (
    <Tooltip
      light
      dismissOnMouseOut
      content={t("share:content.reports_table.tooltips.add_button")}
    >
      <Button plain onClick={() => reportModalOpen(true)}>
        <Icon source={CirclePlusMinor} />
      </Button>
    </Tooltip>
  )
}

export default CreateReportButtonReportAtom
