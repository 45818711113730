import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import Form from "../../Form"
import { FormLayout } from "@shopify/polaris"
import { Select, TextField } from "../../fields/CollectFields"
import FormContextProvider, {
  useFormConfig,
  useFormContext,
} from "../../FormContext"
import WebsiteInventory from "../../inventories/environment/WebsitesInventory"
import useNullifyingField from "../../../useNullifyingField"

const fields = [
  "inclusion_IT_senior_rate",
  "inclusion_IT_disabled_employee_rate",
  "inclusion_IT_available_jobs_for_disabled_rate",
  "inclusion_IT_inclusion_and_accessibility_policy",
  "upload_detailed_inventory",
]
const translationPath = "10.diversity_and_inclusion"

const Inclusion = () => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  const [disabled, setDisabled] = useState(false)

  useFormConfig({ fields, translationPath })

  useEffect(() => setDisabled(formData.upload_detailed_inventory), [formData])

  useNullifyingField("upload_detailed_inventory", [
    "inclusion_IT_senior_rate",
    "inclusion_IT_disabled_employee_rate",
  ])

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <FormLayout>
            <TextField
              id="inclusion_IT_senior_rate"
              type="number"
              disabled={disabled}
            />
            <TextField
              id="inclusion_IT_disabled_employee_rate"
              type="number"
              disabled={disabled}
            />
            <TextField
              id="inclusion_IT_available_jobs_for_disabled_rate"
              type="number"
            />
          </FormLayout>
        </div>
        <div className="column">
          <div>
            <FormContextProvider>
              <WebsiteInventory />
            </FormContextProvider>
            <br />
          </div>
          <Select
            id="inclusion_IT_inclusion_and_accessibility_policy"
            options={[
              {
                label: t(
                  "collect:questions.10.diversity_and_inclusion.inclusion_IT_inclusion_and_accessibility_policy.options.no_policy"
                ),
                value: "no_policy",
              },
              {
                label: t(
                  "collect:questions.10.diversity_and_inclusion.inclusion_IT_inclusion_and_accessibility_policy.options.policy_defined"
                ),
                value: "policy_defined",
              },
              {
                label: t(
                  "collect:questions.10.diversity_and_inclusion.inclusion_IT_inclusion_and_accessibility_policy.options.policy_applied"
                ),
                value: "policy_applied",
              },
              {
                label: t(
                  "collect:questions.10.diversity_and_inclusion.inclusion_IT_inclusion_and_accessibility_policy.options.policy_controlled"
                ),
                value: "policy_controlled",
              },
            ]}
          />
        </div>
      </div>
    </Form>
  )
}

export default Inclusion
