import { Card, Checkbox, Form, FormLayout, Layout } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import useAutofillCollects, {
  AUTOFILL_ENV,
  AUTOFILL_PROFIT,
  AUTOFILL_SOCIAL,
} from "hooks/useAutofillCollects"
import { useAppContext } from "components/AppContext"

const UserProfileDemoCard = () => {
  const { t } = useTranslation()

  const { currentUser } = useAppContext()

  const {
    setAutofill,
    autofillEnv,
    autofillSocial,
    autofillProfit,
    canSeeAutoFillOptions,
  } = useAutofillCollects(currentUser)

  return !canSeeAutoFillOptions ? null : (
    <Layout.AnnotatedSection
      title={t("user_profile:demo.title")}
      description={t("user_profile:demo.description")}
    >
      <Card>
        <Form>
          <Card.Section>
            <FormLayout>
              <Checkbox
                label={t("user_profile:demo.autofill.env_label")}
                checked={autofillEnv}
                onChange={() => setAutofill(AUTOFILL_ENV, !autofillEnv)}
              />
              <Checkbox
                label={t("user_profile:demo.autofill.social_label")}
                checked={autofillSocial}
                onChange={() => setAutofill(AUTOFILL_SOCIAL, !autofillSocial)}
              />
              <Checkbox
                label={t("user_profile:demo.autofill.profit_label")}
                checked={autofillProfit}
                onChange={() => setAutofill(AUTOFILL_PROFIT, !autofillProfit)}
              />
            </FormLayout>
          </Card.Section>
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default UserProfileDemoCard
