import PropTypes from "prop-types"
import React from "react"
import "stylesheets/blocs/organisms/organizationCharts/BreadcrumbOrganizationChartOrganism.scss"
import NodeOrganization from "services/organizations/NodeOrganization"
import classNames from "classnames/bind"

const ItemBreadcrumbOrganizationChartOrganism = ({
  node,
  firstItem,
  isLastItem,
  onClick,
  disabled,
}) => (
  <span
    className={classNames(
      `organizationPath__content__node organizationPath__content__node-${node.organization_category}`,
      {
        "organizationPath__content__node--disabled": disabled,
      }
    )}
  >
    {!firstItem && <span> &gt; </span>}
    <span
      className="organizationPath__content__node__name"
      onClick={() => !disabled && !isLastItem && onClick(node.id)}
    >
      {NodeOrganization.getName(node?.name, node?.type)}
      {node?.version_name && ` (${node.version_name})`}
    </span>
  </span>
)

ItemBreadcrumbOrganizationChartOrganism.propTypes = {
  node: PropTypes.object.isRequired,
  firstItem: PropTypes.bool,
  isLastItem: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ItemBreadcrumbOrganizationChartOrganism.defaultProps = {
  firstItem: false,
  isLastItem: false,
  disabled: false,
}

export default ItemBreadcrumbOrganizationChartOrganism
