import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { default as EnFlag } from "assets/images/en.png"
import { default as FrFlag } from "assets/images/fr.png"

const LanguageIcon = ({ language }) => {
  switch (language) {
    case 'en':
      return <img src={EnFlag} alt="🇬🇧" width="18" height="12"/>
    case 'fr':
      return <img src={FrFlag} alt="🇫🇷" width="18" height="12"/>
    default:
      return
  }
}

LanguageIcon.propTypes = {
  language: PropTypes.string.isRequired,
}

export default LanguageIcon
