import React from "react"
import { Link } from "@shopify/polaris"

import CookieConsent from "react-cookie-consent"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"

const STYLE = {
  background: COLORS.profit.primary,
  boxShadow: "0 -5px 10px rgba(0,0,0,0.25)",
}

const BUTTON_STYLE = {
  background: COLORS.black,
  color: COLORS.white,
  border: "solid 1px #fff",
  borderRadius: "5px",
}

const CookieBanner = () => {
  const { t } = useTranslation()

  return (
    <CookieConsent
      buttonText={t("common:cookie.actions.close")}
      cookieName="verdiktCookieConsent"
      style={STYLE}
      buttonStyle={BUTTON_STYLE}
    >
      {t("common:cookie.text")}
      &nbsp;
      <Link monochrome url="/privacy">
        {t("common:cookie.actions.link")}
      </Link>
      .
    </CookieConsent>
  )
}

export default CookieBanner
