import React from "react"
import { useTranslation } from "react-i18next"
import { Banner, Layout, Page } from "@shopify/polaris"

export default () => {
  const { t } = useTranslation()

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner
            status="critical"
            title={t("score:error.title")}
            action={{
              content: t("score:error.cta"),
              url: "/contact",
            }}
          >
            {t("score:error.message")}
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  )
}
