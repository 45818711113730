import React from "react"
import PropTypes from "prop-types"
import ExternalCompanyBadgeAtom from "views/atoms/badges/ExternalCompanyBadgeAtom"
import { Button, Icon, Tooltip } from "@shopify/polaris"
import UserStateEntity from "services/entities/UserStateEntity"
import { HomeMajor } from "@shopify/polaris-icons"
import { COLORS } from "utils/constants/colors"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"
import { useAppContext } from "components/AppContext"
import { useHistory } from "react-router-dom"

const ExternalBadges = ({ organizationName }) => {
  const { t } = useTranslation()

  const { reloadCurrentRoot } = useAppContext()

  const userStateEntity = useEntity(UserStateEntity)
  const history = useHistory()

  return (
    <>
      <ExternalCompanyBadgeAtom organizationName={organizationName} />
      <Tooltip
        light
        dismissOnMouseOut
        content={t("organization:client_organization.back")}
      >
        <Button
          size="large"
          plain
          monochrome
          onClick={async () => {
            await userStateEntity.resetOrganization()

            reloadCurrentRoot()
            if (history.location.pathname !== "/organize")
              history.push("/dashboard")
          }}
          icon={<Icon source={HomeMajor} color={COLORS.danger.dark} />}
        />
      </Tooltip>
    </>
  )
}

ExternalBadges.propTypes = {
  organizationName: PropTypes.string.isRequired,
}

export default ExternalBadges
