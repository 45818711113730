import React from "react"
import PropTypes from "prop-types"
import HypothesesMolecule from "views/molecules/recommendations/hypotheses/HypothesesMolecule"
import { useTranslation } from "react-i18next"

const RecommendationDetailsConfig = ({ proposition }) => {
  const { t } = useTranslation()
  return (
    <td colSpan="2" className="recommendationsLayout__details__config">
      <div>{t("recommendation:propositions.hypotheses.title")}</div>
      <HypothesesMolecule
        proposition={proposition}
        onChange={(value) => console.log(value)}
      />
    </td>
  )
}

RecommendationDetailsConfig.propTypes = {
  proposition: PropTypes.shape({
    pillar: PropTypes.string.isRequired,
    sdg: PropTypes.number.isRequired,
    topic: PropTypes.string.isRequired,
    kpi: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    lever: PropTypes.string.isRequired,
    impact: PropTypes.string.isRequired,
    complexity: PropTypes.string.isRequired,
    simulation_detail: PropTypes.object.isRequired,
  }).isRequired,
}

export default RecommendationDetailsConfig
