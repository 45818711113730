// @todo refactor component and remove forceEnabled -> hotfix
import { useTranslation } from "react-i18next"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { TextField as PTextField } from "@shopify/polaris"
import { checkHover } from "./Functions"
import PropTypes from "prop-types"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"

export const TextField = ({
  id,
  type,
  onChange,
  suffix,
  disabled,
  multiline,
  step,
  warning,
}) => {
  const { t } = useTranslation()
  const {
    formData,
    setFormData,
    disabledFields,
    errors,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const [value, setValue] = useState(_.get(formData, id, ""))
  const [isUpdated, setIsUpdated] = useState(false)

  const idLastPart = _.last(id.split("."))
  const translationPrefix = `collect:questions.${translationPath}.${idLastPart}.`

  useEffect(() => {
    setValue(_.get(formData, id, ""))
  }, [formData, _.get(formData, id)])

  const onBlur = () => {
    if (isUpdated) {
      if (onChange) {
        onChange(value)
      } else {
        const data = _.cloneDeep(formData)
        _.set(data, id, value)

        setFormData(data)
      }
    }
  }

  return (
    <>
      <PTextField
        label={checkHover(translationPrefix)} //
        type={type}
        suffix={suffix ?? t(translationPrefix + "suffix", "")}
        placeholder={t(translationPrefix + "placeholder", "")}
        value={String(value !== null ? value : "")}
        onChange={(val) => {
          setValue(val)
          setIsUpdated(true)
        }}
        onBlur={onBlur}
        error={_.get(errors, id, null)}
        disabled={isLocked || disabled || _.get(disabledFields, id, false)}
        multiline={multiline}
        step={step}
      />
      {warning && <p className="field-warning">{warning}</p>}
    </>
  )
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.number,
  step: PropTypes.number,
  warning: PropTypes.string,
}

TextField.defaultProps = {
  translationPath: "",
  warning: null,
}

export default TextField
