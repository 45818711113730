import { checkHover } from "./Functions"
import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "../FormContext"

const ExternalLabel = ({ translationPathName, pathOverride }) => {
  const { translationPath } = useFormContext()
  const path = pathOverride ? pathOverride : translationPath

  const translationPrefix =
    translationPathName === ""
      ? `collect:questions.${path}.`
      : `collect:questions.${path}.${translationPathName}.`

  const isExternalLabel = true

  return (
    <div className="Polaris-Labelled__LabelWrapper">
      {checkHover(translationPrefix, isExternalLabel, translationPathName)}
    </div>
  )
}

ExternalLabel.propTypes = {
  children: PropTypes.node,
  translationPathName: PropTypes.string.isRequired,
  pathOverride: PropTypes.string,
}

export default ExternalLabel
