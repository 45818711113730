import { COLORS } from "utils/constants/colors"

export const BAR_CHART_OPTIONS_DEFAULTS = {
  plugins: {
    datalabels: {
      color: COLORS.white,
      font: {
        weight: "bold",
      },
    },
  },
}

export const valueInKEuro = (value) => `${value} k€`
