import PropTypes from "prop-types"
import { Button, Icon, Tooltip } from "@shopify/polaris"
import { RefreshMinor } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { useReportPageContext } from "views/contexts/ReportPageContext"
import ReportEntity from "services/entities/ReportEntity"
import { useEntity } from "../../../../../hooks/useAxios"

const RegenerateReportButtonReportAtom = ({ report }) => {
  const { t } = useTranslation()

  const {
    reportRegenerating,
    setReportRegenerating,
    reportsReloading,
    setReportsReloading,
    setReportRequestResponseBanner,
  } = useReportPageContext()
  const reportEntity = useEntity(ReportEntity)

  const reportRegeneratedRequest = async () => {
    try {
      await reportEntity.updateReport(report)
      setReportRegenerating(!reportRegenerating)
    } catch (err) {
      setReportRequestResponseBanner(err.response.data)
    } finally {
      setReportsReloading(!reportsReloading)
    }
  }

  if (report.report_status === "failed") {
    return (
      <Tooltip
        light
        dismissOnMouseOut
        content={t("share:content.reports_table.tooltips.regenerate_button")}
      >
        <Button plain onClick={() => reportRegeneratedRequest()}>
          <Icon source={RefreshMinor} />
        </Button>
      </Tooltip>
    )
  } else {
    return ""
  }
}

RegenerateReportButtonReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default RegenerateReportButtonReportAtom
