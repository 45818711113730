import { useCallback, useEffect, useState } from "react"
import {
  PILLAR_ENVIRONMENTAL,
  PILLARS_OBJECTIVES,
} from "utils/constants/pillars"

const DEFAULT_PILLAR = null
const DEFAULT_OBJECTIVE = null
export const DEFAULT_URI = `${PILLAR_ENVIRONMENTAL}/13`

const findPillar = (objective) => {
  return (
    Object.keys(PILLARS_OBJECTIVES).find((pillar) => {
      return PILLARS_OBJECTIVES[pillar].includes(parseInt(objective))
    }) || DEFAULT_PILLAR
  )
}

const useObjectiveNavigation = () => {
  const [pillar, setPillar] = useState(PILLAR_ENVIRONMENTAL)
  const [objective, setObjective] = useState(DEFAULT_OBJECTIVE)
  const [objectiveUri, setObjectiveUri] = useState(DEFAULT_URI)

  useEffect(() => setPillar(findPillar(objective)), [objective])

  useEffect(() => setObjectiveUri(objective ? `${pillar}/${objective}` : ""), [
    pillar,
    objective,
  ])

  const reset = useCallback(() => {
    setObjective(DEFAULT_OBJECTIVE)
    setPillar(DEFAULT_PILLAR)
    setObjectiveUri(DEFAULT_URI)
  }, [])

  return {
    pillarNavigation: pillar,
    objectiveNavigation: objective,
    updateObjectiveNavigation: setObjective,
    objectiveUri,
    reset,
  }
}

export default useObjectiveNavigation
