import { FormLayout, TextStyle } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Form from "components/widgets/CollectPage/forms/Form"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  INVENTORY_NETWORK_EQUIPMENT_INVENTORIES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
} from "utils/constants/centralization/inventoryItems"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import {
  EQUIPMENT_NETWORK_COLLECT_MODULE,
  TELCO_MODULE,
} from "utils/constants/collects/modules"
import { Module } from "../../Module"
import { useFormConfig } from "../../FormContext"
import SnowUploadOrganism from "views/organisms/upload/SnowUploadOrganism"
import { useCollectContext } from "../../../CollectContextProvider"
import i18next from "i18next"
import TemplateFileSelectionMolecule from "views/molecules/files/TemplateFileSelectionMolecule"
import {
  DECENTRALIZED_NETWORK_FILE_PATHS,
  DECENTRALIZED_NETWORK_FLEX_FILE_PATHS,
} from "utils/constants/templates"
import { FLEX_INVENTORY_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"

const equipmentsInTable = ["smartphone", "tablet", "box"]

const fields = [
  "telco_smartphone_period_co2",
  "telco_smartphone_period_data",
  "telco_smartphone_amount",
  "telco_smartphone_low_network_ratio",
  "telco_smartphone_high_network_ratio",
  "telco_tablet_period_co2",
  "telco_tablet_period_data",
  "telco_tablet_amount",
  "telco_tablet_low_network_ratio",
  "telco_tablet_high_network_ratio",
  "telco_box_period_co2",
  "telco_box_period_data",
  "telco_box_amount",
  "telco_box_low_network_ratio",
  "telco_box_high_network_ratio",
  "telco_subscription_policy",
  "telco_wifi_policy",
]
const translationPath = "13.network"

const Objective13NetworkForm = () => {
  const { t } = useTranslation()
  const { collect } = useCollectContext()
  const { ifFeatureActive } = useFeatureFlag()

  useFormConfig({ fields, translationPath })

  const tableRowMarkup = (question) => {
    let columns = []
    equipmentsInTable.forEach((equipment, index) => {
      if (equipment != "box")
        columns.push(
          <td key={`table_column_${question}_${index}`}>
            <TextField
              id={`telco_${equipment}_${question}`}
              suffix={t(
                `collect:questions.13.network.table.${question}.suffix`,
                ""
              )}
              type="number"
            />
          </td>
        )
      else
        columns.push(
          <td key={`table_column_${question}_${index}`}>
            <TextField
              id={`telco_${equipment}_${question}`}
              suffix={t(
                `collect:questions.13.network.table.${question}.suffix_box`,
                ""
              )}
              type="number"
            />
          </td>
        )
    })
    return (
      <tr>
        <td>{t(`collect:questions.13.network.table.${question}.label`)}</td>
        {columns}
      </tr>
    )
  }

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <Module module={EQUIPMENT_NETWORK_COLLECT_MODULE}>
              <SnowUploadOrganism
                translationPathName="inventory_equipment_network"
                inventoryType={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}
              >
                <UploadOrganism
                  centralization={collect?.centralization}
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="inventory_equipment_network"
                      inventoryType={INVENTORY_NETWORK_EQUIPMENT_INVENTORIES}
                      displayDownloadIcon
                      displayQualityIcon
                    />
                  }
                  decentralisedComponent={
                    <FileField
                      id="decentralized_inventory_equipment_network"
                      translationPathName="inventory_equipment_network"
                      inventoryType={INVENTORY_NETWORK_EQUIPMENT_INVENTORIES}
                      title={t(
                        "collect:questions.13.network.inventory_equipment_network.label"
                      )}
                      precision={t(
                        "collect:questions.13.network.inventory_equipment_network.precision"
                      )}
                      displayDownloadIcon
                      displayQualityIcon
                      templateSelectionComponent={ifFeatureActive(
                        FLEX_INVENTORY_FEATURE,
                        <TemplateFileSelectionMolecule
                          fileId="decentralized_inventory_equipment_network"
                          structuredFilePath={
                            DECENTRALIZED_NETWORK_FILE_PATHS[i18next.language]
                          }
                          flexFilePath={
                            DECENTRALIZED_NETWORK_FLEX_FILE_PATHS[
                              i18next.language
                            ]
                          }
                        />,
                        <TemplateFileLinkMolecule id="decentralized_inventory_equipment_network" />
                      )}
                    />
                  }
                />
              </SnowUploadOrganism>
            </Module>
          </div>
          <div className="column column-23">
            <Module module={TELCO_MODULE}>
              <FormLayout>
                <div>
                  <ExternalLabel translationPathName={"shared.table"} />
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          {t("collect:questions.13.network.table.column_1")}
                        </th>
                        <th>
                          {t("collect:questions.13.network.table.column_2")}
                        </th>
                        <th>
                          {t("collect:questions.13.network.table.column_3")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableRowMarkup("period_data")}
                      {tableRowMarkup("period_co2")}
                      {tableRowMarkup("amount")}
                      {tableRowMarkup("low_network_ratio")}
                      {tableRowMarkup("high_network_ratio")}
                    </tbody>
                  </table>
                </div>
                <TextStyle variation="subdued">
                  {t("collect:questions.common.nota_bene")}
                </TextStyle>
                <MultipleChoiceField
                  id="policies"
                  choices={["telco_subscription_policy", "telco_wifi_policy"]}
                />
              </FormLayout>
            </Module>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13NetworkForm
