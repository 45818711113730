import Config from "assets/configs/score.js"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Bar } from "react-chartjs-2"
import "stylesheets/ScorePage/BarChart.scss"
import { useParams } from "react-router"
import classNames from "classnames"
import { COLORS } from "utils/constants/colors"
import { LINE_LEGEND_PLUGIN } from "services/charts/plugins/legend"

export const dataLabelsWithUnits = (unit) => ({
  plugins: {
    datalabels: {
      display: true,
      color: COLORS.white,
      formatter: (value) => `${value} ${unit}`,
    },
  },
})

const MixedChart = ({
  title,
  data_inputs,
  labels,
  unit_left_axis,
  unit_right_axis,
  barBackgroundColors,
  legendModifiers,
  config,
  afterBody,
}) => {
  const { pillarId } = useParams()
  const generateData = useCallback(() => {
    const data = {
      labels: labels,
      datasets: [
        {
          type: "bar",
          label: data_inputs.bar_data.label,
          data: data_inputs.bar_data.data,
          backgroundColor: barBackgroundColors,
          datalabels: {
            color: COLORS.white,
            font: { weight: "bold" },
          },
          borderWidth: 1,
          yAxisID: "y-axis-1",
          order: 2,
          suffix: data_inputs.bar_data.suffix,
        },
        {
          type: "line",
          label: data_inputs.line_data.label,
          data: data_inputs.line_data.data,
          borderColor: Config.common.colors[1],
          borderWidth: 2,
          fill: false,
          datalabels: { display: false },
          yAxisID: "y-axis-2",
          order: 1, // Line chart put first so that we can see the line above the bar chart
          suffix: data_inputs.line_data.suffix,
        },
      ],
    }

    return data
  }, [data_inputs])

  const generateOptions = useCallback(() => {
    let options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
      },
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-2",
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: unit_left_axis != null,
              labelString: unit_left_axis,
            },
          },
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-1",
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: unit_right_axis != null,
              labelString: unit_right_axis,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            if (data.datasets[tooltipItem.datasetIndex].label != "") {
              return `${data.datasets[tooltipItem.datasetIndex].label} : ${
                tooltipItem.yLabel
              } ${
                data.datasets[tooltipItem.datasetIndex].type == "line"
                  ? unit_left_axis ?? ""
                  : unit_right_axis ?? ""
              }`
            } else {
              return `${tooltipItem.yLabel} ${
                data.datasets[tooltipItem.datasetIndex].type == "line"
                  ? unit_left_axis ?? ""
                  : unit_right_axis ?? ""
              }`
            }
          },
          afterBody,
        },
      },
      ...config,
    }
    return options
  }, [unit_right_axis, unit_left_axis, config])

  const generatePlugins = useCallback(() => {
    if (!legendModifiers) {
      return []
    }

    return [
      {
        beforeDraw(c) {
          legendModifiers.forEach((modifier, index) =>
            modifier(c.legend.legendItems[index])
          )
        },
      },
    ]
  }, [legendModifiers])

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar
          data={generateData()}
          options={generateOptions()}
          plugins={[...generatePlugins(), LINE_LEGEND_PLUGIN]}
        />
      </div>
    </div>
  )
}

MixedChart.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  data_inputs: PropTypes.object.isRequired,
  unit_left_axis: PropTypes.string.isRequired,
  unit_right_axis: PropTypes.string.isRequired,
  barBackgroundColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  legendModifiers: PropTypes.arrayOf(PropTypes.func),
  config: PropTypes.object,
  afterBody: PropTypes.func,
}

MixedChart.defaultProps = {
  barBackgroundColors: Config.common.colors[0],
  legendModifiers: null,
  config: {},
  afterBody: () => {},
}

export default MixedChart
