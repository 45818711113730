import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import { ExternalLabel, TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import { FormLayout } from "@shopify/polaris"
import { useFormConfig, useFormContext } from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"

const fields = [
  "recruitment_job_offers_per_year",
  "recruitment_rate",
  "recruitment_managerial_employee_rate",
  "recruitment_indefinite_term_contract_rate",
  "recruitment_fixed_term_contract_rate",
  "recruitment_work_study_rate",
  "recruitment_trainee_rate",
  "upload_detailed_inventory",
]
const translationPath = "10.recruitment"

const Recruitment = () => {
  const { t } = useTranslation()
  const { formData, setErrors } = useFormContext()
  const [disabled, setDisabled] = useState(false)

  useFormConfig({ fields, translationPath })

  useEffect(() => setDisabled(formData.upload_detailed_inventory), [formData])

  useEffect(() => {
    if (
      formData.recruitment_indefinite_term_contract_rate &&
      formData.recruitment_fixed_term_contract_rate &&
      formData.recruitment_work_study_rate &&
      formData.recruitment_trainee_rate
    ) {
      let sum =
        Number(formData.recruitment_indefinite_term_contract_rate) +
        Number(formData.recruitment_fixed_term_contract_rate) +
        Number(formData.recruitment_work_study_rate) +
        Number(formData.recruitment_trainee_rate)
      if (sum !== 100) {
        let error_wording = "collect:questions.common.error_sum"
        let error = {
          recruitment_indefinite_term_contract_rate: [t(error_wording)],
          recruitment_fixed_term_contract_rate: [t(error_wording)],
          recruitment_work_study_rate: [t(error_wording)],
          recruitment_trainee_rate: [t(error_wording)],
        }
        setErrors(error)
      } else {
        setErrors({})
      }
    }
  }, [formData])

  useNullifyingField("upload_detailed_inventory", [
    "recruitment_rate",
    "recruitment_managerial_employee_rate",
    "recruitment_indefinite_term_contract_rate",
    "recruitment_fixed_term_contract_rate",
    "recruitment_work_study_rate",
    "recruitment_trainee_rate",
  ])

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <tbody>
          <tr>
            <td>{t("collect:questions.10.recruitment.table.row_1")}</td>
            <td>
              <TextField
                id="recruitment_indefinite_term_contract_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.recruitment.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.recruitment.table.row_2")}</td>
            <td>
              <TextField
                id="recruitment_fixed_term_contract_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.recruitment.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.recruitment.table.row_3")}</td>
            <td>
              <TextField
                id="recruitment_work_study_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.recruitment.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.recruitment.table.row_4")}</td>
            <td>
              <TextField
                id="recruitment_trainee_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.recruitment.table.suffix")}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <FormLayout>
            <TextField id="recruitment_job_offers_per_year" type="number" />
            <TextField
              id="recruitment_rate"
              type="number"
              disabled={disabled}
            />
          </FormLayout>
        </div>
        <div className="column column-23">
          <FormLayout>
            <TextField
              id="recruitment_managerial_employee_rate"
              type="number"
              disabled={disabled}
            />
            <ExternalLabel translationPathName={"table"} />
            {tableMarkup()}
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default Recruitment
