import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit";
import { convertEmissionValue } from "services/units/valueConversion"
import { round } from "services/units/round"
import { T_CO2E } from "services/units/units"

const TextChartODD13GHGEmissionsMajorDCMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const data = score?.odd13_internal_hosting_ghg_emissions_average || {}

  const valueConverted = convertEmissionValue(data?.median_big?.total, T_CO2E, round)

  const medianBigContent = {
    kpi: valueConverted.value,
    kpi_unit: t(`units:units.emission.${valueConverted.unit}`),
    kpi_text: t(`${translationPrefix}chartGHGEmissionsMajorDC.median_big_text`),
    reference: "*" + data?.median_big?.average,
    reference_unit: <Unit unit={t(
        `${translationPrefix}chartGHGEmissionsMajorDC.reference_unit`
    )} />,
    reference_text: t(`${translationPrefix}chartGHGEmissionsMajorDC.subtext`),
  }

  const contentForDisplay = <TextChartMinor content={medianBigContent} />

  return <TextChart content={contentForDisplay} />
}

TextChartODD13GHGEmissionsMajorDCMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13GHGEmissionsMajorDCMolecule
