import React from "react"
import { FormLayout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import {
  ExternalLabel,
  FileField,
  MultipleChoiceField,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import Form from "components/widgets/CollectPage/forms/Form"
import { isInvertedChoice } from "components/widgets/CollectPage/useDisablingFields"
import { INVENTORY_PARTNERS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import UploadOrganism from "views/organisms/upload/UploadOrganism"

const fields = [
  "partnerships_sourcing_dc_specific_location",
  "partnerships_sourcing_dc_specific_renewable_energy",
  "partnerships_sourcing_dc_specific_planet_impacts",
  "partnerships_sourcing_dc_specific_sustainable_assets_mngt",
  "partnerships_sourcing_dc_specific_ecc",
  "partnerships_sourcing_refurbishment_partner",
  "partnerships_sourcing_csr_clauses_importance",
  "partnerships_sourcing_corporate_labels",
  "partnerships_sourcing_csr_labels",
  "partnerships_sourcing_cdp_declaration",
  "partnerships_sourcing_csr_reporting",
  "partnerships_sourcing_cdr_commitments",
  "partnerships_sourcing_others",
  "partnerships_sourcing_impact_assessment",
  "partnerships_equipments_choice_none",
  "partnerships_equipments_choice_label",
  "partnerships_equipments_choice_repair",
  "partnerships_equipments_choice_commitments",
  "partnerships_equipments_choice_impact",
  "partnerships_consumables_choice_none",
  "partnerships_consumables_choice_label",
  "partnerships_consumables_choice_commitments",
  "partnerships_consumables_green_percentage",
  "partnerships_equipments_green_percentage",
  "partnerships_sourcing_software_eco_design_trained",
  "partnerships_sourcing_software_accessibility_trained",
  "partnerships_sourcing_software_eco_design_by_design",
  "partnerships_sourcing_software_accessibility_by_design",
  "partnerships_sourcing_software_it_sustainability_approach",
  "partnerships_hosting_green_percentage",
  "partnerships_software_green_percentage",
]
const translationPath = "17.sourcing"

const disableConfig = [
  {
    disablingField: "it_providers_refurbishment_partner",
    fieldsToDisable: ["it_providers_refurbishment_partner_is_ess"],
  },
  {
    disablingField: "partnerships_consumables_choice_none",
    condition: isInvertedChoice,
    fieldsToDisable: [
      "partnerships_consumables_choice_label",
      "partnerships_consumables_choice_commitments",
    ],
  },
  {
    disablingField: "partnerships_equipments_choice_none",
    condition: isInvertedChoice,
    fieldsToDisable: [
      "partnerships_equipments_choice_label",
      "partnerships_equipments_choice_repair",
      "partnerships_equipments_choice_commitments",
      "partnerships_equipments_choice_impact",
    ],
  },
]

const Objective17SourcingForm = () => {
  useFormConfig({ fields, translationPath, disableConfig })

  const { t } = useTranslation()
  const tPrefix = `collect:questions.${translationPath}`

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_PARTNERS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <UploadOrganism
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="partners"
                      inventoryType={INVENTORY_PARTNERS}
                      displayDownloadIcon
                    />
                  }
                  decentralisedComponent={
                    <FileField
                      id="decentralized_inventory_partners"
                      title="partners"
                      inventoryType={INVENTORY_PARTNERS}
                      translationPathName="partners"
                      templateSelectionComponent={
                        <TemplateFileLinkMolecule id="decentralized_inventory_partners" />
                      }
                    />
                  }
                />
              </FormLayout>
            </div>
            <div className="column column-23">
              <FormLayout>
                <ExternalLabel translationPathName="suppliers_criteria" />
                <table>
                  <thead>
                    <tr>
                      <td />
                      {[
                        "consumables",
                        "assets",
                        "dc_criteria",
                        "software_criteria",
                      ].map((name, index) => (
                        <td key={index}>
                          {t(`${tPrefix}.suppliers_criteria.${name}`)}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.suppliers_criteria.criteria`)}</td>
                      <td>
                        <MultipleChoiceField
                          id="consumables_choices"
                          choices={[
                            "partnerships_consumables_choice_none",
                            "partnerships_consumables_choice_label",
                            "partnerships_consumables_choice_commitments",
                          ]}
                        />
                      </td>
                      <td>
                        <MultipleChoiceField
                          id="equipments_choices"
                          choices={[
                            "partnerships_equipments_choice_none",
                            "partnerships_equipments_choice_label",
                            "partnerships_equipments_choice_repair",
                            "partnerships_equipments_choice_commitments",
                            "partnerships_equipments_choice_impact",
                          ]}
                        />
                      </td>
                      <td>
                        <MultipleChoiceField
                          id="dc_criteria"
                          choices={[
                            "partnerships_sourcing_dc_specific_location",
                            "partnerships_sourcing_dc_specific_renewable_energy",
                            "partnerships_sourcing_dc_specific_planet_impacts",
                            "partnerships_sourcing_dc_specific_sustainable_assets_mngt",
                            "partnerships_sourcing_dc_specific_ecc",
                          ]}
                        />
                      </td>
                      <td>
                        <MultipleChoiceField
                          id="partnerships_sourcing_software"
                          choices={[
                            "partnerships_sourcing_software_eco_design_trained",
                            "partnerships_sourcing_software_accessibility_trained",
                            "partnerships_sourcing_software_eco_design_by_design",
                            "partnerships_sourcing_software_accessibility_by_design",
                            "partnerships_sourcing_software_it_sustainability_approach",
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.suppliers_criteria.sustainable_percentage`
                        )}
                      </td>
                      <td>
                        <TextField
                          id="partnerships_consumables_green_percentage"
                          type="number"
                          suffix="%"
                        />
                      </td>
                      <td>
                        <TextField
                          id="partnerships_equipments_green_percentage"
                          type="number"
                          suffix="%"
                        />
                      </td>
                      <td>
                        <TextField
                          id="partnerships_hosting_green_percentage"
                          type="number"
                          suffix="%"
                        />
                      </td>
                      <td>
                        <TextField
                          id="partnerships_software_green_percentage"
                          type="number"
                          suffix="%"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <YesNoRadio id="partnerships_sourcing_refurbishment_partner" />
                <TextField
                  id="partnerships_sourcing_csr_clauses_importance"
                  type="number"
                />
                <MultipleChoiceField
                  id="sourcing_criteria"
                  choices={[
                    "partnerships_sourcing_corporate_labels",
                    "partnerships_sourcing_csr_labels",
                    "partnerships_sourcing_cdp_declaration",
                    "partnerships_sourcing_csr_reporting",
                    "partnerships_sourcing_cdr_commitments",
                    "partnerships_sourcing_others",
                  ]}
                />
                <YesNoRadio id="partnerships_sourcing_impact_assessment" />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default Objective17SourcingForm
