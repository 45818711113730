import React, { useState } from "react"
import { Badge } from "@shopify/polaris"
import Recommendation from "components/widgets/ImprovePage/Recommendation"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import WebsiteRecommendationTable from "components/widgets/WebsiteImprovePage/WebsiteRecommendationTable"
import WebsiteRecommendationDescription from "components/widgets/WebsiteImprovePage/WebsiteRecommendationDescription"

const WebsiteRecommendation = ({ recommendation }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const {
    impact,
    complexity,
    key,
    context,
    resources,
    details,
  } = recommendation

  const header = details?.header || []
  const rows = details?.rows || []

  return (
    <>
      <Recommendation
        impact={impact}
        complexity={complexity}
        pillar={PILLAR_ENVIRONMENTAL}
        domain="website"
        recoKey={key}
        theme={context}
        objective={null}
        description={
          <WebsiteRecommendationDescription
            title={t(`recommendation:websites.audits.${key}.title`)}
            description={t(`recommendation:websites.audits.${key}.description`)}
          />
        }
        resources={resources}
        additionalBadges={
          <>
            <Badge>
              {t(`improve:recommendations_table.domain.${context}`)}
            </Badge>
          </>
        }
        sdg={13}
        onSelectRecommendation={header.length ? () => setOpen(!open) : null}
      />
      <WebsiteRecommendationTable open={open} header={header} rows={rows} />
    </>
  )
}

WebsiteRecommendation.propTypes = {
  recommendation: PropTypes.object.isRequired,
}

export default WebsiteRecommendation
