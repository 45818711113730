import Config from "assets/configs/score.js"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Doughnut } from "react-chartjs-2"
import "stylesheets/ScorePage/DoughnutChart.scss"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import classNames from "classnames"
import { removeEmptyData } from "utils/functions/charts/ChartsFunctions"
import { COLORS } from "utils/constants/colors"

const DoughnutChart = ({ title, data, config, centerText, centerTextUnit }) => {
  const { t } = useTranslation()
  const { pillarId } = useParams()

  let label_size_max = ""

  const generateData = useCallback(() => {
    let labels = []
    let doughnutData = []
    let colors = []
    let unit = ""

    data = removeEmptyData(data)

    data.forEach((value, index) => {
      if (
        value?.label?.match(t("common:equipment_types.switch")) ||
        value?.label?.match(t("common:equipment_types.server")) ||
        value?.label?.match(t("common:equipment_types.router")) ||
        value?.label?.match(
          t("score:score.13.chart6.other_external_data_volume")
        ) ||
        value?.label?.match(
          t("score:score.13.chart6.other_internal_data_volume")
        )
      ) {
        labels.push(value.label)
        doughnutData.push(+value.data)
        if (value.color) {
          colors.push(value.color)
        } else {
          colors.push(
            Config.common.colors_infra[
              index % Config.common.colors_infra.length
            ]
          )
        }
      } else {
        labels.push(value.label)
        doughnutData.push(+value.data)
        if (value.color) {
          colors.push(value.color)
        } else {
          colors.push(Config.common.colors[index % Config.common.colors.length])
        }
      }

      unit = value.unit
    })

    const datasets = [
      {
        data: doughnutData,
        unit: unit,
        plain_data: data.map((value) => value.plain_data),
        backgroundColor: colors.map((color) => color + "FF"),
        hoverBackgroundColor: colors.map((color) => color + "BB"),
      },
    ]

    for (const label of labels) {
      if ((label || "").length > (label_size_max || "").length) {
        label_size_max = label
      }
    }

    return {
      labels: labels,
      datasets: datasets,
    }
  }, [data])

  const generateOptions = useCallback(() => {
    let options = {
      maintainAspectRatio: false,
      cutoutPercentage: 66.7,
      legend: {
        position: "right",
        display: true,
        fullWidth: true,
        labels: {
          boxWidth: 10,
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const label = data.labels[tooltipItem.index]
            const dataset = data.datasets[tooltipItem.datasetIndex]
            const value = dataset.data[tooltipItem.index]
            const unit = dataset.unit || centerTextUnit || ""

            return `${label} : ${value} ${unit}`
          },
        },
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0
            let dataArr = ctx.chart.data.datasets[0].data
            dataArr.map((data) => {
              sum += data
            })
            let percentage = ((value * 100) / sum).toFixed(0)
            if (percentage > 1) return percentage + "%"
            else return ""
          },
          display: true,
          color: COLORS.white,
          font: {
            weight: "bold",
          },
        },
      },
    }
    return _.merge(options, config)
  }, [config])

  const plugins = centerText
    ? [
        {
          beforeDraw: function (chart) {
            var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx

            const label_size = ctx.measureText(label_size_max).width

            ctx.restore()

            ctx.font = "700 1.7em sans-serif"
            ctx.textBaseline = "top"
            ctx.fontStyle = "bold"
            ctx.fillStyle = COLORS.planet.c70

            const text = `${centerText}${
              centerTextUnit ? ` ${centerTextUnit}` : ""
            }`

            const doughnutWidth = width - label_size

            const text_X = doughnutWidth / 2 - ctx.measureText(text).width / 1.5

            var text_Y = height / 2.2
            var textX = text_X,
              textY = text_Y
            ctx.fillText(text, textX, textY)
            ctx.save()
          },
        },
      ]
    : []

  return (
    <div className={classNames("Verdikt-Score-DoughnutChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Doughnut
          data={generateData()}
          options={generateOptions()}
          plugins={plugins}
        />
      </div>
    </div>
  )
}

DoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  centerText: PropTypes.string,
  centerTextUnit: PropTypes.string,
}

DoughnutChart.defaultProps = {
  config: {},
  centerText: "",
  centerTextUnit: "",
}

export default DoughnutChart
