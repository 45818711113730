import { ResourceList, Stack } from "@shopify/polaris"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { round } from "services/units/round"

const DISCARDED_EQUIPMENT_TYPES_ID = "equipment_type_discarded_count"
const DISCARDED_NODES_ID = "node_discarded_count"

const PostTreatmentResultOrganism = ({ fileQuality }) => {
  const { t } = useTranslation()

  const items = useCallback(() => {
    const baseItems = [
      {
        id: "final_total_count",
        title: t(`collect:qualities.analytics.final_total_count`),
        amount: fileQuality?.final_total_count,
      },
      {
        id: DISCARDED_EQUIPMENT_TYPES_ID,
        title: t(`collect:qualities.analytics.equipment_type_discarded_count`),
        amount: fileQuality?.equipment_type_discarded_count,
      },
    ]

    if (fileQuality?.node_discarded_count ?? 0 > 1)
      baseItems.push({
        id: DISCARDED_NODES_ID,
        title: t(`collect:qualities.analytics.node_discarded_count`),
        amount: fileQuality?.node_discarded_count,
        hidden: fileQuality?.node_discarded_count ?? 0 === 0,
      })

    return baseItems
  }, [fileQuality])

  const discardedEquipments = useCallback(
    () =>
      fileQuality?.equipment_types_discarded
        ?.map(
          (equipmentType) =>
            `${equipmentType?.equipment_type} (${round(
              equipmentType?.percent
            )}%)`
        )
        .join(", "),
    [fileQuality]
  )

  const discardedNodes = useCallback(
    () =>
      fileQuality?.discarded_nodes
        .map((node) => `${node?.node_path} (${node?.percent}%)`)
        .join(", "),
    [fileQuality]
  )

  return (
    <ResourceList
      items={items()}
      renderItem={({ id, title, amount }) => (
        <ResourceList.Item id={id}>
          <Stack>
            <Stack.Item fill>
              <b>{title}</b>
            </Stack.Item>
            <Stack.Item>{amount}</Stack.Item>
          </Stack>

          <br />

          <p className="text text--italic">
            {id === DISCARDED_EQUIPMENT_TYPES_ID && discardedEquipments()}
            {id === DISCARDED_NODES_ID && discardedNodes()}
          </p>
        </ResourceList.Item>
      )}
    />
  )
}

PostTreatmentResultOrganism.propTypes = {
  fileQuality: PropTypes.object.isRequired,
}

export default PostTreatmentResultOrganism
