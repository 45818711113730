import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import InternalInnovationForm from "./objective9/Objective9InternalInnovationForm"
import PositiveImpactForm from "../PositiveImpactForm"
import GovernanceForm from "components/widgets/CollectPage/forms/pillars/financial/objective9/Objective9GovernanceForm"
import InnovationPortfolioForm from "components/widgets/CollectPage/forms/pillars/financial/objective9/InnovationPortfolioForm"

const Objective9Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "innovation_portfolio":
        return <InnovationPortfolioForm />
      case "governance":
        return <GovernanceForm />
      case "internal_innovation":
        return <InternalInnovationForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective9Form
