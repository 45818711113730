import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import {
  AWS_BILLING_EXPORTS,
  GCP_BILLING_EXPORTS,
  INVENTORY_BUDGETS,
  INVENTORY_BUSINESS_ASSETS_INVENTORIES,
  INVENTORY_DATA_CENTER_CLOUD_INVENTORIES,
  INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES,
  INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES,
  INVENTORY_EXTERNAL_APPS,
  INVENTORY_INNOVATIONS,
  INVENTORY_INTERNAL_APPS,
  INVENTORY_NETWORK_EQUIPMENT_INVENTORIES,
  INVENTORY_PARTNERS,
  INVENTORY_PROJECTS,
  INVENTORY_SOCIAL_PEOPLE_INVENTORIES,
  INVENTORY_TECH_TEAM_INVENTORIES,
  INVENTORY_WEBSITES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
} from "utils/constants/centralization/inventoryItems"
import {
  UPLOAD_DATA_CENTER_CLOUD_INVENTORIES_FILES_STATUS,
  UPLOAD_DATA_CENTER_EXTERNAL_INVENTORIES_FILES_STATUS,
  UPLOAD_DATA_CENTER_INTERNAL_INVENTORIES_FILES_STATUS,
  UPLOAD_EXTERNAL_APPS_FILES_STATUS,
  UPLOAD_INTERNAL_APPS_FILES_STATUS,
  UPLOAD_INVENTORY_EQUIPMENT_NETWORK_FILES_STATUS,
  UPLOAD_INVENTORY_EQUIPMENT_WORKPLACE_FILES_STATUS,
  UPLOAD_INVENTORY_SOCIAL_FILES_STATUS,
  UPLOAD_INVENTORY_WEBSITE_FILES_STATUS,
  UPLOAD_TECH_TEAM_INVENTORY_FILES_STATUS,
} from "utils/constants/uploads/uploadFileStatus"
import {
  DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE,
  DECENTRALIZED_INVENTORY_EQUIPMENT_NETWORK,
  DECENTRALIZED_INVENTORY_EQUIPMENT_WORKPLACE,
  DECENTRALIZED_INVENTORY_EXTERNAL_APPS,
  DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE,
  DECENTRALIZED_INVENTORY_EXTERNAL_EQUIPMENT_WORKPLACE,
  DECENTRALIZED_INVENTORY_INTERNAL_APPS,
  DECENTRALIZED_INVENTORY_SOCIAL,
} from "utils/constants/collects/centralizationFilesId"
import {
  IMPORT_STATUS_COMPLETED,
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_UPLOADED,
  IMPORT_STATUS_WAITING_FOR_FILE,
  IMPORT_STATUS_WORK_IN_PROGRESS,
} from "utils/constants/imports/importStatus"

/**
 *
 * @param {String} nodeLevel "company" | "brand" | "region" | "country"
 * @param {String} fileKey
 * @returns {String} The file id for a given organizationanl type and inventory (see app/services/uploads/collects/enums/files_id_enum.rb)
 */
export const getCentralizedFileId = (nodeLevel, fileKey) => {
  if (nodeLevel === NODE_TYPE_SITE) {
    throw new Error("A site can't be centralized")
  }

  switch (fileKey) {
    case INVENTORY_WEBSITES:
      return `centralized_${nodeLevel}_inventory_website`

    case INVENTORY_DATA_CENTER_CLOUD_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_datacenter_cloud`

    case INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_datacenter_external`

    case INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_datacenter_internal`

    case INVENTORY_TECH_TEAM_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_digital_team_commute`

    case INVENTORY_NETWORK_EQUIPMENT_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_equipment_network`

    case INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_equipment_workplace`

    case INVENTORY_BUSINESS_ASSETS_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_equipment_business`

    case INVENTORY_EXTERNAL_APPS:
      return `centralized_${nodeLevel}_inventory_external_apps`

    case INVENTORY_INTERNAL_APPS:
      return `centralized_${nodeLevel}_inventory_internal_apps`

    case INVENTORY_SOCIAL_PEOPLE_INVENTORIES:
      return `centralized_${nodeLevel}_inventory_social`

    case INVENTORY_BUDGETS:
      return `centralized_${nodeLevel}_inventory_budgets`

    case INVENTORY_PROJECTS:
      return `centralized_${nodeLevel}_inventory_projects`

    case INVENTORY_INNOVATIONS:
      return `centralized_${nodeLevel}_inventory_innovations`

    case INVENTORY_PARTNERS:
      return `centralized_${nodeLevel}_inventory_partners`

    case AWS_BILLING_EXPORTS:
      return AWS_BILLING_EXPORTS

    case GCP_BILLING_EXPORTS:
      return GCP_BILLING_EXPORTS

    default:
      throw new Error(`File key '${fileKey}' not found`)
  }
}

/**
 * @description Translate the file processing status to fit the Polaris Badge status prop
 * @param {String} processingStatus "waiting_for_file" | "status_uploaded" | "status_work_in_progress" |
 * "status_completed" | "status_error"
 * @returns {String} "warning" | "success" | "new"
 */
export const fileStatusToBadgeStatus = (processingStatus) => {
  switch (processingStatus) {
    case IMPORT_STATUS_WAITING_FOR_FILE:
      return "warning"

    case IMPORT_STATUS_UPLOADED:
      return "info"

    case IMPORT_STATUS_WORK_IN_PROGRESS:
      return "attention"

    case IMPORT_STATUS_COMPLETED:
      return "success"

    case IMPORT_STATUS_ERROR:
      return "critical"

    default:
      return "new"
  }
}

export const getDecentralizedFileNameFromId = (fileId) => {
  switch (fileId) {
    case DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE:
    case DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE:
      return UPLOAD_TECH_TEAM_INVENTORY_FILES_STATUS

    case DECENTRALIZED_INVENTORY_EQUIPMENT_NETWORK:
      return UPLOAD_INVENTORY_EQUIPMENT_NETWORK_FILES_STATUS

    case DECENTRALIZED_INVENTORY_EQUIPMENT_WORKPLACE:
    case DECENTRALIZED_INVENTORY_EXTERNAL_EQUIPMENT_WORKPLACE:
      return UPLOAD_INVENTORY_EQUIPMENT_WORKPLACE_FILES_STATUS

    case DECENTRALIZED_INVENTORY_EXTERNAL_APPS:
      return UPLOAD_EXTERNAL_APPS_FILES_STATUS

    case DECENTRALIZED_INVENTORY_INTERNAL_APPS:
      return UPLOAD_INTERNAL_APPS_FILES_STATUS

    case DECENTRALIZED_INVENTORY_SOCIAL:
      return UPLOAD_INVENTORY_SOCIAL_FILES_STATUS

    default:
      return fileId
  }
}

export const getCentralizedFileNameFromFileType = (fileType) => {
  switch (fileType) {
    case INVENTORY_WEBSITES:
      return UPLOAD_INVENTORY_WEBSITE_FILES_STATUS

    case INVENTORY_DATA_CENTER_CLOUD_INVENTORIES:
      return UPLOAD_DATA_CENTER_CLOUD_INVENTORIES_FILES_STATUS

    case INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES:
      return UPLOAD_DATA_CENTER_EXTERNAL_INVENTORIES_FILES_STATUS

    case INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES:
      return UPLOAD_DATA_CENTER_INTERNAL_INVENTORIES_FILES_STATUS

    case INVENTORY_TECH_TEAM_INVENTORIES:
      return UPLOAD_TECH_TEAM_INVENTORY_FILES_STATUS

    case INVENTORY_NETWORK_EQUIPMENT_INVENTORIES:
      return UPLOAD_INVENTORY_EQUIPMENT_NETWORK_FILES_STATUS

    case INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES:
      return UPLOAD_INVENTORY_EQUIPMENT_WORKPLACE_FILES_STATUS

    case INVENTORY_EXTERNAL_APPS:
      return UPLOAD_EXTERNAL_APPS_FILES_STATUS

    case INVENTORY_INTERNAL_APPS:
      return UPLOAD_INTERNAL_APPS_FILES_STATUS

    case INVENTORY_SOCIAL_PEOPLE_INVENTORIES:
      return UPLOAD_INVENTORY_SOCIAL_FILES_STATUS

    default:
      return fileType
  }
}

export const getCentralizationFileStatusName = (fileInventoryType) => {
  return `${fileInventoryType}_file_status`
}
