import React, { useCallback } from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import _ from "lodash"
import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import i18n from "i18next"
import Config from "assets/configs/score"
import {
  KPI_STATUS_DISABLED,
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"
import { COLORS } from "utils/constants/colors"
import { round } from "services/units/round"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import { Bar } from "react-chartjs-2"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import classNames from "classnames"
import { DEFAULT_OPTIONS } from "services/charts/default"
import ChartBarStackedGroupedMolecule from "views/molecules/canvases/charts/common/ChartBarStackedGroupedMolecule"
import { useAppContext } from "components/AppContext"
import {
  chart6DatalabelsFormatter,
  Chart6Options,
} from "views/organisms/canvases/charts/analyzes/historization/HistorizationLabelsHelpers"
import ChartRadarMolecule from "views/molecules/canvases/charts/common/ChartRadarMolecule"
import { chart4Options } from "views/organisms/canvases/charts/analyzes/historization/HistorizationAnalyzeChartsOrganism"

const prefixTranslation = "analyze:compare."

const chart3Labels = () => [
  {
    text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.lowest"),
    fillStyle: Config.common.colorsEvaluation.green,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.flat"),
    fillStyle: Config.common.colorsEvaluation.yellow2,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
  {
    text: i18n.t(prefixTranslation + "charts.chart3.titleLabels.highest"),
    fillStyle: Config.common.colorsEvaluation.red,
    strokeStyle: COLORS.white,
    lineWidth: 1,
  },
]
const chart3statusColors = {
  [KPI_STATUS_UP]: Config.common.colorsEvaluation.green,
  [KPI_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow2,
  [KPI_STATUS_DOWN]: Config.common.colorsEvaluation.red,
  [KPI_STATUS_DISABLED]: Config.common.disabledColor,
}
const chart3statusColor = (status) => chart3statusColors[status]

const colors = {
  lowest: {
    backgroundColor: "rgba(100, 164, 108, 0.5)",
    borderColor: Config.common.colorsEvaluation.green,
    pointBackgroundColor: Config.common.colorsEvaluation.green,
  },
  medium: {
    backgroundColor: "rgba(255, 218, 32, 0.5)",
    borderColor: Config.common.colorsEvaluation.yellow,
    pointBackgroundColor: Config.common.colorsEvaluation.yellow,
  },
  highest: {
    backgroundColor: "rgba(199, 0, 57, 0.5)",
    borderColor: Config.common.colorsEvaluation.red,
    pointBackgroundColor: Config.common.colorsEvaluation.red,
  },
}

const colorByPosition = (hasFirstPosition, hasLastPosition, hasOneNode) => {
  if (hasFirstPosition) return colors.lowest
  if (hasLastPosition && hasOneNode) return colors.medium
  if (hasLastPosition) return colors.highest
  return colors.medium
}

const nameByType = ({ name, type, version_name }) => {
  let label = NodeOrganization.getName(name, type)

  if (version_name) label = `${label} (${version_name})`

  return label
}

const NodeAnalysesOrganism = ({ comparedScores, comparedNodes }) => {
  const { currentNode } = useAppContext()
  const { t } = useTranslation()

  const hasOneNode = comparedNodes.length === 1

  const chart1 = useCallback(() => {
    const { datasets, unit_key } = comparedScores.chart1
    const unit = t(`units:units.emission.${unit_key}`)

    const data = datasets.map(({ name, value }, index) => {
      const hasFirstPosition = index === 0
      const hasLastPosition = index === datasets.length - 1
      return {
        label: compareNodeLabels(name),
        data: value,
        unit,
        color: colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode)
          .borderColor,
      }
    })

    if (!datasets.length) return {}

    return {
      data,
      axesLabel: unit,
    }
  }, [comparedScores])

  const chart2 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart2

    const maturities = datasets
      .map(({ maturity }) => +maturity)
      .sort()
      .reverse()

    const cleaned_maturities = _.uniq(maturities)

    const formattedDatasets = datasets.map(({ label, data, maturity }) => {
      const position = cleaned_maturities.findIndex((m) => m === +maturity)
      const hasFirstPosition = position === 0
      const hasLastPosition = position === cleaned_maturities.length - 1

      return {
        label: compareNodeLabels(label),
        data,
        ...colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode),
      }
    })

    return {
      labels,
      datasets: formattedDatasets,
    }
  }, [comparedScores])

  const chart3 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart3
    return {
      labels: labels.map(compareNodeLabels),
      datasets,
    }
  }, [comparedScores])

  const chart3Broker = useCallback(() => {
    const {
      labels,
      datasets,
      grouping_key,
      unit_key,
    } = comparedScores.ghg_emissions_breakdown_workplace
    const unit = t(`units:units.emission.${unit_key}`)

    const data = datasets.map(({ label_key, data, percentages, statuses }) => {
      return {
        label:
          grouping_key === "equipment_type"
            ? t(`common:equipment_types.${label_key}`)
            : label_key,
        data: percentages,
        backgroundColor: statuses.map(chart3statusColor),
        tooltipValues: data,
        stack: true,
        unit,
        showLabelInData: true,
      }
    })

    const { label, help } = t(prefixTranslation + "charts.chart3", {
      returnObjects: true,
    })

    return (
      <HelpWidgetChartInteractiveMolecule text={help}>
        <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
          <h1>{label}</h1>
          <div className="content">
            <Bar
              data={{ datasets: data, labels: labels.map(compareNodeLabels) }}
              options={{
                ...DEFAULT_OPTIONS,
                scales: {
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "%",
                      },
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                      },
                    },
                  ],
                },
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    generateLabels: chart3Labels(),
                  },
                },
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [comparedScores])

  const kp1 = (
    <TextChart
      content={
        <TextChartMajor
          content={{
            kpi: compareNodeLabels(comparedScores.kpi1.name),
            kpi_text: t(prefixTranslation + "charts.kpi1.title"),
          }}
        />
      }
    />
  )

  const kpi2 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi2.name)}
                <br /> {comparedScores.kpi2.delta}
              </>
            ),
            kpi_unit: "%",
            kpi_text: t(
              prefixTranslation + "charts.kpi2.lowest_ghg_emission_node.title"
            ),
            reference: round(comparedScores.kpi2.value.value),
            reference_unit: (
              <>
                &nbsp;
                <Unit
                  unit={NumberFormatterApp.getI18nUnit(
                    EMISSION_UNIT,
                    comparedScores.kpi2.value.unit
                  )}
                />
              </>
            ),
          }}
        />
      }
    />
  )

  const kpi3 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi3.name)}
                <br />
                {comparedScores.kpi3.delta >= 0 ? "+" : "-"}
                {comparedScores.kpi3.delta}
              </>
            ),
            kpi_text: t(
              prefixTranslation + "charts.kpi3.best_maturity_node.title"
            ),
            reference: `${comparedScores.kpi3.maturity} / 100`,
          }}
        />
      }
    />
  )

  return (
    <ITDomainAnalysisLayoutOrganism
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={`${prefixTranslation}charts.`}
      chart1={kp1}
      chart2={kpi2}
      chart3={
        <BarChart
          title={t(prefixTranslation + "charts.chart1.label")}
          displayYAxe={true}
          dataTitle=""
          {...chart1()}
        />
      }
      chart4={
        currentNode.has_workplace_equipment_only ? (
          chart3Broker()
        ) : (
          <ChartBarStackedGroupedMolecule
            title={t(prefixTranslation + "charts.chart3.label")}
            config={chart3()}
            labels={chart3Labels}
            options={Chart6Options}
            statusColor={chart3statusColor}
            axesLabel={t(`units:units.emission.${comparedScores.chart3.unit}`)}
            datalabelsFormatter={chart6DatalabelsFormatter}
            emissionUnit={comparedScores.chart3.unit}
          />
        )
      }
      chart5={kpi3}
      chart6={
        <ChartRadarMolecule
          title={t(prefixTranslation + "charts.chart2.label")}
          config={chart2()}
          options={chart4Options}
          pillarId={PILLAR_ENVIRONMENTAL}
        />
      }
    />
  )
}

NodeAnalysesOrganism.propTypes = {
  comparedScores: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array,
}

NodeAnalysesOrganism.defaultProps = {
  disabled: false,
  comparedNodes: [],
}

export default NodeAnalysesOrganism
