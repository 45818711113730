import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import {
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import { fieldIsFalsyOrNonPositive } from "../../../../useDisablingFields"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Select from "components/widgets/CollectPage/forms/fields/Select"
import { useTranslation } from "react-i18next"
import ExternalLabel from "components/widgets/CollectPage/forms/fields/ExternalLabel"
import Objective16TeamComposition, {
  teamConfigGenerator,
} from "components/widgets/CollectPage/forms/pillars/financial/TeamCompositionFields"
import { POLICY_CDR } from "utils/constants/centralization/policyItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const TEAM = "governance_cdr"
const teamConfig = teamConfigGenerator(TEAM)

const fields = [
  ...teamConfig.fields,
  "governance_cdr_budget",
  "governance_cdr_sustainable_it_strategy_defined",
  "governance_cdr_carbon_assessment_frequency",
  "governance_cdr_carbon_assessment_scope",
  "governance_cdr_carbon_assessment_excel",
  "governance_cdr_carbon_assessment_free_tools",
  "governance_cdr_carbon_assessment_market_tools",
  "governance_cdr_carbon_assessment_consultants",
  "governance_cdr_carbon_assessment_others",
  "governance_cdr_maturity_assessment_frequency",
  "governance_cdr_maturity_assessment_scope",
  "governance_cdr_maturity_assessment_excel",
  "governance_cdr_maturity_assessment_free_tools",
  "governance_cdr_maturity_assessment_market_tools",
  "governance_cdr_maturity_assessment_consultants",
  "governance_cdr_maturity_assessment_others",
  "governance_cdr_kpis_defined",
  "governance_cdr_labels",
]

const translationPath = "16.cdr_strategy"
const tPrefix = `collect:questions.${translationPath}`

const isNullOrNever = (value) => !value || value === "not_measured"

const disableConfig = [
  {
    disablingField: "governance_cdr_sustainable_it_strategy_defined",
    fieldsToDisable: [
      "governance_cdr_carbon_assessment_frequency",
      "governance_cdr_maturity_assessment_frequency",
    ],
  },
  {
    disablingField: "governance_cdr_carbon_assessment_frequency",
    condition: isNullOrNever,
    fieldsToDisable: [
      "carbon_assessment_method",
      "governance_cdr_carbon_assessment_scope",
      "governance_cdr_carbon_assessment_excel",
      "governance_cdr_carbon_assessment_free_tools",
      "governance_cdr_carbon_assessment_market_tools",
      "governance_cdr_carbon_assessment_consultants",
      "governance_cdr_carbon_assessment_others",
    ],
  },
  {
    disablingField: "governance_cdr_maturity_assessment_frequency",
    condition: isNullOrNever,
    fieldsToDisable: [
      "maturity_assessment_method",
      "governance_cdr_maturity_assessment_scope",
      "governance_cdr_maturity_assessment_excel",
      "governance_cdr_maturity_assessment_free_tools",
      "governance_cdr_maturity_assessment_market_tools",
      "governance_cdr_maturity_assessment_consultants",
      "governance_cdr_maturity_assessment_others",
    ],
  },
  {
    disablingField: "internal_innov_events_projects_proposed",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "internal_innov_events_csr_criteria",
      "internal_innov_events_dedicated_time",
      "internal_innov_events_projects_financed",
    ],
  },
  teamConfig.disablingConfig,
]

const CdrStrategy = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath, disableConfig })

  const assessmentOptions = useCallback(
    () =>
      ["not_measured", "once", "recurrent"].map((assessment) => ({
        value: assessment,
        label: t(`${tPrefix}.assessment.choices.${assessment}`),
      })),
    []
  )
  const scopeOptions = useCallback(
    () =>
      ["partial", "full"].map((scope) => ({
        value: scope,
        label: t(`${tPrefix}.assessment.scope.${scope}`),
      })),
    [t]
  )

  return (
    <Form>
      <FormLayout>
        <Module module={POLICY_CDR}>
          <div className="columns">
            <div className="column">
              <Objective16TeamComposition prefix={TEAM} />
              <FormLayout>
                <TextField id="governance_cdr_budget" type="number" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="governance_cdr_sustainable_it_strategy_defined" />
                <ExternalLabel translationPathName="assessment" />
                <table className="increased-padding">
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.assessment.maturity`)}</td>
                      <td>{t(`${tPrefix}.assessment.carbon_footprint`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t(`${tPrefix}.assessment.assessment`)}</td>
                      <td>
                        <Select
                          id="governance_cdr_carbon_assessment_frequency"
                          options={assessmentOptions()}
                        />
                      </td>
                      <td>
                        <Select
                          id="governance_cdr_maturity_assessment_frequency"
                          options={assessmentOptions()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.assessment.scope.label`)}</td>
                      <td>
                        <RadioButton
                          id="governance_cdr_carbon_assessment_scope"
                          choices={scopeOptions()}
                        />
                      </td>
                      <td>
                        <RadioButton
                          id="governance_cdr_maturity_assessment_scope"
                          choices={scopeOptions()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t(`${tPrefix}.assessment.means.label`)}</td>
                      <td>
                        <MultipleChoiceField
                          id="carbon_assessment_method"
                          choices={[
                            "governance_cdr_carbon_assessment_excel",
                            "governance_cdr_carbon_assessment_free_tools",
                            "governance_cdr_carbon_assessment_market_tools",
                            "governance_cdr_carbon_assessment_consultants",
                            "governance_cdr_carbon_assessment_others",
                          ]}
                        />
                      </td>
                      <td>
                        <MultipleChoiceField
                          id="maturity_assessment_method"
                          choices={[
                            "governance_cdr_maturity_assessment_excel",
                            "governance_cdr_maturity_assessment_free_tools",
                            "governance_cdr_maturity_assessment_market_tools",
                            "governance_cdr_maturity_assessment_consultants",
                            "governance_cdr_maturity_assessment_others",
                          ]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Select
                  id="governance_cdr_kpis_defined"
                  options={[
                    "initial",
                    "managed",
                    "defined",
                    "quantitatively_managed",
                    "optimizing",
                  ]}
                />

                <Select
                  id="governance_cdr_labels"
                  options={["none", "nr", "other"]}
                />
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default CdrStrategy
