import { ActionList, TopBar } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import "stylesheets/TopBarCustom.scss"
import TopBarSecondaryMenu from "components/widgets/TopBar/TopBarSecondaryMenu"
import LightOrganizationOrganism from "views/organisms/interactive/LightOrganizationOrganism"
import useAdminRules from "hooks/useAdminRules"
import TopBarUserMenu from "components/widgets/TopBar/TopBarUserMenu"
import useSearch from "hooks/useSearch"
import "stylesheets/blocs/molecules/organization/charts/ActionsOrganizationChartMolecule.scss"
import { useEntity } from "hooks/useAxios"
import NodeEntity from "services/entities/NodeEntity"
import ProductNavigation from "views/organisms/navigation/ProductNavigation"

const SEARCH_LIST_ID = "topBarCustom__search"

const TopBarCustom = () => {
  const { t } = useTranslation()
  const { isUserOverallAdmin } = useAdminRules()
  const {
    updateCurrentNode,
    currentOrganization,
    currentUser,
    currentRootNode,
  } = useAppContext()

  const nodeEntity = useEntity(NodeEntity)

  const {
    isError,
    onFocus,
    searchValue,
    isSearchActive,
    handleSearchChange,
    handleSearchResultsDismiss,
    nodes,
  } = useSearch({
    searchListId: SEARCH_LIST_ID,
    searchRequester: nodeEntity.search,
    onNodeAction: async (node) => await updateCurrentNode(node.id),
  })

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder={t("common:topbar.search")}
      showFocusBorder={false}
      onFocus={onFocus}
      focused={isSearchActive}
    />
  )

  const noResultsAction = [
    {
      content: t("common:topbar.noResults"),
      disabled: true,
    },
  ]

  const errorAction = [
    {
      content: t("common:topbar.searchError"),
      disabled: true,
    },
  ]

  const displayedNodes = nodes.length ? nodes : noResultsAction

  const searchResultsMarkup = (
    <ActionList
      actionRole={SEARCH_LIST_ID}
      items={isError ? errorAction : displayedNodes}
    />
  )

  const showAddCompanyButton = !!currentRootNode?.is_provider && !currentOrganization?.is_partial_partner

  const searchProps = !!currentUser && {
    onSearchResultsDismiss: handleSearchResultsDismiss,
    searchField: searchFieldMarkup,
    searchResults: searchResultsMarkup,
    searchResultsVisible: isSearchActive,
  }

  return (
    <TopBar
      showNavigationToggle
      userMenu={<TopBarUserMenu />}
      secondaryMenu={
        <div className="topBarCustom__activator topBarCustom__activator--max-width">
          {showAddCompanyButton && isUserOverallAdmin && (
            <LightOrganizationOrganism
              disabled={currentOrganization?.is_max_tree_exceeded}
            />
          )}
          <TopBarSecondaryMenu />

          {!currentOrganization?.is_partial_partner && <ProductNavigation />}
        </div>
      }
      {...searchProps}
    />
  )
}

export default TopBarCustom
