import {
  CORPORATE_NODE_TYPES,
  PARTNER_TYPES,
} from "utils/constants/forms/nodeTypesNodeRoot"

export const NODE_ORGANIZATION_SHARE_TYPE = "share"
export const PARTNER_LEVEL_NODE_STRUCTURE_TYPE = "partner"
export const INTERNAL_NODE_STRUCTURE_TYPE = "internal"
export const LIGHT_CORPORATE = "light_corporate"
export const LIGHT_CORPORATE_NODE_STRUCTURE_TYPE = "provider_corporate"

export const INTERNAL_NODE_STRUCTURE = {
  type: INTERNAL_NODE_STRUCTURE_TYPE,
  nodeTypes: CORPORATE_NODE_TYPES,
  root: false,
}

export const PARTNER_NODE_STRUCTURE = {
  type: PARTNER_LEVEL_NODE_STRUCTURE_TYPE,
  nodeTypes: PARTNER_TYPES,
}

export const CORPORATE_NODE_FOR_PROVIDER_STRUCTURE = {
  type: PARTNER_LEVEL_NODE_STRUCTURE_TYPE,
  nodeTypes: CORPORATE_NODE_TYPES,
}
