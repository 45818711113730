import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import ChartBadge from "views/atoms/badges/ChartBadge"
import { useTranslation } from "react-i18next"

const TRANSLATION_PREFIX =
  "analyze:it_domain.cloud.kpis.service_in_carbon_country"

const TextAnalysisCloudServiceInCarbonCountry = ({ value }) => {
  const { t } = useTranslation()

  const badge = (
    <ChartBadge value={value} lowerLimit={30} upperLimit={60} inverse />
  )

  const content = {
    kpi_text: t(`${TRANSLATION_PREFIX}.sub_title`),
    kpi: `${value}% ${t(`${TRANSLATION_PREFIX}.title`)}`,
    reference: badge,
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${TRANSLATION_PREFIX}.help`)}>
      <TextChart
        content={<TextChartMinor content={content} noSubtextHover={true} />}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextAnalysisCloudServiceInCarbonCountry.propTypes = {
  value: PropTypes.string.isRequired,
}

export default TextAnalysisCloudServiceInCarbonCountry
