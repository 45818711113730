import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import ChartBadge from "views/atoms/badges/ChartBadge"
import { useAppContext } from "components/AppContext"

const LOWER_LIMIT = 300
const UPPER_LIMIT = 600

const TextChartODD13MobileDataConsumptionEmployeeMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."
  const { currentRootNode } = useAppContext()

  const mobile_data_consumption_employee =
    score?.odd13_network_mobile_conso_per_employee || 0

  const badge = (
    <ChartBadge
      value={mobile_data_consumption_employee}
      lowerLimit={LOWER_LIMIT}
      upperLimit={UPPER_LIMIT}
      inverse
    />
  )

  const text = currentRootNode?.is_corporate
    ? t("score:score.13.chartMobileDataConsumptionEmployee.corporate.text")
    : t("score:score.13.chartMobileDataConsumptionEmployee.provider.text")

  const content = {
    kpi: mobile_data_consumption_employee,
    kpi_unit: (
      <Unit
        unit={t(`${translationPrefix}chartMobileDataConsumptionEmployee.unit`)}
      />
    ),
    kpi_text: text,
    reference: badge,
  }

  const content_for_display = <TextChartMinor content={content} />

  return <TextChart content={content_for_display} />
}

TextChartODD13MobileDataConsumptionEmployeeMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13MobileDataConsumptionEmployeeMolecule
