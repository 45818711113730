import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import "stylesheets/blocs/atoms/buttons/CentralizedFileTemplateDownloadAtom.scss"

const CentralizedFileTemplateDownloadAtom = ({ focusedNodeType, fileId }) => {
  const { t } = useTranslation()
  return (
    <div className="file-template-link-container">
      <Link
        to={`/templates/collects/centralized/${focusedNodeType}/${i18next.language}/${fileId}.${i18next.language}.xlsx`}
        target="_blank"
        download
      >
        {t("collect:questions.common.drop_zone.template")}
      </Link>
    </div>
  )
}

CentralizedFileTemplateDownloadAtom.propTypes = {
  fileId: PropTypes.string.isRequired,
  focusedNodeType: PropTypes.string.isRequired,
}

export default CentralizedFileTemplateDownloadAtom
