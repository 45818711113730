const ScoreEntity = (backClient) => ({
  getSocialScore: async () => await backClient.get(`/api/social_score`),
  getEnvironmentalScore: async () => await backClient.get(`/api/env_score`),
  getProfitScore: async () => await backClient.get(`/api/profit_score`),
  getScore: async () => await backClient.get(`/api/score`),
  socialPeopleInventoryScore: async () =>
    await backClient.get(`/api/social_people_inventory_score`),
  websiteScore: async () => await backClient.get(`/api/website_scores/score`),
  websiteScores: async () => await backClient.get(`/api/website_scores`),
  socialReference: async () => await backClient.get(`/api/social_reference`),
  compute: async () => await backClient.post(`/api/scores/compute`),
  compare: async (node_ids) =>
    await backClient.get(`/api/compare_score`, { params: { node_ids } }),
  compareWebsites: async (node_ids) =>
    await backClient.get(`/api/compare_score/websites`, {
      params: { node_ids },
    }),
})

export default ScoreEntity
