import React from "react"
import PropTypes from "prop-types"
import PillarsContainerMolecule from "views/molecules/containers/PillarsContainerMolecule"
import PillarFrameAtom from "views/atoms/buttons/PillarFrameAtom"

const PillarOrganism = ({ pillars, oldUI }) => {
  return (
    <PillarsContainerMolecule>
      {pillars.map((pillar, idx) => (
        <PillarFrameAtom
          key={idx}
          link={pillar.link}
          pillar={pillar.pillar}
          selected={pillar.selected}
          text={pillar.text}
          disabled={pillar.disabled}
          progressComponent={pillar.progressComponent}
          oldUI={oldUI}
        />
      ))}
    </PillarsContainerMolecule>
  )
}

PillarOrganism.propTypes = {
  pillars: PropTypes.array,
  oldUI: PropTypes.bool,
}

PillarOrganism.defaultProps = {
  pillars: [],
  oldUI: false,
}

export default PillarOrganism
