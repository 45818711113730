import { useEffect, useState } from "react"
import { useEntity } from "./useAxios"
import useRequest from "hooks/useRequest"
import O365CredentialEntity from "services/entities/O365CredentialEntity"

const useO365 = (defaultCredentials) => {
  const credentialEntity = useEntity(O365CredentialEntity)

  const [submit, onSubmit] = useState(false)
  const [credentials, setCredentials] = useState(defaultCredentials)

  const {
    data: credentialsResponse,
    isLoading,
    isError,
    error,
  } = useRequest(
    () =>
      defaultCredentials?.id
        ? credentialEntity.update(defaultCredentials?.id, credentials)
        : credentialEntity.create(credentials),
    [submit, defaultCredentials],
    { condition: submit }
  )
  useEffect(() => {
    if (credentialsResponse) {
      onSubmit(false)
    }
  }, [credentialsResponse])

  return {
    error,
    submit,
    isError,
    onSubmit,
    isLoading,
    credentials,
    setCredentials,
    credentialsResponse,
  }
}

export default useO365
