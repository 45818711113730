import { PILLARS } from "utils/constants/pillars"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

const usePillars = ({ pageName, ...pillars }) => {
  const { t } = useTranslation()
  const { pillarId } = useParams()

  return PILLARS.map((pillar) => {
    const options = pillars[pillar]

    return {
      pillar,
      link: `${pageName}/${pillar}`,
      selected: pillarId === pillar,
      text: t(`collect:pillars.${pillar}.title`),
      ...options,
    }
  })
}

export default usePillars
