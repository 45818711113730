import React from "react"
import { FormLayout } from "@shopify/polaris"
import TeamCompositionFields, {
  teamConfigGenerator,
} from "components/widgets/CollectPage/forms/pillars/financial/TeamCompositionFields"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import Form from "components/widgets/CollectPage/forms/Form"
import {
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import { INVENTORY_INNOVATIONS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const TEAM = "structure"
const teamConfig = teamConfigGenerator(TEAM)

const fields = [
  ...teamConfig.fields,
  "structure_budget",
  "innovation_process_go_no_go_presence",
  "innovation_process_business_present",
  "innovation_process_eco_conception_present",
  "innovation_process_green_criteria_presence",
  "innovation_process_green_criteria_weight",
  "innovation_process_green_criteria_sgd_link",
]
const translationPath = "9.governance"

const disableConfig = [
  teamConfig.disablingConfig,
  {
    disablingField: "innovation_process_green_criteria_presence",
    fieldsToDisable: [
      "innovation_process_green_criteria_weight",
      "innovation_process_green_criteria_sgd_link",
    ],
  },
  {
    disablingField: "innovation_process_go_no_go_presence",
    fieldsToDisable: [
      "innovation_process_business_present",
      "innovation_process_eco_conception_present",
      "innovation_process_green_criteria_presence",
    ],
  },
]

const GovernanceForm = () => {
  useFormConfig({ fields, translationPath, disableConfig })

  const { t } = useTranslation()
  const tPrefix = `collect:questions.${translationPath}`

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_INNOVATIONS}>
          <div className="columns">
            <div className="column">
              <TeamCompositionFields prefix={TEAM} />

              <FormLayout>
                <TextField id="structure_budget" type="number" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="innovation_process_go_no_go_presence" />
                <table>
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.presence`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      "innovation_process_business_present",
                      "innovation_process_eco_conception_present",
                      "innovation_process_green_criteria_presence",
                    ].map((field) => (
                      <tr key={field}>
                        <td>{t(`${tPrefix}.${field}`)}</td>
                        <td>
                          <YesNoRadio id={field} />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.innovation_process_green_criteria_weight`
                        )}
                      </td>
                      <td>
                        <TextField
                          id="innovation_process_green_criteria_weight"
                          type="number"
                          suffix="%"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.innovation_process_green_criteria_sgd_link`
                        )}
                      </td>
                      <td>
                        <YesNoRadio id="innovation_process_green_criteria_sgd_link" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default GovernanceForm
