import React from "react"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import PropTypes from "prop-types"

const ComparisonList = ({ nodes, onDelete }) => {
  return nodes.map((node, index) => {
    const breadcrumbWithNode = node?.breadcrumb ?? [node]

    return (
      <BreadcrumbsOrganism
        onDelete={onDelete}
        parentOrganization={breadcrumbWithNode}
        redirectDisabled
        key={index}
        node={node}
        italic
      />
    )
  })
}

ComparisonList.propTypes = {
  onDelete: PropTypes.func.isRequired,
  nodes: PropTypes.array,
}

ComparisonList.defaultProps = {
  nodes: [],
}

export default ComparisonList
