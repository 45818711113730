import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import { EMPTY_LINE_LEGEND } from "views/molecules/canvases/charts/ChartOdd13ExternalAppEmissions"
import React from "react"
import PropTypes from "prop-types"
import { COLORS } from "utils/constants/colors"

const ChartOdd13InternalAppsBreakdown = ({
  data,
  localPrefix,
  labelGetter,
}) => {
  const { t } = useTranslation()

  const addUnitToDataLabel = {
    plugins: {
      datalabels: {
        display: true,
        color: COLORS.white,
        formatter: (value) => `${value}\n${t(`units:units.emission.t_unit`)}`,
        textAlign: "center",
      },
    },
  }

  const { bar, line, label } = data

  const data_inputs = {
    bar_data: {
      label: t(`${localPrefix}.emissions`),
      data: bar,
    },
    line_data: {
      label: t(`${localPrefix}.apps`),
      data: line,
    },
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
      <MixedChart
        title={t(`${localPrefix}.title`)}
        labels={label.map(
          (value) => labelGetter(value) ?? value ?? t(`${localPrefix}.empty`)
        )}
        data_inputs={data_inputs}
        unit_right_axis={t(`units:units.emission.t_unit`)}
        unit_left_axis={t(`${localPrefix}.number`)}
        legendModifiers={EMPTY_LINE_LEGEND}
        config={addUnitToDataLabel}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartOdd13InternalAppsBreakdown.propTypes = {
  data: PropTypes.shape({
    bar: PropTypes.arrayOf(PropTypes.number).isRequired,
    line: PropTypes.arrayOf(PropTypes.number).isRequired,
    label: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  localPrefix: PropTypes.string.isRequired,
  labelGetter: PropTypes.func,
}

ChartOdd13InternalAppsBreakdown.defaultProps = {
  labelGetter: null,
}

export default ChartOdd13InternalAppsBreakdown
