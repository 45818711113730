import { useAuth0 as realUseAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"
import { backClient } from "../../helpers/AxiosHelper"
import { useAuthContext } from "./AuthProvider"
import { disabledAuth } from "./AuthConfig"

const useAuth0 = () => {
  if (!disabledAuth) return realUseAuth0()

  const history = useHistory()
  const { isAuthenticated, setIsAuthenticated } = useAuthContext()

  return {
    isLoading: false,
    isAuthenticated,
    setIsAuthenticated,
    getAccessTokenSilently: async () => "this is a fake token (cf useAuth.js)",
    logout: async () => {
      await backClient.delete("/users/sign_out.json")
      setIsAuthenticated(false)
      history.push("/")
    },
  }
}

export default useAuth0
