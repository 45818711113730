import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import NumberFormatterApp from "services/apps/numberFormatterApp"

const DoughnutChartAtom = ({
  data = {
    doughnut_charts: [],
    doughnut_charts_data_total: { number: 0, unit: "" },
  },
  unitType = "count",
  title = "",
  large = false,
}) => {
  const dataCallback = useCallback(
    () => ({
      centerText: `${data.doughnut_charts_data_total?.number}`,
      centerTextUnit: NumberFormatterApp.getI18nUnit(
        unitType,
        data.doughnut_charts_data_total?.unit
      ),
      data: data.doughnut_charts,
    }),
    [data]
  )

  return (
    <DoughnutChart
      title={title}
      centerText={dataCallback().centerText}
      centerTextUnit={dataCallback().centerTextUnit}
      data={dataCallback().data}
      large={large}
    />
  )
}

export default DoughnutChartAtom
