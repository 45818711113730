import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import CsrStrategy from "components/widgets/CollectPage/forms/pillars/financial/objectives16/Objective16CsrStrategyForm"
import CdrStrategy from "./objectives16/Objective16CdrStrategyForm"
import ArtificialIntelligence from "./objectives16/Objective16ArtificialIntelligenceForm"
import Objective16EthicsForm from "components/widgets/CollectPage/forms/pillars/financial/objectives16/Objective16EthicsForm"
import CyberSecurityForm from "components/widgets/CollectPage/forms/pillars/financial/objectives16/Objective16CybersecurityForm"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"

const Objective16Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "csr_strategy":
        return <CsrStrategy />
      case "cdr_strategy":
        return <CdrStrategy />
      case "ethics":
        return <Objective16EthicsForm />
      case "artificial_intelligence":
        return <ArtificialIntelligence />
      case "cyber_security":
        return <CyberSecurityForm />
      case "positive_impact":
        return <PositiveImpactForm />

      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective16Form
