import useRequest from "hooks/useRequest"
import UserStateEntity from "services/entities/UserStateEntity"
import { useEntity } from "./useAxios"
import OrganizationEntity from "../services/entities/OrganizationEntity"
import { useHistory } from "react-router-dom"

const useCurrentNode = (dependencies = [], condition = true) => {
  const history = useHistory()

  const userStateEntity = useEntity(UserStateEntity)
  const organizationEntity = useEntity(OrganizationEntity)

  const {
    data: currentNode,
    isLoading: isCurrentNodeLoading,
    reload: reloadCurrentNode,
  } = useRequest(() => userStateEntity.getCurrentNode(), dependencies, {
    condition,
  })

  const {
    data: parentOrganization,
    isLoading: isParentOrganizationLoading,
  } = useRequest(
    () => organizationEntity.getParentOrganization(),
    [...dependencies, currentNode],
    {
      condition,
    }
  )

  const updateCurrentNode = async (id, redirection = null) => {
    await userStateEntity.setCurrentNode(id)
    await Promise.all([reloadCurrentNode()])

    redirection && history.push(redirection)
  }

  return {
    currentNode,
    updateCurrentNode,
    isCurrentNodeLoading,
    reloadCurrentNode,
    parentOrganization,
    isParentOrganizationLoading,
  }
}

export default useCurrentNode
