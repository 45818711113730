import useRequest from "hooks/useRequest"
import SubscriptionEntity from "services/entities/SubscriptionEntity"
import { useEffect, useState } from "react"
import _ from "lodash"
import { useEntity } from "./useAxios"

const defaultTableLinesValues = _.times(3, () => _.times(5, _.constant(false)))

const tableHeaders = ["environmental", "social", "profit"]
const linesHeaders = [
  "pillar",
  "website",
  "ccf",
  "snow_workplace",
  "snow_network",
  "snow_dc",
  "snow_external_app",
  "office_365",
]

const featuresToPillar = {
  website: ["env"],
}

const useSubscription = () => {
  const [tableLineValues, setTableLineValues] = useState(
    defaultTableLinesValues
  )
  const subscriptionEntity = useEntity(SubscriptionEntity)

  const { data, isLoading, isError, isSuccess } = useRequest(
    () => subscriptionEntity.current(),
    [],
    { defaultValue: {} }
  )

  useEffect(() => {
    const prefixes = ["env", "social", "profit"]
    let features = []

    if (data.features) {
      features = prefixes.map((prefix) =>
        linesHeaders.map((header) => findFeatureValue(prefix, header))
      )

      setTableLineValues(features)
    }
  }, [data])

  const findFeatureValue = (prefix, key) => {
    const prefixedFeature = data.features[`${prefix}_${key}`]

    if (prefixedFeature !== undefined) {
      return prefixedFeature
    }

    const feature = featuresToPillar[key]

    if (feature) {
      return feature.includes(prefix) ? data.features[key] : null
    } else {
      return null
    }
  }

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    table: {
      header: tableHeaders,
      linesHeader: linesHeaders,
      linesValues: tableLineValues,
    },
  }
}

export default useSubscription
