import { backClient } from "helpers/AxiosHelper"
import { ApiError } from "helpers/errors/ApiError"
import Validator from "validator"
import i18n from "i18next"

export class Authentication {
  static async login(email, password, rememberMe = false) {
    const data = {
      user: {
        email: email,
        password: password,
        remember_me: rememberMe,
      },
    }

    try {
      const response = await backClient.post("/users/sign_in.json", data)

      return response.data
    } catch (err) {
      if (err.isAxiosError) {
        throw new ApiError(err.response.status, err.response.data.error)
      } else {
        throw new Error(err)
      }
    }
  }

  static validateEmail(email) {
    let message = null

    if (email == null || email === "") {
      message = i18n.t("common:login.errors.3000")
    } else if (!Validator.isEmail(email)) {
      message = i18n.t("common:login.errors.3001")
    }

    return message
  }

  static validatePassword(password) {
    let message = null

    if (password == null || password === "") {
      message = i18n.t("common:login.errors.3010")
    } else if (password.length < 8) {
      message = i18n.t("common:login.errors.3011")
    }
    return message
  }
}
