import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import {
  ENV_CENTRALIZATION_TYPES,
  PROFIT_CENTRALIZATION_TYPES,
  SOCIAL_CENTRALIZATION_TYPES,
} from "utils/constants/centralization/type"
import { isNodeLeaf } from "utils/constants/organization/nodeTypes"
import AlertBanner from "views/molecules/banners/AlertBanner"
import { useNodeContext } from "views/organisms/interactive/NodeContext"
import PillarCentralization from "views/molecules/centralization/PIllarCentralization"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import useCentralizationForm from "hooks/useCentralizationForm"

const CentralizationFormMolecule = ({ disabled }) => {
  const { t } = useTranslation()
  const {
    generateRules,
    centralizationAuthorized,
    centralizationDisabled,
    updateNodeCentralization,
  } = useCentralizationForm(disabled)

  const { node } = useNodeContext()

  return (
    <>
      <h3>{t(`dashboard:modals.centralization_intro.title`)}</h3>
      <p className={"centralization-intro-description"}>
        {t(`dashboard:modals.centralization_intro.description`)}
      </p>
      <br />
      {!centralizationAuthorized && !isNodeLeaf(node) && (
        <AlertBanner
          title={t(
            `dashboard:modals.banners.unauthorized_centralization_with_node.title`
          )}
        />
      )}

      <PillarCentralization
        title={t(`collect:pillars.environmental.title`)}
        pillar={PILLAR_ENVIRONMENTAL}
        centralizationRules={generateRules(
          ENV_CENTRALIZATION_TYPES,
          node.env_governance_frozen
        )}
        onChange={updateNodeCentralization}
        frozen={node.env_governance_frozen}
        disabled={
          isNodeLeaf(node) ||
          centralizationDisabled ||
          node.env_governance_frozen
        }
      />
      <PillarCentralization
        title={t(`collect:pillars.social.title`)}
        pillar={PILLAR_SOCIAL}
        centralizationRules={generateRules(
          SOCIAL_CENTRALIZATION_TYPES,
          node.social_governance_frozen
        )}
        onChange={updateNodeCentralization}
        frozen={node.social_governance_frozen}
        disabled
      />
      <PillarCentralization
        title={t(`collect:pillars.financial.title`)}
        pillar={PILLAR_PROFIT}
        centralizationRules={generateRules(
          PROFIT_CENTRALIZATION_TYPES,
          node.profit_governance_frozen
        )}
        onChange={updateNodeCentralization}
        frozen={node.profit_governance_frozen}
        disabled={
          isNodeLeaf(node) ||
          centralizationDisabled ||
          node.profit_governance_frozen
        }
      />
    </>
  )
}

CentralizationFormMolecule.displayName = `${CentralizationFormMolecule}`

CentralizationFormMolecule.propTypes = {
  disabled: PropTypes.bool,
}

CentralizationFormMolecule.defaultProps = {
  disabled: false,
}

export default CentralizationFormMolecule
