import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Autocomplete } from "@shopify/polaris"
import _ from "lodash"

const AutocompleteFieldMolecule = ({
  label,
  options,
  disabled,
  placeholder,
  value,
  onChange,
}) => {
  const getOptions = useCallback(() => {
    if (value === "") {
      return options
    }

    const val = value.toLowerCase()

    return options.filter((option) => {
      const label = option.label.toLowerCase()

      return label.includes(val) && label !== val
    })
  }, [value, options])

  return (
    <Autocomplete
      label={label}
      options={getOptions()}
      selected={[]}
      onSelect={(values) => onChange(values[0])}
      textField={
        <Autocomplete.TextField
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoComplete="off"
        />
      }
    />
  )
}

AutocompleteFieldMolecule.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

AutocompleteFieldMolecule.defaultProps = {
  disabled: false,
  placeholder: "",
  value: "",
}

export default AutocompleteFieldMolecule
