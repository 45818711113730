import * as i18nIsoCountries from "i18n-iso-countries"

import i18nIsoCountriesEn from "i18n-iso-countries/langs/en.json"
import i18nIsoCountriesFr from "i18n-iso-countries/langs/fr.json"

import i18n from "i18next"

export default class CountryApp {
  static _constructor() {
    i18nIsoCountries.registerLocale(i18nIsoCountriesEn)
    i18nIsoCountries.registerLocale(i18nIsoCountriesFr)
  }

  /**
   * Returns the translated country (by default in English)
   * @param countryCode
   * @param countryShortName
   * @param countryShortMaximum
   * @returns {string} Country name I18n
   */
  static getNameI18n(
    countryCode,
    countryShortName = false,
    countryShortMaximum = 10
  ) {
    const countryI18n = i18nIsoCountries.getName(countryCode, i18n.language)

    if (countryShortName && countryI18n?.length >= countryShortMaximum) {
      return i18nIsoCountries.getAlpha3Code(countryI18n, i18n.language)
    }

    return countryI18n || countryCode
  }
}

CountryApp._constructor()
