import {
  Button,
  DescriptionList,
  Form as PForm,
  FormLayout,
  Heading,
  Layout,
  Modal,
  Select as PSelect,
  Stack,
  TextField as PTextField,
} from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCookies } from "react-cookie"
import { locations } from "helpers/CollectHelper"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import WebsitesEntity from "../../services/entities/WebsitesEntity"
import { useEntity } from "../../hooks/useAxios"

const WebsiteAssessButton = () => {
  const { t } = useTranslation()
  const [cookies] = useCookies(["autofill"])
  const [formData, setFormData] = useState({})
  const [results, setResults] = useState(null)
  const [active, setActive] = useState(false)
  const websitesEntity = useEntity(WebsitesEntity)

  const handleChange = useCallback(() => {
    setActive(!active)
  }, [active])

  const resultsMarkup = () => {
    return (
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={"Website assessment : " + formData.url}
      >
        <Modal.Section>
          <div className="Verdikt-Website">
            <Heading>Overall Maturity </Heading>
            <DescriptionList
              items={[
                {
                  term: "Maturity",
                  description: `${results.maturity} / 100 `,
                },
              ]}
            />
            <hr />
            <Heading>GHG</Heading>
            <DescriptionList
              items={[
                {
                  term: "Emission",
                  description: `${results.ghg_period_CO2} T eq CO2 `,
                },
                {
                  term: "Maturity",
                  description: `${results.ghg_maturity} / 100 `,
                },
              ]}
            />
            <hr />
            <Heading>Water</Heading>
            <DescriptionList
              items={[
                {
                  term: "Consumption",
                  description: `${results.water_period_water} m3`,
                },
              ]}
            />
            <hr></hr>
            <Heading>Ecodesign Best Practices</Heading>
            <DescriptionList
              items={[
                {
                  term: "Maturity",
                  description: `${results.bp_maturity} / 100 `,
                },
              ]}
            />
            <hr />
            <Heading>Green Hosting</Heading>
            <DescriptionList
              items={[
                {
                  term: "Maturity",
                  description: `${results.green_hosting_maturity} / 100 `,
                },
              ]}
            />
            <hr />
            <Heading>SEO</Heading>
            <DescriptionList
              items={[
                {
                  term: "Maturity",
                  description: `${results.seo_maturity} / 100 `,
                },
              ]}
            />
            <hr />
            <Heading>Accessibility</Heading>
            <DescriptionList
              items={[
                {
                  term: "Maturity",
                  description: `${results.accessibility_maturity} / 100 `,
                },
              ]}
            />
          </div>
        </Modal.Section>
      </Modal>
    )
  }

  const assessWebsite = useCallback(() => {
    const data = {
      data: {
        url: formData.url,
        period_visitors: formData.period_visitors,
        average_duration_on_website_in_min:
          formData.average_duration_on_website_in_min,
        percentage_usage_mobile: formData.percentage_usage_mobile,
        percentage_usage_desktop: 100 - formData.percentage_usage_mobile,
        average_visited_pages_per_session: formData.average_visited_pages,
        country: formData.country,
      },
    }
    websitesEntity
      .assess(data)
      .then((response) => {
        setResults(response.data)
        resultsMarkup()
      })
      .catch((error) => {
        console.log("Error in assessing website: " + error)
      })
  }, [formData])

  const activator = (
    <Button
      onClick={() => {
        handleChange()
      }}
    >
      Evaluer website
    </Button>
  )

  const collectWebsite = () => {
    return (
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={"Evaluer website"}
        primaryAction={{
          content: "Evaluer le service numérique",
          onAction: assessWebsite,
        }}
      >
        <Modal.Section>
          <PForm onSubmit={assessWebsite} implicitSubmit={false}>
            <FormLayout>
              <TextField
                translationPath="13.apps.website_modal"
                id="url"
                formData={formData}
                setFormData={setFormData}
                type="url"
                autoComplete={true}
              />
              <TextField
                translationPath="13.apps.website_modal"
                id="period_visitors"
                formData={formData}
                setFormData={setFormData}
                type="number"
              />
              <TextField
                translationPath="13.apps.website_modal"
                id="average_duration_on_website_in_min"
                formData={formData}
                setFormData={setFormData}
                type="number"
              />
              <TextField
                translationPath="13.apps.website_modal"
                id="percentage_usage_mobile"
                formData={formData}
                setFormData={setFormData}
                type="number"
              />
              <PTextField
                label={t(
                  "collect:questions.13.apps.website_modal.percentage_usage_desktop." +
                    "label",
                  ""
                )}
                type="number"
                suffix={t(
                  "collect:questions.13.apps.website_modal.percentage_usage_desktop." +
                    "suffix",
                  ""
                )}
                placeholder={t(
                  "collect:questions.13.apps.website_modal.percentage_usage_desktop." +
                    "placeholder",
                  ""
                )}
                value={String(
                  formData && formData["percentage_usage_mobile"]
                    ? 100 - Number(formData["percentage_usage_mobile"])
                    : ""
                )}
              />
              <TextField
                translationPath="13.apps.website_modal"
                id="average_visited_pages_per_session"
                formData={formData}
                setFormData={setFormData}
                type="number"
              />
              <PSelect
                label={t("activation:company.questions.headquarters_country")}
                options={locations()}
                placeholder={t(
                  "activation:common.default_select_country_placeholder"
                )}
                value={String((formData && formData["country"]) ?? "")}
                onChange={(value) => {
                  setFormData({ ...formData, country: value })
                }}
              />
            </FormLayout>
          </PForm>
        </Modal.Section>
      </Modal>
    )
  }

  if (cookies.autofill != "true") {
    return null
  } else {
    return (
      <Layout.Section>
        <Stack distribution="trailing">
          {results == null ? collectWebsite() : resultsMarkup()}
        </Stack>
      </Layout.Section>
    )
  }
}

export default WebsiteAssessButton
