import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ResponsibleConsumptionTableHeaderMolecule = ({ domain }) => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <th></th>
        <th>
          {t(`collect:questions.12.sustainable_consumption.table_1.column_4`)}
        </th>
        <th>
          {t(`collect:questions.12.sustainable_consumption.table_1.column_2`)}
        </th>
        {domain === "workplace" && (
          <th>
            {t(`collect:questions.12.sustainable_consumption.table_1.column_3`)}
          </th>
        )}
      </tr>
    </thead>
  )
}

export default ResponsibleConsumptionTableHeaderMolecule

ResponsibleConsumptionTableHeaderMolecule.propTypes = {
  domain: PropTypes.string.isRequired,
}
