import React from "react"
import { useTranslation } from "react-i18next"
import { Card, Layout, Page } from "@shopify/polaris"
import CardSubscriptionMolecule from "views/molecules/organization/subscriptions/CardSubscriptionMolecule"
import useSubscription from "hooks/useSubscription"
import TableSubscriptionOrganism from "views/organisms/subscription/TableSubscriptionOrganism"
import CurrentSubscriptionBadge from "views/atoms/badges/CurrentSubscriptionBadge"
import withAuthRequired from "../../components/auth/withAuthRequired"

const SubscriptionPage = () => {
  const { t } = useTranslation()

  const { data: subscription, table, isSuccess, isLoading } = useSubscription()

  const subscriptionsArchivedElement = () =>
    subscription.old_subscriptions.map((subscriptionArchived, idx) => (
      <CardSubscriptionMolecule
        key={idx}
        title={t("subscription:status.archived.title")}
        subscription={subscriptionArchived}
        badge={null}
      ></CardSubscriptionMolecule>
    ))

  return (
    <Page singleColumn title={t("subscription:title")}>
      <Layout>
        {!isLoading && isSuccess && (
          <>
            <Layout.Section>
              <CardSubscriptionMolecule
                subscription={subscription}
                title={t("subscription:current_subscription.title")}
                badge={<CurrentSubscriptionBadge />}
              />
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header title={t("subscription:features.title")} />
                <Card.Section subdued>
                  <TableSubscriptionOrganism
                    headerCells={table.header}
                    linesHeaderCells={table.linesHeader}
                    lines={table.linesValues}
                  />
                </Card.Section>
              </Card>
            </Layout.Section>

            <Layout.AnnotatedSection
              id="storeDetails"
              title={t("subscription:archived_subscription.title")}
              description={t("subscription:archived_subscription.body")}
            />

            <Layout.Section>{subscriptionsArchivedElement()}</Layout.Section>
          </>
        )}
      </Layout>
    </Page>
  )
}

export default withAuthRequired(SubscriptionPage)
