import { useState } from "react"
import useKeyPressEvent from "hooks/useKeyPressEvent"

const useSearchKeyboardNavigation = (
  isSearchActive,
  length,
  onPressEnter,
  onPressEscape
) => {
  const [index, setIndex] = useState(null)

  const maxIndex = length - 1
  const minIndex = 0

  useKeyPressEvent(
    "ArrowDown",
    () => {
      if (index === null && length > 0) {
        setIndex(minIndex)
        return
      }

      if (index < maxIndex) {
        setIndex(index + 1)
      }
    },
    isSearchActive
  )

  useKeyPressEvent(
    "ArrowUp",
    () => {
      if (index > minIndex) {
        setIndex(index - 1)
      }
    },
    isSearchActive
  )

  useKeyPressEvent("Enter", onPressEnter, isSearchActive)

  useKeyPressEvent("Escape", onPressEscape, isSearchActive)

  return { index, resetIndex: () => setIndex(null) }
}

export default useSearchKeyboardNavigation
