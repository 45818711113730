import { useHistory } from "react-router-dom"
import { useAppContext } from "components/AppContext"

const useRootNode = () => {
  const { setCurrentRootNode } = useAppContext()

  const history = useHistory()

  const changeRoot = async (response, redirection = null) => {
    const { data: node } = await response

    setCurrentRootNode(node)

    redirection && history.push(redirection)
  }

  return {
    changeRoot,
  }
}

export default useRootNode
