export const downloadFile = (url) => {
  const link = document.createElement("a")

  link.href = url

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode.removeChild(link)
}
