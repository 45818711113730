import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Select } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import NodeEntity from "../../../../services/entities/NodeEntity"
import { useEntity } from "../../../../hooks/useAxios"
import useAvailableNodes from "hooks/useAvailableNodes"
import { NODE_AVAILABLE_STATUS } from "utils/constants/organization/nodeTypes"

// @todo This component is a logic level aberration it will have to be redone
const ParentSelectionField = ({ value, type, onChange, disabled, nodeId }) => {
  const [errors, setErrors] = useState({})
  const [parents, setParents] = useState([])
  const nodeEntity = useEntity(NodeEntity)
  const { availableNodeGeneration } = useAvailableNodes(
    (status) => status !== NODE_AVAILABLE_STATUS
  )

  const { t } = useTranslation()

  /**
   * @description API call to retrieve available parents for a given organizational entity
   */
  useEffect(async () => {
    if (!type) return

    try {
      const response = await nodeEntity.availableParent(type, nodeId)

      if (response.data.length === 0) return setParents([])

      const parents = response.data.map(availableNodeGeneration)

      setParents(parents)

      if (parents.length === 1 && !parents[0].disabled) {
        onChange(parents[0])
      }
    } catch (err) {
      setParents([])
      if (err.response) {
        // There is an error response from the server
        setErrors(err.response.data)
        console.error(err)
        console.error(err.response.data)
      } else if (err.request) {
        // The request was made but no response was received
        // Error details are stored in error.request
        console.error("Error request", err.request)
      } else {
        // Some other errors
        console.error("Error", err.message)
      }
    }
  }, [type])

  const placeholder = t("dashboard:chart.selection_form.parent_placeholder")

  return (
    <Select
      label={t("dashboard:chart.selection_form.parent")}
      disabled={disabled}
      options={parents}
      placeholder={placeholder}
      value={value?.id.toString()}
      onChange={(value) =>
        onChange(parents.find((parent) => parent.value === value))
      }
      error={errors.name}
    />
  )
}

ParentSelectionField.propTypes = {
  nodeId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.object,
}

ParentSelectionField.defaultProps = {
  nodeId: null,
  disabled: false,
  type: null,
  value: null,
}

export default ParentSelectionField
