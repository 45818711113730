import React from "react"
import { useTranslation } from "react-i18next"
import CentralizationMessage from "views/molecules/centralization/CentralizationMessage"

const NoCentralizationMessage = () => {
  const { t } = useTranslation()

  return (
    <CentralizationMessage
      text={t("organization:centralization.button.no_centralization")}
    />
  )
}

export default NoCentralizationMessage
