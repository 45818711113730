const FileQualityEntity = (backClient) => ({
  getFileQuality: async (inventoryId) => {
    const { data } = await backClient.get(
      `/api/uploads/collects/ratios/${inventoryId}/quality`
    )
    return data
  },
})

export default FileQualityEntity
