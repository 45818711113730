import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const Objective5Score = ({ score, collect }) => {
  const { t } = useTranslation()

  const { objectiveId } = useParams()
  const translationPrefix = `score:score.${objectiveId}.`

  const socialReference = score.social_references
  const socialPeopleInventoryScore = score.social_people_inventory_score

  const chart1 = useCallback(() => {
    const index_kpi = score["odd5_index_female_male"]

    const content = {
      kpi: index_kpi,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const women_managerial_employee_rate_kpi =
      collect["parity_IT_women_managerial_employee_rate"] ??
      socialPeopleInventoryScore[
        "odd5_parity_IT_women_managerial_employee_rate"
      ]
    const women_managerial_employee_rate_reference = Math.round(
      socialReference["odd5_parity_IT_women_managerial_employee_rate_IT"]
    )

    const content = {
      kpi: women_managerial_employee_rate_kpi,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
      reference: women_managerial_employee_rate_reference,
      reference_unit: t(translationPrefix + "chart2.unit"),
      reference_text: t(translationPrefix + "chart2.subtext"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference, socialPeopleInventoryScore])

  const chart3 = useCallback(() => {
    const women_infrastructure =
      socialReference["odd5_parity_IT_women_infrastructure"]
    const women_development =
      socialReference["odd5_parity_IT_women_development"]
    const women_consulting = socialReference["odd5_parity_IT_women_consulting"]
    const women_data = socialReference["odd5_parity_IT_women_data"]

    let data = [
      {
        label: t(translationPrefix + `chart3.infrastructure`),
        data: women_infrastructure,
      },
      {
        label: t(translationPrefix + `chart3.development`),
        data: women_development,
      },
      {
        label: t(translationPrefix + `chart3.consulting`),
        data: women_consulting,
      },
      {
        label: t(translationPrefix + `chart3.data`),
        data: women_data,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart3.help")}
      >
        <BarChart
          title={t(translationPrefix + "chart3.title")}
          data={data}
          dataTitle={""}
          axesLabel={t(translationPrefix + "chart3.y_label")}
          displayYAxe={true}
          displayLegend={false}
          doSortData={true}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [socialReference])

  const chart4 = useCallback(() => {
    const women_ESN_Atos = socialReference["odd5_parity_IT_women_ESN_Atos"]
    const women_ESN_Capgemini =
      socialReference["odd5_parity_IT_women_ESN_Capgemini"]
    const women_ESN_Accenture =
      socialReference["odd5_parity_IT_women_ESN_Accenture"]
    const women_ESN_Wavestone =
      socialReference["odd5_parity_IT_women_ESN_Wavestone"]
    const women_ESN_CGI = socialReference["odd5_parity_IT_women_ESN_CGI"]
    const women_ESN_Sopra = socialReference["odd5_parity_IT_women_ESN_Sopra"]

    const datas = [
      women_ESN_Atos,
      women_ESN_Capgemini,
      women_ESN_Accenture,
      women_ESN_Wavestone,
      women_ESN_CGI,
      women_ESN_Sopra,
    ]

    const data = [
      {
        labels: [
          t(translationPrefix + `chart4.axes.atos`),
          t(translationPrefix + `chart4.axes.capgemini`),
          t(translationPrefix + `chart4.axes.accenture`),
          t(translationPrefix + `chart4.axes.wavestone`),
          t(translationPrefix + `chart4.axes.cgi`),
          t(translationPrefix + `chart4.axes.sopra`),
        ],
        datasets: [
          {
            label: t(`score:score.common.datasets.market_data`),
            data: { datas },
          },
        ],
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <RadarChart title={t(translationPrefix + "chart4.title")} data={data} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [socialReference])

  const chart5 = useCallback(() => {
    const women_promoted_rate_kpi =
      collect["parity_IT_women_promoted_rate"] ??
      socialPeopleInventoryScore["odd5_parity_IT_women_promoted_rate"]
    const women_promoted_rate_reference = Math.round(
      socialReference["odd5_parity_IT_women_promoted_rate_IT"]
    )

    const content = {
      kpi: women_promoted_rate_kpi,
      kpi_unit: t(translationPrefix + "chart5.unit"),
      kpi_text: t(translationPrefix + "chart5.text"),
      reference: women_promoted_rate_reference,
      reference_unit: t(translationPrefix + "chart5.unit"),
      reference_text: t(translationPrefix + "chart5.subtext"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [collect, socialReference, socialPeopleInventoryScore])

  const chart6 = useCallback(() => {
    let labels = [
      t(translationPrefix + `chart6.female_excom`),
      t(translationPrefix + `chart6.male_excom`),
    ]

    const cio_male = socialReference["odd5_parity_IT_cio_male"]
    const cio_female = socialReference["odd5_parity_IT_cio_female"]
    const cio_male_excom = socialReference["odd5_parity_IT_cio_male_excom"]
    const cio_female_excom = socialReference["odd5_parity_IT_cio_female_excom"]
    const cio_excom = Number(cio_female_excom) + Number(cio_male_excom)

    const bar_data = {
      label: t(translationPrefix + "chart6.excom"),
      data: [
        Math.round((Number(cio_female_excom) / cio_excom) * 100),
        Math.round((Number(cio_male_excom) / cio_excom) * 100),
      ],
      suffix: t(translationPrefix + "chart6.unit_right_axis"),
    }

    const line_data = {
      label: t(translationPrefix + "chart6.number"),
      data: [cio_female, cio_male],
      suffix: "",
    }

    const data = { bar_data, line_data }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart6.help")}
      >
        <MixedChart
          title={t(translationPrefix + "chart6.title")}
          data_inputs={data}
          labels={labels}
          unit_right_axis={t(translationPrefix + "chart6.unit_right_axis")}
          unit_left_axis=""
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [socialReference])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd5_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective5Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective5Score
