import { Card } from "@shopify/polaris"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ConfirmationCardAtom = ({ cancel, validate, children, actionName }) => {
  const { t } = useTranslation()

  const [loading, setIsLoading] = useState(false)

  const edit = async () => {
    try {
      setIsLoading(true)
      await validate()
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card
      title="Confirmation"
      secondaryFooterActions={[
        {
          content: t(`cancel`),
          onAction: () => cancel(),
        },
      ]}
      primaryFooterAction={{
        content: t(`common:${actionName}`),
        destructive: true,
        loading: loading,
        onAction: edit,
      }}
    >
      <Card.Section>
        <p>{children}</p>
      </Card.Section>
    </Card>
  )
}

ConfirmationCardAtom.propTypes = {
  cancel: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  actionName: PropTypes.string,
}

ConfirmationCardAtom.defaultProps = {
  actionName: "save",
}

export default ConfirmationCardAtom
