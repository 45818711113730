import Config from "assets/configs/score.js"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Bar } from "react-chartjs-2"
import "stylesheets/ScorePage/BarChart.scss"
import { useParams } from "react-router"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { sortData } from "utils/functions/charts/ChartsFunctions"
import { BAR_CHART_OPTIONS_DEFAULTS } from "components/widgets/ScorePage/widgets/ChartOptionDefaults"
import { chartTooltipsLabelGenerator } from "./chartTooltipsLabelGenerator"

const colorOpacity = (colors, opacity) =>
  colors.map((color) => {
    if (color.length === 7) return color + opacity

    if (color.length === 4) {
      const firstPart = color.slice(1, 4)
      return "#" + firstPart + firstPart + opacity
    }
  })
const BarChart = ({
  title,
  data,
  config,
  dataTitle,
  referenceTitle,
  axesLabel,
  displayYAxe,
  displayLegend,
  doSortData,
}) => {
  const { pillarId } = useParams()
  const { t } = useTranslation()
  const generateData = useCallback(() => {
    let labels = []
    let barData = []
    let referenceData = []
    let colors = []
    let colorsBorders = []
    let borderWith = []

    data = doSortData ? sortData(data) : data

    data.forEach((value, index) => {
      labels.push(value.label)
      barData.push(value.data)
      if (value.color) colors.push(value.color)
      else
        colors.push(Config.common.colors[index % Config.common.colors.length])

      if (value.border) {
        colorsBorders.push("#E44E58")
        borderWith.push(2.5)
      } else {
        borderWith.push(0)
        colorsBorders.push("#3772FF")
      }

      if (value.reference) {
        referenceData.push(value.reference)
      }
    })

    let datasets = [
      {
        label: dataTitle,
        data: barData,
        order: 10,
        borderSkipped: true,
        backgroundColor: colorOpacity(colors, "FF"),
        hoverBackgroundColor: colorOpacity(colors, "BB"),
        borderColor: colorsBorders,
        borderWidth: borderWith,
      },
    ]

    if (referenceData.length > 0) {
      datasets.push({
        type: "line",
        label: referenceTitle,
        data: referenceData,
        order: 5,
        fill: false,
        pointStyle: "line",
        pointRadius: 20,
        pointHoverRadius: 20,
        pointBorderWidth: 20,
        pointHoverBorderWidth: 20,
        pointBorderColor: "#333C37",
        showLine: false,
        borderColor: "#333C37",
        ticks: { min: 0 },
      })
    }

    return {
      labels: labels,
      datasets: datasets,
    }
  }, [data])

  const generateOptions = useCallback(() => {
    let displayAxes = false
    let boxWidth = referenceTitle
      ? 2
      : Chart.defaults.global.legend.labels.boxWidth

    if (axesLabel) {
      displayAxes = true
    }

    let options
    if (title != t("score:score.6.chart3.title")) {
      options = {
        maintainAspectRatio: false,
        legend: {
          display: displayLegend,
          position: "bottom",
          labels: {
            boxWidth: boxWidth,
            fontStyle: "italic",
          },
        },
        scales: {
          yAxes: [
            {
              display: displayYAxe,
              scaleLabel: {
                display: displayAxes,
                labelString: axesLabel,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: { label: chartTooltipsLabelGenerator(axesLabel) },
        },
      }
    } else {
      options = {
        maintainAspectRatio: false,
        legend: {
          display: displayLegend,
          position: "bottom",
          labels: {
            boxWidth: boxWidth,
            fontStyle: "italic",
          },
        },
        scales: {
          yAxes: [
            {
              display: displayYAxe,
              scaleLabel: {
                display: displayAxes,
                labelString: axesLabel,
              },
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              if (tooltipItem.label == "GCP") {
                return `A`
              } else if (tooltipItem.label == "Azure") {
                return `B`
              } else if (
                tooltipItem.label == "AWS" ||
                tooltipItem.label == "OVH" ||
                tooltipItem.label == "IBM"
              ) {
                return `C`
              } else if (
                tooltipItem.label == "Alibaba" ||
                tooltipItem.label == "Oracle"
              ) {
                return `D`
              }
            },
          },
        },
      }
    }
    return _.merge({}, BAR_CHART_OPTIONS_DEFAULTS, options, config)
  }, [config, axesLabel, displayYAxe])

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar data={generateData()} options={generateOptions()} />
      </div>
    </div>
  )
}

BarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  dataTitle: PropTypes.string,
  referenceTitle: PropTypes.string,
  axesLabel: PropTypes.string,
  displayYAxe: PropTypes.bool,
  displayLegend: PropTypes.bool,
  doSortData: PropTypes.bool,
}

BarChart.defaultProps = {
  config: {},
  displayLegend: false,
  displayYAxe: false,
  doSortData: false,
  referenceTitle: null,
  dataTitle: "",
}

export default BarChart
