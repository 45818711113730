import PropTypes from "prop-types"
import React from "react"
import "stylesheets/blocs/templates/DropZoneCaptionTemplate.scss"

const DropZoneCaptionTemplate = ({ children }) => {
  return <div className="dropzone-caption-template">{children}</div>
}

DropZoneCaptionTemplate.propTypes = {
  children: PropTypes.node,
}

export default DropZoneCaptionTemplate
