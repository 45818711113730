import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { COLORS } from "utils/constants/colors"
import { DEFAULT_OPTIONS } from "services/charts/default"
import Config from "assets/configs/score"
import { DEFAULT_PLUGINS } from "services/charts/plugins/default"

const ChartAnalysisCloudServicesAndCosts = ({
  datasets,
  unitKey,
  lineUnitKey,
  title,
}) => {
  const { t } = useTranslation()

  const unit = t(`units:units.emission.${unitKey}`)
  const lineUnit = t(`units:units.money.${lineUnitKey}`)

  const data = {
    labels: datasets.map((dataset) => dataset.service_name),
    datasets: [
      {
        label: t(
          "analyze:it_domain.cloud.charts.services_and_costs.labels.emissions"
        ),
        data: datasets.map((dataset) => dataset.co2e),
        unit,
        backgroundColor: Config.common.colors[0],
        borderColor: COLORS.white,
        borderWidth: 1,
        stack: "default",
        showUnitOnDatalabel: true,
        type: "bar",
        order: 2,
      },
      {
        label: t(
          "analyze:it_domain.cloud.charts.services_and_costs.labels.cost"
        ),
        isLineDataset: true,
        data: datasets.map((dataset) => dataset.cost),
        borderColor: Config.common.colors[1],
        borderWidth: 2,
        stack: "default",
        yAxisID: "y-axis-2",
        fill: false,
        type: "line",
        order: 1,
        datalabels: { display: false },
        unit: lineUnit,
      },
    ],
  }

  const options = {
    ...DEFAULT_OPTIONS,
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-1",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: unit,
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-2",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: lineUnit,
          },
        },
      ],
    },
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", PILLAR_ENVIRONMENTAL)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar options={options} data={data} plugins={DEFAULT_PLUGINS} />
      </div>
    </div>
  )
}

ChartAnalysisCloudServicesAndCosts.propTypes = {
  datasets: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  unitKey: PropTypes.string.isRequired,
  lineUnitKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ChartAnalysisCloudServicesAndCosts
