import {
  Banner,
  Button,
  DropZone,
  Form,
  FormLayout,
  Layout,
  Modal,
  Stack,
} from "@shopify/polaris"
import { useParams } from "react-router-dom"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import useAutofillCollects from "hooks/useAutofillCollects"
import { useAppContext } from "components/AppContext"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useCollectContext } from "./CollectContextProvider"

const convertJsonToList = (json) => {
  var items = []
  var count = 0
  Object.entries(json).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      items.push(
        <li key={count}>
          <code>{key}</code> {convertJsonToList(value)}
        </li>
      )
    } else {
      items.push(
        <li key={count}>
          <code>{key}</code> {value}
        </li>
      )
    }
    count += 1
  })
  return <ul>{items}</ul>
}

// Button to load tests files and accelerate data collect
// Activated if box checked in user panel, if admin
const AutofillButton = () => {
  const { pillarId } = useParams()

  const { t } = useTranslation()
  const { currentUser } = useAppContext()
  const { autofillEnv, autofillSocial, autofillProfit } = useAutofillCollects(
    currentUser
  )

  const { entity, setCollect, reloadProgress } = useCollectContext()
  const [active, setActive] = useState(false)
  const [bannerMarkup, setBannerMarkup] = useState(null)

  const handleChange = useCallback(() => setActive(!active), [active])

  const sendJson = (jsonData) => {
    const data = JSON.parse(jsonData)

    entity
      .put(data)
      .then((response) => {
        setCollect(response.data)
        reloadProgress()
        setBannerMarkup(
          <Banner status="success">{t("collect:autofill.sent")}</Banner>
        )
      })
      .catch((error) => {
        if (error.response.data) {
          setBannerMarkup(
            <Banner status="critical">
              {convertJsonToList(error.response.data)}
            </Banner>
          )
        } else {
          setBannerMarkup(
            <Banner status="critical">
              {t("collect:autofill.errors.unknown")}
            </Banner>
          )
        }
      })
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = (evt) => {
        try {
          const json = evt.target.result
          sendJson(json)
        } catch (e) {
          setBannerMarkup(<Banner status="critical">{e.message}</Banner>)
        }
      }
      reader.onerror = function () {
        setBannerMarkup(
          <Banner status="critical">
            {t("collect:autofill.cannot_read_file")}
          </Banner>
        )
      }
    },
    [pillarId]
  )

  const activator = (
    <Button onClick={handleChange}>
      {t("collect:autofill:button:title") + " " + pillarId}
    </Button>
  )

  if (
    (pillarId === PILLAR_ENVIRONMENTAL && autofillEnv) ||
    (pillarId === PILLAR_SOCIAL && autofillSocial) ||
    (pillarId === PILLAR_PROFIT && autofillProfit)
  ) {
    return (
      <Layout.Section>
        <Stack distribution="trailing">
          <Modal
            activator={activator}
            accept="json"
            open={active}
            onClose={handleChange}
            title={t("collect:autofill.title")}
          >
            <Modal.Section>
              <Form>
                <FormLayout>
                  {bannerMarkup}
                  <DropZone
                    allowMultiple={false}
                    onDropAccepted={handleDrop}
                    accept="application/json"
                  >
                    <DropZone.FileUpload />
                  </DropZone>
                </FormLayout>
              </Form>
            </Modal.Section>
          </Modal>
        </Stack>
      </Layout.Section>
    )
  } else {
    return null
  }
}

export default AutofillButton
