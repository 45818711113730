import React, { useEffect, useState } from "react"
import { Card } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import Loading from "components/widgets/Loading"
import UsersEntity, { sortByLastNameAsc } from "services/entities/UsersEntity"
import ModalDeleteOrganism from "views/organisms/user/list/ModalDeleteOrganism"
import TableUserListOrganism from "views/organisms/user/list/TableUserListOrganism"
import TwoColumnsOneSectionTemplate from "views/templates/TwoColumnsOneSectionTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import { useEntity } from "hooks/useAxios"
import { useAppContext } from "components/AppContext"
import useAdminRules from "hooks/useAdminRules"

const UserListPage = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [users, setUsers] = useState([])
  const [userToDelete, setUserToDelete] = useState(null)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const userEntity = useEntity(UsersEntity)

  const { t } = useTranslation()

  const { currentUser } = useAppContext()
  const { isUserAdmin } = useAdminRules()

  useEffect(async () => {
    if (!currentUser) return
    await loadCompanyUsers()
  }, [currentUser])

  const loadCompanyUsers = async () => {
    const response = await userEntity.getCurrentRootNodeUsers()

    setUsers(sortByLastNameAsc(response.data))
    setIsFetching(false)
  }

  const onDeleteClick = (user) => {
    setUserToDelete(user)
    setShowModalDelete(true)
  }

  const handleCloseDeleteModal = () => {
    setShowModalDelete(false)
    setUserToDelete(null)
    setErrorMessage(null)
  }

  const handleDeleteCompanyUser = async () => {
    try {
      setErrorMessage(null)
      setShowModalDelete(false)
      setIsFetching(true)
      await userEntity.deleteUserCompany(userToDelete)

      setUserToDelete(null)

      await loadCompanyUsers()
      setIsFetching(false)
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  return (
    <TwoColumnsOneSectionTemplate
      title={t("common:user_list.title")}
      subtitle={t("common:user_list.section_title")}
      description={t("common:user_list.section_description")}
    >
      <Card>
        <Card.Section>
          {isFetching ? (
            <Loading />
          ) : (
            <TableUserListOrganism
              onDeleteClick={onDeleteClick}
              users={users}
              canCreate={isUserAdmin}
              canUpdate={isUserAdmin}
              canRead={isUserAdmin}
              canDelete={isUserAdmin}
            />
          )}
        </Card.Section>
      </Card>

      {!!userToDelete && (
        <ModalDeleteOrganism
          show={showModalDelete}
          errorMessage={errorMessage}
          email={userToDelete.email}
          onDelete={handleDeleteCompanyUser}
          onClose={handleCloseDeleteModal}
        />
      )}
    </TwoColumnsOneSectionTemplate>
  )
}

export default withAuthRequired(UserListPage)
