import React from "react"
import { TextField } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const TextAreaFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
  multiline,
}) => {
  const { t } = useTranslation()

  return (
    <TextField
      label={t(`activation:${type}.questions.${property}.label`)}
      id={property}
      name={property}
      value={value}
      error={error}
      onChange={onChange}
      type="text"
      inputMode="text"
      required
      multiline={multiline}
      disabled={disabled}
    />
  )
}

TextAreaFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.number,
}

TextAreaFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
  multiline: 5,
}

export default TextAreaFieldAtom
