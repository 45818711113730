import PropTypes from "prop-types"
import React from "react"
import { COLORS } from "utils/constants/colors"

function DottedButton({ onClick, position, color }) {
  return (
    <svg
      className={`oci oci-${position}`}
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_215_1240)">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V3Z"
          fill={COLORS.white}
        />
        <path
          d="M24 2.25V18.75C24 19.9922 22.8482 21 21.4286 21H2.57143C1.15179 21 0 19.9922 0 18.75V2.25C0 1.00781 1.15179 0 2.57143 0H21.4286C22.8482 0 24 1.00781 24 2.25ZM21.4286 18.4688V2.53125C21.4286 2.37656 21.2839 2.25 21.1071 2.25H2.89286C2.71607 2.25 2.57143 2.37656 2.57143 2.53125V18.4688C2.57143 18.6234 2.71607 18.75 2.89286 18.75H21.1071C21.2839 18.75 21.4286 18.6234 21.4286 18.4688Z"
          fill={color}
        />
        <path
          d="M6.28031 9.536V12H8.79231V9.536H6.28031ZM10.7334 9.536V12H13.2454V9.536H10.7334ZM15.1866 9.536V12H17.6986V9.536H15.1866Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_215_1240">
          <path
            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V3Z"
            fill={COLORS.white}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

DottedButton.propTypes = {
  onClick: PropTypes.func,
  position: PropTypes.string,
  color: PropTypes.string,
}

DottedButton.defaultProps = {
  position: "bottom",
  color: COLORS.social.c30,
}

export default DottedButton
