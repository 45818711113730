import PropTypes from "prop-types"
import React from "react"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import { COLORS } from "utils/constants/colors"

const ChartODD13DatacenterEquipmentAverageGHGMolecule = ({
  mixedChart = [],
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const labels = mixedChart.map((node) =>
    NodeOrganization.getName(node.label, node.type, {
      countryShortName: true,
    })
  )
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const bar_data = {
    label: t(
      translationPrefix + "chartDatacenterEquipmentAverageGHG.legend.bar"
    ),
    data: bar,
  }

  const line_data = {
    label: t(
      translationPrefix + "chartDatacenterEquipmentAverageGHG.legend.line"
    ),
    data: line,
  }

  const data = { bar_data, line_data }

  return (
    <MixedChart
      title={t(translationPrefix + "chartDatacenterEquipmentAverageGHG.title")}
      data_inputs={data}
      labels={labels}
      unit_left_axis={t(
        translationPrefix + "chartDatacenterEquipmentAverageGHG.unit_left_axis"
      )}
      unit_right_axis={t(
        translationPrefix + "chartDatacenterEquipmentAverageGHG.unit_right_axis"
      )}
      legendModifiers={[
        (legendItem) => (legendItem.fillStyle = COLORS.planet.c10),
      ]}
    />
  )
}

ChartODD13DatacenterEquipmentAverageGHGMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
}

export default ChartODD13DatacenterEquipmentAverageGHGMolecule
