import { Card, Collapsible, Stack } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import "stylesheets/centralization.scss"
import useCentralizationForm from "hooks/useCentralizationForm"
import PillarCentralizationLine from "views/molecules/centralization/PIllarCentralizationLine"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import { isNodeLeaf } from "utils/constants/organization/nodeTypes"
import { useNodeContext } from "views/organisms/interactive/NodeContext"
import PillarCentralizationButton from "views/molecules/centralization/PillarCentralizationButton"
import CentralizationMessage from "views/molecules/centralization/CentralizationMessage"

const PillarCentralization = ({
  title,
  pillar,
  centralizationRules,
  onChange,
  frozen,
  disabled,
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { node } = useNodeContext()

  const {
    hasCentralizedRules,
    hasSnowRunCentralization,
  } = useCentralizationForm(disabled)

  useEffect(() => {
    if (!open) {
      setOpen(hasCentralizedRules(centralizationRules))
    }
  }, [])

  const renderBanners = () => {
    const banners = []

    if (hasSnowRunCentralization && pillar === PILLAR_ENVIRONMENTAL) {
      banners.push(
        <CentralizationMessage
          text={t(`dashboard:modals.banners.it_governance.title`)}
        />
      )
    }

    if (frozen) {
      banners.push(
        <CentralizationMessage
          text={t(`dashboard:modals.banners.governance_frozen.title`)}
        />
      )
    }

    return banners
  }

  const headerKey = isNodeLeaf(node) ? "governed_by" : "governed"

  const banners = renderBanners()

  return (
    <div className={`centralization-form ${pillar}`}>
      <Card sectioned>
        <Stack vertical>
          <PillarCentralizationButton
            title={title}
            onClick={() => setOpen(!open)}
            hasCentralizationRules={!!centralizationRules.length}
          />

          {!isNodeLeaf(node) && banners}
          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <table className={`Verdikt-DynamicTable short ${pillar}`}>
              <thead>
                <tr className="leftAligned">
                  <th />
                  <th>
                    {t(
                      `organization:centralization.table.headers.${headerKey}`
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {centralizationRules.map((rule) => (
                  <PillarCentralizationLine
                    key={rule.key}
                    centralizationKey={rule.key}
                    name={rule.name}
                    value={rule.value}
                    providerName={rule.providerName}
                    disabled={disabled || rule.disabled}
                    onCheckboxChange={onChange}
                  />
                ))}
              </tbody>
            </table>
          </Collapsible>
        </Stack>
      </Card>
    </div>
  )
}

PillarCentralization.propTypes = {
  title: PropTypes.string.isRequired,
  pillar: PropTypes.string.isRequired,
  centralizationRules: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOf(["centralized", "inherited", "not_centralized"])
        .isRequired,
      providerName: PropTypes.string,
      disabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  frozen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PillarCentralization
