import React from "react"
import { Banner, Button, Icon } from "@shopify/polaris"
import { CircleCancelMinor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import "stylesheets/blocs/organisms/organizations/TableMemberListOrganism.scss"

const TableMemberListOrganism = ({
  members,
  onRemove,
  isExternal,
  disabled,
}) => {
  const { t } = useTranslation()

  const { currentUser } = useAppContext()

  if (members.length === 0)
    return (
      <Banner status="info">
        {t("activation:common.members.table.empty")}
      </Banner>
    )

  const generateUserLine = (member, index) => {
    const removePossible =
      !disabled && currentUser.id !== member.id && !member.assigned_on_parent

    return (
      <tr key={index} className={`${member.assigned_on_parent && "disabled"}`}>
        <td>{member.email}</td>
        <td>{member.lastname ?? "-"}</td>
        <td>{member.firstname ?? "-"}</td>
        <td>{t(`common:user_form.roles.${member.role}`)}</td>
        <td>
          {removePossible && (
            <Button plain onClick={() => onRemove(member.id)}>
              <Icon source={CircleCancelMinor} />
            </Button>
          )}
        </td>
      </tr>
    )
  }

  return (
    <>
      <table className={`user-list ${isExternal && "external"}`}>
        <thead>
          <tr className="leftAligned">
            <th>{t("common:user_list.table_header.email")}</th>
            <th>{t("common:user_list.table_header.lastname")}</th>
            <th>{t("common:user_list.table_header.firstname")}</th>
            <th>{t("common:user_list.table_header.role")}</th>
            <th />
          </tr>
        </thead>
        <tbody>{members.map(generateUserLine)}</tbody>
      </table>
      <div className="member-list__legend">
        <div className="member-list__legend__item">
          <div className="member-list__legend__item__color" />
          <div className="member-list__legend__item__label">
            {t("dashboard:modals.legends.inherited_users")}
          </div>
        </div>
      </div>
    </>
  )
}

TableMemberListOrganism.propTypes = {
  isExternal: PropTypes.bool,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      firstname: PropTypes.string,
      assigned_on_parent: PropTypes.bool.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

TableMemberListOrganism.defaultProps = {
  disabled: false,
  isExternal: false,
}

export default TableMemberListOrganism
