import React from "react"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import Unit from "views/atoms/reports/Unit"

const TCO2e = () => {
  return <Unit unit={NumberFormatterApp.getI18nUnit(EMISSION_UNIT, "t_unit")} />
}

export default TCO2e
