import PropTypes from "prop-types"
import React from "react"
import "stylesheets/blocs/organisms/organizationCharts/BreadcrumbOrganizationChartOrganism.scss"
import ItemBreadcrumbOrganizationChartOrganism from "views/atoms/organizationCharts/ItemBreadcrumbOrganizationChartOrganism"
import { useAppContext } from "components/AppContext"
import { MobileCancelMajor } from "@shopify/polaris-icons"
import { Icon } from "@shopify/polaris"
import className from "classnames"

const BreadcrumbOrganizationChartOrganism = ({
  pathArray,
  redirectDisabled,
  onDelete,
  node,
  italic,
}) => {
  const { updateCurrentNode } = useAppContext()

  if (!pathArray) return null

  return (
    <article
      className={className("organizationPath organizationPath__content", {
        "organizationPath__content--italic": italic,
      })}
    >
      {onDelete && (
        <button
          className="organizationPath--deletion"
          onClick={() => onDelete(node)}
        >
          <Icon source={MobileCancelMajor} />
        </button>
      )}

      {/*@todo accessibility, replace by a button the article tag */}
      <p>
        {pathArray.map((node, idx) => (
          <ItemBreadcrumbOrganizationChartOrganism
            key={idx}
            firstItem={idx === 0}
            isLastItem={idx === pathArray.length - 1}
            node={node}
            onClick={updateCurrentNode}
            disabled={redirectDisabled || !node.can_view}
          />
        ))}
      </p>
    </article>
  )
}
BreadcrumbOrganizationChartOrganism.propTypes = {
  pathArray: PropTypes.array.isRequired,
  redirectDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  node: PropTypes.object,
  italic: PropTypes.bool,
}

BreadcrumbOrganizationChartOrganism.defaultProps = {
  redirectDisabled: false,
  italic: false,
}

export default BreadcrumbOrganizationChartOrganism
