import ScoreWidget from "components/widgets/ScorePage/widgets/ScoreWidget"
import PropTypes from "prop-types"
import React from "react"
import { useParams } from "react-router-dom"
import "stylesheets/Score.scss"
import classNames from "classnames"
import { useAppContext } from "components/AppContext"
import {
  HIDE_PROVIDER_MATURITY_FEATURE,
  useFeatureFlag,
} from "hooks/useFeatureFlag"
import * as Sentry from "@sentry/react"
import ScoreChartErrorBoundary from "components/ErrorBoundary/ScoreChartErrorBoundary"

const ScoreLayoutOrganism = ({
  maturity,
  chart1,
  chart2,
  chart3,
  chart4,
  chart5,
  chart6,
  pillarId,
  forceMaturity,
}) => {
  const { pillarId: urlPillarId } = useParams()
  const { currentRootNode, currentOrganization } = useAppContext()
  const { isFeatureActive } = useFeatureFlag()

  const showScoreWidget =
    maturity !== null &&
    !(
      (currentRootNode.is_provider || currentOrganization.is_partial_partner) &&
      isFeatureActive(HIDE_PROVIDER_MATURITY_FEATURE) &&
      !forceMaturity
    )

  return (
    <div className="Verdikt-Score">
      <div className={classNames(urlPillarId || pillarId, "page-frame")}>
        <div className="page">
          <div className="left">
            <div className="row">
              {showScoreWidget && <ScoreWidget maturity={maturity} />}
              <div
                id="widget1"
                className={classNames(
                  urlPillarId || pillarId,
                  "widget",
                  "chart"
                )}
              >
                <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                  {chart1}
                </Sentry.ErrorBoundary>
              </div>
              <div
                id="widget2"
                className={classNames(
                  urlPillarId || pillarId,
                  "widget",
                  "chart"
                )}
              >
                <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                  {chart2}
                </Sentry.ErrorBoundary>
              </div>
            </div>
            <div className="row">
              <div
                id="widget4"
                className={classNames(
                  urlPillarId || pillarId,
                  "widget",
                  "chart"
                )}
              >
                <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                  {chart4}
                </Sentry.ErrorBoundary>
              </div>
              <div
                id="widget5"
                className={classNames(
                  urlPillarId || pillarId,
                  "widget",
                  "chart"
                )}
              >
                <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                  {chart5}
                </Sentry.ErrorBoundary>
              </div>
            </div>
          </div>
          <div className="right">
            <div
              id="widget3"
              className={classNames(urlPillarId || pillarId, "widget", "chart")}
            >
              <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                {chart3}
              </Sentry.ErrorBoundary>
            </div>
            <div
              id="widget6"
              className={classNames(urlPillarId || pillarId, "widget", "chart")}
            >
              <Sentry.ErrorBoundary fallback={<ScoreChartErrorBoundary />}>
                {chart6}
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ScoreLayoutOrganism.propTypes = {
  maturity: PropTypes.number,
  chart1: PropTypes.element.isRequired,
  chart2: PropTypes.element.isRequired,
  chart3: PropTypes.element.isRequired,
  chart4: PropTypes.element.isRequired,
  chart5: PropTypes.element.isRequired,
  chart6: PropTypes.element.isRequired,
  pillarId: PropTypes.string,
  forceMaturity: PropTypes.bool,
}

ScoreLayoutOrganism.defaultProps = {
  maturity: null,
  pillarId: null,
  forceMaturity: false,
}

export default ScoreLayoutOrganism
