import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

const NO_REDIRECTION = ""
export const DASHBOARD_PAGE = "/dashboard"
export const COLLECT_PAGE = "/collect"

const useRedirection = ({ disabledFeatures, score, currentNode }) => {
  const [redirection, setRedirection] = useState(NO_REDIRECTION)
  const history = useHistory()

  useEffect(() => {
    if (!redirection.includes(DASHBOARD_PAGE) || disabledFeatures.score) return

    history.push(redirection)
    setRedirection(NO_REDIRECTION)
  }, [score, disabledFeatures.score])

  useEffect(() => {
    if (!redirection.includes(COLLECT_PAGE) || disabledFeatures.collect) return

    history.push(redirection)
    setRedirection(NO_REDIRECTION)
  }, [currentNode, disabledFeatures.collect])

  const redirect = (pathname) => {
    if (pathname.includes(COLLECT_PAGE)) {
      disabledFeatures.collect
        ? setRedirection(pathname)
        : history.push(pathname)
      return
    }
    if (pathname.includes(DASHBOARD_PAGE)) {
      disabledFeatures.score ? setRedirection(pathname) : history.push(pathname)
    }
  }

  return redirect
}

export default useRedirection
