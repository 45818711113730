import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import ChartNodeMolecule from "views/molecules/organization/ChartNodeMolecule"
import "stylesheets/widgets/Dashboard/ChartContainer.scss"
import HistorizationNavigationMolecule from "views/molecules/historizations/Navigation/HistorizationNavigationMolecule"
import HeaderChartMolecule from "views/molecules/organization/charts/HeaderChartMolecule"
import { TreeContextActionsMolecule } from "views/molecules/organization/charts/TreeContextActionsMolecule"
import { useAppContext } from "components/AppContext"

const ChartContainerOrganism = () => {
  const container = useRef()
  const chart = useRef()

  const { currentPeriod } = useAppContext()
  const { organize, organization } = useChart()

  const rightElements = currentPeriod ? (
    <HistorizationNavigationMolecule organize={organize} />
  ) : (
    <></>
  )

  return (
    <div ref={container} className="orgchart-container">
      <HeaderChartMolecule
        leftElements={<TreeContextActionsMolecule organize={organize} />}
        rightElements={rightElements}
      />

      {organization && (
        <div ref={chart} className="orgchart">
          <ul>
            <ChartNodeMolecule node={organization} />
          </ul>
        </div>
      )}
    </div>
  )
}

ChartContainerOrganism.propTypes = {
  currentNode: PropTypes.object,
  toastStatus: PropTypes.number,
  setToastStatus: PropTypes.func,
}

ChartContainerOrganism.defaultProps = {
  setToastStatus: () => {},
}

export default ChartContainerOrganism
