import React from "react"
import PropTypes from "prop-types"
import IconTableSubscriptionAtom from "views/atoms/subscription/IconTableSubscriptionAtom"
import { useTranslation } from "react-i18next"

const TableSubscriptionOrganism = ({
  headerCells,
  linesHeaderCells,
  lines,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <table className="subscription-features">
        <thead>
          <tr>
            <th />
            {headerCells.map((key, index) => (
              <th key={index}>
                {t(`subscription:features.table.header.${key}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {linesHeaderCells.map((key, index) => (
            <tr key={index}>
              <th>{t(`subscription:features.table.header_lines.${key}`)}</th>
              <td>
                <IconTableSubscriptionAtom valid={lines[0][index]} />
              </td>
              <td>
                <IconTableSubscriptionAtom valid={lines[1][index]} />
              </td>
              <td>
                <IconTableSubscriptionAtom valid={lines[2][index]} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

TableSubscriptionOrganism.propTypes = {
  headerCells: PropTypes.array.isRequired,
  linesHeaderCells: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
}

export default TableSubscriptionOrganism
