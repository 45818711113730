import React, { useCallback } from "react"
import PropTypes from "prop-types"

const SCRIPTS_TO_REPLACE = {
    '₂': <sub>2</sub>,
    '²': <sup>2</sup>,
    '³': <sup>3</sup>,
}

const replaceCharacterWithSubscript = (string, characterToReplace, replacement) => (
    string.split(characterToReplace)
        .reduce((acc, item) => (
            acc === null
                ? <>{item}</>
                : <>{acc}{replacement}{item}</>
        ), null)
    )

const Unit = ({ unit }) => {
    const replaceScripts = useCallback(() => {
        for (const [key, value] of Object.entries(SCRIPTS_TO_REPLACE)) {
            if (unit.includes(key)){
                return replaceCharacterWithSubscript(unit, key, value)
            }
        }

        return (unit)
    }, [unit])

    return replaceScripts()
}

Unit.propTypes = {
    unit: PropTypes.string.isRequired,
}

export default Unit;