import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import PropTypes from "prop-types"
import { useAppContext } from "components/AppContext"
import { useEntity } from "../../../../hooks/useAxios"
import { useCollectContext } from "../CollectContextProvider"
import CentralizationEntity from "services/entities/CentralizationEntity"

const ModuleContext = createContext({
  moduleIsActive: true,
})

export const useModuleContext = () => {
  return useContext(ModuleContext)
}

export const useCollectModules = () => {
  const { currentNode, envCollect } = useAppContext()
  const centralizationEntity = useEntity(CentralizationEntity)

  const [modules, setModules] = useState({})

  useEffect(async () => {
    if (!currentNode) return

    const { data } = await centralizationEntity.index()
    setModules({ ...modules, ...data })
  }, [currentNode, envCollect])

  const isModuleActive = useCallback(
    (moduleName) => !modules?.[moduleName] || modules?.[moduleName].active,
    [modules]
  )

  return {
    modules,
    isModuleActive,
  }
}

export const Module = ({ module, children }) => {
  const { isModuleActive } = useCollectContext()

  return (
    <ModuleContext.Provider
      value={{
        module,
        moduleIsActive: isModuleActive(module),
      }}
    >
      <div className={!isModuleActive(module) ? "inactive-module" : ""}>
        {children}
      </div>
    </ModuleContext.Provider>
  )
}

Module.propTypes = {
  module: PropTypes.string,
  children: PropTypes.node,
}
