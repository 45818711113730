import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import "stylesheets/blocs/molecules/interactive/ButtonIconMolecule.scss"
import { Tooltip } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import {
  EditMajor,
  FolderUpMajor,
  SearchMajor,
  PageDownMajor,
  BugMajor,
  RefreshMajor,
  ViewMajor,
} from "@shopify/polaris-icons"

/**
 * @description This component gives a style and tooltip to an icon
 * @param {Function} handleModalActivation Toggles the activation of the modal in parent component
 * @param {String} iconName { "EditMajor" | "FolderUpMajor" } The name of a Polaris icon
 * @returns
 */
const ButtonIconMolecule = ({ onClick, iconName }) => {
  const { t } = useTranslation()
  const [tooltipMessage, setTooltipMessage] = useState("")
  const [iconComponent, setIconComponent] = useState(null)

  /**
   *
   * @description Sets the icon component and the tooltip message depending on the icon string passed as prop
   */
  const setIconAndTooltip = () => {
    switch (iconName) {
      case "EditMajor":
        setIconComponent(<EditMajor />)
        setTooltipMessage(t(`dashboard:chart.buttons.edit_button`))
        return
      case "ViewMajor":
        setIconComponent(<ViewMajor />)
        setTooltipMessage(t(`dashboard:chart.buttons.view_button`))
        return
      case "FolderUpMajor":
        setIconComponent(<FolderUpMajor />)
        setTooltipMessage(t(`dashboard:chart.buttons.inventory_button`))
        return
      case "SearchMajor":
        setIconComponent(<SearchMajor />)
        setTooltipMessage(
          t(`dashboard:chart.buttons.detailed_dashboard_button`)
        )
        return
      case "PageDownMajor":
        setIconComponent(<PageDownMajor />)
        setTooltipMessage(
          t(`dashboard:chart.buttons.download_inventory_file_button`)
        )
        return
      case "BugMajor":
        setIconComponent(<BugMajor />)
        setTooltipMessage(
          t(`dashboard:chart.buttons.download_error_log_button`)
        )
        return
      case "RefreshMajor":
        setIconComponent(<RefreshMajor />)
        setTooltipMessage(t(`dashboard:chart.buttons.refresh_button`))
        return
      default:
        return
    }
  }

  /* Set icon and tooltip on component load */
  useEffect(() => {
    setIconAndTooltip()
  }, [])

  return (
    <article className={"buttonIconMolecule buttonIconMolecule--outline"}>
      <Tooltip light dismissOnMouseOut content={tooltipMessage}>
        <div
          className="buttonIcon buttonIcon__icon-container"
          onClick={onClick}
        >
          {iconComponent}
        </div>
      </Tooltip>
    </article>
  )
}

ButtonIconMolecule.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ButtonIconMolecule
