import React, { useCallback } from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import PropTypes from "prop-types"
import CountryApp from "services/apps/countryApp"
import Config from "assets/configs/score.js"
import { useTranslation } from "react-i18next"
import { PILLAR_ENVIRONMENTAL, PILLARS } from "utils/constants/pillars"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { KPI_STATUS_GHG_TREND_TO_COLOR } from "utils/constants/analyze/kpi/kpiStatus"
import i18n from "i18next"
import { COLORS } from "utils/constants/colors"

const ChartBarStackedGroupedMolecule = ({
  title,
  pillarId,
  config,
  datalabelsFormatter,
  labels,
  statusColor,
  emissionUnit,
}) => {
  const { t } = useTranslation()

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        generateLabels: labels,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            max: 100,
            callback: (value) => `${value}%`, // Add "%" symbol to tick values
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        color: COLORS.white,
        font: {
          weight: "bold",
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) =>
          `${data.datasets[tooltipItem.datasetIndex].label} : ${
            data.datasets[tooltipItem.datasetIndex].emissions[tooltipItem.index]
          } ${NumberFormatterApp.getI18nUnit("emission", emissionUnit)}`,
      },
    },
  }

  options.plugins.datalabels.formatter = datalabelsFormatter

  const getLabel = (label, type) => {
    const name = CountryApp.getNameI18n(label) || label
    return `${name ? `${name} - ` : ""}${t(
      `score:score.13.chartGHGEmissionsDistribution.${type}`
    )}`
  }

  const generateData = useCallback(() => {
    const data = {
      labels: config.labels,
      datasets: [],
    }

    data.datasets = config.datasets.map((dataset) => {
      const { values, label, type, emissions, statuses } =
        dataset.data || dataset

      const defaultBackgroundColors = new Array(values.length).fill(
        Config["13"].chartGHGEmissionsDistribution.colors[type]
      )

      const filteredData = values.map((value) => (value < 5 ? null : value))

      return {
        label: getLabel(label, type),
        data: filteredData,
        emissions: emissions,
        tension: 0.01,
        backgroundColor: statuses
          ? statuses.map(statusColor)
          : defaultBackgroundColors,
        borderColor: COLORS.white,
        color: [COLORS.white],
        borderWidth: 1,
        fill: false,
        stack: CountryApp.getNameI18n(label) || label,
      }
    })

    return data
  }, [config])

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar options={options} data={generateData()} />
      </div>
    </div>
  )
}

ChartBarStackedGroupedMolecule.propTypes = {
  config: PropTypes.object,
  title: PropTypes.string,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
  datalabelsFormatter: PropTypes.func,
  labels: PropTypes.func,
  statusColor: PropTypes.func,
  emissionUnit: PropTypes.string,
}

ChartBarStackedGroupedMolecule.defaultProps = {
  title: "",
  pillarId: PILLAR_ENVIRONMENTAL,
  datalabelsFormatter: (value, ctx) =>
    value >= 10
      ? `${ctx.dataset?.data?.[ctx.dataIndex]} ${NumberFormatterApp.getI18nUnit(
          "emission",
          "kg_unit"
        )}`
      : null,
  labels: () => [
    {
      text: i18n.t("score:score.trends.decrease"),
      fillStyle: Config.common.colorsEvaluation.green,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
    {
      text: i18n.t("score:score.trends.flat"),
      fillStyle: Config.common.colorsEvaluation.yellow2,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
    {
      text: i18n.t("score:score.trends.increase"),
      fillStyle: Config.common.colorsEvaluation.red,
      strokeStyle: COLORS.white,
      lineWidth: 1,
    },
  ],
  statusColor: (status) => KPI_STATUS_GHG_TREND_TO_COLOR[status],
  emissionUnit: "t_unit",
}

export default ChartBarStackedGroupedMolecule
