import { useAppContext } from "components/AppContext"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
  PILLARS_OBJECTIVES,
} from "utils/constants/pillars"
import { Redirect, useParams } from "react-router-dom"
import _ from "lodash"
import PropTypes from "prop-types"

const RedirectIfDisabled = ({
  pageName,
  fallback,
  children,
  redirectToObjective,
}) => {
  const { disabledFeatures } = useAppContext()
  const { pillarId } = useParams()

  const disabledByPillar = {
    [PILLAR_ENVIRONMENTAL]: disabledFeatures.envScore,
    [PILLAR_SOCIAL]: disabledFeatures.socialScore,
    [PILLAR_PROFIT]: disabledFeatures.profitScore,
  }

  if (!pillarId || !disabledByPillar[pillarId]) {
    return children
  }

  const availablePillar = _.chain(disabledByPillar)
    .keys()
    .reject((key) => disabledByPillar[key])
    .first()
    .value()

  const target = () => {
    if (!availablePillar) return fallback

    const objectiveUri = `${pageName}/${availablePillar}`
    if (!redirectToObjective) return objectiveUri

    const defaultObjective = PILLARS_OBJECTIVES[availablePillar][0]
    return `${objectiveUri}/${defaultObjective}`
  }

  return <Redirect to={target()} />
}

RedirectIfDisabled.propTypes = {
  pageName: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  children: PropTypes.node.isRequired,
  redirectToObjective: PropTypes.bool,
}

RedirectIfDisabled.defaultProps = {
  fallback: "/organize",
  redirectToObjective: false,
}

export default RedirectIfDisabled
