import React, { useEffect, useState } from "react"
import { ChoiceList, TextStyle } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import ScopeBannerAtom from "views/atoms/organization/form/ScopeBannerAtom"

const SiteAssessmentFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()

  const [initialValue] = useState(value)

  const [choiceValue, setChoiceValue] = useState(value)
  const [confirmationBanner, setConfirmationBanner] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const setValue = (value) => {
    setConfirmationBanner(`${initialValue}` !== value[0])
    setChoiceValue(value[0])
  }

  useEffect(
    () =>
      onChange(
        confirmed && `${initialValue}` !== choiceValue
          ? choiceValue
          : initialValue
      ),
    [confirmed, choiceValue]
  )

  return (
    <article>
      <ChoiceList
        title={t(`activation:${type}.questions.${property}.label`)}
        choices={[
          {
            label: t(
              `activation:${type}.questions.${property}.responses.country`
            ),
            value: "false",
          },
          {
            label: t(`activation:${type}.questions.${property}.responses.site`),
            value: "true",
          },
        ]}
        id={property}
        name={property}
        allowMultiple={false}
        selected={[`${choiceValue || false}`]}
        error={error}
        onChange={setValue}
        disabled={disabled}
      />

      <p>
        <TextStyle variation="subdued">
          {t(`activation:${type}.questions.${property}.help`)}
        </TextStyle>
      </p>

      {confirmationBanner && (
        <>
          <br />

          <ScopeBannerAtom confirmed={confirmed} onConfirm={setConfirmed} />
        </>
      )}
    </article>
  )
}

SiteAssessmentFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SiteAssessmentFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default SiteAssessmentFieldAtom
