import PropTypes from "prop-types"
import React from "react"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Unit from "views/atoms/reports/Unit"
import { safePercentage } from "utils/safeMath"
import { round } from "services/units/round"

const T_TO_KG_MULTIPLIER = 1000

const TextChartODD13GHGEmissionsMolecule = ({
  score,
  ghgEmissions = 0,
  waterConsumption,
  hidePercentage,
}) => {
  const { t } = useTranslation()
  let [
    ghgEmissionsFormatted,
    ghgEmissionsFormattedUnit,
  ] = NumberFormatterApp.getNumberFormatter(
    ghgEmissions * T_TO_KG_MULTIPLIER,
    "emission",
    "kg_unit"
  )

  const content = {
    kpi: round(ghgEmissionsFormatted),
    kpi_unit: (
      <Unit
        unit={NumberFormatterApp.getI18nUnit(
          "emission",
          ghgEmissionsFormattedUnit
        )}
      />
    ),
    kpi_text: t(`score:score.13.chartGHGEmissions.text`),
    translationPrefix_chartX: `score:score.13.chartGHGEmissions.`,
  }

  if (ghgEmissions === score?.odd13_it_domain_co2 && !hidePercentage) {
    content.reference = round(safePercentage(ghgEmissions, score?.odd13_CO2), 2)

    content.reference_unit = "%"
    content.reference_text = t(
      `score:score.13.chartGHGEmissions.reference_text`
    )
  }

  if (waterConsumption) {
    content.reference = waterConsumption
    content.reference_unit = <Unit unit={t(`units:units.volume.m3_unit`)} />
    content.reference_text = t(`score:score.13.chartWaterConsumption.text`)
  }

  if (score?.odd13_global_node_parent_score_defined) {
    const odd13_co2_share_vs_parent = score?.odd13_co2_share_vs_parent || 0

    content.reference = odd13_co2_share_vs_parent.percent
    content.reference_unit = (
      <Unit unit={t(`score:score.13.chartGHGEmissions.unit`)} />
    )
    content.reference_text = NodeOrganization.getName(
      odd13_co2_share_vs_parent?.name,
      odd13_co2_share_vs_parent?.type
    )
  }

  const content_for_display = (
    <TextChartMajor content={content} noSubtextHover />
  )

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissions.help`)}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13GHGEmissionsMolecule.propTypes = {
  score: PropTypes.object.isRequired,
  ghgEmissions: PropTypes.string.isRequired,
  waterConsumption: PropTypes.number,
}

TextChartODD13GHGEmissionsMolecule.defaultProps = {
  waterConsumption: null,
}

export default TextChartODD13GHGEmissionsMolecule
