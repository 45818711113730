const CentralizationInventoryEntity = (backClient) => ({
  centralizedFileUpload: async (fileData) => {
    const { data } = await backClient.post(
      "/api/uploads/collects/centralization/inventory/file_upload",
      fileData
    )
    return data
  },
  decentralizedFileUpload: async (file) => {
    const { data } = await backClient.post(
      "/api/uploads/collects/decentralization/inventory/file_upload",
      file
    )
    return data
  },

  /** -------- DECENTRALIZED DATACENTER FUNCTIONS -------- */
  getDatacenterInternal: async () =>
    await backClient.get("/api/data_center_internal_inventories"),
  getDatacenterExternal: async () =>
    await backClient.get("/api/data_center_external_inventories"),
  getDatacenterCloud: async () =>
    await backClient.get("/api/data_center_cloud_inventories"),

  /** -------- Internal */
  decentralizedDatacenterInternalUpload: async (data) =>
    await backClient.post("/api/data_center_internal_inventories", data),
  /**
   * @param {String} id
   * @param {FormData} data
   * @returns AxiosInstance
   */
  decentralizedDatacenterInternalUploadUpdate: async (id, data) =>
    await backClient.put(`/api/data_center_internal_inventories/${id}`, data),
  /**
   * @param {String} id
   * @returns AxiosInstance
   */
  decentralizedDatacenterInternalUploadDelete: async (id) =>
    await backClient.delete(`/api/data_center_internal_inventories/${id}`),

  /** -------- External */
  /**
   *
   * @param {FormData} data FormData
   * @returns AxiosInstance
   */
  decentralizedDatacenterExternalUpload: async (data) =>
    await backClient.post("/api/data_center_external_inventories", data),
  /**
   *
   * @param {String} id
   * @param {FormData} data
   * @returns AxiosInstance
   */
  decentralizedDatacenterExternalUploadUpdate: async (id, data) =>
    await backClient.put(`/api/data_center_external_inventories/${id}`, data),

  /**
   *
   * @param {String} id
   * @returns AxiosInstance
   */
  decentralizedDatacenterExternalUploadDelete: async (id) => {
    return await backClient.delete(
      `/api/data_center_external_inventories/${id}`
    )
  },

  /** -------- Cloud */
  /**
   * @param {Object} data
   * {
      provider: String,
      location: String,
      stored_data: Number,
      ghg_emissions: Number,
    }
   * @returns AxiosInstance
   */
  decentralizedDatacenterCloudUpload: async (data) =>
    await backClient.post("/api/data_center_cloud_inventories", data),
  /**
   * @param {String} id
   * @param {Object} data
   * {
      provider: String,
      location: String,
      stored_data: Number,
      ghg_emissions: Number,
    }
   * @returns AxiosInstance
   */
  decentralizedDatacenterCloudUploadUpdate: async (id, data) =>
    await backClient.put(`/api/data_center_cloud_inventories/${id}`, data),
  /**
   * @param {String} id
   * @returns AxiosInstance
   */
  decentralizedDatacenterCloudUploadDelete: async (id) =>
    await backClient.delete(`/api/data_center_cloud_inventories/${id}`),
})

export default CentralizationInventoryEntity
