import { backClient } from "../helpers/AxiosHelper"
import { useCallback } from "react"
import useAuth0 from "../components/auth/useAuth0"

export const useClientAuth = () => {
  const { getAccessTokenSilently } = useAuth0()

  const withAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently()

    return {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }
  }, [getAccessTokenSilently])

  return {
    get: async (url, params = {}) =>
      await backClient.get(url, { ...(await withAccessToken()), ...params }),
    post: async (url, data) =>
      await backClient.post(url, data, await withAccessToken()),
    put: async (url, data) =>
      await backClient.put(url, data, await withAccessToken()),
    delete: async (url) =>
      await backClient.delete(url, await withAccessToken()),
  }
}

export const useEntity = (entity) => {
  const authClient = useClientAuth()

  return entity(authClient)
}
