import { Button, Icon, Tooltip } from "@shopify/polaris"
import { RefreshMajor } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { useReportPageContext } from "views/contexts/ReportPageContext"

const DownloadReportButtonReportAtom = () => {
  const { t } = useTranslation()

  const {
    setReportRequestResponseBanner,
    setWorkInProgressBanner,
    reportsReloading,
    setReportsReloading,
  } = useReportPageContext()

  const refreshAction = () => {
    setReportRequestResponseBanner({})
    setWorkInProgressBanner(false)
    setReportsReloading(!reportsReloading)
  }

  return (
    <div className="Refresh-Page-Button">
      <Tooltip
        light
        dismissOnMouseOut
        content={t("share:content.reports_table.tooltips.refresh_button")}
      >
        <Button
          className="Refresh-Button"
          plain
          onClick={() => refreshAction()}
        >
          <Icon source={RefreshMajor} />
        </Button>
      </Tooltip>
    </div>
  )
}

export default DownloadReportButtonReportAtom
