import Objective6Score from "components/widgets/ScorePage/pages/Objective6Score"
import Objective7Score from "components/widgets/ScorePage/pages/Objective7Score"
import Objective12Score from "components/widgets/ScorePage/pages/Objective12Score"
import Objective15Score from "components/widgets/ScorePage/pages/Objective15Score"
import Objective3Score from "components/widgets/ScorePage/pages/Objective3Score"
import Objective4Score from "components/widgets/ScorePage/pages/Objective4Score"
import Objective5Score from "components/widgets/ScorePage/pages/Objective5Score"
import Objective10Score from "components/widgets/ScorePage/pages/Objective10Score"
import Objective16Score from "components/widgets/ScorePage/pages/Objective16Score"
import Objective1_2_11Score from "components/widgets/ScorePage/pages/Objective1_2_11Score"
import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useAppContext } from "components/AppContext"
import Objective8Score from "components/widgets/ScorePage/pages/Objective8Score"
import Objective17Score from "components/widgets/ScorePage/pages/Objective17Score"
import Objective9Score from "components/widgets/ScorePage/pages/Objective9Score"
import RedirectIfScoreDisabled from "views/molecules/RedirectIfDisabled"
import { SCORE_URL } from "components/pages/ScorePage"
import Objective13ScoreSwitch from "components/widgets/ScorePage/Objective13ScoreSwitch"

const ObjectiveSwitch = () => {
  const { objectiveId } = useParams()

  const {
    envCollect,
    socialCollect,
    envScore,
    socialScore,
    profitScoreRequest,
    currentNode,
  } = useAppContext()

  const objectiveComponent = useCallback(() => {
    console.log(currentNode)

    switch (Number(objectiveId)) {
      case 6:
        return (
          <Objective6Score
            score={envScore}
            websitesScore={envScore.website_analyse}
            collect={envCollect}
          />
        )
      case 7:
        return <Objective7Score score={envScore} collect={envCollect} />
      case 12:
        return <Objective12Score score={envScore} collect={envCollect} />
      case 13:
        return <Objective13ScoreSwitch score={envScore} collect={envCollect} />

      case 15:
        return <Objective15Score score={envScore} collect={envCollect} />
      // Social Score
      case 3:
        return <Objective3Score score={socialScore} collect={socialCollect} />
      case 4:
        return <Objective4Score score={socialScore} collect={socialCollect} />
      case 5:
        return <Objective5Score score={socialScore} collect={socialCollect} />
      case 10:
        return <Objective10Score score={socialScore} collect={socialCollect} />
      // 1, 2 and 11 redirect to the same component
      case 1:
      case 2:
      case 11:
        return (
          <Objective1_2_11Score score={socialScore} collect={socialCollect} />
        )
      case 8:
        return <Objective8Score score={profitScoreRequest.data} />
      case 9:
        return <Objective9Score score={profitScoreRequest.data} />
      case 16:
        return <Objective16Score score={profitScoreRequest.data} />
      case 17:
        return <Objective17Score score={profitScoreRequest.data} />
      default:
        return <p>Not found</p>
    }
  }, [
    objectiveId,
    envScore,
    envCollect,
    socialCollect,
    socialScore,
    profitScoreRequest,
  ])

  return (
    <RedirectIfScoreDisabled pageName={SCORE_URL} redirectToObjective>
      {objectiveComponent()}
    </RedirectIfScoreDisabled>
  )
}

export default ObjectiveSwitch
