import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import NodeEntity from "services/entities/NodeEntity"
import { useEntity } from "hooks/useAxios"

const useAvailableNodes = (disableRule) => {
  const { t } = useTranslation()
  const nodeEntity = useEntity(NodeEntity)

  const availableNodeGeneration = useCallback(
    ({
      name,
      type,
      id,
      status,
      organization_id,
      version_name,
      parent_name,
    }) => {
      
      let label = NodeOrganization.getName(name, type)

      if (version_name) {
        label += ` (${version_name})`
      }

      if (parent_name) {
        label = `${parent_name} > ` + label
      }

      const disabled = disableRule(status)

      if (disabled) {
        const statusLabel = t(`dashboard:chart.selection_form.${status}`)
        label += ` (${statusLabel})`
      }

      return {
        label,
        disabled,
        id,
        organization_id,
        value: id.toString(), // 'value' has to be a string in order for the Polaris Select field to work
      }
    },
    [t, nodeEntity, disableRule]
  )

  return {
    availableNodeGeneration,
  }
}

export default useAvailableNodes
