import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const TranslateReportTypeReportAtom = ({ report }) => {
  const { t } = useTranslation()

  switch (report.report_type) {
    case "CIO":
      return t("share:content.reports_modal.report_type.options.CIO")
    case "CSR":
      return t("share:content.reports_modal.report_type.options.CSR")
  }
}

TranslateReportTypeReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default TranslateReportTypeReportAtom
