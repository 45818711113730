export const safePercentage = (
  numerator,
  denominator,
  percentageDecimals = 0
) => {
  const num = parseFloat(numerator)
  const den = parseFloat(denominator)

  if (isNaN(num) || isNaN(denominator) || den === 0) return null

  const ratio = num / den

  return (ratio * 100).toFixed(percentageDecimals)
}
