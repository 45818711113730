import React from "react"
import { Select } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import CountryApp from "services/apps/countryApp"

const RelocateHypothesesMolecule = ({ onChange, value }) => {
  const { t } = useTranslation()

  return (
    <div className="recommendationsLayout__details__config__hypotheses">
      <div>
        <div>{t(`recommendation:propositions.hypotheses.relocate.label`)}</div>
        <Select
          label={null}
          options={CountryApp.getCountriesAlpha2().map((country) => ({
            label: CountryApp.getNameI18n(country),
            value: country,
          }))}
          onChange={onChange}
          value={value}
          disabled
        />
      </div>
      <div>{t("recommendation:propositions.hypotheses.title")}</div>
    </div>
  )
}

RelocateHypothesesMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default RelocateHypothesesMolecule
