import React from "react"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import "stylesheets/blocs/atoms/ExternalBadgeAtom.scss"
import PropTypes from "prop-types"

const ExternalCompanyBadgeAtom = ({ organizationName }) => {
  const { t } = useTranslation()

  return (
    <div className="external-badge topbar-badge">
      <Badge progress="default" status="warning">
        {`${t("common:topbar.external_company")} ${organizationName}`}
      </Badge>
    </div>
  )
}

ExternalCompanyBadgeAtom.propTypes = {
  organizationName: PropTypes.string.isRequired,
}

export default ExternalCompanyBadgeAtom
