import { NODE_TYPE_PROGRAM, NODE_TYPE_PROJECT, NODE_TYPE_SERVICE } from "utils/constants/organization/nodeTypes"

const PARTNER_TYPES = [
  NODE_TYPE_PROGRAM,
  NODE_TYPE_SERVICE,
  NODE_TYPE_PROJECT,
]

const isCurrentRootPartner = (currentRootNode) => PARTNER_TYPES.includes(currentRootNode?.type)

export default isCurrentRootPartner
