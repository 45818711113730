import _ from "lodash"
import classNames from "classnames/bind"
import ProgressBar from "components/widgets/ProgressBar"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "stylesheets/ThemeTabs.scss"
import { OBJECTIVES_THEMES } from "utils/constants/pillars"
import { useAppContext } from "../AppContext"
import { IMPROVE_MATURITY_PAGE } from "utils/constants/improve/pages"

const ThemeTabs = ({ pageName, pillar, objective, theme, progress }) => {
  const { t } = useTranslation()
  const themes = OBJECTIVES_THEMES[objective]
  const { subscription } = useAppContext()

  const themeListMarkup = useCallback(() => {
    if (themes === undefined || themes.count === 0) return null //first condition in case objective is undefined (when we want to be able to click on a pillar only), second condition in case objectivesThemes[objective] is an empty array

    const items = _.reject(themes, (existingTheme) => {
      switch (pageName) {
        case IMPROVE_MATURITY_PAGE:
          return (
            existingTheme === "positive_impact" || existingTheme === "cloud"
          )

        case "collect":
          return existingTheme === "cloud"
        default:
          return false
      }
    }).map((existingTheme, i) => (
      <li key={i}>
        <Link to={`/${pageName}/${pillar}/${objective}/${existingTheme}`}>
          <button
            className={classNames(pillar, {
              selected: theme === existingTheme,
            })}
          >
            {t(
              `collect:pillars.${pillar}.objectives.${objective}.themes.${existingTheme}.title`
            )}
            {progress && (
              <ProgressBar
                progress={progress}
                pillarId={pillar}
                objectiveId={objective}
                themeId={existingTheme}
              />
            )}
          </button>
        </Link>
      </li>
    ))
    return items
  }, [objective, theme, progress])

  return (
    <ul
      className={classNames(pillar, "ThemeTabs", {
        "--empty": themes === undefined,
      })}
    >
      {themeListMarkup()}
    </ul>
  )
}

ThemeTabs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pillar: PropTypes.string.isRequired,
  objective: PropTypes.string,
  theme: PropTypes.string,
  progress: PropTypes.object,
}

export default ThemeTabs
