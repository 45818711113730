export const safeDivision = (numerator, denominator) => {
  if (denominator === 0) return 0

  return numerator / denominator
}

export const safePercentage = (numerator, denominator) => {
  if (denominator === 0) return 0

  return (numerator / denominator) * 100
}
