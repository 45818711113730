import { ChoiceList } from "@shopify/polaris"
import { TextField } from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { checkHover } from "components/widgets/CollectPage/forms/fields/Functions"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import _ from "lodash"

const MultipleChoiceField = ({ id, choices, otherField, hideLabel }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const {
    formData,
    setFormData,
    disabledFields,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const translationPrefix = `collect:questions.${translationPath}.${id}.`

  useEffect(() => {
    let newSelected = []
    choices.forEach((choice) => {
      if (formData[choice] == true) {
        newSelected.push(choice)
      }
    })
    setSelected(newSelected)
  }, [formData, choices])

  const choiceItems = useCallback(
    () =>
      choices.map((choiceId) => {
        const label = `${translationPrefix}choices.${choiceId}`
        return {
          label: checkHover(label + ".") || t(label),
          value: choiceId,
          disabled: _.get(disabledFields, choiceId, false),
        }
      }),
    [choices]
  )

  const handleChange = useCallback(
    (values) => {
      let newFormData = { ...formData }
      choices.forEach((choice) => {
        newFormData[choice] = values.includes(choice)
      })
      setFormData(newFormData)
    },
    [choices, formData]
  )

  const otherFieldMarkup = !otherField ? null : (
    <TextField type={"string"} id={otherField} disabled={isLocked} />
  )

  return (
    <div>
      <ChoiceList
        allowMultiple
        title={hideLabel || checkHover(translationPrefix)}
        choices={choiceItems()}
        selected={selected}
        onChange={handleChange}
        disabled={isLocked || _.get(disabledFields, id, false)}
      />
      {otherFieldMarkup}
    </div>
  )
}

MultipleChoiceField.propTypes = {
  id: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  otherField: PropTypes.string,
  hideLabel: PropTypes.bool,
}

MultipleChoiceField.defaultProps = {
  hideLabel: false,
}

export default MultipleChoiceField
