import PropTypes from "prop-types"
import React from "react"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const SoftwareAnalysisOrganism = ({ score }) => {
  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_software_maturity ?? 0}
      chart1={<div></div>}
      chart2={<div></div>}
      chart3={<div></div>}
      chart4={<div></div>}
      chart5={<div></div>}
      chart6={<div></div>}
    />
  )
}

SoftwareAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default SoftwareAnalysisOrganism
