import React from "react"
import { Select } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import financialsUnits from "utils/functions/financialsUnits"
import { useAppContext } from "components/AppContext"

const NumberUnitFieldAtom = ({
  property,
  value,
  type,
  onChange,
  disabled,
  label,
}) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const product = currentRootNode?.is_provider ? "provider" : "corporate"

  label ||= t(`activation:${type}.questions.${property}.label`)

  return (
    <Select
      label={label}
      id={property}
      name={property}
      value={value ?? undefined}
      options={financialsUnits(t, product)}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

NumberUnitFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

NumberUnitFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default NumberUnitFieldAtom
