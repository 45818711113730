import { useCallback } from "react"

export const chartTooltipsLabelGenerator = (axesLabel) =>
  useCallback(
    (tooltipItem, data) => {
      if (!axesLabel) return `${tooltipItem.yLabel}`
      if (data.datasets[tooltipItem.datasetIndex].label === "")
        return `${tooltipItem.yLabel} ${axesLabel}`

      const label = data.datasets[tooltipItem.datasetIndex].label
      return `${label} : ${tooltipItem.yLabel} ${axesLabel}`
    },
    [axesLabel]
  )
