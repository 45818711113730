import PropTypes from "prop-types"
import React from "react"
import "stylesheets/blocs/templates/shared/HeaderTemplate.scss"

const HeaderTemplate = ({ children }) => {
  return <div className={"header header__container"}>{children}</div>
}

HeaderTemplate.propTypes = {
  children: PropTypes.any,
}

export default HeaderTemplate
