import React, { useEffect, useState } from "react"
import { Card } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import ReportImageEn from "assets/images/report_en.png"
import ReportImageFr from "assets/images/report_fr.png"
import TemplateMediaCardReportAtom from "views/atoms/reports/TemplateMediaCardReportAtom"
import { useReportPageContext } from "views/contexts/ReportPageContext"

const CardSectionDispatcherReportOrganism = () => {
  const { t } = useTranslation()
  const [filename, setFilename] = useState("")
  const [reportImage, setReportImage] = useState("")

  const { company } = useReportPageContext()

  // change template name depending on client's
  useEffect(() => {
    if (filename === "" && company != null) {
      let date = new Date()
      let final_date =
        date.getUTCFullYear() +
        "_" +
        date.getMonth() +
        `${t("share:content.template.download_file_name")}` +
        company.name
      setFilename(final_date)
    }
  }, [company])

  // depending on current language, choose the right image template
  useEffect(() => {
    if (reportImage === "") {
      if (i18next.language === "fr") {
        setReportImage(ReportImageFr)
      } else {
        setReportImage(ReportImageEn)
      }
    }
  }, [])

  return (
    <Card.Section>
      <div className="Verdikt-Report">
        <div className="Verdikt-Report-Template">
          <TemplateMediaCardReportAtom
            filename={filename}
            reportImage={reportImage}
          />
        </div>
      </div>
    </Card.Section>
  )
}

export default CardSectionDispatcherReportOrganism
