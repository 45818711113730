import { createContext, useContext } from "react"

export const ReportPageContext = createContext({
  company: {},
  score: {},
  reports: [],
  reportsReloading: false,
  setReportsReloading: (prevState) => prevState,
  reportModalOpen: false,
  setReportModalOpen: (prevState) => prevState,
  reportRegenerating: false,
  setReportRegenerating: (prevState) => prevState,
  workInProgress: false,
  setWorkInProgressBanner: (prevState) => prevState,
  reportRequestResponseBanner: {},
  setReportRequestResponseBanner: (prevState) => prevState,
})

export const useReportPageContext = () => {
  return useContext(ReportPageContext)
}
