import { DropZone } from "@shopify/polaris"
import { ExternalLabel } from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "stylesheets/widgets/FileField.scss"
import CentralizationInventoryEntity from "services/entities/CentralizationInventoryEntity"
import DropZoneCaptionTemplate from "views/templates/DropZoneCaptionTemplate"
import StatusUploadOrganism from "views/organisms/upload/StatusUploadOrganism"
import { FILE_TYPE_XLSX } from "utils/constants/uploads/fileTypes"
import {
  IMPORT_STATUS_COMPLETED,
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_UPLOADED,
  IMPORT_STATUS_WORK_IN_PROGRESS,
} from "utils/constants/imports/importStatus"
import {
  TOAST_STATUS_ALL_CHILDREN_NOT_KEPT,
  TOAST_STATUS_FILE_PROCESSED,
  TOAST_STATUS_FILE_REJECTED,
} from "utils/constants/toastStatuses"
import useFieldIsLocked from "./useFieldIsLocked"
import { useCollectContext } from "../../CollectContextProvider"
import { useEntity } from "../../../../../hooks/useAxios"

const COLLECT_TYPE_TO_SNAKE_CASE = {
  envcollect: "env_collect",
  socialcollect: "social_collect",
  profitcollect: "profit_collect",
}

/**
 * @todo transform this component in atomic design and cleanup its logic
 * @param id
 * @param translationPathName
 * @param translationPath
 * @param setToastStatus
 * @param title
 * @param disabled
 * @param displayDownloadIcon
 * @param displayQualityIcon
 * @param collectFocusedType
 * @param collect
 * @param setCollect
 * @param isCollectLocked
 * @param setShouldUpdateCollectProgress
 * @returns {JSX.Element}
 * @constructor
 */
const FileField = ({
  id,
  translationPathName,
  title,
  disabled,
  inventoryType,
  displayDownloadIcon,
  displayQualityIcon,
  templateSelectionComponent,
}) => {
  const { t } = useTranslation()
  const { collect, setCollect, setToastStatus } = useCollectContext()
  const centralizationEntity = useEntity(CentralizationInventoryEntity)

  if (!collect) {
    return null
  }

  const { isLocked } = useFieldIsLocked()

  const collectType = COLLECT_TYPE_TO_SNAKE_CASE[collect.type]

  const [inventoryStatus, setInventoryStatus] = useState(
    collect?.inventories?.[inventoryType]
  )

  useEffect(() => setInventoryStatus(collect?.inventories?.[inventoryType]), [
    collect,
    inventoryType,
  ])

  const handleFileUpload = useCallback(
    async (_dropFiles, acceptedFiles) => {
      try {
        if (acceptedFiles.length < 1) {
          setToastStatus(TOAST_STATUS_FILE_REJECTED)
          return
        }
        const data = new FormData()
        data.append("file[data]", acceptedFiles[0])
        data.append("file[id]", id)
        data.append("file[collect_type]", collectType)
        data.append("file[collect_id]", collect.id)
        const collectUpdated = await centralizationEntity.decentralizedFileUpload(
          data
        )

        setCollect(collectUpdated)
        setToastStatus(TOAST_STATUS_FILE_PROCESSED)
      } catch (err) {
        console.log({ err })
        setToastStatus(TOAST_STATUS_ALL_CHILDREN_NOT_KEPT)
      }
    },
    [id]
  )

  const handleDropZoneDisplay = useCallback(() => {
    switch (inventoryStatus?.processing_status) {
      case IMPORT_STATUS_UPLOADED:
      case IMPORT_STATUS_WORK_IN_PROGRESS:
        return (
          <StatusUploadOrganism
            accept={FILE_TYPE_XLSX}
            onDrop={handleFileUpload}
            disabled
            inventoryStatus={inventoryStatus}
            setInventoryStatus={setInventoryStatus}
          />
        )

      case IMPORT_STATUS_ERROR:
      case IMPORT_STATUS_COMPLETED:
        return (
          <StatusUploadOrganism
            accept={FILE_TYPE_XLSX}
            onDrop={handleFileUpload}
            disabled={disabled || isLocked}
            inventoryStatus={inventoryStatus}
            setInventoryStatus={setInventoryStatus}
            displayDownloadIcon={displayDownloadIcon}
            displayQualityIcon={displayQualityIcon}
          />
        )

      default:
        return (
          <DropZone
            accept={FILE_TYPE_XLSX}
            allowMultiple={false}
            onDrop={handleFileUpload}
            disabled={disabled || isLocked}
          >
            {fileUploadMarkup()}
          </DropZone>
        )
    }
  }, [collect, inventoryStatus, isLocked])

  const fileUploadMarkup = useCallback(() => {
    if (!inventoryStatus) {
      return (
        <DropZone.FileUpload
          actionTitle={t("collect:questions.common.drop_zone.create.title")}
          actionHint={t("collect:questions.common.drop_zone.create.hint")}
        />
      )
    } else {
      return (
        <DropZoneCaptionTemplate>
          <DropZone.FileUpload
            actionTitle={t("collect:questions.common.drop_zone.update.title")}
            actionHint={t(
              `collect:inventory.statuses.${inventoryStatus.processing_status}`
            )}
          />
        </DropZoneCaptionTemplate>
      )
    }
  }, [inventoryStatus])

  const labelMarkup = !title ? null : (
    <ExternalLabel translationPathName={translationPathName} />
  )

  return (
    <div className="Verdikt-FileField">
      {labelMarkup}
      {templateSelectionComponent}
      {handleDropZoneDisplay()}
    </div>
  )
}

FileField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  translationPathName: PropTypes.string,
  inventoryType: PropTypes.string.isRequired,

  displayQualityIcon: PropTypes.bool,
  displayDownloadIcon: PropTypes.bool,
  templateSelectionComponent: PropTypes.element.isRequired,
}

export default FileField
