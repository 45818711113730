import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import Unit from "views/atoms/reports/Unit";

const TextChartODD13ScopeMolecule = ({
  score,
  scope1,
  scope2 = "",
  referenceScope1 = "",
  referenceScope2 = "",
}) => {
  const { t } = useTranslation()
  const odd13CO2Switcher = (scope) => {
    switch (scope) {
      case "total":
        return score?.odd13_CO2
      case "scope1":
        return score?.odd13_CO2_scope1
      case "scope2":
        return score?.odd13_CO2_scope2
      case "scope3":
        return score?.odd13_CO2_scope3
      case "total_share_global":
        return score?.odd13_CO2_share_global
      case "scope1_share_global":
        return score?.odd13_CO2_scope1_share_global
      case "scope2_share_global":
        return score?.odd13_CO2_scope2_share_global
      case "scope3_share_global":
        return score?.odd13_CO2_scope3_share_global
      case "percentage_scope1_share_global":
        return score?.odd13_CO2_percentage_scope1_share_global
      case "percentage_scope2_share_global":
        return score?.odd13_CO2_percentage_scope2_share_global
      case "percentage_scope3_share_global":
        return score?.odd13_CO2_percentage_scope3_share_global
      case "percentage_total_share_global":
        return score?.odd13_CO2_percentage_share_global
      case "":
        return null
    }
  }
  const formatContent = (scope, referenceScope) => {
    const data = odd13CO2Switcher(scope)
    const [formattedData, dataUnit] = NumberFormatterApp.getNumberFormatter(
      data,
      "emission",
      "t_unit"
    )

    const displayedUnit = <Unit unit={NumberFormatterApp.getI18nUnit("emission", dataUnit)} />

    const referenceData = odd13CO2Switcher(referenceScope)
    if (referenceData) {
      return {
        kpi: formattedData,
        kpi_unit: displayedUnit,
        kpi_text: t(
          `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.${scope}`
        ),
        reference: referenceData,
        reference_unit: "%",
        reference_text: t(
          `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.${referenceScope}`
        ),
      }
    } else {
      return {
        kpi: formattedData,
        kpi_unit: displayedUnit,
        kpi_text: t(
          `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.${scope}`
        ),
      }
    }
  }

  const content1 = formatContent(scope1, referenceScope1)
  let content2 = null
  if (scope2 !== "") {
    content2 = formatContent(scope2, referenceScope2)
  }

  const content_for_display = (
    <div>
      <TextChartMinor content={content1} />
      {content2 ? <TextChartMinor content={content2} /> : null}
    </div>
  )
  return <TextChart content={content_for_display} />
}

TextChartODD13ScopeMolecule.propTypes = {
  score: PropTypes.object.isRequired,
  scope1: PropTypes.string.isRequired,
  scope2: PropTypes.string,
  referenceScope1: PropTypes.string,
  referenceScope2: PropTypes.string,
}

export default TextChartODD13ScopeMolecule
