const RecommendationEntity = (backClient) => ({
  index: async (pillar, objective = null, theme = null) => {
    const { data } = await backClient.get("/api/top_recommendations", {
      params: {
        pillar,
        objective,
        theme,
      },
    })
    return data
  },
})
export default RecommendationEntity
