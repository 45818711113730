import React from "react"
import { FormLayout, TextStyle } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { fieldIsFalsyOrNonPositive } from "components/widgets/CollectPage/useDisablingFields"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import {
  CheckboxField,
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { POLICY_PARTNERSHIP_PRACTICES } from "utils/constants/centralization/policyItems"
import { Module } from "components/widgets/CollectPage/forms/Module"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import _ from "lodash"
import className from "classnames"

const LEVELS = ["international", "regional", "national"]
const ENABLE_FIELD = "id"
const LEVEL_FIELDS = [
  "count",
  "csr_compliance",
  "csr_weight",
  "csr_assessment_on_renewal",
  "csr_training",
  "revenue_partnership_share",
  "revenue_csr_share",
  "shoring",
]

const idMaker = ({ level, field }) => `partnership_levels.${level}.${field}`

const FIELDS = [
  "partnerships_favor_local_partner_technology",
  "partnerships_audit_percentage",
  "partnerships_audit_on_csr_percentage",
  "partnerships_audit_results",
  "partnerships_audit_monitoring",

  "partnerships_ecosystem_academic",
  "partnerships_ecosystem_institutions",
  "partnerships_ecosystem_companies",
  "partnerships_ecosystem_startups",
  "partnerships_ecosystem_individuals",
  "partnerships_ecosystem_csr_academic",
  "partnerships_ecosystem_csr_institutions",
  "partnerships_ecosystem_csr_companies",
  "partnerships_ecosystem_csr_startups",
  "partnerships_ecosystem_csr_individuals",
  "partnerships_ecosystem_simplified_process",
  "partnerships_ecosystem_sourcing",
  "partnerships_ecosystem_open_referencing",
  "partnerships_ecosystem_rfps",
  "partnerships_ecosystem_csr_simplified_process",
  "partnerships_ecosystem_csr_sourcing",
  "partnerships_ecosystem_csr_open_referencing",
  "partnerships_ecosystem_csr_rfps",
  "partnerships_startup_percentage_in_providers",
  "partnerships_startup_percentage_in_projects",
  "partnerships_startup_percentage_in_csr_providers",
  "partnerships_startup_percentage_in_csr_projects",
]

const fields = FIELDS.concat(["partnership_levels"])

const FIXED_CONFIG = [
  {
    disablingField: "investments_ecosystem_startup_percentage_in_providers",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "investments_ecosystem_csr_startup_percentage_in_providers",
    ],
  },
  {
    disablingField: "investments_ecosystem_startup_percentage_in_projects",
    condition: fieldIsFalsyOrNonPositive,
    fieldsToDisable: [
      "investments_ecosystem_csr_startup_percentage_in_projects",
    ],
  },
  {
    disablingField: "partnerships_ecosystem_academic",
    fieldsToDisable: ["partnerships_ecosystem_csr_academic"],
  },
  {
    disablingField: "partnerships_ecosystem_institutions",
    fieldsToDisable: ["partnerships_ecosystem_csr_institutions"],
  },
  {
    disablingField: "partnerships_ecosystem_companies",
    fieldsToDisable: ["partnerships_ecosystem_csr_companies"],
  },
  {
    disablingField: "partnerships_ecosystem_startups",
    fieldsToDisable: ["partnerships_ecosystem_csr_startups"],
  },
  {
    disablingField: "partnerships_ecosystem_individuals",
    fieldsToDisable: ["partnerships_ecosystem_csr_individuals"],
  },
  {
    disablingField: "partnerships_ecosystem_simplified_process",
    fieldsToDisable: ["partnerships_ecosystem_csr_simplified_process"],
  },
  {
    disablingField: "partnerships_ecosystem_sourcing",
    fieldsToDisable: ["partnerships_ecosystem_csr_sourcing"],
  },
  {
    disablingField: "partnerships_ecosystem_open_referencing",
    fieldsToDisable: ["partnerships_ecosystem_csr_open_referencing"],
  },
  {
    disablingField: "partnerships_ecosystem_rfps",
    fieldsToDisable: ["partnerships_ecosystem_csr_rfps"],
  },
]

const levelsDisableConfig = LEVELS.map((level) => ({
  disablingField: idMaker({ level, field: ENABLE_FIELD }),
  fieldsToDisable: LEVEL_FIELDS.map((field) => idMaker({ level, field })),
}))

const disableConfig = FIXED_CONFIG.concat(levelsDisableConfig)

const translationPath = "17.partnerships"

const Objective17PartnershipsForm = () => {
  const { t } = useTranslation()
  const tPrefix = `collect:questions.${translationPath}`

  useFormConfig({ fields, translationPath, disableConfig })

  const percentageComponent = (props) => (
    <TextField
      id={idMaker(props)}
      type="number"
      suffix="%"
      disabled={props.disabled}
    />
  )

  const numberComponent = (props) => (
    <TextField id={idMaker(props)} type="number" disabled={props.disabled} />
  )

  const checkboxComponent = (props) => (
    <CheckboxField id={idMaker(props)} disabled={props.disabled} />
  )

  const shoringField = (props) => (
    <Select
      id={idMaker(props)}
      disabled={props.disabled}
      options={[
        {
          label: t(`${tPrefix}.shores.on_shoring`),
          value: "on_shoring",
        },
        {
          label: t(`${tPrefix}.shores.near_shoring`),
          value: "near_shoring",
        },
        {
          label: t(`${tPrefix}.shores.off_shoring`),
          value: "off_shoring",
        },
      ]}
    />
  )

  const tableFields = [
    { field: ENABLE_FIELD, component: checkboxComponent },
    { field: "count", component: numberComponent },
    { field: "csr_compliance", component: percentageComponent },
    { field: "csr_weight", component: percentageComponent },
    { field: "csr_assessment_on_renewal", component: checkboxComponent },
    { field: "csr_training", component: checkboxComponent },
    { field: "revenue_partnership_share", component: percentageComponent },
    { field: "revenue_csr_share", component: percentageComponent },
    { field: "shoring", component: shoringField },
  ]

  const { isModuleActive } = useCollectContext()
  const levelsDisabled = _.reduce(
    LEVELS,
    (obj, level) => {
      obj[level] = !isModuleActive(`partnership_${level}`)
      return obj
    },
    {}
  )

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <FormLayout>
            <ExternalLabel translationPathName="levels" />
            <table>
              <thead>
                <tr>
                  <td>{t(`${tPrefix}.levels.row_label`)}</td>
                  {LEVELS.map((level) => (
                    <td
                      key={level}
                      className={className({
                        disabled: levelsDisabled[level],
                      })}
                    >
                      {t(`${tPrefix}.levels.${level}`)}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableFields.map(({ field, component }) => (
                  <tr key={field}>
                    <td>{t(`${tPrefix}.${field}`)}</td>
                    {LEVELS.map((level) => (
                      <td key={level} className="select--small centered">
                        {component({
                          level,
                          field,
                          disabled: levelsDisabled[level],
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <Module module={POLICY_PARTNERSHIP_PRACTICES}>
              <YesNoRadio id="partnerships_favor_local_partner_technology" />
            </Module>

            <Module module={POLICY_PARTNERSHIP_PRACTICES}>
              <TextField id="partnerships_audit_percentage" type="number" />
              <TextField
                id="partnerships_audit_on_csr_percentage"
                type="number"
              />
              <Select
                id="partnerships_audit_results"
                options={[
                  "not_shared",
                  "managed_by_partner",
                  "managed_jointly",
                  "managed_and_tracked_jointly",
                ]}
              />
              <YesNoRadio id="partnerships_audit_monitoring" />
            </Module>
          </FormLayout>
        </div>

        <div className="column">
          <FormLayout>
            <Module module={POLICY_PARTNERSHIP_PRACTICES}>
              <ExternalLabel translationPathName="ecosystem" />
              <table>
                <thead>
                  <tr>
                    <td />
                    <td>{t(`${tPrefix}.transactions.all`)}</td>
                    <td>{t(`${tPrefix}.transactions.csr`)}</td>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "academic",
                    "institutions",
                    "companies",
                    "startups",
                    "individuals",
                  ].map((field) => (
                    <tr key={field}>
                      <td>{t(`${tPrefix}.ecosystem.${field}`)}</td>
                      <td className="yes-no">
                        <CheckboxField id={`partnerships_ecosystem_${field}`} />
                      </td>
                      <td className="yes-no">
                        <CheckboxField
                          id={`partnerships_ecosystem_csr_${field}`}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <ExternalLabel translationPathName="method" />
              <table>
                <thead>
                  <tr>
                    <td />
                    <td>{t(`${tPrefix}.transactions.all`)}</td>
                    <td>{t(`${tPrefix}.transactions.csr`)}</td>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "simplified_process",
                    "sourcing",
                    "open_referencing",
                    "rfps",
                  ].map((field) => (
                    <tr key={field}>
                      <td>{t(`${tPrefix}.method.${field}`)}</td>
                      <td className="yes-no">
                        <CheckboxField id={`partnerships_ecosystem_${field}`} />
                      </td>
                      <td className="yes-no">
                        <CheckboxField
                          id={`partnerships_ecosystem_csr_${field}`}
                        />
                      </td>
                    </tr>
                  ))}
                  {["providers", "projects"].map((field) => (
                    <tr key={field}>
                      <td>
                        {t(`${tPrefix}.method.startup_percentage_in_${field}`)}
                      </td>
                      <td className="yes-no">
                        <TextField
                          type="number"
                          id={`partnerships_startup_percentage_in_${field}`}
                          suffix="%"
                        />
                      </td>
                      <td className="yes-no">
                        <TextField
                          type="number"
                          id={`partnerships_startup_percentage_in_csr_${field}`}
                          suffix="%"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TextStyle variation="subdued">
                {t(`${tPrefix}.method.note`)}
              </TextStyle>
            </Module>
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default Objective17PartnershipsForm
