import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Unit from "views/atoms/reports/Unit"
import { safePercentage } from "utils/functions/safeMath"
import { convertValueToReadable } from "services/units/valueConversion"
import {
  CO2_EMISSION_UNITS,
  G_CO2E,
  L_UNIT,
  T_CO2E,
  VOLUME_UNITS_KEYS,
} from "services/units/units"
import { round } from "services/units/round"

const PERCENTAGE_DECIMALS = 1

const ChartSDG13WebsitesGhgMolecule = ({
  websiteEmissions,
  totalEmissions,
  waterConsumption,
  ghgSingleVisitorEmisison,
  ghgSingPageEmission,
  hidePercentageOfEmissions,
}) => {
  const { t } = useTranslation()

  const percentage = safePercentage(
    websiteEmissions,
    totalEmissions,
    PERCENTAGE_DECIMALS
  )

  const { value, unit } = convertValueToReadable(
    websiteEmissions,
    T_CO2E,
    CO2_EMISSION_UNITS,
    (v) => round(v, 2)
  )

  let content = {
    kpi: round(value),
    kpi_unit: <Unit unit={t(`units:units.emission.${unit}`)} />,
    kpi_text: t(`score:score.13.chartGHGEmissions.text`),
    translationPrefix_chartX: `score:score.13.chartGHGEmissions.`,
  }

  if (!hidePercentageOfEmissions) {
    content = {
      ...content,
      reference: percentage,
      reference_unit: t(`score:score.13.chartGHGEmissions.unit`),
      reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
    }
  }

  if (ghgSingleVisitorEmisison) {
    const {
      value: ghgSingleVisitorValue,
      unit: ghgSingleVisitorUnit,
    } = convertValueToReadable(
      ghgSingleVisitorEmisison,
      G_CO2E,
      CO2_EMISSION_UNITS,
      (v) => round(v, 1)
    )

    content.sub_kpi = `${ghgSingleVisitorValue}`
    content.sub_kpi_unit = (
      <Unit unit={t(`units:units.emission.${ghgSingleVisitorUnit}`)} />
    )
    content.sub_kpi_text = t(
      `score:score.13.chartGHGEmissions.single_visitor.suffix_unit`
    )
  }

  if (ghgSingPageEmission) {
    const {
      value: ghgSingPageValue,
      unit: ghgSingPageUnit,
    } = convertValueToReadable(
      ghgSingPageEmission,
      G_CO2E,
      CO2_EMISSION_UNITS,
      (v) => round(v, 2)
    )

    content.reference = ghgSingPageValue
    content.reference_unit = (
      <Unit unit={t(`units:units.emission.${ghgSingPageUnit}`)} />
    )
    content.reference_text = t(
      `score:score.13.chartGHGEmissions.single_page.suffix_unit`
    )
  }

  if (waterConsumption) {
    const {
      value: waterValue,
      unit: waterUnit,
    } = convertValueToReadable(
      waterConsumption,
      L_UNIT,
      VOLUME_UNITS_KEYS,
      (v) => round(v, 2)
    )

    content.sub_reference = waterValue
    content.sub_reference_unit = (
      <Unit unit={t(`units:units.volume.${waterUnit}`)} />
    )
    content.sub_reference_text = t(`score:score.13.chartWaterConsumption.text`)
  }

  const content_for_display = (
    <TextChartMajor content={content} noSubtextHover />
  )

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(
        `score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.help`
      )}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartSDG13WebsitesGhgMolecule.propTypes = {
  websiteEmissions: PropTypes.string.isRequired,
  totalEmissions: PropTypes.string.isRequired,
  waterConsumption: PropTypes.string,
  ghgSingleVisitorEmisison: PropTypes.string,
  ghgSingPageEmission: PropTypes.string,
  hidePercentageOfEmissions: PropTypes.bool,
}

ChartSDG13WebsitesGhgMolecule.defaultProps = {
  hidePercentageOfEmissions: false,
}

export default ChartSDG13WebsitesGhgMolecule
