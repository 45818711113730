import useKeyPress from "hooks/useKeyPress"
import { useEffect } from "react"

const useKeyPressEvent = (targetKey, onPress, active) => {
  const isPressed = useKeyPress(targetKey)

  useEffect(() => {
    if (isPressed && active) {
      onPress()
    }
  }, [isPressed, active])
}

export default useKeyPressEvent
