import React, { useCallback, useEffect, useState } from "react"
import { Banner, Layout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { useAppContext } from "components/AppContext"
import NotificationEntity from "../../services/entities/NotificationEntity"
import { useClientAuth, useEntity } from "../../hooks/useAxios"

const NotificationsWidget = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const notificationEntity = useEntity(NotificationEntity)
  const client = useClientAuth()

  const {
    currentUser,
    shouldRefreshNotifications,
    setShouldRefreshNotifications,
  } = useAppContext()

  const [bannerContent, setBannerContent] = useState(null)
  const [bannerAction, setBannerAction] = useState(null)

  const generateBannerContent = useCallback((id) => {
    switch (id) {
      case "email_not_confirmed":
        setBannerContent({
          id: id,
          title: t("notifications:email_not_confirmed.title"),
          message: t("notifications:email_not_confirmed.message", {
            email: currentUser.email,
          }),
        })
        setBannerAction({
          content: t("notifications:email_not_confirmed.action"),
          disabled: false,
        })
        break
      case "too_large_company":
        setBannerContent({
          id: id,
          title: t("notifications:too_large_company.title"),
          message: t("notifications:too_large_company.message"),
        })
        setBannerAction({
          content: t("notifications:too_large_company.action"),
          url: t("notifications:too_large_company.url"),
          disabled: false,
          external: true,
        })
        break
      default:
        setBannerContent(null)
    }
  })

  useEffect(() => {
    if (!shouldRefreshNotifications) return
    notificationEntity.index().then((notifications) => {
      if (notifications.length === 0) return
      generateBannerContent(notifications[0].id)
    })
    setShouldRefreshNotifications(false)
  }, [shouldRefreshNotifications])

  useEffect(() => {
    setShouldRefreshNotifications(true)
  }, [location])

  const buttonAction = useCallback(() => {
    const resendConfirmationInstructions = async () =>
      client
        .post("/api/resend_confirmation_instructions", {})
        .then(() => {
          setBannerAction({ content: "E-mail renvoyé", disabled: true })
        })
        .catch(() => {
          setBannerAction({
            content: "Impossible pour le moment",
            disabled: true,
          })
        })

    switch (bannerContent.id) {
      case "email_not_confirmed":
        setBannerAction({ content: "Renvoi en cours…", disabled: true })
        resendConfirmationInstructions()
        break
      default:
        break
    }
  }, [bannerContent])

  const generateBanner = useCallback(() => {
    return bannerAction ? (
      <Banner
        style="info"
        title={bannerContent.title ? bannerContent.title : null}
        action={{
          content: bannerAction.content,
          disabled: bannerAction.disabled,
          onAction: buttonAction,
        }}
      >
        {bannerContent.message ? bannerContent.message : null}
      </Banner>
    ) : (
      <Banner
        style="info"
        title={bannerContent.title ? bannerContent.title : null}
      >
        {bannerContent.message ? bannerContent.message : null}
      </Banner>
    )
  }, [bannerContent, bannerAction])

  if (bannerContent == null) return null
  return <Layout.Section>{generateBanner()}</Layout.Section>
}

export default NotificationsWidget
