import React, { useCallback, useEffect, useState } from "react"
import { Checkbox, FormLayout } from "@shopify/polaris"
import Form from "components/widgets/CollectPage/forms/Form"
import { useParams } from "react-router-dom"
import {
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import SocialPeopleInventory from "components/widgets/CollectPage/forms/inventories/social/SocialPeopleInventory"
import { useTranslation } from "react-i18next"
import ConditionalBanner from "components/widgets/ConditionalBanner"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import { useFormConfig, useFormContext } from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"
import { useCollectContext } from "../../../CollectContextProvider"

const fields = [
  "CSR_training_IT_availability",
  "green_IT_training_IT_availability",
  "new_tech_training_IT_availability",
  "training_IT_employee_rate",
  "CSR_training_IT_employee_rate",
  "green_IT_training_IT_employee_rate",
  "new_tech_training_IT_employee_rate",
  "training_IT_average_time_per_employee",
  "CSR_training_IT_average_time_per_employee",
  "green_IT_training_IT_average_time_per_employee",
  "new_tech_training_IT_average_time_per_employee",
  "training_IT_elearning_rate",
  "CSR_training_IT_elearning_rate",
  "green_IT_training_IT_elearning_rate",
  "new_tech_training_IT_elearning_rate",
  "training_IT_average_budget",
  "CSR_training_IT_average_budget",
  "green_IT_training_IT_average_budget",
  "new_tech_training_IT_average_budget",
  "green_IT_training_IT_internal_training_delivered",
  "training_IT_managerial_promotion",
  "partner_training_IT_availability",
  "partner_training_IT_total_hours",
  "partner_training_IT_average_budget",
  "CSR_training_IT_internal_training_delivered",
  "partner_training_IT_CSR_Green_IT_rate",
  "upload_detailed_inventory",
]
const translationPath = "4.education"

const Education = () => {
  const { t } = useTranslation()
  const { isCollectLocked } = useCollectContext()
  const [disabled, setDisabled] = useState({})
  const [bannerError, setBannerError] = useState(null)
  const { formData, setFormData } = useFormContext()

  useFormConfig({ fields, translationPath })

  // Set disabled fields
  useEffect(() => {
    const noTraining = !formData.CSR_training_IT_availability
    const noItTraining = !formData.green_IT_training_IT_availability
    const noNewTechnos = !formData.new_tech_training_IT_availability

    setDisabled({
      ...disabled,
      upload_detailed_inventory: formData.upload_detailed_inventory,
      // Checkbox1 : CSR
      CSR_training_IT_employee_rate: noTraining,
      CSR_training_IT_average_time_per_employee: noTraining,
      CSR_training_IT_elearning_rate: noTraining,
      CSR_training_IT_internal_training_delivered: noTraining,
      CSR_training_IT_average_budget: noTraining,
      // Checkbox2 : Green IT
      green_IT_training_IT_employee_rate: noItTraining,
      green_IT_training_IT_average_time_per_employee: noItTraining,
      green_IT_training_IT_elearning_rate: noItTraining,
      green_IT_training_IT_internal_training_delivered: noItTraining,
      green_IT_training_IT_average_budget: noItTraining,
      // Checkbox3 : New technos
      new_tech_training_IT_employee_rate: noNewTechnos,
      new_tech_training_IT_average_time_per_employee: noNewTechnos,
      new_tech_training_IT_elearning_rate: noNewTechnos,
      new_tech_training_IT_average_budget: noNewTechnos,
      // Partner Training
      partner_training_IT_availability:
        formData.partner_training_IT_availability === false,
    })
  }, [formData])

  useNullifyingField("upload_detailed_inventory", [
    "training_IT_employee_rate",
    "training_IT_average_time_per_employee",
    "CSR_training_IT_employee_rate",
    "CSR_training_IT_average_time_per_employee",
    "green_IT_training_IT_employee_rate",
    "green_IT_training_IT_average_time_per_employee",
    "new_tech_training_IT_employee_rate",
    "new_tech_training_IT_average_time_per_employee",
  ])

  useNullifyingField(
    "CSR_training_IT_availability",
    [
      "CSR_training_IT_employee_rate",
      "CSR_training_IT_average_time_per_employee",
      "CSR_training_IT_elearning_rate",
      "CSR_training_IT_internal_training_delivered",
      "CSR_training_IT_average_budget",
    ],
    false
  )

  useNullifyingField(
    "green_IT_training_IT_availability",
    [
      "green_IT_training_IT_employee_rate",
      "green_IT_training_IT_average_time_per_employee",
      "green_IT_training_IT_elearning_rate",
      "green_IT_training_IT_internal_training_delivered",
      "green_IT_training_IT_average_budget",
    ],
    false
  )

  useNullifyingField(
    "new_tech_training_IT_availability",
    [
      "new_tech_training_IT_employee_rate",
      "new_tech_training_IT_average_time_per_employee",
      "new_tech_training_IT_elearning_rate",
      "green_IT_training_IT_internal_training_delivered",
      "new_tech_training_IT_average_budget",
    ],
    false
  )

  useNullifyingField(
    "partner_training_IT_availability",
    ["partner_training_IT_total_hours", "partner_training_IT_average_budget"],
    false
  )

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th></th>
            <th>{t("collect:questions.4.education.table.column_1")}</th>
            <th>{t("collect:questions.4.education.table.column_2")}</th>
            <th>{t("collect:questions.4.education.table.column_3")}</th>
            <th>{t("collect:questions.4.education.table.column_4")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("collect:questions.4.education.table.row_1")}</td>
            <td></td>
            {[
              "CSR_training_IT_availability",
              "green_IT_training_IT_availability",
              "new_tech_training_IT_availability",
            ].map((id) => {
              return (
                <td key={id} className="centered">
                  <Checkbox
                    checked={formData[id] == true}
                    onChange={(value) =>
                      setFormData({ ...formData, [id]: value })
                    }
                    disabled={isCollectLocked}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t("collect:questions.4.education.table.row_2")}</td>
            {[
              "training_IT_employee_rate",
              "CSR_training_IT_employee_rate",
              "green_IT_training_IT_employee_rate",
              "new_tech_training_IT_employee_rate",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    disabled={
                      disabled.upload_detailed_inventory || disabled[id]
                    }
                    suffix={t(
                      "collect:questions.4.education.table.rate_suffix"
                    )}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t("collect:questions.4.education.table.row_3")}</td>
            {[
              "training_IT_average_time_per_employee",
              "CSR_training_IT_average_time_per_employee",
              "green_IT_training_IT_average_time_per_employee",
              "new_tech_training_IT_average_time_per_employee",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    disabled={
                      disabled.upload_detailed_inventory || disabled[id]
                    }
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t("collect:questions.4.education.table.row_4")}</td>
            {[
              "training_IT_elearning_rate",
              "CSR_training_IT_elearning_rate",
              "green_IT_training_IT_elearning_rate",
              "new_tech_training_IT_elearning_rate",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    disabled={disabled[id]}
                    suffix={t(
                      "collect:questions.4.education.table.rate_suffix"
                    )}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t("collect:questions.4.education.table.row_5")}</td>
            {[
              "training_IT_average_budget",
              "CSR_training_IT_average_budget",
              "green_IT_training_IT_average_budget",
              "new_tech_training_IT_average_budget",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    disabled={disabled[id]}
                    suffix={t(
                      "collect:questions.4.education.table.budget_suffix"
                    )}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td>{t("collect:questions.4.education.table.row_6")}</td>
            <td></td>
            <td className="centered">
              <Checkbox
                checked={
                  formData.CSR_training_IT_internal_training_delivered == true
                }
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    CSR_training_IT_internal_training_delivered: value,
                  })
                }
                disabled={
                  isCollectLocked ||
                  disabled.CSR_training_IT_internal_training_delivered
                }
              />
            </td>
            <td className="centered">
              <Checkbox
                checked={
                  formData[
                    "green_IT_training_IT_internal_training_delivered"
                  ] == true
                }
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    green_IT_training_IT_internal_training_delivered: value,
                  })
                }
                disabled={
                  isCollectLocked ||
                  disabled.green_IT_training_IT_internal_training_delivered
                }
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <ConditionalBanner
        title={t("collect:questions.common.file_error_banner_title_critical")}
        status="critical"
      >
        {bannerError}
      </ConditionalBanner>
      <div className="columns">
        <div className="column">
          <SocialPeopleInventory setBannerError={setBannerError} />
        </div>
        <div className="column column-23">
          <FormLayout>
            <ExternalLabel translationPathName=""></ExternalLabel>
            {tableMarkup()}
            <TextField id="training_IT_managerial_promotion" type="number" />
            {/* Partner Training  */}
            <YesNoRadio id="partner_training_IT_availability" />
            <FormLayout.Group
              title={t(
                "collect:questions.4.education.partner_training_IT_detail.label"
              )}
            >
              <TextField
                id="partner_training_IT_total_hours"
                type="number"
                disabled={disabled["partner_training_IT_availability"]}
              />
              <TextField
                id="partner_training_IT_average_budget"
                type="number"
                disabled={disabled["partner_training_IT_availability"]}
              />
            </FormLayout.Group>
            <Select
              id="partner_training_IT_CSR_Green_IT_rate"
              options={[
                {
                  label: t(
                    "collect:questions.4.education.partner_training_IT_CSR_Green_IT_rate.options.null"
                  ),
                  value: "null",
                },
                {
                  label: t(
                    "collect:questions.4.education.partner_training_IT_CSR_Green_IT_rate.options.sensitized"
                  ),
                  value: "sensitized",
                },
                {
                  label: t(
                    "collect:questions.4.education.partner_training_IT_CSR_Green_IT_rate.options.certified"
                  ),
                  value: "certified",
                },
                {
                  label: t(
                    "collect:questions.4.education.partner_training_IT_CSR_Green_IT_rate.options.dont_know"
                  ),
                  value: "dont_know",
                },
              ]}
            />
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default () => {
  const { themeId } = useParams()
  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "education":
        return <Education />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])
  return themeFormMarkup()
}
