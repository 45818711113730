import React from "react"
import "stylesheets/blocs/molecules/organization/charts/HeaderChartMolecule.scss"
import PropTypes from "prop-types"

const HeaderChartMolecule = ({ rightElements, leftElements }) => {
  return (
    <section className="headerChartMolecule">
      <article className="headerChartMolecule__articles headerChartMolecule__articles--left">
        {leftElements}
      </article>

      <article className="headerChartMolecule__articles headerChartMolecule__articles--right">
        {rightElements}
      </article>
    </section>
  )
}

HeaderChartMolecule.propTypes = {
  rightElements: PropTypes.node,
  leftElements: PropTypes.node,
}

export default HeaderChartMolecule
