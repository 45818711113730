import React, { useEffect, useState } from "react"
import { Button, Form, FormLayout, Modal, Select } from "@shopify/polaris"
import { useOrganizationActionsContext } from "views/molecules/organization/charts/TreeActionsAtom"
import { useTranslation } from "react-i18next"
import { useEntity } from "hooks/useAxios"
import useRequest from "hooks/useRequest"
import NodeEntity from "services/entities/NodeEntity"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import AutocompleteFieldOrganizationShareMolecule from "views/molecules/interactive/AutocompleteFieldOrganizationShareMolecule"
import useAvailableNodes from "hooks/useAvailableNodes"
import { NODE_SCORED_STATUS } from "utils/constants/organization/nodeTypes"

const NodeOrganizationShareOrganism = () => {
  const { t } = useTranslation()

  const { reload } = useChart()
  const { onClose } = useOrganizationActionsContext()

  const [formData, setFormData] = useState({
    node_id: undefined,
    organization_id: undefined,
  })
  const [organizations, setOrganizations] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const nodeEntity = useEntity(NodeEntity)
  const { availableNodeGeneration } = useAvailableNodes(
    (status) => status === NODE_SCORED_STATUS
  )
  const { data: nodes } = useRequest(
    () => nodeEntity.availableNodeShares(),
    [],
    {
      defaultValue: [],
      getter: ({ data }) => data.map(availableNodeGeneration),
    }
  )

  useEffect(async () => {
    if (!formData.node_id) return
    const { data } = await nodeEntity.availableOrganizationShares(formData)
    setOrganizations(
      data.map(({ name, available }) => {
        const label = available
          ? name
          : `${name} (${t("dashboard:chart.share_form.already_shared")})`
        return {
          label,
          value: label,
          disabled: !available,
        }
      })
    )
  }, [formData.node_id])
  const onSubmit = async () => {
    setIsSaving(true)
    try {
      await nodeEntity.share(formData)
      await reload({ organization: true, currentNode: false })
      onClose()
    } finally {
      setIsSaving(false)
    }
  }

  const buttonDisabled = !formData.node_id || !formData.organization_name
  return (
    <Modal
      open
      onClose={onClose}
      title={t(`dashboard:chart.share_form.modal_title`)}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Select
              label={t("dashboard:chart.share_form.node")}
              options={nodes}
              placeholder={t("dashboard:chart.share_form.node_placeholder")}
              value={formData.node_id}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  node_id: value,
                })
              }
            />

            <AutocompleteFieldOrganizationShareMolecule
              organizations={organizations}
              value={formData.organization_name}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  organization_name: value,
                })
              }}
              disabled={!formData.node_id}
            />

            <Button
              submit
              primary={!buttonDisabled}
              disabled={buttonDisabled}
              loading={isSaving}
            >
              {t("common:confirm")}
            </Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

NodeOrganizationShareOrganism.propTypes = {}

export default NodeOrganizationShareOrganism
