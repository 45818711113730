import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"
import "stylesheets/blocs/atoms/buttons/PillarFrameAtom.scss"
import { PILLARS } from "utils/constants/pillars"
import { Tooltip } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const PillarFrameAtom = ({
  pillar,
  link,
  selected,
  text,
  progressComponent,
  disabled = false,
  oldUI,
}) => {
  const { t } = useTranslation()

  const children = (
    <button
      className={classNames("pillarFrameAtom__button", {
        [`pillarFrameAtom__button--${pillar}`]: !!pillar,
        ["pillarFrameAtom__button--selected"]: selected,
        ["pillarFrameAtom__button--disabled"]: disabled,
        ["pillarFrameAtom__button--oldUI"]: oldUI,
      })}
    >
      {text}

      {progressComponent && (
        <div className="pillarFrameAtom__button--progressComponent">
          {progressComponent}
        </div>
      )}
    </button>
  )

  const tooltipContent = t("common:not_available")

  return disabled ? (
    <div className="pillarFrameAtom">
      <Tooltip light content={tooltipContent}>
        {children}
      </Tooltip>
    </div>
  ) : (
    <Link to={link} className="pillarFrameAtom">
      {children}
    </Link>
  )
}

PillarFrameAtom.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  pillar: PropTypes.oneOf(PILLARS),
  progressComponent: PropTypes.element,
  disabled: PropTypes.bool,
  oldUI: PropTypes.bool,
}

PillarFrameAtom.defaultProps = {
  oldUI: false,
}

export default PillarFrameAtom
