import PropTypes from "prop-types"
import React from "react"
import "stylesheets/blocs/molecules/text/InventoryErrorMessageMolecule.scss"
import { InlineError, Stack } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const InventoryErrorMessageMolecule = ({ fileStatus }) => {
  const { t } = useTranslation()

  /**
   *
   * @param {String} errorSourceType The value of the entry "error_source_type" in the file status object
   * @returns {JSX.Element | null} A <Link> component or null if the error source is the client
   */
  const handleInventoryErrorSourceType = (errorSourceType) => {
    switch (errorSourceType) {
      case "type_aws":
      case "type_unknown_source":
      case "type_verdikt":
        return (
          <Link to={`/contact`}>
            {t(`collect:inventory.statuses_errors.contact`)}
          </Link>
        )

      default:
        return undefined
    }
  }

  /**
   * @description Maps the errors array of the file status to display them as InlineErrors
   * @param {Array} errors The array of errors sent in the file status object
   */
  const handleInventoryErrorMessages = (errors) => {
    return (errors || []).map((error, i) => {
      return <InlineError key={i} message={error} />
    })
  }

  /**
   * @description Adds all JSX.Elements for error type, error source type and errors array of the file status object in one array
   * @returns {Array of JSX.Elements} An array of error messages contained in different types JSX.Elements
   */
  const displayErrors = () => {
    let allErrors = []
    if (fileStatus) {
      allErrors.push(
        <div key={fileStatus.error_type}>
          <p
            key={fileStatus.error_type}
            className="inventory-error-message__error-type"
          >
            {t(
              `collect:inventory.statuses_errors.error_type.${fileStatus?.error_type}`
            )}
          </p>

          <br />

          <p
            key={fileStatus.error_source_type}
            className="inventory-error-message__error-source-type"
          >
            {handleInventoryErrorSourceType(fileStatus.error_source_type)}
          </p>
        </div>
      )
      allErrors.push(handleInventoryErrorMessages(fileStatus.errors))
    }
    return allErrors
  }

  const fileStatusHasErrors =
    fileStatus?.error_source_type ||
    fileStatus?.error_type ||
    fileStatus?.errors

  if (!fileStatusHasErrors) {
    return null
  }
  return (
    <Stack distribution="center">
      <div className="inventory-error-message">{displayErrors()}</div>
    </Stack>
  )
}

InventoryErrorMessageMolecule.propTypes = {
  fileStatus: PropTypes.object,
}

export default InventoryErrorMessageMolecule
