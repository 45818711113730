const ReportEntity = (backClient) => ({
  getReports: async () => {
    const { data } = await backClient.get(`/api/reports`)
    return data
  },
  createReport: async (data) => {
    return await backClient.post(`/api/reports`, data)
  },
  updateReport: async (report) => {
    return await backClient.put(`/api/reports`, report)
  },
})

export default ReportEntity
