import Config from "assets/configs/score.js"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Polar } from "react-chartjs-2"
import "stylesheets/ScorePage/PolarChart.scss"
import { PILLARS } from "utils/constants/pillars"
import classNames from "classnames"
import { COLORS } from "utils/constants/colors"

const PolarChart = ({ title, data, config, pillarId }) => {
  const generateData = useCallback(() => {
    let labels = []
    let polarData = []
    let colors = []
    data.forEach((value, index) => {
      labels.push(value.label)
      polarData.push(value.data)
      colors.push(Config.common.colors[index % Config.common.colors.length])
    })
    const datasets = [
      {
        data: polarData,
        borderWidth: 0,
        borderAlign: "inner",
        backgroundColor: colors.map((color) => color + "FF"),
        hoverBackgroundColor: colors.map((color) => color + "BB"),
      },
    ]
    return {
      labels: labels,
      datasets: datasets,
    }
  }, [data])

  const generateOptions = useCallback(() => {
    let options = {
      maintainAspectRatio: false,
      legend: { position: "right", labels: { boxWidth: 10 } },
      scale: {
        gridLines: { color: COLORS.profit.c10 },
        ticks: {
          beginAtZero: true,
          fontSize: 10,
          fontColor: COLORS.profit.primary,
        },
      },
      tooltips: {},
      plugins: {
        datalabels: { display: true, color: COLORS.white },
      },
    }
    return _.merge(options, config)
  }, [config])

  return (
    <div className={classNames("Verdikt-Score-PolarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Polar data={generateData()} options={generateOptions()} />
      </div>
    </div>
  )
}

PolarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
}

PolarChart.defaultProps = {
  config: {},
}

export default PolarChart
