import React from "react"
import PropTypes from "prop-types"

import "stylesheets/blocs/molecules/containers/PillarsContainerMolecule.scss"

const PillarsContainerMolecule = ({ children }) => {
  return <article className="pillarsContainerMolecule">{children}</article>
}

PillarsContainerMolecule.propTypes = {
  children: PropTypes.array.isRequired,
}

export default PillarsContainerMolecule
