const OrganizationEntity = (backClient) => ({
  index: async () => {
    const { data } = await backClient.get(`/api/organizations`)
    return data
  },
  getChildOrganizations: async () =>
    await backClient.get(`/api/organizations?without_current`),
  getParentOrganization: async () =>
    await backClient.get("/api/parent_organization"),
  getCurrentOrganization: async () => await backClient.get("/api/tree"),
  getReassessmentAvailable: async () =>
    await backClient.get(`/api/organizations/reassessment/available`),
  postReassessment: async (reassessmentBody) =>
    await backClient.post(`/api/organizations/reassessment`, reassessmentBody),
  postSubscription: async () =>
    await backClient.post(`/api/organizations/subscription`),
  getHistory: async () => await backClient.get(`/api/organizations/histories`),
  getCurrentPeriod: async () =>
    await backClient.get("/api/organizations/histories/current_period"),
  putHistory: async (companyId) =>
    await backClient.put(`/api/organizations/histories/${companyId}`),
})

export default OrganizationEntity
