import { useTranslation } from "react-i18next"
import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "../FormContext"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"

const YesNoRadio = ({ ...props }) => {
  const { t } = useTranslation()
  const { formData, setFormData } = useFormContext()

  const choices = [
    { label: t("collect:questions.common.yes"), value: "true" },
    { label: t("collect:questions.common.no"), value: "false" },
  ]

  const { id, onChange } = props

  return (
    <RadioButton
      {...props}
      onChange={(value) => {
        onChange
          ? onChange(value)
          : setFormData({ ...formData, [id]: value == "true" })
      }}
      choices={choices}
    />
  )
}

YesNoRadio.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  pathOverride: PropTypes.string,
}

export default YesNoRadio
