import UsersEntity from "services/entities/UsersEntity"
import { useCookies } from "react-cookie"
import { useEffect, useState } from "react"
import { useEntity } from "./useAxios"
import useAdminRules from "hooks/useAdminRules"

export const AUTOFILL_ENV = "autofill_env"
export const AUTOFILL_SOCIAL = "autofill_social"
export const AUTOFILL_PROFIT = "autofill_profit"

const AUTOFILL_COLLECT_KEYS = [AUTOFILL_ENV, AUTOFILL_SOCIAL, AUTOFILL_PROFIT]

const UseAutofillCollects = (user) => {
  const [cookies, setCookie] = useCookies(AUTOFILL_COLLECT_KEYS)
  const [canSeeAutoFillOptions, setCanSeeAutoFillOptions] = useState(false)
  const usersEntity = useEntity(UsersEntity)
  const { isVerdiktAccount } = useAdminRules()

  const setAutofill = (key, value) => setCookie(key, value.toString())

  useEffect(async () => {
    if (isVerdiktAccount) {
      AUTOFILL_COLLECT_KEYS.forEach((key) => {
        if (cookies[key] === undefined) {
          setCookie(key, true)
        }
      })

      setCanSeeAutoFillOptions(true)
      return
    }

    try {
      await usersEntity.currentAdminUser()
      setCanSeeAutoFillOptions(true)
    } catch (error) {
      setCanSeeAutoFillOptions(false)
      AUTOFILL_COLLECT_KEYS.forEach((key) => setCookie(key, false))
    }
  }, [user])

  return {
    autofillEnv: cookies[AUTOFILL_ENV] === "true",
    autofillSocial: cookies[AUTOFILL_SOCIAL] === "true",
    autofillProfit: cookies[AUTOFILL_PROFIT] === "true",
    setAutofill,
    canSeeAutoFillOptions,
  }
}

export default UseAutofillCollects
