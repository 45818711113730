import React from "react"
import { Badge, Button, Icon, Link, Stack } from "@shopify/polaris"
import {
  CircleCancelMinor,
  CirclePlusMinor,
  CustomerPlusMajor,
  EditMinor,
  ReplaceMajor,
  ViewMinor,
} from "@shopify/polaris-icons"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import "stylesheets/widgets/table/Table.scss"
import ExternalUserBadgeAtom from "../../../atoms/badges/ExternalUserBadgeAtom"
import "stylesheets/blocs/organisms/tables/TableUserListOrganism.scss"

const TableUserListOrganism = ({
  users,
  onDeleteClick,
  canCreate,
  canRead,
  canUpdate,
  canDelete,
}) => {
  const { currentUser } = useAppContext()

  const { t } = useTranslation()
  return (
    <>
      <Stack vertical>
        <table className="user-list">
          <thead>
            <tr className="leftAligned">
              <th>{t("common:user_list.table_header.lastname")}</th>
              <th>{t("common:user_list.table_header.firstname")}</th>
              <th>{t("common:user_list.table_header.email")}</th>
              <th colSpan={2}></th>
              <th className="centered">
                {t("common:user_list.table_header.role_override")}
              </th>
              <th></th>
              <th>
                {canCreate && (
                  <Button plain>
                    <Link url="/users/new">
                      <Icon source={CirclePlusMinor} />
                    </Link>
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.lastname || "-"}</td>
                <td>{user.firstname || "-"}</td>
                <td className="email-address">{user.email}</td>
                <td className="centered">
                  {user.has_user_management && (
                    <CustomerPlusMajor width={20} className="colored check" />
                  )}
                </td>
                <td>
                  {user.can_switch_product && <Icon source={ReplaceMajor} />}
                </td>
                <td className="centered">
                  <Badge>
                    {t(`common:user_form.roles.${user.role_override}`)}
                  </Badge>
                </td>
                <td>
                  {user.company} {user.is_external && <ExternalUserBadgeAtom />}
                </td>
                <td>
                  {(canUpdate || canRead) && (
                    <Button plain>
                      <Link key={user.id} url={`/users/update/${user.id}`}>
                        <Icon source={canUpdate ? EditMinor : ViewMinor} />
                      </Link>
                    </Button>
                  )}
                  {canDelete && currentUser.id !== user.id && (
                    <Button plain onClick={() => onDeleteClick(user)}>
                      <Icon source={CircleCancelMinor} />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Stack>
    </>
  )
}

TableUserListOrganism.propTypes = {
  users: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  canCreate: PropTypes.bool.isRequired,
  canRead: PropTypes.bool.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canSwitch: PropTypes.bool.isRequired,
}

export default TableUserListOrganism
