import React from "react"

import "stylesheets/blocs/molecules/objectives/TabObjectiveMolecule.scss"
import PropTypes from "prop-types"
import TabObjectiveMolecule from "views/molecules/objectives/TabObjectiveMolecule"
import SDGsObjectiveOrganism from "views/organisms/objectives/SDGsObjectiveOrganism"
import { PILLARS } from "utils/constants/pillars"

const TabsObjectiveMolecule = ({ tabs, themeType }) => {
  return tabs.map((tab, idx) => {
    if (!tab.hidden) {
      return (
        <TabObjectiveMolecule
          key={idx}
          url={tab.url}
          selected={tab.selected}
          firstItem={idx === 0}
          themeType={themeType}
          title={tab.name}
        />
      )
    }
  })
}

SDGsObjectiveOrganism.propTypes = {
  tabs: PropTypes.array,

  themeType: PropTypes.oneOf(PILLARS).isRequired,
}

SDGsObjectiveOrganism.defaultProps = {
  tabs: [],
}

export default TabsObjectiveMolecule
