import PropTypes from "prop-types"
import React from "react"

const ExternalHelp = ({ children }) => {
  return <div className="Polaris-Labelled__HelpText">{children}</div>
}

ExternalHelp.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
}

export default ExternalHelp
