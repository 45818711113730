import React, { createContext, useCallback, useContext, useState } from "react"
import PropTypes from "prop-types"
import "stylesheets/blocs/molecules/organization/charts/ActionsOrganizationChartMolecule.scss"
import { ActionList, Button, Icon, Popover } from "@shopify/polaris"
import useToggle from "hooks/useToggle"
import { useTranslation } from "react-i18next"
import { PlusMinor } from "@shopify/polaris-icons"

const OrganizationActionsContext = createContext({})

export const useOrganizationActionsContext = () =>
  useContext(OrganizationActionsContext)

export const TreeActionsAtom = ({ actions }) => {
  const { t } = useTranslation()

  const [collapsed, toggleCollapsed] = useToggle(false)
  const [selectedAction, setSelectedAction] = useState(null)

  const onClose = () => setSelectedAction(null)

  const filteredActions = actions.filter(({ hidden }) => !hidden)

  const autoSelection = useCallback(() => {
    if (filteredActions?.length === 1) {
      setSelectedAction(filteredActions[0])
    } else {
      toggleCollapsed()
    }
  }, [actions])

  const actualActions = useCallback(
    () =>
      filteredActions.map((action) => ({
        content: <b>{t(`dashboard:chart.actions.${action.key}.content`)}</b>,
        helpText: t(`dashboard:chart.actions.${action.key}.help`),
        id: action.key,
        onAction: () => {
          toggleCollapsed()
          setSelectedAction(action)
        },
      })),
    [actions]
  )

  if (selectedAction) {
    return (
      <OrganizationActionsContext.Provider value={{ onClose }}>
        {selectedAction.component}
      </OrganizationActionsContext.Provider>
    )
  }

  const activator = (
    <Button onClick={autoSelection} icon={<Icon source={PlusMinor} />}>
      {t(`dashboard:chart.buttons.add_button`)}
    </Button>
  )

  return (
    <Popover
      active={collapsed}
      preferredAlignment="left"
      activator={activator}
      onClose={toggleCollapsed}
      fullWidth
    >
      <ActionList actionRole="menuitem" items={actualActions()} />
    </Popover>
  )
}

TreeActionsAtom.propTypes = {
  actions: PropTypes.array.isRequired,
}

TreeActionsAtom.defaultProps = {
  actions: [],
}
