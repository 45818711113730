import { Button, Form, Modal, TextField } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import useToggle from "../../../hooks/useToggle"
import UserEntity from "../../../services/entities/UserEntity"
import { useEntity } from "../../../hooks/useAxios"

const UserForm = () => {
  const { t } = useTranslation()
  const translationPrefix = "user_profile:dangerous.user_deletion.modal"
  const { currentUser } = useAppContext()
  const [validation, setValidation] = useState("")
  const [active, toggleActive] = useToggle(false)
  const userEntity = useEntity(UserEntity)

  const exitModal = useCallback(() => {
    toggleActive()
    setValidation("")
  }, [])

  const deleteUser = useCallback(async () => {
    await userEntity.delete(currentUser.id)
    history.go(0)
  }, [currentUser])

  const secondaryActions = useCallback(() => {
    return [
      {
        content: t("common:delete"),
        destructive: true,
        onClick: deleteUser,
        disabled: validation !== t(`${translationPrefix}.delete`),
      },
    ]
  }, [validation, t])

  return (
    <Modal
      small
      open={active}
      activator={
        <Button destructive onClick={toggleActive}>
          {t("common:delete")}
        </Button>
      }
      secondaryActions={secondaryActions()}
      onClose={exitModal}
      title={t(`${translationPrefix}.title`)}
    >
      <Modal.Section>
        <Form>
          <TextField
            label={t(`${translationPrefix}.description`)}
            placeholder={t(`${translationPrefix}.delete`)}
            value={validation}
            onChange={setValidation}
          />
        </Form>
      </Modal.Section>
    </Modal>
  )
}

UserForm.propTypes = {}

export default UserForm
