import useRequest from "hooks/useRequest"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { useCallback, useEffect, useState } from "react"
import { useEntity } from "./useAxios"
import { useAppContext } from "components/AppContext"

const useHistorization = () => {
  const { subscription } = useAppContext()

  const organizationEntity = useEntity(OrganizationEntity)
  const { data, reload, isLoading } = useRequest(
    () => organizationEntity.getHistory(),
    [subscription],
    { condition: !!subscription && !subscription.is_provider }
  )

  const [canReassess, setCanReassess] = useState(false)
  const [isCurrentPeriod, setIsCurrentPeriod] = useState(true)

  const handleCanReassess = useCallback(() => {
    if (data) {
      setCanReassess(data.can_reassess)
    }
  }, [data])

  const handleCurrentPeriod = useCallback(() => {
    if (data) {
      setIsCurrentPeriod(
        data.history_selected.id === data.organization_last_created.id
      )
    }
  }, [data])

  useEffect(() => {
    handleCanReassess()
    handleCurrentPeriod()
  }, [data])

  const update = async (companyId) => {
    try {
      await organizationEntity.putHistory(companyId)

      reload()
    } catch (err) {
      console.log(err)
    }
  }

  const goBackToCurrentPeriod = useCallback(async () => {
    if (data) {
      await organizationEntity.putHistory(data.organization_last_created.id)

      reload()
    }
  }, [data, setCanReassess])

  return {
    selected: data?.history_selected,
    lastCreated: data?.organization_last_created,
    list: data?.history_list,
    canReassess,
    isCurrentPeriod,
    isLoading,
    update,
    goBackToCurrentPeriod,
    reload,
  }
}

export default useHistorization
