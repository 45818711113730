import React from "react"
import { Button, Icon, Select } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { HomeMajor } from "@shopify/polaris-icons"
import { useHistoryContext } from "views/contexts/HistoryContext"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import { useAppContext } from "components/AppContext"
import "stylesheets/widgets/Dashboard/AddButton.scss"
import PeriodEditModal from "views/molecules/historizations/modal/PeriodEditModal"
import PropTypes from "prop-types"

export const HistorySelectAtom = ({ showEditPeriod }) => {
  const { t } = useTranslation()

  const {
    companyHistorySelected,
    companyHistoryList,
    isCurrentPeriod,
    updateHistoryRequest,
    goBackToCurrentPeriod,
  } = useHistoryContext()

  const { reloadCurrentPeriod, currentRootNode } = useAppContext()

  const { reload } = useChart()

  const updateHistoryRequestBySelect = async (value) => {
    await updateHistoryRequest(value)
    await reload()
    await reloadCurrentPeriod()
  }

  const updateHistoryRequestByDefault = async () => {
    await goBackToCurrentPeriod()
    await reload()
    await reloadCurrentPeriod()
  }

  const constructorHistorySelector = (list) =>
    list
      ? list.map(({ id, name }) => ({
          value: `${id}`,
          label: name || t("organization:reassessment.select.default"),
        }))
      : []

  const label = currentRootNode?.is_provider
    ? t("organization:reassessment.select.prefix.partner")
    : t("organization:reassessment.select.prefix.corporate")

  if (!companyHistorySelected) return null

  return (
    <div
      className={`organization__historization__navigation__period ${
        isCurrentPeriod
          ? "organization__historization__navigation__period-current"
          : ""
      }`}
    >
      <Select
        disabled={companyHistoryList?.length < 2}
        options={constructorHistorySelector(companyHistoryList)}
        onChange={updateHistoryRequestBySelect}
        value={`${companyHistorySelected?.id}`}
        label={`${label}: `}
        labelInline
      />

      {!isCurrentPeriod && (
        <Button outline onClick={updateHistoryRequestByDefault}>
          <Icon source={HomeMajor} />
        </Button>
      )}

      {showEditPeriod && <PeriodEditModal />}
    </div>
  )
}

HistorySelectAtom.propTypes = {
  showEditPeriod: PropTypes.bool,
}

HistorySelectAtom.defaultProps = {
  showEditPeriod: false,
}

export default HistorySelectAtom
