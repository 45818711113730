import { Banner, Layout } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import "stylesheets/CollectPage.scss"
import "stylesheets/ObjectiveTabs.scss"
import ThemeLayoutOrganism from "views/organisms/themes/ThemeLayoutOrganism"
import SDGsObjectiveOrganism from "views/organisms/objectives/SDGsObjectiveOrganism"
import TabsObjectiveMolecule from "views/organisms/objectives/TabsObjectiveOrganism"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import { PILLARS } from "utils/constants/pillars"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import WaitTagSeleniumAtom from "views/atoms/selenium/WaitTagSeleniumAtom"

const AnalyzeGHGTemplate = ({
  envScore,
  needEnvScore,
  parentOrganization,
  themeType,
  tabs,
  objectives,
  children,
  pillars,
  periodName,
  forceSize, // use to force the website detailed dashboard
}) => {
  const { t } = useTranslation()

  const showEnvScore = () => !needEnvScore || !!envScore

  return (
    <Layout>
      <WaitTagSeleniumAtom />
      {showEnvScore() ? (
        <>
          <Layout.Section>
            <BreadcrumbsOrganism
              parentOrganization={parentOrganization}
              currentPeriodName={periodName}
            />
          </Layout.Section>

          <Layout.Section>
            <PillarOrganism pillars={pillars} />

            <ThemeLayoutOrganism
              tabs={<TabsObjectiveMolecule tabs={tabs} themeType={themeType} />}
              objectives={
                <SDGsObjectiveOrganism
                  objectives={objectives}
                  themeType={themeType}
                />
              }
              forceSize={forceSize}
            >
              {children}
            </ThemeLayoutOrganism>
          </Layout.Section>
        </>
      ) : (
        <Layout.Section>
          <Banner
            title={t("analyze:no_score.title")}
            action={{
              content: t("analyze:no_score.cta"),
              url: "/collect",
            }}
          >
            {t(`analyze:no_score.content`)}
          </Banner>
        </Layout.Section>
      )}
    </Layout>
  )
}

AnalyzeGHGTemplate.propTypes = {
  needEnvScore: PropTypes.bool,
  envScore: PropTypes.object,
  parentOrganization: PropTypes.array.isRequired,
  periodName: PropTypes.string.isRequired,

  tabs: PropTypes.array,
  objectives: PropTypes.array,
  pillars: PropTypes.array,

  children: PropTypes.element,

  themeType: PropTypes.oneOf(PILLARS).isRequired,

  pillarsFrameHidden: PropTypes.bool,

  forceSize: PropTypes.bool,
}

AnalyzeGHGTemplate.defaultProps = {
  needEnvScore: true,
  tabs: [],
  objectives: [],
  pillars: [],
  forceSize: false,
}

export default AnalyzeGHGTemplate
