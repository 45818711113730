import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COMPANY,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
  NODE_TYPE_PARTNER,
  NODE_TYPE_PHASE,
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_REGION,
  NODE_TYPE_SERVICE,
  NODE_TYPE_SERVICE_COMPONENT,
  NODE_TYPE_SITE,
} from "utils/constants/organization/nodeTypes"
import {
  BILLIONS_PER_YEAR,
  MILLIONS_PER_YEAR,
} from "utils/functions/financialsUnits"
import _ from "lodash"

export const NODE_TYPE_TO_DEFAULT_DATASET = {
  [NODE_TYPE_PROGRAM]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_PROGRAM,
    characteristic: {
      name: "",
      client: "",
      revenue_unit: MILLIONS_PER_YEAR,
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_PROJECT]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_PROJECT,
    characteristic: {
      name: "",
      client: "",
      budget_unit: MILLIONS_PER_YEAR,
      revenue_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_PHASE]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_PHASE,
    characteristic: {
      name: "",
      client: "",
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_COUNTRY_TEAM]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_COUNTRY_TEAM,
    characteristic: {
      name: "",
      client: "",
      budget_unit: MILLIONS_PER_YEAR,
      site_assessment: false,
    },
  },
  [NODE_TYPE_COMPANY]: {
    centralization_inventory: {},
    centralization_policy: {},
    characteristic: {
      name: "",
      sector: "",
      headquarters_country: "",
      revenue_unit: BILLIONS_PER_YEAR,
      budget_unit: BILLIONS_PER_YEAR,
      involvement_duration_in_month: "",
    },
  },
  [NODE_TYPE_BRAND]: {
    type: NODE_TYPE_BRAND,
    characteristic: {
      name: "",
      headquarters_country: "",
      centralization_inventory: {},
      centralization_policy: {},
      budget_unit: MILLIONS_PER_YEAR,
      revenue_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_REGION]: {
    centralization_inventory: {},
    centralization_policy: {},
    creationNodeParentSelected: undefined,
    type: NODE_TYPE_REGION,
    characteristic: {
      name: "",
      budget_unit: MILLIONS_PER_YEAR,
      revenue_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_COUNTRY]: {
    centralization_inventory: {},
    centralization_policy: {},
    creationNodeParentSelected: undefined,
    type: NODE_TYPE_COUNTRY,
    characteristic: {
      name: "",
      site_assessment: "false",
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_SITE]: {
    creationNodeParentSelected: undefined,
    type: NODE_TYPE_SITE,
    characteristic: {
      name: "",
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_SERVICE]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_SERVICE,
    characteristic: {
      name: "",
      client: "",
      budget_unit: MILLIONS_PER_YEAR,
      revenue_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_SERVICE_COMPONENT]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_SERVICE_COMPONENT,
    characteristic: {
      name: "",
      client: "",
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
  [NODE_TYPE_PARTNER]: {
    centralization_inventory: {},
    centralization_policy: {},
    type: NODE_TYPE_PARTNER,
    characteristic: {
      name: "",
      budget_unit: MILLIONS_PER_YEAR,
    },
  },
}

export const LIGHT_DATASET = {
  characteristic: {
    name: "",
    light: true,
  },
}

export const PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET = {
  ...NODE_TYPE_TO_DEFAULT_DATASET,
  [NODE_TYPE_COMPANY]: _.cloneDeep(LIGHT_DATASET),
  [NODE_TYPE_BRAND]: _.cloneDeep(LIGHT_DATASET),
  [NODE_TYPE_REGION]: _.cloneDeep(LIGHT_DATASET),
  [NODE_TYPE_COUNTRY]: _.cloneDeep(LIGHT_DATASET),
  [NODE_TYPE_SITE]: _.cloneDeep(LIGHT_DATASET),
}
