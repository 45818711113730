import { useEffect, useState } from "react"

const ON_KEY_DOWN = "keydown"
const ON_KEY_UP = "keyup"

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }

    window.addEventListener(ON_KEY_DOWN, downHandler)
    window.addEventListener(ON_KEY_UP, upHandler)

    return () => {
      window.removeEventListener(ON_KEY_DOWN, downHandler)
      window.removeEventListener(ON_KEY_UP, upHandler)
    }
  }, [targetKey])

  return keyPressed
}

export default useKeyPress
