import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Unit from "views/atoms/reports/Unit"
import ChartBadge from "views/atoms/badges/ChartBadge"
import { useAppContext } from "components/AppContext"
import { round } from "services/units/round"

const TextChartODD13GHGEmissionsEmployeeMolecule = ({ score }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  let odd13_co2_average_employee_share_vs_parent =
    score?.odd13_co2_average_employee_share_vs_parent

  let ghg_emissions_per_employee =
    Number(score["odd13_CO2_average_employee"]) ?? 0

  const share_ghg_emissions_employee =
    odd13_co2_average_employee_share_vs_parent?.percent

  const badge = (
    <ChartBadge
      value={ghg_emissions_per_employee * 1000}
      lowerLimit={300}
      upperLimit={600}
      inverse
    />
  )

  let unit = "score:score.13.t_unit"

  if (ghg_emissions_per_employee < 1) {
    ghg_emissions_per_employee = ghg_emissions_per_employee * 1000
    unit = `score:score.13.kg_unit`
  }

  const text = currentRootNode?.is_corporate
    ? t("score:score.13.chartGHGEmissionsPerEmployee.corporate.text")
    : t("score:score.13.chartGHGEmissionsPerEmployee.provider.text")

  const help = currentRootNode?.is_corporate
    ? t("score:score.13.chartGHGEmissionsPerEmployee.corporate.help")
    : t("score:score.13.chartGHGEmissionsPerEmployee.provider.help")
  const content = {
    kpi: round(ghg_emissions_per_employee),
    kpi_unit: <Unit unit={t(unit)} />,
    kpi_text: text,
    reference: badge,
  }

  if (
    share_ghg_emissions_employee > 0 &&
    score?.odd13_global_node_parent_score_defined
  ) {
    content.sub_kpi = share_ghg_emissions_employee
    content.sub_kpi_unit = t(`score:score.13.chartGHGEmissionsPerEmployee.unit`)
  }

  const content_for_display = <TextChartMinor content={content} />
  return (
    <HelpWidgetChartInteractiveMolecule text={help}>
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13GHGEmissionsEmployeeMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default TextChartODD13GHGEmissionsEmployeeMolecule
