import React from "react"
import HelpWidget from "components/widgets/ScorePage/widgets/HelpWidget"
import PropTypes from "prop-types"

const HelpWidgetChartInteractiveMolecule = ({ children, text }) => {
  return (
    <>
      <HelpWidget text={text} />
      {children}
    </>
  )
}

HelpWidgetChartInteractiveMolecule.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default HelpWidgetChartInteractiveMolecule
