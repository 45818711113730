import React, { useEffect } from "react"
import { FormLayout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import {
  useFormConfig,
  useFormContext,
} from "components/widgets/CollectPage/forms/FormContext"
import {
  ExternalLabel,
  FileField,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import Form from "components/widgets/CollectPage/forms/Form"
import { INVENTORY_PROJECTS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import UploadOrganism from "views/organisms/upload/UploadOrganism"

const fields = [
  "projects_build_percentage",
  "projects_run_percentage",
  "projects_abandoned_percentage",
  "projects_abandoned_percentage_design",
  "projects_abandoned_percentage_build",
  "projects_abandoned_percentage_mvp",
  "projects_process_sustainability_priority",
  "projects_on_time_delivery_percentage",
]
const translationPath = "8.project_portfolio"
const tPrefix = `collect:questions.${translationPath}`
const PHASES = ["design", "build", "mvp"]

const ProjectPortfolioForm = () => {
  const { t } = useTranslation()
  const { formData, setFormData } = useFormContext()

  useFormConfig({ fields, translationPath })

  useEffect(
    () =>
      formData.projects_build_percentage &&
      setFormData({
        ...formData,
        projects_run_percentage: 100 - formData.projects_build_percentage,
      }),
    [formData?.projects_build_percentage]
  )

  useEffect(() => {
    if (
      !(
        formData?.projects_abandoned_percentage_design &&
        formData?.projects_abandoned_percentage_build
      )
    )
      return

    setFormData({
      ...formData,
      projects_abandoned_percentage_mvp:
        100 -
        formData.projects_abandoned_percentage_design -
        formData?.projects_abandoned_percentage_build,
    })
  }, [
    formData?.projects_abandoned_percentage_design,
    formData?.projects_abandoned_percentage_build,
  ])

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_PROJECTS}>
          <div className="columns">
            <div className="column">
              <FormLayout>
                <UploadOrganism
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPathName="projects"
                      inventoryType={INVENTORY_PROJECTS}
                      displayDownloadIcon
                    />
                  }
                  decentralisedComponent={
                    <FileField
                      id="decentralized_inventory_projects"
                      title="projects"
                      inventoryType={INVENTORY_PROJECTS}
                      translationPathName="projects"
                      templateSelectionComponent={
                        <TemplateFileLinkMolecule id="decentralized_inventory_projects" />
                      }
                    />
                  }
                />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="projects_process_sustainability_priority" />

                <hr />

                <ExternalLabel translationPathName="projects_build_rate" />
                <FormLayout.Group>
                  <TextField id="projects_build_percentage" type="number" />
                  <TextField
                    id="projects_run_percentage"
                    type="number"
                    disabled
                  />
                </FormLayout.Group>

                <TextField
                  id="projects_on_time_delivery_percentage"
                  type="number"
                />

                <TextField id="projects_abandoned_percentage" type="number" />

                <ExternalLabel translationPathName="projects_abandoned_per_phase" />
                <table>
                  <thead>
                    <tr>
                      {PHASES.map((phase, index) => (
                        <td key={index}>{t(`${tPrefix}.phase.${phase}`)}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {PHASES.map((phase, index) => (
                        <td key={index}>
                          <TextField
                            id={`projects_abandoned_percentage_${phase}`}
                            type="number"
                            suffix="%"
                            disabled={phase === "mvp"}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default ProjectPortfolioForm
