import useRequest from "hooks/useRequest"
import OrganizationEntity from "services/entities/OrganizationEntity"
import { useEntity } from "./useAxios"

const useCurrentPeriod = (dependencies = [], condition = true) => {
  const organizationEntity = useEntity(OrganizationEntity)

  const { data, reload } = useRequest(
    () => organizationEntity.getCurrentPeriod(),
    dependencies,
    { condition }
  )

  return {
    currentPeriod: data,
    reload,
  }
}

export default useCurrentPeriod
