import i18n from "i18next"
import {
  COUNT_UNIT,
  EMISSION_UNIT,
  STORAGE_UNIT,
  VOLUME_UNIT,
} from "utils/constants/numbers"

export default class NumberFormatterApp {
  /**
   * Transform big numbers to smaller numbers, by changing their unit
   * Format norm is to display only 3 digits (before and after comma together)
   * @param number
   * @param unitType storage | count | emission | volume
   * @param unitInitial according with unit type
   * @param options {{ round?: number }}
   * @returns {(number|string)[]}
   */
  static getNumberFormatter(number, unitType, unitInitial, options = {}) {
    if (number == 0) {
      return [number, unitInitial]
    }

    const unitsTypeSelected = this.dispatcherUnit(unitType)

    const unitInitialIndex = unitsTypeSelected.findIndex(
      (unit) => unit === unitInitial
    )

    const unitsSelected = unitsTypeSelected.slice(
      unitInitialIndex,
      unitsTypeSelected.length
    )

    const unitSelectedLast = unitsSelected[unitsSelected.length - 1]

    for (const [idx, unit] of unitsSelected.entries()) {
      if (number <= 1000 ** (idx + 1)) {
        const roundedNumber = (number / 1000 ** idx).toFixed(
          options?.round || 3 - Math.log10(number / 1000 ** idx)
        )

        if (roundedNumber < 1000 || unit === unitSelectedLast) {
          return [Number(roundedNumber), unit]
        }
      }
    }

    return [
      Number((number / 1000 ** (unitsSelected.length - 1)).toFixed()),
      unitSelectedLast,
    ]
  }

  /**
   * The function allows to convert a selected unit to another
   * @param number
   * @param unitType storage | count | emission | volume
   * @param unitInitial according with unit type
   * @param unitToSet according with unit type
   * @param options
   * @returns {(string|*)[]}
   */
  static getNumberByUnitFormatter(
    number,
    unitType,
    unitInitial,
    unitToSet,
    options = { round: 1 }
  ) {
    const unitsTypeSelected = this.dispatcherUnit(unitType)

    const unitInitialIndex = unitsTypeSelected.findIndex(
      (unit) => unit === unitInitial
    )

    const unitToSetIndex = unitsTypeSelected.findIndex(
      (unit) => unit === unitToSet
    )

    return [
      (Number(number) / 1000 ** (unitToSetIndex - unitInitialIndex)).toFixed(
        options.round
      ),
      unitToSet,
    ]
  }

  /**
   * @param unitType storage | count | emission | volume
   * @param unit
   * @returns {string}
   */
  static getI18nUnit(unitType, unit) {
    return i18n.t(`units:units.${unitType}.${unit}`)
  }

  /**
   * @param unitType storage | count | emission | volume
   * @returns {string[]}
   */
  static dispatcherUnit(unitType) {
    switch (unitType) {
      case EMISSION_UNIT: {
        return ["g_unit", "kg_unit", "t_unit", "kt_unit"]
      }

      case STORAGE_UNIT: {
        return ["gb_unit", "tb_unit", "pb_unit", "eb_unit"]
      }

      case COUNT_UNIT: {
        return ["zero_unit", "k_unit", "m_unit", "b_unit", "t_unit"]
      }

      case VOLUME_UNIT: {
        return ["l_unit", "m3_unit", "dam3_unit", "hm3_unit", "km3_unit"]
      }

      default:
        throw `[NumberFormatterApp] Unexpected unit type (${unitType})`
    }
  }

  /**
   * Remove useless zeros after the comma
   * @param number
   * @return string
   */
  static removeTrailingZeros(number) {
    return number.replace(/\.0+$/, "")
  }
}
