import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import ChartOdd13InternalAppsBreakdown from "views/molecules/canvases/charts/ChartOdd13InternalAppsBreakdown"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"

const AppsAnalysisOrganism = ({ score }) => {
  const { t } = useTranslation()
  const tPrefix = "analyze:it_domain.consolidated.apps"

  const convertedTotalEmissions = convertValueToReadable(
    score.CO2_emissions.value,
    T_CO2E,
    CO2_EMISSION_UNITS
  )

  console.log(
    score.CO2_emissions.value,
    convertedTotalEmissions.value,
    convertedTotalEmissions.unit
  )

  const chart1 = () => {
    const content = {
      kpi: convertedTotalEmissions.value,
      kpi_unit: t(`units:units.emission.${convertedTotalEmissions.unit}`),
      kpi_text: t(`${tPrefix}.emissions.title`),
      reference: score.CO2_emissions.percentage,
      reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
      reference_unit: "%",
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${tPrefix}.emissions.help`)}>
        <TextChart content={<TextChartMajor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const averageEmissions = () => {
    const localPrefix = `${tPrefix}.average_CO2`
    const stats = score.average_CO2

    const content = {
      kpi: stats.per_fte,
      kpi_unit: (
        <>
          <Unit
            unit={NumberFormatterApp.getI18nUnit(EMISSION_UNIT, "kg_unit")}
          />
          {t(`${tPrefix}.perFte`)}
        </>
      ),
      kpi_text: t(`${localPrefix}.title`),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const averageEmissionFactor = () => {
    const localPrefix = `${tPrefix}.emission_factor`

    const [
      emissions,
      emissionsUnitEnum,
    ] = NumberFormatterApp.getNumberFormatter(
      score.emission_factor,
      "emission",
      "t_unit"
    )

    const content = {
      kpi: emissions,
      kpi_unit: (
        <>
          <Unit
            unit={NumberFormatterApp.getI18nUnit(
              EMISSION_UNIT,
              emissionsUnitEnum
            )}
          />
          {t(`${localPrefix}.perApp`)}
        </>
      ),
      kpi_text: t(`${localPrefix}.title`),
    }

    return (
      <HelpWidgetChartInteractiveMolecule text={t(`${localPrefix}.help`)}>
        <TextChart content={<TextChartMinor content={content} />} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  return (
    <ScoreLayoutOrganism
      maturity={score?.score ?? 0}
      chart1={chart1()}
      chart2={averageEmissions()}
      chart3={
        <ChartOdd13InternalAppsBreakdown
          localPrefix={`${tPrefix}.clusters`}
          data={score?.clusters}
          labelGetter={(label) => label ?? t(`${tPrefix}.clusters.empty`)}
        />
      }
      chart4={
        <ChartOdd13InternalAppsBreakdown
          localPrefix={`${tPrefix}.applications`}
          data={score?.applications}
          labelGetter={(label) => label ?? t(`${tPrefix}.applications.empty`)}
        />
      }
      chart5={averageEmissionFactor()}
      chart6={
        <ChartOdd13InternalAppsBreakdown
          localPrefix={`${tPrefix}.app_functions`}
          data={score?.app_functions}
          labelGetter={(label) =>
            label
              ? t(`common:app_functions.${label}`)
              : `${tPrefix}.app_functions.empty`
          }
        />
      }
    />
  )
}

AppsAnalysisOrganism.propTypes = {
  score: PropTypes.shape({
    score: PropTypes.number.isRequired,
    CO2_emissions: PropTypes.shape({
      value: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    }).isRequired,
    average_CO2: PropTypes.shape({
      per_fte: PropTypes.number.isRequired,
    }).isRequired,
    emission_factor: PropTypes.number.isRequired,
    clusters: PropTypes.array.isRequired,
    applications: PropTypes.object.isRequired,
    app_functions: PropTypes.object.isRequired,
  }).isRequired,
}

export default AppsAnalysisOrganism
