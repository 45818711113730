import CountryApp from "services/apps/countryApp"
import { NODE_COUNTRY_LIKE_TYPES } from "utils/constants/organization/nodeTypes"

const useNodeCharacteristic = () => {
  const getFormattedName = (name, type) => {
    return NODE_COUNTRY_LIKE_TYPES.includes(type)
      ? CountryApp.getNameI18n(name)
      : name
  }

  return { getFormattedName }
}

export default useNodeCharacteristic
