import React, { useEffect, useState } from "react"
import { FormLayout, Form } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import SelectReportAtom from "views/atoms/reports/formInputs/SelectReportAtom"
import MultipleOptionListReportAtom from "views/atoms/reports/formInputs/MultipleOptionListReportAtom"

const FormCreateReportMolecule = ({
  createReport,
  setReportFormData,
  errors,
}) => {
  const { t } = useTranslation()

  const [reportType, setReportType] = useState("CIO")
  const [language, setLanguage] = useState("")
  const [pillars, setPillars] = useState(["environmental_pillar", false, false])

  useEffect(async () => {
    try {
      const data = {
        report_type: reportType,
        language: language,
        environmental_pillar: pillars.includes("environmental_pillar"),
        social_pillar: pillars.includes("social_pillar"),
        profit_pillar: pillars.includes("profit_pillar"),
      }
      setReportFormData(data)
    } catch {
      //
    } finally {
      //
    }
  }, [reportType, language, pillars])

  return (
    <Form onSubmit={createReport}>
      <FormLayout>
        <SelectReportAtom
          id={"report_type"}
          value={reportType}
          onChange={setReportType}
          options={[
            {
              label: t("share:content.reports_modal.report_type.options.CIO"),
              value: "CIO",
            },
            {
              label: t("share:content.reports_modal.report_type.options.CSR"),
              value: "CSR",
              disabled: true,
            },
          ]}
          errors={errors}
        />
        <SelectReportAtom
          id={"language"}
          value={language}
          onChange={setLanguage}
          options={[
            {
              label: t("share:content.reports_modal.language.options.en"),
              value: "en",
            },
            {
              label: t("share:content.reports_modal.language.options.fr"),
              value: "fr",
            },
          ]}
          errors={errors}
        />
        <MultipleOptionListReportAtom
          id={"pillars"}
          values={pillars}
          onChange={setPillars}
          options={[
            {
              value: "environmental_pillar",
              label: t(
                "share:content.reports_modal.pillars.environmental_pillar"
              ),
              disabled: true,
            },
            {
              value: "social_pillar",
              label: t("share:content.reports_modal.pillars.social_pillar"),
              disabled: true,
            },
            {
              value: "profit_pillar",
              label: t("share:content.reports_modal.pillars.profit_pillar"),
              disabled: true,
            },
          ]}
          errors={errors}
        />
      </FormLayout>
    </Form>
  )
}

FormCreateReportMolecule.propTypes = {
  createReport: PropTypes.func,
  setReportFormData: PropTypes.func,
  errors: PropTypes.object,
}

export default FormCreateReportMolecule
