import React from "react"
import Form from "components/widgets/CollectPage/forms/Form"
import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import CheckboxField from "components/widgets/CollectPage/forms/fields/CheckboxField"
import RadioButton from "components/widgets/CollectPage/forms/fields/RadioButton"
import { useTranslation } from "react-i18next"
import { useFormConfig } from "components/widgets/CollectPage/forms/FormContext"
import { fieldIsFalsy } from "components/widgets/CollectPage/useDisablingFields"
import TeamCompositionFields, {
  teamConfigGenerator,
} from "components/widgets/CollectPage/forms/pillars/financial/TeamCompositionFields"
import { INVENTORY_PROJECTS } from "utils/constants/centralization/inventoryItems"
import { Module } from "components/widgets/CollectPage/forms/Module"

const TEAM = "projects"
const teamConfig = teamConfigGenerator(TEAM)

const fields = [
  ...teamConfig.fields,
  "projects_team_budget",
  "projects_process_go_no_go_presence",
  "projects_process_business_present",
  "projects_process_eco_conception_present",
  "projects_process_green_criteria_presence",
  "projects_process_green_criteria_weight",
  "projects_process_green_criteria_sgd_link",
  "projects_process_green_study",
  "projects_process_green_scope",
  "projects_process_sourcing_strategy",
  "projects_process_green_how_quantitative",
  "projects_process_green_how_qualitative",
]

const disableConfig = [
  teamConfig.disablingConfig,
  {
    disablingField: "projects_process_green_criteria_presence",
    fieldsToDisable: [
      "projects_process_green_criteria_weight",
      "projects_process_green_criteria_sgd_link",
    ],
  },
  {
    disablingField: "projects_process_go_no_go_presence",
    fieldsToDisable: [
      "projects_process_business_present",
      "projects_process_eco_conception_present",
      "projects_process_green_criteria_presence",
      "projects_process_green_criteria_sgd_link",
    ],
  },
  {
    disablingField: "projects_process_green_study",
    condition: (value) => fieldIsFalsy(value) || value === "none",
    fieldsToDisable: [
      "projects_process_green_scope",
      "projects_process_green_how_quantitative",
      "projects_process_green_how_qualitative",
      "projects_process_sourcing_strategy",
    ],
  },
]

const translationPath = "8.project_governance"
const tPrefix = `collect:questions.${translationPath}`

const ProjectGovernanceForm = () => {
  const { t } = useTranslation()

  useFormConfig({ fields, translationPath, disableConfig })

  return (
    <Form>
      <FormLayout>
        <Module module={INVENTORY_PROJECTS}>
          <div className="columns">
            <div className="column">
              <TeamCompositionFields prefix={TEAM} withoutField />
              <FormLayout>
                <TextField id="projects_team_budget" type="number" />
              </FormLayout>
            </div>
            <div className="column">
              <FormLayout>
                <YesNoRadio id="projects_process_go_no_go_presence" />
                <table>
                  <thead>
                    <tr>
                      <td />
                      <td>{t(`${tPrefix}.presence`)}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      "projects_process_business_present",
                      "projects_process_eco_conception_present",
                      "projects_process_green_criteria_presence",
                    ].map((field) => (
                      <tr key={field}>
                        <td>{t(`${tPrefix}.${field}`)}</td>
                        <td>
                          <YesNoRadio id={field} />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        {t(`${tPrefix}.projects_process_green_criteria_weight`)}
                      </td>
                      <td>
                        <TextField
                          id="projects_process_green_criteria_weight"
                          type="number"
                          suffix="%"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          `${tPrefix}.projects_process_green_criteria_sgd_link`
                        )}
                      </td>
                      <td>
                        <YesNoRadio id="projects_process_green_criteria_sgd_link" />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ExternalLabel translationPathName="projects_process_green" />

                <table>
                  <tbody>
                    <tr>
                      <td>
                        {t(`${tPrefix}.projects_process_green_study.row`)}
                      </td>
                      <td>
                        <Select
                          id="projects_process_green_study"
                          options={["none", "big_projects", "all_projects"]}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {t(`${tPrefix}.projects_process_green_scope.row`)}
                      </td>
                      <td>
                        <Select
                          id="projects_process_green_scope"
                          options={["ghg", "planet", "csr"]}
                        />
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "none" }}>
                      <td rowSpan="2">
                        {t(`${tPrefix}.projects_process_green_how`)}
                      </td>
                      <td style={{ padding: "1px 5px" }}>
                        <CheckboxField id="projects_process_green_how_qualitative" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "1px 5px" }}>
                        <CheckboxField id="projects_process_green_how_quantitative" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(`${tPrefix}.projects_process_sourcing_strategy.row`)}
                      </td>
                      <td>
                        <RadioButton
                          id="projects_process_sourcing_strategy"
                          choices={["make", "buy"].map((choice) => ({
                            value: choice,
                            label: t(
                              `${tPrefix}.projects_process_sourcing_strategy.${choice}`
                            ),
                          }))}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormLayout>
            </div>
          </div>
        </Module>
      </FormLayout>
    </Form>
  )
}

export default ProjectGovernanceForm
