import classNames from "classnames"
import { min } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import "stylesheets/widgets/ScoreGauge.scss"
import { default as Seedling } from "assets/images/seedling.svg"
import { default as Herb } from "assets/images/herb.svg"
import { default as Tree } from "assets/images/tree.svg"

const ScoreGauge = ({ score }) => {
  const calculateIconSize = (level) => {
    if (score === null) return 30

    if (level - 33 < score && score < level) {
      return 50
    } else {
      return 30
    }
  }

  return (
    <div
      className={classNames("Verdikt-ScoreGauge", {
        "Verdikt-ScoreGauge--disabled": !score,
      })}
    >
      <ul className="icons">
        <li className={classNames("icon", { selected: score <= 33 })}>
          <img
            src={Seedling}
            alt="🌱"
            width={calculateIconSize(33)}
            height={calculateIconSize(33)}
          />
        </li>
        <li className={classNames("icon", { selected: score <= 67 })}>
          <img
            src={Herb}
            alt="🌿"
            width={calculateIconSize(67)}
            height={calculateIconSize(67)}
          />
        </li>
        <li className={classNames("icon", { selected: score > 67 })}>
          <img
            src={Tree}
            alt="🌳"
            width={calculateIconSize(100)}
            height={calculateIconSize(100)}
          />
        </li>
      </ul>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={10}
        className="cursor"
        // TODO: change cursor
        style={{ marginLeft: `${min([score, 95])}%` }}
      >
        <polygon points="0,10 10,10 5,0" />
      </svg>
      <div className="bar"></div>
    </div>
  )
}

ScoreGauge.propTypes = {
  score: PropTypes.number,
}

ScoreGauge.defaultProps = {
  score: null,
}

export default ScoreGauge
