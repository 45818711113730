import React, { useCallback, useEffect, useReducer, useState } from "react"
import {
  Banner,
  Button,
  Form as PForm,
  FormLayout,
  Icon,
  Modal,
  Select,
  TextField,
} from "@shopify/polaris"
import { CirclePlusMajor, EditMajor, ViewMajor } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"
import CloudUsageEntity from "services/entities/CloudUsageEntity"
import { useEntity } from "hooks/useAxios"
import PropTypes from "prop-types"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"

const formReducer = (state, action) => {
  return { ...state, ...action }
}

const CLOUD_PROVIDER_OPTIONS = [
  { value: "", label: "" },
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
  { value: "gcp", label: "GCP" },
]

const EMPTY_VALUE = ""

const defaultFormData = {
  provider: EMPTY_VALUE,
  cost: EMPTY_VALUE,
  service_name: EMPTY_VALUE,
  region: "",
  instance_type: EMPTY_VALUE,
  usage_type: EMPTY_VALUE,
  usage_unit: EMPTY_VALUE,
  usage_amount: EMPTY_VALUE,
}

const ModalCloudUsage = ({ item, postAction }) => {
  const { moduleIsActive } = useModuleContext()
  const { isLocked } = useFieldIsLocked()
  const [formData, dispatch] = useReducer(formReducer, item || defaultFormData)
  const [errors, setErrors] = useState(item?.errors || {})
  const [open, setOpen] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [selectDisabled, setSelectDisabled] = useState(true)
  const [regionList, setRegionList] = useState([""])
  const [usageUnitList, setUsageUnitList] = useState([""])
  const [placeholders, setPlaceholders] = useState({})
  const handleChange = useCallback(() => setOpen(!open), [open])
  const { t } = useTranslation()
  const cloudUsageEntity = useEntity(CloudUsageEntity)
  const prefix = "collect:questions.common.cloud_form."
  const titleModal = item
    ? t(prefix + "title_update_usage")
    : t(prefix + "title_create_usage")

  useEffect(() => {
    if (formData.provider) {
      onProviderChange(formData.provider, "provider", false)
    }
  }, [])

  const onProviderChange = async (value, id, reset = true) => {
    try {
      if (value) {
        const response = await cloudUsageEntity.data(value)
        setRegionList([EMPTY_VALUE, ...response.data.regions])
        setUsageUnitList([EMPTY_VALUE, ...response.data.known_usage_units])
        setPlaceholders(response.data.placeholders)
        setSelectDisabled(false)
      } else {
        setSelectDisabled(true)
        setRegionList([EMPTY_VALUE])
        setUsageUnitList([EMPTY_VALUE])
      }
    } catch (err) {
      console.log(err)
    }

    if (reset) {
      dispatch({ region: EMPTY_VALUE, usage_unit: EMPTY_VALUE })
    }

    updateField(value, id)
  }

  const handleDelete = async () => {
    try {
      await cloudUsageEntity.delete(item.id)
      postAction()
    } catch (err) {
      console.log(err)
    }
  }

  const updateField = (value, id) => {
    dispatch({ [id]: value })

    if (errors[id]) {
      setErrors({ ...errors, [id]: undefined })
    }
  }

  const deleteBanner = () => setDeleteConfirm(true)

  const handleUploadSubmit = async () => {
    try {
      formData.id
        ? await cloudUsageEntity.update(formData.id, formData)
        : await cloudUsageEntity.create(formData)
      setOpen(false)
      setDeleteConfirm(false)
      postAction()
    } catch (err) {
      if (err.isAxiosError) {
        setErrors(err.response.data)
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDeleteConfirm(false)
  }

  const getSecondaryAction = () => {
    if (isLocked || !formData.id) {
      return {
        content: t("common:cancel"),
        onAction: handleClose,
      }
    }

    return {
      content: t("common:delete"),
      onAction: deleteBanner,
      destructive: true,
    }
  }

  const icon = moduleIsActive
    ? formData.id
      ? EditMajor
      : CirclePlusMajor
    : ViewMajor

  const activator = (
    <button onClick={handleChange}>
      <div className="iconCollectType">
        <Icon source={icon} />
      </div>
    </button>
  )

  return (
    <Modal
      activator={isLocked && !formData.id ? null : activator}
      open={open}
      onClose={handleClose}
      title={titleModal}
      primaryAction={
        !isLocked && {
          content: t(
            "collect:questions.13.datacenter_external.dc.modal.submit"
          ),
          onAction: handleUploadSubmit,
        }
      }
      secondaryActions={getSecondaryAction()}
    >
      {deleteConfirm ? (
        <Modal.Section>
          <Banner status="warning" title={t(prefix + "title_delete")}>
            <p>{t(prefix + "description_delete")}</p>
            <Button status="danger" onClick={handleDelete}>
              {t("common:delete")}
            </Button>
          </Banner>
        </Modal.Section>
      ) : (
        <Modal.Section>
          <div className="environmental">
            <PForm implicitSubmit={false} onSubmit={handleUploadSubmit}>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Select
                    id="provider"
                    label={t(prefix + "provider")}
                    options={CLOUD_PROVIDER_OPTIONS}
                    value={formData.provider}
                    onChange={onProviderChange}
                    error={errors.provider}
                    disabled={isLocked}
                  />
                  <Select
                    id="region"
                    type="string"
                    options={regionList}
                    label={t(prefix + "region")}
                    value={formData.region}
                    onChange={updateField}
                    error={errors.region}
                    disabled={selectDisabled || isLocked}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    id="service_name"
                    type="string"
                    label={t(prefix + "service_name")}
                    value={formData.service_name}
                    onChange={updateField}
                    error={errors.service_name}
                    placeholder={placeholders.service_name}
                    disabled={isLocked}
                  />

                  <TextField
                    id="instance_type"
                    type="string"
                    label={t(prefix + "instance_type")}
                    value={formData.instance_type}
                    onChange={updateField}
                    error={errors.instance_type}
                    placeholder={placeholders.instance_type}
                    disabled={isLocked}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    id="usage_type"
                    type="string"
                    label={t(prefix + "usage_type")}
                    value={formData.usage_type}
                    onChange={updateField}
                    error={errors.usage_type}
                    placeholder={placeholders.usage_type}
                    disabled={isLocked}
                  />
                  <TextField
                    id="cost"
                    type="number"
                    label={t(prefix + "cost")}
                    value={formData.cost?.toString()}
                    onChange={updateField}
                    error={errors.cost}
                    placeholder={placeholders.cost}
                    suffix="€"
                    disabled={isLocked}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    id="usage_amount"
                    type="number"
                    label={t(prefix + "usage_amount")}
                    value={Math.round(formData.usage_amount).toString()}
                    onChange={updateField}
                    error={errors.usage_amount}
                    placeholder={placeholders.usage_amount}
                    disabled={isLocked}
                    step={1}
                  />
                  <Select
                    id="usage_unit"
                    type="string"
                    label={t(prefix + "usage_unit")}
                    options={usageUnitList}
                    value={formData.usage_unit}
                    onChange={updateField}
                    disabled={selectDisabled || isLocked}
                    error={errors.usage_unit}
                  />
                </FormLayout.Group>
              </FormLayout>
            </PForm>
          </div>
        </Modal.Section>
      )}
    </Modal>
  )
}

ModalCloudUsage.propTypes = {
  item: PropTypes.object,
  postAction: PropTypes.func,
}

ModalCloudUsage.defaultProps = {
  postAction: () => {},
}

export default ModalCloudUsage
