import React, { useCallback } from "react"
import PropTypes from "prop-types"
import "stylesheets/Analyze/Historization/historization_analyze.scss"
import BadgeStatusChartAtom, {
  STATUS_IMPROVING,
  STATUS_NEUTRAL,
  STATUS_NEW,
  STATUS_WORSEN,
} from "views/atoms/canvases/charts/BadgeStatusChartAtom"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import {
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_NEW,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"
import Unit from "views/atoms/reports/Unit"

const KpiTitleAtom = ({
  name,
  value,
  valueUnit,
  delta,
  deltaUnit,
  valueStatus,
  freeText,
  currentUnitToLeft,
}) => {
  const unitWithScripts = <Unit unit={deltaUnit} />

  const deltaComponent = useCallback(() => {
    if (delta === null || delta === undefined) {
      return ""
    }

    return (
      <>
        {delta >= 0 ? "+" : ""}
        {delta} {unitWithScripts}
      </>
    )
  }, [delta, unitWithScripts])

  const getBadgeStatus = useCallback(() => {
    switch (valueStatus) {
      case KPI_STATUS_UP:
        return STATUS_IMPROVING

      case KPI_STATUS_DOWN:
        return STATUS_WORSEN

      case KPI_STATUS_NEUTRAL:
        return STATUS_NEUTRAL

      case KPI_STATUS_NEW:
        return STATUS_NEW
    }
  }, [valueStatus])

  const getKpi = () => {
    return currentUnitToLeft ? `${valueUnit} ${value}` : `${value} ${valueUnit}`
  }

  const content = {
    kpi_title: freeText,
    kpi: value !== null ? getKpi() : null,
    kpi_unit_suffix: deltaComponent(),
    kpi_text: name,
  }

  const content_for_display = <TextChartMajor content={content} />

  return (
    <>
      <TextChart content={content_for_display} />
      {valueStatus && (
        <p>
          {valueStatus && <BadgeStatusChartAtom status={getBadgeStatus()} />}
        </p>
      )}
    </>
  )
}

export default KpiTitleAtom

KpiTitleAtom.propTypes = {
  delta: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  valueStatus: PropTypes.oneOf([
    KPI_STATUS_UP,
    KPI_STATUS_NEUTRAL,
    KPI_STATUS_DOWN,
  ]),
  valueUnit: PropTypes.string,
  deltaUnit: PropTypes.string,
  freeText: PropTypes.string,
  currentUnitToLeft: PropTypes.bool,
}

KpiTitleAtom.defaultProps = {
  delta: null,
  valueStatus: null,
  valueUnit: "",
  deltaUnit: "",
  freeText: "",
  currentUnitToLeft: false,
}
