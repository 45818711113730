import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "stylesheets/ProgressBar.scss"

function ProgressBar({
  progress,
  chart = false,
  pillarId,
  objectiveId,
  themeId,
}) {
  const [hidden, setHidden] = useState(false)
  const [percent, setPercent] = useState(0)

  const pillarProgress = useCallback(
    () => (pillarId ? progress?.pillars?.[pillarId] : null),
    [progress]
  )

  const objectiveProgress = useCallback(
    () => (objectiveId ? pillarProgress()?.objectives[objectiveId] : null),
    [progress]
  )

  const themeProgress = useCallback(
    () => (themeId ? objectiveProgress()?.themes[themeId] : null),
    [progress]
  )

  useEffect(() => {
    if (!progress) return setPercent(0)
    if (chart || typeof progress === "number") return setPercent(progress)

    let progressForElement
    if (pillarId && objectiveId && themeId) {
      progressForElement = themeProgress()
    } else if (pillarId && objectiveId) {
      progressForElement = objectiveProgress()
    } else if (pillarId) {
      progressForElement = pillarProgress()
    }

    setHidden(!progressForElement)
    setPercent(progressForElement?.progress ?? 0)
  }, [progress])

  return hidden ? (
    <></>
  ) : (
    <div className={classNames("ProgressBar", pillarId, { chart: chart })}>
      <div
        className={classNames({
          ProgressBar__Filler: true,
          ProgressBar__full: percent === 100,
        })}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}

/**
 * @todo Refactor in two components, one for the number and the other for an object.
 */
ProgressBar.propTypes = {
  pageName: PropTypes.string,
  chart: PropTypes.bool,
  pillarId: PropTypes.string,
  objectiveId: PropTypes.string,
  themeId: PropTypes.string,
  progress: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    .isRequired,
}

export default ProgressBar
