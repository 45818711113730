import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const NoCurrentScopeBannerReportAtom = () => {
  const { t } = useTranslation()

  return (
    <Banner
      title={t("share:content.banners.no_current_scope.title")}
      status="critical"
    >
      <p>{t("share:content.banners.no_current_scope.body")}</p>
    </Banner>
  )
}

export default NoCurrentScopeBannerReportAtom
