import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { Radar } from "react-chartjs-2"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import className from "classnames"
import Colors from "assets/configs/score"
import { radarTooltips } from "services/charts/tooltips"
import { HIDDEN_LEGEND } from "services/charts/legend"

const ChartAnalyzeWebsiteAccessibilityCompliance = ({ websiteScore }) => {
  const { t } = useTranslation()

  const { label_keys, data } = websiteScore.spider_accessibility_categories
  const generateData = () => {
    const labels = label_keys.map((label_key) =>
      t(`analyze:websites.accessibility_compliance_level.labels.${label_key}`)
    )
    return {
      labels,
      datasets: [
        {
          label: t("analyze:websites.accessibility_compliance_level.title"),
          data: [...data],
          dataLabels: labels,
          backgroundColor: Colors.common.colors[0],
          hideDatalabelContent: true,
        },
      ],
    }
  }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t("analyze:websites.accessibility_compliance_level.help")}
    >
      <div
        className={className("Verdikt-Score-DoughnutChart", "environmental")}
      >
        <h1>{t("analyze:websites.accessibility_compliance_level.title")}</h1>
        <div className="content">
          <Radar
            data={generateData()}
            options={{
              ...DEFAULT_OPTIONS,
              legend: HIDDEN_LEGEND,
              tooltips: radarTooltips,
            }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartAnalyzeWebsiteAccessibilityCompliance.propTypes = {
  websiteScore: PropTypes.object.isRequired,
}

export default ChartAnalyzeWebsiteAccessibilityCompliance
