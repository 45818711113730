import useLocalStorage from "hooks/useLocalStorage"
import { useEffect, useState } from "react"
import NodeEntity from "services/entities/NodeEntity"
import { useEntity } from "hooks/useAxios"

const useComparedNodeStorage = (currentNodeId) => {
  const [nodes, setNodes] = useState([])
  const [storage, setLocalStorage] = useLocalStorage("comparedNodes")
  const nodeEntity = useEntity(NodeEntity)
  const [isInitialised, setIsInitialised] = useState(false)

  useEffect(async () => {
    if (storage && currentNodeId) {
      setIsInitialised(false)

      const ids = storage[currentNodeId] || []
      const { data } = await nodeEntity.breadcrumbIndex(ids)
      setNodes(data)

      setIsInitialised(true)
    }
  }, [currentNodeId])

  const setStoredNodes = (newNodes) => {
    setLocalStorage({ ...storage, [currentNodeId]: newNodes.map((n) => n.id) })
  }

  return {
    nodes,
    setStoredNodes,
    isStoredNodesInitialized: isInitialised,
  }
}

export default useComparedNodeStorage
