import { DropZone } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { UndoMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import "stylesheets/blocs/organisms/upload/StatusUploadOrganism.scss"
import StatusUploadMolecule from "views/molecules/upload/StatusUploadMolecule"
import InventoryRatioEntity from "services/entities/InventoryRatioEntity"
import DownloadFileButtonStatusMolecule from "views/molecules/buttons/DownloadFileButtonStatusMolecule"
import QualityFileModalMolecule from "views/molecules/modals/files/QualityFileModalMolecule"
import {
  IMPORT_PROCESSING_STATUSES,
  IMPORT_STATUS_COMPLETED,
  IMPORT_STATUS_ERROR,
} from "utils/constants/imports/importStatus"
import DownloadFileNotAvailableButtonAtom from "../../molecules/buttons/DownloadFileNotAvailableButtonAtom"
import { useCollectContext } from "../../../components/widgets/CollectPage/CollectContextProvider"
import { useEntity } from "../../../hooks/useAxios"

const StatusUploadOrganism = ({
  accept,
  onDrop,
  disabled,
  inventoryStatus,
  setInventoryStatus,
  displayQualityIcon = false,
  displayDownloadIcon = true,
  onRefreshStatus,
  onDownloadFile,
}) => {
  const [isReloading, setIsReloading] = useState(false)

  const { entity, reloadProgress, collect, setCollect } = useCollectContext()
  const inventoryRatioEntity = useEntity(InventoryRatioEntity)

  const inventoryStatusRefreshRequest = async () => {
    try {
      setIsReloading(true)

      const inventoryStatusResponse = await inventoryRatioEntity.getStatus(
        inventoryStatus.id
      )

      if (
        inventoryStatus.processing_status !==
          inventoryStatusResponse.processing_status &&
        inventoryStatusResponse.processing_status === IMPORT_STATUS_COMPLETED
      ) {
        reloadProgress()
        const { data } = await entity.show()
        setCollect(data)
      } else if (
        inventoryStatus.processing_status !==
        inventoryStatusResponse.processing_status
      ) {
        // Share across pages the status update
        setCollect({
          ...collect,
          inventories: {
            ...collect.inventories,
            [inventoryStatus.inventory_type]: inventoryStatusResponse,
          },
        })
      }

      setInventoryStatus(inventoryStatusResponse)
    } catch (err) {
      console.error(err)
    } finally {
      setIsReloading(false)
    }
  }

  const downloadIcon = useCallback(() => {
    if (
      ![IMPORT_STATUS_COMPLETED, IMPORT_STATUS_ERROR].includes(
        inventoryStatus?.processing_status
      ) ||
      !displayDownloadIcon
    )
      return null

    return (
      <span className="statusUpload__icon statusUpload__icon--download">
        {inventoryStatus.has_file ? (
          <DownloadFileButtonStatusMolecule
            inventoryStatus={inventoryStatus}
            onDownloadFile={onDownloadFile}
          />
        ) : (
          <DownloadFileNotAvailableButtonAtom />
        )}
      </span>
    )
  }, [inventoryStatus, displayDownloadIcon])

  return (
    <section className="statusUpload__parent">
      <article className="statusUpload__content">
        <div className="statusUpload__icons">
          {IMPORT_PROCESSING_STATUSES.includes(
            inventoryStatus?.processing_status
          ) && (
            <span
              className={`statusUpload__icon statusUpload__icon--refresh statusUpload__icon--refresh-reload-${isReloading}`}
            >
              <UndoMajor
                onClick={() =>
                  onRefreshStatus
                    ? onRefreshStatus()
                    : inventoryStatusRefreshRequest()
                }
              />
            </span>
          )}

          {inventoryStatus?.processing_status === IMPORT_STATUS_COMPLETED &&
            displayQualityIcon && (
              <span className="statusUpload__icon statusUpload__icons--quality">
                <QualityFileModalMolecule inventoryStatus={inventoryStatus} />
              </span>
            )}

          {downloadIcon()}
        </div>

        <DropZone
          accept={accept}
          allowMultiple={false}
          onDrop={onDrop}
          disabled={disabled}
        >
          <StatusUploadMolecule inventoryStatus={inventoryStatus} />
        </DropZone>
      </article>
    </section>
  )
}

StatusUploadOrganism.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  inventoryStatus: PropTypes.shape({
    id: PropTypes.number.isRequired,
    processing_status: PropTypes.string.isRequired,
    has_file: PropTypes.bool,
    inventory_type: PropTypes.string.isRequired,
  }),
  setInventoryStatus: PropTypes.func,
  displayQualityIcon: PropTypes.bool,
  displayDownloadIcon: PropTypes.bool,
  onRefreshStatus: PropTypes.func,
  onDownloadFile: PropTypes.func,
}

StatusUploadOrganism.defaultPropTypes = {
  setInventoryStatus: () => null,
  onRefreshStatus: null,
  onDownloadFile: null,
}

export default StatusUploadOrganism
