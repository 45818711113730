export const POLICY_ENV_COLLECT = "env_collect"
export const POLICY_SOCIAL_COLLECT = "social_collect"
export const POLICY_CSR = "csr"
export const POLICY_CDR = "cdr"
export const POLICY_ETHICS = "ethics"
export const POLICY_CYBER_SECURITY = "cyber_security"
export const POLICY_PARTNERSHIP_INTERNATIONAL = "partnership_international"
export const POLICY_PARTNERSHIP_REGIONAL = "partnership_regional"
export const POLICY_PARTNERSHIP_NATIONAL = "partnership_national"
export const POLICY_AI_PERSONAL_DATA = "ai_personal_data"
export const POLICY_PARTNERSHIP_PRACTICES = "partnership_practices"
