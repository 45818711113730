import { useState } from "react"

const useLocalStorage = (key) => {
  const getLocalStorage = () => {
    const localValue = localStorage.getItem(key)
    if (localValue) {
      return JSON.parse(localValue)
    }
  }

  const [value, setValue] = useState(getLocalStorage())
  const setLocalStorage = (newValue) => {
    setValue(newValue)
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [value, setLocalStorage]
}

export default useLocalStorage
