import { Badge, Card, Layout, Page } from "@shopify/polaris"
import NotificationsWidget from "components/widgets/NotificationsWidget"
import UserForm from "components/widgets/ActivationPage/UserForm"
import UserProfileDemoCard from "components/widgets/ActivationPage/UserProfileDemoCard"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import UserDeletion from "../widgets/ActivationPage/UserDeletion"
import withAuthRequired from "../auth/withAuthRequired"
import Loading from "../widgets/Loading"

const UserProfile = () => {
  const { t } = useTranslation()

  const { currentUser } = useAppContext()

  if (!currentUser) return <Loading />

  return (
    <Page title={t("user_profile:title")}>
      <Layout>
        <NotificationsWidget />
        <Layout.AnnotatedSection
          title={
            <>
              {t("user_profile:account.title")}
              <Badge size="small" status="success">
                {t(`common:user_form.roles.${currentUser.role_override}`)}
              </Badge>
            </>
          }
          description={t("user_profile:account.description")}
        >
          <Card>
            <Card.Section title={t("activation:user.questions.section_login")}>
              <UserForm />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={<>{t("user_profile:dangerous.title")}</>}
          description={t("user_profile:dangerous.description")}
        >
          <Card>
            <Card.Section
              title={t("user_profile:dangerous.user_deletion.title")}
            >
              <UserDeletion />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <UserProfileDemoCard />
      </Layout>
    </Page>
  )
}

export default withAuthRequired(UserProfile)
