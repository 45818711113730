import React from "react"
import PropTypes from "prop-types"

import "stylesheets/blocs/molecules/objectives/SDGObjectiveMolecule.scss"
import SDGObjectiveMolecule from "views/molecules/objectives/SDGObjectiveMolecule"
import { PILLARS } from "utils/constants/pillars"

const SDGsObjectiveOrganism = ({ objectives, themeType }) => {
  return objectives.map((objective, idx) => (
    <SDGObjectiveMolecule
      key={idx}
      url={objective.url}
      selected={objective.selected}
      objectiveId={objective.objectiveId}
      themeType={themeType}
    ></SDGObjectiveMolecule>
  ))
}

SDGsObjectiveOrganism.propTypes = {
  objectives: PropTypes.array,

  themeType: PropTypes.oneOf(PILLARS).isRequired,
}

SDGsObjectiveOrganism.defaultProps = {
  objectives: [],
}

export default SDGsObjectiveOrganism
