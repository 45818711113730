import React, { createContext, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

const AuthContext = createContext(null)

export const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") == "true"
  )

  useEffect(() => {
    localStorage.setItem("isLoggedIn", String(isAuthenticated))
  }, [isAuthenticated])

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
