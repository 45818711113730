import { Banner, Layout, Page } from "@shopify/polaris"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

const ViewerOnlyBanner = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner
            title={t(`collect:questions.common.banner.reader_only.title`)}
            status="warning"
          >
            {t(`collect:questions.common.banner.reader_only.description`)}
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

ViewerOnlyBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
}

export default ViewerOnlyBanner
