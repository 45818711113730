import { useEffect, useState } from "react"
import useAuth0 from "../components/auth/useAuth0"

const DEFAULT_OPTIONS = {
  defaultValue: null,
  condition: true,
  getter: ({ data }) => data,
}

/**
 * @param {Function} callback
 * @param dependencies
 * @param options
 * @returns {{isLoading: boolean, isError: boolean, reload: (function(): void), data: any, error: any, isSuccess: boolean}}
 */
const useRequest = (callback, dependencies = [], options = DEFAULT_OPTIONS) => {
  const { defaultValue, condition, getter } = { ...DEFAULT_OPTIONS, ...options }

  const [data, setData] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)
  const [errorCode, setErrorCode] = useState(null)
  const { isAuthenticated } = useAuth0()

  const fetch = () => {
    if (!isAuthenticated) return
    if (!condition) {
      setIsLoading(false)
      setData(defaultValue)
      return
    }

    let shouldUpdate = true

    setError(null)
    setIsError(false)
    setIsLoading(true)
    callback()
      .then((result) => {
        if (shouldUpdate) {
          setData(getter(result))
          setIsSuccess(true)
        }
      })
      .catch((err) => {
        setIsError(true)
        if (err.response) {
          setError(err.response.data)
          setErrorCode(err.response.status)
        }
        setData(defaultValue)
      })
      .finally(() => {
        if (shouldUpdate) setIsLoading(false)
      })

    return () => {
      shouldUpdate = false
    }
  }

  useEffect(fetch, [...dependencies, isAuthenticated])

  return {
    data,
    setData,
    isLoading: !isAuthenticated || isLoading,
    isSuccess,
    isError,
    error,
    errorCode,
    reload: fetch,
  }
}

export default useRequest
