import {
  NODE_TYPE_CLIENT,
  NODE_TYPE_COMPANY,
} from "utils/constants/organization/nodeTypes"

export const getTypeForNodeTree = (type, currentRoot) => {
  if (type === NODE_TYPE_COMPANY && currentRoot?.is_provider) {
    return NODE_TYPE_CLIENT
  }

  return type
}
