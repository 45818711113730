import React, { useEffect, useState } from "react"
import Form from "../../Form"
import { FormLayout } from "@shopify/polaris"
import { TextField } from "../../fields/CollectFields"
import { useFormConfig, useFormContext } from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"

const fields = [
  "diversity_IT_number_nationalities",
  "diversity_IT_under_represented_communities_management_rate",
  "diversity_IT_non_local_employee_rate",
  "upload_detailed_inventory",
]
const translationPath = "10.diversity_and_inclusion"

const Diversity = () => {
  const { formData } = useFormContext()
  const [disabled, setDisabled] = useState(false)

  useFormConfig({ fields, translationPath })

  useEffect(() => setDisabled(formData.upload_detailed_inventory), [formData])

  useNullifyingField("upload_detailed_inventory", [
    "diversity_IT_number_nationalities",
    "diversity_IT_under_represented_communities_management_rate",
    "diversity_IT_non_local_employee_rate",
  ])

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <FormLayout>
            <TextField
              id="diversity_IT_number_nationalities"
              type="number"
              disabled={disabled}
            />
            <TextField
              id="diversity_IT_under_represented_communities_management_rate"
              type="number"
              disabled={disabled}
            />
            <TextField
              id="diversity_IT_non_local_employee_rate"
              type="number"
              disabled={disabled}
            />
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default Diversity
