import PropTypes from "prop-types"
import React from "react"
import "stylesheets/ImprovePage/SeverityGraph.scss"
import { useTranslation } from "react-i18next"

const LevelEmblem = ({ severity, pillar, type }) => {
  const { t } = useTranslation()

  /**
   * @description Translates severity score into a word
   * @returns String representing the severity
   */
  const translateSeverity = () => {
    if (typeof severity === "string") return severity
    switch (severity) {
      case 0:
        return "low"
      case 50:
        return "medium"
      case 100:
        return "high"
    }
  }

  const typeWithSeverity = () => `${type}-${translateSeverity()}`

  return (
    <div className={`severityGraph severityGraph--${pillar}`}>
      <div
        className={`severityGraph__graph-container severityGraph__graph-container--${typeWithSeverity()}`}
      >
        <div
          className={`severityGraph__graph severityGraph__graph--${translateSeverity()} severityGraph__graph--${typeWithSeverity()}`}
        ></div>
      </div>
      <div
        className={`severityGraph__legend severityGraph__legend--${typeWithSeverity()}`}
      >
        {t(`improve:recommendations_table.severity.${translateSeverity()}`)}
      </div>
    </div>
  )
}

LevelEmblem.propTypes = {
  pillar: PropTypes.string.isRequired,
  severity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  type: PropTypes.string.isRequired,
}

export default LevelEmblem
