import { Banner } from "@shopify/polaris"
import PropTypes from "prop-types"
import React from "react"

const ConditionalBanner = ({ title, status, children }) => {
  return children ? (
    <Banner title={title} status={status}>
      {children}
    </Banner>
  ) : null
}

ConditionalBanner.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default ConditionalBanner
