import React from "react"
import PropTypes from "prop-types"

const CentralizationMessage = ({ text }) => {
  return (
    <p>
      <i>{text}</i>
    </p>
  )
}

CentralizationMessage.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CentralizationMessage
