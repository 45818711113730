import React from "react"
import { TextField } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const LessPaperHypothesesMolecule = ({ onChange, value }) => {
  const { t } = useTranslation()
  return (
    <div className="recommendationsLayout__details__config__hypotheses">
      <div>
        <TextField
          label={null}
          onChange={onChange}
          value={`${value}`}
          type="number"
          inputMode="numeric"
          disabled
          suffix={t("recommendation:propositions.hypotheses.less_paper.suffix")}
        />
      </div>
    </div>
  )
}

LessPaperHypothesesMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default LessPaperHypothesesMolecule
