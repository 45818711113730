import React, { useState } from "react"
import FileDownloaderEntity from "services/entities/FileDownloaderEntity"
import "stylesheets/blocs/atoms/buttons/DownloadFileButtonAtom.scss"
import PropTypes from "prop-types"
import { FolderDownMajor } from "@shopify/polaris-icons"
import { useEntity } from "hooks/useAxios"
import { downloadFile } from "services/download/download"

const DownloadFileButtonAtom = ({ inventoryId, onDownloadFile }) => {
  const [loading, setLoading] = useState(false)
  const fileDownloaderEntity = useEntity(FileDownloaderEntity)

  const defaultDownloadFile = async () => {
    const downloadUrl = await fileDownloaderEntity.getDownloadFileUrl(
      inventoryId
    )

    downloadFile(downloadUrl)
  }

  const handleDownload = async () => {
    try {
      setLoading(true)

      if (onDownloadFile) {
        await onDownloadFile(inventoryId)
      } else {
        await defaultDownloadFile()
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <button disabled={loading} onClick={handleDownload}>
      <FolderDownMajor />
    </button>
  )
}

DownloadFileButtonAtom.propTypes = {
  inventoryId: PropTypes.number.isRequired,
  onDownloadFile: PropTypes.func,
}

DownloadFileButtonAtom.defaultProps = {
  onDownloadFile: null,
}

export default DownloadFileButtonAtom
