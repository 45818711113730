import { COLORS } from "utils/constants/colors"
import _ from "lodash"

// usage: add a `unit` property to each dataset
export const DATALABEL_WITH_UNIT_FORMATER = (value, context) => {
  if (value === null) return null

  const { datasets } = context.chart.data
  const dataset = datasets[context.datasetIndex]
  const unit = context.dataset.datalabelUnit ?? context.dataset.unit ?? ""
  const showUnitOnDatalabel = context.dataset.showUnitOnDatalabel ?? false
  const showLabelInData = context.dataset.showLabelInData ?? false
  const hideDatalabelContent = context.dataset.hideDatalabelContent ?? false
  const datalabelValue =
    dataset.datalabelValues && dataset.datalabelValues[context.dataIndex] // use it if you want to show a different value in the datalabel

  if (hideDatalabelContent) return null

  const summedData = datasets[0].data.map((data, index) => {
    return _.sumBy(datasets, (dataset) =>
      dataset.type === "line" ? 0 : dataset.data[index]
    )
  })

  value = datalabelValue || value

  const valueMax = Math.max(..._.without(summedData, null))

  if (value / valueMax < 0.04) return null

  if (showLabelInData) {
    return (
      context?.dataset?.dataLabels?.[context.dataIndex] || context.dataset.label
    )
  }

  return showUnitOnDatalabel ? `${value} ${unit}` : value
}

export const DEFAULT_DATALABELS = {
  datalabels: {
    color: COLORS.white,
    font: {
      weight: "bold",
    },
    formatter: DATALABEL_WITH_UNIT_FORMATER,
    textAlign: "center",
  },
}
