import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import StackedChart from "components/widgets/ScorePage/widgets/StackedChart"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import Config from "assets/configs/score"

const ChartODD13NetworkEquipementsGHGEmissionsMolecule = ({
  stackedChart = [],
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const unit = stackedChart[0]?.unit

  const labels = stackedChart.map((node) =>
    t(`common:equipment_types.${node.label}`)
  )
  let fab_data_to_be_displayed = stackedChart.map(
    (node) => node.fab_data_to_be_displayed
  )
  let use_data_to_be_displayed = stackedChart.map(
    (node) => node.use_data_to_be_displayed
  )

  let data = [
    {
      label: t(
        `score:score.13.chartNetworkEquipementsGHGEmissions.manufacturer`
      ),
      data: stackedChart.map((node) => node.fab_data_to_be_displayed_hover),
    },
    {
      label: t(`score:score.13.chartNetworkEquipementsGHGEmissions.use`),
      data: stackedChart.map((node) => node.use_data_to_be_displayed_hover),
    },
  ]

  return (
    <StackedChart
      data={data}
      labels={labels}
      title={t(`${translationPrefix}chartNetworkEquipementsGHGEmissions.title`)}
      fab_data_to_be_displayed={fab_data_to_be_displayed}
      use_data_to_be_displayed={use_data_to_be_displayed}
      fab_suffix={t(`units:units.emission.${unit}`)}
      use_suffix={t(`units:units.emission.${unit}`)}
      pillarId={PILLAR_ENVIRONMENTAL}
      colorCallback={(index, value, datasetIndex) => {
        return Config.common.colors[datasetIndex]
      }}
    />
  )
}

ChartODD13NetworkEquipementsGHGEmissionsMolecule.propTypes = {
  stackedChart: PropTypes.array.isRequired,
}

export default ChartODD13NetworkEquipementsGHGEmissionsMolecule
