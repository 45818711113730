import PropTypes from "prop-types"
import React, { useCallback } from "react"
import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"

const ChartODD13TopGHGPerCountryMolecule = ({ score }) => {
  const { t } = useTranslation()

  const ghgEmissionsCountries = useCallback(() => {
    const ghg_emissions_countries = score?.ghg_emissions_countries || []

    return ghg_emissions_countries.map((node) => {
      return {
        label: NodeOrganization.getName(node?.label, node?.type, {
          countryShortName: true,
        }),
        data: node?.data,
      }
    })
  }, [score])

  const title = t(`score:score.13.chartTopGHGPerCountry.title`)

  const unit = t(
    `units:units.emission.${score?.ghg_emissions_countries[0]?.unit}`
  )

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartTopGHGPerCountry.help`)}
    >
      <BarChart
        title={title}
        data={ghgEmissionsCountries()}
        dataTitle={t(`score:score.13.chartTopGHGPerCountry.dataTitle`)}
        axesLabel={unit}
        displayYAxe={true}
        displayLegend={false}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13TopGHGPerCountryMolecule.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13TopGHGPerCountryMolecule
