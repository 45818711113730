export const CENTRALIZED_COMPANY_INVENTORY_WEBSITE =
  "centralized_company_inventory_website"
export const CENTRALIZED_COMPANY_INVENTORY_DATACENTER_CLOUD =
  "centralized_company_inventory_datacenter_cloud"
export const CENTRALIZED_COMPANY_INVENTORY_DATACENTER_EXTERNAL =
  "centralized_company_inventory_datacenter_external"
export const CENTRALIZED_COMPANY_INVENTORY_DATACENTER_FULL =
  "centralized_company_inventory_datacenter_full"
export const CENTRALIZED_COMPANY_INVENTORY_DATACENTER_INTERNAL =
  "centralized_company_inventory_datacenter_internal"
export const CENTRALIZED_COMPANY_INVENTORY_DIGITAL_TEAM_COMMUTE =
  "centralized_company_inventory_digital_team_commute"
export const CENTRALIZED_COMPANY_INVENTORY_EQUIPMENT_NETWORK =
  "centralized_company_inventory_equipment_network"
export const CENTRALIZED_COMPANY_INVENTORY_EQUIPMENT_WORKPLACE =
  "centralized_company_inventory_equipment_workplace"
export const CENTRALIZED_COMPANY_INVENTORY_EXTERNAL_APPS =
  "centralized_company_inventory_external_apps"
export const CENTRALIZED_COMPANY_INVENTORY_INTERNAL_APPS =
  "centralized_company_inventory_internal_apps"
export const CENTRALIZED_COMPANY_INVENTORY_SOCIAL =
  "centralized_company_inventory_social"
export const CENTRALIZED_BRAND_INVENTORY_WEBSITE =
  "centralized_brand_inventory_website"
export const CENTRALIZED_BRAND_INVENTORY_DATACENTER_CLOUD =
  "centralized_brand_inventory_datacenter_cloud"
export const CENTRALIZED_BRAND_INVENTORY_DATACENTER_EXTERNAL =
  "centralized_brand_inventory_datacenter_external"
export const CENTRALIZED_BRAND_INVENTORY_DATACENTER_FULL =
  "centralized_brand_inventory_datacenter_full"
export const CENTRALIZED_BRAND_INVENTORY_DATACENTER_INTERNAL =
  "centralized_brand_inventory_datacenter_internal"
export const CENTRALIZED_BRAND_INVENTORY_DIGITAL_TEAM_COMMUTE =
  "centralized_brand_inventory_digital_team_commute"
export const CENTRALIZED_BRAND_INVENTORY_EQUIPMENT_NETWORK =
  "centralized_brand_inventory_equipment_network"
export const CENTRALIZED_BRAND_INVENTORY_EQUIPMENT_WORKPLACE =
  "centralized_brand_inventory_equipment_workplace"
export const CENTRALIZED_BRAND_INVENTORY_EXTERNAL_APPS =
  "centralized_brand_inventory_external_apps"
export const CENTRALIZED_BRAND_INVENTORY_INTERNAL_APPS =
  "centralized_brand_inventory_internal_apps"
export const CENTRALIZED_BRAND_INVENTORY_SOCIAL =
  "centralized_brand_inventory_social"
export const CENTRALIZED_REGION_INVENTORY_WEBSITE =
  "centralized_region_inventory_website"
export const CENTRALIZED_REGION_INVENTORY_DATACENTER_CLOUD =
  "centralized_region_inventory_datacenter_cloud"
export const CENTRALIZED_REGION_INVENTORY_DATACENTER_EXTERNAL =
  "centralized_region_inventory_datacenter_external"
export const CENTRALIZED_REGION_INVENTORY_DATACENTER_FULL =
  "centralized_region_inventory_datacenter_full"
export const CENTRALIZED_REGION_INVENTORY_DATACENTER_INTERNAL =
  "centralized_region_inventory_datacenter_internal"
export const CENTRALIZED_REGION_INVENTORY_DIGITAL_TEAM_COMMUTE =
  "centralized_region_inventory_digital_team_commute"
export const CENTRALIZED_REGION_INVENTORY_EQUIPMENT_NETWORK =
  "centralized_region_inventory_equipment_network"
export const CENTRALIZED_REGION_INVENTORY_EQUIPMENT_WORKPLACE =
  "centralized_region_inventory_equipment_workplace"
export const CENTRALIZED_REGION_INVENTORY_EXTERNAL_APPS =
  "centralized_region_inventory_external_apps"
export const CENTRALIZED_REGION_INVENTORY_INTERNAL_APPS =
  "centralized_region_inventory_internal_apps"
export const CENTRALIZED_REGION_INVENTORY_SOCIAL =
  "centralized_region_inventory_social"
export const CENTRALIZED_COUNTRY_INVENTORY_WEBSITE =
  "centralized_country_inventory_website"
export const CENTRALIZED_COUNTRY_INVENTORY_DATACENTER_CLOUD =
  "centralized_country_inventory_datacenter_cloud"
export const CENTRALIZED_COUNTRY_INVENTORY_DATACENTER_EXTERNAL =
  "centralized_country_inventory_datacenter_external"
export const CENTRALIZED_COUNTRY_INVENTORY_DATACENTER_FULL =
  "centralized_country_inventory_datacenter_full"
export const CENTRALIZED_COUNTRY_INVENTORY_DATACENTER_INTERNAL =
  "centralized_country_inventory_datacenter_internal"
export const CENTRALIZED_COUNTRY_INVENTORY_DIGITAL_TEAM_COMMUTE =
  "centralized_country_inventory_digital_team_commute"
export const CENTRALIZED_COUNTRY_INVENTORY_EQUIPMENT_NETWORK =
  "centralized_country_inventory_equipment_network"
export const CENTRALIZED_COUNTRY_INVENTORY_EQUIPMENT_WORKPLACE =
  "centralized_country_inventory_equipment_workplace"
export const CENTRALIZED_COUNTRY_INVENTORY_EXTERNAL_APPS =
  "centralized_country_inventory_external_apps"
export const CENTRALIZED_COUNTRY_INVENTORY_INTERNAL_APPS =
  "centralized_country_inventory_internal_apps"
export const CENTRALIZED_COUNTRY_INVENTORY_SOCIAL =
  "centralized_country_inventory_social"
export const DECENTRALIZED_INVENTORY_WEBSITE = "decentralized_inventory_website"
export const DECENTRALIZED_INVENTORY_DATACENTER_CLOUD =
  "decentralized_inventory_datacenter_cloud"
export const DECENTRALIZED_INVENTORY_DATACENTER_FULL =
  "decentralized_inventory_datacenter_full"
export const DECENTRALIZED_INVENTORY_DIGITAL_TEAM_COMMUTE =
  "decentralized_inventory_digital_team_commute"
export const DECENTRALIZED_INVENTORY_EQUIPMENT_NETWORK =
  "decentralized_inventory_equipment_network"
export const DECENTRALIZED_INVENTORY_EQUIPMENT_WORKPLACE =
  "decentralized_inventory_equipment_workplace"
export const DECENTRALIZED_INVENTORY_EXTERNAL_APPS =
  "decentralized_inventory_external_apps"
export const DECENTRALIZED_INVENTORY_INTERNAL_APPS =
  "decentralized_inventory_internal_apps"
export const DECENTRALIZED_INVENTORY_SOCIAL = "decentralized_inventory_social"
export const DECENTRALIZED_INVENTORY_EXTERNAL_EQUIPMENT_WORKPLACE =
  "decentralized_inventory_external_equipment_workplace"
export const DECENTRALIZED_INVENTORY_EXTERNAL_DIGITAL_TEAM_COMMUTE =
  "decentralized_inventory_external_digital_team_commute"
