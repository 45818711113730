const FileDownloaderEntity = (backClient) => ({
  getDownloadFileUrl: async (inventoryType) => {
    const { data } = await backClient.get(
      `/api/uploads/collects/ratios/${inventoryType}/download`
    )
    return data
  },
})

export default FileDownloaderEntity
