import React from "react"
import { useEntity } from "hooks/useAxios"
import ScoreEntity from "services/entities/ScoreEntity"
import WebsitesCompareOrganism from "views/organisms/canvases/charts/compare/WebsitesCompareOrganism"
import ComparePageContentTemplate from "views/templates/ComparePageContentTemplate"

const WebsiteComparePage = () => {
  const scoreEntity = useEntity(ScoreEntity)

  return (
    <ComparePageContentTemplate
      compareRequester={scoreEntity.compareWebsites}
      CompareScoreComponent={WebsitesCompareOrganism}
    />
  )
}

export default WebsiteComparePage
