import { Link } from "react-router-dom"
import SdgIcon from "assets/images/sdg"
import React from "react"
import PropTypes from "prop-types"

import "stylesheets/blocs/molecules/objectives/SDGObjectiveMolecule.scss"
import classNames from "classnames/bind"
import { PILLARS } from "utils/constants/pillars"

const SDGObjectiveMolecule = ({
  url,
  selected,
  objectiveId,
  progressComponent,
  themeType,
}) => {
  return (
    <div className="SDGObjectiveMolecule">
      <Link to={url}>
        <button
          className={classNames(
            "SDGObjectiveMolecule__button",
            {
              "SDGObjectiveMolecule__button--selected": selected,
            },
            themeType
          )}
        >
          <SdgIcon id={objectiveId} />
          {progressComponent && progressComponent}
        </button>
      </Link>
    </div>
  )
}

SDGObjectiveMolecule.propTypes = {
  url: PropTypes.string.isRequired,

  selected: PropTypes.bool.isRequired,

  objectiveId: PropTypes.number.isRequired,

  progressComponent: PropTypes.element,

  themeType: PropTypes.oneOf(PILLARS).isRequired,
}

export default SDGObjectiveMolecule
