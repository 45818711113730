import { useTranslation } from "react-i18next"
import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import { Select, TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import { Module } from "../../Module"
import { WATER_CONSUMPTION_MODULE } from "utils/constants/collects/modules"
import { useFormConfig, useFormContext } from "../../FormContext"
import { useAppContext } from "components/AppContext"

const fields = [
  "water_consumption_period_consumption",
  "water_consumption_IT",
  "water_consumption_IT_DC",
  "water_consumption_IT_unit",
  "water_consumption_IT_DC_unit",
  "water_consumption_reuse_ratio",
]
const translationPath = "6.water_consumption"

const WaterConsumptionForm = () => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  const { envCollect } = useAppContext()

  useFormConfig({ fields, translationPath })

  const generateTableMarkUp = useCallback(
    (id) => {
      let id_unit = id + "_unit"
      let title = `collect:questions.${translationPath}.${id}_choice.label`
      const warning_id = id + "_warning"

      return (
        <FormLayout.Group title={t(title)}>
          <TextField
            id={id}
            type="number"
            warning={envCollect[warning_id] ? envCollect[warning_id] : null}
          />
          <Select
            id={id_unit}
            value={String(formData?.id_unit ?? "water_conso")}
            options={[
              {
                label: t(
                  `collect:questions.6.water_consumption.input_unit.water_conso`
                ),
                value: "water_conso",
              },
              {
                label: t(
                  `collect:questions.6.water_consumption.input_unit.percentage`
                ),
                value: "percentage",
              },
            ]}
          />
        </FormLayout.Group>
      )
    },
    [formData, envCollect]
  )

  return (
    <Form>
      <Module module={WATER_CONSUMPTION_MODULE}>
        <FormLayout>
          <TextField id="water_consumption_period_consumption" type="number" />
          {generateTableMarkUp("water_consumption_IT")}
          {generateTableMarkUp("water_consumption_IT_DC")}
          <TextField id="water_consumption_reuse_ratio" type="number" />
        </FormLayout>
      </Module>
    </Form>
  )
}

export default WaterConsumptionForm
