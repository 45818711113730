import React from "react"
import { DisplayText, Card, Layout } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import Question from "components/widgets/HelpPage/Question"

function Faq() {
  const { t } = useTranslation()
  // entries --> {[ ['title', 'foire aux questions'], ['registration', {...}], ['collect', {...}] ]}
  const entries = Object.entries(t("help:faq", { returnObjects: true }))

  let [faqContent, setFaqContent] = useState([])
  let [questionId, setQuestionId] = useState()

  useEffect(() => {
    //the faq component is re-rendered when the translation changes and when a new question is selected
    updateFaq()
  }, [t, questionId])

  function updateFaq() {
    //iterates through the entries and updates faqContent state
    setFaqContent([])
    for (let i = 1; i < entries.length; i++) {
      //we start the iteration at 1 because the first array of entries is the title of the FAQ and we don't need it
      const sectionLabel = entries[i][0] // --> 'registration'
      const questionsLabels = Object.keys(entries[i][1]) // --> ['section_title', 'personal_info', 'edit_info', ...]
      //SECTION TITLE
      setFaqContent((prevArray) => [
        ...prevArray,
        <Layout.Section key={i}>
          <DisplayText key={i} element="h2" size="medium">
            {t(`help:faq.${sectionLabel}.section_title`)}
          </DisplayText>
        </Layout.Section>,
      ])
      //QUESTIONS
      for (let n = 1; n < questionsLabels.length; n++) {
        //we start the iteration at 1 because the first element of the questionsLabel array is the title of the section and we don't need it
        const questionLabel = questionsLabels[n]
        const id = i + "-" + n
        setFaqContent((prevArray) => [
          ...prevArray,
          <Card.Section key={id}>
            <Question
              key={id}
              id={id}
              questionId={questionId}
              setQuestionId={setQuestionId}
              toggleAnswer={questionId == id ? true : false}
              sectionLabel={sectionLabel}
              questionLabel={questionLabel}
            />
          </Card.Section>,
        ])
      }
    }
  }

  return <div>{faqContent}</div>
}

export default Faq
