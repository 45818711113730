import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import CalculatorApp from "services/apps/calculatorApp"
import { convertEmissionValue } from "services/units/valueConversion"
import { T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const TextChartOdd13GhgEmissionTab = ({
  ghgEmissions,
  ghgEmissionsCollect,
  hidePercentageOfEmissions,
}) => {
  const { t } = useTranslation()

  const convertedValue = convertEmissionValue(ghgEmissions, T_CO2E, (value) =>
    round(value)
  )

  let content = {
    kpi: convertedValue.value,
    kpi_unit: NumberFormatterApp.getI18nUnit("emission", convertedValue.unit),
    kpi_text: t(`score:score.13.chartGHGEmissions.text`),
    translationPrefix_chartX: `score:score.13.chartGHGEmissions.`,
  }

  if (!hidePercentageOfEmissions) {
    content = {
      ...content,
      reference: CalculatorApp.getPercent(ghgEmissions, ghgEmissionsCollect, {
        round: 0,
      }),
      reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
      reference_unit: t(`score:score.13.chartGHGEmissions.unit`),
    }
  }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissions.help`)}
    >
      <TextChart
        content={<TextChartMajor content={content} noSubtextHover={true} />}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartOdd13GhgEmissionTab.propTypes = {
  ghgEmissions: PropTypes.string.isRequired,
  ghgEmissionsCollect: PropTypes.string.isRequired,
  node: PropTypes.object,
  hidePercentageOfEmissions: PropTypes.bool,
}

TextChartOdd13GhgEmissionTab.defaultProps = {
  hidePercentageOfEmissions: false,
}

export default TextChartOdd13GhgEmissionTab
