import React, { useCallback } from "react"
import PropTypes from "prop-types"
import "stylesheets/blocs/molecules/organization/charts/ActionsOrganizationChartMolecule.scss"
import { useHistoryContext } from "views/contexts/HistoryContext"
import _ from "lodash"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import {
  CORPORATE_NODE_FOR_PROVIDER_STRUCTURE,
  INTERNAL_NODE_STRUCTURE,
  INTERNAL_NODE_STRUCTURE_TYPE,
  LIGHT_CORPORATE_NODE_STRUCTURE_TYPE,
  NODE_ORGANIZATION_SHARE_TYPE,
  PARTNER_LEVEL_NODE_STRUCTURE_TYPE,
  PARTNER_NODE_STRUCTURE,
} from "utils/constants/forms/nodeStructure"
import NodeCreationOrganism from "views/organisms/interactive/NodeCreationOrganism"
import {
  FORM_FIELDS_TO_COMPONENTS,
  NODE_TYPE_TO_STANDARD_FORM_FIELDS,
  PROVIDER_CORPORATE_NODE_TYPE_TO_FORM_FIELDS,
} from "utils/constants/forms/nodeForms"
import NodeOrganizationShareOrganism from "views/organisms/interactive/NodeOrganizationShareOrganism"
import { TreeActionsAtom } from "views/molecules/organization/charts/TreeActionsAtom"
import {
  CONTEXT_TYPE_CORPORATE,
  CONTEXT_TYPE_EXTERNAL_PARTNER,
  CONTEXT_TYPE_PROVIDER,
} from "utils/constants/organization/contextTypes"
import { useAppContext } from "components/AppContext"
import useAdminRules from "hooks/useAdminRules"

const canEditANode = (branch) =>
  branch && (branch.can_edit || _.some(branch.children, canEditANode))

const hasANodeShared = (branch) =>
  branch && (branch.is_shared || _.some(branch.children, hasANodeShared))

export const TreeContextActionsMolecule = ({ organize }) => {
  const { organization: tree } = useChart()
  const { isCurrentPeriod } = useHistoryContext()
  const { currentOrganization, currentUser } = useAppContext()
  const { isRootNodeAdmin } = useAdminRules()

  const contextType = tree?.context_type ?? ""

  const actions = [
    {
      key: INTERNAL_NODE_STRUCTURE_TYPE,
      hidden: contextType !== CONTEXT_TYPE_CORPORATE,
      component: (
        <NodeCreationOrganism
          formFieldsToComponents={FORM_FIELDS_TO_COMPONENTS}
          nodeTypeToFormFields={NODE_TYPE_TO_STANDARD_FORM_FIELDS}
          nodeStructure={INTERNAL_NODE_STRUCTURE}
        />
      ),
    },
    {
      key: LIGHT_CORPORATE_NODE_STRUCTURE_TYPE,
      hidden: contextType !== CONTEXT_TYPE_PROVIDER,
      component: (
        <NodeCreationOrganism
          formFieldsToComponents={FORM_FIELDS_TO_COMPONENTS}
          nodeTypeToFormFields={PROVIDER_CORPORATE_NODE_TYPE_TO_FORM_FIELDS}
          nodeStructure={CORPORATE_NODE_FOR_PROVIDER_STRUCTURE}
        />
      ),
    },
    {
      key: PARTNER_LEVEL_NODE_STRUCTURE_TYPE,
      hidden: contextType === CONTEXT_TYPE_CORPORATE,
      component: (
        <NodeCreationOrganism
          formFieldsToComponents={FORM_FIELDS_TO_COMPONENTS}
          nodeTypeToFormFields={NODE_TYPE_TO_STANDARD_FORM_FIELDS}
          nodeStructure={PARTNER_NODE_STRUCTURE}
        />
      ),
    },
    {
      key: NODE_ORGANIZATION_SHARE_TYPE,
      hidden: contextType === CONTEXT_TYPE_EXTERNAL_PARTNER,
      component: <NodeOrganizationShareOrganism />,
    },
  ]

  const canAddNode = useCallback(() => {
    const canEditAnyTree = tree && organize && isCurrentPeriod

    const role_override_admin = currentUser.role_override === "admin"

    return (
      canEditAnyTree &&
      (canEditANode(tree) || (role_override_admin && hasANodeShared(tree)))
    )
  }, [currentOrganization, organize, isCurrentPeriod, tree])

  if (!canAddNode()) {
    return null
  }

  return <TreeActionsAtom actions={actions} />
}

TreeContextActionsMolecule.propTypes = {
  organize: PropTypes.bool.isRequired,
}

TreeContextActionsMolecule.defaultProps = {}
