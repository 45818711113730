import PropTypes from "prop-types"
import React from "react"
import ChartODD13EmissionByMonth from "views/molecules/canvases/charts/ChartODD13EmissionByMonth"
import ChartODD13TopGHGPerCountryMolecule from "views/molecules/canvases/charts/ChartODD13TopGHGPerCountryMolecule"
import ChartODD13GHGEmissionOverviewMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionOverviewMolecule"

const ChartODD13ConsolidateChart3 = ({ score }) => {
  if (score?.odd13_emissions_by_month_consolidated) {
    return <ChartODD13EmissionByMonth score={score} />
  }

  if (score.has_one_country_name) {
    return <ChartODD13GHGEmissionOverviewMolecule score={score} />
  }

  return <ChartODD13TopGHGPerCountryMolecule score={score} />
}

ChartODD13ConsolidateChart3.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13ConsolidateChart3
