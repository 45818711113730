import CountryApp from "services/apps/countryApp"

export const compareNodeLabels = (obj, sliceName = false, sliceFirstParent = false) => {
  let parents = obj?.parent_names?.map((parent, index) => {
    const version =
      index === obj.parent_names.length - 1 && obj?.parent_version_names?.[index]
        ? `(${obj.parent_version_names[index]})`
        : ""

    return version ? `${parent} ${version}` : parent
  }) || []

  let name = CountryApp.getNameI18n(obj.name)

  if (name.length > 10 && parents.length === 0 && !!obj.version_name) {
    name = name === obj.name ? name.slice(0, 10) + "..." : obj.name
  }

  if (parents.length > 0) {
    parents = parents.map((parent, index) => {
      if ((index !== 0 || sliceFirstParent) && parent.length > 10) {
        return parent.slice(0, 10) + "..."
      }

      return parent
    })
  }

  if (sliceName && name.length > 10) {
    name = name.slice(0, 10) + "..."
  }

  const versionName = obj.version_name ? ` (${obj.version_name})` : ""

  const breadcrumb = [...parents, name].join(" > ")

  return `${breadcrumb}${versionName}`
}
