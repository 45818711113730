import React from "react"
import { Tooltip } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { FolderDownMajor } from "@shopify/polaris-icons"

const DownloadFileNotAvailableButtonAtom = () => {
  const { t } = useTranslation()

  return (
    <Tooltip
      content={t(`collect:download.not_available`)}
      dismissOnMouseOut
      light
    >
      <div>
        <button disabled>
          <FolderDownMajor />
        </button>
      </div>
    </Tooltip>
  )
}

export default DownloadFileNotAvailableButtonAtom
