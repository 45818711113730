import React from "react"
import PropTypes from "prop-types"
import NoHistorizationAlertBanner from "views/molecules/banners/NoHistorizationAlertBanner"
import { ALERT_BANNER_NO_HISTORIZATION } from "utils/constants/alert_banners/alertBannersConstant"

const AnalyzeHistorizationAlertBanners = ({ type }) => {
  switch (type) {
    case ALERT_BANNER_NO_HISTORIZATION:
      return <NoHistorizationAlertBanner />
    default:
      throw new Error("[NoHistorizationAlertBanner] type not found")
  }
}

export default AnalyzeHistorizationAlertBanners

AnalyzeHistorizationAlertBanners.propTypes = {
  type: PropTypes.oneOf([ALERT_BANNER_NO_HISTORIZATION]),
}
