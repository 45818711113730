import {
  ERROR_TOAST_STATUSES,
  TOAST_STATUS_COLLECT_UPDATED,
  TOAST_STATUS_ERROR,
  TOAST_STATUS_FILE_PROCESSED,
  TOAST_STATUS_INACTIVE,
} from "utils/constants/toastStatuses"
import { Toast } from "@shopify/polaris"
import React from "react"
import { useCollectContext } from "./CollectContextProvider"
import { useTranslation } from "react-i18next"

const CollectToast = () => {
  const { t } = useTranslation()

  const { toastStatus, setToastStatus, error } = useCollectContext()

  if (
    toastStatus === TOAST_STATUS_INACTIVE ||
    toastStatus === TOAST_STATUS_COLLECT_UPDATED
  ) {
    return null
  }
  let content = t(`collect:questions.common.toast_status.${toastStatus}`)
  const isError = ERROR_TOAST_STATUSES.includes(toastStatus)

  if (toastStatus === TOAST_STATUS_ERROR && error) {
    const errorMessage = t(`collect:questions.common.errors.${error}`)
    content = `${content}: ${errorMessage}`
  }

  const toastParamsDefault = {
    content: content,
    duration: 2000,
    error: isError,
    onDismiss: () => {
      setToastStatus(TOAST_STATUS_INACTIVE)
    },
  }

  if (toastStatus === TOAST_STATUS_FILE_PROCESSED) {
    toastParamsDefault.duration = 5000
  }

  return (
    <Toast
      content={toastParamsDefault.content}
      duration={toastParamsDefault.default}
      error={toastParamsDefault.error}
      onDismiss={toastParamsDefault.onDismiss}
    />
  )
}

export default CollectToast
