import {
  Button,
  ChoiceList as PChoiceList,
  Form,
  FormLayout,
  Select as PSelect,
  TextField,
  Toast,
} from "@shopify/polaris"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import UserEntity from "../../../services/entities/UserEntity"
import { useEntity } from "hooks/useAxios"

const UserForm = () => {
  const { t } = useTranslation()
  const translationPrefix = "activation:user.questions."
  const { currentUser, setCurrentUser } = useAppContext()
  const [formData, setFormData] = useState({ email: currentUser.email })
  const [errors, setErrors] = useState({})
  const userEntity = useEntity(UserEntity)

  const [toastStatus, setToastStatus] = useState(0)

  useEffect(() => {
    if (currentUser == null) return

    setFormData({
      lastname: currentUser.lastname,
      firstname: currentUser.firstname,
      job_title: currentUser.job_title,
      job_scope: currentUser.job_scope,
      job_reach: currentUser.job_reach,
    })
  }, [currentUser])

  const submit = useCallback(() => {
    setErrors({})
    const data = {
      user: formData,
    }

    userEntity
      .update(data)
      .then((response) => {
        setCurrentUser(response.data)
        setToastStatus(1)
      })
      .catch(() => {
        setToastStatus(2)
      })
  }, [formData])

  const toastMarkup = useCallback(() => {
    switch (toastStatus) {
      case 1:
        return (
          <Toast
            content="Données sauvegardées"
            onDismiss={() => {
              setToastStatus(0)
            }}
          />
        )
      case 2:
        return (
          <Toast
            error
            content="Veuillez vérifier les données saisies"
            onDismiss={() => {
              setToastStatus(0)
            }}
          />
        )
      default:
        return null
    }
  }, [toastStatus])

  return (
    <Form onSubmit={submit}>
      <FormLayout>
        <TextField
          label={t(translationPrefix + "lastname")}
          value={String((formData && formData["lastname"]) ?? "")}
          onChange={(value) => {
            setFormData({ ...formData, lastname: value })
          }}
          error={errors.lastname}
        />
        <TextField
          label={t(translationPrefix + "firstname")}
          value={String((formData && formData["firstname"]) ?? "")}
          onChange={(value) => {
            setFormData({ ...formData, firstname: value })
          }}
          error={errors.firstname}
        />
        <PSelect
          label={t(translationPrefix + "job_title.label")}
          placeholder={t("collect:questions.common.default_select_placeholder")}
          value={String((formData && formData["job_title"]) ?? "")}
          onChange={(value) => {
            setFormData({ ...formData, job_title: value })
          }}
          options={[
            {
              label: t(translationPrefix + "job_title.options.executive"),
              value: "executive",
            },
            {
              label: t(translationPrefix + "job_title.options.cio"),
              value: "cio",
            },
            {
              label: t(translationPrefix + "job_title.options.csr"),
              value: "csr",
            },
            {
              label: t(translationPrefix + "job_title.options.cto"),
              value: "cto",
            },
            {
              label: t(translationPrefix + "job_title.options.other"),
              value: "other",
            },
          ]}
          error={errors.job_title}
        />
        <PSelect
          label={t(translationPrefix + "job_reach.label")}
          placeholder={t("collect:questions.common.default_select_placeholder")}
          value={String((formData && formData["job_reach"]) ?? "")}
          onChange={(value) => {
            setFormData({ ...formData, job_reach: value })
          }}
          options={[
            {
              label: t(translationPrefix + "job_reach.options.worldwide"),
              value: "worldwide",
            },
            {
              label: t(translationPrefix + "job_reach.options.region"),
              value: "region",
            },
            {
              label: t(translationPrefix + "job_reach.options.country"),
              value: "country",
            },
            {
              label: t(translationPrefix + "job_reach.options.site"),
              value: "site",
            },
          ]}
          error={errors.job_reach}
        />
        <PChoiceList
          title={t(translationPrefix + "job_scope.label")}
          choices={[
            {
              label: t(translationPrefix + "job_scope.group"),
              value: "group",
            },
            {
              label: t(translationPrefix + "job_scope.business"),
              value: "business",
            },
          ]}
          selected={String(formData.job_scope)}
          onChange={(value) => {
            setFormData({
              ...formData,
              job_scope: value == "group" ? "group" : "business",
            })
          }}
          error={errors.job_scope}
        />
        <Button submit primary>
          {t("common:save")}
        </Button>
        {toastMarkup()}
      </FormLayout>
    </Form>
  )
}

UserForm.propTypes = {}

export default UserForm
