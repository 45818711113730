import React from "react"
import "stylesheets/blocs/molecules/collectStartedIconMolecule/collectStartedIconMolecule.scss"
import PropTypes from "prop-types"
import {
  CapitalMajor,
  CapitalFilledMajor,
  CustomersMinor,
  CustomersFilledMinor,
  BillingStatementEuroMajor,
  BillingStatementEuroFilledMajor,
} from "@shopify/polaris-icons"
import IconChartAtom from "views/atoms/charts/IconChartAtom"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"

const CollectStartedIconsMolecule = ({
  hasEnvCollect,
  hasSocialCollect,
  hasProfitCollect,
  hasEnvScore,
  hasSocialScore,
  hasProfitScore,
  nodeId,
}) => {
  return (
    <div className="collect-icon">
      <IconChartAtom
        nodeId={nodeId}
        icon={hasEnvCollect ? CapitalFilledMajor : CapitalMajor}
        suffix={PILLAR_ENVIRONMENTAL}
        disabled={!hasEnvCollect}
        url={
          hasEnvScore
            ? "/score/environmental/13"
            : "/collect/environmental/13/workplace"
        }
      />
      <IconChartAtom
        nodeId={nodeId}
        icon={hasSocialCollect ? CustomersFilledMinor : CustomersMinor}
        suffix={PILLAR_SOCIAL}
        disabled={!hasSocialCollect}
        url={
          hasSocialScore ? "/score/social/3" : "/collect/social/3/well_being"
        }
      />
      <IconChartAtom
        nodeId={nodeId}
        icon={
          hasProfitCollect
            ? BillingStatementEuroFilledMajor
            : BillingStatementEuroMajor
        }
        suffix={PILLAR_PROFIT}
        disabled={!hasProfitCollect}
        url={
          hasProfitScore
            ? "/score/financial/8"
            : "/collect/financial/8/investments"
        }
      />
    </div>
  )
}

CollectStartedIconsMolecule.propTypes = {
  hasEnvCollect: PropTypes.bool.isRequired,
  hasSocialCollect: PropTypes.bool.isRequired,
  hasProfitCollect: PropTypes.bool.isRequired,
  hasEnvScore: PropTypes.bool,
  hasSocialScore: PropTypes.bool,
  hasProfitScore: PropTypes.bool,
  nodeId: PropTypes.number,
}

export default CollectStartedIconsMolecule
