import { useNodeContext } from "views/organisms/interactive/NodeContext"
import { useCallback, useEffect, useState } from "react"
import { getNodeTypePriority } from "utils/constants/nodes/nodeTypePriority"
import { CENTRALIZABLE_NODES } from "utils/constants/centralization/type"
import BaseCentralization from "utils/functions/centralization/baseCentralization"
import { isNodeLeaf } from "utils/constants/organization/nodeTypes"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const CENTRALIZED = "centralized"
const NOT_CENTRALIZED = "not_centralized"
const INHERITED = "inherited"

const useCentralizationForm = (disabled) => {
  const { t } = useTranslation()
  const { node, setNode } = useNodeContext()
  const [centralizationDisabled, setCentralizationDisabled] = useState(false)

  const centralizationAuthorized =
    CENTRALIZABLE_NODES.includes(node?.type) && !node.light

  const hasSnowRunCentralization = BaseCentralization.hasSnowRunCentralization(
    node?.centralization
  )

  useEffect(() => {
    setCentralizationDisabled(
      isNodeLeaf(node) || !centralizationAuthorized || disabled
    )
  }, [node, disabled])

  const getValue = useCallback(
    (type) => {
      const hasCentralization = node.centralization.find(
        (item) => item.centralization_type === type
      )

      if (!hasCentralization) return NOT_CENTRALIZED

      if (hasCentralization.inherited) return INHERITED

      return CENTRALIZED
    },
    [node, node.centralization]
  )

  const isDisabled = (type) => {
    return node.centralization.find((item) => item.centralization_type === type)
      ?.isDisabled
  }

  const getProvider = (type) => {
    const centralization = node.centralization.find(
      (item) => item.centralization_type === type
    )

    if (!centralization || !centralization.inherited) return null

    return centralization.provider
  }

  const generateRules = (centralizationTypes, isPillarFrozen) => {
    const returnNotCentralizedRules = !isNodeLeaf(node) && !isPillarFrozen

    const centralizationRulesObjects = centralizationTypes.map((type) => {
      const provider = getProvider(type)
      return {
        key: type,
        name: t(`activation:questions.centralization.options.${type}`),
        value: getValue(type),
        providerName: provider?.name,
        providerType: provider?.type,
        providerTypePosition: getNodeTypePriority(provider?.type),
        disabled: isDisabled(type),
      }
    })

    if (!returnNotCentralizedRules) {
      return orderCentralizationRules(
        centralizationRulesObjects.filter(
          (rule) => rule.value !== NOT_CENTRALIZED
        )
      )
    }

    return orderCentralizationRules(centralizationRulesObjects)
  }

  const orderCentralizationRules = (centralizationRules) =>
    _.orderBy(
      centralizationRules,
      ["value", "providerTypePosition", "name"],
      ["asc"]
    )

  const hasCentralizedRules = (centralizationRules) =>
    !_.every(centralizationRules, { value: NOT_CENTRALIZED })

  const updateNodeCentralization = (value, key) => {
    const existingCentralization = node.centralization.find(
      (item) => item.centralization_type === key
    )

    if (existingCentralization) {
      const index = node.centralization.indexOf(existingCentralization)
      node.centralization.splice(index, 1)
    }

    if (value) {
      node.centralization.push({
        centralization_type: key,
        centralized: value,
        inherited: false,
      })
    }

    setNode({
      ...node,
      centralization: [...node.centralization],
    })
  }

  const getGovernanceText = (value, providerName) => {
    if (disabled && value === NOT_CENTRALIZED) return ""

    switch (value) {
      case INHERITED:
        return providerName
      case NOT_CENTRALIZED:
        return ""
      default:
        return ""
    }
  }

  const showCheckbox = (value) => !disabled && value !== INHERITED

  const isChecked = (value) => value === CENTRALIZED

  return {
    generateRules,
    centralizationAuthorized,
    hasSnowRunCentralization,
    centralizationDisabled,
    orderCentralizationRules,
    hasCentralizedRules,
    updateNodeCentralization,
    getGovernanceText,
    showCheckbox,
    isChecked,
  }
}

export default useCentralizationForm
