import _ from "lodash"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  TOAST_STATUS_COLLECT_UPDATED,
  TOAST_STATUS_INVALID_COLLECT,
} from "utils/constants/toastStatuses"
import PropTypes from "prop-types"
import { useCollectContext } from "../CollectContextProvider"
import useDisablingFields from "components/widgets/CollectPage/useDisablingFields"

export const FormContext = createContext()

export function useFormContext() {
  return useContext(FormContext)
}

const FormContextProvider = ({ children }) => {
  const {
    collect,
    setToastStatus,
    setCollect,
    isCollectLocked,
    isChanged,
    setChanged,
    reloadProgress,
    entity,
  } = useCollectContext()

  const [isLoading, setLoading] = useState(false)

  const [formData, setFormData] = useState({})
  const [disabledFields, setDisabledFields] = useState({})
  const [errors, setErrors] = useState({})
  const [translationPath, setTranslationPath] = useState("")

  useEffect(() => {
    // if first load (=loaded_data == null) set setchanged to false so that no request is send to update collect
    if (formData.loaded_data == null) {
      setChanged(false)
    } else if (formData.loaded_data) {
      formData.loaded_data = false
      setChanged(false)
    } else {
      setChanged(true)
    }
  }, [formData])

  const loadFormData = useCallback(
    (fields) => {
      if (collect === null) return
      let data = {
        loaded_data: true,
      }
      fields.forEach((key) => _.set(data, key, _.get(collect, key)))
      setFormData(data)
    },
    [collect]
  )

  const saveMarkup = useCallback(() => {
    if (isLoading || isCollectLocked || !isChanged || _.size(formData) === 0)
      return

    setLoading(true)
    setErrors({})

    entity
      .put(formData)
      .then(({ data }) => {
        setCollect(data)
        setToastStatus(TOAST_STATUS_COLLECT_UPDATED)
        reloadProgress()
      })
      .catch((error) => {
        setErrors(error?.response?.data)
        setToastStatus(TOAST_STATUS_INVALID_COLLECT)
      })
      .finally(() => {
        setChanged(false)
        setLoading(false)
      })
  }, [formData, isCollectLocked, isChanged, isLoading])

  const onChange = (value, key) => {
    setFormData({ ...formData, [key]: value })
  }

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        loadFormData,
        errors,
        setErrors,
        disabledFields,
        setDisabledFields,
        saveMarkup,
        translationPath,
        setTranslationPath,
        onChange,
      }}
    >
      {saveMarkup()}
      {children}
    </FormContext.Provider>
  )
}

FormContextProvider.propTypes = {
  children: PropTypes.node,
}

export const useFormConfig = ({
  fields,
  translationPath,
  disableConfig = [],
  dependencies = [],
}) => {
  const { loadFormData, setTranslationPath } = useFormContext()
  useEffect(() => {
    loadFormData(fields)
    setTranslationPath(translationPath)
  }, dependencies)

  useDisablingFields(disableConfig)
}

export default FormContextProvider
