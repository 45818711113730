import Config from "assets/configs/score.js"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartBadge from "views/atoms/badges/ChartBadge"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { COLORS } from "utils/constants/colors"
import BarChartMolecule from "views/molecules/charts/BarChartMolecule"
import { DATALABEL_WITH_UNIT_FORMATER } from "services/charts/datalabels"

const color = (value) => {
  if (value < 33) return COLORS.danger.primary
  if (33 < value && value < 66) return COLORS.warning.primary
  return COLORS.planet.primary
}

const Objective12Score = ({ score, collect }) => {
  const { t } = useTranslation()
  const workplace_equipments = [
    "desktop",
    "laptop",
    "tablet",
    "smartphone",
    "monitor",
  ]
  const translationPrefix = "score:score.12."

  const chart1 = useCallback(() => {
    const eol_reuse_rate = score["odd12_sustainable_production_reuse_rate"]

    const content = {
      kpi: eol_reuse_rate,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const label_maturity = score["odd12_sustainable_consumption_label_maturity"]

    const badge = (
      <ChartBadge value={label_maturity} lowerLimit={30} upperLimit={70} />
    )

    const content = {
      reference: badge,
      kpi: label_maturity,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  // version zoom per equipment
  const chart3a = useCallback(() => {
    let datas = []
    let labels = [
      t(translationPrefix + "chart3.second_hand"),
      t(translationPrefix + "chart3.reuse"),
      t(translationPrefix + "chart3.internal_reconditioning"),
      t(translationPrefix + "chart3.external_reconditioning"),
    ]
    let dataset = []

    workplace_equipments.forEach((workplace_equipment) => {
      collect["collected_equipments"].forEach((equipment) => {
        if (workplace_equipment == equipment["equipment_type"]) {
          datas.push(Number(equipment["second_hand_ratio"]) ?? 0)
          datas.push(Number(equipment["internal_reuse_ratio"]) ?? 0)
          datas.push(
            Number(equipment["eol_internal_reconditioning_ratio"]) ?? 0
          )
          datas.push(
            Number(equipment["eol_external_reconditioning_ratio"]) ?? 0
          )

          let object = {
            label: t(`common:equipment_types.${workplace_equipment}`),
            data: { datas },
          }
          dataset.push(object)
          datas = []
        }
      })
    })

    let data = [
      {
        labels: labels,
        datasets: dataset,
      },
    ]

    return (
      <RadarChart title={t(translationPrefix + "chart3.title")} data={data} />
    )
  }, [score])

  // version zoom per end of life
  const chart3 = useCallback(() => {
    const labels = score.second_hand_vs_reuse.labels.map((label) =>
      t(`common:equipment_types.${label}`)
    )

    score.second_hand_vs_reuse.datasets.forEach((dataset) => {
      dataset.label = t(`score:score.12.chart3.${dataset.label_key}`)
    })

    const data = [{ labels, datasets: score.second_hand_vs_reuse.datasets }]

    const pointColor = (index, data) => {
      return data.map(color)
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart3.help")}
      >
        <RadarChart
          title={t(translationPrefix + "chart3.title")}
          data={data}
          pointColor={pointColor}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart4 = useCallback(() => {
    const colorEvaluation = Config.common.colorsEvaluation
    const colorByDataset = [
      {
        green: colorEvaluation.green,
        red: colorEvaluation.red,
        yellow: colorEvaluation.yellow,
      },
      {
        green: colorEvaluation.green2,
        red: colorEvaluation.red2,
        yellow: colorEvaluation.yellow2,
      },
    ]

    const config = Config["15"]["chart6"]
    const {
      datasets,
      labels,
      references,
      plain_data,
    } = score.odd12_collected_equipments
    const data = {
      labels: labels.map((labels) => {
        return `${t(`common:equipment_types.${labels[1]}`)} (${t(
          `common:domains.${labels[0]}`
        )})`
      }),
      datasets: datasets.map((dataset, datasetIndex) => {
        const references = score.odd12_collected_equipments.references
        const values = dataset.data.map((_, index) => {
          return datasets[0].data[index] + datasets[1].data[index]
        })

        const backgroundColors = values.map((value, index) => {
          const { green, red, yellow } = colorByDataset[
            datasetIndex % colorByDataset.length
          ]
          const reference = references[index]
          return value === reference ? yellow : value > reference ? green : red
        })

        return {
          data: dataset.data,
          label: t(translationPrefix + `${dataset.label}`),
          color: config["colors"][datasetIndex],
          backgroundColor: backgroundColors,
          borderColor: "#fff",
          borderWidth: {
            bottom: datasetIndex,
          },
          borderSkipped: "middle",
          stack: "1",
          order: 2,
        }
      }),
    }

    data.datasets.push({
      type: "line",
      label: t("score:score.common.datasets.market_data"),
      data: references,
      order: 1,
      fill: false,
      pointStyle: "line",
      pointRadius: 20,
      pointHoverRadius: 20,
      pointBorderWidth: 10,
      pointHoverBorderWidth: 10,
      pointBorderColor: "#333C37",
      showLine: false,
      borderColor: "#333C37",
      ticks: { min: 0 },
      hideDatalabelContent: true,
    })

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart4.help")}
      >
        <BarChartMolecule
          title={t(translationPrefix + "chart4.title")}
          data={data}
          dataTitle={t("score:score.common.datasets.client_data")}
          referenceTitle={t("score:score.common.datasets.market_data")}
          yLabel={t(translationPrefix + "chart4.data_title")}
          displayLegend
          plainData={plain_data}
          datalabelsFormatter={DATALABEL_WITH_UNIT_FORMATER}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart5 = useCallback(() => {
    const eolRecycle =
      score["odd15_electronic_waste_non_recycled_ratio"] !== null
        ? 100 - score["odd15_electronic_waste_non_recycled_ratio"]
        : t(`score:score.12.chart5.not_available`)
    const badge = (
      <ChartBadge value={eolRecycle} lowerLimit={80} upperLimit={95} />
    )

    const content = {
      reference: badge,
      kpi: eolRecycle,
      kpi_unit:
        eolRecycle !== "N/A" ? t(translationPrefix + "chart5.unit") : "",
      kpi_text: t(translationPrefix + "chart5.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const endOfLife = score.end_of_life

    const labels = endOfLife.labels.map((label) =>
      t(`common:equipment_types.${label}`)
    )

    endOfLife.datasets.forEach((dataset) => {
      dataset.label = t(`score:score.12.chart6.${dataset.label_key}`)
      dataset.backgroundColor =
        Config["12"].chart6[dataset.label_key].backgroundColor

      const color = Config["12"].chart6[dataset.label_key].borderColor
      dataset.borderColor = color
      dataset.pointBackgroundColor = Array(dataset.data.datas.length).fill(
        color
      )
    })

    const data = [{ labels, datasets: endOfLife.datasets }]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart6.help")}
      >
        <RadarChart title={t(translationPrefix + "chart6.title")} data={data} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd12_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective12Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective12Score
