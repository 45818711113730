import { Icon } from "@shopify/polaris"
import { QuestionMarkMinor } from "@shopify/polaris-icons"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useParams } from "react-router"
import "stylesheets/ScorePage/HelpWidget.scss"
import { PILLAR_ENVIRONMENTAL, PILLARS } from "utils/constants/pillars"

const TextToFormat = (text) =>
  text.split("\n").map((value, index) => {
    return (
      <span key={index}>
        {value}
        <br />
      </span>
    )
  })

const HelpWidget = ({ text, pillar }) => {
  const [isDisplayed, setDisplayed] = useState(false)
  const { pillarId } = useParams()

  return (
    <div className="Verdikt-Score-HelpWidget">
      <div
        className={classNames(pillarId || pillar, {
          "Verdikt-Score-HelpWidget-Modal": true,
          displayed: isDisplayed,
        })}
      >
        <div> {TextToFormat(text)} </div>
      </div>
      <button
        className={classNames("Verdikt-Score-HelpWidget-Button", pillarId)}
        onMouseEnter={() => setDisplayed(true)}
        onMouseLeave={() => setDisplayed(false)}
      >
        <Icon source={QuestionMarkMinor} />
      </button>
    </div>
  )
}

HelpWidget.propTypes = {
  text: PropTypes.string.isRequired,
  pillar: PropTypes.oneOf(PILLARS),
}

HelpWidget.defaultProps = {
  pillar: PILLAR_ENVIRONMENTAL,
}

export default HelpWidget
