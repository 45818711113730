import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import BarChart from "components/widgets/ScorePage/widgets/BarChart"
import { EMISSION_UNIT } from "utils/constants/numbers"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { Banner } from "@shopify/polaris"
import { safePercentage } from "utils/safeMath"

const Office365AnalysisOrganism = ({ score }) => {
  const { t } = useTranslation()

  const totalEmissionsValues = score?.o365_emissions?.total_emissions
  const percentOfTotalEmissions = Math.round(
    safePercentage(totalEmissionsValues.plain_number, score?.odd13_CO2)
  )
  const totalEmissions = {
    kpi: totalEmissionsValues?.number,
    kpi_unit: (
      <Unit
        unit={NumberFormatterApp.getI18nUnit(
          EMISSION_UNIT,
          totalEmissionsValues?.unit
        )}
      />
    ),
    kpi_text: t(
      "analyze:it_domain.consolidated.office365.totalEmissions.title"
    ),
    reference: percentOfTotalEmissions,
    reference_text: t(`analyze:websites.ghg_emissions.sub_text`),
    reference_unit: "%",
  }

  const totalEmissionsMajor = <TextChartMajor content={totalEmissions} />

  const topAppWithAssociatedEmissionsValues =
    score?.o365_emissions?.top_app_with_associated_emissions

  const topApp = {
    kpi: topAppWithAssociatedEmissionsValues?.name,
    kpi_text: t(
      "analyze:it_domain.consolidated.office365.topAppWithAssociatedEmissions.title"
    ),
  }
  const topAppVolume = {
    kpi_text: t(
      "analyze:it_domain.consolidated.office365.topAppWithAssociatedEmissions.subtitle"
    ),
    kpi: topAppWithAssociatedEmissionsValues?.total_emissions?.number,
    kpi_unit: (
      <Unit
        unit={NumberFormatterApp.getI18nUnit(
          EMISSION_UNIT,
          topAppWithAssociatedEmissionsValues?.total_emissions?.unit
        )}
      />
    ),
  }
  const topAppWithAssociatedEmissions = (
    <>
      <TextChartMinor content={topApp} />
      <TextChartMinor content={topAppVolume} />
    </>
  )

  const topRegionValue = score?.o365_emissions?.top_region
  const topRegion = {
    kpi: topRegionValue?.name,
    kpi_text: t(
      "analyze:it_domain.consolidated.office365.topRegionWithAssociatedEmissions.title"
    ),
  }
  const topRegionVolume = {
    kpi_text: t(
      "analyze:it_domain.consolidated.office365.topRegionWithAssociatedEmissions.subtitle"
    ),
    kpi: topRegionValue?.total_emissions?.number,
    kpi_unit: (
      <Unit
        unit={NumberFormatterApp.getI18nUnit(
          EMISSION_UNIT,
          topRegionValue?.total_emissions?.unit
        )}
      />
    ),
  }
  const topRegionWithAssociatedEmissions = (
    <>
      <TextChartMinor content={topRegion} />
      <TextChartMinor content={topRegionVolume} />
    </>
  )

  const splitPerTypeOfApps = score?.o365_emissions?.split_per_type_of_apps

  const splitPerScopes = useCallback(
    () =>
      score?.o365_emissions?.split_per_scopes?.doughnut_charts?.map((chart) => {
        return {
          data: chart.data,
          label: t(
            `analyze:it_domain.consolidated.office365.splitPerScopes.scopes.${chart.label}`
          ),
        }
      }) || [],
    [score]
  )

  const splitPerRegions = useCallback(
    () =>
      score?.o365_emissions?.split_per_regions?.doughnut_charts?.map(
        (chart) => {
          return {
            data: chart.data,
            label: t(`common:regions.${chart.label}`),
          }
        }
      ) || [],
    [score]
  )

  if (!totalEmissions.kpi) {
    return (
      <Banner
        title={t("improve:recommendations_table.empty_emissions.title")}
        status="warning"
      >
        <p>{t("improve:recommendations_table.empty_emissions.description")}</p>
      </Banner>
    )
  }

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_software_workplace_maturity}
      chart1={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.totalEmissions.help"
          )}
        >
          <TextChart content={totalEmissionsMajor} />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart2={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.topAppWithAssociatedEmissions.help"
          )}
        >
          <TextChart content={topAppWithAssociatedEmissions} />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart3={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.splitPerTypeOfApps.help"
          )}
        >
          <BarChart
            title={t(
              "analyze:it_domain.consolidated.office365.splitPerTypeOfApps.title"
            )}
            data={splitPerTypeOfApps?.charts}
            dataTitle={t(
              "analyze:it_domain.consolidated.office365.splitPerTypeOfApps.dataset"
            )}
            axesLabel={NumberFormatterApp.getI18nUnit(
              EMISSION_UNIT,
              splitPerTypeOfApps?.unit
            )}
            displayYAxe={true}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart4={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.splitPerRegions.help"
          )}
        >
          <DoughnutChart
            title={t(
              "analyze:it_domain.consolidated.office365.splitPerRegions.title"
            )}
            centerTextUnit={NumberFormatterApp.getI18nUnit(
              EMISSION_UNIT,
              score?.o365_emissions?.split_per_regions
                ?.doughnut_charts_data_total?.unit
            )}
            data={splitPerRegions()}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart5={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.topRegionWithAssociatedEmissions.help"
          )}
        >
          <TextChart content={topRegionWithAssociatedEmissions} />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart6={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            "analyze:it_domain.consolidated.office365.splitPerScopes.help"
          )}
        >
          <DoughnutChart
            title={t(
              "analyze:it_domain.consolidated.office365.splitPerScopes.title"
            )}
            centerTextUnit={NumberFormatterApp.getI18nUnit(
              EMISSION_UNIT,
              score?.o365_emissions?.split_per_scopes
                ?.doughnut_charts_data_total?.unit
            )}
            data={splitPerScopes()}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
    />
  )
}

Office365AnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Office365AnalysisOrganism
