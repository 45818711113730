import PropTypes from "prop-types"
import React from "react"
import { useAppContext } from "components/AppContext"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import {
  ENVIRONMENTAL_CELLS,
  PROFIT_CELLS,
  SOCIAL_CELLS,
} from "services/score/columns/score_columns"
import Column from "components/widgets/Dashboard/Column"

const Columns = ({ quantitative }) => {
  const { envScore, socialScore, profitScore } = useAppContext()

  return (
    <div className="Verdikt-Score-Columns">
      <ul>
        <li>
          <Column
            score={envScore}
            pillarId={PILLAR_ENVIRONMENTAL}
            cells={ENVIRONMENTAL_CELLS}
            quantitative={quantitative}
          />
        </li>
        <li>
          <Column
            score={socialScore}
            pillarId={PILLAR_SOCIAL}
            cells={SOCIAL_CELLS}
            quantitative={quantitative}
          />
        </li>
        <li>
          <Column
            score={profitScore}
            pillarId={PILLAR_PROFIT}
            cells={PROFIT_CELLS}
            quantitative={quantitative}
          />
        </li>
      </ul>
    </div>
  )
}

Columns.propTypes = {
  setShowObjectiveSwitch: PropTypes.func,
  quantitative: PropTypes.bool,
}

Columns.defaultProps = {
  quantitative: false,
}

export default Columns
