import PropTypes from "prop-types"
import React from "react"
import TextChartOdd13GhgEmissionTab from "views/molecules/canvases/charts/TextChartODD13GHGEmissionTab"
import { useTranslation } from "react-i18next"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { DEFAULT_OPTIONS } from "services/charts/default"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import { yAxes } from "services/charts/yAxes"
import Config from "assets/configs/score"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import ChartODD13EmissionByMonth from "views/molecules/canvases/charts/ChartODD13EmissionByMonth"
import { useAppContext } from "components/AppContext"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import { round } from "services/units/round"
import TextChartODD13EnergyMixMolecule from "views/molecules/canvases/charts/TextChartODD13EnergyMixMolecule"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import { HIDDEN_LEGEND } from "services/charts/legend"

const cutLabel = (label) => {
  return label.length > 10 ? `${label.slice(0, 10)}...` : label
}

const WorkplaceBrokerAnalysisOrganism = ({ score, isOnGlobalDashboard }) => {
  const { t } = useTranslation()
  const { currentNode } = useAppContext()

  const getTranslatedLabel = (groupingKey) => {
    return (labelKey) => {
      if (groupingKey !== "equipment_type") return cutLabel(labelKey)

      return t(`common:equipment_types.${labelKey}`)
    }
  }

  const chart3 = () => {
    const title = t("score:score.13.workplace_broker.split_ghg_emissions.title")
    const {
      label_keys,
      datasets,
      unit_key,
      grouping_key,
    } = score?.workplace_broker?.split_ghg_emissions_chart

    const unit = t(`units:units.emission.${unit_key}`)

    const colors = [
      Config.common.colors[0],
      Config.common.colorsEvaluation.yellow,
    ]

    const datasetsFormatted = datasets.map((dataset, index) => ({
      label: t(`score:score.common.lifecycle.${dataset.label_key}`),
      data: dataset.percentage,
      tooltipValues: dataset.data,
      datalabelValues: dataset.data,
      unit,
      backgroundColor: colors[index],
      stack: true,
    }))

    const data = {
      labels: label_keys.map(getTranslatedLabel(grouping_key)),
      datasets: datasetsFormatted,
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.13.workplace_broker.split_ghg_emissions.help`)}
      >
        <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={data}
              options={{
                ...DEFAULT_OPTIONS,
                scales: {
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "%",
                      },
                      ticks: {
                        beginAtZero: true,
                        max: 100,
                        min: 0,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart4 = () => {
    const title = t(
      `score:score.13.workplace_broker.ghg_emissions_per_brand.title`
    )
    const {
      label_keys,
      datasets,
      unit_key,
    } = score?.workplace_broker?.ghg_emissions_per_brand_chart

    const unit = t(`units:units.emission.${unit_key}`)

    const datasetsFormatted = datasets.map((dataset, index) => ({
      label: cutLabel(dataset.label_key),
      data: dataset.data,
      unit,
      backgroundColor: Config.common.colors[index],
      stack: true,
      showLabelInData: true,
    }))

    const data = {
      labels: label_keys.map(getTranslatedLabel("equipment_type")),
      datasets: datasetsFormatted,
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`score:score.13.workplace_broker.ghg_emissions_per_brand.help`)}
      >
        <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={data}
              options={{
                ...DEFAULT_OPTIONS,
                ...yAxes(unit),
                legend: HIDDEN_LEGEND,
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const chart6 = () => {
    const title = t(
      `score:score.13.workplace_broker.ghg_emissions_brand_per_model.title`
    )
    const {
      label_keys,
      datasets,
      unit_key,
    } = score?.workplace_broker?.ghg_emissions_brand_per_model_chart

    const unit = t(`units:units.emission.${unit_key}`)

    const datasetsFormatted = datasets.map((dataset, index) => ({
      label: cutLabel(dataset.label_key),
      data: dataset.data,
      unit,
      backgroundColor:
        Config.common.colors[index % Config.common.colors.length],
      stack: true,
      showLabelInData: true,
    }))

    const data = {
      labels: label_keys,
      datasets: datasetsFormatted,
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(
          `score:score.13.workplace_broker.ghg_emissions_brand_per_model.help`
        )}
      >
        <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={data}
              options={{
                ...DEFAULT_OPTIONS,
                ...yAxes(unit),
                legend: HIDDEN_LEGEND,
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const kpi3 = () => {
    const {
      value,
      unit_key,
    } = score?.workplace_broker?.average_emissions_per_equipment_kpi
    const content = {
      kpi: value,
      kpi_unit: t(`units:units.emission.${unit_key}`),
      kpi_text: t(
        "score:score.13.workplace_broker.average_emissions_per_equipment.title"
      ),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(`analyze:score.13.workplace_broker.average_energy_mix.help`)}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  const kpi3Consolidated = () => {
    const {
      value,
      unit_key,
      status,
    } = score?.workplace_broker?.average_energy_mix_kpi

    return (
      <TextChartODD13EnergyMixMolecule
        title={t("score:score.13.workplace_broker.average_energy_mix.title")}
        energyMixStatus={status}
        energyMixValue={round(value, 1)}
      />
    )
  }

  const kpi2Consolidated = () => {
    const {
      value,
      unit_key,
    } = score?.workplace_broker?.average_ghg_emissions_equipment_kpi
    const content = {
      kpi: value,
      kpi_unit: t(`units:units.emission.${unit_key}`),
      kpi_text: t(
        "score:score.13.workplace_broker.average_ghg_emissions_equipment.title"
      ),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(
          `analyze:score.13.workplace_broker.average_ghg_emissions_equipment.help`
        )}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_workplace_maturity ?? 0}
      chart1={
        currentNode?.has_children ? (
          <TextChartODD13GHGEmissionsMolecule
            score={score}
            ghgEmissions={score?.odd13_CO2}
            hidePercentage={isOnGlobalDashboard}
          />
        ) : (
          <TextChartOdd13GhgEmissionTab
            ghgEmissions={score?.odd13_workplace_CO2}
            ghgEmissionsCollect={score?.odd13_CO2}
            node={score?.node}
            hidePercentageOfEmissions={isOnGlobalDashboard}
          />
        )
      }
      chart2={
        currentNode?.has_children ? (
          kpi2Consolidated()
        ) : (
          <TextChartODD13EnergyMixMolecule
            energyMixStatus={score?.odd13_energy_mix_status}
            energyMixValue={Number(score?.odd13_energy_mix_value)}
            country={
              score?.node?.type === NODE_TYPE_SITE
                ? score?.node?.parent?.name
                : score?.node?.name
            }
          />
        )
      }
      chart3={chart3()}
      chart4={chart4()}
      chart5={currentNode?.has_children ? kpi3Consolidated() : kpi3()}
      chart6={
        score.odd13_emissions_by_month_consolidated ? (
          <ChartODD13EmissionByMonth score={score} />
        ) : (
          chart6()
        )
      }
    />
  )
}

WorkplaceBrokerAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  isOnGlobalDashboard: PropTypes.bool,
}

WorkplaceBrokerAnalysisOrganism.defaultProps = {
  isOnGlobalDashboard: false,
}

export default WorkplaceBrokerAnalysisOrganism
