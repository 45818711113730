import Objective13SoftwareForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13SoftwareForm"
import Objective13DataExternalForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13DataExternalForm"
import Objective13DataInternalForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13DataInternalForm"
import Objective13WorkplaceForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13WorkplaceForm"
import Objective13NetworkForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13NetworkForm"
import Objective13ITTeamForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13ITTeamForm"
import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import Objective13BusinessAssetsForm from "components/widgets/CollectPage/forms/pillars/environment/Objective13BusinessAssetsForm"

const Objective13Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "workplace":
        return <Objective13WorkplaceForm />
      case "datacenter_internal":
        return <Objective13DataInternalForm />
      case "datacenter_external":
        return <Objective13DataExternalForm />
      case "network":
        return <Objective13NetworkForm />
      case "software":
        return <Objective13SoftwareForm />
      case "it_team":
        return <Objective13ITTeamForm />
      case "business_assets":
        return <Objective13BusinessAssetsForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default:
        return null
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective13Form
