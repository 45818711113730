import React, { useState, useEffect } from "react"
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  Layout,
  Link,
  FooterHelp,
  Form,
  FormLayout,
  Page,
  TextField,
} from "@shopify/polaris"
import Axios from "axios"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"

const Contact = () => {
  const { t } = useTranslation()
  const { currentUser } = useAppContext()
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [smartphone, setSmartphone] = useState("")
  const [message, setMessage] = useState("")

  const [bannerMarkup, setBannerMarkup] = useState(null)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  useEffect(() => {
    if (currentUser == null) return
    setLastName(currentUser.lastname ? currentUser.lastname : "")
    setEmail(currentUser.email)
  }, [currentUser])

  function resetForm() {
    setLastName("")
    setEmail("")
    setSmartphone("")
    setMessage("")
    setBannerMarkup(
      <Banner
        status="info"
        onDismiss={() => {
          setBannerMarkup(null)
        }}
      >
        {t("common:contact.deleted")}
      </Banner>
    )
    window.scrollTo(0, 0)
  }

  function sendMessage() {
    setIsSubmitDisabled(true)
    const data = {
      message: {
        lastname: lastName,
        email: email,
        smartphone: smartphone,
        message: message,
      },
    }
    Axios.post("/api/contact/send-message", data).then((response) => {
      setIsSubmitDisabled(false)
      window.scrollTo(0, 0)
      if (response.status == 204) {
        resetForm()
        setBannerMarkup(
          <Banner status="success">{t("common:contact.sent")}</Banner>
        )
      } else {
        setBannerMarkup(
          <Banner status="critical">{t("common:contact.sent")}</Banner>
        )
      }
    })
  }

  return (
    <Page singleColumn title={t("common:contact.title")}>
      <Layout>
        <Layout.Section>{bannerMarkup}</Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section>
              <Form onSubmit={sendMessage}>
                <FormLayout>
                  <TextField
                    label={t("common:contact.name")}
                    value={lastName}
                    onChange={setLastName}
                  />
                  <TextField
                    label={t("common:contact.email")}
                    type="email"
                    value={email}
                    onChange={setEmail}
                  />
                  <TextField
                    label={t("common:contact.phone")}
                    type="tel"
                    value={smartphone}
                    onChange={setSmartphone}
                  />
                  <TextField
                    label={t("common:contact.message")}
                    multiline={5}
                    value={message}
                    onChange={setMessage}
                  />
                  <ButtonGroup>
                    <Button submit primary disabled={isSubmitDisabled}>
                      {t("common:contact.send")}
                    </Button>
                    <Button plain onClick={resetForm}>
                      {t("common:contact.delete")}
                    </Button>
                  </ButtonGroup>
                </FormLayout>
              </Form>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <FooterHelp>
        {t("common:contact.help")}
        <Link url="/privacy">{t("common:contact.privacy")}</Link>
      </FooterHelp>
    </Page>
  )
}

export default Contact
