import React, { useEffect } from "react"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import { Card, Page } from "@shopify/polaris"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useAppContext } from "components/AppContext"
import usePillars from "views/organisms/pillars/usePillars"
import CompareTemplate from "views/templates/CompareTemplate"
import useComparedNodeStorage from "hooks/useComparedNodeStorage"
import useCompare from "hooks/useCompare"
import Error from "components/widgets/ScorePage/Error"
import Loading from "components/widgets/Loading"
import PropTypes from "prop-types"

const ComparePageContentTemplate = ({
  compareRequester,
  CompareScoreComponent,
}) => {
  const { parentOrganization, currentNode } = useAppContext()
  const {
    nodes: storedNodes,
    setStoredNodes,
    isStoredNodesInitialized,
  } = useComparedNodeStorage(currentNode?.id)

  const pillars = usePillars({
    pageName: "/compare",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  const {
    isComparedScoresLoading,
    isComparedScoresError,
    comparedScores,
    comparedNodes,
    search,
    onDelete,
  } = useCompare(storedNodes, isStoredNodesInitialized, compareRequester)

  useEffect(() => setStoredNodes(comparedNodes), [comparedNodes])
  const display = () => {
    if (isComparedScoresError) return <Error />

    if (isComparedScoresLoading)
      return (
        <Card>
          <Card.Section>
            <Loading />
          </Card.Section>
        </Card>
      )

    if (comparedScores)
      return (
        <CompareScoreComponent
          comparedScores={comparedScores}
          comparedNodes={comparedNodes}
        />
      )

    return null
  }

  if (!isStoredNodesInitialized) return <Loading />

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth>
        <CompareTemplate
          parentOrganization={parentOrganization || []}
          themeType={PILLAR_ENVIRONMENTAL}
          pillars={pillars}
          search={search}
          onDelete={onDelete}
          comparedNodes={comparedNodes}
          storedNodes={storedNodes}
          setStoredNodes={setStoredNodes}
        >
          {display()}
        </CompareTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

ComparePageContentTemplate.propTypes = {
  compareRequester: PropTypes.func.isRequired,
  CompareScoreComponent: PropTypes.elementType.isRequired,
}

export default ComparePageContentTemplate
