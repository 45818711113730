import React from "react"
import ResponsibleConsumptionTableHeaderMolecule from "views/molecules/collects/objectives/12/responsibleConsumption/ResponsibleConsumptionTableHeaderMolecule"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import TitleFormCollect from "views/atoms/forms/collects/title/TitleFormCollect"
import "stylesheets/blocs/molecules/table/ResponsibleConsumption.scss"
import { DOMAIN_TO_MODULE } from "utils/constants/collects/modules"
import { Module } from "components/widgets/CollectPage/forms/Module"
import ResponsibleConsumptionTableBodyLines from "./ResponsibleConsumptionTableBodyLines"

const ResponsibleConsumptionTableMolecule = ({ equipmentTypesAvailable }) => {
  const { t } = useTranslation()

  return equipmentTypesAvailable.map((domain, index) => (
    <Module module={DOMAIN_TO_MODULE[domain.name]} key={index}>
      <TitleFormCollect
        text={t(
          `collect:questions.12.sustainable_consumption.table_1.${domain.name}.title`
        )}
      />
      <table id="table1" className="table__responsible-consumption">
        <ResponsibleConsumptionTableHeaderMolecule domain={domain.name} />
        <tbody>
          <ResponsibleConsumptionTableBodyLines
            equipmentTypes={domain.equipment_types}
            domain={domain.name}
          />
        </tbody>
      </table>
    </Module>
  ))
}

export default ResponsibleConsumptionTableMolecule

ResponsibleConsumptionTableMolecule.propTypes = {
  equipmentTypesAvailable: PropTypes.array.isRequired,
}
