import React from "react"
import { IMPORT_STATUS_WAITING_FOR_FILE } from "utils/constants/imports/importStatus"
import { upload } from "utils/functions/upload/upload"
import { useAppContext } from "components/AppContext"
import { useEntity } from "hooks/useAxios"
import CloudUploadEntity from "services/entities/CloudUploadEntity"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"
import StatusUploadOrganism from "views/organisms/upload/StatusUploadOrganism"
import { useTranslation } from "react-i18next"
import ActiveStorageAttachmentEntity from "services/entities/ActiveStorageAttachmentEntity"
import { downloadFile } from "services/download/download"
import { useModuleContext } from "components/widgets/CollectPage/forms/Module"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"

const CloudProviderDetailedInventories = () => {
  const { moduleIsActive } = useModuleContext()
  const { setToastStatus, currentNode } = useAppContext()
  const { setCollect, collect, entity } = useCollectContext()
  const { t } = useTranslation()
  const cloudUploadEntity = useEntity(CloudUploadEntity)
  const activeStorageAttachmentsEntity = useEntity(
    ActiveStorageAttachmentEntity
  )
  const { isLocked } = useFieldIsLocked()

  const filesStatuses = collect.cloud_provider_exports

  const refresh = async () => {
    const { data } = await entity.show()
    setCollect({ ...data })
  }

  const handleDrop = (type) => {
    return async (files, acceptedFiles) => {
      if (acceptedFiles.length < 1) {
        setToastStatus(6)
        return
      }

      const result = await upload(
        acceptedFiles[0],
        currentNode,
        collect,
        type,
        cloudUploadEntity.upload
      )

      setCollect(result)
    }
  }

  const getStatus = (fileKey) => {
    return () => {
      if (filesStatuses[fileKey]) {
        return filesStatuses[fileKey]
      } else {
        return { processing_status: IMPORT_STATUS_WAITING_FOR_FILE }
      }
    }
  }

  const onDownloadFile = async (activeStorageAttachmentId) => {
    const { data: url } = await activeStorageAttachmentsEntity.show(
      activeStorageAttachmentId
    )

    downloadFile(url)
  }

  const handleAwsDrop = handleDrop("aws")
  const getAwsStatus = getStatus("aws_ccf_billing_exports")

  const handleGcpDrop = handleDrop("gcp")
  const getGcpStatus = getStatus("gcp_ccf_billing_exports")

  const handleAzureDrop = handleDrop("azure")
  const getAzureStatus = getStatus("azure_ccf_billing_exports")

  const providerToConfig = [
    {
      handleDrop: handleAwsDrop,
      getStatus: getAwsStatus,
      show: collect.cloud_inventory_aws,
      wantToUploadFile: collect.cloud_inventory_aws_choice === "billing_export",
      headerTitle: t(
        "collect:questions.13.datacenter_external.cloud.providers.aws"
      ),
    },
    {
      handleDrop: handleGcpDrop,
      getStatus: getGcpStatus,
      show: collect.cloud_inventory_gcp,
      wantToUploadFile: collect.cloud_inventory_gcp_choice === "billing_export",
      headerTitle: t(
        "collect:questions.13.datacenter_external.cloud.providers.gcp"
      ),
    },
    {
      handleDrop: handleAzureDrop,
      getStatus: getAzureStatus,
      show: collect.cloud_inventory_azure,
      wantToUploadFile:
        collect.cloud_inventory_azure_choice === "billing_export",
      headerTitle: t(
        "collect:questions.13.datacenter_external.cloud.providers.azure"
      ),
    },
  ]

  return (
    <>
      <table className="Verdikt-DynamicTable">
        <thead>
          <tr>
            {providerToConfig.map((config, index) => {
              if (config.show) {
                return <th key={index}>{config.headerTitle}</th>
              }
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {providerToConfig.map((config, index) => {
              if (config.show) {
                if (config.wantToUploadFile) {
                  return (
                    <td key={index}>
                      <StatusUploadOrganism
                        accept={""}
                        onDrop={config.handleDrop}
                        inventoryStatus={config.getStatus()}
                        setInventoryStatus={() => {}}
                        onRefreshStatus={refresh}
                        onDownloadFile={onDownloadFile}
                        disabled={
                          !!collect.closed_at || !moduleIsActive || isLocked
                        }
                      />
                    </td>
                  )
                }
              }
            })}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default CloudProviderDetailedInventories
