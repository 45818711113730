import _ from "lodash"

class References {
  /**
   * @param reference The value to recursively clone.
   * @return {*}
   */
  static cloneDeep(reference) {
    return _.cloneDeep(reference)
  }
}

export default References
