export default class CalculatorApp {
  /**
   * @param valuePartial number
   * @param valueTotal number
   * @param options
   * @returns {string|number}
   */
  static getPercent(valuePartial, valueTotal, options = { round: 1 }) {
    if (valueTotal === 0) {
      return 0
    }

    return ((valuePartial * 100) / valueTotal).toFixed(options.round)
  }
}
