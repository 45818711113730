/**
 * @description Sorts the data array in descending order depending on the value of data.data
 * @param {array of objects} data [{label: <string>, data: <number>}, ...] Array of objects used to display the <Bar> chart component
 * @returns Sorted array
 */
export const sortData = (data) => {
  return data.sort((a, b) => {
    return b.data - a.data
  })
}

/**
 * @description Removes empty data in the array
 * @param {array of objects} data [{label: <string>, data: <number>}, ...] Array of objects used to display the <Bar> chart component
 * @returns Filtered array
 */
export const removeEmptyData = (data) => {
  return data.filter((data) => data.data !== 0)
}
