import React from "react"
import { Stack } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import CreateReportButtonReportAtom from "views/atoms/reports/table/buttons/CreateReportButtonReportAtom"

const AddButtonEmptyTableReportMolecule = () => {
  const { t } = useTranslation()

  return (
    <tr key={"0"}>
      <td colSpan="8">
        <Stack distribution="center" alignment="center" spacing="loose">
          <p>{t("share:content.reports_table.empty")}</p>
          <CreateReportButtonReportAtom />
        </Stack>
      </td>
    </tr>
  )
}

AddButtonEmptyTableReportMolecule.propTypes = {}

export default AddButtonEmptyTableReportMolecule
