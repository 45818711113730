import { useModuleContext } from "../Module"
import { useEffect, useState } from "react"
import { useCollectContext } from "../../CollectContextProvider"

const useFieldIsLocked = () => {
  const [isLocked, setIsLocked] = useState(false)

  const { isCollectLocked } = useCollectContext()
  const { moduleIsActive } = useModuleContext()

  useEffect(() => {
    setIsLocked(isCollectLocked || !moduleIsActive)
  }, [isCollectLocked, moduleIsActive])

  return {
    isLocked,
  }
}

export default useFieldIsLocked
