import React, { useContext, useState } from "react"
import {
  Banner,
  Button,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris"
import { HistoryContext } from "views/contexts/HistoryContext"
import { useAppContext } from "components/AppContext"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const NoPeriodNameBanner = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [periodName, setPeriodName] = useState("")
  const { t } = useTranslation()

  const historyContext = useContext(HistoryContext)
  const { reloadCurrentPeriod, subscription } = useAppContext()

  const handleSubmit = async () => {
    setIsLoading(true)

    historyContext.reload()
    reloadCurrentPeriod()
    onSuccess()

    setIsLoading(false)
  }

  const translationPrefix = subscription?.is_provider
    ? "organization:period_name_form.partner"
    : "organization:period_name_form.corporate"

  return (
    <Layout.Section>
      <Banner status="info" title={t(`${translationPrefix}.title`)}>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              value={periodName}
              onChange={setPeriodName}
              label={t(`${translationPrefix}.fields.period_name`)}
              name="periodName"
              type="text"
              disabled={isLoading}
            />

            <Button
              submit
              disabled={periodName.length === 0}
              loading={isLoading}
            >
              {t(`${translationPrefix}.fields.submit`)}
            </Button>
          </FormLayout>
        </Form>
      </Banner>
    </Layout.Section>
  )
}

NoPeriodNameBanner.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default NoPeriodNameBanner
