import PropTypes from "prop-types"
import React from "react"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"

/**
 * @param mixedChart odd13_co2_equipment_emissions_top
 * @returns {JSX.Element}
 * @constructor
 */
const ChartODD13EquipmentFteTopEmissionsLocMolecule = ({ mixedChart = [] }) => {
  const { t } = useTranslation()
  const translationPrefix = "analyze:it_domain.consolidated.workplace."

  const labels = mixedChart.map((node) =>
    compareNodeLabels(node.label, true, true)
  )
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const bar_data = {
    label: t(translationPrefix + "chartEquipmentFteTopEmissionsLoc.legend.bar"),
    data: bar
  }

  const line_data = {
    label: t(
      translationPrefix + "chartEquipmentFteTopEmissionsLoc.legend.line"
    ),
    data: line
  }

  const data = { bar_data, line_data }

  return (
    <MixedChart
      title={t(translationPrefix + "chartEquipmentFteTopEmissionsLoc.title")}
      data_inputs={data}
      labels={labels}
      unit_left_axis={t(
        translationPrefix + "chartEquipmentFteTopEmissionsLoc.unit_left_axis"
      )}
      unit_right_axis={t(`units:units.emission.${mixedChart[0].bar_unit}`)}
    />
  )
}

ChartODD13EquipmentFteTopEmissionsLocMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired
}

export default ChartODD13EquipmentFteTopEmissionsLocMolecule
