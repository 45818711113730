import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const AlreadyGeneratedBannerReportAtom = () => {
  const { t } = useTranslation()

  return (
    <Banner
      title={t("share:content.banners.already_generated_report.title")}
      status="success"
    >
      <p>{t("share:content.banners.already_generated_report.body")}</p>
    </Banner>
  )
}

export default AlreadyGeneratedBannerReportAtom
