import React from "react"
import ServiceNotAvailableBannerReportAtom from "views/atoms/reports/banners/ServiceNotAvailableBannerReportAtom"
import WorkInProgressBannerReportAtom from "views/atoms/reports/banners/WorkInProgressBannerReportAtom"
import AlreadyGeneratedBannerReportAtom from "views/atoms/reports/banners/AlreadyGeneratedBannerReportAtom"
import AlreadyExistingBannerReportAtom from "views/atoms/reports/banners/AlreadyExistingBannerReportAtom"
import NoCurrentScopeBannerReportAtom from "views/atoms/reports/banners/NoCurrentScopeBannerReportAtom"
import { useReportPageContext } from "views/contexts/ReportPageContext"

const BannerDispatcherReportMolecule = () => {
  const { reportRequestResponseBanner, workInProgress } = useReportPageContext()

  if (reportRequestResponseBanner.service_not_available) {
    return <ServiceNotAvailableBannerReportAtom />
  } else if (reportRequestResponseBanner.work_in_progress || workInProgress) {
    return <WorkInProgressBannerReportAtom />
  } else if (reportRequestResponseBanner.already_generated) {
    return <AlreadyGeneratedBannerReportAtom />
  } else if (reportRequestResponseBanner.already_existing) {
    return <AlreadyExistingBannerReportAtom />
  } else if (reportRequestResponseBanner.no_current_scope) {
    return <NoCurrentScopeBannerReportAtom />
  } else {
    return ""
  }
}

export default BannerDispatcherReportMolecule
