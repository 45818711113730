import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import PrintingForm from "./Objective15PrintingForm"
import WasteForm from "./Objective15WasteForm"

const Objective15Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "printing":
        return <PrintingForm />
      case "electronic_waste":
        return <WasteForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default:
        return <p>Aucun formulaire pour ce thème.</p>
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective15Form
