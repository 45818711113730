import React from "react"
import { Banner } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const ForgottenPasswordMessageMolecule = ({
  email,
  isResetPasswordBannerDisplayed,
}) => {
  const { t } = useTranslation()

  return (
    isResetPasswordBannerDisplayed && (
      <Banner status="success">
        {t("common:login.instructions")} {email}.
      </Banner>
    )
  )
}

ForgottenPasswordMessageMolecule.propTypes = {
  email: PropTypes.string,
  isResetPasswordBannerDisplayed: PropTypes.bool.isRequired,
}

ForgottenPasswordMessageMolecule.defaultProps = {
  email: "",
}

export default ForgottenPasswordMessageMolecule
