import React from "react"
import { FormLayout } from "@shopify/polaris"
import Form from "components/widgets/CollectPage/forms/Form"
import { useParams } from "react-router-dom"
import {
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import {
  POSITIVE_IMPACT_MODULE,
  POSITIVE_IMPACT_MODULES,
} from "utils/constants/collects/modules"
import { Module } from "../Module"
import { useFormConfig } from "../FormContext"

const PositiveImpactForm = () => {
  const { objectiveId } = useParams()

  useFormConfig({
    fields: [
      `odd${objectiveId}_positive_impact_contribution`,
      `odd${objectiveId}_positive_impact_project_description`,
      `odd${objectiveId}_positive_impact_KPI_description`,
      `odd${objectiveId}_positive_impact_KPI_value`,
    ],
    translationPath: `${objectiveId}.positive_impact`,
    dependencies: [objectiveId],
    disableConfig: [
      {
        disablingField: `odd${objectiveId}_positive_impact_contribution`,
        fieldsToDisable: [
          `odd${objectiveId}_positive_impact_project_description`,
          `odd${objectiveId}_positive_impact_KPI_description`,
          `odd${objectiveId}_positive_impact_KPI_value`,
        ],
      },
    ],
  })

  const module = POSITIVE_IMPACT_MODULES.includes(
    `odd${objectiveId}_positive_impact`
  )
    ? `odd${objectiveId}_positive_impact`
    : POSITIVE_IMPACT_MODULE

  return (
    <Form>
      <Module module={module}>
        <FormLayout>
          <YesNoRadio id={`odd${objectiveId}_positive_impact_contribution`} />
          <TextField
            id={`odd${objectiveId}_positive_impact_project_description`}
            type="text"
            multiline={4}
          />
          <TextField
            id={`odd${objectiveId}_positive_impact_KPI_description`}
            type="text"
          />
          <TextField
            id={`odd${objectiveId}_positive_impact_KPI_value`}
            type="text"
          />
        </FormLayout>
      </Module>
    </Form>
  )
}

export default PositiveImpactForm
