import PropTypes from "prop-types"
import React from "react"
import { Redirect, useParams } from "react-router-dom"
import ObjectiveTabs from "components/widgets/ObjectiveTabs"
import "stylesheets/CollectPage.scss"
import {
  PILLAR_ENVIRONMENTAL,
  PILLARS_OBJECTIVES,
} from "utils/constants/pillars"
import { useCollectContext } from "./CollectContextProvider"

export const CHANGE_TO_PILLAR = "pillar"
export const CHANGE_TO_OBJECTIVE = "objective"

const ObjectivesFrame = ({ pageName, children, changeToDefault }) => {
  const { progress, isCollectLocked } =
    pageName === "collect" ? useCollectContext() : {}

  const { pillarId, objectiveId } = useParams()
  const pillar = pillarId || PILLAR_ENVIRONMENTAL

  const objectives = PILLARS_OBJECTIVES[pillar]

  if (changeToDefault === CHANGE_TO_PILLAR && !pillarId) {
    return <Redirect to={`/${pageName}/${PILLAR_ENVIRONMENTAL}`} />
  }
  if (changeToDefault === CHANGE_TO_OBJECTIVE && !objectiveId) {
    return <Redirect to={`/${pageName}/${pillar}/${objectives[0]}`} />
  }
  return (
    <div className={"objectivesFrame"}>
      <ObjectiveTabs
        pageName={pageName}
        pillar={pillarId}
        objective={objectiveId}
        progress={isCollectLocked ? null : progress}
      />
      <div className={"objectivesContent"}>{children}</div>
    </div>
  )
}

ObjectivesFrame.propTypes = {
  children: PropTypes.any.isRequired,
  pageName: PropTypes.string.isRequired,
  changeToDefault: PropTypes.oneOf([
    CHANGE_TO_OBJECTIVE,
    CHANGE_TO_PILLAR,
    null,
  ]).isRequired,
}

export default ObjectivesFrame
