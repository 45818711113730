import React from "react"
import { Tooltip } from "@shopify/polaris"
import DownloadFileButtonAtom from "views/atoms/buttons/DownloadFileButtonAtom"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const DownloadFileButtonStatusMolecule = ({
  inventoryStatus,
  onDownloadFile,
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      content={t(`collect:download.${inventoryStatus.processing_status}`)}
      dismissOnMouseOut
      light
    >
      <div>
        {inventoryStatus && (
          <DownloadFileButtonAtom
            inventoryId={inventoryStatus.id}
            onDownloadFile={onDownloadFile}
          />
        )}
      </div>
    </Tooltip>
  )
}

DownloadFileButtonStatusMolecule.propTypes = {
  inventoryStatus: PropTypes.object.isRequired,
  onDownloadFile: PropTypes.func,
}

DownloadFileButtonStatusMolecule.defaultProps = {
  onDownloadFile: null,
}

export default DownloadFileButtonStatusMolecule
