const StandardEntity = (baseUrl) => {
  return (backClient) => ({
    list: async () => await backClient.get(baseUrl),
    create: async (data) => await backClient.post(`${baseUrl}.json`, data),
    update: async (id, data) =>
      await backClient.put(`${baseUrl}/${id}.json`, data),
    delete: async (id) => await backClient.delete(`${baseUrl}/${id}.json`),
  })
}

export default StandardEntity

export const GoodProjectsEntity = StandardEntity("/api/good_projects")
export const LocalCommunityInitiativesEntity = StandardEntity(
  "/api/local_community_initiatives"
)
export const DataCenterCloudInventoriesEntity = StandardEntity(
  "/api/data_center_cloud_inventories"
)
