import PropTypes from "prop-types"
import React from "react"
import SetCurrentScopeAction from "components/widgets/Dashboard/OrganizationChart/NodeActions/SetCurrentScopeAction"
import ConsolidateAction from "components/widgets/Dashboard/OrganizationChart/NodeActions/ConsolidateAction"
import UnavailableConsolidateAction from "components/widgets/Dashboard/OrganizationChart/NodeActions/UnavailableConsolidationAction"

const NodeActions = ({ node }) => {
  if (!node.can_access) return null

  const isConsolidationPossible =
    node.is_env_consolidation_ready || node.is_profit_consolidation_ready

  const isCurrentNodeConsolidationPossible =
    isConsolidationPossible && node.is_current_node && node.can_read

  if (node.score && !isCurrentNodeConsolidationPossible) {
    return (
      <SetCurrentScopeAction
        node={node}
        isConsolidationPossible={isConsolidationPossible}
      />
    )
  }

  if (!node.can_read) {
    return null
  }

  if (isConsolidationPossible && node.can_consolidate) {
    return <ConsolidateAction node={node} />
  }

  if (node.has_collect) {
    return <SetCurrentScopeAction node={node} />
  }

  if (node.children.length) {
    return <UnavailableConsolidateAction />
  }

  return null
}

NodeActions.propTypes = {
  node: PropTypes.shape({
    score: PropTypes.number,
    id: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired,
    has_collect: PropTypes.bool.isRequired,
    can_access: PropTypes.bool.isRequired,
    can_read: PropTypes.bool.isRequired,
    can_edit: PropTypes.bool.isRequired,
    is_env_consolidation_ready: PropTypes.bool.isRequired,
    is_profit_consolidation_ready: PropTypes.bool.isRequired,
    is_current_node: PropTypes.bool.isRequired,
    can_consolidate: PropTypes.bool.isRequired,
  }).isRequired,
}

export default NodeActions
