import React from "react"
import { TextField } from "@shopify/polaris"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const NumberFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
  label,
}) => {
  const { t } = useTranslation()

  label ||= t(`activation:${type}.questions.${property}.label`)
  return (
    <TextField
      label={label}
      id={property}
      name={property}
      value={value?.toString()}
      error={error}
      onChange={(value) => onChange(value === "" ? null : +value)}
      type="number"
      inputMode="numeric"
      disabled={disabled}
    />
  )
}

NumberFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.number,
  type: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

NumberFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default NumberFieldAtom
