import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import ResponsibleConsumptionOrganism from "views/organisms/collects/objectives/12/responsibleConsumption/ResponsibleConsumptionOrganism"
import ResponsibleProductionOrganism from "views/organisms/collects/objectives/12/responsibleProduction/ResponsibleProductionOrganism"

const Objective12Organism = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "sustainable_consumption":
        return <ResponsibleConsumptionOrganism />
      case "sustainable_production":
        return <ResponsibleProductionOrganism />
      case "positive_impact":
        return <PositiveImpactForm />
      default:
        return <p>Aucun formulaire pour ce thème.</p>
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective12Organism
