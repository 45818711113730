import React, { useCallback, useEffect, useState } from "react"
import { FormLayout } from "@shopify/polaris"
import Form from "components/widgets/CollectPage/forms/Form"
import { useParams } from "react-router-dom"
import {
  ExternalLabel,
  Select,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import { useTranslation } from "react-i18next"
import SocialPeopleInventory from "components/widgets/CollectPage/forms/inventories/social/SocialPeopleInventory"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import { useFormConfig, useFormContext } from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"
import { useCollectContext } from "../../../CollectContextProvider"

const fields = [
  "parity_IT_women_employee_rate",
  "parity_IT_women_managerial_employee_rate",
  "parity_IT_women_leader_rate",
  "parity_IT_women_promoted_rate",
  "parity_IT_women_recruited_rate",
  "parity_IT_salary_gap_gender_rate",
  "parity_IT_parental_policy",
  "parity_IT_number_of_highest_paid_women",
  "upload_detailed_inventory",
]
const translationPath = "5.parity"

const Parity = () => {
  const { t } = useTranslation()
  const { collect } = useCollectContext()
  const [disabled, setDisabled] = useState(false)

  const { formData } = useFormContext()

  useFormConfig({ fields, translationPath })

  useEffect(() => {
    if (formData.upload_detailed_inventory === true) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [formData])

  useNullifyingField("upload_detailed_inventory", [
    "parity_IT_women_employee_rate",
    "parity_IT_women_managerial_employee_rate",
    "parity_IT_women_promoted_rate",
    "parity_IT_women_recruited_rate",
    "parity_IT_women_leader_rate",
  ])

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {t("collect:questions.5.parity.table.row_1")} </td>
            <td>
              <TextField
                id="parity_IT_women_employee_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.5.parity.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td> {t("collect:questions.5.parity.table.row_2")} </td>
            <td>
              <TextField
                id="parity_IT_women_managerial_employee_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.5.parity.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td> {t("collect:questions.5.parity.table.row_3")} </td>
            <td>
              <TextField
                id="parity_IT_women_leader_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.5.parity.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td> {t("collect:questions.5.parity.table.row_4")} </td>
            <td>
              <TextField
                id="parity_IT_women_promoted_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.5.parity.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td> {t("collect:questions.5.parity.table.row_5")} </td>
            <td>
              <TextField
                id="parity_IT_women_recruited_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.5.parity.table.suffix")}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <SocialPeopleInventory />
        </div>
        <div className="column column-23">
          <FormLayout>
            <ExternalLabel translationPathName={"table"} />
            {tableMarkup()}
            <TextField
              id="parity_IT_salary_gap_gender_rate"
              type="number"
              disabled={disabled && !collect.salary_field_required}
            />
            <TextField
              id="parity_IT_number_of_highest_paid_women"
              type="number"
              disabled={disabled && !collect.salary_field_required}
            />
            <Select
              id="parity_IT_parental_policy"
              options={[
                {
                  label: t(
                    "collect:questions.5.parity.parity_IT_parental_policy.options.regulatory_enforcement"
                  ),
                  value: "regulatory_enforcement",
                },
                {
                  label: t(
                    "collect:questions.5.parity.parity_IT_parental_policy.options.extended_period_on_request"
                  ),
                  value: "extended_period_on_request",
                },
                {
                  label: t(
                    "collect:questions.5.parity.parity_IT_parental_policy.options.extended_period_paid"
                  ),
                  value: "extended_period_paid",
                },
              ]}
            />
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default () => {
  const { themeId } = useParams()
  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "parity":
        return <Parity />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])
  return themeFormMarkup()
}
