import React from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { Doughnut } from "react-chartjs-2"
import { DEFAULT_OPTIONS } from "services/charts/default"
import Config from "assets/configs/score"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import className from "classnames"
import { RIGHT_LEGEND } from "services/charts/legend"

const ChartAnalyzeWebsiteSplitPerItDomain = ({ websiteScore }) => {
  const { t } = useTranslation()

  const unit = websiteScore.split_per_it_domain.unit_key
  const { labels, datasets } = websiteScore.split_per_it_domain
  const generateData = () => {
    return {
      labels: labels.map((label) =>
        t(`analyze:websites.split_per_it_domain.${label}`)
      ),
      datasets: [
        {
          data: [...datasets[0].percentages],
          tooltipValues: datasets[0].data,
          unit: t(`units:units.emission.${unit}`),
          backgroundColor: Config.common.colors,
          showUnitOnDatalabel: true,
          datalabelUnit: "%",
        },
      ],
    }
  }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t("analyze:websites.split_per_it_domain.help")}
    >
      <div
        className={className("Verdikt-Score-DoughnutChart", "environmental")}
      >
        <h1>{t("score:score.13.chartGHGEmissionsDistribution.title")}</h1>
        <div className="content">
          <Doughnut
            data={generateData()}
            options={{ ...DEFAULT_OPTIONS, legend: RIGHT_LEGEND }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartAnalyzeWebsiteSplitPerItDomain.propTypes = {
  websiteScore: PropTypes.object.isRequired,
}

export default ChartAnalyzeWebsiteSplitPerItDomain
