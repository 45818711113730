import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGCountryEmissionsAverageMolecule from "views/molecules/canvases/charts/TextChartODD13GHGCountryEmissionsAverageMolecule"
import ChartODD13NetworkEquipementsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartOdd13NetworkEquipementsGHGEmissionsMolecule"
import ChartODD13EquipmentTypeVolumeAverageGHGMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentTypeVolumeAverageGHGMolecule"
import ChartODD13EquipmentVolumeEmissionsTopLocMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentVolumeEmissionsTopLocMolecule"
import TextChartODD13GHGEmissionsNetworkEquipmentMolecule from "views/molecules/canvases/charts/TextChartOdd13GHGEmissionsNetworkEquipmentMolecule"
import TextChartOdd13GhgEmissionTab from "views/molecules/canvases/charts/TextChartODD13GHGEmissionTab"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const NetworkAnalysisOrganism = ({ score }) => {
  return (
    <ScoreLayoutOrganism
      maturity={score?.odd13_network_maturity ?? 0}
      chart1={
        <TextChartOdd13GhgEmissionTab
          ghgEmissions={score?.odd13_network_CO2}
          ghgEmissionsCollect={score?.odd13_CO2}
          node={score?.node}
        />
      }
      chart2={
        <TextChartODD13GHGCountryEmissionsAverageMolecule score={score} />
      }
      chart3={
        <ChartODD13EquipmentVolumeEmissionsTopLocMolecule
          mixedChart={score?.odd13_network_equipment_volume_ghg_top}
        />
      }
      chart4={
        <ChartODD13EquipmentTypeVolumeAverageGHGMolecule
          mixedChart={score?.odd13_network_volume_emission_equipment}
        />
      }
      chart5={
        <TextChartODD13GHGEmissionsNetworkEquipmentMolecule score={score} />
      }
      chart6={
        <ChartODD13NetworkEquipementsGHGEmissionsMolecule
          stackedChart={score?.odd13_network_equipments_emissions_top}
        />
      }
    />
  )
}

NetworkAnalysisOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

export default NetworkAnalysisOrganism
