import { useCallback, useState } from "react"
import SnowEntity from "services/entities/SnowEntity"
import { useEntity } from "./useAxios"

const useSnowRun = (snowRun) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const snowEntity = useEntity(SnowEntity)

  const create = useCallback(async (body) => await snowEntity.create(body), [
    snowRun,
  ])

  const update = useCallback(
    async (body) => await snowEntity.update(snowRun.id, body),
    [snowRun]
  )

  const requester = async (request) => {
    try {
      setLoading(true)

      return await request()
    } catch (err) {
      console.error(err)
      setErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    update,
    create,
    errors,
  }
}

export default useSnowRun
