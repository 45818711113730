import React from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import PropTypes from "prop-types"
import { PILLARS } from "utils/constants/pillars"
import { COLORS } from "utils/constants/colors"

const BarChartMoleculeDataQuality = ({
  title,
  data,
  plainData,
  labelSuffix,
  yLabel,
  labelCallback,
  datalabelsFormatter,
  pillarId,
  max,
}) => {
  const hasMultipleDatasets = data.datasets.length > 1

  const defaultLabelCallback = (tooltipItem, data) => {
    const value = plainData[tooltipItem.datasetIndex].data[tooltipItem.index]

    return (
      `${data.datasets[tooltipItem.datasetIndex].label}: ${value}` + labelSuffix
    )
  }

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: hasMultipleDatasets,
      position: "bottom",
      labels: {
        fontStyle: "italic",
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: yLabel,
          },
          ticks: {
            beginAtZero: true,
            max: max || undefined,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: datalabelsFormatter,
        display: true,
        color: COLORS.white,
        font: {
          weight: "bold",
        },
      },
    },
    tooltips: {
      enabled: true,
      mode: "single",
      callbacks: {
        label: labelCallback || defaultLabelCallback,
      },
    },
  }

  return (
    <div className={classNames("Verdikt-Score-BarChart", pillarId)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

BarChartMoleculeDataQuality.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  plainData: PropTypes.array,
  labelSuffix: PropTypes.string,
  yLabel: PropTypes.string,
  labelCallback: PropTypes.func,
  datalabelsFormatter: PropTypes.func,
  max: PropTypes.number,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
}

BarChartMoleculeDataQuality.defaultProps = {
  labelSuffix: "",
  plainData: [],
  yLabel: "",
  labelCallback: null,
  datalabelsFormatter: null,
  max: null,
}

export default BarChartMoleculeDataQuality
