import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import EnvCollectEntity from "services/entities/EnvCollectEntity"
import Form from "components/widgets/CollectPage/forms/Form"
import { Banner, FormLayout, TextStyle } from "@shopify/polaris"
import ResponsibleProductionTable from "views/molecules/collects/objectives/12/responsibleProduction/ResponsibleProductionTable"
import Loading from "components/widgets/Loading"
import { useFormContext } from "../../../../../../components/widgets/CollectPage/forms/FormContext"
import { useCollectContext } from "../../../../../../components/widgets/CollectPage/CollectContextProvider"
import { useEntity } from "../../../../../../hooks/useAxios"

const translationPath = "12.sustainable_production"

const ResponsibleProductionOrganism = () => {
  const { t } = useTranslation()
  const [shouldInit, setShouldInit] = useState(true)
  const { collect } = useCollectContext()
  const [equipmentTypesAvailable, setEquipmentTypesAvailable] = useState([])
  const [
    isFetchingEquipmentsTypesAvailable,
    setIsFetchingEquipmentsTypesAvailable,
  ] = useState(true)
  const { setFormData, setTranslationPath } = useFormContext()
  const envCollectEntity = useEntity(EnvCollectEntity)

  useEffect(async () => {
    try {
      const { data } = await envCollectEntity.getEquipmentsTypesAvailable()
      setEquipmentTypesAvailable(data)
    } catch (e) {
      console.log(e)
    } finally {
      setIsFetchingEquipmentsTypesAvailable(false)
    }
  }, [])

  useEffect(() => {
    if (collect == null) return
    setTranslationPath(translationPath)

    const data = {
      loaded_data: true,
      collected_equipments: {
        workplace: {},
        infrastructure: {},
        network_security: {},
        business: {},
      },
    }

    collect.collected_equipments.forEach((collectedEquipment) => {
      data.collected_equipments[collectedEquipment.domain][
        collectedEquipment.equipment_type
      ] = collectedEquipment
    })

    setFormData(data)
    setShouldInit(false)
  }, [shouldInit])

  if (isFetchingEquipmentsTypesAvailable) {
    return <Loading />
  }

  return equipmentTypesAvailable.length > 0 ? (
    <Form>
      <div className="columns">
        <div className="column column-34">
          <>
            <FormLayout>
              <ResponsibleProductionTable
                equipmentTypes={equipmentTypesAvailable}
              />
            </FormLayout>
            <TextStyle variation="subdued">
              <br />
              {t(
                "collect:questions.12.sustainable_production.internal_reconditioning.label"
              )}
              <br />
              {t(
                "collect:questions.12.sustainable_production.external_reconditioning.label"
              )}
              <br />
              {t("collect:questions.12.sustainable_production.recycle.label")}
            </TextStyle>
          </>
        </div>
      </div>
    </Form>
  ) : (
    <Banner
      title={t(
        "collect:questions.12.sustainable_consumption.banners.no_equipment_types.title"
      )}
      status="warning"
    >
      {t(
        "collect:questions.12.sustainable_consumption.banners.no_equipment_types.description"
      )}
    </Banner>
  )
}

export default ResponsibleProductionOrganism
