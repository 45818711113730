import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, FormLayout, TextField } from "@shopify/polaris"

const ReassessmentScenarioFormMolecule = ({ onChange, formData, disabled }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Section
        title={t("organization:reassessment.form.titles.period.partner")}
      >
        <FormLayout.Group>
          <TextField
            label={t("replay:section.period.partner.fields.versionName")}
            id="versionName"
            name="versionName"
            value={formData.versionName}
            onChange={onChange}
            type="text"
            inputMode="text"
            required
            helpText={
              <span>
                {t(
                  "organization:reassessment.form.warnings.period_name.partner"
                )}
              </span>
            }
            disabled={disabled}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label={t("replay:section.period.partner.fields.versionDescription")}
            id="versionDescription"
            name="versionDescription"
            value={formData.versionDescription}
            onChange={onChange}
            type="text"
            inputMode="text"
            required
            disabled={disabled}
            multiline={3}
          />
        </FormLayout.Group>
      </Card.Section>
    </Card>
  )
}

ReassessmentScenarioFormMolecule.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    versionName: PropTypes.string,
    versionDescription: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
}

ReassessmentScenarioFormMolecule.defaultProps = {
  disabled: false,
  isPartner: false,
}

export default ReassessmentScenarioFormMolecule
