export const chart6DatalabelsFormatter = (value, tooltip) => {
  return value >= 10 ? tooltip.dataset.label : null
}

export const Chart6Options = {
  maintainAspectRatio: false,
  legend: {
    position: "bottom",
    display: true,
    fullWidth: true,
    labels: {
      boxWidth: 10,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "kt.CO2",
        },
      },
    ],
  },
}
