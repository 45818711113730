import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Banner, FormLayout, Modal } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import useToggle from "hooks/useToggle"
import useSnowRun from "hooks/useSnowRun"
import Form from "components/widgets/CollectPage/forms/Form"
import TextFieldAtom from "views/atoms/organization/form/TextFieldAtom"
import StackedButtonMolecule from "views/molecules/activators/StackedButtonMolecule"

const SnowRunModalOrganism = ({ collect, setCollect, disabled }) => {
  const { t } = useTranslation()

  const [open, toggleOpen] = useToggle(false)

  const { loading, update, create, errors } = useSnowRun(collect?.snow_run)

  const [snowRun, setSnowRun] = useState(collect?.snow_run)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setSnowRun(collect?.snow_run)
  }, [collect])

  const onChange = (value, key) => setSnowRun({ ...snowRun, [key]: value })

  const onSubmit = async () => {
    const request = snowRun?.id ? update : create

    try {
      const response = await request(snowRun)
      toggleOpen()
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.message)
      }
    }
  }

  const activator = useCallback(
    () => (
      <StackedButtonMolecule
        toggleOpen={toggleOpen}
        disabled={disabled}
        edit={!!snowRun?.id}
        text={t(`collect:banners.serviceNow.button`)}
      />
    ),
    [collect, snowRun]
  )

  if (!collect?.available_snow) {
    return null
  }

  return (
    <Modal
      activator={activator()}
      open={open}
      onClose={toggleOpen}
      title={t(`collect:banners.serviceNow.form.title`)}
      primaryAction={{
        content: t("common:save"),
        onAction: () => onSubmit(),
        loading: loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: () => toggleOpen(),
        },
      ]}
    >
      <Modal.Section>
        <Form>
          {errorMessage && <Banner status="critical">{errorMessage}</Banner>}
          <FormLayout>
            <TextFieldAtom
              value={snowRun?.service_now_url}
              label={t(`collect:banners.serviceNow.form.service_now_url`)}
              onChange={onChange}
              property="service_now_url"
            />
            <TextFieldAtom
              value={snowRun?.service_now_username}
              label={t(`collect:banners.serviceNow.form.service_now_username`)}
              onChange={onChange}
              property="service_now_username"
            />
            <TextFieldAtom
              value={snowRun?.service_now_password}
              label={t(`collect:banners.serviceNow.form.service_now_password`)}
              onChange={onChange}
              inputType="password"
              property="service_now_password"
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

SnowRunModalOrganism.propTypes = {
  collect: PropTypes.object,
  setCollect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SnowRunModalOrganism.defaultProps = {
  disabled: false,
}

export default SnowRunModalOrganism
