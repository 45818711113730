import React from "react"
import PropTypes from "prop-types"
import { Banner } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import {
  FORM_MODE_EDIT,
} from "utils/constants/forms/nodeForms"

const PartnerCreationBannerAtom = ({ property, type, mode }) => {
  const { t } = useTranslation()

  if (mode === FORM_MODE_EDIT) {
    return null
  }

  return (
    <Banner status="info">
      {t(`activation:${type}.questions.${property}.label`)}
    </Banner>
  )
}

PartnerCreationBannerAtom.propTypes = {
  mode: PropTypes.oneOf(["edit", "create"]),
  property: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default PartnerCreationBannerAtom
