import React from "react"
import PropTypes from "prop-types"
import { Icon } from "@shopify/polaris"
import { MobileCancelMajor } from "@shopify/polaris-icons"

const RecommendationDetailsActions = ({ onClose }) => {
  return (
    <td className="recommendationsLayout__details__actions">
      <button onClick={onClose}>
        <Icon source={MobileCancelMajor} />
      </button>
    </td>
  )
}

RecommendationDetailsActions.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default RecommendationDetailsActions
