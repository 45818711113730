import { AppContextProvider } from "components/AppContext"
import React from "react"
import "stylesheets/style.scss"
import "stylesheets/Navbar.scss"
import AppConfigProvider from "components/AppConfigProvider"

const AppLayout = () => (
  <AppContextProvider>
    <AppConfigProvider />
  </AppContextProvider>
)

export default AppLayout
