export const upload = async (file, node, collect, inventoryType, callback) => {
  const fileFormData = new FormData()
  fileFormData.append("file[data]", file)
  fileFormData.append("file[id]", inventoryType)
  fileFormData.append("file[node_id]", node?.id)
  fileFormData.append("file[collect_type]", collect?.type)
  fileFormData.append("file[collect_id]", collect?.id)

  return await callback(fileFormData)
}
