import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Checkbox, FormLayout } from "@shopify/polaris"
import Form from "components/widgets/CollectPage/forms/Form"
import { useParams } from "react-router-dom"
import {
  ExternalLabel,
  Select,
  TextField,
  YesNoRadio,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import { useFormConfig, useFormContext } from "../../FormContext"
import { useCollectContext } from "../../../CollectContextProvider"

const fields = [
  "well_being_opinion_survey",
  "well_being_working_condition_survey",
  "well_being_opinion_survey_frequency",
  "well_being_working_condition_survey_frequency",
  "well_being_opinion_survey_average_response",
  "well_being_working_condition_survey_average_responses",
  "well_being_opinion_survey_declared_engagement",
  "well_being_working_condition_survey_satisfaction_rate",
  "well_being_remote_work_satisfaction",
  "well_being_workplace_tools_evaluation",
  "well_being_resignation_rate",
  "well_being_absenteeism_rate",
  "well_being_non_economic_layoff_rate",
  "well_being_alert_system_employee",
  "well_being_alert_system_employee_declaration_number",
  "well_being_internal_mobility",
  "well_being_span_control",
  "well_being_contractual_termination_rate",
]
const translationPath = "3.well_being"

const WellBeingForm = () => {
  const { t } = useTranslation()
  const { isCollectLocked } = useCollectContext()
  const [disabled, setDisabled] = useState({})

  const { formData, setFormData } = useFormContext()

  useFormConfig({ fields, translationPath })

  // Set disabled fields
  useEffect(() => {
    const checkbox1 = formData.well_being_opinion_survey
    const checkbox2 = formData.well_being_working_condition_survey

    setDisabled({
      ...disabled,
      well_being_opinion_survey_frequency: !checkbox1,
      well_being_opinion_survey_average_response: !checkbox1,
      well_being_opinion_survey_declared_engagement: !checkbox1,

      well_being_working_condition_survey_frequency: !checkbox2,
      well_being_working_condition_survey_average_responses: !checkbox2,
      well_being_working_condition_survey_satisfaction_rate: !checkbox2,
    })
  }, [formData])

  // Reset fields if option de-selected
  useEffect(() => {
    if (formData.well_being_opinion_survey === false) {
      setFormData({
        ...formData,
        ["well_being_opinion_survey_frequency"]: null,
        ["well_being_opinion_survey_average_response"]: null,
        ["well_being_opinion_survey_declared_engagement"]: null,
      })
    }
  }, [formData?.well_being_opinion_survey])

  // Reset fields if option de-selected
  useEffect(() => {
    if (formData.well_being_working_condition_survey === false) {
      setFormData({
        ...formData,
        ["well_being_working_condition_survey_frequency"]: null,
        ["well_being_working_condition_survey_average_responses"]: null,
        ["well_being_working_condition_survey_satisfaction_rate"]: null,
      })
    }
  }, [formData?.well_being_working_condition_survey])

  // Reset fields if option de-selected
  useEffect(() => {
    if (
      !formData.well_being_alert_system_employee &&
      formData.well_being_alert_system_employee_declaration_number
    )
      setFormData({
        ...formData,
        ["well_being_alert_system_employee_declaration_number"]: null,
      })
  }, [formData?.well_being_alert_system_employee])

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th></th>
            <th> {t("collect:questions.3.well_being.table.column_1")} </th>
            <th> {t("collect:questions.3.well_being.table.column_2")} </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {t("collect:questions.3.well_being.table.row_1")} </td>
            <td className="centered">
              <Checkbox
                id="well_being_opinion_survey"
                checked={formData["well_being_opinion_survey"] == true}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    ["well_being_opinion_survey"]: value,
                  })
                }}
                disabled={isCollectLocked}
              />
            </td>
            <td className="centered">
              <Checkbox
                id="well_being_working_condition_survey"
                checked={
                  formData["well_being_working_condition_survey"] == true
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    ["well_being_working_condition_survey"]: value,
                  })
                }}
                disabled={isCollectLocked}
              />
            </td>
          </tr>
          <tr>
            <td> {t("collect:questions.3.well_being.table.row_2")} </td>
            {[
              "well_being_opinion_survey_frequency",
              "well_being_working_condition_survey_frequency",
            ].map((id) => {
              return (
                <td key={id}>
                  <Select
                    id={id}
                    options={[
                      {
                        label: t(
                          "collect:questions.3.well_being.table.options.monthly"
                        ),
                        value: "100",
                      },
                      {
                        label: t(
                          "collect:questions.3.well_being.table.options.quarterly"
                        ),
                        value: "80",
                      },
                      {
                        label: t(
                          "collect:questions.3.well_being.table.options.semi-annual"
                        ),
                        value: "60",
                      },
                      {
                        label: t(
                          "collect:questions.3.well_being.table.options.annual"
                        ),
                        value: "40",
                      },
                      {
                        label: t(
                          "collect:questions.3.well_being.table.options.biennial"
                        ),
                        value: "20",
                      },
                    ]}
                    disabled={disabled[id]}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td> {t("collect:questions.3.well_being.table.row_3")} </td>
            {[
              "well_being_opinion_survey_average_response",
              "well_being_working_condition_survey_average_responses",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    suffix={t("collect:questions.3.well_being.table.suffix")}
                    disabled={disabled[id]}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <td> {t("collect:questions.3.well_being.table.row_4")} </td>
            {[
              "well_being_opinion_survey_declared_engagement",
              "well_being_working_condition_survey_satisfaction_rate",
            ].map((id) => {
              return (
                <td key={id}>
                  <TextField
                    id={id}
                    type="number"
                    suffix={t("collect:questions.3.well_being.table.suffix")}
                    disabled={disabled[id]}
                  />
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <div className="columns">
        <div className="column column-23">
          <FormLayout>
            <ExternalLabel translationPathName={"table"} />
            {tableMarkup()}
            <TextField id="well_being_remote_work_satisfaction" type="number" />
            <Select
              id="well_being_workplace_tools_evaluation"
              type="number"
              options={[
                {
                  label: t(
                    "collect:questions.3.well_being.well_being_workplace_tools_evaluation.options.bad"
                  ),
                  value: "bad",
                },
                {
                  label: t(
                    "collect:questions.3.well_being.well_being_workplace_tools_evaluation.options.poor"
                  ),
                  value: "poor",
                },
                {
                  label: t(
                    "collect:questions.3.well_being.well_being_workplace_tools_evaluation.options.good"
                  ),
                  value: "good",
                },
                {
                  label: t(
                    "collect:questions.3.well_being.well_being_workplace_tools_evaluation.options.very_good"
                  ),
                  value: "very_good",
                },
              ]}
            />
          </FormLayout>
        </div>
        <div className="column">
          <FormLayout>
            <TextField id="well_being_internal_mobility" type="number" />
            <TextField id="well_being_span_control" type="number" />
            {/* LayoffForm  */}
            <TextField
              id="well_being_absenteeism_rate"
              type="number"
              step={0.1}
            />
            <TextField id="well_being_resignation_rate" type="number" />
            <TextField id="well_being_non_economic_layoff_rate" type="number" />

            <TextField
              id="well_being_contractual_termination_rate"
              type="number"
            />
            {/* AlertSystemForEmployee  */}
            <YesNoRadio id="well_being_alert_system_employee" />
            <TextField
              id="well_being_alert_system_employee_declaration_number"
              type="number"
              disabled={!formData["well_being_alert_system_employee"]}
            />
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default () => {
  const { themeId } = useParams()
  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "well_being":
        return <WellBeingForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])
  return themeFormMarkup()
}
