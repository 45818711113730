import React, { useCallback } from "react"
import { Layout, Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import { useTranslation } from "react-i18next"
import Message from "components/widgets/Dashboard/Message"
import ChartContainerOrganism from "views/organisms/organization/ChartContainerOrganism"
import ToastAtom from "views/atoms/forms/ToastAtom"
import { useChart } from "components/widgets/Dashboard/OrganizationChart/ChartContext"
import NoPeriodNameBanner from "views/molecules/banners/NoPeriodNameBanner"
import { useAppContext } from "components/AppContext"
import HistoryContextContainer from "views/contexts/HistoryContext"
import useAdminRules from "hooks/useAdminRules"
import OrganizationPageBanner from "views/organisms/organization/OrganizationPageBanner"
import ViewerOnlyBanner from "views/molecules/collects/banners/ViewerOnlyBanner"
import NoSubscriptionBanner from "components/widgets/NoSubscriptionBanner"

const ChartContainerLoaderOrganism = () => {
  const { score, isLoading, toastStatus, setToastStatus, reload } = useChart()
  const { t } = useTranslation()

  const {
    currentUser,
    currentRootNode,
    subscription,
    isCurrentRootNodeLoading,
    isCurrentNodeLoading,
    currentNode,
  } = useAppContext()
  const { isRootNodeAdmin } = useAdminRules()
  const formattedDate = new Date(subscription?.date_end).toLocaleDateString()
  const potentialDefineCurrentPeriodName = useCallback(() => {
    if (
      currentRootNode.is_external ||
      currentRootNode.period_name ||
      !isRootNodeAdmin ||
      subscription?.is_provider
    )
      return

    return (
      <Page>
        <Layout>
          <NoPeriodNameBanner
            onSuccess={() => reload({ organization: true })}
          />
        </Layout>
      </Page>
    )
  }, [currentRootNode, currentUser, subscription])

  if (isLoading || isCurrentRootNodeLoading || isCurrentNodeLoading) {
    return <Loading />
  } else if (currentRootNode) {
    return (
      <>
        {potentialDefineCurrentPeriodName()}
        {currentUser.is_reader_only && <ViewerOnlyBanner />}
        {subscription?.ended && (
          <NoSubscriptionBanner
            title={t(`common:subscription_end.title`) + (formattedDate || "")}
            status="warning"
            description={t(`common:subscription_end.description`)}
          />
        )}
        <OrganizationPageBanner />
        <HistoryContextContainer>
          <ChartContainerOrganism />
        </HistoryContextContainer>
        <ToastAtom toastStatus={toastStatus} setToastStatus={setToastStatus} />
      </>
    )
  } else if (score?.failed) {
    return <Message messageId="error" />
  } else {
    return <Message messageId="missing_root_node" />
  }
}

export default ChartContainerLoaderOrganism
