import React, { useEffect, useState } from "react"
import withAuthRequired from "components/auth/withAuthRequired"
import { Layout, Page } from "@shopify/polaris"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import { useAppContext } from "components/AppContext"
import Message from "components/widgets/Dashboard/Message"
import Score from "components/widgets/Dashboard/Score"

const HomePage = () => {
  const {
    currentPeriod,
    parentOrganization,
    score,
    currentRootNode,
  } = useAppContext()

  const [loading, setLoading] = useState(false)

  useEffect(() => setLoading(score?.processing), [score])

  return (
    <div className="Verdikt-Score">
      {((currentRootNode && !currentRootNode.has_children) || !score) && (
        <Message messageId="beginning" show />
      )}
      <Page>
        <Layout>
          <Layout.Section>
            <BreadcrumbsOrganism
              parentOrganization={parentOrganization}
              currentPeriodName={currentPeriod?.name}
            />
          </Layout.Section>

          <Score loading={loading} setLoading={setLoading} quantitative />
        </Layout>
      </Page>
    </div>
  )
}

export default withAuthRequired(HomePage)
