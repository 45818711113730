import PropTypes from "prop-types"

const UpdatedAtReportAtom = ({ report }) => {
  let date = new Date(report.updated_at.toString())
  return date.toLocaleDateString()
}

UpdatedAtReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default UpdatedAtReportAtom
