export const displayUrl = (website) => {
  return website.url.length < 25
    ? website.url.replace("https://", "").replace("www.", "")
    : website.url
        .replace("https://", "")
        .replace("www.", "")
        .split("/")
        .map((part, index, array) => {
          if (index === 0) {
            const domainParts = part.split(".")
            return domainParts.length > 1 ? domainParts[0] + "…" : part
          } else if (index === array.length - 1 || index === array.length - 2) {
            return "/" + part
          } else {
            return ""
          }
        })
        .join("")
}
