import PropTypes from "prop-types"

const TranslateLanguageReportAtom = ({ report }) => {
  switch (report.language) {
    case "en":
      return "🇬🇧"
    case "fr":
      return "🇫🇷"
  }
}

TranslateLanguageReportAtom.propTypes = {
  report: PropTypes.object.isRequired,
}

export default TranslateLanguageReportAtom
