import { Badge, DropZone } from "@shopify/polaris"
import { fileStatusToBadgeStatus } from "utils/functions/centralization/centralizationFunctions"
import React, { useCallback } from "react"
import PropTypes from "prop-types"
import DropZoneCaptionTemplate from "../../templates/DropZoneCaptionTemplate"
import { useTranslation } from "react-i18next"
import InventoryErrorMessageMolecule from "../text/InventoryErrorMessageMolecule"
import {
  IMPORT_STATUS_COMPLETED,
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_WAITING_FOR_FILE,
} from "utils/constants/imports/importStatus"

const StatusUploadMolecule = ({ inventoryStatus }) => {
  const { t } = useTranslation()

  const innerDropZone = useCallback(() => {
    switch (inventoryStatus?.processing_status) {
      case IMPORT_STATUS_COMPLETED:
        return (
          <DropZone.FileUpload
            actionTitle={t("collect:questions.common.drop_zone.update.title")}
            actionHint={
              !!inventoryStatus?.items_count &&
              `${inventoryStatus?.items_count} ${t(
                "collect:questions.common.drop_zone.update.current"
              )}`
            }
          />
        )
      case IMPORT_STATUS_ERROR:
        return (
          <InventoryErrorMessageMolecule
            fileStatus={inventoryStatus?.inventory_file}
          />
        )
      case IMPORT_STATUS_WAITING_FOR_FILE:
      case null:
      case undefined:
        return (
          <DropZone.FileUpload
            actionTitle={t("collect:questions.common.drop_zone.create.title")}
            actionHint={t("collect:questions.common.drop_zone.create.hint")}
          />
        )
      default:
        return null
    }
  }, [inventoryStatus])

  return (
    <section className="statusUpload__parent">
      <DropZoneCaptionTemplate className="statusUpload__dropZone">
        {inventoryStatus &&
          inventoryStatus.processing_status !==
            IMPORT_STATUS_WAITING_FOR_FILE && (
            <Badge
              status={fileStatusToBadgeStatus(
                inventoryStatus?.processing_status
              )}
            >
              {t(
                `collect:inventory.statuses.${inventoryStatus?.processing_status}`
              )}
            </Badge>
          )}
        {innerDropZone()}
      </DropZoneCaptionTemplate>
    </section>
  )
}

StatusUploadMolecule.propTypes = {
  inventoryStatus: PropTypes.object,
}

StatusUploadMolecule.defaultPropTypes = {
  inventoryStatus: {
    processing_status: IMPORT_STATUS_WAITING_FOR_FILE,
    items_count: 0,
  },
}

export default StatusUploadMolecule
