import NodeOrganization from "services/organizations/NodeOrganization"
import { Icon } from "@shopify/polaris"
import { BankMajor, GlobeMajor, NavigationMajor } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"

const useSearchTemplate = (
  onNodeAction,
  onRootAction,
  onOrganizationAction,
  onParentOrganizationAction
) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()
  const connector = t("common:topbar.connector")

  const buildBreadcrumb = (breadcrumb) =>
    breadcrumb.map(NodeOrganization.getNodeNameWithVersion).join(connector)
  const nodeTemplateBuilder = (node) => {
    let name = NodeOrganization.getNodeName(node)
    if (node.version_name) name += ` (${node.version_name})`

    return {
      content: <b>{name}</b>,
      helpText: (
        <i>
          {node.breadcrumb && ` ${buildBreadcrumb(node.breadcrumb)}`}
          {!node.can_read && ` (${t("common:topbar.noAccessRights")})`}
        </i>
      ),
      onAction: () => onNodeAction(node),
      prefix: <Icon source={NavigationMajor} />,
      disabled: !node.can_read,
      active: false,
      id: node.category,
    }
  }

  const rootTemplateBuilder = (node) => ({
    content: <b>{NodeOrganization.getNodeName(node)}</b>,
    helpText: (
      <i>
        {t("common:topbar.rootNode")}
        {!node.can_read && ` (${t("common:topbar.noAccessRights")})`}
      </i>
    ),
    onAction: () => onRootAction(node),
    prefix: <Icon source={BankMajor} />,
    disabled: !node.can_read,
    active: false,
    id: node.category,
  })

  const organizationTemplateBuilder = (organization) => ({
    onAction: () => onOrganizationAction(organization),
    content: <b>{organization.name}</b>,
    helpText: (
      <i>{`${currentRootNode.name} - ${t("common:topbar.organization")}`}</i>
    ),
    prefix: <Icon source={GlobeMajor} />,
    active: false,
    id: "partial_partner",
  })

  const parentOrganizationTemplateBuilder = (rootNode) => ({
    onAction: () => onParentOrganizationAction(),
    content: <b>{rootNode.name}</b>,
    helpText: <i>{t("common:topbar.parent_organization")}</i>,
    prefix: <Icon source={BankMajor} />,
    active: true,
    id: "corporate",
  })

  return {
    nodeTemplateBuilder,
    rootTemplateBuilder,
    organizationTemplateBuilder,
    parentOrganizationTemplateBuilder,
  }
}

export default useSearchTemplate
