import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal, Tooltip } from "@shopify/polaris"
import NodeIconAtom from "components/widgets/Dashboard/OrganizationChart/NodeIconAtom"
import useToggle from "hooks/useToggle"

const NodeActionWithConfirmMolecule = ({
  icon,
  onAction,
  active,
  title,
  text,
}) => {
  const { t } = useTranslation()
  const [isModalActive, toggleIsModalActive, setIsModalActive] = useToggle(
    active
  )

  useEffect(() => setIsModalActive(active), [active])

  const secondaryActions = [
    {
      content: t("common:cancel"),
      onAction: () => toggleIsModalActive(),
    },
  ]

  if (onAction !== null) {
    secondaryActions.push({
      content: t("common:confirm"),
      primary: true,
      onAction: async () => {
        toggleIsModalActive()
        await onAction()
      },
    })
  }

  return (
    <Tooltip light content={title}>
      <Modal
        small
        activator={
          <NodeIconAtom handleChange={toggleIsModalActive}>{icon}</NodeIconAtom>
        }
        open={isModalActive}
        onClose={toggleIsModalActive}
        title={title}
        secondaryActions={secondaryActions}
      >
        <Modal.Section>{text}</Modal.Section>
      </Modal>
    </Tooltip>
  )
}

NodeActionWithConfirmMolecule.propTypes = {
  icon: PropTypes.node.isRequired,
  onAction: PropTypes.func,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

NodeActionWithConfirmMolecule.defaultProps = {
  active: false,
  onAction: null,
}

export default NodeActionWithConfirmMolecule
