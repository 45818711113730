import React, { useCallback } from "react"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import { PropTypes } from "prop-types"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { useTranslation } from "react-i18next"
import { DEFAULT_PLUGINS } from "services/charts/plugins/default"

const ChartLineMolecule = ({ title, type, config, label, options }) => {
  const { t } = useTranslation()

  const defaultOptions = {
    ...DEFAULT_OPTIONS,
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-1",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: config.dataset_unit,
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-2",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: config.line_unit,
          },
        },
      ],
    },
  }

  const generateData = useCallback(() => {
    return {
      labels: config.labels,
      datasets: [
        {
          label: label,
          data: config.dataset,
          unit: config.dataset_unit,
          tension: 0.01,
          backgroundColor: Config.common.colors[0],
          fill: false,
          order: 2,
        },
        {
          type: "line",
          label: t(
            "analyze:historization.node.charts.ghg_evolution.line.label"
          ),
          data: config.line,
          isLineDataset: true,
          unit: config.line_unit,
          borderColor: Config.common.colors[1],
          borderWidth: 2,
          fill: false,
          datalabels: { display: false },
          order: 1, // Line chart put first so that we can see the line above the bar chart
          yAxisID: "y-axis-2",
        },
      ],
    }
  }, [config])

  return (
    <div className={classNames("Verdikt-Score-BarChart", type)}>
      <h1>{title}</h1>
      <div className="content">
        <Bar
          options={defaultOptions}
          data={generateData()}
          plugins={DEFAULT_PLUGINS}
        />
      </div>
    </div>
  )
}

export default ChartLineMolecule

ChartLineMolecule.propTypes = {
  config: PropTypes.object.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["environmental", "people", "profit"]),
  options: PropTypes.object,
}

ChartLineMolecule.defaultProps = {
  label: "",
  title: "",
  type: "environmental",
  options: {},
}
