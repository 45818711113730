import { useEffect, useState } from "react"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useHistory, useParams } from "react-router-dom"
import { useAppContext } from "components/AppContext"

const useAvailablePillar = ({ pathPrefix = "/score" }) => {
  const [availablePillarLoaded, setAvailablePillarLoaded] = useState(false)
  const { pillarId } = useParams()
  const history = useHistory()
  const {
    isEnvScoreLoading,
    isSocialScoreLoading,
    envScore,
    socialScore,
    profitScoreRequest,
  } = useAppContext()

  const isLoaded =
    !isEnvScoreLoading && !isSocialScoreLoading && !profitScoreRequest.isLoading

  useEffect(() => {
    if (isLoaded) {
      const pillarToObject = {
        [PILLAR_ENVIRONMENTAL]: envScore,
        [PILLAR_SOCIAL]: socialScore,
        [PILLAR_PROFIT]: profitScoreRequest.data,
      }

      if (!pillarToObject[pillarId]) {
        const pillar = Object.keys(pillarToObject).find(
          (pillar) => pillarToObject[pillar]
        )

        if (pillar) history.push(`${pathPrefix}/${pillar}`)
      }

      setAvailablePillarLoaded(true)
    }
  }, [isLoaded])

  return availablePillarLoaded
}

useAvailablePillar.propTypes = {}

useAvailablePillar.defaultProps = {}

export default useAvailablePillar
