import { Layout } from "@shopify/polaris"
import HeaderTemplate from "views/templates/shared/HeaderTemplate"
import React from "react"
import PropTypes from "prop-types"

import "stylesheets/CollectPage.scss"
import "stylesheets/ObjectiveTabs.scss"
import ThemeLayoutOrganism from "views/organisms/themes/ThemeLayoutOrganism"
import SDGsObjectiveOrganism from "views/organisms/objectives/SDGsObjectiveOrganism"
import TabsObjectiveMolecule from "views/organisms/objectives/TabsObjectiveOrganism"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import WaitTagSeleniumAtom from "views/atoms/selenium/WaitTagSeleniumAtom"
import CompareSearchOrganism from "views/organisms/searches/CompareSearchOrganism"

const CompareTemplate = ({
  parentOrganization,
  themeType,
  tabs,
  objectives,
  children,
  pillars,
  comparedNodes,
  search,
  onDelete,
}) => {
  return (
    <Layout>
      <WaitTagSeleniumAtom />
      <Layout.Section>
        <HeaderTemplate>
          <CompareSearchOrganism
            parentOrganization={parentOrganization}
            comparedNodes={comparedNodes}
            search={search}
            onDelete={onDelete}
          />
        </HeaderTemplate>
      </Layout.Section>

      <Layout.Section>
        <PillarOrganism pillars={pillars} />

        <ThemeLayoutOrganism
          tabs={<TabsObjectiveMolecule tabs={tabs} themeType={themeType} />}
          objectives={
            <SDGsObjectiveOrganism
              objectives={objectives}
              themeType={themeType}
            />
          }
        >
          {children}
        </ThemeLayoutOrganism>
      </Layout.Section>
    </Layout>
  )
}

CompareTemplate.propTypes = {
  parentOrganization: PropTypes.array.isRequired,
  tabs: PropTypes.array,
  objectives: PropTypes.array,
  pillars: PropTypes.array,
  children: PropTypes.element,
  themeType: PropTypes.oneOf([PILLAR_ENVIRONMENTAL]).isRequired,
  storedNodes: PropTypes.array.isRequired,
  setStoredNodes: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array.isRequired,
}

CompareTemplate.defaultProps = {
  tabs: [],
  objectives: [],
  pillars: [],
}

export default CompareTemplate
