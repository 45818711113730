import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ChecklistMajor } from "@shopify/polaris-icons"
import { Card, Modal, Tooltip } from "@shopify/polaris"
import FileQualityEntity from "services/entities/FileQualityEntity"
import Loading from "components/widgets/Loading"
import CardErrorAtom from "views/atoms/errors/CardErrorAtom"
import "stylesheets/common/text.scss"
import MatchingBreakdownBarStackedChartAtom from "views/atoms/charts/doughnuts/MatchingBreakdownBarStackedChartAtom"
import { useEntity } from "../../../../hooks/useAxios"
import PostTreatmentResultOrganism from "views/molecules/modals/files/PostTreatmentResultOrganism"
import EquipmentDetailOrganism from "views/atoms/charts/doughnuts/EquipmentDetailOrganism"

const QualityFileModalMolecule = ({ inventoryStatus }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [fileQuality, setFileQuality] = useState({
    equipment_type_discarded_count: 0,
    final_total_count: 0,
    node_discarded_count: 0,
    equipment_types_discarded: {},
    equipment_types: {},
    matching_breakdown: {},
    nodes: {},
  })
  const fileQualityEntity = useEntity(FileQualityEntity)

  const toggleActiveModal = useCallback(() => setActiveModal(!activeModal), [
    activeModal,
  ])

  const getFileQuality = async () => {
    try {
      const fileQuality = await fileQualityEntity.getFileQuality(
        inventoryStatus.id
      )

      setFileQuality(fileQuality)
    } catch (err) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(async () => {
    if (activeModal) {
      await getFileQuality()
    } else {
      setLoading(true)
      setError(false)
    }
  }, [activeModal])

  const activator = (
    <Tooltip
      content={t(`collect:quality.tooltip.message`)}
      dismissOnMouseOut
      light
    >
      <button onClick={toggleActiveModal} type="button">
        <ChecklistMajor />
      </button>
    </Tooltip>
  )

  return (
    <Modal
      activator={activator}
      open={activeModal}
      onClose={toggleActiveModal}
      title={t(`collect:qualities.title`)}
    >
      {error && !loading && (
        <Modal.Section>
          <CardErrorAtom />
        </Modal.Section>
      )}

      {!error && loading && (
        <Modal.Section>
          <Loading />
        </Modal.Section>
      )}

      {!error && !loading && (
        <>
          <Modal.Section>
            <Card title={t(`collect:qualities.analytics.title`)}>
              <Card.Section>
                <PostTreatmentResultOrganism fileQuality={fileQuality} />
              </Card.Section>
            </Card>
          </Modal.Section>

          <Modal.Section>
            <Card
              title={t(
                `collect:qualities.doughnut_charts.equipment_type.title`
              )}
            >
              <EquipmentDetailOrganism fileQuality={fileQuality} />
            </Card>
          </Modal.Section>

          <Modal.Section>
            <Card
              title={t(`collect:qualities.stacked_charts.equipment_type.title`)}
            >
              <MatchingBreakdownBarStackedChartAtom
                chartData={fileQuality?.matching_breakdown}
              />
            </Card>
          </Modal.Section>
        </>
      )}
    </Modal>
  )
}

QualityFileModalMolecule.propTypes = {
  inventoryStatus: PropTypes.object.isRequired,
}

export default QualityFileModalMolecule
