import React from "react"
import { Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import { useAppContext } from "components/AppContext"
import AnalyzeTemplate from "views/templates/AnalyzeTemplate"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"
import ScoreLoaderTemplate from "views/templates/ScoreLoaderTemplate"
import DataQualitySwitch from "components/widgets/ScorePage/DataQualitySwitch"

const DataQualityPage = () => {
  const {
    currentPeriod,
    parentOrganization,
    isEnvScoreLoading,
    isCurrentNodeLoading,
    envCollect,
  } = useAppContext()

  const pillars = usePillars({
    pageName: "collect/overview",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  if (isEnvScoreLoading || isCurrentNodeLoading) {
    return <Loading />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth>
        <ScoreLoaderTemplate pillarScore={envCollect}>
          <AnalyzeTemplate
            parentOrganization={parentOrganization}
            themeType={PILLAR_ENVIRONMENTAL}
            pillars={pillars}
            periodName={currentPeriod?.name}
          >
            <DataQualitySwitch />
          </AnalyzeTemplate>
        </ScoreLoaderTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(DataQualityPage)
