import React from "react"
import PropTypes from "prop-types"
import { Button, Icon, Stack } from "@shopify/polaris"
import { CirclePlusMajor, EditMinor, ViewMajor } from "@shopify/polaris-icons"

const StackedButtonMolecule = ({
  text,
  toggleOpen,
  disabled,
  edit,
  locked,
}) => {
  const icon = edit ? EditMinor : CirclePlusMajor

  return (
    <Stack distribution="center" alignment="center" spacing="extraTight">
      <p>{text}</p>
      {!disabled && (
        <Button plain onClick={toggleOpen}>
          <Icon source={locked ? ViewMajor : icon} />
        </Button>
      )}
    </Stack>
  )
}

StackedButtonMolecule.propTypes = {
  text: PropTypes.string.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  locked: PropTypes.bool,
}

StackedButtonMolecule.defaultProps = {
  edit: false,
  disabled: false,
  locked: false,
}

export default StackedButtonMolecule
