import React, { createContext, useContext, useEffect, useState } from "react"
import SocialCollectEntity from "services/entities/SocialCollectEntity"
import EnvCollectEntity from "services/entities/EnvCollectEntity"
import { useAppContext } from "../../AppContext"
import { useParams } from "react-router-dom"
import useRequest from "hooks/useRequest"
import { useCollectModules } from "./forms/Module"
import PropTypes from "prop-types"
import { useEntity } from "../../../hooks/useAxios"
import ProfitCollectEntity from "services/entities/ProfitCollectEntity"
import useAdminRules from "hooks/useAdminRules"

export const CollectContext = createContext()

export function useCollectContext() {
  return useContext(CollectContext)
}

const CollectContextProvider = ({ children }) => {
  const {
    currentNode,
    envCollect,
    setEnvCollect,
    isEnvCollectLoading,
    socialCollect,
    setSocialCollect,
    isSocialCollectLoading,
    profitCollect,
    profitCollectRequest,
  } = useAppContext()
  const { isCurrentNodeViewerOnly } = useAdminRules()
  const { pillarId, themeId } = useParams()

  const [toastStatus, setToastStatus] = useState(0)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [isEnvLocked, setEnvLocked] = useState(true)
  const [isSocialLocked, setSocialLocked] = useState(true)
  const [isProfitLocked, setProfitLocked] = useState(true)
  const [isChanged, setChanged] = useState(false)

  const envCollectEntity = useEntity(EnvCollectEntity)
  const socialCollectEntity = useEntity(SocialCollectEntity)
  const profitCollectEntity = useEntity(ProfitCollectEntity)

  const { data: envProgress, reload: reloadEnvProgress } = useRequest(
    () => envCollectEntity.progress(),
    [envCollect],
    {
      condition: !!envCollect,
    }
  )

  const { data: socialProgress, reload: reloadSocialProgress } = useRequest(
    () => socialCollectEntity.progress(),
    [socialCollect],
    {
      condition: !!socialCollect,
    }
  )

  const { data: profitProgress, reload: reloadProfitProgress } = useRequest(
    () => profitCollectEntity.progress(),
    [profitCollect],
    {
      condition: !!profitCollect,
    }
  )

  const { modules, isModuleActive } = useCollectModules()

  useEffect(() => {
    setChanged(false)
  }, [themeId])

  useEffect(() => {
    setEnvLocked(
      !isEnvCollectLoading &&
        (envCollect === null ||
          !envCollect.is_editable ||
          isCurrentNodeViewerOnly)
    )
    setSocialLocked(
      !isSocialCollectLoading &&
        (socialCollect === null ||
          !socialCollect.is_editable ||
          isCurrentNodeViewerOnly)
    )
    setProfitLocked(
      !profitCollectRequest.isLoading &&
        (profitCollect === null ||
          !profitCollect.is_editable ||
          isCurrentNodeViewerOnly)
    )
  }, [
    isEnvCollectLoading,
    envCollect,
    isSocialCollectLoading,
    socialCollect,
    profitCollectRequest.isLoading,
    profitCollect,
    currentNode,
    isCurrentNodeViewerOnly,
  ])

  const providerValue = () => {
    const common = {
      toastStatus,
      setToastStatus,
      isChanged,
      setChanged,
      error,
      setError,
      isModuleActive,
      errors,
      setErrors,
      modules,
    }
    switch (pillarId) {
      case "social":
        return {
          ...common,
          collect: socialCollect,
          setCollect: setSocialCollect,
          progress: socialProgress,
          isCollectLocked: isSocialLocked,
          setCollectLocked: setSocialLocked,
          reloadProgress: reloadSocialProgress,
          entity: socialCollectEntity,
        }
      case "financial":
        return {
          ...common,
          collect: profitCollect,
          setCollect: profitCollectRequest.setData,
          progress: profitProgress,
          isCollectLocked: isProfitLocked,
          setCollectLocked: setProfitLocked,
          reloadProgress: reloadProfitProgress,
          entity: profitCollectEntity,
        }
      case "environmental":
      default:
        return {
          ...common,
          collect: envCollect,
          setCollect: setEnvCollect,
          progress: envProgress,
          isCollectLocked: isEnvLocked,
          setCollectLocked: setEnvLocked,
          reloadProgress: reloadEnvProgress,
          entity: envCollectEntity,
        }
    }
  }

  return (
    <CollectContext.Provider value={providerValue()}>
      {children}
    </CollectContext.Provider>
  )
}

CollectContextProvider.propTypes = {
  children: PropTypes.node,
}

export default CollectContextProvider
