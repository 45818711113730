import React from "react"
import PropTypes from "prop-types"
import { Banner, ButtonGroup, Checkbox, Stack } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const ScopeBannerAtom = ({ confirmed, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Banner status="critical">
      <Stack spacing="loose" vertical>
        <p>{t("dashboard:chart.setup_form.error_scope")}</p>
        <ButtonGroup>
          <Checkbox
            label={t("common:confirm")}
            checked={confirmed}
            onChange={onConfirm}
          />
        </ButtonGroup>
      </Stack>
    </Banner>
  )
}

ScopeBannerAtom.propTypes = {
  confirmed: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
}

export default ScopeBannerAtom
