import React from "react"
import "stylesheets/blocs/organisms/tables/ReportsList.scss"
import { Card, SkeletonBodyText, Stack } from "@shopify/polaris"
import { useReportPageContext } from "views/contexts/ReportPageContext"
import DownloadReportButtonReportAtom from "views/atoms/reports/table/buttons/RefreshReportsButtonReportAtom"
import TableHeaderReportMolecule from "views/molecules/reports/table/TableHeaderReportMolecule"
import TableContentReportMolecule from "views/molecules/reports/table/TableContentReportMolecule"

const TableMarkupReportOrganism = () => {
  const { reports } = useReportPageContext()

  if (!reports) {
    return <SkeletonBodyText />
  } else {
    return (
      <Stack vertical>
        <DownloadReportButtonReportAtom />
        <Card>
          <table className="Verdikt-DynamicTable Verdikt-DynamicTable-center">
            <TableHeaderReportMolecule />
            <tbody>
              <TableContentReportMolecule />
            </tbody>
          </table>
        </Card>
      </Stack>
    )
  }
}

export default TableMarkupReportOrganism
