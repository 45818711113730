import { useEffect } from "react"
import {
  PILLAR_ENVIRONMENTAL,
  PILLARS_OBJECTIVES,
} from "utils/constants/pillars"
import { useHistory, useParams } from "react-router-dom"

const useDefaultPillar = ({
  defaultPillar = PILLAR_ENVIRONMENTAL,
  defaultObjectiveIndex = 0,
}) => {
  const { pillarId, objectiveId } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (pillarId) return

    history.push(defaultPillar)
  }, [])

  useEffect(() => {
    if (!pillarId || objectiveId) return

    const pillarObjectives = PILLARS_OBJECTIVES[pillarId]
    const defaultObjective = pillarObjectives?.[defaultObjectiveIndex]
    history.push(`${pillarId}/${defaultObjective}`)
  }, [pillarId, objectiveId])
}

export default useDefaultPillar
