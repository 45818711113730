import { Layout, Page } from "@shopify/polaris"
import AutofillButton from "components/widgets/CollectPage/AutofillButton"
import PillarsBanners from "components/widgets/CollectPage/PillarsBanners"
import Loading from "components/widgets/Loading"
import NotificationsWidget from "components/widgets/NotificationsWidget"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"
import WebsiteAssessButton from "components/pages/WebsiteAssessButton"
import ObjectivesFrame, {
  CHANGE_TO_OBJECTIVE,
} from "components/widgets/CollectPage/ObjectivesFrame"
import ThemesFrame from "components/widgets/CollectPage/ThemesFrame"
import ThemeFrame from "components/widgets/CollectPage/ThemeFrame"
import Message from "components/widgets/Dashboard/Message"
import CentralizationBannerCollect from "views/molecules/collects/banners/CentralizationBannerCollect"
import "stylesheets/CollectPage.scss"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import FormContextProvider from "../widgets/CollectPage/forms/FormContext"
import CollectStatusBanner from "views/molecules/collects/banners/CollectStatusBanner"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import CollectContextProvider from "../widgets/CollectPage/CollectContextProvider"
import CollectToast from "../widgets/CollectPage/CollectToast"
import { useEntity } from "hooks/useAxios"
import NotificationEntity from "services/entities/NotificationEntity"
import withAuthRequired from "../auth/withAuthRequired"
import useAuth0 from "../auth/useAuth0"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { COLLECT_PAGE } from "hooks/useRedirection"
import usePillars from "views/organisms/pillars/usePillars"
import ProgressBar from "components/widgets/ProgressBar"
import ViewerOnlyBanner from "views/molecules/collects/banners/ViewerOnlyBanner"

const CollectPage = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const {
    currentRootNode,
    isCurrentRootNodeLoading,
    currentNode,
    isCurrentNodeLoading,
    parentOrganization,
    isParentOrganizationLoading,
    isEnvCollectLoading,
    isSocialCollectLoading,
    profitCollectRequest,
    currentPeriod,
    envCollect,
    socialCollect,
    profitCollect,
  } = useAppContext()
  const notificationEntity = useEntity(NotificationEntity)

  const [hasNotifications, setHasNotifications] = useState(false)

  useEffect(async () => {
    const notifications = await notificationEntity.index()
    setHasNotifications(notifications.length >= 1)
  }, [currentNode])

  const isLoading = useCallback(
    () =>
      !isAuthenticated ||
      isEnvCollectLoading ||
      isSocialCollectLoading ||
      profitCollectRequest.isLoading ||
      isParentOrganizationLoading ||
      isCurrentNodeLoading ||
      isCurrentRootNodeLoading,
    [
      isAuthenticated,
      isEnvCollectLoading,
      isSocialCollectLoading,
      profitCollectRequest.isLoading,
      isParentOrganizationLoading,
      isCurrentNodeLoading,
      isCurrentRootNodeLoading,
    ]
  )
  const progressForPillar = (pillar, collect) =>
    collect?.is_editable ? (
      <ProgressBar progress={collect.progress} pillarId={pillar} />
    ) : null

  const pillars = usePillars({
    pageName: COLLECT_PAGE,
    [PILLAR_ENVIRONMENTAL]: {
      disabled: !(isEnvCollectLoading || envCollect),
      progressComponent: progressForPillar(PILLAR_ENVIRONMENTAL, envCollect),
    },
    [PILLAR_SOCIAL]: {
      disabled: !(isSocialCollectLoading || socialCollect),
      progressComponent: progressForPillar(PILLAR_SOCIAL, socialCollect),
    },
    [PILLAR_PROFIT]: {
      disabled: !(profitCollectRequest.isLoading || profitCollect),
      progressComponent: progressForPillar(PILLAR_PROFIT, profitCollect),
    },
  })

  if (isLoading()) {
    return <Loading />
  } else if (currentRootNode && !currentRootNode.has_children) {
    return <Message messageId="no_node_children" show />
  } else if (hasNotifications && isAuthenticated) {
    return (
      <Page narrowWidth title={t("activation:unconfirmed")}>
        <Layout>
          <NotificationsWidget />
        </Layout>
      </Page>
    )
  } else {
    return (
      <CollectContextProvider>
        <ObjectiveNavigationTemplate>
          <Page fullWidth>
            <Layout>
              <NotificationsWidget />
              <PillarsBanners />

              <Layout.Section>
                <BreadcrumbsOrganism
                  parentOrganization={parentOrganization}
                  currentPeriodName={currentPeriod?.name}
                />

                <br />

                <CollectStatusBanner />

                <CentralizationBannerCollect />
                {currentNode?.viewer_only && <ViewerOnlyBanner />}
                <div className="pageLayout">
                  <PillarOrganism pillars={pillars} />
                  <ObjectivesFrame
                    pageName="collect"
                    changeToDefault={CHANGE_TO_OBJECTIVE}
                  >
                    <ThemesFrame pageName="collect">
                      <FormContextProvider>
                        <ThemeFrame />
                      </FormContextProvider>
                    </ThemesFrame>
                  </ObjectivesFrame>
                </div>
              </Layout.Section>

              <AutofillButton />
              <CollectToast />
            </Layout>
            <WebsiteAssessButton />
          </Page>
        </ObjectiveNavigationTemplate>
      </CollectContextProvider>
    )
  }
}

export default withAuthRequired(CollectPage)
