import OrganizationEntity from "services/entities/OrganizationEntity"
import { useCallback, useEffect, useState } from "react"
import { useEntity } from "./useAxios"

const useReassessment = (defaultData, currentNode) => {
  const [data, setData] = useState(defaultData)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorType, setErrorType] = useState()
  const organizationEntity = useEntity(OrganizationEntity)

  useEffect(() => {
    setData({ ...defaultData, organization: !!currentNode?.is_provider })
  }, [currentNode])

  const reassess = useCallback(
    async (successCallback = () => {}) => {
      try {
        setIsLoading(true)
        setIsError(false)
        await organizationEntity.postReassessment(data)
        successCallback()
      } catch (err) {
        setIsError(true)
        console.error(err)
        setErrorType(err?.response?.data)
      } finally {
        setIsLoading(false)
      }
    },
    [data, setIsLoading, setIsError]
  )

  const onChange = useCallback(
    (value, id) => {
      setData({
        ...data,
        [id]: value,
      })
    },
    [data, setData]
  )

  return {
    data,
    onChange,
    reassess,
    isLoading,
    isError,
    errorType,
  }
}

export default useReassessment
