import { TopBar, VisuallyHidden } from "@shopify/polaris"
import LanguageIconAtom from "views/atoms/icons/LanguageIconAtom"
import i18n from "helpers/i18nHelper"
import React from "react"
import _ from "lodash"
import useToggle from "hooks/useToggle"
import i18next from "i18next"
import { useTranslation } from "react-i18next"

const TopBarSecondaryMenu = () => {
  const { t } = useTranslation()
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false)

  const otherLanguage = (currentLanguage) =>
    _.find(["en", "fr"], (l) => l !== currentLanguage)

  const handleLanguageChange = () => {
    i18next.changeLanguage(t("common:language.other")).then((t) => {
      t("key")
    })
    localStorage.setItem("lng", t("common:language.current"))
  }

  return (
    <TopBar.Menu
      activatorContent={
        <span className="topBarCustom__activator">
          <LanguageIconAtom language={i18n.language} />
          <VisuallyHidden>Secondary menu</VisuallyHidden>
        </span>
      }
      open={isMenuOpen}
      onOpen={toggleIsMenuOpen}
      onClose={toggleIsMenuOpen}
      actions={[
        {
          items: [
            {
              content: (
                <LanguageIconAtom language={otherLanguage(i18n.language)} />
              ),
              onAction: handleLanguageChange,
            },
          ],
        },
      ]}
    />
  )
}

TopBarSecondaryMenu.propTypes = {}

export default TopBarSecondaryMenu
