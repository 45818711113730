import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { Modal, Tabs } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import ToastAtom from "views/atoms/forms/ToastAtom"
import {
  TAB_NODE_CENTRALIZATION,
  TAB_NODE_INFORMATION,
} from "utils/constants/tabs/nodesTabContanst"
import NodeFormModalOrganism from "views/organisms/forms/modal/NodeFormModalOrganism"
import { FORM_MODE_CREATE } from "utils/constants/forms/nodeForms"
import { useNodeContext } from "./NodeContext"
import { useAppContext } from "components/AppContext"
import { useHistory } from "react-router-dom"
import CentralizationFormMolecule from "views/molecules/forms/centralizations/CentralizationFormMolecule"

function AddNodeOrganism({
  open,
  onClose,
  reload,
  onSubmitRedirection,
  hideGovernance,
}) {
  const { t } = useTranslation()
  const history = useHistory()

  const { reloadProducts, reloadCurrentRoot } = useAppContext()

  const {
    node,
    save,
    formFieldsToComponents,
    nodeTypeToFormFields,
  } = useNodeContext()

  const [isSaving, setIsSaving] = useState(false)

  const [toastStatus, setToastStatus] = useState(0)
  const [tabSelected, setTabSelected] = useState(TAB_NODE_INFORMATION)

  const onSubmit = async () => {
    try {
      setIsSaving(true)
      await save(FORM_MODE_CREATE)

      await reload()
      onClose()
      reloadProducts()
      reloadCurrentRoot()

      onSubmitRedirection && history.push(onSubmitRedirection)
    } catch (err) {
      // axios error are already handled by the NodeContext
      // so we only throw the error if it's not an axios error
      if (!err.isAxiosError) {
        throw err
      }
    } finally {
      setIsSaving(false)
    }
  }

  const handleTabChange = useCallback(
    (selectedTabIndex) => setTabSelected(selectedTabIndex),
    []
  )

  const displayNodeForm = () => {
    switch (tabSelected) {
      case TAB_NODE_INFORMATION:
        return (
          <NodeFormModalOrganism
            onSubmit={onSubmit}
            mode={FORM_MODE_CREATE}
            disabled={false}
            formFieldsToComponents={formFieldsToComponents}
            nodeTypeToFormFields={nodeTypeToFormFields}
          />
        )

      case TAB_NODE_CENTRALIZATION:
        return <CentralizationFormMolecule />
    }
  }

  const TABS = [
    {
      id: `${TAB_NODE_INFORMATION}`,
      content: t(`dashboard:modals.tabs.information`),
      panelID: "TAB_NODE_INFORMATION",
    },
    !hideGovernance && {
      id: `${TAB_NODE_CENTRALIZATION}`,
      content: t(`dashboard:modals.tabs.centralization`),
      panelID: "TAB_NODE_CENTRALIZATION",
    },
  ].filter(Boolean)

  return (
    <>
      <ToastAtom toastStatus={toastStatus} setToastStatus={setToastStatus} />

      <Modal
        open={open}
        onClose={onClose}
        title={
          t(`activation:${node.type?.toLowerCase()}.modal_title_add`) +
          (node?.parent ? ` (${node?.parent?.label})` : "")
        }
        primaryAction={{
          content: t("common:save"),
          onAction: () => onSubmit(),
          loading: isSaving,
        }}
        secondaryActions={[
          {
            content: t("cancel"),
            onAction: () => onClose(),
          },
        ]}
      >
        <section style={{ height: "500px", overflowY: "hidden" }}>
          <Tabs
            tabs={TABS}
            selected={tabSelected}
            onSelect={handleTabChange}
            fitted
          />
          <section style={{ overflowY: "scroll", height: "475px" }}>
            <Modal.Section>{displayNodeForm()}</Modal.Section>
          </section>
        </section>
      </Modal>
    </>
  )
}

AddNodeOrganism.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reload: PropTypes.func,
  onSubmitRedirection: PropTypes.string,
  hideGovernance: PropTypes.bool,
}

AddNodeOrganism.defaultProps = {
  reload: () => {},
  hideGovernance: false,
}

export default AddNodeOrganism
