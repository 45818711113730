export function createFormDataFromData(data) {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === "lighthouse_report") return
    if (data[key] === null) return

    formData.append(key, data[key] === null ? "" : data[key])
  })

  if (data.lighthouse_report)
    formData.append("lighthouse_report[data]", data.lighthouse_report.data)

  return formData
}
