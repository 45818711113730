import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

const ConditionalLink = ({ to, condition, children }) => {
  if (condition) {
    return (
      <div>
        <Link to={to}>{children}</Link>
      </div>
    )
  } else {
    return children ?? ""
  }
}

ConditionalLink.propTypes = {
  to: PropTypes.string,
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

ConditionalLink.defaultProps = {
  to: "#",
  condition: true,
}

export default ConditionalLink
