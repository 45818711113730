import React from "react"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const CurrentSubscriptionBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge progress="complete" status="success">
      {t("subscription:status.current.badge")}
    </Badge>
  )
}

export default CurrentSubscriptionBadge
