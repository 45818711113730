import React from "react"
import PropTypes from "prop-types"
import { OptionList } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

const MultipleOptionListReportAtom = ({
  id,
  values,
  onChange,
  options,
  errors,
}) => {
  const { t } = useTranslation()

  return (
    <OptionList
      title={t(`share:content.reports_modal.pillars.label`)}
      selected={values}
      onChange={onChange}
      options={options}
      error={errors ? errors[id] : null}
      allowMultiple
    />
  )
}

MultipleOptionListReportAtom.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errors: PropTypes.object,
}

export default MultipleOptionListReportAtom
