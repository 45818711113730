// To use it you need to pass the unit already translate in the dataset with the key "unit"
export function tooltipsLabelWithUnitValue(tooltipItem, data) {
  const dataset = data.datasets[tooltipItem.datasetIndex]

  const { label, unit, dataLabels } = dataset

  const tooltipValue =
    dataset.tooltipValues && dataset.tooltipValues[tooltipItem.index] // use it if you want to show a different value in the tooltip

  const value = tooltipValue || dataset.data[tooltipItem.index]
  const selectedLabel = (dataLabels && dataLabels[tooltipItem.index]) || label

  const unitValue = unit ? `${value} ${unit}` : value

  if (!selectedLabel) return unitValue

  return `${selectedLabel}: ${unitValue}`
}

export const radarTooltips = {
  callbacks: {
    title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    label: (tooltipItem, data) => {
      const { label } = data.datasets[tooltipItem.datasetIndex]
      return `${label} : ${tooltipItem.yLabel}`
    },
  },
}
