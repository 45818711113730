import PropTypes from "prop-types"
import React, { useCallback } from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Config from "assets/configs/score.js"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { HorizontalBar } from "react-chartjs-2"
import { COLORS } from "utils/constants/colors"
import classNames from "classnames"
import { PILLAR_PROFIT } from "utils/constants/pillars"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import NoDashboardDataOrganism from "views/molecules/canvases/charts/profit/NoDashboardDataOrganism"

const KEY_TO_COLORS = {
  external_nogo: COLORS.danger.secondary,
  internal_nogo: COLORS.warning.primary,
  external_go: COLORS.profit.c20,
  internal_go: COLORS.social.c30,
}

const formatter = (value, unit) => (!value ? "" : `${value} ${unit}`)

const horizontalBarOptions = (unit) => ({
  plugins: {
    datalabels: {
      color: COLORS.white,
      formatter: (value) => formatter(value, unit),
      font: {
        weight: "bold",
      },
    },
  },
  legend: {
    position: "bottom",
  },
  tooltips: {
    callbacks: {
      label: ({ value }) => formatter(value, unit),
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        position: "right", // `axis` is determined by the position as `'y'`
        scaleLabel: {
          display: true,
          labelString: unit,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
})

const Objective9Score = ({ score }) => {
  const { t } = useTranslation()

  const chart1 = useCallback(() => {
    const content = {
      kpi: score.odd9_innov_sustainable_percentage,
      kpi_unit: t("score:score.9.chart1.kpi_unit"),
      kpi_text: t("score:score.9.chart1.kpi_text"),
      reference: score.odd9_innov_sustainable_budget_percentage,
      reference_unit: t("score:score.9.chart1.reference_unit"),
      reference_text: t("score:score.9.chart1.reference_text"),
    }

    const contentForDisplay = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart1.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const content = {
      kpi: score.odd9_innov_client_facing_budget_percentage,
      kpi_unit: t("score:score.9.chart2.kpi_unit"),
      kpi_text: t("score:score.9.chart2.kpi_text"),
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart2.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    const odd9_source_and_status_breakdown_chart =
      score.odd9_source_and_status_breakdown_chart
    const data = odd9_source_and_status_breakdown_chart.map((item) => ({
      label: t(`score:score.9.chart3.labels.${item.label_key}`),
      data: item.value,
      plain_value: item.plain_value,
      color: KEY_TO_COLORS[item.label_key],
    }))

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart3.help")}>
        <DoughnutChart title={t("score:score.9.chart3.title")} data={data} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart4 = useCallback(() => {
    const odd9_innovation_split_by_source_chart =
      score.odd9_innovation_split_by_source_chart

    const data = odd9_innovation_split_by_source_chart.map((item) => ({
      label: t(`score:score.9.chart4.labels.${item.label_key}`),
      data: item.value,
    }))

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart4.help")}>
        <DoughnutChart title={t("score:score.9.chart4.title")} data={data} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart5 = useCallback(() => {
    const content = {
      kpi: score.odd9_innov_not_client_facing_count,
      kpi_unit: t("score:score.9.chart5.kpi_unit"),
      kpi_text: t("score:score.9.chart5.kpi_text"),
      sub_kpi: score.odd9_innov_not_client_facing_workload_sum,
      sub_kpi_unit: `${t("score:score.9.chart5.sub_kpi_unit")} ${t(
        "score:score.9.chart5.sub_kpi_text"
      )}`,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart5.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const {
      sustainable_budgets,
      not_sustainable_budgets,
      label_keys,
      unit_key,
    } = score.odd9_innovation_budget_breakdown_per_status_chart

    const title = t("score:score.9.chart6.title")

    const unit = t(`units:units.money.${unit_key}`)

    const labels = label_keys.map((key) =>
      t(`score:score.9.chart6.labels.${key}`)
    )

    const data = [
      {
        label: t("score:score.9.chart6.labels.sustainable"),
        data: sustainable_budgets,
        stack: 0,
        backgroundColor: Config.common.colors[4],
      },
      {
        label: t("score:score.9.chart6.labels.not_sustainable"),
        data: not_sustainable_budgets,
        stack: 0,
        backgroundColor: Config.common.colorsEvaluation.red,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart6.help")}>
        <div
          className={classNames(
            "Verdikt-Score-HorizontalBarChart",
            PILLAR_PROFIT
          )}
        >
          <h1>{title}</h1>
          <div className="content">
            <HorizontalBar
              data={{ labels: labels, datasets: data }}
              options={horizontalBarOptions(unit)}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  if (score.inventories.innovations === 0)
    return (
      <NoDashboardDataOrganism
        maturity={score.odd9_maturity}
        description={t("score:score.9.no_innovations")}
      />
    )
  return (
    <ScoreLayoutOrganism
      maturity={score.odd9_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective9Score.propTypes = {
  score: PropTypes.object.isRequired,
}

export default Objective9Score
