export const yAxes = (value) => {
  return {
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: value,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
}
