import React from "react"
import { useAppContext } from "components/AppContext"
import AnalyzeHistoryTemplate from "views/templates/AnalyzeHistoryTemplate"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import { PILLAR_SOCIAL } from "utils/constants/pillars"
import CompareKpiMolecule from "views/molecules/organization/kpis/CompareKpiMolecule"
import { compareKpiProps } from "views/organisms/canvases/charts/analyzes/historization/HistoryPropTypes"
import MixedChart, {
  dataLabelsWithUnits,
} from "components/widgets/ScorePage/widgets/MixedChart"
import { COLORS } from "utils/constants/colors"

const EMPTY_LINE_LEGEND = [
  (legendItem) => (legendItem.fillStyle = COLORS.profit.c10),
]

const DATA_LABELS_WITH_PERCENTAGE_UNIT = dataLabelsWithUnits("%")

const ProfitHistoryAnalyzeOrganism = ({ data, pillars }) => {
  const { currentPeriod, parentOrganization } = useAppContext()

  const { t } = useTranslation()
  const transSuffix = "analyze:historization.profit."

  return (
    <AnalyzeHistoryTemplate
      periodName={currentPeriod?.name}
      parentOrganization={parentOrganization}
      pillars={pillars}
    >
      <ITDomainAnalysisLayoutOrganism
        maturity={data.score}
        pillar={PILLAR_SOCIAL}
        transSuffix={transSuffix}
        chart1={
          <CompareKpiMolecule
            title={t(transSuffix + "kpi.cdr_maturity.title")}
            current={data.cdr_maturity_evolution.value}
            difference={data.cdr_maturity_evolution.difference}
            status={data.cdr_maturity_evolution.status}
          />
        }
        chart2={
          <CompareKpiMolecule
            freeText={t(transSuffix + "kpi.projects_eco_designed.title")}
            current={data.projects_eco_designed_percentage_evolution.value}
            difference={
              data.projects_eco_designed_percentage_evolution.difference
            }
            status={data.projects_eco_designed_percentage_evolution.status}
            unit="%"
          />
        }
        chart3={
          <MixedChart
            title={t(`${transSuffix}chart3.title`)}
            data_inputs={{
              bar_data: {
                label: t(`${transSuffix}chart3.bar`),
                data: data.projects_evolution.bar,
              },
              line_data: {
                label: t(`${transSuffix}chart3.line`),
                data: data.projects_evolution.line,
              },
            }}
            labels={data.projects_evolution.label}
            unit_left_axis="k€"
            unit_right_axis="%"
            config={DATA_LABELS_WITH_PERCENTAGE_UNIT}
            legendModifiers={EMPTY_LINE_LEGEND}
          />
        }
        chart4={
          <MixedChart
            title={t(`${transSuffix}chart4.title`)}
            data_inputs={{
              bar_data: {
                label: t(`${transSuffix}chart4.bar`),
                data: data.budgets_evolution.bar,
              },
              line_data: {
                label: t(`${transSuffix}chart4.line`),
                data: data.budgets_evolution.line,
              },
            }}
            labels={data.budgets_evolution.label}
            unit_left_axis="k€"
            unit_right_axis="%"
            config={DATA_LABELS_WITH_PERCENTAGE_UNIT}
            legendModifiers={EMPTY_LINE_LEGEND}
          />
        }
        chart5={
          <CompareKpiMolecule
            freeText={t(transSuffix + "kpi.innov_sustainable.title")}
            current={data.innov_sustainable_percentage_evolution.value}
            difference={data.innov_sustainable_percentage_evolution.difference}
            status={data.innov_sustainable_percentage_evolution.status}
            unit="%"
          />
        }
        chart6={
          <MixedChart
            title={t(`${transSuffix}chart6.title`)}
            data_inputs={{
              bar_data: {
                label: t(`${transSuffix}chart6.bar`),
                data: data.partners_evolution.bar,
              },
              line_data: {
                label: t(`${transSuffix}chart6.line`),
                data: data.partners_evolution.line,
              },
            }}
            labels={data.partners_evolution.label}
            unit_left_axis="%"
            unit_right_axis="%"
            config={DATA_LABELS_WITH_PERCENTAGE_UNIT}
            legendModifiers={EMPTY_LINE_LEGEND}
          />
        }
      />
    </AnalyzeHistoryTemplate>
  )
}

ProfitHistoryAnalyzeOrganism.propTypes = {
  data: PropTypes.shape({
    score: PropTypes.number.isRequired,
    cdr_maturity_evolution: compareKpiProps.isRequired,
    projects_eco_designed_percentage_evolution: compareKpiProps.isRequired,
    innov_sustainable_percentage_evolution: compareKpiProps.isRequired,
    projects_evolution: PropTypes.object.isRequired,
    budgets_evolution: PropTypes.object.isRequired,
    partners_evolution: PropTypes.object.isRequired,
  }).isRequired,
  pillars: PropTypes.array.isRequired,
}

export default ProfitHistoryAnalyzeOrganism
