import React from "react"
import PropTypes from "prop-types"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS } from "services/units/units"
import { T_UNIT } from "utils/constants/numbers"
import { round } from "services/units/round"

const TRANSLATION_PREFIX = "analyze:it_domain.cloud.kpis.service"

const TextAnalysisCloudService = ({ serviceName, value, percentage }) => {
  const { t } = useTranslation()

  const { unit, value: convertedValue } = convertValueToReadable(
    value,
    T_UNIT,
    CO2_EMISSION_UNITS,
    round
  )

  const translatedUnit = t(`units:units.emission.${unit}`)

  const content = {
    kpi: serviceName,
    kpi_text: t(`${TRANSLATION_PREFIX}.title`),
    sub_kpi: `${convertedValue} ${translatedUnit} | ${percentage}%`,
  }

  return (
    <HelpWidgetChartInteractiveMolecule text={t(`${TRANSLATION_PREFIX}.help`)}>
      <TextChart
        content={<TextChartMinor content={content} noSubtextHover={true} />}
      />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextAnalysisCloudService.propTypes = {
  serviceName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
}

export default TextAnalysisCloudService
