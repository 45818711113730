import React, { useState } from "react"
import { Modal } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { useReportPageContext } from "views/contexts/ReportPageContext"
import ReportEntity from "services/entities/ReportEntity"
import BannerDispatcherReportOrganism from "views/molecules/reports/BannerDispatcherReportMolecule"
import FormCreateReportMolecule from "views/molecules/reports/form/FormCreateReportMolecule"
import { useEntity } from "../../../hooks/useAxios"

const ModalMarkupReportOrganism = () => {
  const { t } = useTranslation()

  const {
    setReportRequestResponseBanner,
    reportModalOpen,
    setReportModalOpen,
    reportsReloading,
    setReportsReloading,
    setWorkInProgressBanner,
  } = useReportPageContext()
  const reportEntity = useEntity(ReportEntity)

  const [errors, setErrors] = useState({})
  const [reportSending, setReportSending] = useState(false)
  const [reportFormData, setReportFormData] = useState({})

  const createReport = async () => {
    try {
      setReportSending(true)
      setReportRequestResponseBanner({})
      await reportEntity.createReport(reportFormData)
      setReportModalOpen(!reportModalOpen)
      setReportSending(false)
      setErrors({})
      setReportsReloading(!reportsReloading)
      setWorkInProgressBanner(true)
    } catch (err) {
      setReportSending(false)
      setErrors(err.response.data)
      setReportRequestResponseBanner(err.response.data)
    } finally {
      //
    }
  }

  const reportModalClose = () => {
    setReportFormData({})
    setErrors({})
    setReportModalOpen(false)
  }

  const title = t("share:content.reports_modal.add")

  return (
    <Modal
      open={reportModalOpen}
      onClose={() => reportModalClose()}
      title={title}
      primaryAction={{
        content: t("common:generate"),
        onAction: createReport,
        loading: reportSending,
      }}
    >
      <BannerDispatcherReportOrganism />
      <Modal.Section>
        <FormCreateReportMolecule
          createReport={createReport}
          setReportFormData={setReportFormData}
          errors={errors}
        />
      </Modal.Section>
    </Modal>
  )
}

export default ModalMarkupReportOrganism
