import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import Staff from "./Objective10Staff"
import Recruitment from "./Objective10Recruitment"
import Inclusion from "./Objective10Inclusion"
import Diversity from "./Objective10Diversity"

export default () => {
  const { themeId } = useParams()
  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "staff":
        return <Staff />
      case "recruitment":
        return <Recruitment />
      case "inclusion":
        return <Inclusion />
      case "diversity":
        return <Diversity />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])
  return themeFormMarkup()
}
