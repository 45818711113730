const baseUrl = "/api/user/state"

const UserStateEntity = (backClient) => ({
  getCurrentNode: async () => await backClient.get(`${baseUrl}/current_node`),
  setCurrentNode: async (id) =>
    await backClient.post(`${baseUrl}/current_node`, { id }),
  getCurrentRoot: async () => await backClient.get(`${baseUrl}/current_root`),
  setCurrentRoot: async (id) =>
    await backClient.post(`${baseUrl}/current_root`, { id }),
  resetCurrentRoot: async () =>
    await backClient.post(`${baseUrl}/current_root/reset`, {}),
  getOrganization: async () =>
    await backClient.get(`${baseUrl}/current_organization`),
  setOrganization: async (id) =>
    await backClient.post(`${baseUrl}/current_organization`, { id }),
  resetOrganization: async () =>
    await backClient.post(`${baseUrl}/current_organization/reset`, {}),
})

export default UserStateEntity
