import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import "stylesheets/HelpPage/Question.scss"

function Question({
  id,
  questionId,
  setQuestionId,
  sectionLabel,
  questionLabel,
  toggleAnswer,
}) {
  const { t } = useTranslation()

  /**
   * @description Sends the id of the clicked question to the parent by updating the parent's 'questionId' state
   */
  function handleClick() {
    if (id == questionId) handleClose()
    //if the question was already toggled we close it
    else setQuestionId(id)
  }

  function handleClose() {
    setQuestionId("")
  }

  return (
    <div
      className="q-and-a"
      key={t(`help:faq.${sectionLabel}.${questionLabel}`)}
    >
      <p
        className={`question ${toggleAnswer ? "toggled" : ""}`}
        key={t(`help:faq.${sectionLabel}.${questionLabel}.question`)}
        onClick={handleClick}
        size="medium"
      >
        {t(`help:faq.${sectionLabel}.${questionLabel}.question`)}
      </p>
      {toggleAnswer ? (
        <p
          className="answer"
          key={t(`help:faq.${sectionLabel}.${questionLabel}.answer`)}
        >
          {t(`help:faq.${sectionLabel}.${questionLabel}.answer`)}
        </p>
      ) : null}
    </div>
  )
}

Question.propTypes = {
  id: PropTypes.string,
  questionId: PropTypes.string,
  questionLabel: PropTypes.string,
  sectionLabel: PropTypes.string,
  setQuestionId: PropTypes.func,
  toggleAnswer: PropTypes.bool,
}

export default Question
