const InventoryRatioEntity = (backClient) => ({
  getStatus: async (inventoryType) => {
    const { data } = await backClient.get(
      `/api/uploads/collects/ratios/${inventoryType}/status`
    )
    return data
  },
})

export default InventoryRatioEntity
