import React from "react"
import { NODE_TYPE_COMPANY } from "utils/constants/organization/nodeTypes"
import { NodeContextProvider } from "views/organisms/interactive/NodeContext"
import {
  CREATE_PROVIDER_NODE_TYPE_TO_FORM_FIELDS,
  FORM_FIELDS_TO_COMPONENTS,
} from "utils/constants/forms/nodeForms"
import {
  LIGHT_DATASET,
  PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET,
} from "utils/constants/forms/nodeFormDataset"
import AddNodeOrganism from "views/organisms/interactive/AddNodeOrganism"
import useToggle from "hooks/useToggle"
import { Button, Tooltip } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { PlusMinor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"

const LightOrganizationOrganism = ({ disabled }) => {
  const { t } = useTranslation()

  const [toggled, onToggle] = useToggle(false)

  const lightCompany = {
    type: NODE_TYPE_COMPANY,
    ...LIGHT_DATASET,
  }

  const AddButton = () => (
    <Button onClick={onToggle} icon={PlusMinor} outline disabled={disabled}>
      {t(`activation:${lightCompany.type}.modal_title_add`)}
    </Button>
  )

  const DisabledButton = () => (
    <Tooltip
      content={t("organization:max_tree_exceeded.text")}
      dismissOnMouseOut
    >
      <AddButton />
    </Tooltip>
  )

  return (
    <>
      {disabled ? <DisabledButton /> : <AddButton />}

      <NodeContextProvider
        nodeTypeToFormFields={CREATE_PROVIDER_NODE_TYPE_TO_FORM_FIELDS}
        formFieldsToComponents={FORM_FIELDS_TO_COMPONENTS}
        defaultNode={lightCompany}
        defaultDatasets={PROVIDER_NODE_TYPE_TO_DEFAULT_DATASET}
      >
        <AddNodeOrganism
          open={toggled}
          onClose={onToggle}
          onSubmitRedirection="/dashboard"
          hideGovernance
        />
      </NodeContextProvider>
    </>
  )
}

LightOrganizationOrganism.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default LightOrganizationOrganism
