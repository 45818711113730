import PropTypes from "prop-types"
import React, { useCallback } from "react"
import DoughnutChart from "components/widgets/ScorePage/widgets/DoughnutChart"
import { useTranslation } from "react-i18next"
import prepareDoughnutChartData from "utils/functions/charts/DoughnutChartFunctions"

const ChartODD13RegionNetworkBreakdownMolecule = ({ score }) => {
  const { t } = useTranslation()

  const generateData = useCallback(
    () =>
      prepareDoughnutChartData(
        score?.odd13_network_co2_top?.doughnut_charts,
        score?.odd13_network_co2_top?.doughnut_charts_data_total
      ),
    [score]
  )

  const generatedData = generateData()

  return (
    <DoughnutChart
      title={t(`score:score.13.chartRegionNetworkBreakdown.title`)}
      centerText={generatedData.formatedTotal}
      centerTextUnit={generatedData.totalUnit}
      data={generatedData.chartsData}
      large={false}
    />
  )
}

ChartODD13RegionNetworkBreakdownMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartODD13RegionNetworkBreakdownMolecule
