import React from "react"
import PropTypes from "prop-types"
import "stylesheets/blocs/molecules/charts/ParentChartMolecule.scss"
import { PILLARS } from "utils/constants/pillars"
import classNames from "classnames"

const ParentChartMolecule = ({ title, children, pillarId }) => {
  return (
    <article className={classNames("parentChartMolecule", pillarId)}>
      <h1 className="parentChartMolecule__title">{title}</h1>
      {children}
    </article>
  )
}

ParentChartMolecule.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
}

export default ParentChartMolecule
