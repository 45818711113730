import PropTypes from "prop-types"
import Loading from "components/widgets/Loading"
import React, { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Modal, Page } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import { TextChart } from "components/widgets/ScorePage/widgets/TextCharts"
import MixedChart from "components/widgets/ScorePage/widgets/MixedChart"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import classNames from "classnames"
import NodeOrganization from "services/organizations/NodeOrganization"
import withAuthRequired from "../../../auth/withAuthRequired"
import { useEntity } from "../../../../hooks/useAxios"
import ScoreEntity from "../../../../services/entities/ScoreEntity"
import useToggle from "../../../../hooks/useToggle"

const WebsiteEnvScorePage = ({ score, translationScope }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13.chartGHGWebsitesEmissions."
  const [isModalOpen, toggleModalOpen] = useToggle()
  const [isWebsiteScoresLoading, setIsWebsiteScoresLoading] = useState(true)
  const [websiteScores, setWebsiteScores] = useState({})
  const { pillarId } = useParams()
  const scoreEntity = useEntity(ScoreEntity)

  useEffect(() => {
    scoreEntity
      .websiteScores()
      .then((response) => {
        setWebsiteScores(response.data)
      })
      .finally(() => {
        setIsWebsiteScoresLoading(false)
      })
  }, [])

  const chartQuantitativeCO2Impacts = useCallback(() => {
    let labels = []

    let bar = []
    let line = []

    if (websiteScores.length == 0) return

    websiteScores.forEach((websiteScore) => {
      labels.push(websiteScore.url.split("/")[2])
      websiteScore.ghg_period_CO2 == null
        ? bar.push(0)
        : bar.push(websiteScore.ghg_period_CO2)
      websiteScore.ghg_average_visit_CO2 == null
        ? line.push(0)
        : line.push(websiteScore.ghg_average_visit_CO2)
    })

    const bar_data = {
      label: t(
        translationPrefix + "modal.chartQuantitativeCO2Impacts.legend.bar"
      ),
      data: bar,
    }

    const line_data = {
      label: t(
        translationPrefix + "modal.chartQuantitativeCO2Impacts.legend.line"
      ),
      data: line,
    }

    const data = { bar_data, line_data }

    return (
      <div id="widget4" className={classNames(pillarId, "widget", "chart")}>
        <MixedChart
          title={t(
            translationPrefix + "modal.chartQuantitativeCO2Impacts.title"
          )}
          data_inputs={data}
          labels={labels}
          unit_left_axis={t(
            translationPrefix +
              "modal.chartQuantitativeCO2Impacts.unit_left_axis"
          )}
          unit_right_axis={t(
            translationPrefix +
              "modal.chartQuantitativeCO2Impacts.unit_right_axis"
          )}
        />
      </div>
    )
  }, [websiteScores])

  const chartMaturityScores = useCallback(() => {
    let datas = []
    let category_labels = [
      t(translationPrefix + "modal.chartMaturityScores.legend.ghg_maturity"),
      t(translationPrefix + "modal.chartMaturityScores.legend.bp_maturity"),
      t(
        translationPrefix +
          "modal.chartMaturityScores.legend.green_hosting_maturity"
      ),
      t(translationPrefix + "modal.chartMaturityScores.legend.seo_maturity"),
      t(
        translationPrefix +
          "modal.chartMaturityScores.legend.accessibility_maturity"
      ),
    ]

    if (websiteScores.length == 0) return

    let datasets = []
    websiteScores.forEach((websiteScore) => {
      datas.push(
        websiteScore.ghg_maturity == null ? 0 : websiteScore.ghg_maturity
      )
      datas.push(
        websiteScore.bp_maturity == null ? 0 : websiteScore.bp_maturity
      )
      datas.push(
        websiteScore.green_hosting_maturity == null
          ? 0
          : websiteScore.green_hosting_maturity
      )
      datas.push(
        websiteScore.seo_maturity == null ? 0 : websiteScore.seo_maturity
      )
      datas.push(
        websiteScore.accessibility_maturity == null
          ? 0
          : websiteScore.accessibility_maturity
      )

      let object = {
        label: websiteScore.url.split("/")[2],
        data: { datas },
      }
      datasets.push(object)
      datas = []
    })

    const data = [
      {
        labels: category_labels,
        datasets: datasets,
      },
    ]

    return (
      <div id="widget4" className={classNames(pillarId, "widget", "chart")}>
        <RadarChart
          title={t(translationPrefix + "modal.chartMaturityScores.title")}
          data={data}
          config={{ legend: { position: "right" } }}
        />
      </div>
    )
  }, [websiteScores])

  const modalMarkup = () => {
    return (
      <Modal
        title={t(translationPrefix + "modal.title")}
        open={isModalOpen}
        onClose={toggleModalOpen}
      >
        <div className="environmental">
          <Modal.Section>
            <div className="Verdikt-Score">
              <Page>
                <div className="page-frame">
                  <div className="page">
                    <div className="right">
                      {chartMaturityScores()}
                      {chartQuantitativeCO2Impacts()}
                    </div>
                  </div>
                </div>
              </Page>
            </div>
          </Modal.Section>
        </div>
      </Modal>
    )
  }

  const chartMarkup = () => {
    let CO2_software_websites = score?.odd13_software_CO2_websites

    if (!CO2_software_websites || CO2_software_websites == 0)
      CO2_software_websites = "< 0"

    const content = {
      kpi: CO2_software_websites,
      kpi_unit: t("score:score.13.t_unit"),
      kpi_text: t(translationPrefix + "text"),
    }

    if (
      translationScope &&
      translationScope != "global" &&
      score?.odd13_global_node_parent_score_defined
    ) {
      const odd13_software_co2_websites_share_vs_company =
        score?.odd13_software_co2_websites_share_vs_company ?? 0

      content.reference = odd13_software_co2_websites_share_vs_company?.percent
      content.reference_unit = t(translationPrefix + "unit")
      content.reference_text = NodeOrganization.getName(
        odd13_software_co2_websites_share_vs_company?.name,
        odd13_software_co2_websites_share_vs_company?.type
      )
    }

    const content_for_display = (
      <div>
        <p>{content.kpi_text}</p>
        <p className="em">
          {content.kpi}&nbsp;{content.kpi_unit}
        </p>
        <p>
          <br />
          {content.reference}
          {content.reference_unit}&nbsp;
          {content.reference_text}
        </p>
        <br />
        <p className="light">
          🔍{" "}
          {
            <Link
              onClick={() => {
                toggleModalOpen()
              }}
            >
              {t(translationPrefix + "modal.activation")}
            </Link>
          }
        </p>
      </div>
    )
    return <TextChart content={content_for_display} />
  }

  if (isWebsiteScoresLoading) return <Loading />
  else
    return (
      <div className="Verdikt-Score-TextChart">
        <div className="Verdikt-Score-TextChart-Content">
          {modalMarkup()}
          {chartMarkup()}
        </div>
      </div>
    )
}

WebsiteEnvScorePage.propTypes = {
  score: PropTypes.object,
  translationScope: PropTypes.string,
}

export default withAuthRequired(WebsiteEnvScorePage)
