import React from "react"
import { Banner, Layout, Page } from "@shopify/polaris"
import { useParams } from "react-router-dom"
import Loading from "components/widgets/Loading"
import { useTranslation } from "react-i18next"
import Message from "components/widgets/Dashboard/Message"
import BreadcrumbsOrganism from "views/organisms/commons/breadcrumbs/BreadcrumbsOrganism"
import { useAppContext } from "components/AppContext"
import PillarOrganism from "views/organisms/pillars/PillarOrganism"
import ThemeLayoutOrganism from "views/organisms/themes/ThemeLayoutOrganism"
import TabsObjectiveMolecule from "views/organisms/objectives/TabsObjectiveOrganism"
import SDGsObjectiveOrganism from "views/organisms/objectives/SDGsObjectiveOrganism"
import { PILLAR_PROFIT, PILLAR_SOCIAL } from "utils/constants/pillars"
import RecommendationsGhgLayout from "components/widgets/ImprovePage/RecommendationsGHGLayout"
import useRequest from "hooks/useRequest"
import PropositionEntity from "services/entities/PropositionEntity"
import { useEntity } from "../../hooks/useAxios"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import _ from "lodash"
import usePillars from "views/organisms/pillars/usePillars"

const BASE_URL = "/improve/ghg"

const ImproveGHGPage = () => {
  const { pillar, objective, theme } = useParams()
  const { t } = useTranslation()
  const propositionEntity = useEntity(PropositionEntity)

  const { data: recommendations } = useRequest(
    () => propositionEntity.index({ pillar, objective, theme }),
    [pillar, objective, theme],
    { defaultValue: [] }
  )

  const {
    currentPeriod,
    isCurrentNodeLoading,
    currentRootNode,
    isCurrentRootNodeLoading,
    parentOrganization,
    isParentOrganizationLoading,
    score,
  } = useAppContext()

  const pillars = usePillars({
    pageName: BASE_URL,
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: `${BASE_URL}/environmental/13`,
    },
  ]

  const tabs = [
    "workplace",
    "internal_hosting",
    "external_hosting",
    "network",
  ].map((domain) => ({
    name: t(`improve:ghg.tabs.${domain}`),
    selected: theme === domain,
    url: `${BASE_URL}/environmental/13/${domain}`,
  }))

  if (currentRootNode && !currentRootNode.has_children) {
    return <Message messageId="no_node_children" show />
  }

  const getActualConsumption = () =>
    _.sumBy(recommendations, "current_emissions")

  return !currentPeriod ||
    isCurrentNodeLoading ||
    isCurrentRootNodeLoading ||
    isParentOrganizationLoading ? (
    <Loading />
  ) : !score ? (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner
            title={t(`improve:messages.empty.title`)}
            action={{
              content: t(`improve:messages.empty.cta`),
              url: "/collect",
            }}
          >
            {t(`improve:messages.empty.message`)}
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    <Page fullWidth>
      <Layout.Section>
        <BreadcrumbsOrganism
          parentOrganization={parentOrganization}
          currentPeriodName={currentPeriod?.name}
        />
      </Layout.Section>

      <Layout.Section>
        <PillarOrganism pillars={pillars} />

        <ThemeLayoutOrganism
          tabs={<TabsObjectiveMolecule tabs={tabs} themeType={pillar} />}
          objectives={
            <SDGsObjectiveOrganism
              objectives={objectives}
              themeType={"environmental"}
            />
          }
        >
          <RecommendationsGhgLayout
            kpiComponent={
              <div className="recommendationsLayout--kpi">
                <div>
                  {
                    NumberFormatterApp.getNumberFormatter(
                      getActualConsumption(),
                      "emission",
                      "kt_unit"
                    )[0]
                  }
                </div>
                <div>
                  {t(
                    `units:units.emission.${
                      NumberFormatterApp.getNumberFormatter(
                        getActualConsumption(),
                        "emission",
                        "kt_unit"
                      )[1]
                    }`
                  )}
                </div>
              </div>
            }
            headingText={t(`improve:heading.synthesis.theme`)}
            recommendations={recommendations}
            theme={theme}
            objective={objective}
          />
        </ThemeLayoutOrganism>
      </Layout.Section>
    </Page>
  )
}

export default ImproveGHGPage
