import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import { ExternalLabel, TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import SocialPeopleInventory from "../../inventories/social/SocialPeopleInventory"
import { FormLayout } from "@shopify/polaris"
import { useFormConfig, useFormContext } from "../../FormContext"
import useNullifyingField from "../../../useNullifyingField"

const fields = [
  "staff_managerial_employee_rate",
  "staff_indefinite_term_contract_rate",
  "staff_fixed_term_contract_rate",
  "staff_work_study_rate",
  "staff_trainee_rate",
  "staff_average_age",
  "upload_detailed_inventory",
]
const translationPath = "10.staff"

const Staff = () => {
  const { t } = useTranslation()
  const { formData, setFormData, setErrors } = useFormContext()
  const [disabled, setDisabled] = useState(false)

  useFormConfig({ fields, translationPath })

  useEffect(() => setDisabled(formData.upload_detailed_inventory), [formData])

  useEffect(() => {
    if (
      formData.staff_indefinite_term_contract_rate &&
      formData.staff_fixed_term_contract_rate &&
      formData.staff_work_study_rate &&
      formData.staff_trainee_rate
    ) {
      let sum =
        Number(formData.staff_indefinite_term_contract_rate) +
        Number(formData.staff_fixed_term_contract_rate) +
        Number(formData.staff_work_study_rate) +
        Number(formData.staff_trainee_rate)
      if (sum !== 100) {
        let error_wording = "collect:questions.common.error_sum"
        let error = {
          staff_indefinite_term_contract_rate: [t(error_wording)],
          staff_fixed_term_contract_rate: [t(error_wording)],
          staff_work_study_rate: [t(error_wording)],
          staff_trainee_rate: [t(error_wording)],
        }
        setErrors(error)
      } else {
        setErrors({
          staff_indefinite_term_contract_rate: null,
          staff_fixed_term_contract_rate: null,
          staff_work_study_rate: null,
          staff_trainee_rate: null,
        })
      }
    }
  }, [formData])

  useNullifyingField("upload_detailed_inventory", [
    "staff_managerial_employee_rate",
    "staff_indefinite_term_contract_rate",
    "staff_fixed_term_contract_rate",
    "staff_work_study_rate",
    "staff_trainee_rate",
    "staff_average_age",
  ])

  useEffect(() => {
    if (
      formData.staff_indefinite_term_contract_rate &&
      formData.staff_fixed_term_contract_rate &&
      formData.staff_work_study_rate
    ) {
      const staff_trainee_rate =
        100 -
        (+formData.staff_indefinite_term_contract_rate +
          +formData.staff_fixed_term_contract_rate +
          +formData.staff_work_study_rate)
      setFormData({ ...formData, staff_trainee_rate })
    }
  }, [
    formData?.staff_indefinite_term_contract_rate,
    formData?.staff_fixed_term_contract_rate,
    formData?.staff_work_study_rate,
  ])

  const tableMarkup = () => {
    return (
      <table style={{ tableLayout: "fixed" }}>
        <tbody>
          <tr>
            <td>{t("collect:questions.10.staff.table.row_1")}</td>
            <td>
              <TextField
                id="staff_indefinite_term_contract_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.staff.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.staff.table.row_2")}</td>
            <td>
              <TextField
                id="staff_fixed_term_contract_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.staff.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.staff.table.row_3")}</td>
            <td>
              <TextField
                id="staff_work_study_rate"
                type="number"
                disabled={disabled}
                suffix={t("collect:questions.10.staff.table.suffix")}
              />
            </td>
          </tr>
          <tr>
            <td>{t("collect:questions.10.staff.table.row_4")}</td>
            <td>
              <TextField
                id="staff_trainee_rate"
                type="number"
                disabled
                suffix={t("collect:questions.10.staff.table.suffix")}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Form>
      <div className="columns">
        <div className="column">
          <SocialPeopleInventory />
        </div>
        <div className="column column-23">
          <FormLayout>
            <TextField
              id="staff_managerial_employee_rate"
              type="number"
              disabled={disabled}
            />
            <ExternalLabel translationPathName={"table"} />
            {tableMarkup()}
            <TextField
              id="staff_average_age"
              disabled={disabled}
              type="number"
            />
          </FormLayout>
        </div>
      </div>
    </Form>
  )
}

export default Staff
