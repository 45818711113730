import { useTranslation } from "react-i18next"
import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import { Select, TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import { Module } from "../../Module"
import { ELECTRICITY_CONSUMPTION_MODULE } from "utils/constants/collects/modules"
import { useFormConfig, useFormContext } from "../../FormContext"
import { useAppContext } from "components/AppContext"

const fields = [
  "energy_consumption_period_consumption",
  "energy_consumption_IT",
  "energy_consumption_IT_DC",
  "energy_consumption_IT_unit",
  "energy_consumption_IT_DC_unit",
  "energy_consumption_reuse_ratio",
]
const translationPath = "7.energy_consumption"

const EnergyConsumptionForm = () => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  const { envCollect } = useAppContext()
  useFormConfig({ fields, translationPath })

  const generateTableMarkUp = useCallback(
    (id) => {
      let id_unit = id + "_unit"
      let title = `collect:questions.${translationPath}.${id}_choice.label`
      const warning_id = id + "_warning"

      return (
        <FormLayout.Group title={t(title)}>
          <TextField
            id={id}
            type="number"
            warning={envCollect[warning_id] ? envCollect[warning_id] : null}
          />
          <Select
            id={id_unit}
            value={String(formData?.id_unit ?? "energy_conso")}
            options={[
              {
                label: t(
                  `collect:questions.7.energy_consumption.input_unit.energy_conso`
                ),
                value: "energy_conso",
              },
              {
                label: t(
                  `collect:questions.7.energy_consumption.input_unit.percentage`
                ),
                value: "percentage",
              },
            ]}
          />
        </FormLayout.Group>
      )
    },
    [formData, envCollect]
  )

  return (
    <Form>
      <Module module={ELECTRICITY_CONSUMPTION_MODULE}>
        <FormLayout>
          <TextField id="energy_consumption_period_consumption" type="number" />
          {generateTableMarkUp("energy_consumption_IT")}
          {generateTableMarkUp("energy_consumption_IT_DC")}
          <TextField id="energy_consumption_reuse_ratio" type="number" />
        </FormLayout>
      </Module>
    </Form>
  )
}

export default EnergyConsumptionForm
