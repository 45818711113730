import { useTranslation } from "react-i18next"
import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import { Select, TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import { Module } from "../../Module"
import { ELECTRICITY_CONSUMPTION_MODULE } from "utils/constants/collects/modules"
import { useFormConfig, useFormContext } from "../../FormContext"

const fields = [
  "energy_green",
  "energy_green_unit",
  "energy_green_type",
  "energy_green_IT",
  "energy_green_IT_unit",
  "energy_green_provider",
]
const translationPath = "7.energy_green"

const EnergyGreenForm = () => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  useFormConfig({ fields, translationPath })

  const generateTableMarkUp = useCallback(
    (id) => {
      let id_unit = id + "_unit"
      let title = `collect:questions.${translationPath}.${id}_choice.label`

      return (
        <FormLayout.Group title={t(title)}>
          <TextField id={id} type="number" />
          <Select
            id={id_unit}
            value={String(formData?.id_unit ?? "energy_conso")}
            options={[
              {
                label: t(
                  `collect:questions.7.energy_consumption.input_unit.energy_conso`
                ),
                value: "energy_conso",
              },
              {
                label: t(
                  `collect:questions.7.energy_consumption.input_unit.percentage`
                ),
                value: "percentage",
              },
            ]}
          />
        </FormLayout.Group>
      )
    },
    [formData]
  )

  return (
    <Form>
      <Module module={ELECTRICITY_CONSUMPTION_MODULE}>
        <FormLayout>
          {generateTableMarkUp("energy_green")}
          <Select
            id="energy_green_type"
            options={[
              { label: t("collect:questions.common.none"), value: "0" },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_type.options.solar"
                ),
                value: "1",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_type.options.geothermics"
                ),
                value: "2",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_type.options.wind"
                ),
                value: "3",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_type.options.hydraulic"
                ),
                value: "4",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_type.options.biomass"
                ),
                value: "5",
              },
            ]}
          />
          <Select
            id="energy_green_provider"
            options={[
              { label: t("collect:questions.common.none"), value: "0" },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.enercoop"
                ),
                value: "1",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.planet"
                ),
                value: "2",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.urban"
                ),
                value: "3",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.ilek"
                ),
                value: "4",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.plum"
                ),
                value: "5",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.mint"
                ),
                value: "6",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.ekwateur"
                ),
                value: "7",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.GEG"
                ),
                value: "8",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.alterna"
                ),
                value: "9",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.selia"
                ),
                value: "10",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.energem"
                ),
                value: "11",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.greenyellow"
                ),
                value: "12",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.lucia"
                ),
                value: "13",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.leclerc"
                ),
                value: "14",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.OHM"
                ),
                value: "15",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.butagaz"
                ),
                value: "16",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.proxelia"
                ),
                value: "17",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.santerre"
                ),
                value: "18",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.iberdrola"
                ),
                value: "19",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.vattenfall"
                ),
                value: "20",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.engie"
                ),
                value: "21",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.total"
                ),
                value: "22",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.EDF"
                ),
                value: "23",
              },
              {
                label: t(
                  "collect:questions.7.energy_green.energy_green_provider.options.ENI"
                ),
                value: "24",
              },
              {
                label: t("collect:questions.common.other"),
                value: "25",
              },
            ]}
          />
          {generateTableMarkUp("energy_green_IT", "7.energy_green")}
        </FormLayout>
      </Module>
    </Form>
  )
}

export default EnergyGreenForm
