import { Form as PForm, FormLayout } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "./FormContext"

const Form = ({ children }) => {
  const { saveMarkup } = useFormContext()

  return (
    <PForm onSubmit={saveMarkup}>
      <FormLayout>{children}</FormLayout>
    </PForm>
  )
}

Form.propTypes = {
  children: PropTypes.node,
}

export default Form
