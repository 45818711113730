import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const TemplateFileLinkMolecule = ({ id }) => {
  const { t } = useTranslation()
  return (
    <Link
      to={`/templates/collects/decentralized/${i18next.language}/${id}.${i18next.language}.xlsx`}
      target="_blank"
      download
    >
      {t("collect:questions.common.drop_zone.template")}
    </Link>
  )
}

TemplateFileLinkMolecule.propTypes = {
  id: PropTypes.string.isRequired,
}

export default TemplateFileLinkMolecule
