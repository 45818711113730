import React, { createContext, useCallback, useContext, useState } from "react"
import NodeEntity from "services/entities/NodeEntity"
import PropTypes from "prop-types"
import _ from "lodash"
import {
  FORM_FIELDS_TO_PREFILL,
  FORM_MODE_EDIT,
} from "utils/constants/forms/nodeForms"
import PartnerEntity from "services/entities/PartnerEntity"
import BaseCentralization from "utils/functions/centralization/baseCentralization"
import { useEntity } from "hooks/useAxios"
import { useAppContext } from "components/AppContext"
import { CORPORATE_ROOT_NODE_TYPES } from "utils/constants/organization/nodeTypes"

const NodeContext = createContext({})

export const useNodeContext = () => useContext(NodeContext)

export const NodeContextProvider = ({
  children,
  nodeId,
  formFieldsToComponents,
  nodeTypeToFormFields,
  defaultDatasets,
  defaultNode,
}) => {
  const [node, setNode] = useState(defaultNode || {})
  const [initialNode, setInitialNode] = useState({})
  const [errors, setErrors] = useState({})
  const nodeEntity = useEntity(NodeEntity)
  const partnerEntity = useEntity(PartnerEntity)
  const { currentOrganization } = useAppContext()

  const getDefaultDataset = (type) => {
    return { ..._.cloneDeep(defaultDatasets[type]) } || null
  }

  const load = async () => {
    const { data } = await nodeEntity.show(nodeId)
    setInitialNode(_.cloneDeep(data))
    setNode(data)
  }

  const setParent = useCallback(
    async (parent, nodeType, root) => {
      const mergedObject = {
        ...node,
        ...getDefaultDataset(nodeType),
        type: nodeType,
        parent: parent,
        root,
      }

      const { data } = await nodeEntity.show(parent.id)
      const { characteristic: parentCharacteristic } = data

      FORM_FIELDS_TO_PREFILL.forEach((field) => {
        if (parentCharacteristic[field] !== null) {
          mergedObject.characteristic[field] = parentCharacteristic[field]
        }
      })

      mergedObject.centralization = BaseCentralization.defineInheritanceFromParent(
        data?.centralization
      )

      setNode(mergedObject)
    },
    [node]
  )

  const save = useCallback(
    async (mode) => {
      try {
        node.parent_id = node?.parent?.id ?? null
        node.root = node.parent_id === null

        if (mode === FORM_MODE_EDIT) {
          await nodeEntity.update(node.id, node)
        } else if (
          !CORPORATE_ROOT_NODE_TYPES.includes(node.type) &&
          node.parent.organization_id !== currentOrganization.id
        ) {
          node.partner_id = node.parent_id
          await partnerEntity.create(node)
        } else {
          await nodeEntity.create(node)
        }
      } catch (err) {
        setErrors(err?.response?.data)
        throw err
      }
    },
    [node]
  )

  const destroy = useCallback(async () => await nodeEntity.destroy(node.id), [
    node,
  ])

  return (
    <NodeContext.Provider
      value={{
        node,
        setNode,
        initialNode,
        errors,
        setParent,
        load,
        save,
        destroy,
        formFieldsToComponents,
        nodeTypeToFormFields,
      }}
    >
      {children}
    </NodeContext.Provider>
  )
}

NodeContextProvider.propTypes = {
  children: PropTypes.node,
  nodeId: PropTypes.number,
  defaultNode: PropTypes.object,
  formFieldsToComponents: PropTypes.object.isRequired,
  nodeTypeToFormFields: PropTypes.object.isRequired,
  defaultDatasets: PropTypes.object.isRequired,
}

NodeContextProvider.defaultProps = {
  formFieldsToComponents: {},
  nodeTypeToFormFields: {},
}
