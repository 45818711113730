import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

const AlreadyExistingBannerReportAtom = () => {
  const { t } = useTranslation()

  return (
    <Banner
      title={t("share:content.banners.already_existing_report.title")}
      status="warning"
    >
      <p>{t("share:content.banners.already_existing_report.body")}</p>
    </Banner>
  )
}

export default AlreadyExistingBannerReportAtom
