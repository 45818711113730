import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import {
  CENTRALIZATION_FIELD,
  FORM_MODE_CREATE,
  HIDDEN_CREATE_FIELDS,
  HIDDEN_FIELDS,
  HIDDEN_FIELDS_FOR_NO_PROVIDER,
  PARENT_FIELD,
} from "utils/constants/forms/nodeForms"
import { useNodeContext } from "views/organisms/interactive/NodeContext"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"

const NodeFormFields = ({
  mode,
  disabled,
  formFieldsToComponents,
  nodeTypeToFormFields,
}) => {
  const PROPERTIES_TO_ROOT = [PARENT_FIELD, CENTRALIZATION_FIELD]
  const { t } = useTranslation()
  const { currentRootNode, currentOrganization } = useAppContext()
  const { node, setNode, errors } = useNodeContext()

  const propertyMapper = useCallback(
    (property) =>
      property === PARENT_FIELD ? node.parent : node.characteristic?.[property],
    [node]
  )

  const onChange = useCallback(
    (property, value) => {
      if (!PROPERTIES_TO_ROOT.includes(property)) {
        node.characteristic[property] = value
      } else {
        node[property] = value
      }

      setNode({ ...node })
    },
    [node]
  )

  const generateFields = useCallback(() => {
    let type = node.type

    if (!type) {
      return null
    }

    let fields
    if (mode === FORM_MODE_CREATE) {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_CREATE_FIELDS.includes(property)
      )
    } else {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_FIELDS.includes(property)
      )
    }

    if (!currentRootNode.is_provider) {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_FIELDS_FOR_NO_PROVIDER.includes(property)
      )
    }

    if (!fields) return null

    return fields.map((property) => {
      if (Array.isArray(property)) {
        return (
          <FormLayout.Group key={property}>
            {property.map((subProperty) => {
              const Component = formFieldsToComponents[subProperty]
              return (
                <Component
                  key={subProperty}
                  nodeId={node.id}
                  type={type}
                  property={subProperty}
                  value={propertyMapper(subProperty)}
                  error={errors?.[subProperty]}
                  onChange={(value) => onChange(subProperty, value)}
                  disabled={disabled}
                  mode={mode}
                />
              )
            })}
          </FormLayout.Group>
        )
      } else {
        const Component = formFieldsToComponents[property]
        const label =
          currentOrganization?.is_partial_partner &&
          type === "country_team" &&
          property === "team_member_count"
            ? t(`activation:${type}.questions.${property}.label_guest`)
            : null
        property = property === "country_name" ? "name" : property
        return (
          <FormLayout.Group key={property}>
            <Component
              nodeId={node.id}
              type={type}
              property={property}
              value={propertyMapper(property)}
              error={errors?.[property]}
              onChange={(value) => onChange(property, value)}
              disabled={disabled}
              mode={mode}
              label={label}
            />
          </FormLayout.Group>
        )
      }
    })
  }, [node, errors])

  return generateFields()
}

export default NodeFormFields
