import React from "react"
import AlertBanner from "views/molecules/banners/AlertBanner"
import { useTranslation } from "react-i18next"

const NoHistorizationAlertBanner = () => {
  const { t } = useTranslation()

  return (
    <AlertBanner
      title={t(`analyze:no_historic.title`)}
      content={t(`analyze:no_historic.content`)}
    />
  )
}

export default NoHistorizationAlertBanner
