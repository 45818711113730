import PropTypes from "prop-types"
import React, { useCallback } from "react"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import PolarChart from "components/widgets/ScorePage/widgets/PolarChart"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import Config from "assets/configs/score.js"
import { Bar } from "react-chartjs-2"
import ChartBadge from "views/atoms/badges/ChartBadge"
import { COLORS } from "utils/constants/colors"
import { valueInKEuro } from "components/widgets/ScorePage/widgets/ChartOptionDefaults"
import { PILLAR_PROFIT } from "utils/constants/pillars"
import classNames from "classnames"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

const genderColorPerValue = (value, neutralColor) => {
  if (value < 50) {
    return COLORS.neutral.c10
  }
  if (value <= 55) {
    return neutralColor
  }
  if (value < 60) {
    return COLORS.warning.primary
  }
  return COLORS.danger.secondary
}

const Objective16Score = ({ score }) => {
  const { t } = useTranslation()

  const chart1 = useCallback(() => {
    const value = score.odd16_cdr_strategy_maturity
    const badge = <ChartBadge value={value} lowerLimit={10} upperLimit={40} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart1.kpi_unit"),
      kpi_text: t("score:score.16.chart1.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.9.chart2.help")}>
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const value = score.odd16_personal_data_maturity
    const badge = <ChartBadge value={value} lowerLimit={33} upperLimit={66} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart2.kpi_unit"),
      kpi_text: t("score:score.16.chart2.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart2.help")}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = useCallback(() => {
    const {
      female_values,
      male_values,
      label_keys,
    } = score.odd16_team_split_gender_chart
    const title = t("score:score.16.chart3.title")

    const labels = label_keys.map((key) =>
      t(`score:score.16.chart3.labels.${key}`)
    )

    const maleColors = male_values.map((value) =>
      genderColorPerValue(value[1], Config.common.colors[4])
    )
    const femaleColors = female_values.map((value) =>
      genderColorPerValue(value[1], Config.common.colors[1])
    )

    const data = [
      {
        label: t("score:score.16.chart3.labels.male"),
        gender: t("score:score.16.chart3.labels.male_letter"),
        data: male_values.map((value) => value[1]),
        plain_data: male_values.map((value) => value[0]),
        stack: 0,
        backgroundColor: maleColors,
      },
      {
        label: t("score:score.16.chart3.labels.female"),
        gender: t("score:score.16.chart3.labels.female_letter"),
        data: female_values.map((value) => value[1]),
        plain_data: female_values.map((value) => value[0]),
        stack: 0,
        backgroundColor: femaleColors,
      },
    ]
    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart3.help")}
      >
        <div
          className={classNames(
            "Verdikt-Score-HorizontalBarChart",
            PILLAR_PROFIT
          )}
        >
          <h1>{title}</h1>
          <div className="content">
            <Bar
              data={{ labels: labels, datasets: data }}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                plugins: {
                  datalabels: {
                    color: COLORS.white,
                    font: {
                      weight: "bold",
                    },
                    formatter: (value, tooltipItem) =>
                      `${tooltipItem.dataset.gender}: ${value}%`,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      const plainValue =
                        data.datasets[tooltipItem.datasetIndex].plain_data[
                          tooltipItem.index
                        ]

                      const gender = data.datasets[
                        tooltipItem.datasetIndex
                      ].label.toLowerCase()

                      return `${plainValue} ${gender}s (${tooltipItem.value}%)`
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart4 = useCallback(() => {
    const odd16_budget_chart = score.odd16_budget_chart

    const data = odd16_budget_chart.map((item) => {
      return {
        label: t(`score:score.16.chart4.labels.${item.label_key}`),
        data: item.value,
      }
    })

    const config = {
      scale: {
        display: true,
        gridLines: { color: COLORS.profit.c10 },
        ticks: {
          beginAtZero: true,
          fontSize: 10,
          fontColor: COLORS.profit.primary,
          callback: valueInKEuro,
        },
      },
      tooltips: {
        callbacks: {
          label: ({ value, index }, config) => {
            const label = config.labels[index]

            return `${label}: ${valueInKEuro(value)}`
          },
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: COLORS.white,
          formatter: valueInKEuro,
        },
      },
    }

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart4.help")}
      >
        <PolarChart
          title={t("score:score.16.chart4.title")}
          data={data}
          config={config}
          pillarId={PILLAR_PROFIT}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart5 = useCallback(() => {
    const value = score.odd16_ai_and_personal_data_maturity
    const badge = <ChartBadge value={value} lowerLimit={10} upperLimit={40} />

    const content = {
      kpi: value,
      kpi_unit: t("score:score.16.chart5.kpi_unit"),
      kpi_text: t("score:score.16.chart5.kpi_text"),
      reference: badge,
    }

    const contentForDisplay = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart5.help")}
      >
        <TextChart content={contentForDisplay} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const odd16_kpi_maturity_chart = score.odd16_kpi_maturity_chart

    const labels = odd16_kpi_maturity_chart.map((item) =>
      t(`score:score.16.chart6.labels.${item.label_key}`)
    )

    const values = odd16_kpi_maturity_chart.map((item) => item.value)
    let datasets = [
      {
        label: t("score:score.16.chart6.title"),
        data: { datas: values },
        borderColor: COLORS.profit.primary,
        pointBackgroundColor: COLORS.profit.primary,
      },
    ]

    const data = [
      {
        labels,
        datasets,
      },
    ]

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t("score:score.16.chart6.help")}
      >
        <RadarChart
          title={t("score:score.16.chart6.title")}
          titleClass={"increased-margin"}
          data={data}
          config={{
            // maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const { label } = data.datasets[tooltipItem.datasetIndex]
                  return `${label}: ${tooltipItem.yLabel}`
                },
              },
            },
          }}
        />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score?.odd16_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective16Score.propTypes = {
  score: PropTypes.shape({
    odd16_maturity: PropTypes.number,
    odd16_cdr_strategy_maturity: PropTypes.number.isRequired,
    odd16_personal_data_maturity: PropTypes.number.isRequired,
    odd16_ai_and_personal_data_maturity: PropTypes.number.isRequired,
    odd16_budget_chart: PropTypes.array.isRequired,
    odd16_kpi_maturity_chart: PropTypes.array.isRequired,
    odd16_team_split_gender_chart: PropTypes.shape({
      female_values: PropTypes.array.isRequired,
      male_values: PropTypes.array.isRequired,
      label_keys: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Objective16Score
