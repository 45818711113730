import Loading from "../widgets/Loading"
import React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { disabledAuth } from "./AuthConfig"

const withAuthRequired = (component) =>
  disabledAuth
    ? component
    : withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      })

export default withAuthRequired
