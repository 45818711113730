import _ from "lodash"
import { useEffect, useState } from "react"
import { useFormContext } from "./forms/FormContext"

export const isInvertedChoice = (value) => value
export const fieldIsFalsy = (value) => !value
export const fieldIsFalsyOrNonPositive = (value) =>
  fieldIsFalsy(value) || +value <= 0

/**
 * Disable and reset fields if another is set to false
 * disablingFields should be a list of { disablingField, fieldsToDisable, [condition] }
 */
const useDisablingFields = (disableConfig) => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    formData,
    setFormData,
    disabledFields,
    setDisabledFields,
  } = useFormContext()

  // Initial useEffect that sets all disabledFields. It cannot be merged with
  // the individual useEffects because the functions run in parallel and `disabledFields`
  // is not synced between all instances
  useEffect(() => {
    const disablingFields = _.map(disableConfig, "disablingField")
    const relevantFormData = _.pick(formData, disablingFields)
    const relevantFormDataSize = _.size(relevantFormData)

    if (!isLoading || relevantFormDataSize === 0) return

    const nextDisabled = {}
    disableConfig.forEach(
      ({ disablingField, fieldsToDisable, condition = fieldIsFalsy }) => {
        const isFieldDisabled = condition(_.get(formData, disablingField))
        fieldsToDisable.forEach((_field) =>
          _.set(nextDisabled, _field, isFieldDisabled)
        )
      }
    )
    setDisabledFields(nextDisabled)

    setIsLoading(relevantFormDataSize === 0)
  }, [formData])

  disableConfig.forEach(
    ({ disablingField, fieldsToDisable, condition = fieldIsFalsy }) => {
      useEffect(() => {
        if (isLoading) return

        const isFieldDisabled = condition(_.get(formData, disablingField))

        const nextDisabled = { ...disabledFields }
        fieldsToDisable.forEach((_field) =>
          _.set(nextDisabled, _field, isFieldDisabled)
        )
        setDisabledFields(nextDisabled)

        if (!isFieldDisabled) return

        const nextFormData = { ...formData }
        fieldsToDisable.forEach((_field) => _.set(nextFormData, _field, null))
        setFormData(nextFormData)
      }, [_.get(formData, disablingField)])
    }
  )

  return isLoading
}

export default useDisablingFields
