import React, { createContext, useContext } from "react"
import useHistorization from "hooks/useHistorization"

export const HistoryContext = createContext({
  companyHistorySelected: null,
  companyHistoryLastCreated: null,
  companyHistoryList: [],
  updateHistoryRequest: () => {},
  goBackToCurrentPeriod: () => {},
  canReassess: false,
  isCurrentPeriod: false,
  reload: () => {},
})

export const useHistoryContext = () => {
  return useContext(HistoryContext)
}

const HistoryContextContainer = ({ children }) => {
  const historization = useHistorization()

  if (!historization.isLoading) {
    return (
      <HistoryContext.Provider
        value={{
          companyHistorySelected: historization.selected,
          companyHistoryLastCreated: historization.lastCreated,
          companyHistoryList: historization.list,
          updateHistoryRequest: historization.update,
          canReassess: historization.canReassess,
          isCurrentPeriod: historization.isCurrentPeriod,
          goBackToCurrentPeriod: historization.goBackToCurrentPeriod,
          reload: historization.reload,
        }}
      >
        {children}
      </HistoryContext.Provider>
    )
  } else {
    return <></>
  }
}

export default HistoryContextContainer
