import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
  NODE_TYPE_PHASE,
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_REGION,
  NODE_TYPE_SITE,
} from "utils/constants/organization/nodeTypes"

export const CORPORATE_NODE_TYPES = [
  NODE_TYPE_BRAND,
  NODE_TYPE_REGION,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_SITE,
]

export const PARTNER_TYPES = [
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_PHASE,
  NODE_TYPE_COUNTRY_TEAM,
]
