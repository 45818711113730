import _ from "lodash"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"
import {
  convertValuesToUnit,
  determineUnit,
} from "services/units/valueConversion"
import { round } from "services/units/round"

const useDomainBreakdownChart = (structure, score, options) => {
  const { t } = useTranslation()

  const {
    baseUnit,
    lowerUnit,
    unitType,
    manufacturingLabel,
    useLabel,
    units,
  } = options

  const cleanedStructure = structure.filter(
    ({ fab, use }) => +score[fab] || +score[use]
  )

  const totals = cleanedStructure.map(
    ({ fab, use }) => Number(score[fab]) + Number(score[use])
  )

  const fab_data = cleanedStructure.map(({ fab }) => score[fab])
  const use_data = cleanedStructure.map(({ use }) => score[use])
  const all_data = fab_data.concat(use_data)

  let unit = baseUnit

  if (!units) {
    unit = totals.some((total) => total !== 0 && total < 1)
      ? lowerUnit
      : baseUnit
    const unitMultiplier = unit === lowerUnit ? 1000 : 1
    const displayFormatter = (value) =>
      Number(value * unitMultiplier).toFixed(0)

    var fab_data_to_be_displayed = cleanedStructure.map(({ fab }) =>
      displayFormatter(score[fab])
    )
    var use_data_to_be_displayed = cleanedStructure.map(({ use }) =>
      displayFormatter(score[use])
    )
  } else {
    unit = determineUnit(all_data, baseUnit, units, 0.1)
    var { values: fab_data_to_be_displayed } = convertValuesToUnit(
      fab_data,
      baseUnit,
      unit,
      units,
      (value) => round(value, 0)
    )
    var { values: use_data_to_be_displayed } = convertValuesToUnit(
      use_data,
      baseUnit,
      unit,
      units,
      (value) => round(value, 0)
    )
  }

  let data = [
    {
      label: manufacturingLabel,
      data: _.zipWith(cleanedStructure, totals, ({ fab }, total) =>
        Math.round((Number(score[fab]) / total) * 100)
      ),
      plain_values: _.zipWith(cleanedStructure, ({ fab }) => score[fab]),
    },
    {
      label: useLabel,
      data: _.zipWith(cleanedStructure, totals, ({ use }, total) =>
        Math.round((Number(score[use]) / total) * 100)
      ),
      plain_values: _.zipWith(cleanedStructure, ({ use }) => score[use]),
    },
  ]

  const labelKeys = cleanedStructure.map(({ label }) => label)

  const colorCallback = (
    index,
    { plain_values },
    datasetIndex,
    percentLimit = 50
  ) => {
    const xPercentThreshold = totals[index] * (percentLimit / 100)

    return plain_values[index] >= xPercentThreshold
      ? COLORS.warning.primary
      : COLORS.planet.primary
  }

  return {
    fab_data_to_be_displayed,
    use_data_to_be_displayed,
    data,
    unit,
    colorCallback,
    fab_suffix: t(`units:units.${unitType}.${unit}`),
    use_suffix: t(`units:units.${unitType}.${unit}`),
    labelKeys,
  }
}

export default useDomainBreakdownChart
