import { FormLayout } from "@shopify/polaris"
import CheckboxTableField from "components/widgets/CollectPage/forms/fields/CheckboxTableField"
import FileField from "components/widgets/CollectPage/forms/fields/FileField"
import Form from "components/widgets/CollectPage/forms/Form"
import WebsiteInventory from "components/widgets/CollectPage/forms/inventories/environment/WebsitesInventory"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  INVENTORY_EXTERNAL_APPS,
  INVENTORY_VIRTUAL_MACHINES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
} from "utils/constants/centralization/inventoryItems"
import {
  EXTERNAL_APPS_COLLECT_MODULE,
  INTERNAL_APPS_COLLECT_MODULE,
  MAIL_COLLABORATION_MODULE,
  WEBSITE_COLLECT_MODULE,
} from "utils/constants/collects/modules"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import { Module } from "../../Module"
import FormContextProvider, { useFormConfig } from "../../FormContext"
import SnowUploadOrganism from "views/organisms/upload/SnowUploadOrganism"
import { useCollectContext } from "../../../CollectContextProvider"
import O365CredentialsModalOrganism from "views/organisms/forms/modal/O365CredentialsModalOrganism"
import { useAppContext } from "components/AppContext"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"

const KEYS_TO_MODULES = {
  internal_apps_ecoconception_policy: INTERNAL_APPS_COLLECT_MODULE,
  external_apps_ecoconception_policy: EXTERNAL_APPS_COLLECT_MODULE,
  internal_apps_sobriety_policy: INTERNAL_APPS_COLLECT_MODULE,
  internal_apps_web_optim_policy: INTERNAL_APPS_COLLECT_MODULE,
  external_apps_web_optim_policy: EXTERNAL_APPS_COLLECT_MODULE,
  internal_apps_data_volume_policy: INTERNAL_APPS_COLLECT_MODULE,
  external_apps_data_volume_policy: EXTERNAL_APPS_COLLECT_MODULE,
  external_apps_csr_criteria: EXTERNAL_APPS_COLLECT_MODULE,
}

const fields = [
  "collab_archiving_policy",
  "collab_deletion_policy",
  "collab_file_quota_policy",
  "collab_storage_quota_policy",
  "external_apps_data_volume_policy",
  "external_apps_ecoconception_policy",
  "external_apps_web_optim_policy",
  "internal_apps_data_volume_policy",
  "internal_apps_ecoconception_policy",
  "internal_apps_sobriety_policy",
  "internal_apps_web_optim_policy",
  "mail_archiving_policy",
  "mail_deletion_policy",
  "mail_mail_quota_policy",
  "mail_mailbox_quota_policy",
  "external_apps_csr_criteria",
]
const translationPath = "13.software"

const Objective13SoftwareForm = () => {
  const { t } = useTranslation()
  const { collect, setCollect } = useCollectContext()
  useFormConfig({ fields, translationPath })

  const { isModuleActive, isCollectLocked } = useCollectContext()
  const { subscription } = useAppContext()

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <FormLayout>
              <FormLayout.Group
                condensed
                title={
                  t("collect:questions.13.software.files_precision") +
                  t("collect:questions.13.software.files")
                }
              >
                <Module module={INTERNAL_APPS_COLLECT_MODULE}>
                  <UploadOrganism
                    centralisedComponent={
                      <CentralizedUploadMolecule
                        translationPathName="virtual_machines_file"
                        inventoryType={INVENTORY_VIRTUAL_MACHINES}
                        displayDownloadIcon
                      />
                    }
                    decentralisedComponent={
                      <FileField
                        id="decentralized_inventory_virtual_machines"
                        translationPathName="virtual_machines_file"
                        inventoryType={INVENTORY_VIRTUAL_MACHINES}
                        title={t(
                          "collect:questions.13.software.virtual_machines_file.label"
                        )}
                        templateSelectionComponent={
                          <TemplateFileLinkMolecule id="decentralized_inventory_virtual_machines" />
                        }
                      />
                    }
                  />
                </Module>
                <Module module={EXTERNAL_APPS_COLLECT_MODULE}>
                  <SnowUploadOrganism
                    translationPathName="external_apps_file"
                    inventoryType={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}
                  >
                    <UploadOrganism
                      centralization={collect?.centralization}
                      centralisedComponent={
                        <CentralizedUploadMolecule
                          translationPathName="external_apps_file"
                          inventoryType={INVENTORY_EXTERNAL_APPS}
                          displayDownloadIcon
                        />
                      }
                      decentralisedComponent={
                        <FileField
                          id="decentralized_inventory_external_apps"
                          translationPathName="external_apps_file"
                          inventoryType={INVENTORY_EXTERNAL_APPS}
                          title={t(
                            "collect:questions.13.software.external_apps_communication_file.label"
                          )}
                          templateSelectionComponent={
                            <TemplateFileLinkMolecule id="decentralized_inventory_external_apps" />
                          }
                        />
                      }
                    />
                  </SnowUploadOrganism>

                  {subscription?.features?.env_office_365 && (
                    <O365CredentialsModalOrganism
                      collect={collect}
                      setCollect={setCollect}
                      locked={isCollectLocked}
                    />
                  )}
                </Module>
              </FormLayout.Group>
              <br />
              <Module module={WEBSITE_COLLECT_MODULE}>
                <FormContextProvider>
                  <WebsiteInventory />
                </FormContextProvider>
              </Module>
            </FormLayout>
          </div>
          <div className="column">
            <FormLayout>
              <CheckboxTableField
                id="ecomanagement"
                headerIds={["mail", "collab"]}
                disabler={(field) => !isModuleActive(KEYS_TO_MODULES[field])}
                modules={[MAIL_COLLABORATION_MODULE, MAIL_COLLABORATION_MODULE]}
                rows={[
                  {
                    title: "archive",
                    fields: [
                      "mail_archiving_policy",
                      "collab_archiving_policy",
                    ],
                  },
                  {
                    title: "deletion",
                    fields: ["mail_deletion_policy", "collab_deletion_policy"],
                  },
                  {
                    title: "global_quota",
                    fields: [
                      "mail_mailbox_quota_policy",
                      "collab_storage_quota_policy",
                    ],
                  },
                  {
                    title: "detail_quota",
                    fields: [
                      "mail_mail_quota_policy",
                      "collab_file_quota_policy",
                    ],
                  },
                ]}
              />
              <CheckboxTableField
                id="ecoconception"
                headerIds={["internal", "external"]}
                disabler={(field) => !isModuleActive(KEYS_TO_MODULES[field])}
                modules={[
                  INTERNAL_APPS_COLLECT_MODULE,
                  EXTERNAL_APPS_COLLECT_MODULE,
                ]}
                rows={[
                  {
                    title: "ecoconception",
                    fields: [
                      "internal_apps_ecoconception_policy",
                      "external_apps_ecoconception_policy",
                    ],
                  },
                  {
                    title: "sobriety",
                    fields: ["internal_apps_sobriety_policy", null],
                  },
                  {
                    title: "web_optim",
                    fields: [
                      "internal_apps_web_optim_policy",
                      "external_apps_web_optim_policy",
                    ],
                  },
                  {
                    title: "data_volume",
                    fields: [
                      "internal_apps_data_volume_policy",
                      "external_apps_data_volume_policy",
                    ],
                  },
                  {
                    title: "saas_criteria",
                    fields: [null, "external_apps_csr_criteria"],
                  },
                ]}
              />
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13SoftwareForm
