import { Form, Modal } from "@shopify/polaris"
import React from "react"
import PropTypes from "prop-types"
import {
  FORM_MODE_CREATE,
  FORM_MODE_EDIT,
} from "utils/constants/forms/nodeForms"
import NodeFormFields from "../../interactive/NodeFormFields"

const NodeFormModalOrganism = ({
  mode,
  disabled,
  formFieldsToComponents,
  nodeTypeToFormFields,
  onSubmit,
}) => {
  return (
    <Modal.Section>
      <Form onSubmit={onSubmit}>
        <NodeFormFields
          mode={mode}
          disabled={disabled}
          formFieldsToComponents={formFieldsToComponents}
          nodeTypeToFormFields={nodeTypeToFormFields}
        />
      </Form>
    </Modal.Section>
  )
}

NodeFormModalOrganism.displayName = `${NodeFormModalOrganism}`

NodeFormModalOrganism.propTypes = {
  mode: PropTypes.oneOf([FORM_MODE_EDIT, FORM_MODE_CREATE]),
  disabled: PropTypes.bool,
  formFieldsToComponents: PropTypes.object.isRequired,
  nodeTypeToFormFields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

NodeFormModalOrganism.defaultProps = {
  creationNodeParentSelected: null,
  disabled: false,
  mode: FORM_MODE_CREATE,
  onSubmit: () => null,
}

export default NodeFormModalOrganism
